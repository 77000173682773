import { FileExcelOutlined, LoadingOutlined } from "@ant-design/icons";
import { I18n } from "@lingui/core";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  Channel,
  ChannelNames,
  Container,
  Form,
  MultiOption,
  Select,
  Spinner,
  Tooltip,
  Typography,
  VirtualTable,
  formatNumber,
} from "@ster/ster-toolkit";
import { Alert, Space } from "antd";
import { SelectValue } from "antd/lib/select";
import { ColumnType, ColumnsType } from "antd/lib/table";
import { format } from "date-fns";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { SpotResultGrpOutput, SpotResultOutput } from "../../api";
import { ReduxStoreState } from "../../store/base";
import { StoreModel } from "../../store/models";
import { UniqueStrings } from "../../utils";
import { compact } from "../../utils/array";
import { SpotAnalysisFilter } from "./models";

const columnLabels = {
  DateOfBroadcast: t`Datum`,
  TimeOfBroadcast: t`Tijd`,
  BreakId: t`Blok ID`,
  Channel: t`Zender`,
  ProgramBefore: t`Voor`,
  ProgramAfter: t`Na`,
  CommercialCode: t`Code`,
  CommercialTitle: t`Titel`,
  Duration: t`Spotlengte`,
  "TargetGroupAvg:0": t`Primaire doelgroep (blokgemiddelde)`,
  "TargetGroup:0": t`Primaire doelgroep`,
  "TargetGroupAvg:X": t`Secundaire doelgroep(en) (blokgemiddelde)`,
  "TargetGroup:X": t`Secundaire doelgroep(en)`,
  AdvertiserName: t`Adverteerder`,
  ProductName: t`Productnaam`,
};

export type SpotAnalysisColumn = keyof typeof columnLabels;

const allColumnKeys = Object.keys(columnLabels) as SpotAnalysisColumn[];
const initialSelection = allColumnKeys.filter(
  (c) => !["AdvertiserName", "ProductName"].includes(c)
);

const getLabel = (c: SpotAnalysisColumn, i18n: I18n) => i18n._(columnLabels[c]);

const SpotAnalysisList = ({
  filter,
  onExport,
}: {
  filter: SpotAnalysisFilter;
  onExport: (selectedColumns: SpotAnalysisColumn[]) => void;
}) => {
  const { i18n } = useLingui();
  const {
    loading = false,
    state,
    spots,
  } = useSelector((store: StoreModel) => store.spotTracker.data);
  const { loading: exportLoading = false } = useSelector(
    (store: StoreModel) => store.spotTracker.export
  );

  const [selectedColumns, setSelectedColumns] = useState(initialSelection);

  const handleColumnsChange = useCallback((values: SelectValue) => {
    const newSelection = (values as SpotAnalysisColumn[]) ?? initialSelection;
    setSelectedColumns(newSelection);
  }, []);

  const channels = useMemo(
    () => UniqueStrings((spots ?? []).map((s) => s.channel).sort()),
    [spots]
  );

  const secondaryTargetGroups = useMemo(() => {
    const secTargetGroups = (spots ?? [])
      .flatMap((s) =>
        s.targetGroups && s.targetGroups.length > 1
          ? s.targetGroups.slice(1)
          : []
      )
      .map((tg) => tg.displayName!)
      .sort();
    return UniqueStrings(compact(secTargetGroups));
  }, [spots]);

  const renderGrpsAvg = useCallback(
    (
      predicate: (value: SpotResultGrpOutput) => boolean,
      targetGroups: SpotResultGrpOutput[]
    ) => (
      <>
        {formatNumber(
          Number(targetGroups.find(predicate)?.blockAvarage ?? "0"),
          1
        )}
      </>
    ),
    []
  );

  const sorterGrpsAvg = useCallback(
    (
      predicate: (value: SpotResultGrpOutput) => boolean,
      a: SpotResultOutput,
      b: SpotResultOutput
    ): number =>
      Number(a.targetGroups.find(predicate)?.blockAvarage ?? "0") -
      Number(b.targetGroups.find(predicate)?.blockAvarage ?? "0"),
    []
  );

  const renderGrps = useCallback(
    (
      predicate: (value: SpotResultGrpOutput) => boolean,
      targetGroups: SpotResultGrpOutput[]
    ) => (
      <>{formatNumber(Number(targetGroups.find(predicate)?.grps ?? "0"), 1)}</>
    ),
    []
  );

  const sorterGrps = useCallback(
    (
      predicate: (value: SpotResultGrpOutput) => boolean,
      a: SpotResultOutput,
      b: SpotResultOutput
    ): number =>
      Number(a.targetGroups.find(predicate)?.grps ?? "0") -
      Number(b.targetGroups.find(predicate)?.grps ?? "0"),
    []
  );

  if (state === ReduxStoreState.Initial) {
    return (
      <Container>
        <Typography.Title level={2}>
          <Trans>Spotanalyse starten</Trans>
        </Typography.Title>
        <Typography.Paragraph>
          <Trans>
            Met onze spotanalyse krijg je een compleet overzicht van het bruto
            bereik van alle uitgezonden spots. Selecteer de doelgroep van jouw
            keuze en de campagne waar het om gaat, en de analyse wordt
            uitgedraaid. Kom je er niet uit of heb je vragen over de analyse?
            Wij staan voor je klaar! Neem contact op met je accountmanager of
            met onze planners via de chatfunctie in de Ster Klantportal.
          </Trans>
        </Typography.Paragraph>
      </Container>
    );
  }

  const columns: ColumnsType<SpotResultOutput> = [
    {
      title: i18n._(t`Datum`),
      key: "DateOfBroadcast",
      width: 125,
      render: (_, { dateOfBroadcast }) => (
        <>{format(dateOfBroadcast, "d MMM yyyy")}</>
      ),
      sorter: (a, b): number =>
        a.dateOfBroadcast.getTime() - b.dateOfBroadcast.getTime(),
    },
    {
      title: i18n._(t`Tijd`),
      key: "TimeOfBroadcast",
      width: 100,
      render: (_, { dateOfBroadcast }) => (
        <>{format(dateOfBroadcast, "HH:mm")}</>
      ),
      sorter: (a, b): number =>
        a.dateOfBroadcast.getTime() - b.dateOfBroadcast.getTime(),
    },
    {
      title: i18n._(t`Blok ID`),
      key: "BreakId",
      dataIndex: "breakId",
      width: 150,
      sorter: (a, b): number => a.breakId.localeCompare(b.breakId),
    },
    {
      title: i18n._(t`Zender`),
      key: "Channel",
      width: 110,
      align: "center",
      filters: channels.map((c) => ({ text: c, value: c })),
      onFilter: (value, { channel }) => channel === value,
      render: (_, { channel }) => (
        <div title={channel}>
          <Channel type={channel as ChannelNames} />
        </div>
      ),
      sorter: (a, b): number => a.channel.localeCompare(b.channel),
    },
    {
      title: i18n._(t`Voor`),
      key: "ProgramBefore",
      dataIndex: "programBefore",
      width: 200,
      ellipsis: true,
      sorter: (a, b): number => a.programBefore.localeCompare(b.programBefore),
    },
    {
      title: i18n._(t`Na`),
      key: "ProgramAfter",
      dataIndex: "programAfter",
      width: 200,
      ellipsis: true,
      sorter: (a, b): number => a.programAfter.localeCompare(b.programAfter),
    },
    {
      title: i18n._(t`Code`),
      key: "CommercialCode",
      dataIndex: "commercialCode",
      width: 150,
      sorter: (a, b): number => a.breakId.localeCompare(b.breakId),
    },
    {
      title: i18n._(t`Titel`),
      key: "CommercialTitle",
      dataIndex: "commercialTitle",
      width: 200,
      ellipsis: true,
      sorter: (a, b): number =>
        a.commercialTitle.localeCompare(b.commercialTitle),
    },
    {
      title: i18n._(t`Spotlengte`),
      key: "Duration",
      width: 125,
      align: "right",
      render: (_, { duration }) => <>{duration}&quot;</>,
      sorter: (a, b): number => a.duration - b.duration,
    },
    {
      title: (
        <Tooltip
          title={i18n._(
            t`Gemiddeld aantal GRP's op blokniveau voor de 13+ doelgroep`
          )}
        >
          {i18n._(t`13+ (gem.)`)}
        </Tooltip>
      ),
      key: "TargetGroupAvg:0",
      width: 150,
      align: "right",
      render: (_, { targetGroups }) =>
        renderGrpsAvg((tg) => tg.targetGroup === "13+", targetGroups),
      sorter: (a, b): number =>
        sorterGrpsAvg((tg) => tg.targetGroup === "13+", a, b),
    },
    {
      title: (
        <Tooltip title={i18n._(t`GRP's op spotniveau voor de 13+ doelgroep`)}>
          {i18n._(t`13+`)}
        </Tooltip>
      ),
      key: "TargetGroup:0",
      width: 150,
      align: "right",
      render: (_, { targetGroups }) =>
        renderGrps((tg) => tg.targetGroup === "13+", targetGroups),
      sorter: (a, b): number =>
        sorterGrps((tg) => tg.targetGroup === "13+", a, b),
    },
    ...secondaryTargetGroups.flatMap(
      (secondaryTargetGroup, idx): ColumnType<SpotResultOutput>[] => [
        {
          title: secondaryTargetGroup ? (
            <Tooltip
              title={i18n._(
                t`Gemiddeld aantal GRP's op blokniveau voor de ingevulde secundaire doelgroep`
              )}
            >
              {secondaryTargetGroup} {i18n._(t`(gem.)`)}
            </Tooltip>
          ) : (
            i18n._(t`Sec. dlgrp.`)
          ),
          key: `TargetGroupAvg:${idx + 1}`,
          width: 150,
          align: "right",
          render: (_, { targetGroups }) =>
            renderGrpsAvg(
              (tg) => tg.displayName === secondaryTargetGroup,
              targetGroups
            ),
          sorter: (a, b): number =>
            sorterGrpsAvg(
              (tg) => tg.displayName === secondaryTargetGroup,
              a,
              b
            ),
        },
        {
          title: secondaryTargetGroup ? (
            <Tooltip
              title={i18n._(
                t`GRP's op spotniveau voor de ingevulde secundaire doelgroep`
              )}
            >
              {secondaryTargetGroup}
            </Tooltip>
          ) : (
            i18n._(t`Sec. dlgrp.`)
          ),
          key: `TargetGroup:${idx + 1}`,
          width: 150,
          align: "right",
          render: (_, { targetGroups }) =>
            renderGrps(
              (tg) => tg.displayName === secondaryTargetGroup,
              targetGroups
            ),
          sorter: (a, b): number =>
            sorterGrps((tg) => tg.displayName === secondaryTargetGroup, a, b),
        },
      ]
    ),
    {
      title: i18n._(t`Adverteerder`),
      key: "AdvertiserName",
      dataIndex: "advertiserName",
      width: 200,
      ellipsis: true,
      sorter: (a, b): number =>
        a.advertiserName.localeCompare(b.advertiserName),
    },
    {
      title: i18n._(t`Productnaam`),
      key: "ProductName",
      dataIndex: "productName",
      width: 200,
      ellipsis: true,
      sorter: (a, b): number => a.productName.localeCompare(b.productName),
    },
  ];

  return (
    <Container>
      <Spinner spinning={loading}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          <Typography.Title level={2}>
            <Trans>Spots</Trans>
          </Typography.Title>

          <Form layout="vertical">
            <Space>
              <Form.Item label={i18n._(t`Toon/verberg kolommen`)}>
                <Select.Multi
                  placeholder={i18n._(t`Kies kolommen`)}
                  style={{ minWidth: 320 }}
                  value={selectedColumns}
                  onChange={handleColumnsChange}
                >
                  {allColumnKeys.map((c) => (
                    <MultiOption key={c} value={c} title={getLabel(c, i18n)} />
                  ))}
                </Select.Multi>
              </Form.Item>
              <Form.Item label={i18n._(t`Download overzicht`)}>
                <Tooltip title={i18n._(t`Exporteer de spotanalyse naar Excel`)}>
                  <Button
                    mode="secondary"
                    onClick={() => onExport(selectedColumns)}
                    disabled={exportLoading}
                  >
                    <Space>
                      {exportLoading ? (
                        <LoadingOutlined />
                      ) : (
                        <FileExcelOutlined />
                      )}
                      <Trans>Exporteer</Trans>
                    </Space>
                  </Button>
                </Tooltip>
              </Form.Item>
            </Space>
          </Form>
        </div>
        {spots?.length === 0 ? (
          <Typography.Paragraph>
            <Trans>
              Er zijn geen spots beschikbaar voor de gemaakte selectie. Pas de
              filters aan in het formulier.
            </Trans>
          </Typography.Paragraph>
        ) : (
          <>
            {spots?.length > 0 && (
              <Alert
                showIcon
                type="warning"
                style={{ width: "100%", marginBottom: 24 }}
                message={<Trans>Let op</Trans>}
                description={
                  <Trans>
                    Voor de analyses via de Ster Klantportal wordt gebruik
                    gemaakt van een externe databron. Alle getoonde data zijn op
                    basis van het daadwerkelijke bereik op spotniveau. Bij de
                    verkoop van zendtijd wordt gebruik gemaakt van
                    blokgemiddelden, waardoor deze cijfers afwijken van de
                    getoonde data in de rest van de Ster Klantportal.
                  </Trans>
                }
              />
            )}
            <VirtualTable
              columns={columns.filter(
                (c) =>
                  selectedColumns.includes(c.key as SpotAnalysisColumn) ||
                  (filter.secondaryTargetGroups &&
                    selectedColumns.some(
                      (s) =>
                        (s === "TargetGroup:X" &&
                          c.key?.toString().startsWith("TargetGroup:")) ||
                        (s === "TargetGroupAvg:X" &&
                          c.key?.toString().startsWith("TargetGroupAvg:"))
                    ))
              )}
              dataSource={spots}
              scroll={{ x: "max-content", y: 640 }}
              rowKey={(row): string => JSON.stringify(row)}
            />
          </>
        )}
      </Spinner>
    </Container>
  );
};

export default SpotAnalysisList;
