/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  CommercialUploadRegistration,
  CommercialUploadRegistrationFromJSON,
  CommercialUploadRegistrationReactivate,
  CommercialUploadRegistrationReactivateFromJSON,
  CommercialUploadRegistrationReactivateToJSON,
  CommercialUploadRegistrationReview,
  CommercialUploadRegistrationReviewFromJSON,
  CommercialUploadRegistrationReviewToJSON,
  CommercialUploadRegistrationToJSON,
  CommercialUploadResult,
  CommercialUploadResultFromJSON,
  CommercialUploadResultToJSON,
  CommercialsPerAdvertiser,
  CommercialsPerAdvertiserFromJSON,
  CommercialsPerAdvertiserToJSON,
  MediaFileType,
  MediaFileTypeFromJSON,
  MediaFileTypeToJSON,
  MediumEnum,
  MediumEnumFromJSON,
  MediumEnumToJSON,
  NpoPlayerResult,
  NpoPlayerResultFromJSON,
  NpoPlayerResultToJSON,
  ProblemDetails,
  ProblemDetailsFromJSON,
  ProblemDetailsToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface ApiV1CommercialsAuditGetRequest {
  medium?: MediumEnum;
  showAll?: boolean;
}

export interface ApiV1CommercialsAuditPostRequest {
  commercialUploadRegistrationReview?: CommercialUploadRegistrationReview;
  medium?: MediumEnum;
}

export interface ApiV1CommercialsGetRequest {
  medium?: MediumEnum;
  from?: Date;
  to?: Date;
  productId?: number;
  includeThumbs?: boolean;
}

export interface ApiV1CommercialsMediaIdInitGetRequest {
  mediaId: string;
}

export interface ApiV1CommercialsProductIdGetRequest {
  productId: number;
  medium?: MediumEnum;
  from?: Date;
  to?: Date;
  includeThumbs?: boolean;
}

export interface ApiV1CommercialsReactivatePostRequest {
  commercialUploadRegistrationReactivate?: CommercialUploadRegistrationReactivate;
  medium?: MediumEnum;
}

export interface ApiV1CommercialsThumbnailsCommercialCodeThumbnailIndexGetRequest {
  commercialCode: string;
  thumbnailIndex: string;
}

export interface ApiV1CommercialsTypeCommercialCodeGetRequest {
  commercialCode: string;
  type: MediaFileType;
}

export interface ApiV1CommercialsUploadPostRequest {
  advertiserId: number;
  advertiserName: string;
  commercialTitle: string;
  uploadType: string;
  files: Array<Blob>;
  clickUrl: string;
  medium?: MediumEnum;
}

/**
 *
 */
export class CommercialsApi extends runtime.BaseAPI {
  /**
   */
  async apiV1CommercialsAuditGetRaw(
    requestParameters: ApiV1CommercialsAuditGetRequest
  ): Promise<runtime.ApiResponse<Array<CommercialUploadRegistration>>> {
    const queryParameters: any = {};

    if (requestParameters.medium !== undefined) {
      queryParameters["medium"] = requestParameters.medium;
    }

    if (requestParameters.showAll !== undefined) {
      queryParameters["showAll"] = requestParameters.showAll;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Commercials/audit`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(CommercialUploadRegistrationFromJSON)
    );
  }

  /**
   */
  async apiV1CommercialsAuditGet(
    requestParameters: ApiV1CommercialsAuditGetRequest
  ): Promise<Array<CommercialUploadRegistration>> {
    const response = await this.apiV1CommercialsAuditGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1CommercialsAuditPostRaw(
    requestParameters: ApiV1CommercialsAuditPostRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    if (requestParameters.medium !== undefined) {
      queryParameters["medium"] = requestParameters.medium;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Commercials/audit`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: CommercialUploadRegistrationReviewToJSON(
        requestParameters.commercialUploadRegistrationReview
      ),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async apiV1CommercialsAuditPost(
    requestParameters: ApiV1CommercialsAuditPostRequest
  ): Promise<void> {
    await this.apiV1CommercialsAuditPostRaw(requestParameters);
  }

  /**
   */
  async apiV1CommercialsGetRaw(
    requestParameters: ApiV1CommercialsGetRequest
  ): Promise<runtime.ApiResponse<Array<CommercialsPerAdvertiser>>> {
    const queryParameters: any = {};

    if (requestParameters.medium !== undefined) {
      queryParameters["medium"] = requestParameters.medium;
    }

    if (requestParameters.from !== undefined) {
      queryParameters["from"] = (requestParameters.from as any).toISOString();
    }

    if (requestParameters.to !== undefined) {
      queryParameters["to"] = (requestParameters.to as any).toISOString();
    }

    if (requestParameters.productId !== undefined) {
      queryParameters["productId"] = requestParameters.productId;
    }

    if (requestParameters.includeThumbs !== undefined) {
      queryParameters["includeThumbs"] = requestParameters.includeThumbs;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Commercials`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(CommercialsPerAdvertiserFromJSON)
    );
  }

  /**
   */
  async apiV1CommercialsGet(
    requestParameters: ApiV1CommercialsGetRequest
  ): Promise<Array<CommercialsPerAdvertiser>> {
    const response = await this.apiV1CommercialsGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1CommercialsMediaIdInitGetRaw(
    requestParameters: ApiV1CommercialsMediaIdInitGetRequest
  ): Promise<runtime.ApiResponse<NpoPlayerResult>> {
    if (
      requestParameters.mediaId === null ||
      requestParameters.mediaId === undefined
    ) {
      throw new runtime.RequiredError(
        "mediaId",
        "Required parameter requestParameters.mediaId was null or undefined when calling apiV1CommercialsMediaIdInitGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Commercials/{mediaId}/init`.replace(
        `{${"mediaId"}}`,
        encodeURIComponent(String(requestParameters.mediaId))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      NpoPlayerResultFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1CommercialsMediaIdInitGet(
    requestParameters: ApiV1CommercialsMediaIdInitGetRequest
  ): Promise<NpoPlayerResult> {
    const response =
      await this.apiV1CommercialsMediaIdInitGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1CommercialsProductIdGetRaw(
    requestParameters: ApiV1CommercialsProductIdGetRequest
  ): Promise<runtime.ApiResponse<CommercialsPerAdvertiser>> {
    if (
      requestParameters.productId === null ||
      requestParameters.productId === undefined
    ) {
      throw new runtime.RequiredError(
        "productId",
        "Required parameter requestParameters.productId was null or undefined when calling apiV1CommercialsProductIdGet."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.medium !== undefined) {
      queryParameters["medium"] = requestParameters.medium;
    }

    if (requestParameters.from !== undefined) {
      queryParameters["from"] = (requestParameters.from as any).toISOString();
    }

    if (requestParameters.to !== undefined) {
      queryParameters["to"] = (requestParameters.to as any).toISOString();
    }

    if (requestParameters.includeThumbs !== undefined) {
      queryParameters["includeThumbs"] = requestParameters.includeThumbs;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Commercials/{productId}`.replace(
        `{${"productId"}}`,
        encodeURIComponent(String(requestParameters.productId))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CommercialsPerAdvertiserFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1CommercialsProductIdGet(
    requestParameters: ApiV1CommercialsProductIdGetRequest
  ): Promise<CommercialsPerAdvertiser> {
    const response =
      await this.apiV1CommercialsProductIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1CommercialsReactivatePostRaw(
    requestParameters: ApiV1CommercialsReactivatePostRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    if (requestParameters.medium !== undefined) {
      queryParameters["medium"] = requestParameters.medium;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Commercials/reactivate`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: CommercialUploadRegistrationReactivateToJSON(
        requestParameters.commercialUploadRegistrationReactivate
      ),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async apiV1CommercialsReactivatePost(
    requestParameters: ApiV1CommercialsReactivatePostRequest
  ): Promise<void> {
    await this.apiV1CommercialsReactivatePostRaw(requestParameters);
  }

  /**
   */
  async apiV1CommercialsThumbnailsCommercialCodeThumbnailIndexGetRaw(
    requestParameters: ApiV1CommercialsThumbnailsCommercialCodeThumbnailIndexGetRequest
  ): Promise<runtime.ApiResponse<Blob>> {
    if (
      requestParameters.commercialCode === null ||
      requestParameters.commercialCode === undefined
    ) {
      throw new runtime.RequiredError(
        "commercialCode",
        "Required parameter requestParameters.commercialCode was null or undefined when calling apiV1CommercialsThumbnailsCommercialCodeThumbnailIndexGet."
      );
    }

    if (
      requestParameters.thumbnailIndex === null ||
      requestParameters.thumbnailIndex === undefined
    ) {
      throw new runtime.RequiredError(
        "thumbnailIndex",
        "Required parameter requestParameters.thumbnailIndex was null or undefined when calling apiV1CommercialsThumbnailsCommercialCodeThumbnailIndexGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Commercials/thumbnails/{commercialCode}/{thumbnailIndex}`
        .replace(
          `{${"commercialCode"}}`,
          encodeURIComponent(String(requestParameters.commercialCode))
        )
        .replace(
          `{${"thumbnailIndex"}}`,
          encodeURIComponent(String(requestParameters.thumbnailIndex))
        ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async apiV1CommercialsThumbnailsCommercialCodeThumbnailIndexGet(
    requestParameters: ApiV1CommercialsThumbnailsCommercialCodeThumbnailIndexGetRequest
  ): Promise<Blob> {
    const response =
      await this.apiV1CommercialsThumbnailsCommercialCodeThumbnailIndexGetRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   */
  async apiV1CommercialsTypeCommercialCodeGetRaw(
    requestParameters: ApiV1CommercialsTypeCommercialCodeGetRequest
  ): Promise<runtime.ApiResponse<Blob>> {
    if (
      requestParameters.commercialCode === null ||
      requestParameters.commercialCode === undefined
    ) {
      throw new runtime.RequiredError(
        "commercialCode",
        "Required parameter requestParameters.commercialCode was null or undefined when calling apiV1CommercialsTypeCommercialCodeGet."
      );
    }

    if (
      requestParameters.type === null ||
      requestParameters.type === undefined
    ) {
      throw new runtime.RequiredError(
        "type",
        "Required parameter requestParameters.type was null or undefined when calling apiV1CommercialsTypeCommercialCodeGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Commercials/{type}/{commercialCode}`
        .replace(
          `{${"commercialCode"}}`,
          encodeURIComponent(String(requestParameters.commercialCode))
        )
        .replace(
          `{${"type"}}`,
          encodeURIComponent(String(requestParameters.type))
        ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async apiV1CommercialsTypeCommercialCodeGet(
    requestParameters: ApiV1CommercialsTypeCommercialCodeGetRequest
  ): Promise<Blob> {
    const response =
      await this.apiV1CommercialsTypeCommercialCodeGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1CommercialsUploadPostRaw(
    requestParameters: ApiV1CommercialsUploadPostRequest
  ): Promise<runtime.ApiResponse<CommercialUploadResult>> {
    if (
      requestParameters.advertiserId === null ||
      requestParameters.advertiserId === undefined
    ) {
      throw new runtime.RequiredError(
        "advertiserId",
        "Required parameter requestParameters.advertiserId was null or undefined when calling apiV1CommercialsUploadPost."
      );
    }

    if (
      requestParameters.advertiserName === null ||
      requestParameters.advertiserName === undefined
    ) {
      throw new runtime.RequiredError(
        "advertiserName",
        "Required parameter requestParameters.advertiserName was null or undefined when calling apiV1CommercialsUploadPost."
      );
    }

    if (
      requestParameters.commercialTitle === null ||
      requestParameters.commercialTitle === undefined
    ) {
      throw new runtime.RequiredError(
        "commercialTitle",
        "Required parameter requestParameters.commercialTitle was null or undefined when calling apiV1CommercialsUploadPost."
      );
    }

    if (
      requestParameters.uploadType === null ||
      requestParameters.uploadType === undefined
    ) {
      throw new runtime.RequiredError(
        "uploadType",
        "Required parameter requestParameters.uploadType was null or undefined when calling apiV1CommercialsUploadPost."
      );
    }

    if (
      requestParameters.files === null ||
      requestParameters.files === undefined
    ) {
      throw new runtime.RequiredError(
        "files",
        "Required parameter requestParameters.files was null or undefined when calling apiV1CommercialsUploadPost."
      );
    }

    if (
      requestParameters.clickUrl === null ||
      requestParameters.clickUrl === undefined
    ) {
      throw new runtime.RequiredError(
        "clickUrl",
        "Required parameter requestParameters.clickUrl was null or undefined when calling apiV1CommercialsUploadPost."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.medium !== undefined) {
      queryParameters["medium"] = requestParameters.medium;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const consumes: runtime.Consume[] = [
      { contentType: "multipart/form-data" },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.advertiserId !== undefined) {
      formParams.append("AdvertiserId", requestParameters.advertiserId as any);
    }

    if (requestParameters.advertiserName !== undefined) {
      formParams.append(
        "AdvertiserName",
        requestParameters.advertiserName as any
      );
    }

    if (requestParameters.commercialTitle !== undefined) {
      formParams.append(
        "CommercialTitle",
        requestParameters.commercialTitle as any
      );
    }

    if (requestParameters.uploadType !== undefined) {
      formParams.append("UploadType", requestParameters.uploadType as any);
    }

    if (requestParameters.files) {
      requestParameters.files.forEach((element) => {
        formParams.append("Files", element as any);
      });
    }

    if (requestParameters.clickUrl !== undefined) {
      formParams.append("ClickUrl", requestParameters.clickUrl as any);
    }

    const response = await this.request({
      path: `/api/v1/Commercials/upload`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CommercialUploadResultFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1CommercialsUploadPost(
    requestParameters: ApiV1CommercialsUploadPostRequest
  ): Promise<CommercialUploadResult> {
    const response =
      await this.apiV1CommercialsUploadPostRaw(requestParameters);
    return await response.value();
  }
}
