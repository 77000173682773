/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  AnalysisInput,
  AnalysisInputFromJSON,
  AnalysisInputToJSON,
  AnalysisTargetGroups,
  AnalysisTargetGroupsFromJSON,
  AnalysisTargetGroupsToJSON,
  GenericAnalysisInput,
  GenericAnalysisInputFromJSON,
  GenericAnalysisInputToJSON,
  MediumEnum,
  MediumEnumFromJSON,
  MediumEnumToJSON,
  OrderListItem,
  OrderListItemFromJSON,
  OrderListItemToJSON,
  ProblemDetails,
  ProblemDetailsFromJSON,
  ProblemDetailsToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface ApiV1CampaignAnalysisAnalysisGenericPostRequest {
  genericAnalysisInput: GenericAnalysisInput;
  medium: MediumEnum;
}

export interface ApiV1CampaignAnalysisAnalysisPostRequest {
  medium: MediumEnum;
  orderId: number;
  analysisInput?: AnalysisInput;
}

export interface ApiV1CampaignAnalysisRelatedMediumOrderIdGetRequest {
  medium: MediumEnum;
  orderId: number;
}

export interface ApiV1CampaignAnalysisUploadFilePostRequest {
  files: Array<Blob>;
  medium: string;
  year: number;
  advertiser?: string;
}

/**
 *
 */
export class CampaignAnalysisApi extends runtime.BaseAPI {
  /**
   */
  async apiV1CampaignAnalysisAnalysisGenericPostRaw(
    requestParameters: ApiV1CampaignAnalysisAnalysisGenericPostRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.genericAnalysisInput === null ||
      requestParameters.genericAnalysisInput === undefined
    ) {
      throw new runtime.RequiredError(
        "genericAnalysisInput",
        "Required parameter requestParameters.genericAnalysisInput was null or undefined when calling apiV1CampaignAnalysisAnalysisGenericPost."
      );
    }

    if (
      requestParameters.medium === null ||
      requestParameters.medium === undefined
    ) {
      throw new runtime.RequiredError(
        "medium",
        "Required parameter requestParameters.medium was null or undefined when calling apiV1CampaignAnalysisAnalysisGenericPost."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.medium !== undefined) {
      queryParameters["medium"] = requestParameters.medium;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/CampaignAnalysis/analysis/generic`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: GenericAnalysisInputToJSON(requestParameters.genericAnalysisInput),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async apiV1CampaignAnalysisAnalysisGenericPost(
    requestParameters: ApiV1CampaignAnalysisAnalysisGenericPostRequest
  ): Promise<void> {
    await this.apiV1CampaignAnalysisAnalysisGenericPostRaw(requestParameters);
  }

  /**
   */
  async apiV1CampaignAnalysisAnalysisPostRaw(
    requestParameters: ApiV1CampaignAnalysisAnalysisPostRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.medium === null ||
      requestParameters.medium === undefined
    ) {
      throw new runtime.RequiredError(
        "medium",
        "Required parameter requestParameters.medium was null or undefined when calling apiV1CampaignAnalysisAnalysisPost."
      );
    }

    if (
      requestParameters.orderId === null ||
      requestParameters.orderId === undefined
    ) {
      throw new runtime.RequiredError(
        "orderId",
        "Required parameter requestParameters.orderId was null or undefined when calling apiV1CampaignAnalysisAnalysisPost."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.medium !== undefined) {
      queryParameters["medium"] = requestParameters.medium;
    }

    if (requestParameters.orderId !== undefined) {
      queryParameters["orderId"] = requestParameters.orderId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/CampaignAnalysis/analysis`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: AnalysisInputToJSON(requestParameters.analysisInput),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async apiV1CampaignAnalysisAnalysisPost(
    requestParameters: ApiV1CampaignAnalysisAnalysisPostRequest
  ): Promise<void> {
    await this.apiV1CampaignAnalysisAnalysisPostRaw(requestParameters);
  }

  /**
   */
  async apiV1CampaignAnalysisAnalysistargetgroupsGetRaw(): Promise<
    runtime.ApiResponse<AnalysisTargetGroups>
  > {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/CampaignAnalysis/analysistargetgroups`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AnalysisTargetGroupsFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1CampaignAnalysisAnalysistargetgroupsGet(): Promise<AnalysisTargetGroups> {
    const response =
      await this.apiV1CampaignAnalysisAnalysistargetgroupsGetRaw();
    return await response.value();
  }

  /**
   */
  async apiV1CampaignAnalysisHasTVCampaignOnNMOSwitchGetRaw(): Promise<
    runtime.ApiResponse<boolean>
  > {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/CampaignAnalysis/hasTVCampaignOnNMOSwitch`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   */
  async apiV1CampaignAnalysisHasTVCampaignOnNMOSwitchGet(): Promise<boolean> {
    const response =
      await this.apiV1CampaignAnalysisHasTVCampaignOnNMOSwitchGetRaw();
    return await response.value();
  }

  /**
   */
  async apiV1CampaignAnalysisRelatedMediumOrderIdGetRaw(
    requestParameters: ApiV1CampaignAnalysisRelatedMediumOrderIdGetRequest
  ): Promise<runtime.ApiResponse<Array<OrderListItem>>> {
    if (
      requestParameters.medium === null ||
      requestParameters.medium === undefined
    ) {
      throw new runtime.RequiredError(
        "medium",
        "Required parameter requestParameters.medium was null or undefined when calling apiV1CampaignAnalysisRelatedMediumOrderIdGet."
      );
    }

    if (
      requestParameters.orderId === null ||
      requestParameters.orderId === undefined
    ) {
      throw new runtime.RequiredError(
        "orderId",
        "Required parameter requestParameters.orderId was null or undefined when calling apiV1CampaignAnalysisRelatedMediumOrderIdGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/CampaignAnalysis/related/{medium}/{orderId}`
        .replace(
          `{${"medium"}}`,
          encodeURIComponent(String(requestParameters.medium))
        )
        .replace(
          `{${"orderId"}}`,
          encodeURIComponent(String(requestParameters.orderId))
        ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(OrderListItemFromJSON)
    );
  }

  /**
   */
  async apiV1CampaignAnalysisRelatedMediumOrderIdGet(
    requestParameters: ApiV1CampaignAnalysisRelatedMediumOrderIdGetRequest
  ): Promise<Array<OrderListItem>> {
    const response =
      await this.apiV1CampaignAnalysisRelatedMediumOrderIdGetRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   */
  async apiV1CampaignAnalysisUploadFilePostRaw(
    requestParameters: ApiV1CampaignAnalysisUploadFilePostRequest
  ): Promise<runtime.ApiResponse<Blob>> {
    if (
      requestParameters.files === null ||
      requestParameters.files === undefined
    ) {
      throw new runtime.RequiredError(
        "files",
        "Required parameter requestParameters.files was null or undefined when calling apiV1CampaignAnalysisUploadFilePost."
      );
    }

    if (
      requestParameters.medium === null ||
      requestParameters.medium === undefined
    ) {
      throw new runtime.RequiredError(
        "medium",
        "Required parameter requestParameters.medium was null or undefined when calling apiV1CampaignAnalysisUploadFilePost."
      );
    }

    if (
      requestParameters.year === null ||
      requestParameters.year === undefined
    ) {
      throw new runtime.RequiredError(
        "year",
        "Required parameter requestParameters.year was null or undefined when calling apiV1CampaignAnalysisUploadFilePost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const consumes: runtime.Consume[] = [
      { contentType: "multipart/form-data" },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.files) {
      requestParameters.files.forEach((element) => {
        formParams.append("Files", element as any);
      });
    }

    if (requestParameters.medium !== undefined) {
      formParams.append("Medium", requestParameters.medium as any);
    }

    if (requestParameters.advertiser !== undefined) {
      formParams.append("Advertiser", requestParameters.advertiser as any);
    }

    if (requestParameters.year !== undefined) {
      formParams.append("Year", requestParameters.year as any);
    }

    const response = await this.request({
      path: `/api/v1/CampaignAnalysis/uploadFile`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async apiV1CampaignAnalysisUploadFilePost(
    requestParameters: ApiV1CampaignAnalysisUploadFilePostRequest
  ): Promise<Blob> {
    const response =
      await this.apiV1CampaignAnalysisUploadFilePostRaw(requestParameters);
    return await response.value();
  }
}
