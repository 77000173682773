import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Container, Typography } from "@ster/ster-toolkit";
import { List } from "antd";

import styles from "./Rates.module.less";

const rateLinks = [
  {
    label: msg`Algemene inkoopinformatie`,
    link: "https://www.ster.nl/inkoopinformatie/",
  },
  {
    label: msg`Inkoopopties`,
    link: "https://ster.nl/inkoopopties-ster-reclame/",
  },
  {
    label: msg`Inkoopvoordelen 2024`,
    link: "https://www.ster.nl/inkoopinformatie/voordelen-2024/",
  },
  {
    label: msg`Inkoopvoordelen 2025`,
    link: "https://ster.nl/inkoopinformatie/voordelen-2025/",
  },
  {
    label: msg`Tariefinformatie radio`,
    link: "https://www.ster.nl/tarieven-radioreclame/",
  },
  {
    label: msg`Tariefinformatie televisie`,
    link: "https://www.ster.nl/tarieven-televisiereclame/",
  },
  {
    label: msg`Tariefinformatie online`,
    link: "https://www.ster.nl/tarieven-online-reclame/",
  },
];

const Rates = () => {
  const { i18n } = useLingui();
  return (
    <Container>
      <Typography.Title level={2}>
        <Trans>Tarieven</Trans>
      </Typography.Title>
      <List
        className={styles.rates}
        dataSource={rateLinks}
        renderItem={({ label, link }) => (
          <List.Item className={styles.rates}>
            <Typography.Text>
              <a href={link} target="_blank" rel="noopener noreferrer">
                {i18n._(label)}
              </a>
            </Typography.Text>
          </List.Item>
        )}
      />
    </Container>
  );
};

export default Rates;
