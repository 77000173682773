/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum RunningState {
  Unkown = "Unkown",
  Forecast = "Forecast",
  Combination = "Combination",
  Realization = "Realization",
}

export function RunningStateFromJSON(json: any): RunningState {
  return RunningStateFromJSONTyped(json, false);
}

export function RunningStateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RunningState {
  return json as RunningState;
}

export function RunningStateToJSON(value?: RunningState | null): any {
  return value as any;
}
