import "./mediumicon.less";

import { Trans } from "@lingui/macro";
import { Icons } from "@ster/ster-toolkit";

import { MediumEnum } from "../../../api";

interface MediumIconProps {
  medium: MediumEnum;
}

const MediumIcon = ({ medium }: MediumIconProps) => {
  const { OnlineIcon, RadioIcon, TVIcon } = Icons;

  switch (medium) {
    case "inter":
      return (
        <span className="medium-icon">
          <OnlineIcon width={24} height={24} fill="#000" />
          <span className="medium-label">
            <Trans>Online</Trans>
          </span>
        </span>
      );
    case "radio":
      return (
        <span className="medium-icon">
          <RadioIcon width={24} height={24} fill="#000" />
          <span className="medium-label">
            <Trans>Radio</Trans>
          </span>
        </span>
      );
    case "tv":
      return (
        <span className="medium-icon">
          <TVIcon width={24} height={24} fill="#000" />
          <span className="medium-label">
            <Trans>Televisie</Trans>
          </span>
        </span>
      );
    default:
      return null;
  }
};

export default MediumIcon;
