import { SagaIterator } from "redux-saga";
import { all, takeEvery, takeLeading } from "redux-saga/effects";

import {
  loadOperationalContextStateAction,
  logoutMeAction,
  receiveMeAction,
  setOperationalContextStateAction,
} from "./account/actions";
import {
  checkAndSetCurrentOperationalContextStateSaga,
  logoutMe,
  receiveMe,
  setOperationalContextStateSaga,
} from "./account/sagas";
import {
  getEditableSettingsAction,
  updateEditableSettingAction,
} from "./admin/actions";
import { getEditableSettings, updateEditableSetting } from "./admin/sagas";
import { receiveAdvertisersAction } from "./advertisers/actions";
import { receiveAdvertisers } from "./advertisers/sagas";
import {
  exportSpotTrackerAction,
  receiveSpotTrackerAction,
} from "./analysis/actions";
import { exportSpotTracker, receiveSpotTracker } from "./analysis/sagas";
import {
  deleteAnnouncementAction,
  receiveAnnouncementsAction,
  receivePublishedAnnouncementsAction,
  upsertAnnouncementAction,
} from "./announcements/actions";
import {
  deleteAnnouncement,
  receiveAnnouncements,
  receivePublishedAnnouncements,
  upsertAnnouncement,
} from "./announcements/sagas";
import { bookSpotAction, receiveBreaksAction } from "./breakselect/actions";
import { AvailableBreakRequestPayload } from "./breakselect/models";
import { bookSpot, receiveBreaks } from "./breakselect/sagas";
import { getAnalysisNmoNotificationAction } from "./campaignAnalysis/actions";
import { getAnalysisNmoNotification } from "./campaignAnalysis/sagas";
import {
  analysisUploadFileAction,
  campaignConfigurationFetchAction,
  finalizeRequestAction,
  importConfigurationAction,
  proposalGenerateAction,
  proposalUploadForecastAction,
  receiveBookingDateAction,
  receiveCalculationAction,
  receiveContextTargetsAction,
  receiveCustomerAccountsAction,
  receiveCustomerContactsAction,
  receiveCustomerOpportunitiesAction,
  receiveForecastAction,
  receiveForecastConversionGroupsAction,
  receiveGroupedPackagesRadioAction,
  receiveGroupedPackagesTvAction,
  receiveInitialRequestAction,
  receivePackagesAction,
  receiveProductsAction,
  receiveProgramsAction,
  receiveSalesPeriodsAction,
  receiveSitesAction,
  receiveSpotIndexesAction,
  receiveTargetGroupsAction,
  saveRequestAction,
} from "./campaignCreate/actions";
import {
  CalculationRequestPayload,
  InitialRequestRequestPayload,
} from "./campaignCreate/models";
import {
  analysisUploadFile,
  campaignConfigurationFetch,
  finalizeRequest,
  importConfiguration,
  proposalGenerate,
  proposalUploadForecast,
  receiveBookingDate,
  receiveCalculation,
  receiveContextTargets,
  receiveCustomerAccounts,
  receiveCustomerContacts,
  receiveCustomerOpportunities,
  receiveForecast,
  receiveForecastConversionGroups,
  receiveGroupedPackagesRadio,
  receiveGroupedPackagesTv,
  receiveInitialRequest,
  receivePackages,
  receiveProducts,
  receivePrograms,
  receiveSalesPeriods,
  receiveSites,
  receiveSpotIndexes,
  receiveTargetGroups,
  saveRequest,
} from "./campaignCreate/sagas";
import {
  deleteInstructionAction,
  receiveCampaignAnalysisTargetGroupsAction,
  receiveCampaignDetailAction,
  receiveCampaignDetailWithSpotsAction,
  receiveCampaignExportAction,
  receiveCampaignInstructionsAction,
  receiveCampaignSpotsAction,
  receiveCampaignSpotsDownloadAction,
  requestCampaignAnalysisAction,
  requestCampaignAnalysisGenericAction,
  requestRelatedCampaignsAction,
  saveCampaignInstructionsAction,
  saveCampaignInstructionsOnlineAction,
  saveMultiCampaignInstructionsAction,
  validateCampaignAction,
} from "./campaignDetail/actions";
import {
  deleteInstruction,
  receiveCampaignAnalysisTargetGroups,
  receiveCampaignDetail,
  receiveCampaignDetailWithSpots,
  receiveCampaignExport,
  receiveCampaignInstructions,
  receiveCampaignSpots,
  receiveCampaignSpotsDownload,
  requestCampaignAnalysis,
  requestCampaignAnalysisGeneric,
  requestRelatedCampaigns,
  saveCampaignInstructions,
  saveCampaignInstructionsOnline,
  saveMultiCampaignInstructions,
  validateCampaign,
} from "./campaignDetail/sagas";
import {
  receiveCampaignInstructionsOnlineAction,
  receiveEditableCampaignAction,
  updateCampaignAction,
} from "./campaignEdit/actions";
import {
  receiveCampaignInstructionsOnline,
  receiveEditableCampaign,
  updateCampaign,
} from "./campaignEdit/sagas";
import {
  acceptAvailableCampaignAction,
  forwardCampaignAction,
  receiveAvailableCampaignDetailAction,
  receiveAvailableCampaignsAction,
  receiveContactsForForwardAction,
  receiveProductsForForwardAction,
  rejectAvailableCampaignAction,
} from "./campaignForward/actions";
import {
  acceptAvailableCampaign,
  forwardCampaign,
  receiveAvailableCampaignDetail,
  receiveAvailableCampaigns,
  receiveContactsForForward,
  receiveProductsForForward,
  rejectAvailableCampaigns,
} from "./campaignForward/sagas";
import {
  copyCampaignAction,
  deleteCampaignAction,
  receiveCampaignsAction,
} from "./campaigns/actions";
import {
  copyCampaign,
  deleteCampaign,
  receiveCampaigns,
} from "./campaigns/sagas";
import {
  initNpoPlayerAction,
  reactivateCommercialAction,
  receiveCommercialsAction,
  receiveCommercialsForAuditAction,
  receiveCommercialsForProductIdAction,
  updateCommercialRegistrationStatusAction,
  uploadCommercialAction,
} from "./commercials/actions";
import {
  initNpoPlayerUrl,
  reactivateCommercial,
  receiveCommercials,
  receiveCommercialsForAudit,
  receiveCommercialsForProductId,
  updateCommercialRegistrationStatus,
  uploadCommercial,
} from "./commercials/sagas";
import {
  receiveCurrentSpotsAction,
  receiveTipsAction,
} from "./dashboard/actions";
import { receiveCurrentSpots, receiveTips } from "./dashboard/sagas";
import { receiveDocumentsAction } from "./documents/actions";
import { receiveDocuments } from "./documents/sagas";
import { receiveSecondaryTargetGroupsAction } from "./generic/actions";
import { receiveSecondaryTargetGroups } from "./generic/sagas";
import { receiveInvoicesAction } from "./invoices/actions";
import { receiveInvoices } from "./invoices/sagas";
import { receiveOrganisationsAction } from "./organisations/actions";
import { receiveOrganisations } from "./organisations/sagas";
import {
  postProductTourAction,
  receiveProductTourAction,
} from "./productTour/actions";
import { postProductTour, receiveProductTour } from "./productTour/sagas";
import { receiveProposalDataAction } from "./proposal/actions";
import { receiveProposalData } from "./proposal/sagas";
import { receiveSettingsAction } from "./settings/actions";
import { receiveSettings } from "./settings/sagas";
import { postTermsAction, receiveTermsAction } from "./terms/actions";
import { postTerms, receiveTerms } from "./terms/sagas";
import {
  createUserAction,
  deleteUserAction,
  receiveUserAction,
  receiveUsersAction,
  receiveUsersExportAction,
  updateUserAction,
} from "./users/actions";
import {
  createUser,
  deleteUser,
  receiveUser,
  receiveUsers,
  receiveUsersExport,
  updateUser,
} from "./users/sagas";
import { takeLatestPerKey, takeLeadingPerKey } from "./utils";

function id({ payload }: { payload: number }) {
  return payload;
}

function selectSubOrderId({
  payload: { subOrderId },
}: {
  payload: CalculationRequestPayload;
}) {
  return subOrderId;
}

function selectId({
  payload: { initialRequestId },
}: {
  payload: InitialRequestRequestPayload;
}) {
  return initialRequestId;
}

function keyId({
  payload: { key },
}: {
  payload: AvailableBreakRequestPayload;
}) {
  return key;
}

function* sagas(): SagaIterator {
  yield all([
    takeLeading(bookSpotAction.request, bookSpot),
    takeLeading(
      campaignConfigurationFetchAction.request,
      campaignConfigurationFetch
    ),
    takeLeading(createUserAction.request, createUser),
    takeLeading(deleteAnnouncementAction.request, deleteAnnouncement),
    takeLeading(deleteCampaignAction.request, deleteCampaign),
    takeLeading(deleteInstructionAction.request, deleteInstruction),
    takeLeading(deleteUserAction.request, deleteUser),
    takeLeading(finalizeRequestAction.request, finalizeRequest),
    takeLeading(importConfigurationAction.request, importConfiguration),
    takeLeading(initNpoPlayerAction.request, initNpoPlayerUrl),
    takeLeading(logoutMeAction.request, logoutMe),
    takeLeading(postProductTourAction.request, postProductTour),
    takeLeading(postTermsAction.request, postTerms),
    takeLeading(proposalGenerateAction.request, proposalGenerate),
    takeLeading(proposalUploadForecastAction.request, proposalUploadForecast),
    takeLeading(reactivateCommercialAction.request, reactivateCommercial),
    takeLeading(receiveAdvertisersAction.request, receiveAdvertisers),
    takeLeading(receiveAnnouncementsAction.request, receiveAnnouncements),
    takeLeading(receiveBookingDateAction.request, receiveBookingDate),
    takeLeadingPerKey(receiveBreaksAction.request, receiveBreaks, keyId),
    takeLeading(receiveCampaignDetailAction.request, receiveCampaignDetail),
    takeLeading(
      receiveCampaignDetailWithSpotsAction.request,
      receiveCampaignDetailWithSpots
    ),
    takeLeading(receiveCampaignExportAction.request, receiveCampaignExport),
    takeLeading(
      receiveCampaignInstructionsAction.request,
      receiveCampaignInstructions
    ),
    takeLeading(receiveCampaignSpotsAction.request, receiveCampaignSpots),
    takeLeading(
      receiveCampaignSpotsDownloadAction.request,
      receiveCampaignSpotsDownload
    ),
    takeLeading(receiveCampaignsAction.request, receiveCampaigns),
    takeLeading(receiveCommercialsAction.request, receiveCommercials),
    takeLeading(
      receiveCommercialsForAuditAction.request,
      receiveCommercialsForAudit
    ),
    takeLeading(
      receiveCommercialsForProductIdAction.request,
      receiveCommercialsForProductId
    ),
    takeLeading(receiveContextTargetsAction.request, receiveContextTargets),
    takeLeading(receiveCurrentSpotsAction.request, receiveCurrentSpots),
    takeLeading(receiveDocumentsAction.request, receiveDocuments),
    takeLeading(receiveForecastAction.request, receiveForecast),
    takeLeading(
      receiveForecastConversionGroupsAction.request,
      receiveForecastConversionGroups
    ),
    takeLeading(receiveProposalDataAction.request, receiveProposalData),
    takeLeading(
      receiveGroupedPackagesRadioAction.request,
      receiveGroupedPackagesRadio
    ),
    takeLeading(
      receiveGroupedPackagesTvAction.request,
      receiveGroupedPackagesTv
    ),
    takeLeadingPerKey(
      receiveInitialRequestAction.request,
      receiveInitialRequest,
      selectId
    ),
    takeLeading(receiveInvoicesAction.request, receiveInvoices),
    takeLeading(receiveMeAction.request, receiveMe),
    takeLeading(receiveOrganisationsAction.request, receiveOrganisations),
    takeLeading(receivePackagesAction.request, receivePackages),
    takeLeading(receiveProductTourAction.request, receiveProductTour),
    takeLeading(receiveProductsAction.request, receiveProducts),
    takeLeading(receiveProgramsAction.request, receivePrograms),
    takeLeading(
      receivePublishedAnnouncementsAction.request,
      receivePublishedAnnouncements
    ),
    takeLeading(receiveSalesPeriodsAction.request, receiveSalesPeriods),
    takeLeading(
      receiveSecondaryTargetGroupsAction.request,
      receiveSecondaryTargetGroups
    ),
    takeLeading(receiveSettingsAction.request, receiveSettings),
    takeLeading(receiveSitesAction.request, receiveSites),
    takeLeading(receiveTargetGroupsAction.request, receiveTargetGroups),
    takeLeading(receiveSpotIndexesAction.request, receiveSpotIndexes),
    takeLeading(receiveTermsAction.request, receiveTerms),
    takeLeading(receiveUserAction.request, receiveUser),
    takeLeading(receiveUsersAction.request, receiveUsers),
    takeLeading(receiveUsersExportAction.request, receiveUsersExport),
    takeLeading(
      saveCampaignInstructionsAction.request,
      saveCampaignInstructions
    ),
    takeLeading(
      saveMultiCampaignInstructionsAction.request,
      saveMultiCampaignInstructions
    ),
    takeLeading(saveRequestAction.request, saveRequest),
    takeEvery(
      updateCommercialRegistrationStatusAction.request,
      updateCommercialRegistrationStatus
    ),
    takeLeading(updateUserAction.request, updateUser),
    takeLeading(upsertAnnouncementAction.request, upsertAnnouncement),
    takeLeading(validateCampaignAction.request, validateCampaign),
    takeLeading(receiveCustomerAccountsAction.request, receiveCustomerAccounts),
    takeLeading(
      receiveCustomerOpportunitiesAction.request,
      receiveCustomerOpportunities
    ),
    takeLeading(requestCampaignAnalysisAction.request, requestCampaignAnalysis),
    takeLeading(
      requestCampaignAnalysisGenericAction.request,
      requestCampaignAnalysisGeneric
    ),
    takeLeading(
      receiveCampaignAnalysisTargetGroupsAction.request,
      receiveCampaignAnalysisTargetGroups
    ),
    takeLeading(uploadCommercialAction.request, uploadCommercial),
    takeLeading(
      receiveContactsForForwardAction.request,
      receiveContactsForForward
    ),
    takeLeading(
      receiveProductsForForwardAction.request,
      receiveProductsForForward
    ),
    takeLeading(forwardCampaignAction.request, forwardCampaign),
    takeLeading(updateCampaignAction.request, updateCampaign),
    takeLeading(
      receiveAvailableCampaignsAction.request,
      receiveAvailableCampaigns
    ),
    takeLeading(acceptAvailableCampaignAction.request, acceptAvailableCampaign),
    takeLeading(
      rejectAvailableCampaignAction.request,
      rejectAvailableCampaigns
    ),
    takeLeadingPerKey(
      receiveAvailableCampaignDetailAction.request,
      receiveAvailableCampaignDetail,
      id
    ),
    takeLeading(
      receiveCampaignInstructionsOnlineAction.request,
      receiveCampaignInstructionsOnline
    ),
    takeLeading(copyCampaignAction.request, copyCampaign),
    takeLeading(receiveCustomerContactsAction.request, receiveCustomerContacts),
    takeLeading(
      saveCampaignInstructionsOnlineAction.request,
      saveCampaignInstructionsOnline
    ),
    takeLatestPerKey(
      receiveCalculationAction.request,
      receiveCalculation,
      selectSubOrderId
    ),
    takeLeading(requestRelatedCampaignsAction.request, requestRelatedCampaigns),
    takeLeading(receiveEditableCampaignAction.request, receiveEditableCampaign),
    takeLeading(getEditableSettingsAction.request, getEditableSettings),
    takeLeading(updateEditableSettingAction.request, updateEditableSetting),
    takeLeading(
      getAnalysisNmoNotificationAction.request,
      getAnalysisNmoNotification
    ),
    takeLeading(analysisUploadFileAction.request, analysisUploadFile),
    takeLeading(receiveSpotTrackerAction.request, receiveSpotTracker),
    takeLeading(exportSpotTrackerAction.request, exportSpotTracker),
    takeLeading(receiveTipsAction.request, receiveTips),

    takeLeading(
      setOperationalContextStateAction,
      setOperationalContextStateSaga
    ),
    takeLeading(
      loadOperationalContextStateAction,
      checkAndSetCurrentOperationalContextStateSaga
    ),
  ]);
}

export default sagas;
