/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CalculationResult
 */
export interface CalculationResult {
  /**
   *
   * @type {number}
   * @memberof CalculationResult
   */
  budget?: number;
  /**
   *
   * @type {number}
   * @memberof CalculationResult
   */
  grp?: number;
  /**
   *
   * @type {number}
   * @memberof CalculationResult
   */
  spotsPerDay?: number;
  /**
   *
   * @type {number}
   * @memberof CalculationResult
   */
  spotsPerDayChannel?: number;
  /**
   *
   * @type {number}
   * @memberof CalculationResult
   */
  spots?: number;
  /**
   *
   * @type {number}
   * @memberof CalculationResult
   */
  impressions?: number;
}

export function CalculationResultFromJSON(json: any): CalculationResult {
  return CalculationResultFromJSONTyped(json, false);
}

export function CalculationResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CalculationResult {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    budget: !exists(json, "budget") ? undefined : json["budget"],
    grp: !exists(json, "grp") ? undefined : json["grp"],
    spotsPerDay: !exists(json, "spotsPerDay") ? undefined : json["spotsPerDay"],
    spotsPerDayChannel: !exists(json, "spotsPerDayChannel")
      ? undefined
      : json["spotsPerDayChannel"],
    spots: !exists(json, "spots") ? undefined : json["spots"],
    impressions: !exists(json, "impressions") ? undefined : json["impressions"],
  };
}

export function CalculationResultToJSON(value?: CalculationResult | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    budget: value.budget,
    grp: value.grp,
    spotsPerDay: value.spotsPerDay,
    spotsPerDayChannel: value.spotsPerDayChannel,
    spots: value.spots,
    impressions: value.impressions,
  };
}
