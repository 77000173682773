import {
  createStructuredAppSelector,
  operationalContextStateSelector,
} from "../../shared/selectors";

export const rootSelectors = createStructuredAppSelector({
  operationalContextState: operationalContextStateSelector,
});

export default rootSelectors;
