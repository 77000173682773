import { LineChartOutlined } from "@ant-design/icons";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  Form,
  Icons,
  Modal,
  Option,
  Select,
  Typography,
} from "@ster/ster-toolkit";
import { Form as AntForm, Dropdown, Upload } from "antd";
import { RcFile } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Advertiser, MediumEnum } from "../../api";
import {
  analysisUploadFileAction,
  proposalUploadForecastAction,
} from "../../store/campaignCreate/actions";
import { internalAdverteerder } from "../../utils/constants";
import styles from "./GenerateFormattedDocument.module.less";
import { generateFormattedDocumentRootSelector } from "./selectors";

// eslint-disable-next-line no-shadow
export enum UploadDocumentType {
  Proposal = "proposal",
  Analysis = "analysis",
}

const GenerateFormattedDocument = memo(
  ({
    advertisers,
    organisation,
  }: {
    advertisers: Advertiser[];
    organisation: string;
  }) => {
    const { i18n } = useLingui();
    const dispatch = useDispatch();
    const [form] = AntForm.useForm();
    const [documentType, setDocumentType] = useState<UploadDocumentType>(
      UploadDocumentType.Proposal
    );

    const [selectedDocuments, setSelectedDocuments] = useState<RcFile[]>();
    const [showModal, setShowModal] = useState<boolean>(false);

    const { loading } = useSelector(generateFormattedDocumentRootSelector);

    const handleUpload = useCallback((file: RcFile) => {
      setSelectedDocuments((documents) => [...(documents ?? []), file]);
      return false;
    }, []);

    const handleRemoveUpload = useCallback(
      (file: UploadFile) => {
        setSelectedDocuments(
          selectedDocuments?.filter((s) => s.uid !== file.uid)
        );
        return false;
      },
      [selectedDocuments]
    );

    const changeModalVisible = useCallback(() => {
      if (showModal) {
        form.setFieldValue("medium", "tv");
        setSelectedDocuments(undefined);
      }
      setShowModal(!showModal);
    }, [form, showModal]);

    const changeProposalModalVisible = useCallback(() => {
      setDocumentType(UploadDocumentType.Proposal);
      changeModalVisible();
    }, [changeModalVisible]);

    const changeAnalysisModalVisible = useCallback(() => {
      setDocumentType(UploadDocumentType.Analysis);
      changeModalVisible();
    }, [changeModalVisible]);

    const requestProposal = useCallback(() => {
      form.validateFields();
      const formValues = form.getFieldsValue();
      if (!formValues?.year || !selectedDocuments) {
        return;
      }

      if (documentType === UploadDocumentType.Proposal) {
        dispatch(
          proposalUploadForecastAction.request({
            files: selectedDocuments,
            medium: formValues?.medium ?? MediumEnum.Tv,
            advertiser: formValues?.advertiser ?? "",
            year: formValues.year,
          })
        );
      } else {
        dispatch(
          analysisUploadFileAction.request({
            files: selectedDocuments,
            medium: formValues?.medium ?? MediumEnum.Tv,
            advertiser: formValues?.advertiser ?? "",
            year: formValues.year,
          })
        );
      }
    }, [dispatch, documentType, form, selectedDocuments]);

    return (
      <>
        <Dropdown
          overlayClassName="more-menu"
          menu={{
            items: [
              {
                label: <Trans>Prognose</Trans>,
                key: UploadDocumentType.Proposal,
                icon: <Icons.PdfIcon width={20} height={20} />,
                className: "more-menu-item",
                onClick: changeProposalModalVisible,
              },
              {
                label: <Trans>Analyse</Trans>,
                key: UploadDocumentType.Analysis,
                icon: (
                  <LineChartOutlined
                    width={40}
                    height={40}
                    className={styles.analyseIcon}
                  />
                ),
                className: "more-menu-item",
                onClick: changeAnalysisModalVisible,
              },
            ],
          }}
        >
          <Button mode="tertiary" className={styles.button}>
            <Icons.PdfIcon width={20} height={20} />{" "}
            <Trans>Opgemaakt bestand</Trans> <Icons.ChevronDownIcon />
          </Button>
        </Dropdown>
        <Modal
          open={showModal}
          title={
            documentType === UploadDocumentType.Proposal
              ? i18n._(t`Voorstel genereren vanuit prognose`)
              : i18n._(t`Analyse genereren`)
          }
          onCancel={changeModalVisible}
          cancelText={i18n._(t`Annuleren`)}
          okText={i18n._(t`Bestand genereren`)}
          onOk={requestProposal}
          confirmLoading={loading}
          className={styles.modal}
        >
          <Typography.Paragraph>
            {documentType === UploadDocumentType.Proposal
              ? i18n._(
                  t`Genereer een voorstel vanuit 1 of meer aangeleverde prognoses.`
                )
              : i18n._(
                  t`Genereer een analyse vanuit 1 of meer aangeleverde bestanden.`
                )}
          </Typography.Paragraph>
          <Typography.Paragraph>
            <Form
              form={form}
              className={styles.form}
              layout="horizontal"
              initialValues={{
                medium: MediumEnum.Tv,
                advertiser: "",
                year: 2024,
              }}
            >
              <Form.Item
                className={styles.advertiser}
                name="advertiser"
                label={i18n._(t`Adverteerder`)}
              >
                <Select.Search
                  optionFilterProp="children"
                  disabled={organisation === internalAdverteerder}
                >
                  <Option value="" key={0}>
                    <Trans>Prospect</Trans>
                  </Option>
                  {advertisers?.map((advertiser) => (
                    <Option value={advertiser.name} key={advertiser.id}>
                      {advertiser.name}
                    </Option>
                  ))}
                </Select.Search>
              </Form.Item>
              <Form.Item label={i18n._(t`Medium`)} name="medium">
                <Select
                  className={styles.medium}
                  options={[
                    {
                      label: <Trans>Televisie</Trans>,
                      value: MediumEnum.Tv,
                    },
                    {
                      label: <Trans>Radio</Trans>,
                      value: MediumEnum.Radio,
                    },
                    {
                      label: <Trans>Online</Trans>,
                      value: MediumEnum.Inter,
                      disabled: documentType === UploadDocumentType.Analysis,
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label={i18n._(t`Jaar`)}
                name="year"
                rules={[
                  {
                    required: true,
                    message: i18n._(t`Kies een jaar`),
                  },
                ]}
                className={styles.year}
              >
                <Select
                  options={[
                    {
                      label: "2024",
                      value: 2024,
                    },
                    ...(documentType === UploadDocumentType.Proposal
                      ? [
                          {
                            label: "2025",
                            value: 2025,
                          },
                        ]
                      : []),
                  ]}
                />
              </Form.Item>
              <Form.Item label={i18n._(t`Prognoses`)} valuePropName="fileList">
                <Upload
                  accept=".pdf"
                  beforeUpload={handleUpload}
                  onRemove={handleRemoveUpload}
                  fileList={selectedDocuments}
                  showUploadList
                  multiple
                  className={styles.upload}
                >
                  <Button mode="tertiary" icon={<Icons.UploadIcon />}>
                    {i18n._(t`Upload bestanden...`)}
                  </Button>
                </Upload>
              </Form.Item>
            </Form>
          </Typography.Paragraph>
        </Modal>
      </>
    );
  }
);

export default GenerateFormattedDocument;
