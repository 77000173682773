/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface TipDto
 */
export interface TipDto {
  /**
   *
   * @type {string}
   * @memberof TipDto
   */
  alias: string;
  /**
   *
   * @type {string}
   * @memberof TipDto
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof TipDto
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof TipDto
   */
  cssClassName: string;
  /**
   *
   * @type {number}
   * @memberof TipDto
   */
  sortOrder: number;
  /**
   *
   * @type {string}
   * @memberof TipDto
   */
  imageUrl?: string | null;
  /**
   *
   * @type {Date}
   * @memberof TipDto
   */
  startDate?: Date | null;
  /**
   *
   * @type {Date}
   * @memberof TipDto
   */
  endDate?: Date | null;
}

export function TipDtoFromJSON(json: any): TipDto {
  return TipDtoFromJSONTyped(json, false);
}

export function TipDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TipDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    alias: json["alias"],
    title: json["title"],
    description: json["description"],
    cssClassName: json["cssClassName"],
    sortOrder: json["sortOrder"],
    imageUrl: !exists(json, "imageUrl") ? undefined : json["imageUrl"],
    startDate: !exists(json, "startDate")
      ? undefined
      : json["startDate"] === null
        ? null
        : new Date(json["startDate"]),
    endDate: !exists(json, "endDate")
      ? undefined
      : json["endDate"] === null
        ? null
        : new Date(json["endDate"]),
  };
}

export function TipDtoToJSON(value?: TipDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    alias: value.alias,
    title: value.title,
    description: value.description,
    cssClassName: value.cssClassName,
    sortOrder: value.sortOrder,
    imageUrl: value.imageUrl,
    startDate:
      value.startDate === undefined
        ? undefined
        : value.startDate === null
          ? null
          : value.startDate.toISOString(),
    endDate:
      value.endDate === undefined
        ? undefined
        : value.endDate === null
          ? null
          : value.endDate.toISOString(),
  };
}
