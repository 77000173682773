import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Alert } from "antd";
import { memo, useMemo } from "react";

import { Advertiser, MediumEnum, OrderRequest } from "../../../api";
import {
  getMediumIcon,
  getMediumName,
  getVolumeDiscountTranslation,
} from "../../../utils";
import { getFormattedDate } from "../../../utils/dateHelper";
import { FormReadOnlyItem } from "../../partials/Form";
import styles from "./Details.module.less";

const DetailsReadPeriodAdvertiser = memo(
  ({
    order,
    productsByAdvertiser,
    showDigitalReportWarning,
  }: {
    productsByAdvertiser: Advertiser[];
    order: OrderRequest | undefined;
    showDigitalReportWarning: boolean;
  }) => {
    const { i18n } = useLingui();

    const selectedProspectAdvertiser = useMemo(
      () =>
        productsByAdvertiser?.find((p) => p.id === order?.advertiserId)
          ?.isProspect,
      [order?.advertiserId, productsByAdvertiser]
    );

    const year = useMemo(
      () => order?.period?.from.getFullYear(),
      [order?.period?.from]
    );

    const volumeDiscount = useMemo(
      () =>
        productsByAdvertiser.find((p) =>
          p.products?.find((s) => s?.id === order?.productId)
        )?.volumeDiscount,
      [order?.productId, productsByAdvertiser]
    );

    // Korting voor 2024
    const showVolumeDiscounts = useMemo(
      () =>
        selectedProspectAdvertiser && year && year >= 2024 && volumeDiscount,
      [selectedProspectAdvertiser, volumeDiscount, year]
    );

    return (
      <div className={styles.readOnly}>
        {
          // Medium en periode zijn niet wijzigbaar bij een bestaand concept
        }
        <FormReadOnlyItem
          label={i18n._(t`Medium`)}
          className={styles.medium}
          value={
            <>
              {i18n._(getMediumName(order?.medium))}
              {getMediumIcon(order?.medium)}
            </>
          }
        />
        {showDigitalReportWarning && order?.medium === MediumEnum.Inter && (
          <Alert
            showIcon
            message=""
            type="warning"
            className={styles.alert}
            description={
              <Trans>
                <b>Let op:</b> Momenteel ervaren we enkele technische problemen
                met de rapportages van de online campagnes. Dit heeft echter
                geen invloed op de uitlevering van de campagne(s).
              </Trans>
            }
          />
        )}
        <FormReadOnlyItem
          label={i18n._(t`Periode`)}
          value={`${getFormattedDate(
            order?.period.from,
            "PPP"
          )} t/m ${getFormattedDate(order?.period.to, "PPP")}`}
        />
        {selectedProspectAdvertiser ? (
          <>
            <FormReadOnlyItem
              label={i18n._(t`Adverteerder`)}
              value={i18n._(t`Nieuwe adverteerder`)}
            />
            {showVolumeDiscounts && (
              <FormReadOnlyItem
                label={i18n._(t`Volumekorting`)}
                value={i18n._(getVolumeDiscountTranslation(volumeDiscount!))}
              />
            )}
          </>
        ) : (
          <>
            <FormReadOnlyItem
              label={i18n._(t`Adverteerder`)}
              value={
                productsByAdvertiser.find((p) =>
                  p.products?.find((s) => s?.id === order?.productId)
                )?.name
              }
            />
            <FormReadOnlyItem
              label={i18n._(t`Sternummer`)}
              value={
                productsByAdvertiser
                  .flatMap((p) => p.products)
                  .filter((p) => p?.id === order?.productId)
                  ?.map((p) => `${p?.description} - ${p?.id}`)[0]
              }
            />
          </>
        )}
      </div>
    );
  }
);

export default DetailsReadPeriodAdvertiser;
