import { I18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { RuleObject } from "antd/lib/form";

const forbiddenWordsForStarlight = ["select ", "delete ", "update ", "\\|\\|"];
const forbiddenRegExp = new RegExp(
  `(${forbiddenWordsForStarlight.join("|")})`,
  "gi"
);

// eslint-disable-next-line import/prefer-default-export
export const validateForbiddenWords =
  (i18n: I18n) =>
  (rule: RuleObject, value: string): Promise<void> => {
    if (forbiddenRegExp.test(value)) {
      const found = value
        .match(forbiddenRegExp)
        ?.map((w) => w.toLowerCase())
        .join(", ");

      const msg = i18n._(t`Deze woorden zijn niet toegestaan: ${found}`);
      return Promise.reject(new Error(msg));
    }

    return Promise.resolve();
  };
