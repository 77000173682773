/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  Advertiser,
  AdvertiserFromJSON,
  AdvertiserToJSON,
  InlineResponse200,
  InlineResponse200FromJSON,
  InlineResponse200ToJSON,
  MediumEnum,
  MediumEnumFromJSON,
  MediumEnumToJSON,
  SalesPeriodResult,
  SalesPeriodResultFromJSON,
  SalesPeriodResultToJSON,
  ValueList,
  ValueListFromJSON,
  ValueListToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface ApiV1GenericGetproductsGetRequest {
  medium?: MediumEnum;
  from?: Date;
  to?: Date;
  filter?: string;
}

/**
 *
 */
export class GenericApi extends runtime.BaseAPI {
  /**
   */
  async apiV1GenericDigitalContextGetRaw(): Promise<
    runtime.ApiResponse<Array<ValueList>>
  > {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Generic/digital/context`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(ValueListFromJSON)
    );
  }

  /**
   */
  async apiV1GenericDigitalContextGet(): Promise<Array<ValueList>> {
    const response = await this.apiV1GenericDigitalContextGetRaw();
    return await response.value();
  }

  /**
   */
  async apiV1GenericDigitalTargetgroupsGetRaw(): Promise<
    runtime.ApiResponse<Array<ValueList>>
  > {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Generic/digital/targetgroups`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(ValueListFromJSON)
    );
  }

  /**
   */
  async apiV1GenericDigitalTargetgroupsGet(): Promise<Array<ValueList>> {
    const response = await this.apiV1GenericDigitalTargetgroupsGetRaw();
    return await response.value();
  }

  /**
   */
  async apiV1GenericGetproductsGetRaw(
    requestParameters: ApiV1GenericGetproductsGetRequest
  ): Promise<runtime.ApiResponse<Array<Advertiser>>> {
    const queryParameters: any = {};

    if (requestParameters.medium !== undefined) {
      queryParameters["medium"] = requestParameters.medium;
    }

    if (requestParameters.from !== undefined) {
      queryParameters["from"] = (requestParameters.from as any).toISOString();
    }

    if (requestParameters.to !== undefined) {
      queryParameters["to"] = (requestParameters.to as any).toISOString();
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Generic/getproducts`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(AdvertiserFromJSON)
    );
  }

  /**
   */
  async apiV1GenericGetproductsGet(
    requestParameters: ApiV1GenericGetproductsGetRequest
  ): Promise<Array<Advertiser>> {
    const response =
      await this.apiV1GenericGetproductsGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1GenericGetsalesperiodGetRaw(): Promise<
    runtime.ApiResponse<Array<SalesPeriodResult>>
  > {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Generic/getsalesperiod`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(SalesPeriodResultFromJSON)
    );
  }

  /**
   */
  async apiV1GenericGetsalesperiodGet(): Promise<Array<SalesPeriodResult>> {
    const response = await this.apiV1GenericGetsalesperiodGetRaw();
    return await response.value();
  }

  /**
   */
  async apiV1GenericSecondaryTargetGroupsGetRaw(): Promise<
    runtime.ApiResponse<InlineResponse200>
  > {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Generic/secondaryTargetGroups`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InlineResponse200FromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1GenericSecondaryTargetGroupsGet(): Promise<InlineResponse200> {
    const response = await this.apiV1GenericSecondaryTargetGroupsGetRaw();
    return await response.value();
  }
}
