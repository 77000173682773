/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  TenantEnum,
  TenantEnumFromJSON,
  TenantEnumFromJSONTyped,
  TenantEnumToJSON,
} from "./";

/**
 *
 * @export
 * @interface OperationalContextState
 */
export interface OperationalContextState {
  /**
   *
   * @type {TenantEnum}
   * @memberof OperationalContextState
   */
  tenant: TenantEnum;
  /**
   *
   * @type {string}
   * @memberof OperationalContextState
   */
  organisationCode: string;
  /**
   *
   * @type {number}
   * @memberof OperationalContextState
   */
  advertiserCode?: number | null;
}

export function OperationalContextStateFromJSON(
  json: any
): OperationalContextState {
  return OperationalContextStateFromJSONTyped(json, false);
}

export function OperationalContextStateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OperationalContextState {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    tenant: TenantEnumFromJSON(json["tenant"]),
    organisationCode: json["organisationCode"],
    advertiserCode: !exists(json, "advertiserCode")
      ? undefined
      : json["advertiserCode"],
  };
}

export function OperationalContextStateToJSON(
  value?: OperationalContextState | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    tenant: TenantEnumToJSON(value.tenant),
    organisationCode: value.organisationCode,
    advertiserCode: value.advertiserCode,
  };
}
