import { LoadingOutlined } from "@ant-design/icons";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Modal, Typography } from "@ster/ster-toolkit";
import { Alert, App as AntApp, Space } from "antd";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import TagManager from "react-gtm-module";
import { useDispatch, useSelector } from "react-redux";

import { OrderRequest, OrderStatus } from "../../api";
import { finalizeRequestAction } from "../../store/campaignCreate/actions";
import { validateCampaignAction } from "../../store/campaignDetail/actions";
import { StoreModel } from "../../store/models";
import { usePrevious } from "../../utils/hooks";
import styles from "./FinalizeModal.module.less";
import { FinalizeModalProps } from "./models";
import { finalizeModalSelector } from "./selectors";

const FinalizeModal = memo(
  ({
    order,
    onClose,
    validationErrors,
    saveInstructions,
  }: FinalizeModalProps) => {
    const { i18n } = useLingui();
    const dispatch = useDispatch();
    const { notification } = AntApp.useApp();

    const {
      account,
      loadingRequest,
      loadingInstructions,
      validCampaign,
      loadingValidateCampaign,
    } = useSelector((state: StoreModel) =>
      finalizeModalSelector(state, order.period.from, order.id ?? 0)
    );

    const notValidated = !validCampaign || loadingValidateCampaign;
    const loading = loadingRequest || loadingInstructions;
    const prevLoading = usePrevious(loading);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    useEffect(() => {
      dispatch(validateCampaignAction.request(order));
    }, [dispatch, order]);

    const handleFinalized = useCallback(
      (initialRequest: OrderRequest) => {
        if (
          initialRequest &&
          initialRequest.requestStatus !== OrderStatus.Concept
        ) {
          if (initialRequest.subOrders?.find((s) => s.errorMessage)) {
            notification.warning({
              message: i18n._(t`Campagne ingediend`),
              description: i18n._(
                t`Je campagne is ingediend met de volgende melding: ${Array.from(
                  new Set(initialRequest.subOrders?.map((s) => s.errorMessage))
                ).join(", ")}`
              ),
              duration: 0,
            });
          } else {
            notification.success({
              message: i18n._(t`Campagne ingediend`),
              description: i18n._(t`Je campagne is ingediend.`),
              duration: 10,
            });
          }

          TagManager.dataLayer({
            dataLayer: {
              event: "Aanvraag ingediend",
            },
          });
        }
      },
      [i18n, notification]
    );

    const handleSave = useCallback(() => {
      setButtonDisabled(true);
      if (saveInstructions) {
        // save concepts before finalize
        saveInstructions(order, undefined, () => {
          dispatch(
            finalizeRequestAction.request({
              orderRequest: order,
              onSuccess: (props) => {
                handleFinalized(props);
              },
            })
          );
        });
      } else {
        dispatch(
          finalizeRequestAction.request({
            orderRequest: order,
            onSuccess: (props) => {
              handleFinalized(props);
            },
          })
        );
      }
    }, [dispatch, handleFinalized, order, saveInstructions]);

    useEffect(() => {
      // Wanneer het laden klaar is is de aanvraag ingediend
      if (prevLoading && !loading) {
        setButtonDisabled(false);
        onClose();
      }
    }, [loading, onClose, prevLoading]);

    const disabled = useMemo(
      () => validationErrors.length > 0,
      [validationErrors.length]
    );

    return (
      <Modal
        title={i18n._(t`Wil je de campagne indienen?`)}
        cancelText={i18n._(t`Annuleren`)}
        cancelButtonProps={{ disabled: loading || buttonDisabled }}
        okText={i18n._(t`Indienen`)}
        okButtonProps={{
          disabled: disabled || loading || buttonDisabled || notValidated,
        }}
        open
        className={styles.finalizeModal}
        onOk={handleSave}
        onCancel={onClose}
        confirmLoading={loading}
      >
        {disabled ? (
          <>
            <Typography.Paragraph>
              <Trans>
                Je campagne is nog niet volledig, deze fout(en) zijn nog
                aanwezig:
              </Trans>
            </Typography.Paragraph>
            <ul>
              {validationErrors.map((error) => (
                <li key={error}>
                  <Typography.Text>{error}</Typography.Text>
                </li>
              ))}
            </ul>
          </>
        ) : (
          <Space>
            <Typography.Paragraph>
              <Trans>
                We gaan je campagne beoordelen. Zodra deze is goedgekeurd,
                ontvang je bericht op het bij ons bekende e-mailadres{" "}
                <strong>{account.sterInlog?.email}</strong>. In principe ontvang
                je onze reactie binnen 24 uur.
              </Trans>
            </Typography.Paragraph>
            <Typography.Footnote>
              <Trans>
                Op al onze overeenkomsten zijn de Ster Algemene Voorwaarden,
                Verkoopvoorwaarden en Brochure met aanbod voor het kalenderjaar
                van jouw campagne van toepassing. Je vindt deze op{" "}
                <a
                  href="https://www.ster.nl/voorwaarden/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  ster.nl/voorwaarden
                </a>
                . Je wordt geacht kennis te hebben genomen van deze voorwaarden.
                Toepasselijkheid van andersluidende voorwaarden wordt hierbij
                uitdrukkelijk van de hand gewezen.
              </Trans>
            </Typography.Footnote>
            {loadingValidateCampaign && (
              <Alert
                className={styles.alert}
                showIcon
                type="info"
                message=""
                description={
                  <>
                    {i18n._(
                      t`We zijn aan het controleren of je campagne aan alle voorwaarden voldoet.`
                    )}
                    <LoadingOutlined />
                  </>
                }
              />
            )}
            {!loadingValidateCampaign && !validCampaign && (
              <Alert
                className={styles.alert}
                showIcon
                type="error"
                message=""
                description={
                  <>
                    {i18n._(
                      t`Het is niet mogelijk om deze campagne in te dienen. In dezelfde periode mag je alleen pakketten met hetzelfde Your Time type (Vast of Variabel) inkopen.`
                    )}
                  </>
                }
              />
            )}
          </Space>
        )}
      </Modal>
    );
  }
);

export default FinalizeModal;
