/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  OnlineInstructionSpots,
  OnlineInstructionSpotsFromJSON,
  OnlineInstructionSpotsFromJSONTyped,
  OnlineInstructionSpotsToJSON,
} from "./";

/**
 *
 * @export
 * @interface OnlineInstructionPerPeriod
 */
export interface OnlineInstructionPerPeriod {
  /**
   *
   * @type {number}
   * @memberof OnlineInstructionPerPeriod
   */
  id: number;
  /**
   *
   * @type {Date}
   * @memberof OnlineInstructionPerPeriod
   */
  startDate?: Date | null;
  /**
   *
   * @type {Date}
   * @memberof OnlineInstructionPerPeriod
   */
  endDate?: Date | null;
  /**
   *
   * @type {Array<OnlineInstructionSpots>}
   * @memberof OnlineInstructionPerPeriod
   */
  instructions?: Array<OnlineInstructionSpots> | null;
}

export function OnlineInstructionPerPeriodFromJSON(
  json: any
): OnlineInstructionPerPeriod {
  return OnlineInstructionPerPeriodFromJSONTyped(json, false);
}

export function OnlineInstructionPerPeriodFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OnlineInstructionPerPeriod {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    startDate: !exists(json, "startDate")
      ? undefined
      : json["startDate"] === null
        ? null
        : new Date(json["startDate"]),
    endDate: !exists(json, "endDate")
      ? undefined
      : json["endDate"] === null
        ? null
        : new Date(json["endDate"]),
    instructions: !exists(json, "instructions")
      ? undefined
      : json["instructions"] === null
        ? null
        : (json["instructions"] as Array<any>).map(
            OnlineInstructionSpotsFromJSON
          ),
  };
}

export function OnlineInstructionPerPeriodToJSON(
  value?: OnlineInstructionPerPeriod | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    startDate:
      value.startDate === undefined
        ? undefined
        : value.startDate === null
          ? null
          : value.startDate.toISOString(),
    endDate:
      value.endDate === undefined
        ? undefined
        : value.endDate === null
          ? null
          : value.endDate.toISOString(),
    instructions:
      value.instructions === undefined
        ? undefined
        : value.instructions === null
          ? null
          : (value.instructions as Array<any>).map(
              OnlineInstructionSpotsToJSON
            ),
  };
}
