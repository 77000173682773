import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Form, Modal, Typography } from "@ster/ster-toolkit";
import { Form as AntForm, Space } from "antd";

import { LoadingPortalUser } from "../../../store/models";

const CampaignProposalModal = ({
  onClose,
  account,
  loading,
  saving,
}: {
  onClose: () => void;
  account: LoadingPortalUser;
  loading?: boolean;
  saving: boolean;
}) => {
  const { i18n } = useLingui();
  const [form] = AntForm.useForm();

  return (
    <Modal
      open
      footer={null}
      title={i18n._(t`Voorstel aanvragen`)}
      width={500}
      onCancel={onClose}
    >
      <Typography.Paragraph>
        {i18n._(t`Wil je deze campagne als voorstel in je mail ontvangen?`)}
        <br />
        {i18n._(
          t`We slaan daarvoor eerst al je wijzigingen op en daarna ontvang je binnen enkele minuten het voorstel op het bij ons bekende e-mailadres`
        )}{" "}
        <strong>{account.sterInlog?.email}</strong>.
      </Typography.Paragraph>

      <Form requiredMark={false} name="proposal" form={form}>
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          <Typography.Footnote>
            {i18n._(
              t`Indien je het voorstel niet ontvangen hebt, controleer dan eerst je spam folder.`
            )}
          </Typography.Footnote>

          <Form.Item>
            <Space
              style={{ width: "100%", justifyContent: "flex-end" }}
              align="end"
            >
              <Button
                mode="tertiary"
                onClick={onClose}
                disabled={loading || saving}
              >
                <Trans>Annuleren</Trans>
              </Button>
              <Button
                mode="primary"
                htmlType="submit"
                loading={loading || saving}
                disabled={loading || saving}
              >
                <Trans>Opslaan en versturen</Trans>
              </Button>
            </Space>
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
};

export default CampaignProposalModal;
