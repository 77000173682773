import { createAction, createAsyncAction } from "typesafe-actions";

import {
  ApiV1CampaignAnalysisUploadFilePostRequest,
  ApiV1CampaignCodeCampaignCodeGetRequest,
  ApiV1CustomerContactsAccountIdGetRequest,
  ApiV1CustomerContactsAdvertiserIdGetRequest,
  ApiV1CustomerOpportunitiesAccountIdGetRequest,
  ApiV1CustomerOpportunitiesAdvertiserIdGetRequest,
  ApiV1ProposalUploadForecastPostRequest,
  CampaignConfiguration,
  CampaignConfigurationInput,
  CampaignImportOutput,
  CustomerAccount,
  CustomerContact,
  CustomerOpportunity,
  DigitalProgramIListValueResult,
  ForecastConversionGroups,
  ForecastResult,
  OrderRequest,
  PackageResult,
  ProposalRequest,
  SalesPeriod,
  ValueList,
  ValueListIEnumerableValueResult,
} from "../../api";
import { ResponseError } from "../base";
import { actionNameAsync } from "../utils";
import {
  BookingDateMapped,
  BookingDateRequestPayload,
  CalculationFailurePayload,
  CalculationRequestPayload,
  CalculationResponse,
  FinalizeRequestPayload,
  FinalizeRequestResponse,
  ForecastRequestPayload,
  GroupedPackagesRadioResponse,
  GroupedPackagesRequestPayload,
  GroupedPackagesTvResponse,
  InitialRequestFailurePayload,
  InitialRequestRequestPayload,
  PackagesRequestPayload,
  ProductsRequestPayload,
  SaveRequestPayload,
  SaveRequestResponse,
  SpotIndexesFailurePayload,
  SpotIndexesRequestPayload,
  SpotIndexesResultPayload,
} from "./models";

export const receiveSalesPeriodsAction = createAsyncAction(
  actionNameAsync("salesPeriod", "REQUEST"),
  actionNameAsync("salesPeriod", "SUCCESS"),
  actionNameAsync("salesPeriod", "FAILURE")
)<undefined, SalesPeriod[], ResponseError>();

export const receiveProductsAction = createAsyncAction(
  actionNameAsync("products", "REQUEST"),
  actionNameAsync("products", "SUCCESS"),
  actionNameAsync("products", "FAILURE")
)<ProductsRequestPayload, SalesPeriod[], ResponseError>();

export const clearProductsAction = createAction("PRODUCTS_CLEAR")();

export const receiveInitialRequestAction = createAsyncAction(
  actionNameAsync("initialRequest", "REQUEST"),
  actionNameAsync("initialRequest", "SUCCESS"),
  actionNameAsync("initialRequest", "FAILURE")
)<InitialRequestRequestPayload, OrderRequest, InitialRequestFailurePayload>();

export const receiveSpotIndexesAction = createAsyncAction(
  actionNameAsync("spotIndexes", "REQUEST"),
  actionNameAsync("spotIndexes", "SUCCESS"),
  actionNameAsync("spotIndexes", "FAILURE")
)<
  SpotIndexesRequestPayload,
  SpotIndexesResultPayload,
  SpotIndexesFailurePayload
>();

export const receivePackagesAction = createAsyncAction(
  actionNameAsync("packages", "REQUEST"),
  actionNameAsync("packages", "SUCCESS"),
  actionNameAsync("packages", "FAILURE")
)<PackagesRequestPayload, PackageResult, ResponseError>();

export const clearPackagesAction = createAction("PACKAGES_CLEAR")();

export const receiveGroupedPackagesRadioAction = createAsyncAction(
  actionNameAsync("groupedPackagesRadio", "REQUEST"),
  actionNameAsync("groupedPackagesRadio", "SUCCESS"),
  actionNameAsync("groupedPackagesRadio", "FAILURE")
)<GroupedPackagesRequestPayload, GroupedPackagesRadioResponse, ResponseError>();

export const clearGroupedPackagesRadioAction = createAction(
  "GROUPEDPACKAGESRADIO_CLEAR"
)();

export const receiveGroupedPackagesTvAction = createAsyncAction(
  actionNameAsync("groupedPackagesTv", "REQUEST"),
  actionNameAsync("groupedPackagesTv", "SUCCESS"),
  actionNameAsync("groupedPackagesTv", "FAILURE")
)<GroupedPackagesRequestPayload, GroupedPackagesTvResponse, ResponseError>();

export const clearGroupedPackagesTvAction = createAction(
  "GROUPEDPACKAGESTV_CLEAR"
)();

export const receiveBookingDateAction = createAsyncAction(
  actionNameAsync("bookingDate", "REQUEST"),
  actionNameAsync("bookingDate", "SUCCESS"),
  actionNameAsync("bookingDate", "FAILURE")
)<BookingDateRequestPayload, BookingDateMapped, ResponseError>();

export const saveRequestAction = createAsyncAction(
  actionNameAsync("saveRequest", "REQUEST"),
  actionNameAsync("saveRequest", "SUCCESS"),
  actionNameAsync("saveRequest", "FAILURE")
)<SaveRequestPayload, SaveRequestResponse, InitialRequestFailurePayload>();

export const finalizeRequestAction = createAsyncAction(
  actionNameAsync("finalizeRequest", "REQUEST"),
  actionNameAsync("finalizeRequest", "SUCCESS"),
  actionNameAsync("finalizeRequest", "FAILURE")
)<
  FinalizeRequestPayload,
  FinalizeRequestResponse,
  InitialRequestFailurePayload
>();

export const receiveForecastAction = createAsyncAction(
  actionNameAsync("receiveForecast", "REQUEST"),
  actionNameAsync("receiveForecast", "SUCCESS"),
  actionNameAsync("receiveForecast", "FAILURE")
)<ForecastRequestPayload, ForecastResult, ResponseError>();

export const clearForecastAction = createAction("RECEIVE_FORECAST_CLEAR")();

export const receiveForecastConversionGroupsAction = createAsyncAction(
  actionNameAsync("receiveForecastConversionGroups", "REQUEST"),
  actionNameAsync("receiveForecastConversionGroups", "SUCCESS"),
  actionNameAsync("receiveForecastConversionGroups", "FAILURE")
)<undefined, ForecastConversionGroups, ResponseError>();

export const receiveSitesAction = createAsyncAction(
  actionNameAsync("sites", "REQUEST"),
  actionNameAsync("sites", "SUCCESS"),
  actionNameAsync("sites", "FAILURE")
)<undefined, ValueListIEnumerableValueResult, ResponseError>();

export const receiveProgramsAction = createAsyncAction(
  actionNameAsync("programs", "REQUEST"),
  actionNameAsync("programs", "SUCCESS"),
  actionNameAsync("programs", "FAILURE")
)<undefined, DigitalProgramIListValueResult, ResponseError>();

export const receiveContextTargetsAction = createAsyncAction(
  actionNameAsync("contextTargets", "REQUEST"),
  actionNameAsync("contextTargets", "SUCCESS"),
  actionNameAsync("contextTargets", "FAILURE")
)<undefined, ValueList[], ResponseError>();

export const receiveTargetGroupsAction = createAsyncAction(
  actionNameAsync("targetGroups", "REQUEST"),
  actionNameAsync("targetGroups", "SUCCESS"),
  actionNameAsync("targetGroups", "FAILURE")
)<undefined, ValueList[], ResponseError>();

export const proposalGenerateAction = createAsyncAction(
  actionNameAsync("proposalGenerate", "REQUEST"),
  actionNameAsync("proposalGenerate", "SUCCESS"),
  actionNameAsync("proposalGenerate", "FAILURE")
)<ProposalRequest, undefined, ResponseError>();

export const clearProposalGenerateAction = createAction(
  "RECEIVE_PROPOSALGENERATE_CLEAR"
)();

export const proposalUploadForecastAction = createAsyncAction(
  actionNameAsync("proposalUploadForecast", "REQUEST"),
  actionNameAsync("proposalUploadForecast", "SUCCESS"),
  actionNameAsync("proposalUploadForecast", "FAILURE")
)<ApiV1ProposalUploadForecastPostRequest, Blob, ResponseError>();

export const analysisUploadFileAction = createAsyncAction(
  actionNameAsync("analysisUploadFile", "REQUEST"),
  actionNameAsync("analysisUploadFile", "SUCCESS"),
  actionNameAsync("analysisUploadFile", "FAILURE")
)<ApiV1CampaignAnalysisUploadFilePostRequest, Blob, ResponseError>();

export const campaignConfigurationFetchAction = createAsyncAction(
  actionNameAsync("campaignConfigurationFetch", "REQUEST"),
  actionNameAsync("campaignConfigurationFetch", "SUCCESS"),
  actionNameAsync("campaignConfigurationFetch", "FAILURE")
)<
  ApiV1CampaignCodeCampaignCodeGetRequest,
  CampaignConfiguration,
  ResponseError
>();

export const campaignConfigurationClearAction = createAction(
  "CAMPAIGNCONFIGURATIONFETCH_CLEAR"
)();

export const importConfigurationAction = createAsyncAction(
  actionNameAsync("importConfiguration", "REQUEST"),
  actionNameAsync("importConfiguration", "SUCCESS"),
  actionNameAsync("importConfiguration", "FAILURE")
)<CampaignConfigurationInput, CampaignImportOutput, ResponseError>();

export const importConfigurationClearAction = createAction(
  "IMPORTCONFIGURATION_CLEAR"
)();

export const receiveCustomerAccountsAction = createAsyncAction(
  actionNameAsync("receiveCustomerAccounts", "REQUEST"),
  actionNameAsync("receiveCustomerAccounts", "SUCCESS"),
  actionNameAsync("receiveCustomerAccounts", "FAILURE")
)<undefined, CustomerAccount[], ResponseError>();

export const receiveCustomerOpportunitiesAction = createAsyncAction(
  actionNameAsync("receiveCustomerOpportunities", "REQUEST"),
  actionNameAsync("receiveCustomerOpportunities", "SUCCESS"),
  actionNameAsync("receiveCustomerOpportunities", "FAILURE")
)<
  | ApiV1CustomerOpportunitiesAccountIdGetRequest
  | ApiV1CustomerOpportunitiesAdvertiserIdGetRequest,
  CustomerOpportunity[],
  ResponseError
>();

export const receiveCustomerContactsAction = createAsyncAction(
  actionNameAsync("receiveCustomerContacts", "REQUEST"),
  actionNameAsync("receiveCustomerContacts", "SUCCESS"),
  actionNameAsync("receiveCustomerContacts", "FAILURE")
)<
  | ApiV1CustomerContactsAccountIdGetRequest
  | ApiV1CustomerContactsAdvertiserIdGetRequest,
  CustomerContact[],
  ResponseError
>();

export const receiveCustomerOpportunitiesClearAction = createAction(
  "RECEIVECUSTOMEROPPORTUNITIES_CLEAR"
)();

export const receiveCustomerContactClearAction = createAction(
  "RECEIVECUSTOMERCONTACTS_CLEAR"
)();

export const receiveCalculationAction = createAsyncAction(
  actionNameAsync("calculation", "REQUEST"),
  actionNameAsync("calculation", "SUCCESS"),
  actionNameAsync("calculation", "FAILURE")
)<CalculationRequestPayload, CalculationResponse, CalculationFailurePayload>();
