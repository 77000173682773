/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  InvoiceStatus,
  InvoiceStatusFromJSON,
  InvoiceStatusFromJSONTyped,
  InvoiceStatusToJSON,
  MediumEnum,
  MediumEnumFromJSON,
  MediumEnumFromJSONTyped,
  MediumEnumToJSON,
} from "./";

/**
 *
 * @export
 * @interface Invoice
 */
export interface Invoice {
  /**
   *
   * @type {string}
   * @memberof Invoice
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Invoice
   */
  internalId: string;
  /**
   *
   * @type {MediumEnum}
   * @memberof Invoice
   */
  medium: MediumEnum;
  /**
   *
   * @type {string}
   * @memberof Invoice
   */
  productDescr: string;
  /**
   *
   * @type {string}
   * @memberof Invoice
   */
  advertiserName: string;
  /**
   *
   * @type {Date}
   * @memberof Invoice
   */
  dueDate: Date;
  /**
   *
   * @type {Date}
   * @memberof Invoice
   */
  invoiceDate: Date;
  /**
   *
   * @type {number}
   * @memberof Invoice
   */
  invoiceAmount: number;
  /**
   *
   * @type {number}
   * @memberof Invoice
   */
  vatAmount: number;
  /**
   *
   * @type {InvoiceStatus}
   * @memberof Invoice
   */
  status: InvoiceStatus;
}

export function InvoiceFromJSON(json: any): Invoice {
  return InvoiceFromJSONTyped(json, false);
}

export function InvoiceFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Invoice {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    internalId: json["internalId"],
    medium: MediumEnumFromJSON(json["medium"]),
    productDescr: json["productDescr"],
    advertiserName: json["advertiserName"],
    dueDate: new Date(json["dueDate"]),
    invoiceDate: new Date(json["invoiceDate"]),
    invoiceAmount: json["invoiceAmount"],
    vatAmount: json["vatAmount"],
    status: InvoiceStatusFromJSON(json["status"]),
  };
}

export function InvoiceToJSON(value?: Invoice | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    internalId: value.internalId,
    medium: MediumEnumToJSON(value.medium),
    productDescr: value.productDescr,
    advertiserName: value.advertiserName,
    dueDate: value.dueDate.toISOString(),
    invoiceDate: value.invoiceDate.toISOString(),
    invoiceAmount: value.invoiceAmount,
    vatAmount: value.vatAmount,
    status: InvoiceStatusToJSON(value.status),
  };
}
