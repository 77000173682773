/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  InternetFormat,
  InternetFormatFromJSON,
  InternetFormatFromJSONTyped,
  InternetFormatToJSON,
  PackageType,
  PackageTypeFromJSON,
  PackageTypeFromJSONTyped,
  PackageTypeToJSON,
} from "./";

/**
 *
 * @export
 * @interface PackageRequest
 */
export interface PackageRequest {
  /**
   *
   * @type {string}
   * @memberof PackageRequest
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof PackageRequest
   */
  name: string;
  /**
   *
   * @type {Array<InternetFormat>}
   * @memberof PackageRequest
   */
  internetFormat?: Array<InternetFormat> | null;
  /**
   *
   * @type {boolean}
   * @memberof PackageRequest
   */
  readonly isYourTime: boolean;
  /**
   *
   * @type {string}
   * @memberof PackageRequest
   */
  yourTimeType?: string | null;
  /**
   *
   * @type {number}
   * @memberof PackageRequest
   */
  preferredPositionSurcharge?: number;
  /**
   *
   * @type {PackageType}
   * @memberof PackageRequest
   */
  type?: PackageType;
  /**
   *
   * @type {boolean}
   * @memberof PackageRequest
   */
  readonly isCombi: boolean;
}

export function PackageRequestFromJSON(json: any): PackageRequest {
  return PackageRequestFromJSONTyped(json, false);
}

export function PackageRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PackageRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    code: json["code"],
    name: json["name"],
    internetFormat: !exists(json, "internetFormat")
      ? undefined
      : json["internetFormat"] === null
        ? null
        : (json["internetFormat"] as Array<any>).map(InternetFormatFromJSON),
    isYourTime: json["isYourTime"],
    yourTimeType: !exists(json, "yourTimeType")
      ? undefined
      : json["yourTimeType"],
    preferredPositionSurcharge: !exists(json, "preferredPositionSurcharge")
      ? undefined
      : json["preferredPositionSurcharge"],
    type: !exists(json, "type") ? undefined : PackageTypeFromJSON(json["type"]),
    isCombi: json["isCombi"],
  };
}

export function PackageRequestToJSON(value?: PackageRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    code: value.code,
    name: value.name,
    internetFormat:
      value.internetFormat === undefined
        ? undefined
        : value.internetFormat === null
          ? null
          : (value.internetFormat as Array<any>).map(InternetFormatToJSON),
    yourTimeType: value.yourTimeType,
    preferredPositionSurcharge: value.preferredPositionSurcharge,
    type: PackageTypeToJSON(value.type),
  };
}
