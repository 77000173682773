import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Option, Select } from "@ster/ster-toolkit";
import { SelectValue } from "antd/lib/select";
import { memo, useCallback } from "react";

import styles from "./PreferredPositionSelect.module.less";

const PreferredPositionSelect = memo(
  ({
    uniqueId,
    value,
    enabled,
    positionFreeString,
    onSelect,
  }: {
    uniqueId: string;
    value?: string;
    enabled: boolean;
    positionFreeString?: string | null;
    onSelect: (uid: string, preferredPosition: string) => void;
  }) => {
    const { i18n } = useLingui();
    const handleSelect = useCallback(
      (newValue: SelectValue): void => {
        onSelect(uniqueId, newValue as string);
      },
      [onSelect, uniqueId]
    );

    return (
      <Select
        className={styles.preferredPositionSelect}
        placeholder={i18n._(t`Selecteer...`)}
        disabled={!enabled}
        onChange={handleSelect}
        value={value}
        getPopupContainer={(triggernode) =>
          document.getElementById("breakSelectTable") ??
          triggernode.parentElement
        }
      >
        <Option value="NNNN" title={i18n._(t`Geen voorkeur`)}>
          <Trans>Geen voorkeur</Trans>
        </Option>
        <Option
          value="JNNN"
          title={i18n._(t`1e in blok`)}
          disabled={positionFreeString?.[0] === "N"}
        >
          <Trans>1e in blok</Trans>
        </Option>
        <Option
          value="NJNN"
          title={i18n._(t`2e in blok`)}
          disabled={positionFreeString?.[1] === "N"}
        >
          <Trans>2e in blok</Trans>
        </Option>
        <Option
          value="NNJN"
          title={i18n._(t`Op 1 na laatste in blok`)}
          disabled={positionFreeString?.[2] === "N"}
        >
          <Trans>Op 1 na laatste in blok</Trans>
        </Option>
        <Option
          value="NNNJ"
          title={i18n._(t`Laatste in blok`)}
          disabled={positionFreeString?.[3] === "N"}
        >
          <Trans>Laatste in blok</Trans>
        </Option>
      </Select>
    );
  }
);

export default PreferredPositionSelect;
