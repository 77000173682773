import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

import { GenericApi } from "../../api/apis";
import apiConfig from "../../apiConfig";
import { toError } from "../utils";
import { receiveSecondaryTargetGroupsAction } from "./actions";

// eslint-disable-next-line import/prefer-default-export
export function* receiveSecondaryTargetGroups(): SagaIterator {
  try {
    const genericApi = new GenericApi(apiConfig());
    const response = yield call(() =>
      genericApi.apiV1GenericSecondaryTargetGroupsGet()
    );
    yield put(receiveSecondaryTargetGroupsAction.success(response));
  } catch (err) {
    yield put(receiveSecondaryTargetGroupsAction.failure(toError(err)));
  }
}
