/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SecondaryTargetGroup
 */
export interface SecondaryTargetGroup {
  /**
   *
   * @type {number}
   * @memberof SecondaryTargetGroup
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof SecondaryTargetGroup
   */
  code?: string | null;
  /**
   *
   * @type {string}
   * @memberof SecondaryTargetGroup
   */
  mbsId?: string | null;
  /**
   *
   * @type {string}
   * @memberof SecondaryTargetGroup
   */
  medium?: string | null;
  /**
   *
   * @type {string}
   * @memberof SecondaryTargetGroup
   */
  displayName?: string | null;
}

export function SecondaryTargetGroupFromJSON(json: any): SecondaryTargetGroup {
  return SecondaryTargetGroupFromJSONTyped(json, false);
}

export function SecondaryTargetGroupFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SecondaryTargetGroup {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    code: !exists(json, "code") ? undefined : json["code"],
    mbsId: !exists(json, "mbsId") ? undefined : json["mbsId"],
    medium: !exists(json, "medium") ? undefined : json["medium"],
    displayName: !exists(json, "displayName") ? undefined : json["displayName"],
  };
}

export function SecondaryTargetGroupToJSON(
  value?: SecondaryTargetGroup | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    code: value.code,
    mbsId: value.mbsId,
    medium: value.medium,
    displayName: value.displayName,
  };
}
