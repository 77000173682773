import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  ComponentLocalization,
  Form,
  Input,
  Radio,
  RangePicker,
  Spinner,
  formatToEuro,
} from "@ster/ster-toolkit";
import { Alert, Form as AntForm } from "antd";
import { RadioChangeEvent } from "antd/lib";
import { Store } from "antd/lib/form/interface";
import classNames from "classnames";
import { differenceInDays } from "date-fns";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  BreakSelection,
  PackageChoiceEnum,
  SubOrderRequest,
} from "../../../api";
import { settingsSelector } from "../../../shared/selectors";
import { ReduxStoreState } from "../../../store/base";
import { receiveSpotIndexesAction } from "../../../store/campaignCreate/actions";
import { StoreModel } from "../../../store/models";
import {
  getLanguage,
  getPopupContainerSubOrders,
  getSpotPrice,
} from "../../../utils";
import { isBetweenDays } from "../../../utils/dateHelper";
import { isBudgetOrBreakSelectionChoiceEnabled } from "../../../utils/settingsHelper";
import { formItemLayout } from "../../partials/Form";
import DynamicIndexWarning from "../DynamicIndexWarning";
import { SubOrderEditProps } from "./models";
import BreakSelectionInput from "./partials/BreakSelectionInput";
import ExcludeDays from "./partials/ExcludeDays";
import PackageSelectGrouped from "./partials/PackageSelectGrouped";
import SpotLength from "./partials/SpotLength";
import { fixedCostsSubOrderSelector } from "./selectors";
import styles from "./SubOrder.module.less";

// eslint-disable-next-line no-shadow
enum BudgetOrBreakSelectionChoice {
  BudgetChoice = "budget",
  BreakSelectionChoice = "breakselection",
}

/**
 * Toevoegen/wijzigen van een pakket van het type vaste kosten per spot
 */
const FixedCostsSubOrder = memo(
  ({
    onChange,
    subOrder,
    productId,
    onFinishFailed,
    period,
    selectedSalesPeriod,
    medium,
    alreadySelected,
  }: SubOrderEditProps) => {
    const { i18n } = useLingui();
    const [form] = AntForm.useForm();
    const dispatch = useDispatch();
    const [isDirty, setIsDirty] = useState<boolean>(false);

    const {
      spotIndexes,
      packages,
      loading,
      canRequest,
      states: { spotIndexes: spotIndexesState },
    } = useSelector((state: StoreModel) =>
      fixedCostsSubOrderSelector(state, {
        period,
        medium,
        packageChoice: subOrder.packageChoice,
      })
    );

    const settings = useSelector(settingsSelector);

    const budgetOrBreakSelectionChoiceEnabled =
      isBudgetOrBreakSelectionChoiceEnabled(settings.settings);

    const selectedPackage = useMemo(() => {
      if (subOrder._package) {
        return packages?.find((p) => p.code === subOrder._package?.code);
      }
      return packages.length === 1 ? packages[0] : undefined;
    }, [packages, subOrder]);

    // Haal spotindexen op
    useEffect(() => {
      if (spotIndexesState === ReduxStoreState.Initial) {
        dispatch(
          receiveSpotIndexesAction.request({
            // TODO: spotindexen per medium in store?
            medium,
            year: period.from.getFullYear(),
          })
        );
      }
    }, [dispatch, medium, period.from, spotIndexesState]);

    useEffect((): void => {
      if (subOrder) {
        // Vul het formulier met de bestaande deelorder
        form.setFieldsValue({
          spotLength: [undefined], // Spotlength is altijd minimaal een leeg array om de control te initialiseren
          ...subOrder,
          period: subOrder.period
            ? [subOrder.period.from, subOrder.period.to]
            : undefined,
          targetGroupId: subOrder.targetGroup?.id,
          packageCode: subOrder._package?.code,
          packageGroup: subOrder._package?.name,
        });

        // Re-validate de spotlength
        const currentSpotLength = (
          (form.getFieldValue("spotLength") ?? []) as (number | undefined)[]
        ).filter((l) => l !== undefined);
        if (currentSpotLength.length > 0) {
          form.validateFields(["spotLength"]);
        }
      }
    }, [subOrder, form]);

    const periodSpotLengthAndPackageAreAvailable = Boolean(
      subOrder.period &&
        selectedPackage &&
        (subOrder.spotLength?.reduce((a, b) => a + (b ?? 0)) ?? 0) > 0
    );

    const showBudgetOrBreakSelectionChoice =
      budgetOrBreakSelectionChoiceEnabled &&
      periodSpotLengthAndPackageAreAvailable;

    const subOrderHasBreakSelection =
      Number(subOrder.breakSelection?.length) > 0;

    const [budgetOrBreakSelectionChoice, setBudgetOrBreakSelectionChoice] =
      useState<BudgetOrBreakSelectionChoice>(() => {
        if (!showBudgetOrBreakSelectionChoice) {
          // no choice available yet
          // but we do assign a default value
          // if we don't - form validation doesnt kick in on our non-part form.item.
          return BudgetOrBreakSelectionChoice.BreakSelectionChoice;
        }

        if (subOrderHasBreakSelection) {
          return BudgetOrBreakSelectionChoice.BreakSelectionChoice; // choice depends on contents of suborder
        }

        return BudgetOrBreakSelectionChoice.BudgetChoice;
      });

    const showPackageSelection = useMemo(() => packages.length > 1, [packages]);

    // Blokkeuze alleen beschikbaar indien (1) keuze instelling aan + keuze gemaakt of (2) keuze instelling uit + generieke velden gevuld
    const showBudgetSelection = useCallback(
      () =>
        budgetOrBreakSelectionChoice ===
        BudgetOrBreakSelectionChoice.BudgetChoice,
      [budgetOrBreakSelectionChoice]
    );

    const showBreakSelection = useCallback(
      () =>
        periodSpotLengthAndPackageAreAvailable &&
        budgetOrBreakSelectionChoice ===
          BudgetOrBreakSelectionChoice.BreakSelectionChoice,
      [budgetOrBreakSelectionChoice, periodSpotLengthAndPackageAreAvailable]
    );

    // Wijzigen van het formulier
    const handleValuesChange = useCallback(
      (changedValues: Store, values: Store) => {
        const newBudget = showBreakSelection()
          ? values.breakSelection?.reduce(
              (a: number, b: BreakSelection) =>
                a +
                (getSpotPrice(
                  b,
                  selectedPackage?.preferredPositionSurcharge ?? 0
                ) ?? 0),
              0
            )
          : changedValues.budget ?? values.budget;

        const newValues: Partial<SubOrderRequest> = {
          ...values,
          id: subOrder.id,
          period: values.period
            ? {
                from: values.period[0],
                to: values.period[1],
              }
            : undefined,
          budget: newBudget,
        };

        if (values.packageGroup) {
          const _package = packages?.find(
            (p) => p.name === values.packageGroup
          );
          newValues._package = _package;
          newValues.targetGroup = _package?.targetGroups?.[0];
        }

        onChange(newValues);

        if (
          showBreakSelection() &&
          (changedValues.spotLength ||
            changedValues.period ||
            changedValues.excluded)
        ) {
          // Als de spotlengte of periode wijzigt dienen de blokken herberekent te worden
          setIsDirty(true);
        }
      },
      [
        packages,
        subOrder.id,
        onChange,
        showBreakSelection,
        selectedPackage?.preferredPositionSurcharge,
      ]
    );

    const handleBudgetOrBlockSelectionChange = useCallback(
      (e: RadioChangeEvent) => {
        setBudgetOrBreakSelectionChoice(
          e.target.value as BudgetOrBreakSelectionChoice
        );

        // raise form handler manually - the form.item is not actually part of this form due to the missing `name` property
        const newValue = { budget: 0, breakSelection: undefined };
        form.setFieldsValue(newValue);
        handleValuesChange(newValue, form.getFieldsValue());
      },
      [form, handleValuesChange]
    );

    // Afhandelen van het optreden van een error bij het versturen van het formulier
    const handleFinishFailed = useCallback(() => {
      onFinishFailed(subOrder.id ?? 0);
    }, [onFinishFailed, subOrder]);

    // Bepaling van de niet beschikbare data in de periode-kiezer
    const isDateDisabled = useCallback(
      (currentDate: Date): boolean =>
        !isBetweenDays(
          selectedSalesPeriod.salesPeriodStartDate,
          selectedSalesPeriod.salesPeriodEndDate,
          currentDate
        ),
      [
        selectedSalesPeriod.salesPeriodEndDate,
        selectedSalesPeriod.salesPeriodStartDate,
      ]
    );

    const handleBreakSelectionChange = useCallback(
      (value: BreakSelection[]) => {
        form.setFieldsValue({ breakSelection: value });
        setIsDirty(false);
      },
      [form]
    );

    const minDays = useMemo(
      () => selectedPackage?.minDays ?? 0,
      [selectedPackage?.minDays]
    );

    const normalizeToNumber = (value: string) => Number(value);

    return (
      <Spinner spinning={loading}>
        <Form
          {...formItemLayout}
          onValuesChange={handleValuesChange}
          onFinishFailed={handleFinishFailed}
          form={form}
          name={`suborder-${subOrder.id}`}
          className={classNames(styles.fixedcosts, "fixedWidthLabels")}
        >
          <Form.Item
            label={i18n._(t`Periode`)}
            name="period"
            rules={[
              {
                required: true,
                message: i18n._(t`Kies een periode`),
              },
              {
                validator: (_, value): Promise<void> =>
                  minDays === 0 ||
                  differenceInDays(value?.[1], value?.[0]) + 1 >= minDays
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          i18n._(
                            t`Je dient minimaal ${minDays} dagen te selecteren`
                          )
                        )
                      ),
              },
            ]}
            extra={minDays > 0 ? i18n._(t`Minimaal ${minDays} dagen`) : null}
          >
            <RangePicker
              showWeek
              disabledDate={isDateDisabled}
              defaultPickerValue={[
                selectedSalesPeriod.salesPeriodStartDate,
                null!,
              ]}
              componentLocale={getLanguage() as ComponentLocalization}
              getPopupContainer={getPopupContainerSubOrders}
            />
          </Form.Item>
          {subOrder.packageChoice !== PackageChoiceEnum.FixedCostsCult && (
            <Form.Item label={i18n._(t`Uitgesloten dagen`)} name="excluded">
              <ExcludeDays
                period={subOrder.period}
                weekDays={selectedPackage?.weekDays}
                canDisableSeperateDays
                disabled={!subOrder.period}
                disabledText={<Trans>Selecteer eerst een periode</Trans>}
              />
            </Form.Item>
          )}
          <Form.Item label={i18n._(t`Spotlengte`)}>
            <SpotLength
              spotIndexes={spotIndexes}
              spotLength={subOrder.spotLength ?? []}
              enabled={subOrder.period !== undefined}
            />
          </Form.Item>
          {showPackageSelection && (
            <PackageSelectGrouped
              packages={packages}
              groupedPackages={packages.map((p) => ({
                name: p.name,
                properties: [
                  {
                    code: p.code,
                    hasSpread: false,
                    hasPreferredPosition: false,
                    hasHotspot: false,
                    hasPremium: false,
                  },
                ],
              }))}
              period={subOrder.period}
              enabled={subOrder.period !== undefined}
              numberOfExcludedDays={subOrder.excluded?.length}
              spreadEnabled={subOrder.spread ?? false}
              value={form.getFieldValue("packageGroup")}
              subOrder={subOrder}
            />
          )}
          {showBudgetOrBreakSelectionChoice && (
            <Form.Item
              label={i18n._(t`Budget of blokkeuze`)}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Radio.Group
                onChange={handleBudgetOrBlockSelectionChange}
                value={budgetOrBreakSelectionChoice}
                mode="horizontal"
                name="radioTypeGroup"
              >
                <Radio value={BudgetOrBreakSelectionChoice.BudgetChoice}>
                  <Trans>Budget</Trans>
                </Radio>
                <Radio
                  value={BudgetOrBreakSelectionChoice.BreakSelectionChoice}
                >
                  <Trans>Blokkeuze</Trans>
                </Radio>
              </Radio.Group>
            </Form.Item>
          )}
          {showBudgetSelection() && (
            <Form.Item
              name="budget"
              label={i18n._(t`Budget`)}
              wrapperCol={{ span: 6 }}
              normalize={normalizeToNumber}
              rules={[
                {
                  required: true,
                },
                {
                  validator: (_, value) => {
                    if (value && value > 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        `${i18n._(t`Budget moet groter zijn dan`)} ${formatToEuro(0)}.`
                      )
                    );
                  },
                },
              ]}
            >
              <Input.Currency decimalScale={0} />
            </Form.Item>
          )}
          {showBreakSelection() &&
            (canRequest ? (
              <BreakSelectionInput
                canSelectPreferredPosition={
                  selectedPackage?.canSelectPreferredPosition ?? false
                }
                preferredPositionSurcharge={
                  selectedPackage?.preferredPositionSurcharge ?? 0
                }
                handleBreakSelectionChange={handleBreakSelectionChange}
                medium={medium}
                subOrder={subOrder}
                productId={productId}
                selectionRequired
                isDirty={isDirty}
                alreadySelected={alreadySelected}
              />
            ) : (
              <Alert
                showIcon
                type="warning"
                message=""
                description={
                  <Trans>
                    Op dit moment kun je nog geen blokken koppelen, dit kan pas
                    zodra de periode is vrijgegeven door Ster.
                  </Trans>
                }
              />
            ))}

          {subOrder.period && (
            <DynamicIndexWarning
              medium={medium}
              className={styles.dynamicIndex}
              fixedCosts
              {...subOrder.period}
              {...subOrder}
            />
          )}
        </Form>
      </Spinner>
    );
  }
);

export default FixedCostsSubOrder;
