import { i18n } from "@lingui/core";
import { Trans, t } from "@lingui/macro";
import {
  Button,
  Container,
  ContentContainer,
  Form,
  Row,
  Spinner,
  Typography,
} from "@ster/ster-toolkit";
import { Form as AntForm, Col } from "antd";
import { memo, useCallback, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";

import { OperationalContextState } from "../../api";
import { setOperationalContextStateAction } from "../../store/account/actions";
import OperationalContextStateForm from "./OperationalContextStateForm";
import styles from "./Organisation.module.less";
import rootSelectors from "./selectors";

type FormValues = {
  preferredOperationalContextState: OperationalContextState;
};

const OrganisationContainer = memo(() => {
  const [form] = AntForm.useForm<FormValues>();

  const dispatch = useDispatch();
  const { operationalContextState } = useSelector(rootSelectors);

  const [
    operationalContextFormLoadingState,
    setOperationalContextFormLoadingState,
  ] = useState(false);
  const [showAdvertisers, setShowAdvertisers] = useState(false);

  const onFinish = useCallback(() => {
    const formData = form.getFieldsValue();
    const requestedState = formData.preferredOperationalContextState;

    dispatch(setOperationalContextStateAction(requestedState));
  }, [dispatch, form]);

  return (
    <>
      <Helmet>
        <title>{i18n._(t`Inloggen als`)}</title>
      </Helmet>

      <ContentContainer>
        <Spinner spinning={operationalContextFormLoadingState}>
          <Row gutter={[24, 0]} style={{ marginTop: 40 }}>
            <Col span={18} offset={3}>
              <Container>
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <Typography.Title className={styles.typographyCenter}>
                      <Trans>Inloggen als</Trans>
                    </Typography.Title>
                  </Col>

                  <Col span={20} offset={2}>
                    <Typography.Paragraph className={styles.typographyCenter}>
                      <Trans>
                        Met behulp van onderstaande zoek-veld(en) kun je een
                        organisatie kiezen. Wanneer je wilt inloggen als
                        adverteerder, kies je eerst voor de organisatie
                        &quot;Klantportal adverteerder&quot; en vervolgens kies
                        je de gewenste adverteerder door ernaar te zoeken.
                      </Trans>
                    </Typography.Paragraph>
                  </Col>
                </Row>

                <Form
                  form={form}
                  onFinish={onFinish}
                  layout="vertical"
                  initialValues={{
                    preferredOperationalContextState: operationalContextState,
                  }}
                >
                  <Row gutter={[24, 0]} justify="center">
                    <Col span={12} lg={8}>
                      <OperationalContextStateForm
                        fieldName="preferredOperationalContextState"
                        autoFocus
                        setLoadingState={setOperationalContextFormLoadingState}
                        setShowAdvertisers={setShowAdvertisers}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[24, 0]}>
                    <Col span={14} lg={10} offset={6}>
                      <Form.Item name="submit">
                        <Row gutter={[8, 0]} justify="end">
                          <Button
                            mode="primary"
                            type="primary"
                            htmlType="submit"
                          >
                            {showAdvertisers ? (
                              <Trans>Kies adverteerder</Trans>
                            ) : (
                              <Trans>Kies organisatie</Trans>
                            )}
                          </Button>
                        </Row>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Container>
            </Col>
          </Row>
        </Spinner>
      </ContentContainer>
    </>
  );
});

export default OrganisationContainer;
