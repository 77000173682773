import { createSelector, createStructuredSelector } from "reselect";

import { Loading, ReduxStoreState } from "../../../store/base";
import { StoreModel } from "../../../store/models";

export const deleteInstructionsStoreSelector = ({
  deleteInstruction,
}: StoreModel): Loading => deleteInstruction;

export const deleteInstructionsStateSelector = createSelector(
  [deleteInstructionsStoreSelector],
  (deleteInstruction: Loading | undefined): ReduxStoreState | undefined =>
    deleteInstruction?.state
);

export const instructionsForConceptSelector = createStructuredSelector({
  deleteInstructionsState: deleteInstructionsStateSelector,
});
