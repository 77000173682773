/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  GetOrganisationCodesResponseData,
  GetOrganisationCodesResponseDataFromJSON,
  GetOrganisationCodesResponseDataFromJSONTyped,
  GetOrganisationCodesResponseDataToJSON,
} from "./";

/**
 *
 * @export
 * @interface GetOrganisationCodesResponse
 */
export interface GetOrganisationCodesResponse {
  /**
   *
   * @type {GetOrganisationCodesResponseData}
   * @memberof GetOrganisationCodesResponse
   */
  data: GetOrganisationCodesResponseData;
}

export function GetOrganisationCodesResponseFromJSON(
  json: any
): GetOrganisationCodesResponse {
  return GetOrganisationCodesResponseFromJSONTyped(json, false);
}

export function GetOrganisationCodesResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetOrganisationCodesResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: GetOrganisationCodesResponseDataFromJSON(json["data"]),
  };
}

export function GetOrganisationCodesResponseToJSON(
  value?: GetOrganisationCodesResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: GetOrganisationCodesResponseDataToJSON(value.data),
  };
}
