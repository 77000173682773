/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface DocumentUser
 */
export interface DocumentUser {
  /**
   *
   * @type {string}
   * @memberof DocumentUser
   */
  userName: string;
}

export function DocumentUserFromJSON(json: any): DocumentUser {
  return DocumentUserFromJSONTyped(json, false);
}

export function DocumentUserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DocumentUser {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    userName: json["userName"],
  };
}

export function DocumentUserToJSON(value?: DocumentUser | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    userName: value.userName,
  };
}
