import { createSelector, createStructuredSelector } from "reselect";

import {
  accountSelector,
  createStructuredAppSelector,
} from "../../shared/selectors";
import { Loading, ReduxStoreState } from "../../store/base";
import {
  LoadingCampaigns,
  LoadingProposalData,
  StoreModel,
} from "../../store/models";
import { campaignListSelector } from "../campaigns/selectors";

const proposalDataFromStoreSelector = ({
  proposalData: proposalDataFromStore,
}: StoreModel): LoadingProposalData => proposalDataFromStore;

const campaignFromStoreSelector = ({
  campaigns: campaignsFromStore,
}: StoreModel): LoadingCampaigns => campaignsFromStore;

const proposalDataSelector = createSelector(
  [proposalDataFromStoreSelector],
  (proposalData: LoadingProposalData) => proposalData?.proposalData
);

const proposalSelector = ({
  proposalGenerate: { state },
}: StoreModel): ReduxStoreState => state;

const proposalUploadForecastFromStoreSelector = ({
  proposalUploadForecast: proposalUploadForecastFromStore,
}: StoreModel): Loading => proposalUploadForecastFromStore;

const analysisUploadFileFromStoreSelector = ({
  analysisUploadFile: analysisUploadFileFromStore,
}: StoreModel): Loading => analysisUploadFileFromStore;

const loadingGenerateFormattedDocumentSelector = createSelector(
  [
    proposalUploadForecastFromStoreSelector,
    analysisUploadFileFromStoreSelector,
  ],
  (proposalUploadForecastLoading, analysisUploadFileLoading): boolean =>
    proposalUploadForecastLoading.loading || analysisUploadFileLoading.loading
);

export const loadingSelector = createSelector(
  [proposalDataFromStoreSelector, campaignFromStoreSelector],
  (proposalData: LoadingProposalData, campaigns: LoadingCampaigns): boolean =>
    (proposalData.loading ?? false) || (campaigns.loading ?? false)
);

export const proposalRootSelector = createStructuredSelector({
  loading: loadingSelector,
  proposalData: proposalDataSelector,
  campaignsData: campaignListSelector,
});

export const proposalRequestSelector = createStructuredAppSelector({
  proposalState: proposalSelector,
  account: accountSelector,
});

export const generateFormattedDocumentRootSelector =
  createStructuredAppSelector({
    loading: loadingGenerateFormattedDocumentSelector,
  });
