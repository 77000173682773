import { Trans } from "@lingui/macro";
import { Affix, Alert } from "antd";
import { memo, useMemo } from "react";

import { SalesPeriod } from "../../../../api";
import { getFormattedDateShort } from "../../../../utils/dateHelper";

interface PeriodClosedTooltipProps {
  selectedSalesPeriod: SalesPeriod;
}

const PeriodClosedTooltip = memo(
  ({ selectedSalesPeriod }: PeriodClosedTooltipProps) => {
    const periodClosedText = useMemo(() => {
      if (
        selectedSalesPeriod.requestEndDate &&
        new Date() > selectedSalesPeriod.salesPeriodEndDate
      ) {
        return (
          <Trans>
            Deze aanvraag is niet meer in te dienen, de periode is gesloten
          </Trans>
        );
      }
      if (selectedSalesPeriod.requestStartDate) {
        return (
          <Trans>
            Je aanvraag is in te dienen op het moment dat de periode open gaat
            op {getFormattedDateShort(selectedSalesPeriod.requestStartDate)}
          </Trans>
        );
      }
      return (
        <Trans>
          Je aanvraag is in te dienen op het moment dat de periode open gaat
        </Trans>
      );
    }, [
      selectedSalesPeriod.requestEndDate,
      selectedSalesPeriod.requestStartDate,
      selectedSalesPeriod.salesPeriodEndDate,
    ]);

    return (
      <Affix className="tooltipAffix" offsetTop={102}>
        <div>
          <div className="ant-tooltip-arrow">
            <span className="ant-tooltip-arrow-content" />
          </div>
          <Alert
            showIcon
            type="info"
            message=""
            className="submitAlert"
            description={periodClosedText}
          />
        </div>
      </Affix>
    );
  }
);

export default PeriodClosedTooltip;
