/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface DocumentResultSuccess
 */
export interface DocumentResultSuccess {
  /**
   *
   * @type {string}
   * @memberof DocumentResultSuccess
   */
  success?: string | null;
}

export function DocumentResultSuccessFromJSON(
  json: any
): DocumentResultSuccess {
  return DocumentResultSuccessFromJSONTyped(json, false);
}

export function DocumentResultSuccessFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DocumentResultSuccess {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: !exists(json, "success") ? undefined : json["success"],
  };
}

export function DocumentResultSuccessToJSON(
  value?: DocumentResultSuccess | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
  };
}
