import { SendOutlined } from "@ant-design/icons";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  Container,
  ContentContainer,
  Form,
  Icons,
  Pageheader,
  Row,
  Spinner,
} from "@ster/ster-toolkit";
import { App as AntApp, Form as AntForm, Col, Input, Space } from "antd";
import { addDays, isAfter, startOfDay } from "date-fns";
// eslint-disable-next-line import/no-extraneous-dependencies
import { FormFinishInfo } from "rc-field-form/lib/FormContext";
import {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  MediumEnum,
  OrderRequest,
  OrderStatus,
  SubOrderRequest,
} from "../../api";
import {
  clearForecastAction,
  receiveSalesPeriodsAction,
} from "../../store/campaignCreate/actions";
import { StoreModel } from "../../store/models";
import { getFirstWorkingDay } from "../../utils/dateHelper";
import OnlineInstructionsForConcept from "../campaignCreate/instructions/OnlineInstructionsForConcept";
import { ValidationResults } from "../campaigns/models";
import SubOrdersContainer from "../campaigns/SubOrdersContainer";
import Notification from "../Notification";
import CampaignBackButton from "../partials/CampaignBackButton";
import { OnlineInstructionsPerSubOrder } from "../partials/OnlineInstructions/types";
import Step from "../partials/Steps/Step";
import Steps from "../partials/Steps/Steps";
import HistoryTooltip from "../partials/Tooltips/HistoryTooltip/HistoryTooltip";
import OrderStatusTooltip from "../partials/Tooltips/OrderstatusTooltip/OrderStatusTooltip";
import styles from "./CampaignEdit.module.less";
import Details from "./Details";
import { campaignEditRootSelector } from "./selectors";
import UpdateModal from "./subOrder/UpdateModal";
import { hasChanges } from "./utils";

interface CampaignEditProps {
  onAddForecastConversionGroup: (value: string) => void;
  onDeleteForecastConversionGroup: (value: string) => void;
  onDeleteSubOrder: (id: number) => void;
  onAddSubOrder: (subOrder: Partial<SubOrderRequest>) => void;
  onChangeSubOrder: (subOrder: Partial<SubOrderRequest>) => void;
  onFormFinish: (
    name: string,
    { forms }: FormFinishInfo,
    handleFormValid: (
      formName: string,
      validationResults: ValidationResults[]
    ) => void
  ) => void;
  orderRequest: OrderRequest;
  updateOrderRequest: (orderRequest: OrderRequest) => void;
  onCancel: () => void;
}

const CampaignEdit = memo(
  ({
    onAddForecastConversionGroup,
    onAddSubOrder,
    onChangeSubOrder,
    onDeleteForecastConversionGroup,
    onDeleteSubOrder,
    onFormFinish,
    orderRequest,
    updateOrderRequest,
    onCancel,
  }: CampaignEditProps) => {
    const { i18n } = useLingui();
    const dispatch = useDispatch();
    const { message } = AntApp.useApp();
    const detailsRef = useRef<HTMLInputElement>(null);
    const subOrdersRef = useRef<HTMLInputElement>(null);
    const commentsRef = useRef<HTMLDivElement>(null);
    const {
      medium: mediumFromParams,
      orderId: orderIdFromParams,
      subOrderId: subOrderIdFromParams,
    } = useParams<{
      medium?: string;
      orderId?: string;
      subOrderId?: string;
    }>();

    const medium = mediumFromParams as MediumEnum;
    const orderId = Number(orderIdFromParams ?? 0);
    const subOrderId = Number(subOrderIdFromParams ?? 0);
    const [showFinalizeModal, setShowFinalizeModal] = useState(false);
    const { campaign, loading, settings, selectedSalesPeriod } = useSelector(
      (state: StoreModel) => campaignEditRootSelector(state, orderId)
    );

    const handleCloseFinalizeModal = useCallback(() => {
      setShowFinalizeModal(false);
    }, []);

    useEffect(() => {
      setTimeout(
        () => subOrdersRef.current?.scrollIntoView({ block: "start" }),
        100
      );
    }, []);

    useEffect(() => {
      dispatch(receiveSalesPeriodsAction.request());

      // Verwijder eventuele bestaande prognoses bij het openen
      dispatch(clearForecastAction());
    }, [dispatch]);

    const upsertOnlineInstruction = useCallback(
      (newInstructions: OnlineInstructionsPerSubOrder[]) => {
        newInstructions.forEach((s) => {
          onChangeSubOrder({
            ...orderRequest.subOrders?.find((o) => o.id === s.subOrderId),
            onlineInstructionsPerPeriod: s.instructionsPerPeriod,
          });
        });
      },
      [onChangeSubOrder, orderRequest.subOrders]
    );

    const deleteOnlineInstruction = useCallback(
      (idSubOrder: number) => {
        onChangeSubOrder({
          ...orderRequest.subOrders?.find((o) => o.id === idSubOrder),
          onlineInstructionsPerPeriod: undefined,
        });
      },
      [onChangeSubOrder, orderRequest.subOrders]
    );

    const handleFinalize = useCallback(() => {
      setShowFinalizeModal(true);
    }, []);

    const isEdited = useMemo(() => {
      if (!campaign?.subOrders || !orderRequest.subOrders) {
        return false;
      }

      return hasChanges(campaign, orderRequest);
    }, [campaign, orderRequest]);

    const handleFormValid = useCallback(
      (name: string, results: ValidationResults[]) => {
        const errors = results.filter((r) => !r.valid);
        if (errors.length > 0) {
          // Afhandeling als er een incorrect veld in 1 van de formulier is
          message.error(i18n._(t`Niet alle velden zijn correct ingevuld`));
        } else if (name === "finalize") {
          // indienen van de wijzigingen
          handleFinalize();
          detailsRef.current?.scrollIntoView({ block: "end" });
        }
      },
      [handleFinalize, i18n, message]
    );

    const handleFormFinish = useCallback(
      (name: string, finishInfo: FormFinishInfo) => {
        // Alleen afhandeling als er op een submit knop in een save-formulier gedrukt wordt
        onFormFinish(name, finishInfo, handleFormValid);
      },
      [handleFormValid, onFormFinish]
    );

    const salesPeriod = useMemo(() => {
      if (!selectedSalesPeriod) {
        return undefined;
      }

      let firstWorkingDay;
      if (medium === MediumEnum.Inter) {
        firstWorkingDay = startOfDay(addDays(new Date(), 1));
      } else {
        // Periode moet in een van de startmaanden blijven, maar wel 2 werkdagen in de toekomst blijven
        firstWorkingDay = startOfDay(getFirstWorkingDay(new Date()));
      }

      const salesPeriodStartDate = isAfter(
        selectedSalesPeriod.salesPeriodStartDate,
        firstWorkingDay
      )
        ? selectedSalesPeriod.salesPeriodStartDate
        : firstWorkingDay;

      return {
        ...selectedSalesPeriod,
        salesPeriodStartDate,
      };
    }, [medium, selectedSalesPeriod]);

    const handleCommentsChange = useCallback(
      (event: ChangeEvent<HTMLTextAreaElement>) => {
        updateOrderRequest({ ...orderRequest, comments: event.target.value });
      },
      [orderRequest, updateOrderRequest]
    );

    const handleScrollToComments = useCallback(() => {
      commentsRef.current?.scrollIntoView({ block: "start" });
    }, []);

    const pageTitle = useMemo(
      () =>
        orderRequest.campaignName
          ? orderRequest.campaignName
          : i18n._(t`Wijzig Campagne`),
      [orderRequest, i18n]
    );

    return (
      <>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>

        <AntForm.Provider onFormFinish={handleFormFinish}>
          <Pageheader
            title={pageTitle}
            icon={
              <Icons.CampaignIcon
                width="100%"
                height="100%"
                fill="rgba(129, 176, 210, 0.2)"
              />
            }
          >
            <Space wrap>
              <Button mode="tertiary" disabled={loading} onClick={onCancel}>
                <Icons.CloseIcon fill="#008ccc" />
                <Trans>Annuleren</Trans>
              </Button>
              <Form name="finalize" className={styles.save}>
                <Form.Item>
                  <Button
                    mode="primary"
                    htmlType="submit"
                    disabled={loading || !isEdited}
                  >
                    <SendOutlined color="#fff" />
                    <Trans>Wijziging indienen</Trans>
                  </Button>
                </Form.Item>
              </Form>
            </Space>
          </Pageheader>
          <Spinner spinning={loading}>
            <ContentContainer className="content">
              <Notification />
              <div className={styles.topContainer}>
                <div className="back-link">
                  <CampaignBackButton />
                </div>
                <div className={styles.right}>
                  {orderRequest.auditLogs && (
                    <HistoryTooltip logs={orderRequest.auditLogs} />
                  )}
                  <OrderStatusTooltip
                    status={orderRequest.requestStatus ?? OrderStatus.Submitted}
                  />
                </div>
              </div>

              <Container>
                <Steps>
                  <Step
                    status="finished"
                    title={i18n._(t`gegevens`)}
                    allowEdit={false}
                    showEdit={false}
                  >
                    <Row>
                      <Col lg={20} md={24}>
                        <div ref={detailsRef}>
                          {settings.settings && (
                            <Details
                              order={orderRequest}
                              settings={settings.settings}
                              onUpdateOrder={updateOrderRequest}
                            />
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Step>
                  <Step
                    status="finished"
                    title={i18n._(t`pakketten`)}
                    allowEdit
                    showEdit={false}
                  >
                    <div ref={subOrdersRef} id="suborders">
                      {salesPeriod && (
                        <SubOrdersContainer
                          onAddForecastConversionGroup={
                            onAddForecastConversionGroup
                          }
                          onDeleteForecastConversionGroup={
                            onDeleteForecastConversionGroup
                          }
                          onDeleteSubOrder={onDeleteSubOrder}
                          onSubOrderChange={onChangeSubOrder}
                          onAddNewSubOrder={onAddSubOrder}
                          selectedSalesPeriod={salesPeriod}
                          subOrderId={subOrderId}
                          orderRequest={orderRequest}
                          orderRequestOriginal={campaign}
                          onScrollToComments={handleScrollToComments}
                          isEditCampaign
                        />
                      )}
                    </div>
                  </Step>
                  {settings.settings?.enableInstructionsFromRequest &&
                    orderRequest.medium === MediumEnum.Inter && (
                      <Step
                        status="finished"
                        title={i18n._(t`uitzendinstructies`)}
                        allowEdit={false}
                        showEdit={false}
                      >
                        <div>
                          <OnlineInstructionsForConcept
                            orderRequest={orderRequest}
                            onSave={upsertOnlineInstruction}
                            onDelete={deleteOnlineInstruction}
                            isEditCampaign
                          />
                        </div>
                      </Step>
                    )}

                  <Step
                    status="finished"
                    title={i18n._(t`Opmerkingen`)}
                    allowEdit
                    showEdit={false}
                  >
                    <div ref={commentsRef}>
                      <Input.TextArea
                        rows={3}
                        placeholder={i18n._(
                          t`Extra opmerkingen over de wijziging.`
                        )}
                        onBlur={handleCommentsChange}
                      />
                    </div>
                  </Step>
                </Steps>
                {showFinalizeModal && (
                  <UpdateModal
                    order={orderRequest}
                    onClose={handleCloseFinalizeModal}
                  />
                )}
              </Container>
            </ContentContainer>
          </Spinner>
        </AntForm.Provider>
      </>
    );
  }
);

export default CampaignEdit;
