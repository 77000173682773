import { OrderListItem, ProposalInitialRequest } from "../api";

// eslint-disable-next-line import/prefer-default-export
export const mapToProposalInitialRequest = (
  orderListItem: OrderListItem
): ProposalInitialRequest => ({
  from: new Date(orderListItem.startDate),
  to: new Date(orderListItem.endDate),
  initialRequestId: orderListItem.id,
  medium: orderListItem.medium,
});
