/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CampaignImportMessage
 */
export interface CampaignImportMessage {
  /**
   *
   * @type {string}
   * @memberof CampaignImportMessage
   */
  message: string;
  /**
   *
   * @type {number}
   * @memberof CampaignImportMessage
   */
  subOrderIndex: number;
  /**
   *
   * @type {string}
   * @memberof CampaignImportMessage
   */
  packageCode: string;
}

export function CampaignImportMessageFromJSON(
  json: any
): CampaignImportMessage {
  return CampaignImportMessageFromJSONTyped(json, false);
}

export function CampaignImportMessageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CampaignImportMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    message: json["message"],
    subOrderIndex: json["subOrderIndex"],
    packageCode: json["packageCode"],
  };
}

export function CampaignImportMessageToJSON(
  value?: CampaignImportMessage | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    message: value.message,
    subOrderIndex: value.subOrderIndex,
    packageCode: value.packageCode,
  };
}
