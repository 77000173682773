/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  CampaignTotals,
  CampaignTotalsFromJSON,
  CampaignTotalsFromJSONTyped,
  CampaignTotalsToJSON,
  ForecastsResult,
  ForecastsResultFromJSON,
  ForecastsResultFromJSONTyped,
  ForecastsResultToJSON,
} from "./";

/**
 *
 * @export
 * @interface ProposalData
 */
export interface ProposalData {
  /**
   *
   * @type {ForecastsResult}
   * @memberof ProposalData
   */
  forecasts?: ForecastsResult;
  /**
   *
   * @type {CampaignTotals}
   * @memberof ProposalData
   */
  totals: CampaignTotals;
}

export function ProposalDataFromJSON(json: any): ProposalData {
  return ProposalDataFromJSONTyped(json, false);
}

export function ProposalDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProposalData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    forecasts: !exists(json, "forecasts")
      ? undefined
      : ForecastsResultFromJSON(json["forecasts"]),
    totals: CampaignTotalsFromJSON(json["totals"]),
  };
}

export function ProposalDataToJSON(value?: ProposalData | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    forecasts: ForecastsResultToJSON(value.forecasts),
    totals: CampaignTotalsToJSON(value.totals),
  };
}
