import { t } from "@lingui/macro";
import { formatDate, formatNumber } from "@ster/ster-toolkit";
import moment from "moment";

import { MediumEnum, RunningState, TimeBand } from "../../../../api";
import { formatTime } from "../../../../utils/dateHelper";

export const formatDatePeriod = (startDate: Date, endDate: Date): string => {
  const formattedStartDate = formatDate(startDate, "d MMM yyyy");
  const formattedEndDate = formatDate(endDate, "d MMM yyyy");
  return `${formattedStartDate} - ${formattedEndDate}`;
};

export const formatTimePeriod = ({ startTime, endTime }: TimeBand): string => {
  const start = formatTime(startTime);
  const end = formatTime(endTime);
  return `${start} - ${end}`;
};

export const getProgressDate = (
  startDate: Date,
  endDate: Date,
  now: Date | undefined = undefined
): number => {
  const start = moment(startDate).startOf("day");
  const end = moment(endDate).endOf("day");
  const totalDays = Math.ceil(end.diff(start, "days", true));

  // vandaag telt **niet** mee als geweest
  const n = (now ? moment(now) : moment()).startOf("day");

  // eslint-disable-next-line no-nested-ternary
  const daysSinceStart = n.isBetween(start, end, "days", "[]")
    ? Math.ceil(n.diff(start, "days", true))
    : n.isAfter(end, "days")
      ? totalDays
      : 0;
  return Math.ceil((daysSinceStart / totalDays) * 100);
};

export const getProgress = (booked: number, requested: number): number =>
  requested >= 0 ? Math.round((booked / requested) * 100) : 0;

export const getRunningStateTitle = (
  state: RunningState,
  medium: MediumEnum | undefined = undefined,
  hasNacalc = false
): string => {
  switch (state) {
    case RunningState.Forecast:
      return t`Prognose`;
    case RunningState.Combination:
      return t`Realisatie / Geboekt`;
    case RunningState.Realization:
      return medium === MediumEnum.Radio && !hasNacalc
        ? t`Prognose`
        : t`Realisatie`;
    case RunningState.Unkown:
    default:
      return t`Realisatie`;
  }
};

export const getRunningStateNumber = (
  prefix: string,
  predictedValue: number,
  achievedValue: number,
  state: RunningState,
  decimals = 0
): string => {
  switch (state) {
    case RunningState.Forecast:
      return `${prefix} ${formatNumber(predictedValue, decimals)}`;
    case RunningState.Combination:
      return `${prefix} ${formatNumber(
        achievedValue,
        decimals
      )} / ${formatNumber(predictedValue, decimals)}`;
    case RunningState.Realization:
    case RunningState.Unkown:
    default:
      return `${prefix} ${formatNumber(achievedValue, decimals)}`;
  }
};
