import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  ComponentLocalization,
  ContentContainer,
  MediaRadioGroup,
  Option,
  RangePicker,
  Row,
  Select,
  Typography,
} from "@ster/ster-toolkit";
import { Col, RadioChangeEvent, Space } from "antd";
import { SelectValue } from "antd/lib/select";
import classNames from "classnames";
import { isBefore } from "date-fns";
import { memo, useCallback, useState } from "react";
import { useSelector } from "react-redux";

import { StoreModel } from "../../store/models";
import { RangeValueType, getLanguage } from "../../utils";
import { minCommercialDate } from "../../utils/constants";
import shared from "../Shared.module.less";
import Commercials from "./Commercials";
import { CommercialListProps } from "./models";
import { commercialsFilterSelector } from "./selectors";

const CommercialList = memo(
  ({
    byAdvertiser,
    period,
    onPeriodChange,
    medium,
    onMediumChange,
    availableMediumTypes,
  }: CommercialListProps): React.ReactElement => {
    const { i18n } = useLingui();
    const [advertiser, setAdvertiser] = useState(0);
    const handleSelectAdvertiser = useCallback((value: SelectValue): void => {
      setAdvertiser(value as number);
    }, []);

    const [product, setProduct] = useState(0);
    const handleSelectProduct = useCallback((value: SelectValue): void => {
      setProduct(value as number);
    }, []);

    const { filteredAdvertisers, products } = useSelector((state: StoreModel) =>
      commercialsFilterSelector(state, { advertiser, medium })
    );

    const handleMediumChange = useCallback(
      ({ target }: RadioChangeEvent): void => onMediumChange(target.value),
      [onMediumChange]
    );

    const isDateDisabled = useCallback(
      (currentDate: Date): boolean => isBefore(currentDate, minCommercialDate),
      []
    );

    const handlePeriodChange = useCallback(
      (dates: RangeValueType<Date>): void => {
        if (dates) {
          onPeriodChange(dates[0], dates[1]);
        } else {
          onPeriodChange(null, null);
        }
      },
      [onPeriodChange]
    );

    return (
      <ContentContainer>
        <Row
          className={classNames(shared.filterrow, "form-filtering-paging")}
          justify="space-between"
          align="middle"
          gutter={[12, 24]}
        >
          <Col flex="470px">
            <MediaRadioGroup
              value={medium}
              mediaTypes={availableMediumTypes}
              onChange={handleMediumChange}
              className={shared.mediaRadioButtons}
              labels={{
                online: i18n._(t`Online`),
                radio: i18n._(t`Radio`),
                tv: i18n._(t`Televisie`),
                all: i18n._(t`Alles`),
              }}
              includeAll
            />
          </Col>
          <Col flex="auto">
            <Space className={shared.filterspacer} direction="horizontal">
              <Select.Search
                value={advertiser}
                onChange={handleSelectAdvertiser}
                disabled={product > 0}
                className={shared.fixedWidthSelect}
                popupMatchSelectWidth={false}
                optionFilterProp="children"
              >
                <Option key="all" value={0}>
                  <Trans>Alle adverteerders</Trans>
                </Option>
                {byAdvertiser.map((a) => (
                  <Option key={a.advertiserId} value={a.advertiserId}>
                    {a.advertiserName}
                  </Option>
                ))}
              </Select.Search>
              <Select.Search
                value={product}
                onChange={handleSelectProduct}
                className={shared.fixedWidthSelect}
                popupMatchSelectWidth={false}
                optionFilterProp="children"
              >
                <Option key="all" value={0}>
                  <Trans>Alle sternummers</Trans>
                </Option>
                {products.map((p) => (
                  <Option key={p.id} value={p.id}>
                    {p.id}: {p.description}
                  </Option>
                ))}
              </Select.Search>
              <RangePicker
                allowClear={false}
                disabledDate={isDateDisabled}
                onChange={handlePeriodChange}
                value={
                  period.from && period.to
                    ? [period.from, period.to]
                    : undefined
                }
                componentLocale={getLanguage() as ComponentLocalization}
              />
            </Space>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {filteredAdvertisers.length === 0 ? (
              <Trans>
                <Typography.Paragraph>
                  Er zijn geen commercials voor deze selectie beschikbaar.
                </Typography.Paragraph>
              </Trans>
            ) : (
              filteredAdvertisers
                .filter(
                  (a) =>
                    product === 0 || a.products.some((p) => p.id === product)
                )
                .map((a) => (
                  <Commercials
                    key={a.advertiserId}
                    byAdvertiser={a}
                    product={product}
                  />
                ))
            )}
          </Col>
        </Row>
      </ContentContainer>
    );
  }
);

export default CommercialList;
