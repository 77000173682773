/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Package,
  PackageChoiceEnum,
  PackageChoiceEnumFromJSON,
  PackageChoiceEnumFromJSONTyped,
  PackageChoiceEnumToJSON,
  PackageFromJSON,
  PackageFromJSONTyped,
  PackageToJSON,
} from "./";

/**
 *
 * @export
 * @interface PackageResult
 */
export interface PackageResult {
  /**
   *
   * @type {PackageChoiceEnum}
   * @memberof PackageResult
   */
  type: PackageChoiceEnum;
  /**
   *
   * @type {Array<Package>}
   * @memberof PackageResult
   */
  packages: Array<Package>;
}

export function PackageResultFromJSON(json: any): PackageResult {
  return PackageResultFromJSONTyped(json, false);
}

export function PackageResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PackageResult {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: PackageChoiceEnumFromJSON(json["type"]),
    packages: (json["packages"] as Array<any>).map(PackageFromJSON),
  };
}

export function PackageResultToJSON(value?: PackageResult | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: PackageChoiceEnumToJSON(value.type),
    packages: (value.packages as Array<any>).map(PackageToJSON),
  };
}
