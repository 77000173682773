import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { ContentContainer, Spinner } from "@ster/ster-toolkit";
import { App as AntApp } from "antd";
import { memo, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { StoreModel } from "../../store/models";
import {
  deleteUserAction,
  receiveUsersExportAction,
} from "../../store/users/actions";
import { escapeRegex } from "../../utils";
import { UsersFilter } from "./models";
import { usersRootSelector } from "./selectors";
import UsersList from "./UsersList";

const UsersListContainer = memo(
  ({
    setFilter,
    filter,
  }: {
    setFilter: (value: UsersFilter) => void;
    filter: UsersFilter;
  }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { modal } = AntApp.useApp();

    const { loadingSingleUser = false } = useSelector(
      ({ user }: StoreModel) => ({
        loadingSingleUser: user.loading,
      })
    );

    // map state to props
    const { users, organisations, loading, downloading } =
      useSelector(usersRootSelector);

    const handleSearchFilter = useCallback(
      (search: string) => {
        setFilter({ ...filter, search });
      },
      [filter, setFilter]
    );

    const handleOrganisationFilter = useCallback(
      (organisationCode: string) => {
        setFilter({ ...filter, organisationCode });
      },
      [filter, setFilter]
    );

    const filteredUsers = useMemo(
      () =>
        users
          .filter(
            ({ userInfo: { organisationCode } }) =>
              !filter.organisationCode ||
              organisationCode === filter.organisationCode
          )
          .filter(({ email, userInfo: { fullName } }) => {
            if (!filter.search) {
              return true;
            }

            const regExp = new RegExp(`${escapeRegex(filter.search)}`, "i");
            return regExp.test(email) || regExp.test(fullName ?? "");
          }),
      [filter.organisationCode, filter.search, users]
    );

    const filteredOrgCodes = useMemo(
      () =>
        Array.from(
          new Set(filteredUsers.map((u) => u.userInfo.organisationCode))
        ),
      [filteredUsers]
    );

    const handleExport = useCallback(() => {
      dispatch(receiveUsersExportAction.request());
    }, [dispatch]);

    const handleNewUser = useCallback(() => {
      navigate("/admin/users/new");
    }, [navigate]);

    const handleDeleteUser = useCallback(
      (userIdToDelete: string) => {
        const userToDelete = users.find((u) => u.id === userIdToDelete);
        if (userToDelete) {
          modal.confirm({
            title: i18n._(t`Weet je het zeker?`),
            content: userToDelete.active
              ? i18n._(
                  t`Wil je de gebruiker ${userToDelete.email} inactief maken?`
                )
              : i18n._(
                  t`Wil je de gebruiker ${userToDelete.email} verwijderen?`
                ),
            okText: userToDelete.active
              ? i18n._(t`Inactief maken`)
              : i18n._(t`Verwijderen`),
            onOk: () => {
              dispatch(
                deleteUserAction.request({
                  userId: userToDelete.id,
                  active: userToDelete.active,
                })
              );
            },
            cancelText: i18n._(t`Annuleren`),
          });
        }
      },
      [dispatch, modal, users]
    );

    return (
      <ContentContainer>
        <Spinner spinning={loading || loadingSingleUser || downloading}>
          <UsersList
            users={filteredUsers}
            organisations={organisations.filter(({ code }) =>
              filteredOrgCodes.includes(code)
            )}
            onSearch={handleSearchFilter}
            onOrganisationSelect={handleOrganisationFilter}
            onDelete={handleDeleteUser}
            filter={filter}
            onExport={handleExport}
            onCreate={handleNewUser}
          />
        </Spinner>
      </ContentContainer>
    );
  }
);

export default UsersListContainer;
