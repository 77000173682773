/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface AvailableBreak
 */
export interface AvailableBreak {
  /**
   *
   * @type {number}
   * @memberof AvailableBreak
   */
  baseBreakPrice?: number;
  /**
   *
   * @type {string}
   * @memberof AvailableBreak
   */
  breakId: string;
  /**
   *
   * @type {number}
   * @memberof AvailableBreak
   */
  predictedRating?: number;
  /**
   *
   * @type {number}
   * @memberof AvailableBreak
   */
  predictedRatingSecondaryTargetGroup?: number;
  /**
   *
   * @type {number}
   * @memberof AvailableBreak
   */
  selectivity?: number;
  /**
   *
   * @type {number}
   * @memberof AvailableBreak
   */
  selectivitySurcharge: number;
  /**
   *
   * @type {string}
   * @memberof AvailableBreak
   */
  channelDescr: string;
  /**
   *
   * @type {number}
   * @memberof AvailableBreak
   */
  grossSpotPrice?: number;
  /**
   *
   * @type {number}
   * @memberof AvailableBreak
   */
  nettSpotPrice: number;
  /**
   *
   * @type {string}
   * @memberof AvailableBreak
   */
  programmeAfter: string;
  /**
   *
   * @type {string}
   * @memberof AvailableBreak
   */
  programmeBefore: string;
  /**
   *
   * @type {Date}
   * @memberof AvailableBreak
   */
  schedDate: Date;
  /**
   *
   * @type {Date}
   * @memberof AvailableBreak
   */
  schedStartTime: Date;
  /**
   *
   * @type {number}
   * @memberof AvailableBreak
   */
  selectivityDemandPerc?: number;
  /**
   *
   * @type {boolean}
   * @memberof AvailableBreak
   */
  disabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AvailableBreak
   */
  isDisabledTop2000?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AvailableBreak
   */
  booked?: boolean;
  /**
   *
   * @type {string}
   * @memberof AvailableBreak
   */
  positionFreeString?: string | null;
  /**
   *
   * @type {string}
   * @memberof AvailableBreak
   */
  preferredPosition?: string | null;
  /**
   *
   * @type {number}
   * @memberof AvailableBreak
   */
  spotId?: number;
  /**
   *
   * @type {string}
   * @memberof AvailableBreak
   */
  readonly uniqueId: string;
}

export function AvailableBreakFromJSON(json: any): AvailableBreak {
  return AvailableBreakFromJSONTyped(json, false);
}

export function AvailableBreakFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AvailableBreak {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    baseBreakPrice: !exists(json, "baseBreakPrice")
      ? undefined
      : json["baseBreakPrice"],
    breakId: json["breakId"],
    predictedRating: !exists(json, "predictedRating")
      ? undefined
      : json["predictedRating"],
    predictedRatingSecondaryTargetGroup: !exists(
      json,
      "predictedRatingSecondaryTargetGroup"
    )
      ? undefined
      : json["predictedRatingSecondaryTargetGroup"],
    selectivity: !exists(json, "selectivity") ? undefined : json["selectivity"],
    selectivitySurcharge: json["selectivitySurcharge"],
    channelDescr: json["channelDescr"],
    grossSpotPrice: !exists(json, "grossSpotPrice")
      ? undefined
      : json["grossSpotPrice"],
    nettSpotPrice: json["nettSpotPrice"],
    programmeAfter: json["programmeAfter"],
    programmeBefore: json["programmeBefore"],
    schedDate: new Date(json["schedDate"]),
    schedStartTime: new Date(json["schedStartTime"]),
    selectivityDemandPerc: !exists(json, "selectivityDemandPerc")
      ? undefined
      : json["selectivityDemandPerc"],
    disabled: !exists(json, "disabled") ? undefined : json["disabled"],
    isDisabledTop2000: !exists(json, "isDisabledTop2000")
      ? undefined
      : json["isDisabledTop2000"],
    booked: !exists(json, "booked") ? undefined : json["booked"],
    positionFreeString: !exists(json, "positionFreeString")
      ? undefined
      : json["positionFreeString"],
    preferredPosition: !exists(json, "preferredPosition")
      ? undefined
      : json["preferredPosition"],
    spotId: !exists(json, "spotId") ? undefined : json["spotId"],
    uniqueId: json["uniqueId"],
  };
}

export function AvailableBreakToJSON(value?: AvailableBreak | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    baseBreakPrice: value.baseBreakPrice,
    breakId: value.breakId,
    predictedRating: value.predictedRating,
    predictedRatingSecondaryTargetGroup:
      value.predictedRatingSecondaryTargetGroup,
    selectivity: value.selectivity,
    selectivitySurcharge: value.selectivitySurcharge,
    channelDescr: value.channelDescr,
    grossSpotPrice: value.grossSpotPrice,
    nettSpotPrice: value.nettSpotPrice,
    programmeAfter: value.programmeAfter,
    programmeBefore: value.programmeBefore,
    schedDate: value.schedDate.toISOString(),
    schedStartTime: value.schedStartTime.toISOString(),
    selectivityDemandPerc: value.selectivityDemandPerc,
    disabled: value.disabled,
    isDisabledTop2000: value.isDisabledTop2000,
    booked: value.booked,
    positionFreeString: value.positionFreeString,
    preferredPosition: value.preferredPosition,
    spotId: value.spotId,
  };
}
