/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  CommercialInstructionAlternationType,
  CommercialInstructionAlternationTypeFromJSON,
  CommercialInstructionAlternationTypeFromJSONTyped,
  CommercialInstructionAlternationTypeToJSON,
  CommercialInstructionCommercialsPerPeriod,
  CommercialInstructionCommercialsPerPeriodFromJSON,
  CommercialInstructionCommercialsPerPeriodFromJSONTyped,
  CommercialInstructionCommercialsPerPeriodToJSON,
  CommercialInstructionStatus,
  CommercialInstructionStatusFromJSON,
  CommercialInstructionStatusFromJSONTyped,
  CommercialInstructionStatusToJSON,
  DateSpan,
  DateSpanFromJSON,
  DateSpanFromJSONTyped,
  DateSpanToJSON,
  MediumEnum,
  MediumEnumFromJSON,
  MediumEnumFromJSONTyped,
  MediumEnumToJSON,
} from "./";

/**
 *
 * @export
 * @interface CommercialInstructionRequest
 */
export interface CommercialInstructionRequest {
  /**
   *
   * @type {number}
   * @memberof CommercialInstructionRequest
   */
  id?: number;
  /**
   *
   * @type {CommercialInstructionStatus}
   * @memberof CommercialInstructionRequest
   */
  instructionStatus: CommercialInstructionStatus;
  /**
   *
   * @type {MediumEnum}
   * @memberof CommercialInstructionRequest
   */
  medium: MediumEnum;
  /**
   *
   * @type {number}
   * @memberof CommercialInstructionRequest
   */
  productId: number;
  /**
   *
   * @type {DateSpan}
   * @memberof CommercialInstructionRequest
   */
  period?: DateSpan;
  /**
   *
   * @type {Date}
   * @memberof CommercialInstructionRequest
   */
  creationDate?: Date | null;
  /**
   *
   * @type {string}
   * @memberof CommercialInstructionRequest
   */
  startTime?: string | null;
  /**
   *
   * @type {string}
   * @memberof CommercialInstructionRequest
   */
  endTime?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof CommercialInstructionRequest
   */
  channels?: Array<string> | null;
  /**
   *
   * @type {Array<number>}
   * @memberof CommercialInstructionRequest
   */
  selectedDays?: Array<number> | null;
  /**
   *
   * @type {string}
   * @memberof CommercialInstructionRequest
   */
  instructionInfo?: string | null;
  /**
   *
   * @type {string}
   * @memberof CommercialInstructionRequest
   */
  contactPersonAgencyEmail?: string | null;
  /**
   *
   * @type {CommercialInstructionAlternationType}
   * @memberof CommercialInstructionRequest
   */
  alternationType: CommercialInstructionAlternationType;
  /**
   *
   * @type {Array<CommercialInstructionCommercialsPerPeriod>}
   * @memberof CommercialInstructionRequest
   */
  commercialsPerPeriod: Array<CommercialInstructionCommercialsPerPeriod>;
}

export function CommercialInstructionRequestFromJSON(
  json: any
): CommercialInstructionRequest {
  return CommercialInstructionRequestFromJSONTyped(json, false);
}

export function CommercialInstructionRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CommercialInstructionRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    instructionStatus: CommercialInstructionStatusFromJSON(
      json["instructionStatus"]
    ),
    medium: MediumEnumFromJSON(json["medium"]),
    productId: json["productId"],
    period: !exists(json, "period")
      ? undefined
      : DateSpanFromJSON(json["period"]),
    creationDate: !exists(json, "creationDate")
      ? undefined
      : json["creationDate"] === null
        ? null
        : new Date(json["creationDate"]),
    startTime: !exists(json, "startTime") ? undefined : json["startTime"],
    endTime: !exists(json, "endTime") ? undefined : json["endTime"],
    channels: !exists(json, "channels") ? undefined : json["channels"],
    selectedDays: !exists(json, "selectedDays")
      ? undefined
      : json["selectedDays"],
    instructionInfo: !exists(json, "instructionInfo")
      ? undefined
      : json["instructionInfo"],
    contactPersonAgencyEmail: !exists(json, "contactPersonAgencyEmail")
      ? undefined
      : json["contactPersonAgencyEmail"],
    alternationType: CommercialInstructionAlternationTypeFromJSON(
      json["alternationType"]
    ),
    commercialsPerPeriod: (json["commercialsPerPeriod"] as Array<any>).map(
      CommercialInstructionCommercialsPerPeriodFromJSON
    ),
  };
}

export function CommercialInstructionRequestToJSON(
  value?: CommercialInstructionRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    instructionStatus: CommercialInstructionStatusToJSON(
      value.instructionStatus
    ),
    medium: MediumEnumToJSON(value.medium),
    productId: value.productId,
    period: DateSpanToJSON(value.period),
    creationDate:
      value.creationDate === undefined
        ? undefined
        : value.creationDate === null
          ? null
          : value.creationDate.toISOString(),
    startTime: value.startTime,
    endTime: value.endTime,
    channels: value.channels,
    selectedDays: value.selectedDays,
    instructionInfo: value.instructionInfo,
    contactPersonAgencyEmail: value.contactPersonAgencyEmail,
    alternationType: CommercialInstructionAlternationTypeToJSON(
      value.alternationType
    ),
    commercialsPerPeriod: (value.commercialsPerPeriod as Array<any>).map(
      CommercialInstructionCommercialsPerPeriodToJSON
    ),
  };
}
