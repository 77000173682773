import { MessageDescriptor } from "@lingui/core";
import { msg } from "@lingui/macro";

import { StoreModel } from "../store/models";

export interface ErrorMessages
  extends Record<keyof StoreModel, MessageDescriptor> {
  defaultMessage: MessageDescriptor;
}

const errorMessages: ErrorMessages = {
  account: msg`Je accountgegevens konden niet opgehaald worden.`,
  advertisers: msg`De adverteerders konden niet worden opgehaald.`,
  bookSpot: msg`Er trad een fout op tijdens het verwerken van de blokwijzigingen.`,
  breaks: msg`Vanwege een fout konden de spots niet worden opgehaald.`,
  campaignConfiguration: msg`Er is iets fout gegaan bij het ophalen van de campagneconfiguratie.`,
  importConfiguration: msg`De gebruikte code is niet te importeren voor de gekozen adverteerder en sternummer combinatie.`,
  campaignDetail: msg`Er is iets fout gegaan bij het ophalen van de orderdetails.`,
  campaignExport: msg`Er is iets fout gegaan bij het exporteren.`,
  campaignInstructions: msg`Er is iets fout gegaan bij het verwerken van de uitzendinstructies.`,
  campaigns: msg`Het campagne-overzicht ophalen ging fout.`,
  commercials: msg`Het ophalen van het commercial-overzicht ging fout.`,
  currentSpots: msg`Het ophalen van het overzicht Nu & Straks ging fout.`,
  documents: msg`Het ophalen van het documenten-overzicht ging fout.`,
  invoices: msg`Het ophalen van het factuur-overzicht ging fout.`,
  packages: msg`Het ophalen van de pakketten is fout gegaan.`,
  terms: msg`Er ging iets mis bij het verwerken van de voorwaarden.`,
  user: msg`Het ophalen van deze gebruiker is niet gelukt.`,
  users: msg`Het ophalen van het gebruikers-overzicht ging fout.`,
  forecast: msg`Het ophalen van de prognose ging fout.`,
  proposalGenerate: msg`Er is iets mis gegaan bij het aanvragen van een voorstel.`,

  /* Wanneer een melding niet aanwezig is, deze standaard melding tonen */
  defaultMessage: msg`Probeer het (later) opnieuw. Mocht het probleem aanhouden neem dan contact op met:`,
} as ErrorMessages;

export const errorMessageMapping: Record<string, MessageDescriptor> = {
  "Not all packages from the configuration are available in the system": msg`De gekozen pakketten zijn momenteel niet beschikbaar voor deze adverteerder of dit Sternummer. Neem contact op met Ster, of kies een andere adverteerder.`,
  "Not all packages are available for the chosen organisation": msg`Niet alle pakketten uit het concept zijn beschikbaar voor de gekozen organisatie en bijbehorend Sternummer.`,
};

export default errorMessages;
