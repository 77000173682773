/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  AvailableBreak,
  AvailableBreakFromJSON,
  AvailableBreakToJSON,
  AvailableBreaksRequest,
  AvailableBreaksRequestFromJSON,
  AvailableBreaksRequestToJSON,
  CalculationRequest,
  CalculationRequestFromJSON,
  CalculationRequestToJSON,
  CalculationResult,
  CalculationResultFromJSON,
  CalculationResultToJSON,
  DigitalProgramIListValueResult,
  DigitalProgramIListValueResultFromJSON,
  DigitalProgramIListValueResultToJSON,
  GroupedPackageRadio,
  GroupedPackageRadioFromJSON,
  GroupedPackageRadioToJSON,
  GroupedPackageTv,
  GroupedPackageTvFromJSON,
  GroupedPackageTvToJSON,
  MediumEnum,
  MediumEnumFromJSON,
  MediumEnumToJSON,
  PackageResult,
  PackageResultFromJSON,
  PackageResultToJSON,
  ValueListIEnumerableValueResult,
  ValueListIEnumerableValueResultFromJSON,
  ValueListIEnumerableValueResultToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface ApiV1RequestCalculatePostRequest {
  calculationRequest?: CalculationRequest;
  medium?: MediumEnum;
}

export interface ApiV1RequestGetavailablespotsPostRequest {
  availableBreaksRequest?: AvailableBreaksRequest;
}

export interface ApiV1RequestGetpackagesGetRequest {
  medium?: MediumEnum;
  product?: string;
  from?: Date;
  to?: Date;
  orderRequestDate?: Date;
}

export interface ApiV1RequestGetpackagesGroupedRadioYearGetRequest {
  year: number;
}

export interface ApiV1RequestGetpackagesGroupedTvYearGetRequest {
  year: number;
}

/**
 *
 */
export class RequestApi extends runtime.BaseAPI {
  /**
   */
  async apiV1RequestCalculatePostRaw(
    requestParameters: ApiV1RequestCalculatePostRequest
  ): Promise<runtime.ApiResponse<CalculationResult>> {
    const queryParameters: any = {};

    if (requestParameters.medium !== undefined) {
      queryParameters["medium"] = requestParameters.medium;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Request/calculate`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: CalculationRequestToJSON(requestParameters.calculationRequest),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CalculationResultFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1RequestCalculatePost(
    requestParameters: ApiV1RequestCalculatePostRequest
  ): Promise<CalculationResult> {
    const response = await this.apiV1RequestCalculatePostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1RequestDigitalProgramsGetRaw(): Promise<
    runtime.ApiResponse<DigitalProgramIListValueResult>
  > {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Request/digital/programs`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      DigitalProgramIListValueResultFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1RequestDigitalProgramsGet(): Promise<DigitalProgramIListValueResult> {
    const response = await this.apiV1RequestDigitalProgramsGetRaw();
    return await response.value();
  }

  /**
   */
  async apiV1RequestDigitalSitesGetRaw(): Promise<
    runtime.ApiResponse<ValueListIEnumerableValueResult>
  > {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Request/digital/sites`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ValueListIEnumerableValueResultFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1RequestDigitalSitesGet(): Promise<ValueListIEnumerableValueResult> {
    const response = await this.apiV1RequestDigitalSitesGetRaw();
    return await response.value();
  }

  /**
   */
  async apiV1RequestGetavailablespotsPostRaw(
    requestParameters: ApiV1RequestGetavailablespotsPostRequest
  ): Promise<runtime.ApiResponse<Array<AvailableBreak>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Request/getavailablespots`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: AvailableBreaksRequestToJSON(
        requestParameters.availableBreaksRequest
      ),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(AvailableBreakFromJSON)
    );
  }

  /**
   */
  async apiV1RequestGetavailablespotsPost(
    requestParameters: ApiV1RequestGetavailablespotsPostRequest
  ): Promise<Array<AvailableBreak>> {
    const response =
      await this.apiV1RequestGetavailablespotsPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1RequestGetpackagesGetRaw(
    requestParameters: ApiV1RequestGetpackagesGetRequest
  ): Promise<runtime.ApiResponse<Array<PackageResult>>> {
    const queryParameters: any = {};

    if (requestParameters.medium !== undefined) {
      queryParameters["medium"] = requestParameters.medium;
    }

    if (requestParameters.product !== undefined) {
      queryParameters["product"] = requestParameters.product;
    }

    if (requestParameters.from !== undefined) {
      queryParameters["from"] = (requestParameters.from as any).toISOString();
    }

    if (requestParameters.to !== undefined) {
      queryParameters["to"] = (requestParameters.to as any).toISOString();
    }

    if (requestParameters.orderRequestDate !== undefined) {
      queryParameters["orderRequestDate"] = (
        requestParameters.orderRequestDate as any
      ).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Request/getpackages`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(PackageResultFromJSON)
    );
  }

  /**
   */
  async apiV1RequestGetpackagesGet(
    requestParameters: ApiV1RequestGetpackagesGetRequest
  ): Promise<Array<PackageResult>> {
    const response =
      await this.apiV1RequestGetpackagesGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1RequestGetpackagesGroupedRadioYearGetRaw(
    requestParameters: ApiV1RequestGetpackagesGroupedRadioYearGetRequest
  ): Promise<runtime.ApiResponse<Array<GroupedPackageRadio>>> {
    if (
      requestParameters.year === null ||
      requestParameters.year === undefined
    ) {
      throw new runtime.RequiredError(
        "year",
        "Required parameter requestParameters.year was null or undefined when calling apiV1RequestGetpackagesGroupedRadioYearGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Request/getpackages/grouped/radio/{year}`.replace(
        `{${"year"}}`,
        encodeURIComponent(String(requestParameters.year))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(GroupedPackageRadioFromJSON)
    );
  }

  /**
   */
  async apiV1RequestGetpackagesGroupedRadioYearGet(
    requestParameters: ApiV1RequestGetpackagesGroupedRadioYearGetRequest
  ): Promise<Array<GroupedPackageRadio>> {
    const response =
      await this.apiV1RequestGetpackagesGroupedRadioYearGetRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   */
  async apiV1RequestGetpackagesGroupedTvYearGetRaw(
    requestParameters: ApiV1RequestGetpackagesGroupedTvYearGetRequest
  ): Promise<runtime.ApiResponse<Array<GroupedPackageTv>>> {
    if (
      requestParameters.year === null ||
      requestParameters.year === undefined
    ) {
      throw new runtime.RequiredError(
        "year",
        "Required parameter requestParameters.year was null or undefined when calling apiV1RequestGetpackagesGroupedTvYearGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Request/getpackages/grouped/tv/{year}`.replace(
        `{${"year"}}`,
        encodeURIComponent(String(requestParameters.year))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(GroupedPackageTvFromJSON)
    );
  }

  /**
   */
  async apiV1RequestGetpackagesGroupedTvYearGet(
    requestParameters: ApiV1RequestGetpackagesGroupedTvYearGetRequest
  ): Promise<Array<GroupedPackageTv>> {
    const response =
      await this.apiV1RequestGetpackagesGroupedTvYearGetRaw(requestParameters);
    return await response.value();
  }
}
