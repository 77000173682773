/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  AnalysisPeriod,
  AnalysisPeriodFromJSON,
  AnalysisPeriodFromJSONTyped,
  AnalysisPeriodToJSON,
  AnalysisTargetGroupRadio,
  AnalysisTargetGroupRadioFromJSON,
  AnalysisTargetGroupRadioFromJSONTyped,
  AnalysisTargetGroupRadioToJSON,
  AnalysisTargetGroupTV,
  AnalysisTargetGroupTVFromJSON,
  AnalysisTargetGroupTVFromJSONTyped,
  AnalysisTargetGroupTVToJSON,
} from "./";

/**
 *
 * @export
 * @interface SpotTrackerRequestInput
 */
export interface SpotTrackerRequestInput {
  /**
   *
   * @type {AnalysisPeriod}
   * @memberof SpotTrackerRequestInput
   */
  period?: AnalysisPeriod;
  /**
   *
   * @type {Array<AnalysisTargetGroupTV>}
   * @memberof SpotTrackerRequestInput
   */
  targetGroupsTv?: Array<AnalysisTargetGroupTV> | null;
  /**
   *
   * @type {Array<AnalysisTargetGroupRadio>}
   * @memberof SpotTrackerRequestInput
   */
  targetGroupsRadio?: Array<AnalysisTargetGroupRadio> | null;
  /**
   *
   * @type {Array<number>}
   * @memberof SpotTrackerRequestInput
   */
  productId?: Array<number> | null;
}

export function SpotTrackerRequestInputFromJSON(
  json: any
): SpotTrackerRequestInput {
  return SpotTrackerRequestInputFromJSONTyped(json, false);
}

export function SpotTrackerRequestInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SpotTrackerRequestInput {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    period: !exists(json, "period")
      ? undefined
      : AnalysisPeriodFromJSON(json["period"]),
    targetGroupsTv: !exists(json, "targetGroupsTv")
      ? undefined
      : json["targetGroupsTv"] === null
        ? null
        : (json["targetGroupsTv"] as Array<any>).map(
            AnalysisTargetGroupTVFromJSON
          ),
    targetGroupsRadio: !exists(json, "targetGroupsRadio")
      ? undefined
      : json["targetGroupsRadio"] === null
        ? null
        : (json["targetGroupsRadio"] as Array<any>).map(
            AnalysisTargetGroupRadioFromJSON
          ),
    productId: !exists(json, "productId") ? undefined : json["productId"],
  };
}

export function SpotTrackerRequestInputToJSON(
  value?: SpotTrackerRequestInput | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    period: AnalysisPeriodToJSON(value.period),
    targetGroupsTv:
      value.targetGroupsTv === undefined
        ? undefined
        : value.targetGroupsTv === null
          ? null
          : (value.targetGroupsTv as Array<any>).map(
              AnalysisTargetGroupTVToJSON
            ),
    targetGroupsRadio:
      value.targetGroupsRadio === undefined
        ? undefined
        : value.targetGroupsRadio === null
          ? null
          : (value.targetGroupsRadio as Array<any>).map(
              AnalysisTargetGroupRadioToJSON
            ),
    productId: value.productId,
  };
}
