/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  DynamicMarketIndexRadio,
  DynamicMarketIndexRadioFromJSON,
  DynamicMarketIndexRadioFromJSONTyped,
  DynamicMarketIndexRadioToJSON,
} from "./";

/**
 *
 * @export
 * @interface OutputPricePerMonth
 */
export interface OutputPricePerMonth {
  /**
   *
   * @type {number}
   * @memberof OutputPricePerMonth
   */
  month: number;
  /**
   *
   * @type {number}
   * @memberof OutputPricePerMonth
   */
  monthIndex: number;
  /**
   *
   * @type {number}
   * @memberof OutputPricePerMonth
   */
  marketIndex: number;
  /**
   *
   * @type {Array<DynamicMarketIndexRadio>}
   * @memberof OutputPricePerMonth
   */
  marketIndexRadio?: Array<DynamicMarketIndexRadio> | null;
  /**
   *
   * @type {number}
   * @memberof OutputPricePerMonth
   */
  outputPrice: number;
  /**
   *
   * @type {number}
   * @memberof OutputPricePerMonth
   */
  discountPercWithMarketIndex: number;
}

export function OutputPricePerMonthFromJSON(json: any): OutputPricePerMonth {
  return OutputPricePerMonthFromJSONTyped(json, false);
}

export function OutputPricePerMonthFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OutputPricePerMonth {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    month: json["month"],
    monthIndex: json["monthIndex"],
    marketIndex: json["marketIndex"],
    marketIndexRadio: !exists(json, "marketIndexRadio")
      ? undefined
      : json["marketIndexRadio"] === null
        ? null
        : (json["marketIndexRadio"] as Array<any>).map(
            DynamicMarketIndexRadioFromJSON
          ),
    outputPrice: json["outputPrice"],
    discountPercWithMarketIndex: json["discountPercWithMarketIndex"],
  };
}

export function OutputPricePerMonthToJSON(
  value?: OutputPricePerMonth | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    month: value.month,
    monthIndex: value.monthIndex,
    marketIndex: value.marketIndex,
    marketIndexRadio:
      value.marketIndexRadio === undefined
        ? undefined
        : value.marketIndexRadio === null
          ? null
          : (value.marketIndexRadio as Array<any>).map(
              DynamicMarketIndexRadioToJSON
            ),
    outputPrice: value.outputPrice,
    discountPercWithMarketIndex: value.discountPercWithMarketIndex,
  };
}
