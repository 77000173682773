import { Trans } from "@lingui/macro";
import { formatNumber, formatToEuro } from "@ster/ster-toolkit";
import { memo, useCallback, useMemo } from "react";

import { AvailableBreak, BreakSelection } from "../../api";
import styles from "./BreakSelectTable.module.less";
import { BreakFilters } from "./models";

const Footer = memo(
  ({
    breaks,
    value,
    filters,
    preferredPositionSurcharge,
    budget,
  }: {
    breaks: AvailableBreak[];
    value: BreakSelection[];
    filters: BreakFilters;
    preferredPositionSurcharge: number;
    budget?: number;
  }) => {
    const hasSecondaryTargetGroup = useMemo(
      () => !!filters.secondaryTargetGroup,
      [filters.secondaryTargetGroup]
    );

    /**
     * Calculate the nett spot price
     */
    const calculateNettSpotPrice = useCallback(
      (
        {
          nettSpotPrice,
          grossSpotPrice,
          selectivitySurcharge,
          booked,
        }: AvailableBreak,
        preferredPosition?: string | null
      ) =>
        nettSpotPrice +
        selectivitySurcharge +
        (booked || !preferredPosition || preferredPosition === "NNNN"
          ? 0
          : (grossSpotPrice ?? 0) * preferredPositionSurcharge),
      [preferredPositionSurcharge]
    );

    const totalNettPrice = useMemo(
      () =>
        value.reduce((previousValue, currentValue) => {
          const availableBreak = breaks.find(
            (b) => b.uniqueId === currentValue.key
          );
          if (!availableBreak) {
            return previousValue;
          }

          return (
            previousValue +
            calculateNettSpotPrice(
              availableBreak,
              currentValue.preferredPosition
            )
          );
        }, 0),
      [breaks, calculateNettSpotPrice, value]
    );

    const totalPredictedRating = useMemo(
      () =>
        value.reduce((previousValue, currentValue) => {
          const availableBreak = breaks.find(
            (b) => b.uniqueId === currentValue.key
          );
          if (!availableBreak) {
            return previousValue;
          }

          return previousValue + (availableBreak.predictedRating ?? 0);
        }, 0),
      [breaks, value]
    );

    const totalSecondaryTargetGroup = useMemo(
      () =>
        value.reduce((previousValue, currentValue) => {
          const availableBreak = breaks.find(
            (b) => b.uniqueId === currentValue.key
          );
          if (!availableBreak) {
            return previousValue;
          }

          return (
            previousValue +
            (availableBreak.predictedRatingSecondaryTargetGroup ?? 0)
          );
        }, 0),
      [breaks, value]
    );

    return (
      <div className={styles.tableFooter}>
        <Trans>
          Je hebt {value.length} blok{value.length !== 1 ? "ken" : ""}{" "}
          geselecteerd, met een totale netto prijs van{" "}
          <strong>{formatToEuro(totalNettPrice, false)}</strong> en een totale
          prognose van <strong>{formatNumber(totalPredictedRating, 2)}</strong>
        </Trans>
        {hasSecondaryTargetGroup && totalSecondaryTargetGroup > 0 && (
          <>
            {" "}
            <Trans>
              (sec. doelgroep: {formatNumber(totalSecondaryTargetGroup, 2)})
            </Trans>
          </>
        )}
        {". "}
        {budget && budget > 0 && (
          <Trans>
            Saldo:{" "}
            <strong>{formatToEuro(budget - totalNettPrice, false)}</strong>
          </Trans>
        )}
      </div>
    );
  }
);

export default Footer;
