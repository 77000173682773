import { Trans } from "@lingui/macro";
import { Row, Spinner, Typography } from "@ster/ster-toolkit";
import { Col } from "antd";
import { memo } from "react";

import { CommercialsPerAdvertiser } from "../../../api";
import OnlineInstructionSummary from "../OnlineInstructionSummary/OnlineInstructionSummary";
import { OnlineInstructionsPerSubOrder, SimpleOnlineSubOrder } from "./types";

interface InstructionsProps {
  loading: boolean;
  commercialsPerAdvertiser?: CommercialsPerAdvertiser;
  className?: string;
  onSave?: (instructions: OnlineInstructionsPerSubOrder[]) => void;
  onDelete?: (instructionId: number) => void;
  maxDate?: Date;
  subOrders: SimpleOnlineSubOrder[];
  productId: number;
  isEditCampaign?: boolean;
}

const OnlineInstructions = memo(
  ({
    loading,
    commercialsPerAdvertiser,
    className,
    onSave,
    onDelete,
    maxDate,
    subOrders,
    productId,
    isEditCampaign,
  }: InstructionsProps) => (
    <Spinner spinning={loading}>
      {!loading &&
        subOrders.filter((s) => s.onlineInstructionsPerPeriod).length === 0 && (
          <Row className={className} gutter={[40, 40]}>
            <Col span={24}>
              <Typography.Paragraph>
                <Trans>
                  Er zijn geen uitzendinstructies gevonden voor de opgegeven
                  periode.
                </Trans>
              </Typography.Paragraph>
            </Col>
          </Row>
        )}
      {subOrders
        .filter((s) => s.onlineInstructionsPerPeriod?.length > 0)
        .map((s) => (
          <Row className={className} key={s.id} gutter={[40, 40]}>
            <Col span={24}>
              <OnlineInstructionSummary
                subOrder={s}
                productId={productId}
                commercialsPerAdvertiser={commercialsPerAdvertiser}
                onSave={onSave}
                onDelete={onDelete}
                maxDate={maxDate}
                subOrders={subOrders}
                isEditCampaign={isEditCampaign}
              />
            </Col>
          </Row>
        ))}
    </Spinner>
  )
);

export default OnlineInstructions;
