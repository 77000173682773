/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ExportFileType,
  ExportFileTypeFromJSON,
  ExportFileTypeFromJSONTyped,
  ExportFileTypeToJSON,
} from "./";

/**
 *
 * @export
 * @interface ExportOutputSettings
 */
export interface ExportOutputSettings {
  /**
   *
   * @type {number}
   * @memberof ExportOutputSettings
   */
  orderId: number;
  /**
   *
   * @type {number}
   * @memberof ExportOutputSettings
   */
  subOrderId?: number | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ExportOutputSettings
   */
  columns: Array<string>;
  /**
   *
   * @type {Date}
   * @memberof ExportOutputSettings
   */
  dateFrom: Date;
  /**
   *
   * @type {Date}
   * @memberof ExportOutputSettings
   */
  dateTo: Date;
  /**
   *
   * @type {string}
   * @memberof ExportOutputSettings
   */
  targetGroup?: string | null;
  /**
   *
   * @type {ExportFileType}
   * @memberof ExportOutputSettings
   */
  fileType: ExportFileType;
}

export function ExportOutputSettingsFromJSON(json: any): ExportOutputSettings {
  return ExportOutputSettingsFromJSONTyped(json, false);
}

export function ExportOutputSettingsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ExportOutputSettings {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    orderId: json["orderId"],
    subOrderId: !exists(json, "subOrderId") ? undefined : json["subOrderId"],
    columns: json["columns"],
    dateFrom: new Date(json["dateFrom"]),
    dateTo: new Date(json["dateTo"]),
    targetGroup: !exists(json, "targetGroup") ? undefined : json["targetGroup"],
    fileType: ExportFileTypeFromJSON(json["fileType"]),
  };
}

export function ExportOutputSettingsToJSON(
  value?: ExportOutputSettings | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    orderId: value.orderId,
    subOrderId: value.subOrderId,
    columns: value.columns,
    dateFrom: value.dateFrom.toISOString(),
    dateTo: value.dateTo.toISOString(),
    targetGroup: value.targetGroup,
    fileType: ExportFileTypeToJSON(value.fileType),
  };
}
