/* eslint-disable jsx-a11y/media-has-caption */
import { memo } from "react";

const AudioPlayer = memo(({ mediaId }: { mediaId: string }) => (
  <audio controls style={{ width: "100%" }}>
    <source src={`/api/v1/commercials/audio/${mediaId}`} type="audio/mp3" />
    <p>Het audio element wordt niet door uw browser ondersteund.</p>
  </audio>
));

export default AudioPlayer;
