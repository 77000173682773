/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Tagon
 */
export interface Tagon {
  /**
   *
   * @type {number}
   * @memberof Tagon
   */
  spotId: number;
  /**
   *
   * @type {string}
   * @memberof Tagon
   */
  commercialCode: string;
  /**
   *
   * @type {string}
   * @memberof Tagon
   */
  commercialDescr: string;
  /**
   *
   * @type {number}
   * @memberof Tagon
   */
  compositionSeqNr: number;
  /**
   *
   * @type {number}
   * @memberof Tagon
   */
  spotLength: number;
}

export function TagonFromJSON(json: any): Tagon {
  return TagonFromJSONTyped(json, false);
}

export function TagonFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Tagon {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    spotId: json["spotId"],
    commercialCode: json["commercialCode"],
    commercialDescr: json["commercialDescr"],
    compositionSeqNr: json["compositionSeqNr"],
    spotLength: json["spotLength"],
  };
}

export function TagonToJSON(value?: Tagon | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    spotId: value.spotId,
    commercialCode: value.commercialCode,
    commercialDescr: value.commercialDescr,
    compositionSeqNr: value.compositionSeqNr,
    spotLength: value.spotLength,
  };
}
