/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ForecastConversionGroupsConversionGroups,
  ForecastConversionGroupsConversionGroupsFromJSON,
  ForecastConversionGroupsConversionGroupsFromJSONTyped,
  ForecastConversionGroupsConversionGroupsToJSON,
} from "./";

/**
 *
 * @export
 * @interface ForecastConversionGroups
 */
export interface ForecastConversionGroups {
  /**
   *
   * @type {ForecastConversionGroupsConversionGroups}
   * @memberof ForecastConversionGroups
   */
  conversionGroups?: ForecastConversionGroupsConversionGroups | null;
}

export function ForecastConversionGroupsFromJSON(
  json: any
): ForecastConversionGroups {
  return ForecastConversionGroupsFromJSONTyped(json, false);
}

export function ForecastConversionGroupsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ForecastConversionGroups {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    conversionGroups: !exists(json, "conversionGroups")
      ? undefined
      : ForecastConversionGroupsConversionGroupsFromJSON(
          json["conversionGroups"]
        ),
  };
}

export function ForecastConversionGroupsToJSON(
  value?: ForecastConversionGroups | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    conversionGroups: ForecastConversionGroupsConversionGroupsToJSON(
      value.conversionGroups
    ),
  };
}
