/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  BlockSpot,
  BlockSpotFromJSON,
  BlockSpotFromJSONTyped,
  BlockSpotToJSON,
} from "./";

/**
 *
 * @export
 * @interface Block
 */
export interface Block {
  /**
   *
   * @type {string}
   * @memberof Block
   */
  breakId: string;
  /**
   *
   * @type {string}
   * @memberof Block
   */
  programmeAfter: string;
  /**
   *
   * @type {string}
   * @memberof Block
   */
  programmeAfterTime?: string | null;
  /**
   *
   * @type {string}
   * @memberof Block
   */
  programmeBefore: string;
  /**
   *
   * @type {string}
   * @memberof Block
   */
  programmeBeforeTime?: string | null;
  /**
   *
   * @type {Date}
   * @memberof Block
   */
  schedDate: Date;
  /**
   *
   * @type {Date}
   * @memberof Block
   */
  schedStartTime: Date;
  /**
   *
   * @type {Array<BlockSpot>}
   * @memberof Block
   */
  spots: Array<BlockSpot>;
}

export function BlockFromJSON(json: any): Block {
  return BlockFromJSONTyped(json, false);
}

export function BlockFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Block {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    breakId: json["breakId"],
    programmeAfter: json["programmeAfter"],
    programmeAfterTime: !exists(json, "programmeAfterTime")
      ? undefined
      : json["programmeAfterTime"],
    programmeBefore: json["programmeBefore"],
    programmeBeforeTime: !exists(json, "programmeBeforeTime")
      ? undefined
      : json["programmeBeforeTime"],
    schedDate: new Date(json["schedDate"]),
    schedStartTime: new Date(json["schedStartTime"]),
    spots: (json["spots"] as Array<any>).map(BlockSpotFromJSON),
  };
}

export function BlockToJSON(value?: Block | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    breakId: value.breakId,
    programmeAfter: value.programmeAfter,
    programmeAfterTime: value.programmeAfterTime,
    programmeBefore: value.programmeBefore,
    programmeBeforeTime: value.programmeBeforeTime,
    schedDate: value.schedDate.toISOString(),
    schedStartTime: value.schedStartTime.toISOString(),
    spots: (value.spots as Array<any>).map(BlockSpotToJSON),
  };
}
