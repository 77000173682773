import { Trans } from "@lingui/macro";
import { Row, Spinner, Typography } from "@ster/ster-toolkit";
import { Col } from "antd";
import { Moment } from "moment";
import { memo } from "react";

import {
  CommercialInstructionRequest,
  CommercialsPerAdvertiser,
} from "../../../api";
import InstructionSummary from "../InstructionSummary/InstructionSummary";
import { SimpleSubOrder } from "./utils";

interface InstructionsProps {
  loading: boolean;
  instructions?: CommercialInstructionRequest[];
  commercialsPerAdvertiser?: CommercialsPerAdvertiser;
  className?: string;
  onSave?: (
    instruction: CommercialInstructionRequest,
    onOkCallback: () => void
  ) => void;
  onDelete?: (instructionId: number) => void;
  minDate?: Date;
  maxDate?: Date;
  spotLengths?: number[][];
  campaignPeriod?: [Moment, Moment];
  subOrders: SimpleSubOrder[];
}

const Instructions = memo(
  ({
    loading,
    instructions,
    commercialsPerAdvertiser,
    className,
    onSave,
    onDelete,
    minDate,
    maxDate,
    spotLengths,
    campaignPeriod,
    subOrders,
  }: InstructionsProps) => {
    if (!instructions) {
      return null;
    }

    return (
      <Spinner spinning={loading}>
        {!loading && instructions.length === 0 && (
          <Row className={className} gutter={[40, 40]}>
            <Col span={24}>
              <Typography.Paragraph>
                <Trans>
                  Er zijn geen uitzendinstructies gevonden voor de opgegeven
                  periode.
                </Trans>
              </Typography.Paragraph>
            </Col>
          </Row>
        )}
        {instructions.map((instruction, idx) => (
          <Row className={className} key={instruction.id} gutter={[40, 40]}>
            <Col span={24}>
              <InstructionSummary
                instruction={instruction}
                commercialsPerAdvertiser={commercialsPerAdvertiser}
                instructionNo={idx + 1}
                onSave={onSave}
                onDelete={onDelete}
                minDate={minDate}
                maxDate={maxDate}
                spotLengths={spotLengths}
                campaignPeriod={campaignPeriod}
                subOrders={subOrders}
              />
            </Col>
          </Row>
        ))}
      </Spinner>
    );
  }
);

export default Instructions;
