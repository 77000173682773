import "./historytooltip.less";

import { Trans, msg, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Tooltip } from "@ster/ster-toolkit";
import { Popover, Timeline } from "antd";
import classNames from "classnames";
import moment from "moment";
import { memo } from "react";

import { AuditlogModel } from "../../../../api/models";

export interface HistoryTooltipProps {
  className?: string;
  logs: AuditlogModel[];
}

export const auditActionType = Object.freeze({
  RequestCreate: msg`Initiële aanvraag is aangemaakt`,
  RequestUpdate: msg`Initiële aanvraag is bewerkt`,
  RequestDelete: msg`Initiële aanvraag is verwijderd`,
  RequestFinalize: msg`Initiële aanvraag is ingediend`,
  OrderUpdate: msg`Campagne is bewerkt`,
});

const TooltipContent = memo(({ logs }: { logs: AuditlogModel[] }) => {
  const { i18n } = useLingui();
  return (
    <div className="history-tooltip__container">
      <Timeline
        items={logs?.map((l) => ({
          key: `${l.id}`,
          children: (
            <>
              {" "}
              <time
                className="history-tooltip__date text--caption"
                dateTime={moment(l.created).format("ll")}
              >
                {moment(l.created).calendar(null, {
                  sameDay: "[Vandaag] [om] LT",
                  lastDay: "[Gisteren] [om] LT",
                  lastWeek: "[Afgelopen] dddd [om] LT",
                  sameElse: "L [om] LT",
                })}
              </time>
              {l.actionType && i18n._(auditActionType[l.actionType])}{" "}
              {i18n._(t`door`)}{" "}
              <Tooltip
                title={i18n._(
                  t`E-mail: ${l.emailadress} - Organisatiecode: ${l.organisationCode}`
                )}
              >
                <a href={`mailto:${l.emailadress}`}>
                  {l.user?.fullName || l.emailadress}
                </a>
              </Tooltip>
            </>
          ),
        }))}
      />
    </div>
  );
});

const HistoryTooltip = memo(({ className, logs }: HistoryTooltipProps) => {
  if (!logs || logs.length === 0) {
    return null;
  }

  return (
    <div className={classNames("history-tooltip", className)}>
      <Popover
        placement="bottomRight"
        arrow={{ pointAtCenter: true }}
        autoAdjustOverflow={false}
        content={<TooltipContent logs={logs} />}
      >
        <span className="history-tooltip__label">
          <Trans>Historie</Trans>
        </span>
      </Popover>
    </div>
  );
});

export default HistoryTooltip;
