/* eslint-disable jsx-a11y/media-has-caption */
import { CloudDownloadOutlined } from "@ant-design/icons";
import { Trans } from "@lingui/macro";
import { Typography } from "@ster/ster-toolkit";
import { memo } from "react";

import styles from "./NpoPlayer.module.less";

const NpoPlayer = memo(({ mediaId }: { mediaId: string }) => (
  <>
    <video width="100%" height="100%" controls autoPlay>
      <source src={`/api/v1/commercials/video/${mediaId}`} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <div className={styles.downloadLink}>
      <Typography.Text>
        <a
          href={`/api/v1/commercials/video/${mediaId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <CloudDownloadOutlined />
          <Trans>Download MP4</Trans>
        </a>
      </Typography.Text>
    </div>
  </>
));

export default NpoPlayer;
