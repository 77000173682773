/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Block,
  BlockFromJSON,
  BlockFromJSONTyped,
  BlockToJSON,
  MediumEnum,
  MediumEnumFromJSON,
  MediumEnumFromJSONTyped,
  MediumEnumToJSON,
} from "./";

/**
 *
 * @export
 * @interface Channel
 */
export interface Channel {
  /**
   *
   * @type {Array<Block>}
   * @memberof Channel
   */
  blocks: Array<Block>;
  /**
   *
   * @type {string}
   * @memberof Channel
   */
  channelAka: string;
  /**
   *
   * @type {string}
   * @memberof Channel
   */
  channelDescr: string;
  /**
   *
   * @type {MediumEnum}
   * @memberof Channel
   */
  medium: MediumEnum;
}

export function ChannelFromJSON(json: any): Channel {
  return ChannelFromJSONTyped(json, false);
}

export function ChannelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Channel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    blocks: (json["blocks"] as Array<any>).map(BlockFromJSON),
    channelAka: json["channelAka"],
    channelDescr: json["channelDescr"],
    medium: MediumEnumFromJSON(json["medium"]),
  };
}

export function ChannelToJSON(value?: Channel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    blocks: (value.blocks as Array<any>).map(BlockToJSON),
    channelAka: value.channelAka,
    channelDescr: value.channelDescr,
    medium: MediumEnumToJSON(value.medium),
  };
}
