import "../../Pickers/styles.less";

import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Icons, Tooltip } from "@ster/ster-toolkit";
import { Alert } from "antd";
import classNames from "classnames";
import { max } from "date-fns";
import { useCallback, useMemo } from "react";

import {
  CommercialInstructionSpots,
  CommercialsPerAdvertiser,
  OnlineInstructionPerPeriod,
  OnlineInstructionSpots,
  PackageChoiceEnum,
} from "../../../../api";
import { InstructionConstants } from "../../../../utils";
import { DateRange } from "../../../../utils/types";
import { emptyCommercialSelection } from "../../Instructions/utils";
import OnlineInstructionPeriodPicker from "../InstructionPeriodPicker/OnlineInstructionPeriodPicker";
import { SimpleOnlineSubOrder } from "../types";
import { validateLandingsPage } from "../utils";
import OnlineCommercialRow from "./OnlineCommercialRow";
import styles from "./OnlineCommercialsPerPeriod.module.less";

export interface OnlineCommercialPickerProps {
  commercialsPerAdvertiser?: CommercialsPerAdvertiser;
  spotLength: number[];
  onChangeSpots: (commercials: OnlineInstructionSpots[]) => void;
  onChangePeriod: (period: DateRange | undefined) => void;
  enableAdd?: boolean;
  enableRemove?: boolean;
  subOrder: SimpleOnlineSubOrder;
  onDelete: (spotsPerPeriod: OnlineInstructionPerPeriod) => void;
  canDelete: boolean;
  spotsPerPeriod: OnlineInstructionPerPeriod;
}

export const OnlineCommercialsPerPeriod = ({
  subOrder,
  commercialsPerAdvertiser,
  spotLength,
  onChangeSpots,
  onChangePeriod,
  enableAdd,
  enableRemove,
  spotsPerPeriod,
  onDelete,
  canDelete,
}: OnlineCommercialPickerProps) => {
  const { i18n } = useLingui();
  const { startDate, endDate } = spotsPerPeriod;
  const instructions = useMemo(
    () => spotsPerPeriod.instructions ?? [],
    [spotsPerPeriod.instructions]
  );

  const commercialIds = useMemo(
    () => instructions.map((c) => c.id),
    [instructions]
  );
  const handleAdd = useCallback(() => {
    onChangeSpots([
      ...instructions,
      {
        id: Math.max(...commercialIds) + 1,
      },
    ]);
  }, [commercialIds, instructions, onChangeSpots]);

  const handleRemove = useCallback(
    (id: number) => {
      if (instructions.length === 1) {
        onChangeSpots(emptyCommercialSelection(spotLength));
      } else {
        onChangeSpots(instructions.filter((r) => r.id !== id));
      }
    },
    [instructions, onChangeSpots, spotLength]
  );

  const handleReady = useCallback(
    (row: CommercialInstructionSpots) => {
      const idx = commercialIds.indexOf(row.id);
      const newArr = [
        ...instructions.slice(0, idx),
        row,
        ...instructions.slice(idx + 1, instructions?.length),
      ];

      onChangeSpots(newArr);
    },
    [commercialIds, instructions, onChangeSpots]
  );

  const onDeleteClick = useCallback(() => {
    onDelete(spotsPerPeriod);
  }, [onDelete, spotsPerPeriod]);

  const hasInvalidUrl = useMemo(
    () =>
      instructions.some(
        (s) => (s.commercial || s.banner) && !validateLandingsPage(s.homepage)
      ),
    [instructions]
  );

  const useBanners = useMemo(
    () => subOrder.packageChoice === PackageChoiceEnum.Display,
    [subOrder.packageChoice]
  );

  return (
    <section className="instructions-container">
      <Tooltip
        className="picker-row__tooltip"
        title={<Trans>Regel verwijderen</Trans>}
      >
        <button
          className={classNames(styles.delete, "link")}
          type="button"
          onClick={onDeleteClick}
          hidden={!canDelete}
        >
          <Icons.DeleteIcon />
        </button>
      </Tooltip>
      <OnlineInstructionPeriodPicker
        dateRange={{
          startDate: max([
            subOrder.dateRange.startDate,
            InstructionConstants.firstMomentOnline,
          ]),
          endDate: subOrder.dateRange.endDate,
        }}
        value={
          startDate || endDate
            ? {
                startDate: startDate ?? undefined,
                endDate: endDate ?? undefined,
              }
            : undefined
        }
        onChange={onChangePeriod}
      />

      <span className="picker__title">
        {useBanners ? <Trans>Banner</Trans> : <Trans>Commercial</Trans>}
      </span>
      <span className="homepage__title">
        <Trans>Landingspagina</Trans>
      </span>
      {instructions.map((row) => (
        <OnlineCommercialRow
          key={row.id}
          selection={row}
          onRemove={enableRemove ? handleRemove : undefined}
          onReady={handleReady}
          commercialsPerAdvertiser={commercialsPerAdvertiser}
          canRemove
          spotLength={spotLength}
          useBanners={useBanners}
        />
      ))}
      {enableAdd && (
        <Button
          mode="tertiary"
          disabled={instructions.some(
            ({ commercial, banner }) => !commercial && !banner
          )}
          onClick={handleAdd}
        >
          <span className="more-menu-button__label">
            +{" "}
            {useBanners ? (
              <Trans>Banner toevoegen</Trans>
            ) : (
              <Trans>Commercial toevoegen</Trans>
            )}
          </span>
        </Button>
      )}
      {hasInvalidUrl && (
        <Alert
          showIcon
          className={styles.warning}
          type="error"
          message=""
          description={i18n._(
            t`Landingspagina is verplicht en dient te beginnen met https://`
          )}
        />
      )}
    </section>
  );
};
