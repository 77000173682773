/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  CommercialStatus,
  CommercialStatusFromJSON,
  CommercialStatusFromJSONTyped,
  CommercialStatusToJSON,
  MediumEnum,
  MediumEnumFromJSON,
  MediumEnumFromJSONTyped,
  MediumEnumToJSON,
  PreviewUrl,
  PreviewUrlFromJSON,
  PreviewUrlFromJSONTyped,
  PreviewUrlToJSON,
} from "./";

/**
 *
 * @export
 * @interface Banner
 */
export interface Banner {
  /**
   *
   * @type {number}
   * @memberof Banner
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Banner
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof Banner
   */
  clickUrl?: string | null;
  /**
   *
   * @type {number}
   * @memberof Banner
   */
  width?: number;
  /**
   *
   * @type {number}
   * @memberof Banner
   */
  height?: number;
  /**
   *
   * @type {Date}
   * @memberof Banner
   */
  creationDate?: Date;
  /**
   *
   * @type {CommercialStatus}
   * @memberof Banner
   */
  status?: CommercialStatus;
  /**
   *
   * @type {string}
   * @memberof Banner
   */
  tag?: string | null;
  /**
   *
   * @type {Array<PreviewUrl>}
   * @memberof Banner
   */
  previews?: Array<PreviewUrl> | null;
  /**
   *
   * @type {MediumEnum}
   * @memberof Banner
   */
  medium?: MediumEnum;
}

export function BannerFromJSON(json: any): Banner {
  return BannerFromJSONTyped(json, false);
}

export function BannerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Banner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    description: !exists(json, "description") ? undefined : json["description"],
    clickUrl: !exists(json, "clickUrl") ? undefined : json["clickUrl"],
    width: !exists(json, "width") ? undefined : json["width"],
    height: !exists(json, "height") ? undefined : json["height"],
    creationDate: !exists(json, "creationDate")
      ? undefined
      : new Date(json["creationDate"]),
    status: !exists(json, "status")
      ? undefined
      : CommercialStatusFromJSON(json["status"]),
    tag: !exists(json, "tag") ? undefined : json["tag"],
    previews: !exists(json, "previews")
      ? undefined
      : json["previews"] === null
        ? null
        : (json["previews"] as Array<any>).map(PreviewUrlFromJSON),
    medium: !exists(json, "medium")
      ? undefined
      : MediumEnumFromJSON(json["medium"]),
  };
}

export function BannerToJSON(value?: Banner | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    description: value.description,
    clickUrl: value.clickUrl,
    width: value.width,
    height: value.height,
    creationDate:
      value.creationDate === undefined
        ? undefined
        : value.creationDate.toISOString(),
    status: CommercialStatusToJSON(value.status),
    tag: value.tag,
    previews:
      value.previews === undefined
        ? undefined
        : value.previews === null
          ? null
          : (value.previews as Array<any>).map(PreviewUrlToJSON),
    medium: MediumEnumToJSON(value.medium),
  };
}
