/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  ProductTourModel,
  ProductTourModelFromJSON,
  ProductTourModelToJSON,
  ProductTourResult,
  ProductTourResultFromJSON,
  ProductTourResultToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface ApiV1ProductTourPostRequest {
  productTourModel?: ProductTourModel;
}

/**
 *
 */
export class ProductTourApi extends runtime.BaseAPI {
  /**
   */
  async apiV1ProductTourGetRaw(): Promise<
    runtime.ApiResponse<ProductTourResult>
  > {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/productTour`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProductTourResultFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1ProductTourGet(): Promise<ProductTourResult> {
    const response = await this.apiV1ProductTourGetRaw();
    return await response.value();
  }

  /**
   */
  async apiV1ProductTourPostRaw(
    requestParameters: ApiV1ProductTourPostRequest
  ): Promise<runtime.ApiResponse<ProductTourResult>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/productTour`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ProductTourModelToJSON(requestParameters.productTourModel),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProductTourResultFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1ProductTourPost(
    requestParameters: ApiV1ProductTourPostRequest
  ): Promise<ProductTourResult> {
    const response = await this.apiV1ProductTourPostRaw(requestParameters);
    return await response.value();
  }
}
