import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

import { DocumentApi } from "../../api/apis";
import apiConfig from "../../apiConfig";
import { toError } from "../utils";
import { receiveDocumentsAction } from "./actions";

// eslint-disable-next-line import/prefer-default-export
export function* receiveDocuments(): SagaIterator {
  try {
    const documentApi = new DocumentApi(apiConfig());
    const response = yield call(() => documentApi.apiV1DocumentGet());
    yield put(receiveDocumentsAction.success(response));
  } catch (err) {
    yield put(receiveDocumentsAction.failure(toError(err)));
  }
}
