import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { memo, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";

import { receiveAdvertisersAction } from "../../store/advertisers/actions";
import { receiveOrganisationsAction } from "../../store/organisations/actions";
import { receiveUsersAction } from "../../store/users/actions";
import { UsersFilter } from "./models";
import UserDetailContainer from "./UserDetailContainer";
import UsersListContainer from "./UsersListContainer";

const initialFilter: UsersFilter = {
  search: "",
  organisationCode: "",
};

const UsersContainer = memo(() => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(receiveUsersAction.request());
    dispatch(receiveOrganisationsAction.request());
    dispatch(receiveAdvertisersAction.request());
  }, [dispatch]);

  const [filter, setFilter] = useState(initialFilter);

  return (
    <>
      <Helmet>
        <title>{i18n._(t`Gebruikers`)}</title>
      </Helmet>

      <Routes>
        <Route
          path="/new"
          element={<UserDetailContainer setFilter={setFilter} />}
        />
        <Route
          path="/:userId"
          element={<UserDetailContainer setFilter={setFilter} />}
        />
        <Route
          path="/"
          element={<UsersListContainer setFilter={setFilter} filter={filter} />}
        />
      </Routes>
    </>
  );
});

export default UsersContainer;
