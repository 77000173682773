/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  DigitalProgram,
  DigitalProgramFromJSON,
  DigitalProgramFromJSONTyped,
  DigitalProgramToJSON,
} from "./";

/**
 *
 * @export
 * @interface DigitalProgramIListValueResult
 */
export interface DigitalProgramIListValueResult {
  /**
   *
   * @type {string}
   * @memberof DigitalProgramIListValueResult
   */
  name?: string | null;
  /**
   *
   * @type {Array<DigitalProgram>}
   * @memberof DigitalProgramIListValueResult
   */
  data?: Array<DigitalProgram> | null;
}

export function DigitalProgramIListValueResultFromJSON(
  json: any
): DigitalProgramIListValueResult {
  return DigitalProgramIListValueResultFromJSONTyped(json, false);
}

export function DigitalProgramIListValueResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DigitalProgramIListValueResult {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, "name") ? undefined : json["name"],
    data: !exists(json, "data")
      ? undefined
      : json["data"] === null
        ? null
        : (json["data"] as Array<any>).map(DigitalProgramFromJSON),
  };
}

export function DigitalProgramIListValueResultToJSON(
  value?: DigitalProgramIListValueResult | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    data:
      value.data === undefined
        ? undefined
        : value.data === null
          ? null
          : (value.data as Array<any>).map(DigitalProgramToJSON),
  };
}
