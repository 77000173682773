import { i18n } from "@lingui/core";
import { Trans, t } from "@lingui/macro";
import {
  Button,
  ComponentLocalization,
  Container,
  ContentContainer,
  Form,
  Icons,
  Option,
  RangePicker,
  Row,
  Select,
  Spinner,
  Typography,
} from "@ster/ster-toolkit";
import { App as AntApp, Form as AntForm, Col, Upload } from "antd";
import { Store } from "antd/lib/form/interface";
import { SelectValue } from "antd/lib/select";
import { RcFile, UploadChangeParam } from "antd/lib/upload";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ApiV1DocumentPostRequest, DocumentApi } from "../../api";
import apiConfig from "../../apiConfig";
import { receiveAdvertisersAction } from "../../store/advertisers/actions";
import { receiveDocumentsAction } from "../../store/documents/actions";
import { receiveOrganisationsAction } from "../../store/organisations/actions";
import { getLanguage, getOrganisationCode } from "../../utils";
import { klantportalAdverteerder } from "../../utils/constants";
import styles from "./Document.module.less";
import { documentsRootSelector } from "./selectors";

const emptyStringArr: string[] = [];
const DocumentUpload = memo(() => {
  const dispatch = useDispatch();
  const { message } = AntApp.useApp();
  const [form] = AntForm.useForm<ApiV1DocumentPostRequest>();

  const [selectedDocument, setSelectedDocument] = useState<RcFile>();

  const [showAdvertisers, setShowAdvertisers] = useState(false);

  const [isUploading, setIsUploading] = useState(false);

  // map state to props
  const { account, organisations, loading, advertisers, advertisersLoading } =
    useSelector(documentsRootSelector);

  const canChangeOrganisation = useMemo(
    () =>
      (account.roles ?? emptyStringArr).includes("Medewerker") &&
      (account.claims ?? emptyStringArr).includes("documenten_upload_alles"),
    [account]
  );

  // haal organisaties op/vernieuwen
  useEffect((): void => {
    if (canChangeOrganisation && organisations.length === 0 && !loading) {
      dispatch(receiveOrganisationsAction.request());
    }
  }, [canChangeOrganisation, dispatch, loading, organisations]);

  const handleOnFinish = useCallback(
    (values: Store) => {
      if (!selectedDocument) {
        return;
      }

      const uploadDocValues: ApiV1DocumentPostRequest = {
        organisationCode: canChangeOrganisation
          ? values.organisation
          : getOrganisationCode(account),
        advertiserCode: values.advertiser,
        publicationStart:
          values.publicationPeriod && values.publicationPeriod.length > 0
            ? values.publicationPeriod[0].format()
            : undefined,
        publicationEnd:
          values.publicationPeriod && values.publicationPeriod.length > 1
            ? values.publicationPeriod[1].format()
            : undefined,
        file: selectedDocument,
        userName: account.userName as string,
      };

      const api = new DocumentApi(apiConfig());
      setIsUploading(true);
      api
        .apiV1DocumentPost(uploadDocValues)
        .then(() => {
          // Upload succesvol
          setIsUploading(false);
          message.success(i18n._(t`Het uploaden is succesvol voltooid.`));
          dispatch(receiveDocumentsAction.request());
          form.resetFields();
        })
        .catch(() => {
          setIsUploading(false);
          message.error(i18n._(t`Er ging iets fout tijdens het uploaden.`));
        });
    },
    [selectedDocument, canChangeOrganisation, account, message, dispatch, form]
  );

  const handleUpload = useCallback(
    (file: RcFile) => {
      setSelectedDocument(file);
      return false;
    },
    [setSelectedDocument]
  );

  const onChangeOrganisation = useCallback(
    (value: SelectValue) => {
      if ((value as string) === klantportalAdverteerder) {
        if (advertisers.length === 0 && !advertisersLoading) {
          dispatch(receiveAdvertisersAction.request());
        }
        setShowAdvertisers(true);
      } else {
        setShowAdvertisers(false);
      }
    },
    [advertisers.length, advertisersLoading, dispatch]
  );

  const getFile = useCallback((e: UploadChangeParam) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  }, []);

  return (
    <ContentContainer>
      <Spinner spinning={loading || advertisersLoading || isUploading}>
        <Row gutter={[24, 0]} style={{ marginTop: 40 }}>
          <Col span={24}>
            <Container>
              <Row gutter={[24, 4]}>
                <Col span={24}>
                  <Typography.Title>
                    <Trans>uploaden</Trans>
                  </Typography.Title>
                </Col>

                <Col span={24} style={{ marginBottom: 80 }}>
                  <Typography.Paragraph>
                    <Trans>
                      Met behulp van onderstaande formulier kun je als Ster
                      medewerker bestanden uploaden voor specifieke
                      organisaties.
                    </Trans>
                  </Typography.Paragraph>
                </Col>

                <Form form={form} onFinish={handleOnFinish}>
                  <Col span={24}>
                    <Form.Item
                      label={i18n._(t`Document`)}
                      name="document"
                      className={styles.labelLeft}
                      rules={[
                        {
                          required: true,
                          message: i18n._(t`Kies een bestand`),
                        },
                      ]}
                      valuePropName="fileList"
                      getValueFromEvent={getFile}
                    >
                      <Upload
                        accept=".csv, .pdf, .doc, .docx, .xls, .xlsx, .zip, .ppt, .pptx"
                        beforeUpload={handleUpload}
                        showUploadList={false}
                      >
                        <Button
                          className={styles.posCol}
                          mode="tertiary"
                          icon={<Icons.UploadIcon />}
                        >
                          {selectedDocument ? (
                            selectedDocument.name
                          ) : (
                            <Trans>Upload bestand...</Trans>
                          )}
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                  {canChangeOrganisation && (
                    <Col span={24}>
                      <Form.Item
                        label={i18n._(t`Voor organisatie`)}
                        name="organisation"
                        className={styles.labelLeft}
                        rules={[
                          {
                            required: true,
                            message: i18n._(t`Kies een organisatie`),
                          },
                        ]}
                        initialValue={getOrganisationCode(account)}
                      >
                        <Select.Search
                          className={styles.posCol}
                          placeholder={i18n._(t`Kies een organisatie`)}
                          optionFilterProp="children"
                          onChange={onChangeOrganisation}
                          disabled={loading}
                          filterOption={(
                            input: string,
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            option: any
                          ): boolean =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {organisations.map((Organisation) => (
                            <Option
                              value={`${Organisation.code}`}
                              key={`${Organisation.code}`}
                            >
                              {Organisation.name}
                            </Option>
                          ))}
                        </Select.Search>
                      </Form.Item>
                    </Col>
                  )}
                  {showAdvertisers && !advertisersLoading && (
                    <Col span={24}>
                      <Form.Item
                        label={i18n._(t`Voor adverteerder`)}
                        name="advertiser"
                        rules={[
                          {
                            required: true,
                            message: i18n._(t`Kies een adverteerder`),
                          },
                        ]}
                        className={styles.labelLeft}
                      >
                        <Select
                          className={styles.posCol}
                          showSearch
                          optionFilterProp="children"
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          filterOption={(input: string, option: any) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder={i18n._(t`Zoek`)}
                        >
                          {advertisers.map((Advertiser) => (
                            <Option
                              value={`${Advertiser.id}`}
                              key={`${Advertiser.id}`}
                            >
                              {Advertiser.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                  <Col span={24}>
                    <Form.Item
                      label={i18n._(t`Publicatieperiode`)}
                      name="publicationPeriod"
                      className={styles.labelLeft}
                    >
                      <RangePicker
                        className={styles.posCol}
                        componentLocale={getLanguage() as ComponentLocalization}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} className={styles.posButton}>
                    <Button mode="tertiary" href="/documents">
                      <Trans>Annuleren</Trans>
                    </Button>

                    <Button mode="primary" type="primary" htmlType="submit">
                      <Trans>Uploaden</Trans>
                    </Button>
                  </Col>
                </Form>
              </Row>
            </Container>
          </Col>
        </Row>
      </Spinner>
    </ContentContainer>
  );
});

export default DocumentUpload;
