import { useMemo } from "react";

import {
  AnalysisAvailability,
  MediumEnum,
  PortalSettings,
} from "../../../../api";

export const useCampaignAnalysisVisible = (
  campaignData: {
    analysis: AnalysisAvailability;
    medium: MediumEnum;
  },
  settings?: PortalSettings
) =>
  useMemo<boolean>(
    () => isCampaignAnalysisVisible(campaignData, settings),
    [campaignData, settings]
  );

export const useCampaignAnalysisDisabled = (campaignData: {
  analysis: AnalysisAvailability;
}) =>
  useMemo<boolean>(
    () => isCampaignAnalysisDisabled(campaignData),
    [campaignData]
  );

export const isCampaignAnalysisVisible = (
  campaignData: {
    analysis: AnalysisAvailability;
    medium: MediumEnum;
  },
  settings?: PortalSettings
): boolean =>
  !!campaignData.analysis.from &&
  Boolean(settings?.enableCampaignAnalysis?.[campaignData.medium] ?? true) &&
  !!settings?.nmoSwitchDate;

export const isCampaignAnalysisDisabled = (campaignData: {
  analysis: AnalysisAvailability;
}): boolean => campaignData.analysis.isAvailable === false;
