import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button } from "@ster/ster-toolkit";
import { Alert, App as AntApp } from "antd";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { BookSpotAction, BookSpotItem } from "../../../api";
import {
  bookSpotAction,
  clearBookSpotAction,
} from "../../../store/breakselect/actions";
import { insideImmediateBookSpotWindow } from "../../../utils";
import { BookSpotStatusProps } from "../models";
import BookSpotDialog from "./BookSpotDialog";
import styles from "./BookSpotFooter.module.less";
import BookSpotStatus from "./BookSpotStatus";
import { bookSpotRootSelector } from "./selectors";

const BookSpotFooter = memo(
  ({
    breaks,
    value,
    currentBalance,
    currentGRPBalance,
    orderId,
    subOrderId,
    showTop2000Warning,
    onCancel,
    onOk,
  }: BookSpotStatusProps) => {
    const { i18n } = useLingui();
    const dispatch = useDispatch();
    const { notification } = AntApp.useApp();
    const [showDetails, setShowDetails] = useState(false);
    const [isFinalize, setIsFinalize] = useState(false);

    const { loading, success } = useSelector(bookSpotRootSelector);

    const addedItems = useMemo(
      () =>
        breaks.filter(
          (b) =>
            !b.booked && value.find((v) => v.key === b.uniqueId) !== undefined
        ),
      [breaks, value]
    );

    const deletedItems = useMemo(
      () =>
        breaks.filter(
          (b) =>
            b.booked && value.find((v) => v.key === b.uniqueId) === undefined
        ),
      [breaks, value]
    );

    const balance = useMemo(() => {
      if (currentBalance !== undefined) {
        return (
          currentBalance +
          (addedItems?.reduce((a, b) => a + b.nettSpotPrice, 0) ?? 0) -
          (deletedItems?.reduce((a, b) => a + b.nettSpotPrice, 0) ?? 0)
        );
      }
      return undefined;
    }, [addedItems, currentBalance, deletedItems]);

    const grpBalance = useMemo(() => {
      if (currentGRPBalance !== undefined) {
        return (
          currentGRPBalance +
          (addedItems?.reduce((a, b) => a + (b.predictedRating ?? 0), 0) ?? 0) -
          (deletedItems?.reduce((a, b) => a + (b.predictedRating ?? 0), 0) ?? 0)
        );
      }
      return undefined;
    }, [addedItems, currentGRPBalance, deletedItems]);

    const handleDetailsClick = useCallback(() => {
      setShowDetails(!showDetails);
    }, [showDetails]);

    const handleOkClick = useCallback(() => {
      setIsFinalize(true);
      setShowDetails(true);
    }, []);

    const finalize = useCallback(() => {
      const bookSpotItemsToAdd: BookSpotItem[] = addedItems.map((item) => ({
        action: BookSpotAction.Book,
        preferredPositionString: value.find((v) => v.key === item.uniqueId)
          ?.preferredPosition,
        ...item,
      }));
      const bookSpotItemsToDelete: BookSpotItem[] = deletedItems.map(
        (item) => ({
          action: BookSpotAction.Cancel,
          ...item,
        })
      );

      dispatch(
        bookSpotAction.request({
          bookSpotCollection: {
            orderId,
            subOrderId,
            items: [...bookSpotItemsToAdd, ...bookSpotItemsToDelete],
          },
        })
      );
    }, [addedItems, deletedItems, dispatch, orderId, subOrderId, value]);

    useEffect(() => {
      if (success === undefined) {
        return;
      }

      if (success) {
        setShowDetails(false);
        onOk();
        notification.success({
          message: i18n._(t`Spotwijziging ingediend`),
          description: (
            <>
              {i18n._(t`We hebben je aanvraag om spots te wijzigen ontvangen.`)}
              <br />
              <br />
              {i18n._(
                insideImmediateBookSpotWindow()
                  ? t`Binnen enkele minuten ontvang je bericht welke wijzigingen zijn doorgevoerd.`
                  : t`Tijdens kantooruren ontvang je bericht welke wijzigingen zijn doorgevoerd.`
              )}
            </>
          ),
          duration: 20,
        });
      }

      dispatch(clearBookSpotAction());
    }, [dispatch, i18n, notification, onOk, success]);

    const handleOkClickModal = useCallback(() => {
      if (!isFinalize) {
        handleDetailsClick();
      } else {
        finalize();
      }
    }, [finalize, handleDetailsClick, isFinalize]);

    return (
      <>
        <BookSpotStatus
          addedItems={addedItems}
          deletedItems={deletedItems}
          balance={balance ?? 0}
          grpBalance={grpBalance ?? 0}
          handleDetailsClick={handleDetailsClick}
        />
        {showTop2000Warning && (
          <Alert
            showIcon
            type="warning"
            className={styles.top2000warning}
            message=""
            description={i18n._(
              t`Let op! indien je Top2000 blokken in wilt boeken dien je een
            Top2000 GRP pakket te selecteren!`
            )}
          />
        )}
        <Button
          mode="tertiary"
          key="back"
          className={styles.cancel}
          onClick={onCancel}
        >
          <Trans>Annuleren</Trans>
        </Button>
        <Button
          key="submit"
          mode="primary"
          disabled={addedItems.length === 0 && deletedItems.length === 0}
          onClick={handleOkClick}
        >
          <Trans>OK</Trans>
        </Button>
        <BookSpotDialog
          visible={showDetails}
          onClose={handleDetailsClick}
          onOk={handleOkClickModal}
          addedItems={addedItems}
          deletedItems={deletedItems}
          balance={balance ?? 0}
          grpBalance={grpBalance ?? 0}
          isFinalize={isFinalize}
          loading={loading}
        />
      </>
    );
  }
);

export default BookSpotFooter;
