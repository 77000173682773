import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

import {
  CampaignAnalysisApi,
  CampaignApi,
  CommercialInstructionsApi,
  DownloadApi,
  PlanningApi,
} from "../../api";
import apiConfig from "../../apiConfig";
import { toError } from "../utils";
import {
  deleteInstructionAction,
  receiveCampaignAnalysisTargetGroupsAction,
  receiveCampaignDetailAction,
  receiveCampaignDetailWithSpotsAction,
  receiveCampaignExportAction,
  receiveCampaignInstructionsAction,
  receiveCampaignSpotsAction,
  receiveCampaignSpotsDownloadAction,
  requestCampaignAnalysisAction,
  requestCampaignAnalysisGenericAction,
  requestRelatedCampaignsAction,
  saveCampaignInstructionsAction,
  saveCampaignInstructionsOnlineAction,
  saveMultiCampaignInstructionsAction,
  validateCampaignAction,
} from "./actions";

export function* receiveCampaignDetail(
  action: ReturnType<typeof receiveCampaignDetailAction.request>
): SagaIterator {
  try {
    const campaignApi = new CampaignApi(apiConfig());
    const response = yield call(() =>
      campaignApi.apiV1CampaignGetorderGet(action.payload)
    );
    yield put(receiveCampaignDetailAction.success(response));
  } catch (err) {
    yield put(
      receiveCampaignDetailAction.failure({
        orderId: action.payload.orderId,
        ...toError(err),
      })
    );
  }
}

export function* receiveCampaignDetailWithSpots(
  action: ReturnType<typeof receiveCampaignDetailWithSpotsAction.request>
): SagaIterator {
  try {
    const campaignApi = new CampaignApi(apiConfig());
    const response = yield call(() =>
      campaignApi.apiV1CampaignGetorderwithspotsGet(action.payload)
    );
    yield put(receiveCampaignDetailWithSpotsAction.success(response));
  } catch (err) {
    yield put(
      receiveCampaignDetailWithSpotsAction.failure({
        orderId: action.payload.orderId,
        ...toError(err),
      })
    );
  }
}

export function* receiveCampaignSpots(
  action: ReturnType<typeof receiveCampaignSpotsAction.request>
): SagaIterator {
  try {
    const campaignApi = new CampaignApi(apiConfig());
    const response = yield call(() =>
      campaignApi.apiV1CampaignGetspotsbyorderGet(action.payload)
    );
    yield put(receiveCampaignSpotsAction.success(response));
  } catch (err) {
    yield put(
      receiveCampaignSpotsAction.failure({
        orderId: action.payload.orderId,
        ...toError(err),
      })
    );
  }
}

export function* receiveCampaignSpotsDownload(
  action: ReturnType<typeof receiveCampaignSpotsDownloadAction.request>
): SagaIterator {
  try {
    const downloadApi = new DownloadApi(apiConfig());
    const { subOrderId, fileType, orderId } = action.payload;
    const response = yield call(() => {
      if (subOrderId) {
        return downloadApi.typeIdSubIdExtensionGet({
          extension: fileType,
          id: orderId,
          subId: subOrderId,
          type: "spots",
        });
      }

      return downloadApi.typeIdExtensionGet({
        extension: fileType,
        id: orderId,
        type: "spots",
      });
    });

    yield put(
      receiveCampaignSpotsDownloadAction.success({
        orderId,
        fileType,
        data: response,
      })
    );
  } catch (err) {
    yield put(receiveCampaignSpotsDownloadAction.failure(toError(err)));
  }
}

export function* receiveCampaignExport(
  action: ReturnType<typeof receiveCampaignExportAction.request>
): SagaIterator {
  try {
    const campaignApi = new CampaignApi(apiConfig());
    const {
      payload,
      payload: {
        exportOutputSettings: { orderId, fileType },
      },
    } = action;
    const response = yield call(() =>
      campaignApi.apiV1CampaignExportPost(payload)
    );
    yield put(
      receiveCampaignExportAction.success({
        orderId,
        fileType: fileType.toLowerCase(),
        data: response,
      })
    );
  } catch (err) {
    yield put(receiveCampaignExportAction.failure(toError(err)));
  }
}

export function* receiveCampaignInstructions(
  action: ReturnType<typeof receiveCampaignInstructionsAction.request>
): SagaIterator {
  try {
    const commercialInstructionsApi = new CommercialInstructionsApi(
      apiConfig()
    );
    const response = yield call(() =>
      commercialInstructionsApi.apiV1CommercialInstructionsGet(action.payload)
    );
    yield put(receiveCampaignInstructionsAction.success(response));
  } catch (err) {
    yield put(receiveCampaignInstructionsAction.failure(toError(err)));
  }
}

export function* saveCampaignInstructions(
  action: ReturnType<typeof saveCampaignInstructionsAction.request>
): SagaIterator {
  try {
    const commercialInstructionsApi = new CommercialInstructionsApi(
      apiConfig()
    );
    const response = yield call(() =>
      commercialInstructionsApi.apiV1CommercialInstructionsPost({
        commercialInstructionRequest: action.payload,
      })
    );
    yield put(saveCampaignInstructionsAction.success(response));
  } catch (err) {
    yield put(saveCampaignInstructionsAction.failure(toError(err)));
  }
}

export function* saveMultiCampaignInstructions(
  action: ReturnType<typeof saveMultiCampaignInstructionsAction.request>
): SagaIterator {
  try {
    const commercialInstructionsApi = new CommercialInstructionsApi(
      apiConfig()
    );
    yield call(() =>
      commercialInstructionsApi.apiV1CommercialInstructionsMultiPost({
        commercialInstructionRequest: action.payload.instructions,
      })
    );
    yield put(saveMultiCampaignInstructionsAction.success());

    if (action.payload.successProps) {
      const { medium, productId, periodFrom, periodTo } =
        action.payload.successProps;
      if (productId) {
        yield put(
          receiveCampaignInstructionsAction.request({
            medium,
            productId,
            from: periodFrom,
            to: periodTo,
          })
        );
      }
    }

    if (action.payload.onSuccess && action.payload.successProps) {
      yield call(action.payload.onSuccess, action.payload.successProps);
    }
  } catch (err) {
    yield put(saveMultiCampaignInstructionsAction.failure(toError(err)));
  }
}

export function* deleteInstruction(
  action: ReturnType<typeof deleteInstructionAction.request>
): SagaIterator {
  try {
    const commercialInstructionsApi = new CommercialInstructionsApi(
      apiConfig()
    );
    const {
      payload: { instructionId, medium },
    } = action;

    yield call(() =>
      commercialInstructionsApi.apiV1CommercialInstructionsDeleteInstructionIdDelete(
        { instructionId, medium }
      )
    );
    yield put(deleteInstructionAction.success());
  } catch (err) {
    yield put(deleteInstructionAction.failure(toError(err)));
  }
}

export function* validateCampaign(
  action: ReturnType<typeof validateCampaignAction.request>
): SagaIterator {
  try {
    const planningApi = new PlanningApi(apiConfig());
    const response = yield call(() =>
      planningApi.apiV1PlanningValidatePost({
        orderRequest: action.payload,
      })
    );
    yield put(validateCampaignAction.success({ valid: response === "true" }));
  } catch (err) {
    yield put(validateCampaignAction.failure(toError(err)));
  }
}

export function* requestCampaignAnalysis(
  action: ReturnType<typeof requestCampaignAnalysisAction.request>
): SagaIterator {
  try {
    const campaignAnalysisApi = new CampaignAnalysisApi(apiConfig());

    yield call(() =>
      campaignAnalysisApi.apiV1CampaignAnalysisAnalysisPost({
        medium: action.payload.medium,
        orderId: action.payload.orderId,
        analysisInput: action.payload.analysisInput,
      })
    );

    yield put(requestCampaignAnalysisAction.success());
  } catch (err) {
    yield put(requestCampaignAnalysisAction.failure(toError(err)));
  }
}

export function* requestCampaignAnalysisGeneric(
  action: ReturnType<typeof requestCampaignAnalysisGenericAction.request>
): SagaIterator {
  try {
    const campaignAnalysisApi = new CampaignAnalysisApi(apiConfig());

    yield call(() =>
      campaignAnalysisApi.apiV1CampaignAnalysisAnalysisGenericPost({
        genericAnalysisInput: action.payload.analysisInput,
        medium: action.payload.medium,
      })
    );

    yield put(requestCampaignAnalysisGenericAction.success());
  } catch (err) {
    yield put(requestCampaignAnalysisGenericAction.failure(toError(err)));
  }
}

export function* receiveCampaignAnalysisTargetGroups(): SagaIterator {
  try {
    const campaignAnalysisApi = new CampaignAnalysisApi(apiConfig());

    const response = yield call(() =>
      campaignAnalysisApi.apiV1CampaignAnalysisAnalysistargetgroupsGet()
    );

    yield put(receiveCampaignAnalysisTargetGroupsAction.success(response));
  } catch (err) {
    yield put(receiveCampaignAnalysisTargetGroupsAction.failure(toError(err)));
  }
}

export function* saveCampaignInstructionsOnline(
  action: ReturnType<typeof saveCampaignInstructionsOnlineAction.request>
): SagaIterator {
  try {
    const commercialInstructionsApi = new CommercialInstructionsApi(
      apiConfig()
    );
    const response = yield call(() =>
      commercialInstructionsApi.apiV1CommercialInstructionsSaveOnlineOrderIdPost(
        action.payload
      )
    );
    yield put(
      saveCampaignInstructionsOnlineAction.success({ succes: response })
    );
  } catch (err) {
    yield put(saveCampaignInstructionsOnlineAction.failure(toError(err)));
  }
}

export function* requestRelatedCampaigns(
  action: ReturnType<typeof requestRelatedCampaignsAction.request>
): SagaIterator {
  try {
    const campaignAnalysisApi = new CampaignAnalysisApi(apiConfig());
    const response = yield call(() =>
      campaignAnalysisApi.apiV1CampaignAnalysisRelatedMediumOrderIdGet(
        action.payload
      )
    );

    yield put(requestRelatedCampaignsAction.success(response));
  } catch (err) {
    yield put(requestRelatedCampaignsAction.failure(toError(err)));
  }
}
