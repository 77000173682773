/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  AdScienceMediaFile,
  AdScienceMediaFileFromJSON,
  AdScienceMediaFileFromJSONTyped,
  AdScienceMediaFileToJSON,
  CommercialUploadStatus,
  CommercialUploadStatusFromJSON,
  CommercialUploadStatusFromJSONTyped,
  CommercialUploadStatusToJSON,
  CommercialUploader,
  CommercialUploaderFromJSON,
  CommercialUploaderFromJSONTyped,
  CommercialUploaderToJSON,
} from "./";

/**
 *
 * @export
 * @interface CommercialUploadRegistration
 */
export interface CommercialUploadRegistration {
  /**
   *
   * @type {number}
   * @memberof CommercialUploadRegistration
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof CommercialUploadRegistration
   */
  adScienceId: number;
  /**
   *
   * @type {number}
   * @memberof CommercialUploadRegistration
   */
  advertiserId: number;
  /**
   *
   * @type {Date}
   * @memberof CommercialUploadRegistration
   */
  uploadedOn: Date;
  /**
   *
   * @type {CommercialUploadStatus}
   * @memberof CommercialUploadRegistration
   */
  status: CommercialUploadStatus;
  /**
   *
   * @type {string}
   * @memberof CommercialUploadRegistration
   */
  message?: string | null;
  /**
   *
   * @type {CommercialUploader}
   * @memberof CommercialUploadRegistration
   */
  uploader: CommercialUploader;
  /**
   *
   * @type {AdScienceMediaFile}
   * @memberof CommercialUploadRegistration
   */
  mediaFile: AdScienceMediaFile;
}

export function CommercialUploadRegistrationFromJSON(
  json: any
): CommercialUploadRegistration {
  return CommercialUploadRegistrationFromJSONTyped(json, false);
}

export function CommercialUploadRegistrationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CommercialUploadRegistration {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    adScienceId: json["adScienceId"],
    advertiserId: json["advertiserId"],
    uploadedOn: new Date(json["uploadedOn"]),
    status: CommercialUploadStatusFromJSON(json["status"]),
    message: !exists(json, "message") ? undefined : json["message"],
    uploader: CommercialUploaderFromJSON(json["uploader"]),
    mediaFile: AdScienceMediaFileFromJSON(json["mediaFile"]),
  };
}

export function CommercialUploadRegistrationToJSON(
  value?: CommercialUploadRegistration | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    adScienceId: value.adScienceId,
    advertiserId: value.advertiserId,
    uploadedOn: value.uploadedOn.toISOString(),
    status: CommercialUploadStatusToJSON(value.status),
    message: value.message,
    uploader: CommercialUploaderToJSON(value.uploader),
    mediaFile: AdScienceMediaFileToJSON(value.mediaFile),
  };
}
