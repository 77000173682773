import { createAsyncAction } from "typesafe-actions";

import { ResponseError } from "../base";
import { TenantAdvertisers } from "../models";
import { actionNameAsync } from "../utils";

// eslint-disable-next-line import/prefer-default-export
export const receiveAdvertisersAction = createAsyncAction(
  actionNameAsync("advertisers", "REQUEST"),
  actionNameAsync("advertisers", "SUCCESS"),
  actionNameAsync("advertisers", "FAILURE")
)<void, TenantAdvertisers, ResponseError>();
