import "./progressicon.less";

import { memo } from "react";

interface ProgressIconProps {
  label: string;
  value: number;
  type: "grp" | "date";
  size?: "large";
}

const TVFillSvg = ({ width = 74, height = 54, fill = "#D9E7F1" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 74 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0V49.7882H74.0005V0H0ZM70.2066 46.1083H3.79386V3.67941H70.2066V46.1083Z"
      fill={fill}
    />
    <rect x="6" y="7" width="62" height="37" fill={fill} />
    <path d="M52.3402 51.7031H21.6602V54.0003H52.3402V51.7031Z" fill={fill} />
  </svg>
);

const CalendarFillSvg = ({ width = 34, height = 38, fill = "#D9E7F1" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 34 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.6673 4.0013H28.6673V0.667969H25.334V4.0013H8.66732V0.667969H5.33399V4.0013H0.333984V37.3346H33.6673V4.0013ZM30.334 34.0013H3.66732V12.3346H30.334V34.0013Z"
      fill={fill}
    />
    <rect
      x="5.13477"
      y="13.9141"
      width="23.7288"
      height="18.9831"
      fill={fill}
    />
  </svg>
);

const GRPIcon = memo(({ height, size }: { height: number; size?: "large" }) => {
  const maxHeight = height > 100 ? 100 : height;
  return (
    <div
      className={`icons-wrapper icons-wrapper__grp ${
        size === "large" ? "icons-wrapper--large" : ""
      }`}
    >
      <div role="img" className="progress-icon__icon icon--front">
        <TVFillSvg />
      </div>
      <div
        role="img"
        className="progress-icon__icon icon--back"
        style={{ height: `${100 - maxHeight}%` }}
      >
        <TVFillSvg />
      </div>
    </div>
  );
});

const DateIcon = memo(
  ({ height, size }: { height: number; size?: "large" }) => (
    <div
      className={`icons-wrapper icons-wrapper__date ${
        size === "large" ? "icons-wrapper--large" : ""
      }`}
    >
      <div role="img" className="progress-icon__icon icon--front">
        <CalendarFillSvg />
      </div>
      <div
        role="img"
        className="progress-icon__icon icon--back"
        style={{ height: `${100 - height}%` }}
      >
        <CalendarFillSvg />
      </div>
    </div>
  )
);

const ProgressIcon = ({ label, value, type, size }: ProgressIconProps) => (
  <div className="progress-icon">
    {type === "grp" ? <GRPIcon height={value} /> : <DateIcon height={value} />}
    <div className="progress-icon__value-wrapper">
      <span className="progress-icon__label">{label}</span>
      <span
        className={`progress-icon__value ${
          size === "large" ? "progress-icon__value--large" : ""
        }`}
      >
        {value === Infinity || Number.isNaN(value) ? "-" : `${value}%`}
      </span>
    </div>
  </div>
);

export default ProgressIcon;
