/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  AnalysisPeriod,
  AnalysisPeriodFromJSON,
  AnalysisPeriodFromJSONTyped,
  AnalysisPeriodToJSON,
  AnalysisTargetGroups,
  AnalysisTargetGroupsFromJSON,
  AnalysisTargetGroupsFromJSONTyped,
  AnalysisTargetGroupsToJSON,
} from "./";

/**
 *
 * @export
 * @interface GenericAnalysisInput
 */
export interface GenericAnalysisInput {
  /**
   *
   * @type {AnalysisTargetGroups}
   * @memberof GenericAnalysisInput
   */
  secondaryTargetGroups?: AnalysisTargetGroups;
  /**
   *
   * @type {number}
   * @memberof GenericAnalysisInput
   */
  productId?: number;
  /**
   *
   * @type {AnalysisPeriod}
   * @memberof GenericAnalysisInput
   */
  period?: AnalysisPeriod;
}

export function GenericAnalysisInputFromJSON(json: any): GenericAnalysisInput {
  return GenericAnalysisInputFromJSONTyped(json, false);
}

export function GenericAnalysisInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GenericAnalysisInput {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    secondaryTargetGroups: !exists(json, "secondaryTargetGroups")
      ? undefined
      : AnalysisTargetGroupsFromJSON(json["secondaryTargetGroups"]),
    productId: !exists(json, "productId") ? undefined : json["productId"],
    period: !exists(json, "period")
      ? undefined
      : AnalysisPeriodFromJSON(json["period"]),
  };
}

export function GenericAnalysisInputToJSON(
  value?: GenericAnalysisInput | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    secondaryTargetGroups: AnalysisTargetGroupsToJSON(
      value.secondaryTargetGroups
    ),
    productId: value.productId,
    period: AnalysisPeriodToJSON(value.period),
  };
}
