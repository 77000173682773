/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  AnalysisAvailability,
  AnalysisAvailabilityFromJSON,
  AnalysisAvailabilityFromJSONTyped,
  AnalysisAvailabilityToJSON,
  AuditlogModel,
  AuditlogModelFromJSON,
  AuditlogModelFromJSONTyped,
  AuditlogModelToJSON,
  DateSpan,
  DateSpanFromJSON,
  DateSpanFromJSONTyped,
  DateSpanToJSON,
  MediumEnum,
  MediumEnumFromJSON,
  MediumEnumFromJSONTyped,
  MediumEnumToJSON,
  OrderStatus,
  OrderStatusFromJSON,
  OrderStatusFromJSONTyped,
  OrderStatusToJSON,
  RunningState,
  RunningStateFromJSON,
  RunningStateFromJSONTyped,
  RunningStateToJSON,
  SubOrderDetail,
  SubOrderDetailFromJSON,
  SubOrderDetailFromJSONTyped,
  SubOrderDetailToJSON,
} from "./";

/**
 *
 * @export
 * @interface OrderDetail
 */
export interface OrderDetail {
  /**
   *
   * @type {string}
   * @memberof OrderDetail
   */
  productDescr: string;
  /**
   *
   * @type {number}
   * @memberof OrderDetail
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof OrderDetail
   */
  productId: number;
  /**
   *
   * @type {Date}
   * @memberof OrderDetail
   */
  orderStartDate: Date;
  /**
   *
   * @type {Date}
   * @memberof OrderDetail
   */
  orderEndDate: Date;
  /**
   *
   * @type {number}
   * @memberof OrderDetail
   */
  orderBudget: number;
  /**
   *
   * @type {MediumEnum}
   * @memberof OrderDetail
   */
  medium: MediumEnum;
  /**
   *
   * @type {number}
   * @memberof OrderDetail
   */
  requestedBudget: number;
  /**
   *
   * @type {number}
   * @memberof OrderDetail
   */
  readonly requestedGrp: number;
  /**
   *
   * @type {number}
   * @memberof OrderDetail
   */
  bookedBudget: number;
  /**
   *
   * @type {number}
   * @memberof OrderDetail
   */
  readonly bookedGrp: number;
  /**
   *
   * @type {number}
   * @memberof OrderDetail
   */
  readonly achievedNettBudget: number;
  /**
   *
   * @type {number}
   * @memberof OrderDetail
   */
  readonly achievedGrp: number;
  /**
   *
   * @type {string}
   * @memberof OrderDetail
   */
  campaignName: string;
  /**
   *
   * @type {number}
   * @memberof OrderDetail
   */
  advertiserId: number;
  /**
   *
   * @type {string}
   * @memberof OrderDetail
   */
  advertiserName: string;
  /**
   *
   * @type {Array<SubOrderDetail>}
   * @memberof OrderDetail
   */
  subOrders?: Array<SubOrderDetail> | null;
  /**
   *
   * @type {OrderStatus}
   * @memberof OrderDetail
   */
  status: OrderStatus;
  /**
   *
   * @type {RunningState}
   * @memberof OrderDetail
   */
  runningState: RunningState;
  /**
   *
   * @type {number}
   * @memberof OrderDetail
   */
  requestedImpressions?: number;
  /**
   *
   * @type {number}
   * @memberof OrderDetail
   */
  bookedImpressions?: number;
  /**
   *
   * @type {Array<AuditlogModel>}
   * @memberof OrderDetail
   */
  auditLogs: Array<AuditlogModel>;
  /**
   *
   * @type {DateSpan}
   * @memberof OrderDetail
   */
  salesPeriod: DateSpan;
  /**
   *
   * @type {boolean}
   * @memberof OrderDetail
   */
  isSpreaded?: boolean;
  /**
   *
   * @type {DateSpan}
   * @memberof OrderDetail
   */
  spreadedPeriod?: DateSpan;
  /**
   *
   * @type {AnalysisAvailability}
   * @memberof OrderDetail
   */
  analysis: AnalysisAvailability;
  /**
   *
   * @type {Array<string>}
   * @memberof OrderDetail
   */
  analysisTargetGroupIds?: Array<string> | null;
  /**
   *
   * @type {boolean}
   * @memberof OrderDetail
   */
  hasMaterial?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof OrderDetail
   */
  bonusBudgetRequested?: number;
  /**
   *
   * @type {number}
   * @memberof OrderDetail
   */
  bonusBudgetBooked?: number;
  /**
   *
   * @type {number}
   * @memberof OrderDetail
   */
  initialRequestId?: number;
  /**
   *
   * @type {number}
   * @memberof OrderDetail
   */
  originalBudget?: number;
}

export function OrderDetailFromJSON(json: any): OrderDetail {
  return OrderDetailFromJSONTyped(json, false);
}

export function OrderDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OrderDetail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    productDescr: json["productDescr"],
    id: json["id"],
    productId: json["productId"],
    orderStartDate: new Date(json["orderStartDate"]),
    orderEndDate: new Date(json["orderEndDate"]),
    orderBudget: json["orderBudget"],
    medium: MediumEnumFromJSON(json["medium"]),
    requestedBudget: json["requestedBudget"],
    requestedGrp: json["requestedGrp"],
    bookedBudget: json["bookedBudget"],
    bookedGrp: json["bookedGrp"],
    achievedNettBudget: json["achievedNettBudget"],
    achievedGrp: json["achievedGrp"],
    campaignName: json["campaignName"],
    advertiserId: json["advertiserId"],
    advertiserName: json["advertiserName"],
    subOrders: !exists(json, "subOrders")
      ? undefined
      : json["subOrders"] === null
        ? null
        : (json["subOrders"] as Array<any>).map(SubOrderDetailFromJSON),
    status: OrderStatusFromJSON(json["status"]),
    runningState: RunningStateFromJSON(json["runningState"]),
    requestedImpressions: !exists(json, "requestedImpressions")
      ? undefined
      : json["requestedImpressions"],
    bookedImpressions: !exists(json, "bookedImpressions")
      ? undefined
      : json["bookedImpressions"],
    auditLogs: (json["auditLogs"] as Array<any>).map(AuditlogModelFromJSON),
    salesPeriod: DateSpanFromJSON(json["salesPeriod"]),
    isSpreaded: !exists(json, "isSpreaded") ? undefined : json["isSpreaded"],
    spreadedPeriod: !exists(json, "spreadedPeriod")
      ? undefined
      : DateSpanFromJSON(json["spreadedPeriod"]),
    analysis: AnalysisAvailabilityFromJSON(json["analysis"]),
    analysisTargetGroupIds: !exists(json, "analysisTargetGroupIds")
      ? undefined
      : json["analysisTargetGroupIds"],
    hasMaterial: !exists(json, "hasMaterial") ? undefined : json["hasMaterial"],
    bonusBudgetRequested: !exists(json, "bonusBudgetRequested")
      ? undefined
      : json["bonusBudgetRequested"],
    bonusBudgetBooked: !exists(json, "bonusBudgetBooked")
      ? undefined
      : json["bonusBudgetBooked"],
    initialRequestId: !exists(json, "initialRequestId")
      ? undefined
      : json["initialRequestId"],
    originalBudget: !exists(json, "originalBudget")
      ? undefined
      : json["originalBudget"],
  };
}

export function OrderDetailToJSON(value?: OrderDetail | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    productDescr: value.productDescr,
    id: value.id,
    productId: value.productId,
    orderStartDate: value.orderStartDate.toISOString(),
    orderEndDate: value.orderEndDate.toISOString(),
    orderBudget: value.orderBudget,
    medium: MediumEnumToJSON(value.medium),
    requestedBudget: value.requestedBudget,
    bookedBudget: value.bookedBudget,
    campaignName: value.campaignName,
    advertiserId: value.advertiserId,
    advertiserName: value.advertiserName,
    subOrders:
      value.subOrders === undefined
        ? undefined
        : value.subOrders === null
          ? null
          : (value.subOrders as Array<any>).map(SubOrderDetailToJSON),
    status: OrderStatusToJSON(value.status),
    runningState: RunningStateToJSON(value.runningState),
    requestedImpressions: value.requestedImpressions,
    bookedImpressions: value.bookedImpressions,
    auditLogs: (value.auditLogs as Array<any>).map(AuditlogModelToJSON),
    salesPeriod: DateSpanToJSON(value.salesPeriod),
    isSpreaded: value.isSpreaded,
    spreadedPeriod: DateSpanToJSON(value.spreadedPeriod),
    analysis: AnalysisAvailabilityToJSON(value.analysis),
    analysisTargetGroupIds: value.analysisTargetGroupIds,
    hasMaterial: value.hasMaterial,
    bonusBudgetRequested: value.bonusBudgetRequested,
    bonusBudgetBooked: value.bonusBudgetBooked,
    initialRequestId: value.initialRequestId,
    originalBudget: value.originalBudget,
  };
}
