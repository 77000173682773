import "./orderstatustooltip.less";

import { useLingui } from "@lingui/react";
import { Tag } from "@ster/ster-toolkit";
import { Popover } from "antd";
import { useCallback } from "react";

import { OrderStatus } from "../../../../api/models";
import { getOrderStatusTranslation, tagStatusMapper } from "../../../../utils";

export interface OrderStatusTooltipProps {
  status: OrderStatus;
}

const OrderStatusTooltip = ({ status }: OrderStatusTooltipProps) => {
  const { i18n } = useLingui();
  const TooltipContent = useCallback(
    () => (
      <div className="status-tooltip__container">
        <div className="status-wrapper">
          <Tag
            status={tagStatusMapper(OrderStatus.Concept)}
            text={i18n._(getOrderStatusTranslation(OrderStatus.Concept))}
          />
        </div>
        <div className="status-wrapper">
          <Tag
            status={tagStatusMapper(OrderStatus.Submitted)}
            text={i18n._(getOrderStatusTranslation(OrderStatus.Submitted))}
          />
        </div>
        <div className="status-wrapper">
          <Tag
            status={tagStatusMapper(OrderStatus.Planned)}
            text={i18n._(getOrderStatusTranslation(OrderStatus.Planned))}
          />
        </div>
        <div className="status-wrapper">
          <Tag
            status={tagStatusMapper(OrderStatus.Active)}
            text={i18n._(getOrderStatusTranslation(OrderStatus.Active))}
          />
        </div>
        <div className="status-wrapper">
          <Tag
            status={tagStatusMapper(OrderStatus.Ready)}
            text={i18n._(getOrderStatusTranslation(OrderStatus.Ready))}
          />
        </div>
      </div>
    ),
    [i18n]
  );

  return (
    <div className="status-tooltip">
      <Popover
        placement="bottomRight"
        arrow={{ pointAtCenter: true }}
        autoAdjustOverflow={false}
        content={<TooltipContent />}
      >
        <span>
          <Tag
            status={tagStatusMapper(status)}
            text={i18n._(getOrderStatusTranslation(status))}
          />
        </span>
      </Popover>
    </div>
  );
};

export default OrderStatusTooltip;
