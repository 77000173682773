import "./SpotAnalysis.less";

import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  ContentContainer,
  Icons,
  Pageheader,
  Row,
  Spinner,
} from "@ster/ster-toolkit";
import { Col } from "antd";
import {
  endOfMonth,
  startOfDay,
  startOfMonth,
  subDays,
  subMonths,
} from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";

import {
  AnalysisTargetGroupRadio,
  AnalysisTargetGroupTV,
  DateSpan,
  MediumEnum,
} from "../../api";
import {
  exportSpotTrackerAction,
  receiveSpotTrackerAction,
} from "../../store/analysis/actions";
import { receiveProductsAction } from "../../store/campaignCreate/actions";
import { receiveCampaignAnalysisTargetGroupsAction } from "../../store/campaignDetail/actions";
import { StoreModel } from "../../store/models";
import { targetGroupDisplayName } from "../../utils/analysis";
import { compact } from "../../utils/array";
import { useAvailableMediumTypes } from "../../utils/hooks";
import { SpotAnalysisFilter } from "./models";
import SpotAnalysisFilters from "./SpotAnalysisFilters";
import SpotAnalysisList, { SpotAnalysisColumn } from "./SpotAnalysisList";

const SpotAnalysis = () => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();

  /**
   * Initially select the previous month, taking into account that
   * the most recent day for an analysis is 7 days ago.
   */
  const previousMonth = useMemo<DateSpan>(() => {
    const mostRecent = startOfDay(subDays(new Date(), 7));
    return {
      from: startOfMonth(subMonths(mostRecent, 1)),
      to: endOfMonth(subMonths(mostRecent, 1)),
    };
  }, []);

  const getProducts = useCallback(
    (mediumValue: MediumEnum, period: DateSpan) => {
      dispatch(
        receiveProductsAction.request({
          medium: mediumValue,
          from: new Date(period.from),
          to: new Date(period.to),
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    getProducts(MediumEnum.Tv, previousMonth);
    dispatch(receiveCampaignAnalysisTargetGroupsAction.request());
  }, [dispatch, getProducts, previousMonth]);

  const { products: advertisers = [], loading: loadingAdvertisers = false } =
    useSelector((store: StoreModel) => store.products);

  const analysisTargetGroups = useSelector(
    (store: StoreModel) => store.campaignAnalysisTargetGroups
  );

  const loading = useMemo(
    () => loadingAdvertisers || analysisTargetGroups.loading,
    [analysisTargetGroups.loading, loadingAdvertisers]
  );

  const [mediumTypes, firstMediumType] = useAvailableMediumTypes("campagnes");

  const targetGroups = useMemo<
    (AnalysisTargetGroupTV | AnalysisTargetGroupRadio)[]
  >(() => {
    if (!analysisTargetGroups) {
      return [];
    }

    return (firstMediumType ?? MediumEnum.Tv) === MediumEnum.Tv
      ? analysisTargetGroups.tv
      : analysisTargetGroups.radio;
  }, [analysisTargetGroups, firstMediumType]);

  const [filter, setFilter] = useState<SpotAnalysisFilter>(() => ({
    medium: firstMediumType,
    period: [previousMonth.from, previousMonth.to],
  }));

  useEffect(() => {
    let targetGroup;
    if (targetGroups) {
      targetGroup = targetGroups.find(
        (tg) => targetGroupDisplayName(tg) === "13+"
      );
    }

    if (!targetGroup || filter.targetGroup) {
      return;
    }

    setFilter({ ...filter, targetGroup });
  }, [filter, targetGroups]);

  const handleChangeFilter = useCallback(
    (value: SpotAnalysisFilter) => {
      setFilter(value);
      if (
        !value.medium ||
        !value.targetGroup ||
        !value.advertiserId ||
        !value.productId
      ) {
        return;
      }

      dispatch(
        receiveSpotTrackerAction.request({
          spotTrackerRequestInput: {
            period: { start: value.period[0], end: value.period[1] },
            [value.medium === MediumEnum.Tv
              ? "targetGroupsTv"
              : "targetGroupsRadio"]: compact([
              value.targetGroup,
              ...(value.secondaryTargetGroups ?? []),
            ]),
            productId: [value.productId],
          },
          medium: value.medium,
        })
      );
    },
    [dispatch]
  );

  const exportSpotAnalysis = useCallback(
    (selectedColumns: SpotAnalysisColumn[]) => {
      if (
        !filter.medium ||
        !filter.targetGroup ||
        !filter.advertiserId ||
        !filter.productId
      ) {
        return;
      }

      dispatch(
        exportSpotTrackerAction.request({
          spotTrackerRequestInput: {
            period: { start: filter.period[0], end: filter.period[1] },
            [filter.medium === MediumEnum.Tv
              ? "targetGroupsTv"
              : "targetGroupsRadio"]: compact([
              filter.targetGroup,
              ...(filter.secondaryTargetGroups ?? []),
            ]),
            productId: [filter.productId],
          },
          selectedColumns,
          medium: filter.medium,
        })
      );
    },
    [
      dispatch,
      filter.advertiserId,
      filter.medium,
      filter.period,
      filter.productId,
      filter.secondaryTargetGroups,
      filter.targetGroup,
    ]
  );

  return (
    <>
      <Helmet>
        <title>{i18n._(t`Spotanalyse`)}</title>
      </Helmet>

      <Pageheader
        title={<Trans>Spotanalyse</Trans>}
        icon={
          <Icons.CampaignIcon
            width="100%"
            height="100%"
            fill="rgba(129, 176, 210, 0.2)"
          />
        }
      />

      <ContentContainer className="content">
        <Spinner spinning={loading}>
          <Row>
            <Col span={24}>
              {filter.targetGroup && (
                <SpotAnalysisFilters
                  filter={filter}
                  onChange={handleChangeFilter}
                  advertisers={advertisers.filter((a) => !a.isProspect)}
                  mediumTypes={mediumTypes}
                  targetGroups={targetGroups}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <SpotAnalysisList filter={filter} onExport={exportSpotAnalysis} />
            </Col>
          </Row>
        </Spinner>
      </ContentContainer>
    </>
  );
};

export default SpotAnalysis;
