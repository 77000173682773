/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  MediumEnum,
  MediumEnumFromJSON,
  MediumEnumFromJSONTyped,
  MediumEnumToJSON,
} from "./";

/**
 *
 * @export
 * @interface ProposalInitialRequest
 */
export interface ProposalInitialRequest {
  /**
   *
   * @type {MediumEnum}
   * @memberof ProposalInitialRequest
   */
  medium: MediumEnum;
  /**
   *
   * @type {number}
   * @memberof ProposalInitialRequest
   */
  initialRequestId: number;
  /**
   *
   * @type {Date}
   * @memberof ProposalInitialRequest
   */
  from: Date;
  /**
   *
   * @type {Date}
   * @memberof ProposalInitialRequest
   */
  to: Date;
}

export function ProposalInitialRequestFromJSON(
  json: any
): ProposalInitialRequest {
  return ProposalInitialRequestFromJSONTyped(json, false);
}

export function ProposalInitialRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProposalInitialRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    medium: MediumEnumFromJSON(json["medium"]),
    initialRequestId: json["initialRequestId"],
    from: new Date(json["from"]),
    to: new Date(json["to"]),
  };
}

export function ProposalInitialRequestToJSON(
  value?: ProposalInitialRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    medium: MediumEnumToJSON(value.medium),
    initialRequestId: value.initialRequestId,
    from: value.from.toISOString(),
    to: value.to.toISOString(),
  };
}
