/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ApplicationFromSterInlog,
  ApplicationFromSterInlogFromJSON,
  ApplicationFromSterInlogFromJSONTyped,
  ApplicationFromSterInlogToJSON,
  RoleFromSterInlog,
  RoleFromSterInlogFromJSON,
  RoleFromSterInlogFromJSONTyped,
  RoleFromSterInlogToJSON,
} from "./";

/**
 *
 * @export
 * @interface SterInlogUser
 */
export interface SterInlogUser {
  /**
   *
   * @type {string}
   * @memberof SterInlogUser
   */
  readonly id: string;
  /**
   *
   * @type {boolean}
   * @memberof SterInlogUser
   */
  internalUser: boolean;
  /**
   *
   * @type {string}
   * @memberof SterInlogUser
   */
  azureId?: string | null;
  /**
   *
   * @type {string}
   * @memberof SterInlogUser
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof SterInlogUser
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof SterInlogUser
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof SterInlogUser
   */
  fullName: string;
  /**
   *
   * @type {string}
   * @memberof SterInlogUser
   */
  company?: string | null;
  /**
   *
   * @type {string}
   * @memberof SterInlogUser
   */
  phonenumber?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof SterInlogUser
   */
  active: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SterInlogUser
   */
  verified: boolean;
  /**
   *
   * @type {Array<RoleFromSterInlog>}
   * @memberof SterInlogUser
   */
  roles: Array<RoleFromSterInlog>;
  /**
   *
   * @type {Array<ApplicationFromSterInlog>}
   * @memberof SterInlogUser
   */
  applications: Array<ApplicationFromSterInlog>;
}

export function SterInlogUserFromJSON(json: any): SterInlogUser {
  return SterInlogUserFromJSONTyped(json, false);
}

export function SterInlogUserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SterInlogUser {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    internalUser: json["internalUser"],
    azureId: !exists(json, "azureId") ? undefined : json["azureId"],
    email: json["email"],
    firstName: json["firstName"],
    lastName: json["lastName"],
    fullName: json["fullName"],
    company: !exists(json, "company") ? undefined : json["company"],
    phonenumber: !exists(json, "phonenumber") ? undefined : json["phonenumber"],
    active: json["active"],
    verified: json["verified"],
    roles: (json["roles"] as Array<any>).map(RoleFromSterInlogFromJSON),
    applications: (json["applications"] as Array<any>).map(
      ApplicationFromSterInlogFromJSON
    ),
  };
}

export function SterInlogUserToJSON(value?: SterInlogUser | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    internalUser: value.internalUser,
    azureId: value.azureId,
    email: value.email,
    firstName: value.firstName,
    lastName: value.lastName,
    fullName: value.fullName,
    company: value.company,
    phonenumber: value.phonenumber,
    active: value.active,
    verified: value.verified,
    roles: (value.roles as Array<any>).map(RoleFromSterInlogToJSON),
    applications: (value.applications as Array<any>).map(
      ApplicationFromSterInlogToJSON
    ),
  };
}
