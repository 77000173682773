import { saveAs } from "file-saver";
import moment from "moment";
import { AnyAction } from "redux";
import { Reducer, createReducer } from "typesafe-actions";

import { CommercialInstructionRequest } from "../../api";
import { genericReducer } from "../../utils";
import { Loading, ReduxStoreState } from "../base";
import {
  LoadingCampaign,
  LoadingCampaignExport,
  LoadingCampaignSpotsDownload,
  LoadingCampaignSpotsResult,
  LoadingCommercialInstruction,
  LoadingInstructionsResult,
  LoadingSaveCampaignInstructionsOnline,
  LoadingValidateCampaign,
} from "../models";
import {
  clearCampaignAnalysisAction,
  clearCampaignInstructionsAction,
  clearRelatedCampaignsAction,
  clearSaveCampaignInstructionsAction,
  clearSaveCampaignInstructionsOnlineAction,
  deleteInstructionAction,
  receiveCampaignAnalysisTargetGroupsAction,
  receiveCampaignDetailAction,
  receiveCampaignDetailWithSpotsAction,
  receiveCampaignExportAction,
  receiveCampaignInstructionsAction,
  receiveCampaignSpotsAction,
  receiveCampaignSpotsDownloadAction,
  requestCampaignAnalysisAction,
  requestCampaignAnalysisGenericAction,
  requestRelatedCampaignsAction,
  saveCampaignInstructionsAction,
  saveCampaignInstructionsOnlineAction,
  saveMultiCampaignInstructionsAction,
  validateCampaignAction,
} from "./actions";
import {
  LoadingCampaignAnalysisTargetGroups,
  RelatedCampaignsOutput,
} from "./models";

export const campaignDetailReducer: Reducer<
  Record<number, LoadingCampaign>,
  AnyAction
> = createReducer({
  state: ReduxStoreState.Initial,
})
  .handleAction(
    receiveCampaignDetailAction.request,
    (
      state: Record<number, LoadingCampaign>,
      action: ReturnType<typeof receiveCampaignDetailAction.request>
    ) => ({
      ...state,
      [action.payload.orderId]: {
        ...state[action.payload.orderId],
        loading: true,
        state: ReduxStoreState.Loading,
      },
    })
  )
  .handleAction(
    receiveCampaignDetailAction.failure,
    (
      state: Record<number, LoadingCampaign>,
      action: ReturnType<typeof receiveCampaignDetailAction.failure>
    ) => ({
      ...state,
      [action.payload.orderId]: {
        ...state[action.payload.orderId],
        loading: false,
        state: ReduxStoreState.Failure,
      },
    })
  )
  .handleAction(
    receiveCampaignDetailAction.success,
    (
      state: Record<number, LoadingCampaign>,
      action: ReturnType<typeof receiveCampaignDetailAction.success>
    ) => ({
      ...state,
      [action.payload.id]: {
        campaign: action.payload,
        loading: false,
        state: ReduxStoreState.Success,
      },
    })
  );

export const campaignDetailWithSpotsReducer: Reducer<
  Record<number, LoadingCampaign>,
  AnyAction
> = createReducer({
  state: ReduxStoreState.Initial,
})
  .handleAction(
    receiveCampaignDetailWithSpotsAction.request,
    (
      state: Record<number, LoadingCampaign>,
      action: ReturnType<typeof receiveCampaignDetailWithSpotsAction.request>
    ) => ({
      ...state,
      [action.payload.orderId]: {
        ...state[action.payload.orderId],
        loading: true,
        state: ReduxStoreState.Loading,
      },
    })
  )
  .handleAction(
    receiveCampaignDetailWithSpotsAction.failure,
    (
      state: Record<number, LoadingCampaign>,
      action: ReturnType<typeof receiveCampaignDetailWithSpotsAction.failure>
    ) => ({
      ...state,
      [action.payload.orderId]: {
        ...state[action.payload.orderId],
        loading: false,
        state: ReduxStoreState.Failure,
      },
    })
  )
  .handleAction(
    receiveCampaignDetailWithSpotsAction.success,
    (
      state: Record<number, LoadingCampaign>,
      action: ReturnType<typeof receiveCampaignDetailWithSpotsAction.success>
    ) => ({
      ...state,
      [action.payload.id]: {
        campaign: action.payload,
        loading: false,
        state: ReduxStoreState.Success,
      },
    })
  );

export const campaignSpotsReducer: Reducer<
  Record<number, LoadingCampaignSpotsResult>,
  AnyAction
> = createReducer({
  state: ReduxStoreState.Initial,
})
  .handleAction(
    receiveCampaignSpotsAction.request,
    (
      state: Record<number, LoadingCampaignSpotsResult>,
      action: ReturnType<typeof receiveCampaignSpotsAction.request>
    ) => ({
      ...state,
      [action.payload.orderId]: {
        ...state[action.payload.orderId],
        loading: true,
        state: ReduxStoreState.Loading,
      },
    })
  )
  .handleAction(
    receiveCampaignSpotsAction.failure,
    (
      state: Record<number, LoadingCampaignSpotsResult>,
      action: ReturnType<typeof receiveCampaignSpotsAction.failure>
    ) => ({
      ...state,
      [action.payload.orderId]: {
        ...state[action.payload.orderId],
        loading: false,
        state: ReduxStoreState.Failure,
      },
    })
  )
  .handleAction(
    receiveCampaignSpotsAction.success,
    (
      state: Record<number, LoadingCampaignSpotsResult>,
      action: ReturnType<typeof receiveCampaignSpotsAction.success>
    ) => ({
      ...state,
      [action.payload.orderId]: {
        ...action.payload,
        loading: false,
        state: ReduxStoreState.Success,
      },
    })
  );

export const campaignSpotsDownloadReducer: Reducer<
  LoadingCampaignSpotsDownload,
  AnyAction
> = createReducer({
  state: ReduxStoreState.Initial,
})
  .handleAction(receiveCampaignSpotsDownloadAction.request, () => ({
    loading: true,
    state: ReduxStoreState.Loading,
  }))
  .handleAction(receiveCampaignSpotsDownloadAction.failure, () => ({
    loading: false,
    state: ReduxStoreState.Failure,
  }))
  .handleAction(
    receiveCampaignSpotsDownloadAction.success,
    (
      state: LoadingCampaignSpotsDownload,
      action: ReturnType<typeof receiveCampaignSpotsDownloadAction.success>
    ) => {
      const { orderId, fileType, data } = action.payload;
      const filename = `ster-spots-${orderId}-${moment().format(
        "YYYYMMDDhhmm"
      )}.${fileType}`;
      const fileContents = data as Blob;
      saveAs(fileContents, filename);

      return {
        loading: false,
        state: ReduxStoreState.Success,
      };
    }
  );

export const campaignExportReducer: Reducer<LoadingCampaignExport, AnyAction> =
  createReducer({
    state: ReduxStoreState.Initial,
  })
    .handleAction(receiveCampaignExportAction.request, () => ({
      loading: true,
      state: ReduxStoreState.Loading,
    }))
    .handleAction(receiveCampaignExportAction.failure, () => ({
      loading: false,
      state: ReduxStoreState.Failure,
    }))
    .handleAction(
      receiveCampaignExportAction.success,
      (
        state: LoadingCampaignSpotsDownload,
        action: ReturnType<typeof receiveCampaignExportAction.success>
      ) => {
        const { orderId, fileType, data } = action.payload;
        const filename = `ster-spots-${orderId}-${moment().format(
          "YYYYMMDDhhmm"
        )}.${fileType}`;
        const fileContents = data as Blob;
        saveAs(fileContents, filename);

        return {
          loading: false,
          state: ReduxStoreState.Success,
        };
      }
    );

export const campaignInstructionsReducer: Reducer<
  LoadingInstructionsResult,
  AnyAction
> = createReducer({
  state: ReduxStoreState.Initial,
})
  .handleAction(
    receiveCampaignInstructionsAction.request,
    (state: LoadingInstructionsResult) => ({
      ...state,
      loading: true,
      state: ReduxStoreState.Loading,
    })
  )
  .handleAction(
    receiveCampaignInstructionsAction.failure,
    (state: Record<string, LoadingInstructionsResult>) => ({
      ...state,
      loading: false,
      state: ReduxStoreState.Failure,
    })
  )
  .handleAction(
    receiveCampaignInstructionsAction.success,
    (
      state: Record<string, LoadingInstructionsResult>,
      action: ReturnType<typeof receiveCampaignInstructionsAction.success>
    ) => ({
      instructions: action.payload,
      loading: false,
      state: ReduxStoreState.Success,
    })
  )
  .handleAction(clearCampaignInstructionsAction, () => ({
    instructions: undefined,
    loading: false,
    state: ReduxStoreState.Initial,
  }));

export const saveCampaignInstructionsReducer: Reducer<
  LoadingCommercialInstruction,
  AnyAction
> = createReducer({
  state: ReduxStoreState.Initial,
})
  .handleAction(
    clearSaveCampaignInstructionsAction,
    (): LoadingCommercialInstruction =>
      ({ state: ReduxStoreState.Initial }) as LoadingCommercialInstruction
  )
  .handleAction(
    saveCampaignInstructionsAction.request,
    (
      state: CommercialInstructionRequest,
      { payload }: ReturnType<typeof saveCampaignInstructionsAction.request>
    ) => ({
      ...payload,
      state: ReduxStoreState.Loading,
    })
  )
  .handleAction(
    saveCampaignInstructionsAction.failure,
    (
      state: CommercialInstructionRequest,
      { payload }: ReturnType<typeof saveCampaignInstructionsAction.request>
    ) => ({
      ...payload,
      state: ReduxStoreState.Failure,
    })
  )
  .handleAction(
    saveCampaignInstructionsAction.success,
    (
      state: CommercialInstructionRequest,
      { payload }: ReturnType<typeof saveCampaignInstructionsAction.success>
    ): LoadingCommercialInstruction =>
      ({
        ...payload,
        state: ReduxStoreState.Success,
      }) as LoadingCommercialInstruction
  );

export const saveMultiCampaignInstructionsReducer: Reducer<Loading, AnyAction> =
  createReducer({
    state: ReduxStoreState.Initial,
  })
    .handleAction(saveMultiCampaignInstructionsAction.request, () => ({
      loading: true,
      state: ReduxStoreState.Loading,
    }))
    .handleAction(saveMultiCampaignInstructionsAction.failure, () => ({
      loading: false,
      state: ReduxStoreState.Failure,
    }))
    .handleAction(saveMultiCampaignInstructionsAction.success, () => ({
      loading: false,
      state: ReduxStoreState.Success,
    }));

export const deleteInstructionReducer: Reducer<Loading, AnyAction> =
  createReducer({
    state: ReduxStoreState.Initial,
  })
    .handleAction(deleteInstructionAction.request, () => ({
      loading: true,
      state: ReduxStoreState.Loading,
    }))
    .handleAction(deleteInstructionAction.failure, () => ({
      loading: false,
      state: ReduxStoreState.Failure,
    }))
    .handleAction(deleteInstructionAction.success, () => ({
      loading: false,
      state: ReduxStoreState.Success,
    }));

export const validateCampaignReducer: Reducer<
  LoadingValidateCampaign,
  AnyAction
> = genericReducer(
  validateCampaignAction.request,
  validateCampaignAction.success,
  validateCampaignAction.failure
);

export const campaignAnalysisReducer: Reducer<Loading, AnyAction> =
  genericReducer(
    requestCampaignAnalysisAction.request,
    requestCampaignAnalysisAction.success,
    requestCampaignAnalysisAction.failure,
    undefined,
    clearCampaignAnalysisAction
  );

export const campaignAnalysisGenericReducer: Reducer<Loading, AnyAction> =
  genericReducer(
    requestCampaignAnalysisGenericAction.request,
    requestCampaignAnalysisGenericAction.success,
    requestCampaignAnalysisGenericAction.failure,
    undefined,
    clearCampaignAnalysisAction
  );

export const campaignAnalysisTargetGroupsReducer: Reducer<
  LoadingCampaignAnalysisTargetGroups,
  AnyAction
> = genericReducer(
  receiveCampaignAnalysisTargetGroupsAction.request,
  receiveCampaignAnalysisTargetGroupsAction.success,
  receiveCampaignAnalysisTargetGroupsAction.failure
);

export const saveCampaignInstructionsOnlineReducer: Reducer<
  LoadingSaveCampaignInstructionsOnline,
  AnyAction
> = genericReducer(
  saveCampaignInstructionsOnlineAction.request,
  saveCampaignInstructionsOnlineAction.success,
  saveCampaignInstructionsOnlineAction.failure,
  undefined,
  clearSaveCampaignInstructionsOnlineAction
);

export const requestRelatedCampaignsReducer: Reducer<
  RelatedCampaignsOutput,
  AnyAction
> = genericReducer(
  requestRelatedCampaignsAction.request,
  requestRelatedCampaignsAction.success,
  requestRelatedCampaignsAction.failure,
  "related",
  clearRelatedCampaignsAction
);
