import { Channel, ChannelNames } from "@ster/ster-toolkit";

import styles from "./ChannelList.module.less";

const ChannelList = ({
  channelNames,
  channelWidth,
  channelHeight,
}: {
  channelNames: ChannelNames[];
  channelWidth?: number | string;
  channelHeight?: number | string;
}) => (
  <div className={styles.channelList}>
    {channelNames &&
      channelNames.map((channelName) => (
        <span key={channelName} className={styles.channelIcon}>
          <Channel
            type={channelName}
            width={channelWidth}
            height={channelHeight}
          />
        </span>
      ))}
  </div>
);
export default ChannelList;
