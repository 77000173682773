import "../../Pickers/styles.less";

import { Trans } from "@lingui/macro";
import { Button } from "@ster/ster-toolkit";
import { useCallback } from "react";

import {
  CommercialInstructionSpots,
  CommercialsPerAdvertiser,
  OnlineInstructionPerPeriod,
} from "../../../../api";
import { getFormattedDateShort } from "../../../../utils/dateHelper";
import { DateRange } from "../../../../utils/types";
import InnerStep from "../../Steps/InnerStep";
import { SimpleOnlineSubOrder } from "../types";
import { emptyOnlineCommercialSelection } from "../utils";
import styles from "./OnlineCommercialPicker.module.less";
import { OnlineCommercialsPerPeriod } from "./OnlineCommercialsPerPeriod";

export interface OnlineCommercialPickerProps {
  commercialsPerAdvertiser?: CommercialsPerAdvertiser;
  spotLength: number[];
  value: OnlineInstructionPerPeriod[];
  onChange: (values: OnlineInstructionPerPeriod[]) => void;
  enableAdd?: boolean;
  enableRemove?: boolean;
  subOrder: SimpleOnlineSubOrder;
}

export const OnlineCommercialPicker = ({
  subOrder,
  commercialsPerAdvertiser,
  spotLength,
  value,
  onChange,
  enableAdd,
  enableRemove,
}: OnlineCommercialPickerProps) => {
  const handleChangePeriod = useCallback(
    (index: number, period: DateRange | undefined) => {
      const newValue = [
        ...value.slice(0, index),
        {
          ...value[index],
          startDate: period?.startDate,
          endDate: period?.endDate,
        },
        ...value.slice(index + 1, value.length),
      ];
      onChange(newValue);
    },
    [onChange, value]
  );

  const handleChangeSpots = useCallback(
    (index: number, instructions: CommercialInstructionSpots[]) => {
      const newValue = [
        ...value.slice(0, index),
        { ...value[index], instructions },
        ...value.slice(index + 1, value.length),
      ];
      onChange(newValue);
    },
    [onChange, value]
  );

  const handleAddPeriod = useCallback(() => {
    onChange([
      ...value,
      {
        id: Math.max(...(value.map((c) => c.id) ?? 0)) + 1,
        startDate: undefined,
        endDate: undefined,
        instructions: emptyOnlineCommercialSelection,
      },
    ]);
  }, [onChange, value]);

  const onDelete = useCallback(
    (toDelete: OnlineInstructionPerPeriod) => {
      const index = value.map((c) => c.id).indexOf(toDelete.id);
      const newValue = [
        ...value.slice(0, index),
        ...value.slice(index + 1, value.length),
      ];
      onChange(newValue);
    },
    [onChange, value]
  );

  return (
    <InnerStep
      title={`${subOrder.packageName}${
        subOrder.spotLength?.[0] ? ` ${subOrder.spotLength?.[0]}"` : ""
      }: ${subOrder.campaignName}`}
      subTitle={`${getFormattedDateShort(
        subOrder.dateRange.startDate
      )} - ${getFormattedDateShort(subOrder.dateRange.endDate)}`}
    >
      <section className="picker commercial-picker">
        {value?.map((p, index) => (
          <OnlineCommercialsPerPeriod
            key={p.id}
            commercialsPerAdvertiser={commercialsPerAdvertiser}
            onChangeSpots={(spots) => handleChangeSpots(index, spots)}
            onChangePeriod={(period) => handleChangePeriod(index, period)}
            spotLength={spotLength}
            enableAdd={enableAdd}
            enableRemove={enableRemove}
            subOrder={subOrder}
            spotsPerPeriod={p}
            canDelete={index > 0}
            onDelete={onDelete}
          />
        ))}
        <Button
          mode="tertiary"
          onClick={handleAddPeriod}
          className={styles.add}
        >
          +&nbsp;<Trans>Periode toevoegen</Trans>
        </Button>
      </section>
    </InnerStep>
  );
};
