/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface TargetGroupRequest
 */
export interface TargetGroupRequest {
  /**
   *
   * @type {number}
   * @memberof TargetGroupRequest
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof TargetGroupRequest
   */
  intomartTargetGroupId?: string | null;
  /**
   *
   * @type {string}
   * @memberof TargetGroupRequest
   */
  targetGroupId?: string | null;
}

export function TargetGroupRequestFromJSON(json: any): TargetGroupRequest {
  return TargetGroupRequestFromJSONTyped(json, false);
}

export function TargetGroupRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TargetGroupRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    intomartTargetGroupId: !exists(json, "intomartTargetGroupId")
      ? undefined
      : json["intomartTargetGroupId"],
    targetGroupId: !exists(json, "targetGroupId")
      ? undefined
      : json["targetGroupId"],
  };
}

export function TargetGroupRequestToJSON(
  value?: TargetGroupRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    intomartTargetGroupId: value.intomartTargetGroupId,
    targetGroupId: value.targetGroupId,
  };
}
