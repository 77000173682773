import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Typography } from "antd";
import { memo } from "react";

import SimplePage from "../SimplePage";

const Forbidden = memo(() => {
  const { i18n } = useLingui();
  return (
    <SimplePage title={i18n._(t`Geen toegang`)}>
      <Trans>
        <Typography.Paragraph>
          Je hebt niet de juiste rechten voor dit onderdeel van de Ster
          Klantportal.
        </Typography.Paragraph>
      </Trans>
    </SimplePage>
  );
});

export default Forbidden;
