import { memo } from "react";

import { Banner } from "../../../../api";
import AdScienceTagPlayer from "./AdScienceTagPlayer";

interface BannerPlayerProps {
  banner: Banner;
  autoSizeToComponentRef?: React.RefObject<HTMLDivElement>;
}

const BannerPlayer = memo(
  ({ banner, autoSizeToComponentRef }: BannerPlayerProps) =>
    banner &&
    banner.tag &&
    banner.width &&
    banner.height && (
      <AdScienceTagPlayer
        width={banner.width}
        height={banner.height}
        tag={banner.tag}
        autoSizeToComponentRef={autoSizeToComponentRef}
      />
    )
);

export default BannerPlayer;
