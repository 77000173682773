import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Icons,
  Row,
  Tooltip,
  formatNumber,
  formatToEuro,
} from "@ster/ster-toolkit";
import { Alert, Col, Popover } from "antd";
import { ColumnsType } from "antd/lib/table";
import classNames from "classnames";
import moment from "moment";
// eslint-disable-next-line import/no-extraneous-dependencies
import { RenderExpandIconProps } from "rc-table/lib/interface";
import { memo, useCallback, useMemo, useRef } from "react";

import {
  DateSpan,
  MediumEnum,
  PackageChoiceEnum,
  PackageRequest,
  SubOrderRequest,
  TargetGroup,
  UpdateFields,
} from "../../../api";
import { getDistinctChannelNames } from "../../../utils/channelNamesUtils";
import { getDaysDiff, getFormattedDateShort } from "../../../utils/dateHelper";
import SubOrderListFooter from "../../campaigns/SubOrderListFooter";
import BudgetDecreaseIncreaseWarning from "../../partials/BudgetDecreaseIncreaseWarning";
import ChannelList from "../../partials/Channel/ChannelList";
import { BreakPerSubOrder } from "../models";
import FixedCostsSubOrder from "./FixedCostsSubOrder";
import GrpSubOrder from "./GrpSubOrder";
import { SubOrderListProps } from "./models";
import NpoPromoSubOrder from "./NpoPromoSubOrder";
import OnlineSubOrder from "./OnlineSubOrder";
import BlockList from "./partials/BlockList";
import EditableTable from "./partials/EditableTable";
import { EditableCellProps } from "./partials/models";
import styles from "./SubOrderList.module.less";

// Icoon voor het uitklappen van een rij
const ExpandIcon = ({
  expanded,
  record,
  onExpand,
}: RenderExpandIconProps<SubOrderRequest>) => (
  <div
    role="button"
    tabIndex={0}
    onClick={(e): void => {
      onExpand(record, e);
    }}
  >
    {expanded ? <Icons.ChevronDownIcon /> : <Icons.ChevronRightIcon />}
  </div>
);

export interface ContextMenuProps {
  id: number;
  showEdit: boolean;
  onExpand?: (expand: boolean, { id }: Partial<SubOrderRequest>) => void;
  onDuplicate: (id: number) => void;
  onDelete: (id: number) => void;
}

const ContextMenu = memo(
  ({ id, showEdit, onExpand, onDuplicate, onDelete }: ContextMenuProps) => {
    const { i18n } = useLingui();

    const handleExpand = useCallback(() => {
      onExpand?.(true, { id });
    }, [id, onExpand]);

    const handleDuplicateSubOrder = useCallback(() => {
      onDuplicate(id);
    }, [id, onDuplicate]);

    const handleDeleteSubOrder = useCallback(() => {
      onDelete(id);
    }, [id, onDelete]);

    return (
      <div>
        {showEdit && onExpand && (
          <div
            role="button"
            aria-label={i18n._(t`Inklappen`)}
            tabIndex={0}
            className="context-menu__link"
            onClick={handleExpand}
          >
            <Icons.EditIcon fill="#000" />
            <Trans>Bewerken</Trans>
          </div>
        )}
        <div
          role="button"
          aria-label={i18n._(t`Dupliceren`)}
          tabIndex={0}
          className="context-menu__link"
          onClick={handleDuplicateSubOrder}
        >
          <Icons.CopyIcon fill="#000" height="18" />
          <Trans>Dupliceren</Trans>
        </div>
        <div
          role="button"
          aria-label={i18n._(t`Verwijderen`)}
          tabIndex={0}
          className="context-menu__link"
          onClick={handleDeleteSubOrder}
        >
          <Icons.DeleteIcon fill="#000" />
          <Trans>Verwijderen</Trans>
        </div>
      </div>
    );
  }
);

export interface SubOrderContextMenuProps {
  id: number;
  showEdit?: boolean;
  className?: string;
  onExpand?: (expand: boolean, { id }: Partial<SubOrderRequest>) => void;
  onDuplicate: (id: number) => void;
  onDelete: (id: number) => void;
}

const SubOrderContextMenu = memo(
  ({
    id,
    showEdit = true,
    className,
    onExpand,
    onDuplicate,
    onDelete,
  }: SubOrderContextMenuProps) => (
    <Popover
      trigger="click"
      content={
        <ContextMenu
          id={id}
          showEdit={showEdit}
          onExpand={onExpand}
          onDuplicate={onDuplicate}
          onDelete={onDelete}
        />
      }
      placement="bottomRight"
      overlayClassName=""
      className={className}
    >
      <button type="button" className="more-menu-btn">
        <Icons.MoreIcon height={28} />
      </button>
    </Popover>
  )
);

/**
 * Lijstweergave van deelorders.
 */
const SubOrderList = memo(
  ({
    subOrders,
    subOrdersOriginal,
    onChange,
    productId,
    period,
    medium,
    onAddNewSubOrder,
    onDuplicateSubOrder,
    onDeleteSubOrder,
    onScrollToComments,
    expandedItemKey,
    setExpandedItemKey,
    editable,
    packageChoices,
    selectedSalesPeriod,
    requestDate,
    isEditCampaign = false,
    originalBudget,
  }: SubOrderListProps) => {
    // Event wanneer er een rij uitgeklapt wordt
    const handleExpand = useCallback(
      (expand: boolean, { id }: Partial<SubOrderRequest>): void => {
        if (id !== undefined && id !== null) {
          setExpandedItemKey(expand ? [id] : []);
        }
      },
      [setExpandedItemKey]
    );

    const handleDuplicateSubOrder = useCallback(
      (id: number) => {
        onDuplicateSubOrder(id);
      },
      [onDuplicateSubOrder]
    );

    const showContextMenu = useCallback(
      (subOrder: SubOrderRequest) =>
        // Via het contextmenu kunnen campagnes verwijderd worden,
        // deze tonen we bij het wijzigen van een bestaande campagne alleen als de campagne gewijzigd mag worden
        editable &&
        (!isEditCampaign ||
          subOrder.editableFields?.includes(UpdateFields.Package)),
      [editable, isEditCampaign]
    );

    const canAddSubOrder = useMemo(
      () =>
        // Het toevoegen van deelorders mag voor een bestaande campagne alleen als er deelorder is waarvoor het pakket nog gewijzigd mag worden
        // Dan is de campagne nog niet gestart
        editable &&
        ((!isEditCampaign ||
          subOrders?.some((s) =>
            s.editableFields?.includes(UpdateFields.Package)
          )) ??
          false),
      [editable, isEditCampaign, subOrders]
    );

    const { i18n } = useLingui();
    const columns = [
      {
        title: i18n._(t`Periode`),
        dataIndex: "period",
        key: "period",
        render: (dates: DateSpan) => (
          <span>
            {dates &&
              `${getFormattedDateShort(dates.from)} - ${getFormattedDateShort(
                dates.to
              )}`}
          </span>
        ),
      },
      {
        title: i18n._(t`Spotlengte`),
        dataIndex: "spotLength",
        key: "spotLength",
        render: (spotLength: number[]) => (
          <span>
            {spotLength?.length > 0
              ? spotLength.map((s) => s && `${s}"`).join(" + ")
              : "n.v.t."}
          </span>
        ),
      },
      {
        title: i18n._(t`Pakket`),
        dataIndex: "_package",
        key: "_package",
        expandableCell: false,
        render: (
          { name, preferredPositionSurcharge }: Partial<PackageRequest> = {},
          { breakSelection }: SubOrderRequest
        ) => (
          <>
            {name ?? ""}
            <BlockList
              breakSelection={breakSelection}
              preferredPositionSurcharge={preferredPositionSurcharge}
            />
          </>
        ),
      },
      {
        title: i18n._(t`Inkoopdoelgroep`),
        dataIndex: "targetGroup",
        key: "targetGroup",
        render: (
          targetGroup: TargetGroup,
          subOrderRequest: SubOrderRequest
        ) => {
          const channelNames = getDistinctChannelNames(subOrderRequest);

          if (subOrderRequest.packageChoice === PackageChoiceEnum.Grps) {
            return (
              <span className={styles.targetGroupWrapper}>
                <ChannelList channelNames={channelNames} />
                <span>{targetGroup?.targetGroupId}</span>
              </span>
            );
          }

          if (channelNames.length > 0) {
            return (
              <span className={styles.targetGroupWrapper}>
                <ChannelList channelNames={channelNames} />
              </span>
            );
          }

          return "n.v.t.";
        },
      },
      {
        title: i18n._(t`Campagnenaam`),
        dataIndex: "campaignName",
        key: "campaignName",
        render: (campaignName: string): string => campaignName,
      },
      {
        title: i18n._(t`GRP's`),
        dataIndex: "grp",
        key: "grp",
        editableCell: true,
        onCell: () => ({ colSpan: 3 }),
        render: (grp: number, { packageChoice }: SubOrderRequest): string =>
          packageChoice === PackageChoiceEnum.Grps
            ? formatNumber(grp ?? 0, 2)
            : i18n._(t`n.v.t.`),
      },
      {
        title: i18n._(t`Impressies`),
        dataIndex: "impressions",
        key: "impressions",
        render: (impressions: number): string => formatNumber(impressions ?? 0),
      },
      {
        title: i18n._(t`Budget`),
        dataIndex: "budget",
        key: "budget",
        editableCell: true,
        render: (value: number): string =>
          value ? formatToEuro(value, false) : formatToEuro(0, false),
      },
      {
        title: i18n._(t`Spots/dag/zender`),
        dataIndex: "spotNo",
        key: "spotNo",
        render: (
          value: number,
          { period: subOrderPeriod, excluded, channels }: SubOrderRequest
        ): number =>
          value
            ? Math.round(
                value /
                  (getDaysDiff(
                    moment(subOrderPeriod?.from),
                    moment(subOrderPeriod?.to)
                  ) -
                    (excluded?.length ?? 0)) /
                  (channels?.length ?? 0)
              )
            : 0,
      },
      {
        title: i18n._(t`Dagelijkse eindtijd`),
        dataIndex: "endTime",
        key: "endTime",
        render: (value: number): number => value,
      },
      {
        title: i18n._(t`Zenders`),
        dataIndex: "channels",
        key: "channels",
        render: (value: string[]): string =>
          [...(value ?? [])].sort((a, b) => a.localeCompare(b)).join(", "),
      },
      {
        title: "",
        expandableCell: false,
        render: (subOrder: SubOrderRequest) => (
          <div className={styles.contextMenuCell}>
            {subOrder.errorMessage && (
              <Tooltip title={subOrder.errorMessage}>
                <span>
                  <Icons.InfoIcon fill="#bc2d34" />
                </span>
              </Tooltip>
            )}
            {subOrder.id && showContextMenu(subOrder) ? (
              <SubOrderContextMenu
                id={subOrder.id}
                showEdit
                onExpand={handleExpand}
                onDuplicate={handleDuplicateSubOrder}
                onDelete={onDeleteSubOrder}
              />
            ) : undefined}
          </div>
        ),
      },
    ];

    // Renderen van een rij in de tabel
    const onRow = ({
      id,
    }: Partial<SubOrderRequest>): React.HTMLAttributes<HTMLElement> =>
      expandedItemKey?.includes(id as number)
        ? { className: styles.expanded } // Geef de uitgeklapte rij een aparte class
        : { className: "" };

    // Afhandelen van het optreden van een error bij het versturen van het formulier
    const handleFinishFailed = useCallback(
      (id: number) => {
        // Klap het formulier uit als er een error in zit bij het versturen
        setExpandedItemKey([id]);
      },
      [setExpandedItemKey]
    );

    const filterColumns = useCallback(
      (mergedColumns: ColumnsType<SubOrderRequest>) => {
        let columnsToFilter: string[] = [];
        if (subOrders) {
          // Voor NPO promo worden er afwijkende kolommen getoond
          if (
            subOrders.find(
              (s) => s.packageChoice === PackageChoiceEnum.NpoPromo
            )
          ) {
            columnsToFilter = [
              ...columnsToFilter,
              "targetGroup",
              "grp",
              "budget",
            ];
          } else {
            columnsToFilter = [
              ...columnsToFilter,
              "campaignName",
              "spotNo",
              "endTime",
              "channels",
            ];
          }
        }
        if (medium === MediumEnum.Inter) {
          columnsToFilter = [...columnsToFilter, "targetGroup", "grp"];
        } else {
          columnsToFilter = [...columnsToFilter, "impressions"];
        }
        return mergedColumns.filter(
          (s) => !columnsToFilter.find((c) => c === s.key)
        );
      },
      [medium, subOrders]
    );

    const allSelectedBlockIds = useMemo<BreakPerSubOrder[]>(
      () =>
        subOrders?.flatMap((s) =>
          (s.breakSelection ?? []).map((b) => ({
            subOrderId: s.id,
            selectedBreak: b.key,
          }))
        ) ?? [],
      [subOrders]
    );

    const lastPackageRef = useRef<HTMLDivElement | null>(null);
    // Uitklappen van een rij in de tabel
    const expandedRowRender = useCallback(
      (subOrder: SubOrderRequest) => {
        const { id = 0, packageChoice } = subOrder;
        const subOrderOriginal = subOrdersOriginal?.find((s) => s.id === id);
        const showNoEditMessage =
          isEditCampaign && (subOrder?.editableFields?.length ?? 0) === 0;

        return (
          <Row>
            <Col span={1}>
              <div
                onClick={(): void => handleExpand(false, { id })}
                role="button"
                aria-label={i18n._(t`Inklappen`)}
                tabIndex={0}
                className={styles.collapse}
              >
                <Icons.ChevronDownIcon />
              </div>
            </Col>
            <Col span={23}>
              {showNoEditMessage && (
                <Alert
                  className={styles.info}
                  showIcon
                  type="info"
                  message=""
                  description={
                    <Trans>
                      Voor deze deelorder zijn geen aanpassingen mogelijk.
                    </Trans>
                  }
                />
              )}
              <div className="marker" id={`marker-${id}`} ref={lastPackageRef}>
                {(() => {
                  switch (packageChoice) {
                    case PackageChoiceEnum.FixedCosts:
                    case PackageChoiceEnum.FixedCostsCult:
                      return (
                        <FixedCostsSubOrder
                          onChange={onChange}
                          subOrder={subOrder}
                          subOrderOriginal={subOrderOriginal}
                          productId={productId}
                          period={period}
                          medium={medium}
                          onFinishFailed={handleFinishFailed}
                          selectedSalesPeriod={selectedSalesPeriod}
                          alreadySelected={allSelectedBlockIds
                            .filter((b) => b.subOrderId !== id)
                            .map((b) => b.selectedBreak as string)}
                        />
                      );
                    case PackageChoiceEnum.Grps:
                      return (
                        <GrpSubOrder
                          onChange={onChange}
                          subOrder={subOrder}
                          subOrderOriginal={subOrderOriginal}
                          productId={productId}
                          period={period}
                          medium={medium}
                          onFinishFailed={handleFinishFailed}
                          selectedSalesPeriod={selectedSalesPeriod}
                          subOrders={subOrders}
                          alreadySelected={allSelectedBlockIds
                            .filter((b) => b.subOrderId !== id)
                            .map((b) => b.selectedBreak as string)}
                          isEditCampaign={isEditCampaign}
                          requestDate={requestDate}
                        />
                      );
                    case PackageChoiceEnum.NpoPromo:
                      return (
                        <NpoPromoSubOrder
                          onChange={onChange}
                          subOrder={subOrder}
                          subOrderOriginal={subOrderOriginal}
                          productId={productId}
                          period={period}
                          medium={medium}
                          onFinishFailed={handleFinishFailed}
                          selectedSalesPeriod={selectedSalesPeriod}
                        />
                      );
                    case PackageChoiceEnum.Display:
                    case PackageChoiceEnum.Video:
                      return (
                        <OnlineSubOrder
                          onChange={onChange}
                          subOrder={subOrder}
                          subOrderOriginal={subOrderOriginal}
                          productId={productId}
                          period={period}
                          medium={medium}
                          onFinishFailed={handleFinishFailed}
                          packageChoice={packageChoice}
                          selectedSalesPeriod={selectedSalesPeriod}
                          isEditCampaign={isEditCampaign}
                          requestDate={requestDate}
                        />
                      );
                    default:
                      // eslint-disable-next-line react/jsx-no-useless-fragment
                      return <></>;
                  }
                })()}
                {isEditCampaign && (
                  <BudgetDecreaseIncreaseWarning
                    key={id}
                    subOrders={subOrders}
                    originalBudget={originalBudget}
                  />
                )}
              </div>
              {id && showContextMenu(subOrder) && (
                <SubOrderContextMenu
                  id={id}
                  showEdit={false}
                  className={styles.contextMenu}
                  onDuplicate={handleDuplicateSubOrder}
                  onDelete={onDeleteSubOrder}
                />
              )}
            </Col>
          </Row>
        );
      },
      [
        allSelectedBlockIds,
        handleDuplicateSubOrder,
        handleExpand,
        handleFinishFailed,
        i18n,
        isEditCampaign,
        medium,
        onChange,
        onDeleteSubOrder,
        originalBudget,
        period,
        productId,
        requestDate,
        selectedSalesPeriod,
        showContextMenu,
        subOrders,
        subOrdersOriginal,
      ]
    );

    // Breid de kolommen uit met properties voor de wijzigbare cel
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const mergedColumns: any = columns.map((col) => ({
      ...col,
      onCell: (record: SubOrderRequest): EditableCellProps => ({
        record,
        dataIndex: col.dataIndex as keyof SubOrderRequest,
        editableCell: col.editableCell,
        expandableCell: col.expandableCell,
        onExpand: handleExpand,
        expandedItemKey,
        editable,
        medium,
        productId,
        onSubOrderChange: onChange,
        requestDate,
        isEditCampaign,
      }),
    }));

    const expandIcon = useCallback(
      (props: RenderExpandIconProps<SubOrderRequest>) => (
        <ExpandIcon {...props} />
      ),
      []
    );

    const footer = useCallback(
      () => (
        <SubOrderListFooter
          subOrders={subOrders}
          onAddNewSubOrder={onAddNewSubOrder}
          packageChoices={packageChoices}
          medium={medium}
          packageRef={lastPackageRef}
          canAddSubOrder={canAddSubOrder}
          isEditCampaign={isEditCampaign}
          onScrollToComments={onScrollToComments}
          originalBudget={originalBudget}
          isSubOrderExpanded={(expandedItemKey ?? []).length > 0}
        />
      ),
      [
        canAddSubOrder,
        expandedItemKey,
        isEditCampaign,
        medium,
        onAddNewSubOrder,
        onScrollToComments,
        originalBudget,
        packageChoices,
        subOrders,
      ]
    );

    return (
      <EditableTable
        rowKey={({ id }): number => id ?? 0}
        className={classNames(styles.subOrderList, {
          [styles.editable]: editable,
        })}
        columns={filterColumns(mergedColumns)}
        dataSource={subOrders ?? []}
        onRow={onRow}
        pagination={false}
        expandable={
          editable
            ? {
                expandedRowRender,
                expandRowByClick: false,
                expandIcon,
                expandedRowKeys: expandedItemKey,
                onExpand: handleExpand,
              }
            : {
                expandedRowRender,
                expandedRowKeys: expandedItemKey,
                expandIcon: () => null,
              }
        }
        footer={footer}
      />
    );
  }
);

export default SubOrderList;
