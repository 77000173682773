import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  Checkbox,
  Divider,
  Icons,
  Row,
  Tooltip,
  Typography,
} from "@ster/ster-toolkit";
import { Affix, Col, Drawer } from "antd";
import { Gutter } from "antd/lib/grid/row";
import classNames from "classnames";
import { memo, useCallback, useMemo } from "react";
import TagManager from "react-gtm-module";
import { useNavigate } from "react-router-dom";

import {
  OrderListItem,
  OrderStatus,
  PortalSettings,
  PortalUser,
} from "../../api";
import { useAvailableMediumTypes } from "../../utils/hooks";
import CampaignCard from "../partials/Cards/Campaign/CampaignCard";
import {
  isCampaignAnalysisDisabled,
  isCampaignAnalysisVisible,
} from "../partials/Cards/CampaignDetail/CampaignAnalysisHooks";
import CampaignFilters from "./CampaignFilters";
import styles from "./CampaignList.module.less";
import { CampaignListProps } from "./models";

export const Campaigns = memo(
  ({
    campaigns,
    onDelete,
    selectCampaigns,
    onSelectCampaign,
    selectedCampaigns,
    settings,
    account,
  }: {
    campaigns: OrderListItem[];
    onDelete: (order: OrderListItem) => void;
    selectCampaigns: boolean;
    onSelectCampaign: (campaign: OrderListItem) => void;
    selectedCampaigns: OrderListItem[];
    settings: PortalSettings;
    account: PortalUser;
  }) => {
    const { i18n } = useLingui();
    const ordersSecondPartHandled: OrderListItem[] = [];
    return (
      <>
        {campaigns.map((campaign) => {
          const spreaded =
            campaign.spreadedFirstPart || campaign.spreadedSecondPart;
          if (ordersSecondPartHandled.includes(campaign)) {
            return false; // Sla de campagne over als deze al als maandoverschrijdend bij een andere campagne is getoond
          }
          let campaignSpreadedSecondPart;
          if (campaign.spreadedFirstPart) {
            campaignSpreadedSecondPart = campaigns.find(
              (s) => s.spreadedSecondPart && s.id === campaign.spreadedOrderId
            );
            if (campaignSpreadedSecondPart) {
              ordersSecondPartHandled.push(campaignSpreadedSecondPart);
            }
          }

          // Als dit het tweede deel is van een maandoverschrijdende campagne en er is ook een eerste deel, dan slaan we deze over
          if (
            campaign.spreadedSecondPart &&
            campaigns.find(
              (s) => s.spreadedFirstPart && s.id === campaign.spreadedOrderId
            )
          ) {
            return undefined;
          }

          // When both spreaded parts are available (e.g. to be rendered)
          // - First part displays campaign analysis button when the campaign analysis is isCampaignAnalysisVisible == true and isCampaignAnalysisDisabled == false
          // - Second part always displays the campaign analysis when isCampaignAnalysisVisible == true

          // Then use the results in your condition
          const displayCampaignAnalysisWhenAvailable =
            campaignSpreadedSecondPart
              ? isCampaignAnalysisVisible(
                  campaignSpreadedSecondPart,
                  settings
                ) && !isCampaignAnalysisDisabled(campaignSpreadedSecondPart)
              : true;

          let campaignGutter: [Gutter, Gutter] | undefined = [40, 40];
          if (campaignSpreadedSecondPart) {
            campaignGutter = [20, 20];
          } else if (spreaded) {
            campaignGutter = undefined;
          }

          return (
            <Row gutter={spreaded ? [40, 40] : undefined} key={campaign.id}>
              <Col span={24}>
                <article
                  className={classNames({
                    [styles.spreaded]: spreaded,
                    "card card--campaign card--spreaded": spreaded,
                  })}
                >
                  {spreaded && (
                    <>
                      <Typography.Title level={3}>
                        <Trans>Maandoverschrijdende campagne</Trans>{" "}
                        <Tooltip
                          title={i18n._(
                            t`Deze campagne bestaat uit 2 losse delen die initieel samen geboekt zijn`
                          )}
                          placement="bottom"
                        >
                          <span>
                            <Icons.QuestionIcon />
                          </span>
                        </Tooltip>
                      </Typography.Title>
                      {!campaignSpreadedSecondPart && (
                        <p>
                          <Trans>
                            Deze campagne is onderdeel van een
                            maandoverschrijdende order. Pas de filters aan om
                            ook het andere deel te tonen.
                          </Trans>
                        </p>
                      )}
                    </>
                  )}
                  <Row gutter={campaignGutter} key={campaign.id}>
                    <Col span={24}>
                      {selectCampaigns &&
                      campaign.status === OrderStatus.Concept ? (
                        <Checkbox
                          className={classNames("checkbox", styles.select)}
                          onChange={() => onSelectCampaign(campaign)}
                          checked={selectedCampaigns.some(
                            (s) => s.id === campaign.id
                          )}
                        >
                          <CampaignCard
                            cardData={campaign}
                            displayCampaignAnalysisWhenAvailable={
                              displayCampaignAnalysisWhenAvailable
                            }
                            onDelete={onDelete}
                            settings={settings}
                            account={account}
                          />
                        </Checkbox>
                      ) : (
                        <CampaignCard
                          cardData={campaign}
                          displayCampaignAnalysisWhenAvailable={
                            displayCampaignAnalysisWhenAvailable
                          }
                          onDelete={onDelete}
                          settings={settings}
                          account={account}
                        />
                      )}
                    </Col>
                  </Row>
                  {campaignSpreadedSecondPart && (
                    <Row>
                      <Col span={24}>
                        <CampaignCard
                          cardData={campaignSpreadedSecondPart}
                          displayCampaignAnalysisWhenAvailable
                          onDelete={onDelete}
                          settings={settings}
                          account={account}
                        />
                      </Col>
                    </Row>
                  )}
                </article>
              </Col>
            </Row>
          );
        })}
      </>
    );
  }
);

const CampaignList = memo(
  ({
    data,
    showFilters,
    onShowFilters,
    availableMediumTypes,
    onDelete,
    selectCampaigns,
    onSelectCampaign,
    filtersDisabled,
    selectedCampaigns,
    settings,
  }: CampaignListProps): React.ReactElement => {
    const navigate = useNavigate();
    const [availableMediumTypesRequest] = useAvailableMediumTypes(
      "aanvragen_formulier"
    );

    const newCampaign = useCallback(() => {
      navigate("/campaigns/new");
      TagManager.dataLayer({
        dataLayer: {
          event: "Concept aangemaakt",
        },
      });
    }, [navigate]);

    const showEditableFilter = useMemo(
      () =>
        availableMediumTypesRequest.length > 0 &&
        availableMediumTypesRequest.some(
          (s) => settings?.enableEditCampaign?.[s]
        ),
      [availableMediumTypesRequest, settings?.enableEditCampaign]
    );

    return (
      <Row>
        <Col xs={0} md={6}>
          <Row>
            <Col span={20}>
              <CampaignFilters
                data={data}
                availableMediumTypes={availableMediumTypes}
                filtersDisabled={filtersDisabled}
                showEditableFilter={showEditableFilter}
              />
            </Col>
            <Col span={1} />
            <Col xs={0} md={2}>
              <Divider type="vertical" className={styles.divider} />
            </Col>
            <Col span={1} />
          </Row>
        </Col>
        <Col xs={24} md={18}>
          <Campaigns
            campaigns={data.campaigns}
            onDelete={onDelete}
            selectCampaigns={selectCampaigns}
            onSelectCampaign={onSelectCampaign}
            selectedCampaigns={selectedCampaigns}
            settings={settings}
            account={data.account}
          />
          {data.campaigns.length === 0 && (
            <div className={styles.noData}>
              <Row gutter={[40, 40]} style={{ marginTop: 40 }}>
                <Typography.Title>
                  <Trans>Je hebt nog geen campagnes</Trans>
                </Typography.Title>
              </Row>
              <Row gutter={[40, 40]}>
                <Button
                  mode="tertiary"
                  className="center"
                  onClick={newCampaign}
                >
                  <Trans>Start een nieuwe campagne</Trans>
                </Button>
              </Row>
            </div>
          )}
        </Col>
        <Drawer
          placement="left"
          onClose={onShowFilters}
          open={showFilters}
          width="100%"
          className={styles.filterDrawer}
          title={
            <Typography.Title>
              <Trans>Filters</Trans>
            </Typography.Title>
          }
        >
          <CampaignFilters
            data={data}
            availableMediumTypes={availableMediumTypes}
            filtersDisabled={filtersDisabled}
            showEditableFilter={showEditableFilter}
          />
          {showFilters && (
            <Affix offsetBottom={0} className={styles.filterDrawerFooter}>
              <Button
                mode="primary"
                className={styles.filterDrawerButton}
                onClick={onShowFilters}
              >
                <Trans>Toepassen</Trans>
              </Button>
            </Affix>
          )}
        </Drawer>
      </Row>
    );
  }
);

export default CampaignList;
