/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  BreakSelection,
  BreakSelectionFromJSON,
  BreakSelectionFromJSONTyped,
  BreakSelectionToJSON,
  DateSpan,
  DateSpanFromJSON,
  DateSpanFromJSONTyped,
  DateSpanToJSON,
  DiscountProps,
  DiscountPropsFromJSON,
  DiscountPropsFromJSONTyped,
  DiscountPropsToJSON,
  OnlineInstructionPerPeriod,
  OnlineInstructionPerPeriodFromJSON,
  OnlineInstructionPerPeriodFromJSONTyped,
  OnlineInstructionPerPeriodToJSON,
  OrderStatus,
  OrderStatusFromJSON,
  OrderStatusFromJSONTyped,
  OrderStatusToJSON,
  PackageChoiceEnum,
  PackageChoiceEnumFromJSON,
  PackageChoiceEnumFromJSONTyped,
  PackageChoiceEnumToJSON,
  PackageRequest,
  PackageRequestFromJSON,
  PackageRequestFromJSONTyped,
  PackageRequestToJSON,
  TargetGroupRequest,
  TargetGroupRequestFromJSON,
  TargetGroupRequestFromJSONTyped,
  TargetGroupRequestToJSON,
  UpdateFields,
  UpdateFieldsFromJSON,
  UpdateFieldsFromJSONTyped,
  UpdateFieldsToJSON,
} from "./";

/**
 *
 * @export
 * @interface SubOrderRequest
 */
export interface SubOrderRequest {
  /**
   *
   * @type {number}
   * @memberof SubOrderRequest
   */
  id?: number | null;
  /**
   *
   * @type {DateSpan}
   * @memberof SubOrderRequest
   */
  period: DateSpan;
  /**
   *
   * @type {Array<Date>}
   * @memberof SubOrderRequest
   */
  excluded?: Array<Date> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof SubOrderRequest
   */
  excludedPeriodStrings?: Array<string> | null;
  /**
   *
   * @type {boolean}
   * @memberof SubOrderRequest
   */
  spread?: boolean;
  /**
   *
   * @type {PackageRequest}
   * @memberof SubOrderRequest
   */
  _package: PackageRequest;
  /**
   *
   * @type {Array<string>}
   * @memberof SubOrderRequest
   */
  channels?: Array<string> | null;
  /**
   *
   * @type {TargetGroupRequest}
   * @memberof SubOrderRequest
   */
  targetGroup?: TargetGroupRequest;
  /**
   *
   * @type {Array<number>}
   * @memberof SubOrderRequest
   */
  spotLength?: Array<number> | null;
  /**
   *
   * @type {number}
   * @memberof SubOrderRequest
   */
  budget: number;
  /**
   *
   * @type {number}
   * @memberof SubOrderRequest
   */
  grp?: number;
  /**
   *
   * @type {number}
   * @memberof SubOrderRequest
   */
  spotsPerDayChannel?: number;
  /**
   *
   * @type {number}
   * @memberof SubOrderRequest
   */
  spotsPerDay?: number;
  /**
   *
   * @type {number}
   * @memberof SubOrderRequest
   */
  spotsTotal?: number | null;
  /**
   *
   * @type {number}
   * @memberof SubOrderRequest
   */
  spotNo?: number;
  /**
   *
   * @type {number}
   * @memberof SubOrderRequest
   */
  impressions?: number;
  /**
   *
   * @type {number}
   * @memberof SubOrderRequest
   */
  cpmPrice?: number | null;
  /**
   *
   * @type {Array<string>}
   * @memberof SubOrderRequest
   */
  contextTargets?: Array<string> | null;
  /**
   *
   * @type {PackageChoiceEnum}
   * @memberof SubOrderRequest
   */
  packageChoice?: PackageChoiceEnum;
  /**
   *
   * @type {Array<BreakSelection>}
   * @memberof SubOrderRequest
   */
  breakSelection?: Array<BreakSelection> | null;
  /**
   *
   * @type {Array<number>}
   * @memberof SubOrderRequest
   */
  timeSelection?: Array<number> | null;
  /**
   *
   * @type {string}
   * @memberof SubOrderRequest
   */
  endTime?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof SubOrderRequest
   */
  site?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof SubOrderRequest
   */
  program?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof SubOrderRequest
   */
  deviceTargets?: Array<string> | null;
  /**
   *
   * @type {DiscountProps}
   * @memberof SubOrderRequest
   */
  discountProperties?: DiscountProps;
  /**
   *
   * @type {string}
   * @memberof SubOrderRequest
   */
  campaignName?: string | null;
  /**
   *
   * @type {OrderStatus}
   * @memberof SubOrderRequest
   */
  subOrderRequestStatus?: OrderStatus;
  /**
   *
   * @type {string}
   * @memberof SubOrderRequest
   */
  errorMessage?: string | null;
  /**
   *
   * @type {Array<number>}
   * @memberof SubOrderRequest
   */
  daysOfWeek?: Array<number> | null;
  /**
   *
   * @type {string}
   * @memberof SubOrderRequest
   */
  format?: string | null;
  /**
   *
   * @type {number}
   * @memberof SubOrderRequest
   */
  revenueType?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof SubOrderRequest
   */
  useTime?: boolean;
  /**
   *
   * @type {Array<OnlineInstructionPerPeriod>}
   * @memberof SubOrderRequest
   */
  onlineInstructionsPerPeriod?: Array<OnlineInstructionPerPeriod> | null;
  /**
   *
   * @type {Array<UpdateFields>}
   * @memberof SubOrderRequest
   */
  editableFields?: Array<UpdateFields> | null;
  /**
   *
   * @type {string}
   * @memberof SubOrderRequest
   */
  lineItemId?: string | null;
}

export function SubOrderRequestFromJSON(json: any): SubOrderRequest {
  return SubOrderRequestFromJSONTyped(json, false);
}

export function SubOrderRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SubOrderRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    period: DateSpanFromJSON(json["period"]),
    excluded: !exists(json, "excluded") ? undefined : json["excluded"],
    excludedPeriodStrings: !exists(json, "excludedPeriodStrings")
      ? undefined
      : json["excludedPeriodStrings"],
    spread: !exists(json, "spread") ? undefined : json["spread"],
    _package: PackageRequestFromJSON(json["package"]),
    channels: !exists(json, "channels") ? undefined : json["channels"],
    targetGroup: !exists(json, "targetGroup")
      ? undefined
      : TargetGroupRequestFromJSON(json["targetGroup"]),
    spotLength: !exists(json, "spotLength") ? undefined : json["spotLength"],
    budget: json["budget"],
    grp: !exists(json, "grp") ? undefined : json["grp"],
    spotsPerDayChannel: !exists(json, "spotsPerDayChannel")
      ? undefined
      : json["spotsPerDayChannel"],
    spotsPerDay: !exists(json, "spotsPerDay") ? undefined : json["spotsPerDay"],
    spotsTotal: !exists(json, "spotsTotal") ? undefined : json["spotsTotal"],
    spotNo: !exists(json, "spotNo") ? undefined : json["spotNo"],
    impressions: !exists(json, "impressions") ? undefined : json["impressions"],
    cpmPrice: !exists(json, "cpmPrice") ? undefined : json["cpmPrice"],
    contextTargets: !exists(json, "contextTargets")
      ? undefined
      : json["contextTargets"],
    packageChoice: !exists(json, "packageChoice")
      ? undefined
      : PackageChoiceEnumFromJSON(json["packageChoice"]),
    breakSelection: !exists(json, "breakSelection")
      ? undefined
      : json["breakSelection"] === null
        ? null
        : (json["breakSelection"] as Array<any>).map(BreakSelectionFromJSON),
    timeSelection: !exists(json, "timeSelection")
      ? undefined
      : json["timeSelection"],
    endTime: !exists(json, "endTime") ? undefined : json["endTime"],
    site: !exists(json, "site") ? undefined : json["site"],
    program: !exists(json, "program") ? undefined : json["program"],
    deviceTargets: !exists(json, "deviceTargets")
      ? undefined
      : json["deviceTargets"],
    discountProperties: !exists(json, "discountProperties")
      ? undefined
      : DiscountPropsFromJSON(json["discountProperties"]),
    campaignName: !exists(json, "campaignName")
      ? undefined
      : json["campaignName"],
    subOrderRequestStatus: !exists(json, "subOrderRequestStatus")
      ? undefined
      : OrderStatusFromJSON(json["subOrderRequestStatus"]),
    errorMessage: !exists(json, "errorMessage")
      ? undefined
      : json["errorMessage"],
    daysOfWeek: !exists(json, "daysOfWeek") ? undefined : json["daysOfWeek"],
    format: !exists(json, "format") ? undefined : json["format"],
    revenueType: !exists(json, "revenueType") ? undefined : json["revenueType"],
    useTime: !exists(json, "useTime") ? undefined : json["useTime"],
    onlineInstructionsPerPeriod: !exists(json, "onlineInstructionsPerPeriod")
      ? undefined
      : json["onlineInstructionsPerPeriod"] === null
        ? null
        : (json["onlineInstructionsPerPeriod"] as Array<any>).map(
            OnlineInstructionPerPeriodFromJSON
          ),
    editableFields: !exists(json, "editableFields")
      ? undefined
      : json["editableFields"] === null
        ? null
        : (json["editableFields"] as Array<any>).map(UpdateFieldsFromJSON),
    lineItemId: !exists(json, "lineItemId") ? undefined : json["lineItemId"],
  };
}

export function SubOrderRequestToJSON(value?: SubOrderRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    period: DateSpanToJSON(value.period),
    excluded: value.excluded,
    excludedPeriodStrings: value.excludedPeriodStrings,
    spread: value.spread,
    package: PackageRequestToJSON(value._package),
    channels: value.channels,
    targetGroup: TargetGroupRequestToJSON(value.targetGroup),
    spotLength: value.spotLength,
    budget: value.budget,
    grp: value.grp,
    spotsPerDayChannel: value.spotsPerDayChannel,
    spotsPerDay: value.spotsPerDay,
    spotsTotal: value.spotsTotal,
    spotNo: value.spotNo,
    impressions: value.impressions,
    cpmPrice: value.cpmPrice,
    contextTargets: value.contextTargets,
    packageChoice: PackageChoiceEnumToJSON(value.packageChoice),
    breakSelection:
      value.breakSelection === undefined
        ? undefined
        : value.breakSelection === null
          ? null
          : (value.breakSelection as Array<any>).map(BreakSelectionToJSON),
    timeSelection: value.timeSelection,
    endTime: value.endTime,
    site: value.site,
    program: value.program,
    deviceTargets: value.deviceTargets,
    discountProperties: DiscountPropsToJSON(value.discountProperties),
    campaignName: value.campaignName,
    subOrderRequestStatus: OrderStatusToJSON(value.subOrderRequestStatus),
    errorMessage: value.errorMessage,
    daysOfWeek: value.daysOfWeek,
    format: value.format,
    revenueType: value.revenueType,
    useTime: value.useTime,
    onlineInstructionsPerPeriod:
      value.onlineInstructionsPerPeriod === undefined
        ? undefined
        : value.onlineInstructionsPerPeriod === null
          ? null
          : (value.onlineInstructionsPerPeriod as Array<any>).map(
              OnlineInstructionPerPeriodToJSON
            ),
    editableFields:
      value.editableFields === undefined
        ? undefined
        : value.editableFields === null
          ? null
          : (value.editableFields as Array<any>).map(UpdateFieldsToJSON),
    lineItemId: value.lineItemId,
  };
}
