import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  ContentContainer,
  Icons,
  Pageheader,
  Spinner,
} from "@ster/ster-toolkit";
import { startOfMonth } from "date-fns";
import { memo, useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";

import { MediumEnum } from "../../api/models";
import { Period } from "../../shared/models";
import { receiveInvoicesAction } from "../../store/invoices/actions";
import { useAvailableMediumTypes } from "../../utils/hooks";
import InvoiceList from "./InvoiceList";
import { invoicesRootSelector } from "./selectors";

const InvoiceContainer = memo(() => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();

  // map state to props
  const {
    invoices,
    period: initialPeriod,
    loading,
  } = useSelector(invoicesRootSelector);

  // filteren op periode
  const [period, setPeriod] = useState<Period>(initialPeriod);
  const handlePeriodChange = useCallback(
    (dateFrom?: Date | null, dateTo?: Date | null) => {
      if (dateFrom && dateTo) {
        setPeriod({
          from: startOfMonth(dateFrom),
          to: startOfMonth(dateTo),
        });
      } else {
        setPeriod(initialPeriod);
      }
    },
    [initialPeriod]
  );

  const [availableMediumTypes, _] = useAvailableMediumTypes("facturen");

  // filteren op medium
  const [medium, setMedium] = useState<MediumEnum | "">("");

  const handleMediumChange = useCallback((value: MediumEnum) => {
    setMedium(value);
  }, []);

  // haal facturen op/vernieuwen
  useEffect((): void => {
    const { from, to } = period;
    if (from && to) {
      dispatch(receiveInvoicesAction.request({ from, to }));
    }
  }, [dispatch, period]);

  return (
    <>
      <Helmet>
        <title>{i18n._(t`Facturen`)}</title>
      </Helmet>

      <Pageheader
        title={i18n._(t`Facturen`)}
        icon={
          <Icons.InvoiceIcon
            width="100%"
            height="100%"
            fill="rgba(129, 176, 210, 0.2)"
          />
        }
      />
      <ContentContainer>
        <Spinner spinning={loading}>
          <InvoiceList
            invoices={invoices.filter(({ medium: m }) =>
              medium === "" ? true : m === medium
            )}
            period={period}
            onPeriodChange={handlePeriodChange}
            medium={medium}
            onMediumChange={handleMediumChange}
            availableMediumTypes={availableMediumTypes}
          />
        </Spinner>
      </ContentContainer>
    </>
  );
});

export default InvoiceContainer;
