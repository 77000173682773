/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ProposalInitialRequest,
  ProposalInitialRequestFromJSON,
  ProposalInitialRequestFromJSONTyped,
  ProposalInitialRequestToJSON,
  ProposalType,
  ProposalTypeFromJSON,
  ProposalTypeFromJSONTyped,
  ProposalTypeToJSON,
} from "./";

/**
 *
 * @export
 * @interface ProposalRequest
 */
export interface ProposalRequest {
  /**
   *
   * @type {Array<ProposalInitialRequest>}
   * @memberof ProposalRequest
   */
  initialRequests: Array<ProposalInitialRequest>;
  /**
   *
   * @type {ProposalType}
   * @memberof ProposalRequest
   */
  type?: ProposalType;
}

export function ProposalRequestFromJSON(json: any): ProposalRequest {
  return ProposalRequestFromJSONTyped(json, false);
}

export function ProposalRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProposalRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    initialRequests: (json["initialRequests"] as Array<any>).map(
      ProposalInitialRequestFromJSON
    ),
    type: !exists(json, "type")
      ? undefined
      : ProposalTypeFromJSON(json["type"]),
  };
}

export function ProposalRequestToJSON(value?: ProposalRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    initialRequests: (value.initialRequests as Array<any>).map(
      ProposalInitialRequestToJSON
    ),
    type: ProposalTypeToJSON(value.type),
  };
}
