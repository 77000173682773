import { Trans } from "@lingui/macro";
import { Icons } from "@ster/ster-toolkit";

export interface CampaignCopyButtonProps {
  onOpen: () => void;
}

const CampaignCopyButton = ({ onOpen }: CampaignCopyButtonProps) => (
  <button type="button" className="link context-menu__link" onClick={onOpen}>
    <Icons.CopyIcon fill="#000" />
    <Trans>Campagne kopiëren</Trans>
  </button>
);

export default CampaignCopyButton;
