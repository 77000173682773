import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Channel,
  ChannelNames,
  Divider,
  Icons,
  Modal,
  Table,
  formatNumber,
  formatToEuro,
} from "@ster/ster-toolkit";
import { ColumnsType } from "antd/lib/table";
import classNames from "classnames";
import moment from "moment";
import { memo } from "react";

import { AvailableBreak } from "../../../api";
import styles from "./BookSpotFooter.module.less";

const BookSpotDialog = memo(
  ({
    visible,
    onClose,
    onOk,
    addedItems,
    deletedItems,
    balance,
    grpBalance,
    isFinalize,
    loading,
  }: {
    visible: boolean;
    onClose: () => void;
    onOk: () => void;
    addedItems: AvailableBreak[];
    deletedItems: AvailableBreak[];
    balance: number;
    grpBalance: number;
    isFinalize: boolean;
    loading: boolean;
  }) => {
    const { i18n } = useLingui();
    const columns: ColumnsType<AvailableBreak> = [
      {
        title: "Blok",
        width: 90,
        key: "breakId",
        dataIndex: "breakId",
        sorter: (a, b): number => `${a.breakId}`.localeCompare(b.breakId),
      },
      {
        title: "Datum",
        key: "schedDate",
        render: (_, { schedDate }) => (
          <>{moment(schedDate).format("dd D MMM YYYY")}</>
        ),
      },
      {
        title: "Tijd",
        width: 90,
        key: "schedStartTime",
        render: (_, { schedStartTime }) => (
          <>{moment(schedStartTime).format("LT")}</>
        ),
      },
      {
        title: "Zender",
        width: 90,
        key: "channelDescr",
        render: (_, { channelDescr }) => (
          <Channel type={channelDescr as ChannelNames} />
        ),
      },
      {
        title: "Voor",
        width: 150,
        key: "programmeBefore",
        dataIndex: "programmeBefore",
        ellipsis: true,
      },
      {
        title: "Na",
        width: 150,
        key: "programmeAfter",
        dataIndex: "programmeAfter",
        ellipsis: true,
      },
      {
        title: "Netto prijs",
        key: "nettSpotPrice",
        align: "right",
        render: (_, { nettSpotPrice }) => <>{formatToEuro(nettSpotPrice)}</>,
      },
      {
        title: "Prognose",
        key: "predictedRating",
        dataIndex: "predictedRating",
        align: "right",
        render: (_, { predictedRating }) =>
          predictedRating ? formatNumber(predictedRating, 2) : "-",
      },
    ];

    return (
      <Modal
        open={visible}
        onCancel={onClose}
        onOk={onOk}
        cancelText={<Trans>Annuleren</Trans>}
        okText={isFinalize ? <Trans>Bevestigen</Trans> : <Trans>Sluiten</Trans>}
        confirmLoading={loading}
        title={i18n._(t`Spots bij-/afboeken`)}
        width="1000px"
        className={styles.dialog}
      >
        {deletedItems.length > 0 && (
          <>
            <div className={styles.deleted}>
              <Icons.MinusIcon /> <Trans>Spot(s) die worden verwijderd</Trans>
            </div>
            <Table
              pagination={false}
              columns={columns}
              dataSource={deletedItems}
              className={styles.deletedTable}
              rowKey="uniqueId"
            />
          </>
        )}
        {addedItems.length > 0 && (
          <>
            <div className={styles.added}>
              <Icons.PlusIcon /> <Trans>Spot(s) die worden toegevoegd</Trans>
            </div>
            <Table
              pagination={false}
              columns={columns}
              dataSource={addedItems}
              rowKey="uniqueId"
            />
          </>
        )}
        <Divider />
        <div className={styles.balance}>
          {balance > 0 ? <Trans>Overscore</Trans> : <Trans>Onderscore</Trans>}:{" "}
          <span
            className={classNames(
              {
                [styles.overscore]: balance > 0,
              },
              {
                [styles.underscore]: balance <= 0,
              }
            )}
          >
            {formatToEuro(balance, false)} ({formatNumber(grpBalance ?? 0, 2)}{" "}
            GRP&apos;s)
          </span>
        </div>
      </Modal>
    );
  }
);

export default BookSpotDialog;
