import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Alert, App as AntApp, Space } from "antd";
import { isSameDay } from "date-fns";
import moment from "moment";
import { memo, useCallback, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";

import {
  CommercialStatus,
  CommercialsPerAdvertiser,
  OrderDetail,
  OrderStatus,
  SubOrderDetail,
} from "../../../api";
import { ReduxStoreState } from "../../../store/base";
import {
  clearSaveCampaignInstructionsOnlineAction,
  saveCampaignInstructionsOnlineAction,
} from "../../../store/campaignDetail/actions";
import { receiveCampaignInstructionsOnlineAction } from "../../../store/campaignEdit/actions";
import { receiveCommercialsForProductIdAction } from "../../../store/commercials/actions";
import { StoreModel } from "../../../store/models";
import { InstructionConstants } from "../../../utils";
import { minCommercialDate } from "../../../utils/constants";
import { getDaysInsidePeriod } from "../../../utils/dateHelper";
import AddOnlineInstructions from "../../partials/OnlineInstructions/AddOnlineInstructions";
import OnlineInstructions from "../../partials/OnlineInstructions/OnlineInstructions";
import {
  OnlineInstructionsPerSubOrder,
  SimpleOnlineSubOrder,
} from "../../partials/OnlineInstructions/types";
import { campaignOnlineInstructionsRootSelector } from "../selectors";

const CampaignOnlineInstructions = memo(
  ({ campaign }: { campaign: OrderDetail }) => {
    const { i18n } = useLingui();
    const dispatch = useDispatch();
    const { message, notification } = AntApp.useApp();

    /**
     * Construct the maximum end date for an instruction
     */
    const maxDate = useMemo(() => {
      const max = Math.max(
        ...(campaign.subOrders
          ?.filter((s) => moment(s.period.to).isAfter(InstructionConstants.now))
          .map((s: SubOrderDetail) => s.period.to.getTime()) ??
          InstructionConstants.emptyTicks)
      );

      // Math.max(...[]) => Infinity
      return Math.abs(max) !== Infinity ? new Date(max) : campaign.orderEndDate;
    }, [campaign.orderEndDate, campaign.subOrders]);

    /**
     * Construct the minimum start date for an instruction
     */
    const minDateNewInstruction = useMemo(() => {
      const min = Math.min(
        ...(campaign.subOrders
          ?.filter((s) =>
            moment(s.period.to).isAfter(InstructionConstants.firstMomentOnline)
          )
          .map((s: SubOrderDetail) =>
            moment(s.period.from).isAfter(
              InstructionConstants.firstMomentOnline
            )
              ? s.period.from.getTime()
              : InstructionConstants.now.getTime()
          ) ?? InstructionConstants.emptyTicks)
      );

      // Math.min(...[]) => Infinity
      return Math.abs(min) !== Infinity
        ? new Date(min)
        : campaign.orderStartDate;
    }, [campaign.orderStartDate, campaign.subOrders]);

    const refresh = useCallback(() => {
      dispatch(
        receiveCampaignInstructionsOnlineAction.request({
          orderId: campaign.id,
        })
      );
    }, [campaign.id, dispatch]);

    useEffect(() => {
      refresh();
      dispatch(
        receiveCommercialsForProductIdAction.request({
          medium: campaign.medium,
          productId: campaign.productId,
          from: minCommercialDate,
          to: campaign.orderEndDate,
        })
      );
    }, [
      campaign.medium,
      campaign.orderEndDate,
      campaign.productId,
      dispatch,
      refresh,
    ]);

    const {
      loading,
      instructions,
      commercialsPerAdvertiser,
      saveInstructions,
    } = useSelector((state: StoreModel) =>
      campaignOnlineInstructionsRootSelector(state)
    );

    const activeCommercialAndBanners = useMemo(
      () =>
        ({
          commercials: commercialsPerAdvertiser?.commercials.filter(
            (s) => s.status === CommercialStatus.Active
          ),
          banners: commercialsPerAdvertiser?.banners.filter(
            (s) => s.status === CommercialStatus.Active
          ),
          ...commercialsPerAdvertiser,
        }) as CommercialsPerAdvertiser | undefined,
      [commercialsPerAdvertiser]
    );

    const title = campaign
      ? `${campaign?.campaignName || campaign?.productDescr} (${
          campaign?.medium
        })`
      : "...";

    const subOrders = useMemo(
      () =>
        campaign.subOrders
          ?.filter(({ id, period, spotLength }) => id && period && spotLength)
          .map(
            ({
              id,
              period,
              spotLength,
              campaignName,
              _package,
              status,
              packageChoice,
            }) =>
              ({
                id,
                campaignName,
                dateRange: { startDate: period.from, endDate: period.to },
                spotLength,
                packageName: _package.name,
                packageChoice,
                status,
                onlineInstructionsPerPeriod: instructions.find(
                  (s) => s.subOrderId === id
                )?.instructionsPerPeriod,
              }) as SimpleOnlineSubOrder
          ) ?? [],
      [campaign.subOrders, instructions]
    );

    // Bepaal of er periodes of deelorders zonder instructies zijn
    const missingInstructions = useMemo(() => {
      if (instructions) {
        return subOrders.some((s) => {
          if (!s.onlineInstructionsPerPeriod) {
            return true;
          }

          const daysInPeriod = getDaysInsidePeriod(
            s.dateRange.startDate < minDateNewInstruction
              ? minDateNewInstruction
              : s.dateRange.startDate,
            s.dateRange.endDate
          );

          // Bepaal of de instructies de gehele looptijd van de deelorder beslaan
          const daysInInstructions = s.onlineInstructionsPerPeriod.flatMap(
            (p) =>
              getDaysInsidePeriod(
                p.startDate ?? minDateNewInstruction,
                p.endDate ?? maxDate
              )
          );

          return (
            daysInPeriod.filter(
              (p) => !daysInInstructions.some((d) => d && isSameDay(d, p))
            ).length > 0
          );
        });
      }
      return true;
    }, [instructions, maxDate, minDateNewInstruction, subOrders]);

    /* Gebaseerd op de status, dialoog sluiten of niet? */
    useEffect(() => {
      switch (saveInstructions?.state) {
        case ReduxStoreState.Success:
          notification.success({
            message: i18n._(t`Instructie aangepast`),
            description: i18n._(
              t`Je wijziging wordt doorgevoerd. Bij een lopende campagne kan dit tot 30 minuten duren.`
            ),
            duration: 20,
          });
          refresh();
          dispatch(clearSaveCampaignInstructionsOnlineAction()); // reset
          break;
        case ReduxStoreState.Failure:
          message.error(
            i18n._(t`Er ging iets mis bij het opslaan van je instructie.`)
          );
          dispatch(clearSaveCampaignInstructionsOnlineAction()); // reset
          break;
        default:
          break;
      }
    }, [
      dispatch,
      i18n,
      message,
      notification,
      refresh,
      saveInstructions?.state,
    ]);

    const handleDeleteInstruction = useCallback(
      (instructionId: number) => {
        dispatch(
          saveCampaignInstructionsOnlineAction.request({
            onlineInstructionPerSubOrder: subOrders
              .filter((s) => s.id !== instructionId)
              .map((s) => ({
                subOrderId: s.id,
                instructionsPerPeriod: s.onlineInstructionsPerPeriod,
              })),
            orderId: campaign.id,
          })
        );
      },
      [campaign.id, dispatch, subOrders]
    );

    const handleSavePartialInstructions = useCallback(
      (newInstructions: OnlineInstructionsPerSubOrder[]) => {
        const mergedInstructions = [
          ...subOrders
            .filter((s) => !newInstructions.some((i) => i.subOrderId === s.id))
            .map((s) => ({
              subOrderId: s.id,
              instructionsPerPeriod: s.onlineInstructionsPerPeriod,
            })),
          ...newInstructions,
        ];

        dispatch(
          saveCampaignInstructionsOnlineAction.request({
            onlineInstructionPerSubOrder: mergedInstructions,
            orderId: campaign.id,
          })
        );
      },
      [campaign.id, dispatch, subOrders]
    );

    const handleSaveInstructions = useCallback(
      (newInstructions: OnlineInstructionsPerSubOrder[]) => {
        dispatch(
          saveCampaignInstructionsOnlineAction.request({
            onlineInstructionPerSubOrder: newInstructions,
            orderId: campaign.id,
          })
        );
      },
      [campaign.id, dispatch]
    );

    return (
      <>
        <Helmet>
          <title>{i18n._(t`Uitzendinstructies: ${title}`)}</title>
        </Helmet>

        <AddOnlineInstructions
          enabled={
            !loading &&
            [OrderStatus.Planned, OrderStatus.Active].includes(campaign.status)
          }
          commercialsPerAdvertiser={activeCommercialAndBanners}
          maxDate={maxDate}
          subOrders={subOrders}
          onSave={handleSaveInstructions}
          newInstructionText={
            subOrders.some((s) => s.onlineInstructionsPerPeriod?.length === 0)
              ? i18n._(t`Instructie toevoegen`)
              : i18n._(t`Instructie bewerken`)
          }
        />

        <Space size="large" direction="vertical" style={{ width: "100%" }}>
          {missingInstructions &&
            !loading &&
            campaign.status !== OrderStatus.Ready && (
              <Alert
                showIcon
                type="warning"
                message=""
                description={
                  <Trans>
                    Let op: op dit moment leveren niet al je campagnes uit. Er
                    is niet voor alle periodes materiaal gekoppeld. Via de knop
                    &quot;Instructie bewerken&quot; kun je dit aanpassen.
                  </Trans>
                }
              />
            )}

          <OnlineInstructions
            loading={loading}
            commercialsPerAdvertiser={activeCommercialAndBanners}
            subOrders={subOrders}
            productId={campaign.productId}
            maxDate={maxDate}
            onSave={handleSavePartialInstructions}
            onDelete={handleDeleteInstruction}
            isEditCampaign
          />
        </Space>
      </>
    );
  }
);

export default CampaignOnlineInstructions;
