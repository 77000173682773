/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum MediaFileType {
  Audio = "Audio",
  Video = "Video",
}

export function MediaFileTypeFromJSON(json: any): MediaFileType {
  return MediaFileTypeFromJSONTyped(json, false);
}

export function MediaFileTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MediaFileType {
  return json as MediaFileType;
}

export function MediaFileTypeToJSON(value?: MediaFileType | null): any {
  return value as any;
}
