import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  Container,
  ContentContainer,
  Form,
  Icons,
  Input,
  Option,
  Row,
  Select,
  Spinner,
} from "@ster/ster-toolkit";
import {
  Alert,
  App as AntApp,
  Form as AntForm,
  Col,
  Divider,
  Radio,
  Upload,
} from "antd";
import { SelectValue } from "antd/lib/select";
import { RcFile, UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import Cookies from "js-cookie";
import moment from "moment";
// eslint-disable-next-line import/no-extraneous-dependencies
import { FieldData, InternalNamePath } from "rc-field-form/lib/interface";
import { useCallback, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Advertiser, MediumEnum } from "../../api";
import { ReduxStoreState } from "../../store/base";
import { receiveProductsAction } from "../../store/campaignCreate/actions";
import {
  uploadCommercialAction,
  uploadCommercialClearAction,
} from "../../store/commercials/actions";
import { receiveOrganisationsAction } from "../../store/organisations/actions";
import {
  UniqueStrings,
  defaultCookieOptions,
  getOrganisationCode,
} from "../../utils";
import { organisationCookieName } from "../../utils/constants";
import { isInternalUser } from "../../utils/userHelper";
import { formItemLayout } from "../partials/Form";
import { validateLandingsPage } from "../partials/OnlineInstructions/utils";
import CommercialRequirements from "./CommercialRequirements";
import styles from "./CommercialUpload.module.less";
import { uploadCommercialSelector } from "./selectors";

const start = moment().startOf("month");
const end = moment().endOf("month");

const emptyAdvertisers: Advertiser[] = [];
const maxFileSize = 104857600;
const maxFileSizeMbs = maxFileSize / 1024 / 1024;
const maxFileSizeBanner = 1048576;
const maxFileSizeBannerMbs = maxFileSizeBanner / 1024 / 1024;

export type CommercialUploadType = "Video" | "DisplayImage" | "DisplayZip";

const CommercialUpload = () => {
  const { medium } = useParams();
  const { i18n } = useLingui();
  const [form] = AntForm.useForm();
  const dispatch = useDispatch();
  const { notification } = AntApp.useApp();

  const {
    loading,
    uploadState,
    products: { products: productsByAdvertiser = emptyAdvertisers, state },
    uploadError,
    account,
    organisations,
  } = useSelector(uploadCommercialSelector);

  useEffect(() => {
    if (state !== ReduxStoreState.Initial) {
      return;
    }

    dispatch(receiveOrganisationsAction.request());

    dispatch(
      receiveProductsAction.request({
        medium: medium as MediumEnum,
        from: start.toDate(),
        to: end.toDate(),
      })
    );
  }, [dispatch, medium, state]);

  const getFile = useCallback((e: UploadChangeParam) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  }, []);

  const handleUpload = useCallback(
    (_file: RcFile) => false, // TODO
    []
  );

  const handleOnFieldsChange = useCallback(
    (changedFields: FieldData[]) => {
      const uploadTypeChanged = changedFields.some(
        (f: FieldData) => (f.name as InternalNamePath)[0] === "uploadType"
      );

      if (uploadTypeChanged) {
        form.resetFields(["file"]);
      }

      // Verwijder eventuele oude errors
      dispatch(uploadCommercialClearAction());
    },
    [dispatch, form]
  );

  const handleOnFinish = useCallback(
    (values: {
      commercialTitle: string;
      uploadType: CommercialUploadType;
      file: UploadFile[];
      advertiserId: number;
      advertiserName: string;
      clickUrl: string;
    }) => {
      dispatch(
        uploadCommercialAction.request({
          commercialTitle: values.commercialTitle,
          uploadType: values.uploadType,
          medium: medium as MediumEnum,
          files: values.file.map((f) => f.originFileObj as Blob),
          advertiserId: values.advertiserId,
          advertiserName: values.advertiserName,
          clickUrl: values.clickUrl,
        })
      );
    },
    [dispatch, medium]
  );

  useEffect(() => {
    if (uploadState === ReduxStoreState.Success) {
      notification.success({
        message: i18n._(t`Uploaden voltooid`),
        description: i18n._(
          t`Het uploaden is succesvol voltooid. De commercial wordt nu verwerkt en beoordeeld. Na het beoordelen ontvang je een e-mail en kan de commercial ingezet worden.`
        ),
        duration: 20,
      });
      form.resetFields();
    }
  }, [form, i18n, notification, uploadState]);

  const validateExtension = useCallback(
    (fileValues: RcFile[], uploadType: string) => {
      let extensions: string[] = [];
      switch (uploadType) {
        case "Video":
          extensions = [".mp4"];
          break;
        case "DisplayImage":
          extensions = [".jpg", ".png", ".gif"];
          break;

        case "DisplayZip":
        default:
          extensions = [".zip"];
          break;
      }

      if (
        fileValues &&
        !fileValues.every((f) =>
          extensions.some((ext) => f.name.toLowerCase().endsWith(ext))
        )
      ) {
        return Promise.reject(
          i18n._(
            t`Alleen bestanden toevoegen met deze extensie: ${extensions.join(
              ", "
            )}`
          )
        );
      }

      return Promise.resolve();
    },
    [i18n]
  );

  const validateFileSize = useCallback(
    (fileValues: RcFile[], uploadType: string) => {
      if (!fileValues || fileValues.length === 0) {
        return Promise.reject(i18n._(t`Geen bestand aanwezig.`));
      }

      for (let i = 0; i < (fileValues?.length ?? 0); i += 1) {
        const file = fileValues[i];

        switch (uploadType) {
          case "Video":
            if (file.size >= maxFileSize) {
              // Larger then 100MB
              return Promise.reject(
                i18n._(
                  t`Bestand is groter dan het maximum van ${maxFileSizeMbs} MB.`
                )
              );
            }
            break;
          case "DisplayImage":
          case "DisplayZip":
          default:
            if (file.size >= maxFileSizeBanner) {
              // Controle op grootst toegestane grootte, fijnmazigere check in API
              return Promise.reject(
                i18n._(
                  t`Bestand is groter dan het maximum van ${maxFileSizeBannerMbs} MB.`
                )
              );
            }
            break;
        }
      }

      return Promise.resolve();
    },
    [i18n]
  );

  const handleSelectAdvertiser = useCallback(
    (value: SelectValue) => {
      const advertiserId = value as number;
      const advertiserName = productsByAdvertiser.find(
        (a) => a.id === advertiserId
      )?.name;
      form.setFieldsValue({ advertiserName });
    },
    [form, productsByAdvertiser]
  );

  useEffect(() => {
    const advertiserId =
      productsByAdvertiser.length === 1
        ? productsByAdvertiser[0].id
        : undefined;
    form.setFieldsValue({ advertiserId });
    handleSelectAdvertiser(advertiserId);
  }, [form, productsByAdvertiser, handleSelectAdvertiser]);

  const getErrorText = useCallback((error: string) => {
    switch (error) {
      case "IncorrectHtmlFile":
        return t`Het HTML-bestand dient in de root van het ZIP-bestand te staan. Controleer de ZIP en probeer het opnieuw.`;
      case "IncorrectBannerSize":
        return t`Je upload voldoet niet aan de vastgestelde formaten. Controleer je bestanden en probeer het opnieuw.`;
      case "IncorrectFileSize":
        return t`Je upload voldoet niet aan de vastgestelde maximale grootte. Controleer je bestanden en probeer het opnieuw.`;
      default:
    }
    return undefined;
  }, []);

  useEffect(() => {
    if (!uploadError?.validationResult) {
      return;
    }

    // Zet de status van de mislukte bestanden op error en voeg een errormelding toe
    form.setFieldValue(
      "file",
      form
        .getFieldValue("file")
        ?.map((f: UploadFile<unknown>[], index: number) => {
          const result = uploadError.validationResult?.[index];
          const errorText = getErrorText(result?.[0] ?? "");
          return {
            status: result ? "error" : "",
            response: errorText ? i18n._(errorText) : undefined,
            ...f,
          };
        })
    );
  }, [uploadError, form, getErrorText, i18n]);

  const filesWithError = useCallback(
    (error: string) => {
      if (!uploadError) {
        return undefined;
      }

      const errorKeys = Object.keys(uploadError.validationResult ?? {});
      const files: UploadFile<unknown>[] = form.getFieldValue("file");
      return errorKeys
        .filter((s) => uploadError.validationResult?.[Number(s)][0] === error)
        .map((s) => files?.[Number(s)]?.name);
    },
    [uploadError, form]
  );

  const validateError = useMemo(() => {
    if (!uploadError) {
      return undefined;
    }

    const errors = UniqueStrings(
      Object.values(uploadError.validationResult ?? {}).flatMap((s) => s?.[0])
    );

    if (!errors) {
      return `Er is een fout opgetreden bij het uploaden.`;
    }

    // Combineer de foutmelding en de betreffende bestanden in een foutmelding
    return errors
      .map((s) => {
        const files = filesWithError(s);
        const errorText = getErrorText(s);
        const errorTexTranslated = errorText ? i18n._(errorText) : undefined;
        return files
          ? `${errorTexTranslated} \r\n${i18n._("Bestand(en):")} ${files.join(", ")}`
          : errorTexTranslated;
      })
      .join("\r\n");
  }, [uploadError, getErrorText, filesWithError, i18n]);

  const onChangeOrganisation = useCallback(
    (value: SelectValue) => {
      // Wijzig de organisatie in de cookie en haal de producten opnieuw op
      Cookies.set(
        organisationCookieName,
        value?.toString() ?? "",
        defaultCookieOptions
      );
      dispatch(
        receiveProductsAction.request({
          medium: medium as MediumEnum,
          from: start.toDate(),
          to: end.toDate(),
        })
      );
    },
    [dispatch, medium]
  );

  return (
    <ContentContainer>
      <Helmet>
        <title>{i18n._(t`Materiaal uploaden`)}</title>
      </Helmet>

      <Container className={styles.container}>
        <Spinner spinning={loading}>
          <CommercialRequirements />
          <Divider />
          <Row>
            <Col xl={18} lg={18} md={24}>
              <Form
                name="uploader"
                form={form}
                initialValues={{ uploadType: "Video" }}
                onFinish={handleOnFinish}
                onFieldsChange={handleOnFieldsChange}
                {...formItemLayout}
              >
                <Form.Item label={i18n._(t`Type`)} name="uploadType">
                  <Radio.Group>
                    <Radio value="Video">
                      <Trans>Online video (MP4)</Trans>
                    </Radio>
                    <Radio value="DisplayImage">
                      <Trans>Online banner (JPG, PNG of GIF)</Trans>
                    </Radio>
                    <Radio value="DisplayZip">
                      <Trans>HTML5 banner (ZIP)</Trans>
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label={i18n._(t`Commercial`)} shouldUpdate>
                  {({ getFieldValue }) => {
                    const value = getFieldValue("file");
                    const uploadType = getFieldValue("uploadType");
                    return (
                      <Form.Item
                        name="file"
                        dependencies={["uploadType"]}
                        rules={[
                          {
                            required: true,
                            message: i18n._(t`Kies een bestand`),
                          },
                          {
                            validator: (_, fileValues: RcFile[]) =>
                              validateExtension(
                                fileValues,
                                getFieldValue("uploadType")
                              ),
                          },
                          {
                            validator: (_, fileValues: RcFile[]) =>
                              validateFileSize(
                                fileValues,
                                getFieldValue("uploadType")
                              ),
                          },
                        ]}
                        valuePropName="fileList"
                        getValueFromEvent={getFile}
                        noStyle
                      >
                        <Upload
                          accept={
                            // eslint-disable-next-line no-nested-ternary
                            uploadType === "Video"
                              ? ".mp4"
                              : uploadType === "DisplayImage"
                                ? ".jpg,.png,.gif"
                                : ".zip"
                          }
                          multiple={false}
                          beforeUpload={handleUpload}
                          maxCount={uploadType === "Video" ? 1 : undefined}
                          showUploadList={uploadType !== "Video"}
                        >
                          <Button mode="tertiary" icon={<Icons.UploadIcon />}>
                            {/* eslint-disable-next-line no-nested-ternary */}
                            {uploadType === "Video" && value && value[0] ? (
                              value[0].name
                            ) : value?.length > 0 ? (
                              <Trans>Voeg bestand toe...</Trans>
                            ) : (
                              <Trans>Upload bestand...</Trans>
                            )}
                          </Button>
                        </Upload>
                      </Form.Item>
                    );
                  }}
                </Form.Item>

                <Form.Item
                  label={i18n._(t`Titel`)}
                  name="commercialTitle"
                  rules={[
                    {
                      required: true,
                      message: i18n._(t`Voer een titel voor de commercial in`),
                    },
                  ]}
                >
                  <Input placeholder={i18n._(t`Titel van de commercial`)} />
                </Form.Item>

                <Form.Item dependencies={["uploadType"]} noStyle>
                  {() => (
                    <Form.Item
                      label={i18n._(t`Landingspagina`)}
                      name="clickUrl"
                      rules={[
                        {
                          required: true,
                          message: i18n._(
                            t`Landingspagina is verplicht en dient te beginnen met https://`
                          ),
                        },
                        {
                          validator: (_, value): Promise<void> =>
                            !value || validateLandingsPage(value)
                              ? Promise.resolve()
                              : Promise.reject(
                                  i18n._(
                                    t`Landingspagina is verplicht en dient te beginnen met https://`
                                  )
                                ),
                        },
                      ]}
                    >
                      <Input
                        placeholder={i18n._(t`URL van de landingspagina`)}
                      />
                    </Form.Item>
                  )}
                </Form.Item>

                {isInternalUser(account) && (
                  <Form.Item
                    label={i18n._(t`Organisatie`)}
                    name="organisation"
                    className={styles.labelLeft}
                    rules={[
                      {
                        required: true,
                        message: i18n._(t`Kies een organisatie`),
                      },
                    ]}
                    initialValue={getOrganisationCode(account)}
                  >
                    <Select.Search
                      placeholder={i18n._(t`Kies een organisatie`)}
                      optionFilterProp="children"
                      onChange={onChangeOrganisation}
                      disabled={loading}
                    >
                      {organisations.map((Organisation) => (
                        <Option
                          value={`${Organisation.code}`}
                          key={`${Organisation.code}`}
                        >
                          {Organisation.name}
                        </Option>
                      ))}
                    </Select.Search>
                  </Form.Item>
                )}

                <Form.Item
                  name="advertiserName"
                  rules={[
                    {
                      required: true,
                      message: i18n._(t`Kies een adverteerder`),
                    },
                  ]}
                  hidden
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label={i18n._(t`Adverteerder`)}
                  name="advertiserId"
                  rules={[
                    {
                      required: true,
                      message: i18n._(t`Kies een adverteerder`),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={i18n._(t`Zoek een adverteerder`)}
                    optionFilterProp="children"
                    onChange={handleSelectAdvertiser}
                  >
                    {productsByAdvertiser.map(({ id, name }) => (
                      <Option value={id} key={id}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                {uploadState === ReduxStoreState.Failure &&
                  validateError !== undefined && (
                    <Alert
                      showIcon
                      className={styles.alert}
                      type="error"
                      message=""
                      description={validateError}
                    />
                  )}

                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    mode="primary"
                    type="primary"
                    htmlType="submit"
                    disabled={loading}
                  >
                    <Trans>Uploaden</Trans>
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Spinner>
      </Container>
    </ContentContainer>
  );
};

export default CommercialUpload;
