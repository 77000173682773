import { AnyAction } from "redux";
import { Reducer, createReducer } from "typesafe-actions";

import { ReduxStoreState } from "../base";
import { LoadingBookSpot, LoadingBreaks } from "../models";
import {
  bookSpotAction,
  clearBookSpotAction,
  clearBreaksAction,
  receiveBreaksAction,
} from "./actions";

export const breaksReducer: Reducer<
  Record<number, LoadingBreaks>,
  AnyAction
> = createReducer({
  state: ReduxStoreState.Initial,
})
  .handleAction(
    receiveBreaksAction.request,
    (
      state: LoadingBreaks,
      action: ReturnType<typeof receiveBreaksAction.request>
    ) => ({
      ...state,
      [action.payload.key]: {
        loading: true,
        state: ReduxStoreState.Loading,
      },
    })
  )
  .handleAction(
    receiveBreaksAction.failure,
    (
      state: LoadingBreaks,
      action: ReturnType<typeof receiveBreaksAction.failure>
    ) => ({
      ...state,
      [action.payload.key]: {
        loading: false,
        state: ReduxStoreState.Failure,
      },
    })
  )
  .handleAction(
    receiveBreaksAction.success,
    (
      state: Record<string, LoadingBreaks>,
      action: ReturnType<typeof receiveBreaksAction.success>
    ) => ({
      ...state,
      [action.payload.key]: {
        breaks: action.payload.breaks || [],
        loading: false,
        state: ReduxStoreState.Success,
      },
    })
  )
  .handleAction(clearBreaksAction, () => ({
    breaks: [],
    loading: false,
    state: ReduxStoreState.Initial,
  }));

export const bookSpotReducer: Reducer<LoadingBookSpot, AnyAction> =
  createReducer({
    state: ReduxStoreState.Initial,
  })
    .handleAction(bookSpotAction.request, () => ({
      success: undefined,
      loading: true,
      state: ReduxStoreState.Loading,
    }))
    .handleAction(bookSpotAction.failure, () => ({
      success: false,
      loading: false,
      state: ReduxStoreState.Failure,
    }))
    .handleAction(bookSpotAction.success, () => ({
      success: true,
      loading: false,
      state: ReduxStoreState.Success,
    }))
    .handleAction(clearBookSpotAction, () => ({
      success: undefined,
      loading: false,
      state: ReduxStoreState.Initial,
    }));
