/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum CommercialStatus {
  Active = "Active",
  Inactive = "Inactive",
  Offline = "Offline",
  Nieuw = "Nieuw",
}

export function CommercialStatusFromJSON(json: any): CommercialStatus {
  return CommercialStatusFromJSONTyped(json, false);
}

export function CommercialStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CommercialStatus {
  return json as CommercialStatus;
}

export function CommercialStatusToJSON(value?: CommercialStatus | null): any {
  return value as any;
}
