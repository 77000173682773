import { SagaIterator } from "redux-saga";
import { SagaReturnType, call, put } from "redux-saga/effects";

import { ProposalApi } from "../../api/apis";
import apiConfig from "../../apiConfig";
import { toError } from "../utils";
import { receiveProposalDataAction } from "./actions";

// eslint-disable-next-line import/prefer-default-export
export function* receiveProposalData(
  action: ReturnType<typeof receiveProposalDataAction.request>
): SagaIterator {
  try {
    const proposalApi = new ProposalApi(apiConfig());
    const response: SagaReturnType<
      typeof proposalApi.apiV1ProposalGetProposalDataPost
    > = yield call(() =>
      proposalApi.apiV1ProposalGetProposalDataPost(action.payload)
    );
    yield put(receiveProposalDataAction.success(response));
  } catch (err) {
    yield put(receiveProposalDataAction.failure(toError(err)));
  }
}
