import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form } from "@ster/ster-toolkit";
import { Alert } from "antd";
import classNames from "classnames";
import { memo, useMemo } from "react";

import {
  GroupedPackageRadio,
  GroupedPackageTv,
  MediumEnum,
} from "../../../api";
import { getFormattedDate } from "../../../utils/dateHelper";
import {
  FormEditableItem,
  FormReadOnlyItem,
  formItemLayout,
} from "../../partials/Form";
import { GrpSubOrderFormProps } from "./models";
import DaySelection from "./partials/DaySelection";
import ExcludeDays from "./partials/ExcludeDays";
import PackageOptionsRadio from "./partials/PackageOptionsRadio";
import PackageOptionsTv from "./partials/PackageOptionsTv";
import PackageSelectGrouped from "./partials/PackageSelectGrouped";
import Period from "./partials/Period";
import SpotLength from "./partials/SpotLength";
import TargetGroupSelect from "./partials/TargetGroupSelect";
import TimeSelection from "./partials/TimeSelection";
import styles from "./SubOrder.module.less";

/**
 * Toevoegen/wijzigen van een pakket van het type GRP
 */
const GrpSubOrderForm = memo(
  ({
    handleChange,
    handleFinishFailed,
    subOrder,
    period,
    medium,
    selectedSalesPeriod,
    subOrders,
    form,
    packages,
    spotIndexes,
    groupedPackagesRadio,
    groupedPackagesTv,
    showPackageOptions,
    groupedPackage,
    packagePropertiesRadioWithoutSalesLock,
    packagePropertiesTvWithoutSalesLock,
    selectedPackage,
    enabledDaysOfWeek,
    canUpdatePeriod,
    canUpdateSpotLength,
    canUpdatePackage,
    showBlockEditMessage,
  }: GrpSubOrderFormProps) => {
    const { i18n } = useLingui();

    const periodReadOnlyValue = useMemo(
      () => `${getFormattedDate(subOrder.period?.from, "d MMM yyyy")} -
    ${getFormattedDate(subOrder.period?.to, "d MMM yyyy")}`,
      [subOrder.period?.from, subOrder.period?.to]
    );

    const showTimeSelection = useMemo(
      () =>
        !(
          showPackageOptions && medium === MediumEnum.Radio
        ) /* Bij Radio zitten het tijdvak in `PackageOptionsRadio` */ &&
        selectedPackage &&
        selectedPackage.minDailyHours > 0,
      [medium, selectedPackage, showPackageOptions]
    );

    const showDaySelection = useMemo(
      () =>
        !(
          showPackageOptions && medium === MediumEnum.Radio
        ) /* Bij Radio zitten de dagen in `PackageOptionsRadio` */ &&
        selectedPackage &&
        selectedPackage.minDaysNoWeekly > 0 &&
        selectedPackage.minDaysNoWeekly < 7,
      [medium, selectedPackage, showPackageOptions]
    );

    return (
      <Form
        {...formItemLayout}
        onValuesChange={handleChange}
        onFinishFailed={handleFinishFailed}
        scrollToFirstError
        className={classNames(styles.form, formItemLayout.className)}
        form={form}
        name={`suborder-${subOrder.id}`}
      >
        <FormEditableItem
          label={i18n._(t`Periode`)}
          value={periodReadOnlyValue}
          className={styles.readOnly}
          editable={canUpdatePeriod}
        >
          <Period
            subOrder={subOrder}
            period={period}
            selectedSalesPeriod={selectedSalesPeriod}
          />
        </FormEditableItem>
        <FormEditableItem
          label={i18n._(t`Spotlengte`)}
          value={subOrder.spotLength?.join("+ ")}
          className={styles.readOnly}
          editable={canUpdateSpotLength}
        >
          <Form.Item label={i18n._(t`Spotlengte`)}>
            <SpotLength
              spotIndexes={spotIndexes}
              spotLength={subOrder.spotLength ?? []}
              enabled={subOrder.period !== undefined}
            />
          </Form.Item>
          {showBlockEditMessage && (
            <div className={styles.infoWrapper}>
              <Alert
                showIcon
                type="info"
                message=""
                description={i18n._(
                  t`Zodra de aangepaste spotlengte is verwerkt, kun je de blokken wijzigen in dit YourTime pakket.`
                )}
              />
            </div>
          )}
        </FormEditableItem>

        <FormEditableItem
          label={i18n._(t`Pakket`)}
          value={subOrder._package?.name}
          className={styles.readOnly}
          editable={canUpdatePackage}
        >
          <PackageSelectGrouped
            packages={packages}
            groupedPackages={
              medium === MediumEnum.Tv
                ? groupedPackagesTv
                : groupedPackagesRadio
            }
            period={subOrder.period}
            enabled={subOrder.period !== undefined}
            numberOfExcludedDays={subOrder.excluded?.length}
            spreadEnabled={subOrder.spread ?? false}
            value={form.getFieldValue("packageGroup")}
            subOrder={subOrder}
            subOrders={subOrders}
          />
          {showPackageOptions &&
            (medium === MediumEnum.Tv ? (
              <PackageOptionsTv
                packages={packages}
                groupedPackage={{
                  ...(groupedPackage as GroupedPackageTv),
                  properties: packagePropertiesTvWithoutSalesLock ?? [],
                }}
                channels={
                  selectedPackage?.targetGroups?.find(
                    (g) =>
                      g.targetGroupId === subOrder.targetGroup?.targetGroupId
                  )?.channels ?? []
                }
                onChange={handleChange}
                subOrder={subOrder}
                selectedPackage={selectedPackage}
                onFinishFailed={handleFinishFailed}
                hasSalesLock={
                  packagePropertiesTvWithoutSalesLock?.length !==
                  groupedPackage?.properties.length
                }
              />
            ) : (
              <PackageOptionsRadio
                packages={packages}
                groupedPackage={{
                  ...(groupedPackage as GroupedPackageRadio),
                  properties: packagePropertiesRadioWithoutSalesLock ?? [],
                }}
                channels={
                  selectedPackage?.targetGroups?.find(
                    (g) =>
                      g.targetGroupId === subOrder.targetGroup?.targetGroupId
                  )?.channels ?? []
                }
                onChange={handleChange}
                subOrder={subOrder}
                selectedPackage={selectedPackage}
                onFinishFailed={handleFinishFailed}
                hasSalesLock={
                  packagePropertiesRadioWithoutSalesLock?.length !==
                  groupedPackage?.properties.length
                }
                enabledDaysOfWeek={enabledDaysOfWeek}
              />
            ))}
          <Form.Item label={i18n._(t`Uitgesloten dagen`)} name="excluded">
            <ExcludeDays
              period={subOrder.period}
              weekDays={selectedPackage?.weekDays}
              daysOfWeek={subOrder.daysOfWeek}
              canDisableSeperateDays={selectedPackage?.minDaysNoWeekly === 0}
              disabled={!selectedPackage}
              disabledText={<Trans>Selecteer eerst een pakket</Trans>}
            />
          </Form.Item>
          <TargetGroupSelect
            disabled={subOrder._package?.code === undefined}
            targetGroups={selectedPackage?.targetGroups ?? []}
            medium={medium}
            form={form}
          />
          {showTimeSelection && (
            <Form.Item label={i18n._(t`Tijdvak`)}>
              <TimeSelection _package={selectedPackage!} medium={medium} />
            </Form.Item>
          )}
          {showDaySelection && (
            <Form.Item label={i18n._(t`Dagen van de week`)}>
              <DaySelection
                _package={selectedPackage!}
                enabledDaysOfWeek={enabledDaysOfWeek}
                enabled={!!subOrder.period}
              />
            </Form.Item>
          )}
        </FormEditableItem>

        {!canUpdatePackage && (
          <FormReadOnlyItem
            label={i18n._(t`Doelgroep`)}
            value={subOrder.targetGroup?.targetGroupId}
            className={styles.readOnly}
          />
        )}
      </Form>
    );
  }
);

export default GrpSubOrderForm;
