/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  MediumEnum,
  MediumEnumFromJSON,
  MediumEnumFromJSONTyped,
  MediumEnumToJSON,
} from "./";

/**
 *
 * @export
 * @interface BookingDate
 */
export interface BookingDate {
  /**
   *
   * @type {MediumEnum}
   * @memberof BookingDate
   */
  medium: MediumEnum;
  /**
   *
   * @type {string}
   * @memberof BookingDate
   */
  date: string;
  /**
   *
   * @type {string}
   * @memberof BookingDate
   */
  spotsEditableDate: string;
}

export function BookingDateFromJSON(json: any): BookingDate {
  return BookingDateFromJSONTyped(json, false);
}

export function BookingDateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BookingDate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    medium: MediumEnumFromJSON(json["medium"]),
    date: json["date"],
    spotsEditableDate: json["spotsEditableDate"],
  };
}

export function BookingDateToJSON(value?: BookingDate | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    medium: MediumEnumToJSON(value.medium),
    date: value.date,
    spotsEditableDate: value.spotsEditableDate,
  };
}
