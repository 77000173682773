/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  PackagePropertiesRadio,
  PackagePropertiesRadioFromJSON,
  PackagePropertiesRadioFromJSONTyped,
  PackagePropertiesRadioToJSON,
} from "./";

/**
 *
 * @export
 * @interface GroupedPackageRadio
 */
export interface GroupedPackageRadio {
  /**
   *
   * @type {string}
   * @memberof GroupedPackageRadio
   */
  name: string;
  /**
   *
   * @type {Array<PackagePropertiesRadio>}
   * @memberof GroupedPackageRadio
   */
  properties: Array<PackagePropertiesRadio>;
}

export function GroupedPackageRadioFromJSON(json: any): GroupedPackageRadio {
  return GroupedPackageRadioFromJSONTyped(json, false);
}

export function GroupedPackageRadioFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GroupedPackageRadio {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json["name"],
    properties: (json["properties"] as Array<any>).map(
      PackagePropertiesRadioFromJSON
    ),
  };
}

export function GroupedPackageRadioToJSON(
  value?: GroupedPackageRadio | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    properties: (value.properties as Array<any>).map(
      PackagePropertiesRadioToJSON
    ),
  };
}
