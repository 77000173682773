/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  TenantEnum,
  TenantEnumFromJSON,
  TenantEnumFromJSONTyped,
  TenantEnumToJSON,
} from "./";

/**
 *
 * @export
 * @interface SimplePortalUser
 */
export interface SimplePortalUser {
  /**
   *
   * @type {string}
   * @memberof SimplePortalUser
   */
  userId?: string | null;
  /**
   *
   * @type {string}
   * @memberof SimplePortalUser
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof SimplePortalUser
   */
  firstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof SimplePortalUser
   */
  lastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof SimplePortalUser
   */
  readonly fullName?: string | null;
  /**
   *
   * @type {string}
   * @memberof SimplePortalUser
   */
  organisationCode: string;
  /**
   *
   * @type {TenantEnum}
   * @memberof SimplePortalUser
   */
  tenant: TenantEnum;
  /**
   *
   * @type {number}
   * @memberof SimplePortalUser
   */
  advertiserCode?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof SimplePortalUser
   */
  isInternalUser?: boolean;
  /**
   *
   * @type {string}
   * @memberof SimplePortalUser
   */
  clientIdList?: string | null;
  /**
   *
   * @type {string}
   * @memberof SimplePortalUser
   */
  phoneNumber?: string | null;
}

export function SimplePortalUserFromJSON(json: any): SimplePortalUser {
  return SimplePortalUserFromJSONTyped(json, false);
}

export function SimplePortalUserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SimplePortalUser {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    userId: !exists(json, "userId") ? undefined : json["userId"],
    email: !exists(json, "email") ? undefined : json["email"],
    firstName: !exists(json, "firstName") ? undefined : json["firstName"],
    lastName: !exists(json, "lastName") ? undefined : json["lastName"],
    fullName: !exists(json, "fullName") ? undefined : json["fullName"],
    organisationCode: json["organisationCode"],
    tenant: TenantEnumFromJSON(json["tenant"]),
    advertiserCode: !exists(json, "advertiserCode")
      ? undefined
      : json["advertiserCode"],
    isInternalUser: !exists(json, "isInternalUser")
      ? undefined
      : json["isInternalUser"],
    clientIdList: !exists(json, "clientIdList")
      ? undefined
      : json["clientIdList"],
    phoneNumber: !exists(json, "phoneNumber") ? undefined : json["phoneNumber"],
  };
}

export function SimplePortalUserToJSON(value?: SimplePortalUser | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    userId: value.userId,
    email: value.email,
    firstName: value.firstName,
    lastName: value.lastName,
    organisationCode: value.organisationCode,
    tenant: TenantEnumToJSON(value.tenant),
    advertiserCode: value.advertiserCode,
    isInternalUser: value.isInternalUser,
    clientIdList: value.clientIdList,
    phoneNumber: value.phoneNumber,
  };
}
