import { Plural, Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Checkbox,
  ContentContainer,
  Form,
  Select,
  Switch,
  Typography,
} from "@ster/ster-toolkit";
import { Form as AntForm } from "antd";
import { Store } from "antd/lib/form/interface";
import { memo, useCallback, useEffect } from "react";

import {
  MediumEnum,
  PackageChoiceEnum,
  PackageType,
  SubOrderRequest,
} from "../../../../api";
import {
  actionOrientedValue,
  brandAwerenessValue,
} from "../../../../utils/constants";
import { formItemLayout } from "../../../partials/Form";
import DaySelection from "./DaySelection";
import { PackageOptionsOnlineProps } from "./models";
import styles from "./PackageOptions.module.less";
import TimeSelection from "./TimeSelection";

const PackageOptionsOnline = memo(
  ({
    selectedPackage,
    subOrder,
    deviceTargets,
    onChange,
    onFinishFailed,
    packageChoice,
  }: PackageOptionsOnlineProps) => {
    const { i18n } = useLingui();
    const [form] = AntForm.useForm();
    const selectedDeviceTargets = AntForm.useWatch("deviceTargets", form);
    const revenueType = AntForm.useWatch("revenueType", form);

    const isConnectedTvOnly = useCallback(
      (deviceTargetsInput: string[] | undefined) =>
        deviceTargetsInput &&
        deviceTargetsInput.length === 1 &&
        deviceTargetsInput[0] === "connected_tv",
      []
    );

    const handleValuesChange = useCallback(
      (changedValues: Store) => {
        const resetFields = [];

        if (changedValues.selectTime === false) {
          resetFields.push("timeSelection");
        }

        if (changedValues.selectDays === false) {
          resetFields.push("daysOfWeek");
        }

        if (changedValues.selectContextTargets === false) {
          resetFields.push("contextTargets");
        }

        if (changedValues.selectDeviceTargets === false) {
          resetFields.push("deviceTargets");
        }

        // De 'Doel campage' optie 'actiematig' is niet beschikbaar als het device target alleen 'connected tv' bevat
        if (
          changedValues.deviceTargets &&
          revenueType === actionOrientedValue &&
          isConnectedTvOnly(changedValues.deviceTargets)
        ) {
          resetFields.push("revenueType");
        }

        const newValues: Partial<SubOrderRequest> = {
          ...changedValues,
          ...Object.assign(
            // Alle velden die gereset moeten worden krijgen de waarde undefined
            {},
            ...resetFields.map((s) => ({ [s]: undefined }))
          ),
        };
        onChange(newValues);
      },
      [isConnectedTvOnly, onChange, revenueType]
    );

    useEffect(() => {
      form.setFieldsValue({
        revenueType: packageChoice === PackageChoiceEnum.Video ? 4 : undefined,
        ...subOrder,
        selectTime:
          (subOrder.timeSelection?.length ?? 0) > 0 ||
          form.getFieldValue("selectTime"),
        selectDays:
          ((subOrder.daysOfWeek?.length ?? 0) > 0 &&
            (subOrder.daysOfWeek?.length ?? 0) < 7) ||
          form.getFieldValue("selectDays"),
        selectContextTargets:
          (subOrder.contextTargets?.length ?? 0) > 0 ||
          form.getFieldValue("selectContextTargets"),
        selectDeviceTargets:
          ((subOrder.deviceTargets?.length ?? 0) > 0 &&
            (subOrder.deviceTargets?.length ?? 0) <
              deviceTargets.filter((s) => !s.disabled).length) ||
          form.getFieldValue("selectDeviceTargets"),
      });
    }, [deviceTargets, deviceTargets.length, form, packageChoice, subOrder]);

    return (
      <div className={styles.container}>
        <Typography.Title level={3} className={styles.title}>
          <Trans>Targeting mogelijkheden</Trans>
        </Typography.Title>
        <ContentContainer className={styles.options}>
          <Form
            {...formItemLayout}
            form={form}
            onValuesChange={handleValuesChange}
            name={`packageOptionsOnline-${subOrder.id}`}
            onFinishFailed={onFinishFailed}
            wrapperCol={{ span: 18 }}
            initialValues={{
              selectTime: false,
              selectDays: false,
              selectContextTargets: false,
              selectDeviceTargets: false,
              // default bij video = awareness
              revenueType:
                packageChoice === PackageChoiceEnum.Video ? 4 : undefined,
            }}
          >
            {selectedPackage && (
              <>
                <Form.Item label={i18n._(t`Tijdvak`)}>
                  <Form.Item name="selectTime" valuePropName="checked">
                    <Switch
                      checkedChildren={i18n._(t`aan`)}
                      unCheckedChildren={i18n._(t`uit`)}
                    />
                  </Form.Item>
                  <Form.Item dependencies={["selectTime"]} noStyle>
                    {({ getFieldValue }) =>
                      getFieldValue("selectTime") && (
                        <TimeSelection
                          _package={selectedPackage}
                          medium={MediumEnum.Inter}
                        />
                      )
                    }
                  </Form.Item>
                </Form.Item>
                <Form.Item
                  label={i18n._(t`Dagen`)}
                  helpText={
                    <Trans>
                      Met de optie Dagen bepaal je op welke dagen van de week je
                      ingedeeld wordt, minimaal{" "}
                      <Plural
                        value={selectedPackage.minDaysNoWeekly}
                        one="# dag"
                        other="# dagen"
                      />{" "}
                      per week.
                    </Trans>
                  }
                >
                  <Form.Item name="selectDays" valuePropName="checked">
                    <Switch
                      checkedChildren={i18n._(t`aan`)}
                      unCheckedChildren={i18n._(t`uit`)}
                    />
                  </Form.Item>
                  <Form.Item dependencies={["selectDays"]} noStyle>
                    {({ getFieldValue }) =>
                      getFieldValue("selectDays") && (
                        <DaySelection
                          _package={selectedPackage}
                          enabledDaysOfWeek={[1, 1, 1, 1, 1, 1, 1]}
                          shortDayNames
                          enabled
                        />
                      )
                    }
                  </Form.Item>
                </Form.Item>
                {(selectedPackage.type === PackageType.RunOnSite ||
                  selectedPackage.type === PackageType.RunOnNetwork) && (
                  <Form.Item label={i18n._(t`Device targeting`)}>
                    <Form.Item
                      name="selectDeviceTargets"
                      valuePropName="checked"
                    >
                      <Switch
                        checkedChildren={i18n._(t`aan`)}
                        unCheckedChildren={i18n._(t`uit`)}
                      />
                    </Form.Item>
                    <Form.Item dependencies={["selectDeviceTargets"]} noStyle>
                      {({ getFieldValue }) =>
                        getFieldValue("selectDeviceTargets") && (
                          <Form.Item name="deviceTargets">
                            <Checkbox.Group
                              mode="vertical"
                              options={deviceTargets}
                            />
                          </Form.Item>
                        )
                      }
                    </Form.Item>
                  </Form.Item>
                )}

                {packageChoice === PackageChoiceEnum.Video && (
                  <Form.Item
                    label={i18n._(t`Doel campagne`)}
                    name="revenueType"
                    helpText={i18n._(
                      "Bij een naamsbekendheid campagne wordt er geoptimaliseerd op VTR (view through rate) en bij een actiematige campagne op clicks."
                    )}
                    dependencies={["selectDeviceTargets"]}
                    rules={[
                      {
                        required: true,
                        message: i18n._(t`Kies een doel voor deze campagne`),
                      },
                    ]}
                    className={styles.type}
                  >
                    <Select
                      placeholder={i18n._(t`Kies een doel voor deze campagne`)}
                      options={[
                        {
                          label: i18n._(t`Naamsbekendheid`),
                          value: brandAwerenessValue,
                        },
                        {
                          label: (
                            <>
                              {i18n._(t`Actiematig`)}
                              <span>
                                {i18n._(t`(Connected TV wordt uitgesloten)`)}
                              </span>
                            </>
                          ),
                          value: actionOrientedValue,
                          disabled: isConnectedTvOnly(selectedDeviceTargets),
                        },
                      ]}
                    />
                  </Form.Item>
                )}
              </>
            )}
          </Form>
        </ContentContainer>
      </div>
    );
  }
);

export default PackageOptionsOnline;
