import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Tooltip } from "@ster/ster-toolkit";
import { memo, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { OrderDetail } from "../../api";
import { ReduxStoreState } from "../../store/base";
import { receiveInitialRequestAction } from "../../store/campaignCreate/actions";
import { StoreModel } from "../../store/models";
import { getFormattedDate } from "../../utils/dateHelper";

interface CampaignInitialRequestButtonProps {
  campaign: OrderDetail;
}

const CampaignInitialRequestButton = memo(
  ({ campaign }: CampaignInitialRequestButtonProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { i18n } = useLingui();

    const initialRequestId = campaign.initialRequestId ?? 0;
    const initialRequest = useSelector(
      (store: StoreModel) =>
        store.initialRequest[
          `${initialRequestId}_${campaign.orderStartDate.getMonth()}`
        ]
    );

    useMemo(() => {
      // Haal de initiele aanvraag op om te bepalen of deze in te zien is door de gebruiker
      if (
        !!campaign.initialRequestId &&
        (!initialRequest || initialRequest?.state === ReduxStoreState.Initial)
      ) {
        dispatch(
          receiveInitialRequestAction.request({
            medium: campaign.medium,
            initialRequestId,
            from: new Date(campaign.orderStartDate),
            to: new Date(campaign.orderEndDate),
          })
        );
      }
    }, [
      campaign.initialRequestId,
      campaign.medium,
      campaign.orderEndDate,
      campaign.orderStartDate,
      dispatch,
      initialRequest,
      initialRequestId,
    ]);

    const handleClick = useCallback(() => {
      navigate({
        pathname: `/campaigns/new/${campaign.medium}/${
          campaign.initialRequestId
        }/${getFormattedDate(campaign?.orderStartDate)}/${getFormattedDate(
          campaign?.orderEndDate
        )}`,
      });
    }, [
      campaign.initialRequestId,
      campaign.medium,
      campaign?.orderEndDate,
      campaign?.orderStartDate,
      navigate,
    ]);

    const tooltipText = useMemo(() => {
      if (!campaign.initialRequestId) {
        return i18n._(t`Er is geen initiele aanvraag bekend`);
      }
      if (
        !initialRequest?.loading &&
        initialRequest?.state !== ReduxStoreState.Success
      ) {
        return i18n._(
          t`De initiele aanvraag is door een andere organisatie ingediend`
        );
      }
      return null;
    }, [campaign.initialRequestId, i18n, initialRequest]);

    return (
      <Tooltip title={tooltipText} placement="left">
        {/* Extra element omdat de tooltip anders niet werkt op een uitgeschakelde button */}
        <div>
          <Button
            mode="tertiary"
            onClick={handleClick}
            loading={initialRequest?.loading}
            disabled={initialRequest?.state !== ReduxStoreState.Success}
          >
            <Trans>Initiele aanvraag</Trans>
          </Button>
        </div>
      </Tooltip>
    );
  }
);

export default CampaignInitialRequestButton;
