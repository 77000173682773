import { Plural, Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Alert } from "antd";
import { addMonths, format } from "date-fns";
import { enGB, nl } from "date-fns/locale";
import moment from "moment";
import { memo, useMemo } from "react";

import { MediumEnum } from "../../api";
import { getLanguage } from "../../utils";
import { DynamicIndexWarningProps } from "./models";

// Tijdelijke melding over het inzetten van de dynamische marktindex in oktober t/m december 2022 op radio
const DynamicIndexWarning = memo(
  ({ medium, from, to, className, fixedCosts }: DynamicIndexWarningProps) => {
    const { i18n } = useLingui();
    const startDate = useMemo(() => new Date(2022, 9, 1), []);
    const endDate = useMemo(() => new Date(2022, 11, 31), []);

    const showWarning = useMemo(
      () =>
        medium === MediumEnum.Radio &&
        moment(startDate).isSameOrBefore(moment(to), "day") &&
        moment(endDate).isSameOrAfter(moment(from), "day"),
      [endDate, from, medium, startDate, to]
    );

    const months = useMemo(() => {
      const dateArray = [];
      let currentDate = new Date(from);
      const stopDate = new Date(to);
      while (
        currentDate.getMonth() <= stopDate.getMonth() &&
        currentDate.getFullYear() === stopDate.getFullYear()
      ) {
        if (currentDate >= startDate && currentDate <= endDate) {
          dateArray.push(currentDate);
        }
        currentDate = addMonths(new Date(currentDate), 1);
      }
      return dateArray;
    }, [endDate, from, startDate, to]);

    if (!showWarning) {
      return null;
    }

    return fixedCosts ? (
      <Alert
        showIcon
        className={className}
        type="warning"
        message=""
        description={
          <Trans>
            <b>Let op:</b> voor de maand{" "}
            <Plural value={months.length} one="maand" other="maanden" />{" "}
            {months
              .map((m) =>
                format(m, "LLLL", {
                  locale: getLanguage() === "nl" ? nl : enGB,
                })
              )
              .join(` ${i18n._(t`en`)} `)}{" "}
            geldt er een marktindex van 110 voor alle radiozenders behalve
            Radio4 en FunX. Deze is nog niet verwerkt in de specifieke
            blokprijzen. Hier werken we hard aan om het op te lossen!
          </Trans>
        }
      />
    ) : (
      <Alert
        showIcon
        className={className}
        type="warning"
        message=""
        description={
          <Trans>
            <b>Let op:</b> voor de{" "}
            <Plural value={months.length} one="maand" other="maanden" />{" "}
            {months
              .map((m) =>
                format(m, "LLLL", {
                  locale: getLanguage() === "nl" ? nl : enGB,
                })
              )
              .join(` ${i18n._(t`en`)} `)}{" "}
            geldt er een marktindex van 110 voor alle radiozenders behalve
            Radio4 en FunX. Deze is verwerkt in de budgetten en GRP&apos;s bij
            de aanvraag, maar nog niet zichtbaar in de outputprijs berekening.
            Hier werken we hard aan om het op te lossen!
          </Trans>
        }
      />
    );
  }
);

export default DynamicIndexWarning;
