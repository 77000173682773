import { Trans } from "@lingui/macro";
import { ComponentLocalization, Form, RangePicker } from "@ster/ster-toolkit";
import { Form as AntForm } from "antd";
import { Store } from "antd/lib/form/interface";
import { endOfDay, format, isAfter, isBefore, startOfDay } from "date-fns";
import { useCallback, useEffect } from "react";

import { getLanguage, range } from "../../../../utils";
import { DateRange, DateRangeNullable } from "../../../../utils/types";
import styles from "./OnlineInstructionPeriodPicker.module.less";

interface OnlineInstructionPeriodPickerProps {
  dateRange: DateRange;
  value?: DateRangeNullable;
  onChange: (value: DateRange | undefined) => void;
}

const OnlineInstructionPeriodPicker = ({
  dateRange,
  value,
  onChange,
}: OnlineInstructionPeriodPickerProps) => {
  const [form] = AntForm.useForm();

  useEffect(() => {
    if (value) {
      /* Set form-field values when `selection` is set */
      if (value) {
        /* set date range */
        form.setFieldsValue({
          "date-range": [value.startDate, value.endDate],
        });
      }
    }
  }, [form, value]);

  const isDateDisabled = useCallback(
    (current: Date): boolean =>
      isBefore(current, dateRange.startDate) ||
      isAfter(current, endOfDay(dateRange.endDate)),
    [dateRange.endDate, dateRange.startDate]
  );

  const setSelectionValues = (values: Store): void => {
    const keyValue = Object.keys(values)[0];
    switch (keyValue) {
      case "date-range": {
        onChange(
          values[keyValue] && values[keyValue][0]
            ? {
                startDate: values[keyValue][0],
                endDate: values[keyValue][1],
              }
            : undefined
        );
        break;
      }
      default:
        break;
    }
  };

  return (
    <section className="picker picker--instructions">
      <span className="picker__title">
        <Trans>Wanneer</Trans>
      </span>

      <div className="picker-row picker-row--instructions">
        <Form layout="inline" form={form} onValuesChange={setSelectionValues}>
          <Form.Item shouldUpdate>
            {({ getFieldValue }) => (
              <Form.Item name="date-range">
                <RangePicker
                  className={styles.periodWithTime}
                  defaultPickerValue={
                    getFieldValue("date-range") // workaround voor rangepicker bug bij defaultPickerValue + value + showTime
                      ? undefined
                      : [dateRange.startDate, dateRange.endDate]
                  }
                  disabledDate={isDateDisabled}
                  placeholder={[
                    format(dateRange.startDate, "PP"),
                    format(dateRange.endDate, "PP"),
                  ]}
                  disabledTime={() => ({
                    disabledMinutes: () =>
                      range(0, 60).filter(
                        (min) => ![0, 15, 30, 45, 59].includes(min)
                      ),
                  })}
                  format="PPp"
                  showTime={{
                    format: "HH:mm",
                    hideDisabledOptions: true,
                    defaultValue: [
                      startOfDay(new Date()),
                      startOfDay(new Date()),
                    ],
                  }}
                  componentLocale={getLanguage() as ComponentLocalization}
                />
              </Form.Item>
            )}
          </Form.Item>
        </Form>
      </div>
    </section>
  );
};

export default OnlineInstructionPeriodPicker;
