import { ActionType, createReducer } from "typesafe-actions";

import { ReduxStoreState } from "../base";
import { LoadingOrganisations } from "../models";
import { receiveOrganisationsAction } from "./actions";

const initialState = {
  state: ReduxStoreState.Initial,
  organisations: {},
  loading: false,
};

// eslint-disable-next-line import/prefer-default-export
export const organisationsReducer = createReducer<
  LoadingOrganisations,
  ActionType<typeof receiveOrganisationsAction>
>(initialState)
  .handleAction(receiveOrganisationsAction.request, (state) => ({
    ...state,
    loading: true,
    state: ReduxStoreState.Loading,
  }))
  .handleAction(receiveOrganisationsAction.failure, (state) => ({
    ...state,
    loading: false,
    state: ReduxStoreState.Failure,
  }))
  .handleAction(receiveOrganisationsAction.success, (state, action) => ({
    ...state,
    organisations: action.payload,
    loading: false,
    state: ReduxStoreState.Success,
  }));
