import { AnyAction } from "redux";
import { Reducer, createReducer } from "typesafe-actions";

import { ReduxStoreState } from "../base";
import { LoadingDocuments } from "../models";
import { receiveDocumentsAction } from "./actions";

// eslint-disable-next-line import/prefer-default-export
export const documentsReducer: Reducer<LoadingDocuments, AnyAction> =
  createReducer({
    state: ReduxStoreState.Initial,
  })
    .handleAction(
      receiveDocumentsAction.request,
      (state: LoadingDocuments) => ({
        ...state,
        loading: true,
        state: ReduxStoreState.Loading,
      })
    )
    .handleAction(
      receiveDocumentsAction.failure,
      (state: LoadingDocuments) => ({
        ...state,
        loading: false,
        state: ReduxStoreState.Failure,
      })
    )
    .handleAction(
      receiveDocumentsAction.success,
      (
        state: LoadingDocuments,
        action: ReturnType<typeof receiveDocumentsAction.success>
      ) => ({
        documents: action.payload || [],
        loading: false,
        state: ReduxStoreState.Success,
      })
    );
