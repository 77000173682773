import { Trans } from "@lingui/macro";
import { Container, Spinner, Table, Typography } from "@ster/ster-toolkit";
import moment from "moment";
import { memo } from "react";

import { MediumEnum, SalesPeriod, SalesPeriodResult } from "../../api/models";
import { getFormattedDate } from "../../utils/dateHelper";

const emptySalesPeriod: SalesPeriod[] = [];

const SalesPeriods = memo(
  ({
    salesPeriods,
    loading,
    maximum,
  }: {
    salesPeriods: SalesPeriodResult[];
    loading: boolean;
    maximum: number;
  }) => (
    <Spinner spinning={loading}>
      <Container>
        <Typography.Title level={2}>
          <Trans>Aanvraagperiodes</Trans>
        </Typography.Title>
        <Table
          pagination={false}
          columns={[
            {
              title: <Trans>Maand</Trans>,
              key: "salesPeriodStartDate",
              dataIndex: "salesPeriodStartDate",
              render: (_, { salesPeriodStartDate }) => (
                <>{getFormattedDate(salesPeriodStartDate, "MMM yyyy")}</>
              ),
            },
            {
              title: <Trans>Start aanvraag</Trans>,
              key: "requestStartDate",
              dataIndex: "requestStartDate",
              render: (_, { requestStartDate }) => (
                <>{getFormattedDate(requestStartDate as Date, "d MMM")}</>
              ),
            },
            {
              title: <Trans>Eind aanvraag</Trans>,
              key: "requestEndDate",
              dataIndex: "requestEndDate",
              render: (_, { requestEndDate }) => (
                <>
                  {getFormattedDate(requestEndDate as Date, "d MMM H:mm 'uur'")}
                </>
              ),
            },
            {
              title: <Trans>Bevestigd op</Trans>,
              key: "confirmationDate",
              dataIndex: "confirmationDate",
              render: (_, { confirmationDate }) => (
                <>{getFormattedDate(confirmationDate as Date, "d MMM")}</>
              ),
            },
          ]}
          dataSource={(
            salesPeriods.find(({ medium }) => medium === MediumEnum.Tv)
              ?.result ?? emptySalesPeriod
          ).slice(0, maximum)}
          rowKey={(period: SalesPeriod): string =>
            moment(period.requestStartDate).format()
          }
        />
      </Container>
    </Spinner>
  )
);

export default SalesPeriods;
