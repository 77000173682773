import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Modal, Typography } from "@ster/ster-toolkit";
import { App as AntApp, Space } from "antd";
import { memo, useCallback, useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { useDispatch, useSelector } from "react-redux";

import { updateCampaignAction } from "../../../store/campaignEdit/actions";
import { StoreModel } from "../../../store/models";
import { usePrevious } from "../../../utils/hooks";
import BudgetDecreaseIncreaseWarning from "../../partials/BudgetDecreaseIncreaseWarning";
import { updateModalSelector } from "../selectors";
import { UpdateModalProps } from "./models";
import styles from "./UpdateModal.module.less";

const UpdateModal = memo(({ order, onClose }: UpdateModalProps) => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const { notification } = AntApp.useApp();

  const { account, loadingRequest } = useSelector((state: StoreModel) =>
    updateModalSelector(state)
  );

  const prevLoading = usePrevious(loadingRequest);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleFinalized = useCallback(() => {
    notification.success({
      message: i18n._(t`Wijziging ingediend`),
      description: i18n._(
        t`Je wijziging is ingediend. De aanpassing is niet direct zichtbaar. Wij zullen deze controleren waarna je bericht van ons ontvangt.`
      ),
      duration: 0,
    });

    TagManager.dataLayer({
      dataLayer: {
        event: "Wijziging campagne ingediend",
      },
    });
  }, [i18n, notification]);

  const handleSave = useCallback(() => {
    setButtonDisabled(true);

    dispatch(
      updateCampaignAction.request({
        orderRequest: order,
        // TODO: instructies
        onSuccess: () => {
          handleFinalized();
        },
      })
    );
  }, [dispatch, handleFinalized, order]);

  useEffect(() => {
    // Wanneer het laden klaar is is de aanvraag ingediend
    if (prevLoading && !loadingRequest) {
      setButtonDisabled(false);
      onClose();
    }
  }, [loadingRequest, onClose, prevLoading]);

  return (
    <Modal
      title={i18n._(t`Wil je de campagne wijzigen?`)}
      cancelText={i18n._(t`Annuleren`)}
      cancelButtonProps={{ disabled: loadingRequest || buttonDisabled }}
      okText={i18n._(t`Wijziging indienen`)}
      okButtonProps={{
        disabled: loadingRequest || buttonDisabled,
      }}
      open
      className={styles.finalizeModal}
      onOk={handleSave}
      onCancel={onClose}
      confirmLoading={loadingRequest}
    >
      <Space>
        <Typography.Paragraph>
          <Trans>
            Zodra je hieronder op ‘wijziging indienen’ klikt, ontvang je binnen
            enkele minuten een overzicht via e-mail op het bij ons bekende
            e-mailadres <strong>{account.sterInlog?.email}</strong>.<br />{" "}
            <strong>Let op:</strong> de wijzigingen die je zojuist hebt
            aangevraagd zijn nog niet definitief. Ster zal deze controleren.
            Zodra de wijzigingen zijn goedgekeurd en verwerkt ontvang je bericht
            van ons. Dit kan tot 24 uur duren.
          </Trans>
        </Typography.Paragraph>
        <Typography.Footnote>
          <Trans>
            Op al onze overeenkomsten zijn de Ster Algemene Voorwaarden,
            Verkoopvoorwaarden en Brochure met aanbod voor het kalenderjaar van
            jouw campagne van toepassing. Je vindt deze op{" "}
            <a
              href="https://www.ster.nl/voorwaarden/"
              rel="noopener noreferrer"
              target="_blank"
            >
              ster.nl/voorwaarden
            </a>
            . Je wordt geacht kennis te hebben genomen van deze voorwaarden.
            Toepasselijkheid van andersluidende voorwaarden wordt hierbij
            uitdrukkelijk van de hand gewezen.
          </Trans>
        </Typography.Footnote>
      </Space>
      <BudgetDecreaseIncreaseWarning
        subOrders={order.subOrders}
        originalBudget={order.originalBudget}
      />
    </Modal>
  );
});

export default UpdateModal;
