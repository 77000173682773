import { AnyAction } from "redux";
import { Reducer, createReducer } from "typesafe-actions";

import { genericReducer } from "../../utils";
import { ReduxStoreState } from "../base";
import { LoadingSpotsByMedium, LoadingTipsResult } from "../models";
import { receiveCurrentSpotsAction, receiveTipsAction } from "./actions";

export const currentSpotsReducer: Reducer<LoadingSpotsByMedium, AnyAction> =
  createReducer({
    state: ReduxStoreState.Initial,
  })
    .handleAction(
      receiveCurrentSpotsAction.request,
      (state: LoadingSpotsByMedium) => ({
        ...state,
        loading: true,
        state: ReduxStoreState.Loading,
      })
    )
    .handleAction(
      receiveCurrentSpotsAction.failure,
      (state: LoadingSpotsByMedium) => ({
        ...state,
        loading: false,
        state: ReduxStoreState.Failure,
      })
    )
    .handleAction(
      receiveCurrentSpotsAction.success,
      (
        state: LoadingSpotsByMedium,
        action: ReturnType<typeof receiveCurrentSpotsAction.success>
      ) => ({
        spotsByMedium: action.payload || [],
        loading: false,
        state: ReduxStoreState.Success,
      })
    );

export const tipsReducer: Reducer<LoadingTipsResult, AnyAction> =
  genericReducer(
    receiveTipsAction.request,
    receiveTipsAction.success,
    receiveTipsAction.failure,
    "data"
  );
