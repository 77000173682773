import { createAsyncAction } from "typesafe-actions";

import {
  ApiV1CampaignGeteditablecampaignGetRequest,
  ApiV1CommercialInstructionsOnlineOrderIdGetRequest,
  OrderRequest,
} from "../../api";
import { ResponseError } from "../base";
import { actionNameAsync } from "../utils";
import {
  CampaignInstructionsOnline,
  EditableCampaignFailurePayload,
  UpdateCampaignFailurePayload,
  UpdateCampaignPayload,
} from "./models";

export const updateCampaignAction = createAsyncAction(
  actionNameAsync("updateCampaign", "REQUEST"),
  actionNameAsync("updateCampaign", "SUCCESS"),
  actionNameAsync("updateCampaign", "FAILURE")
)<UpdateCampaignPayload, undefined, UpdateCampaignFailurePayload>();

export const receiveCampaignInstructionsOnlineAction = createAsyncAction(
  actionNameAsync("campaignInstructionsOnline", "REQUEST"),
  actionNameAsync("campaignInstructionsOnline", "SUCCESS"),
  actionNameAsync("campaignInstructionsOnline", "FAILURE")
)<
  ApiV1CommercialInstructionsOnlineOrderIdGetRequest,
  CampaignInstructionsOnline,
  ResponseError
>();

export const receiveEditableCampaignAction = createAsyncAction(
  actionNameAsync("editableCampaign", "REQUEST"),
  actionNameAsync("editableCampaign", "SUCCESS"),
  actionNameAsync("editableCampaign", "FAILURE")
)<
  ApiV1CampaignGeteditablecampaignGetRequest,
  OrderRequest,
  EditableCampaignFailurePayload
>();
