import "../mediumicon.less";

import { Trans } from "@lingui/macro";
import { Icons } from "@ster/ster-toolkit";

interface FileTypeIconProps {
  medium: string;
}

const FiletypeIcon = ({ medium }: FileTypeIconProps) => {
  const { FileIcon, PdfIcon, WordIcon } = Icons;

  switch (medium) {
    case "pdf":
      return (
        <>
          <PdfIcon width={24} height={24} fill="#000" />
          <span className="medium-label">
            <Trans>PDF</Trans>
          </span>
        </>
      );
    case "docx":
      return (
        <>
          <WordIcon width={24} height={24} fill="#000" />
          <span className="medium-label">
            <Trans>Docx</Trans>
          </span>
        </>
      );
    default:
      return <FileIcon width={24} height={24} fill="#000" />;
  }
};

export default FiletypeIcon;
