/* eslint-disable no-nested-ternary */
import { SaveOutlined, UndoOutlined } from "@ant-design/icons";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, DatePicker, Input, Switch, Tooltip } from "@ster/ster-toolkit";
import { Form, Space, message } from "antd";
import { parseISO } from "date-fns";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

import { EditableSetting, SettingDataType } from "../../api";
import { updateEditableSettingAction } from "../../store/admin/actions";
import { receiveSettingsAction } from "../../store/settings/actions";
import styles from "./EditableSettingField.module.less";

const EditableSettingField = ({ setting }: { setting: EditableSetting }) => {
  const { i18n } = useLingui();
  const [form] = Form.useForm<EditableSetting>();

  useEffect(() => {
    form.resetFields();
  }, [form, setting]);

  const dispatch = useDispatch();
  const handleFinish = useCallback(
    (values: EditableSetting) => {
      dispatch(
        updateEditableSettingAction.request({
          setting:
            values.type === SettingDataType.Int
              ? { ...values, value: Number(values.value) }
              : values,
          onSuccess: () => {
            dispatch(receiveSettingsAction.request());
            message.success(
              i18n._(t`De aanpassing is opgeslagen voor "${values.alias}".`)
            );
          },
        })
      );
    },
    [dispatch, i18n]
  );

  if (!setting.type) {
    return null;
  }

  const type = setting.type as SettingDataType;
  const value: boolean | Date | string | number =
    type === SettingDataType.Bool
      ? Boolean(setting.value)
      : type === SettingDataType.Datetime
        ? parseISO(setting.value)
        : type === SettingDataType.Int
          ? Number(setting.value)
          : String(setting.value);

  return (
    <Form
      layout="inline"
      form={form}
      name={`field-${setting.alias}`}
      initialValues={{ ...setting, value }}
      onFinish={handleFinish}
      style={{ alignItems: "center" }}
    >
      <Form.Item name="name" rules={[{ required: true }]} hidden>
        <Input />
      </Form.Item>
      <Form.Item name="alias" rules={[{ required: true }]} hidden>
        <Input />
      </Form.Item>
      <Form.Item name="type" rules={[{ required: true }]} hidden>
        <Input />
      </Form.Item>
      <Form.Item
        name="value"
        rules={[{ required: true, message: i18n._(t`Vul een waarde in`) }]}
        valuePropName={type === SettingDataType.Bool ? "checked" : undefined}
      >
        {type === SettingDataType.Bool ? (
          <Switch />
        ) : type === SettingDataType.Datetime ? (
          <DatePicker
            format="PPp"
            showTime={{
              format: "HH:mm",
            }}
            style={{ width: 200 }}
          />
        ) : type === SettingDataType.Int ? (
          <Input.Decimal style={{ width: 200 }} step={1} decimalScale={0} />
        ) : (
          <Input style={{ width: 200 }} />
        )}
      </Form.Item>
      <Form.Item dependencies={["value"]}>
        {({ isFieldTouched, getFieldValue, resetFields }) =>
          isFieldTouched("value") &&
          getFieldValue("value") !== setting.value && (
            <Space direction="horizontal">
              <Tooltip title={i18n._(t`Herstel originele waarde`)}>
                <Button
                  type="link"
                  mode="tertiary"
                  shape="circle"
                  ghost
                  size="small"
                  className={styles.settingsBtn}
                  onClick={() => {
                    resetFields();
                  }}
                >
                  <UndoOutlined />
                </Button>
              </Tooltip>
              <Tooltip title={i18n._(t`Gewijzigde waarde opslaan`)}>
                <Button
                  htmlType="submit"
                  type="link"
                  mode="tertiary"
                  shape="circle"
                  ghost
                  size="small"
                  className={styles.settingsBtn}
                >
                  <SaveOutlined />
                </Button>
              </Tooltip>
            </Space>
          )
        }
      </Form.Item>
    </Form>
  );
};

export default EditableSettingField;
