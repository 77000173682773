import { Trans } from "@lingui/macro";
import { Button, Row } from "@ster/ster-toolkit";
import { Alert, Col } from "antd";
import { ReactNode, memo, useCallback, useMemo, useState } from "react";

import {
  CommercialInstructionRequest,
  CommercialInstructionStatus,
  CommercialsPerAdvertiser,
  MediumEnum,
} from "../../../api";
import { DateRange } from "../../../utils/types";
import styles from "./AddInstructions.module.less";
import InstructionModal from "./InstructionModal";
import { SimpleSubOrder } from "./utils";

export interface AddInstructionsProps {
  enabled: boolean;
  instructions: CommercialInstructionRequest[];
  commercialsPerAdvertiser?: CommercialsPerAdvertiser;
  onOk?: () => void;
  maxDate: Date;
  medium: MediumEnum;
  productId: number;
  instructionStatus?: CommercialInstructionStatus;
  okText?: ReactNode;
  onSave?: (
    instruction: CommercialInstructionRequest,
    onOkCallback: () => void
  ) => void;
  newInstructionText?: ReactNode;
  newButtonMode?: "primary" | "secondary" | "tertiary" | "outline";
  subOrders: SimpleSubOrder[];
  dateRangesWithoutInstructions: DateRange[];
}

const AddInstructions = ({
  enabled,
  instructions,
  commercialsPerAdvertiser,
  onOk,
  maxDate,
  medium,
  productId,
  instructionStatus,
  okText,
  onSave,
  newInstructionText,
  newButtonMode,
  subOrders,
  dateRangesWithoutInstructions,
}: AddInstructionsProps) => {
  const [showModal, setModal] = useState(false);
  const handleModalOpen = useCallback(() => {
    setModal(true);
  }, []);
  const handleModalCancel = useCallback(() => {
    setModal(false);
  }, []);
  const handleModalOk = useCallback(() => {
    setModal(false);
    if (onOk) {
      onOk();
    }
  }, [onOk]);

  const newIds = instructions
    .filter((i) => i.id && i.id < 0)
    .map((i) => i.id as number);
  const nextNewId = (newIds.length > 0 ? Math.min(...newIds) : 0) - 1;

  const noInstructionPossible = useMemo(
    () => dateRangesWithoutInstructions.length === 0,
    [dateRangesWithoutInstructions.length]
  );

  const partialInstructionPossible = useMemo(
    () => dateRangesWithoutInstructions.length > 0 && instructions.length > 0,
    [dateRangesWithoutInstructions.length, instructions.length]
  );

  return (
    <>
      {enabled && (
        <Row gutter={[40, 40]} className={styles.instructionButtonBarWrapper}>
          <Col span={24} className={styles.instructionButtonBar}>
            <Button mode={newButtonMode ?? "primary"} onClick={handleModalOpen}>
              {newInstructionText ?? <Trans>Nieuwe instructie</Trans>}
            </Button>
          </Col>
        </Row>
      )}

      {showModal && (
        <InstructionModal
          onCancel={handleModalCancel}
          onOk={handleModalOk}
          commercialsPerAdvertiser={commercialsPerAdvertiser}
          maxDate={maxDate}
          medium={medium}
          productId={productId}
          instructionStatus={instructionStatus}
          okText={okText}
          onSave={onSave}
          nextId={nextNewId}
          subOrders={subOrders}
          dateRangesWithoutInstructions={dateRangesWithoutInstructions}
          header={
            (noInstructionPossible || partialInstructionPossible) && (
              <Alert
                className={styles.warning}
                showIcon
                type={noInstructionPossible ? "warning" : "info"}
                message=""
                description={
                  noInstructionPossible ? (
                    <Trans>
                      Het is niet mogelijk om een nieuwe instructie toe te
                      voegen, er zijn in deze periode al andere instructies
                      actief. <br />
                      Neem contact op met{" "}
                      <a href="mailto:klantportal@ster.nl">planning</a> om je
                      instructie in te dienen.
                    </Trans>
                  ) : (
                    <Trans>
                      Kun je niet de gewenste periode kiezen? Mogelijk zijn er
                      op dat moment al andere instructies actief. <br />
                      Neem contact op met{" "}
                      <a href="mailto:klantportal@ster.nl">planning</a> om je
                      instructie in te dienen.
                    </Trans>
                  )
                }
              />
            )
          }
        />
      )}
    </>
  );
};

export default memo(AddInstructions);
