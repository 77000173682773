import { createAsyncAction } from "typesafe-actions";

import { SettingsGetResponse } from "../../api/models";
import { ResponseError } from "../base";
import { actionNameAsync } from "../utils";

// eslint-disable-next-line import/prefer-default-export
export const receiveSettingsAction = createAsyncAction(
  actionNameAsync("settings", "REQUEST"),
  actionNameAsync("settings", "SUCCESS"),
  actionNameAsync("settings", "FAILURE")
)<void, SettingsGetResponse, ResponseError>();
