/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum Status {
  Success = "Success",
  Failed = "Failed",
}

export function StatusFromJSON(json: any): Status {
  return StatusFromJSONTyped(json, false);
}

export function StatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Status {
  return json as Status;
}

export function StatusToJSON(value?: Status | null): any {
  return value as any;
}
