import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form, Table } from "@ster/ster-toolkit";
import { Form as AntForm } from "antd";
import { FormInstance } from "antd/lib/form";
import { TableProps } from "antd/lib/table";
import classNames from "classnames";
import {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import {
  CalculationResult,
  PackageChoiceEnum,
  SubOrderRequest,
  UpdateFields,
} from "../../../../api";
import styles from "../SubOrderList.module.less";
import CalculateGrpBudget from "./CalculateGrpBudget";
import { EditableCellProps } from "./models";

const EditableContext = createContext<FormInstance | undefined>(undefined);

// Wijzigbare rij voor in de deelorderlijst
const EditableRow = ({ ...props }) => {
  const [form] = AntForm.useForm();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

// Wijzigbare cel om GRP's of budget van een deelorder te wijzigen
const EditableCell = ({
  dataIndex,
  children,
  editableCell,
  expandableCell = true,
  record,
  onExpand,
  expandedItemKey,
  editable,
  medium,
  productId,
  onSubOrderChange,
  className,
  requestDate,
  isEditCampaign,
  ...restProps
}: EditableCellProps) => {
  const { i18n } = useLingui();
  const form = useContext(EditableContext);
  useEffect((): void => {
    // Vul het formulier met de bestaande deelorder
    if (form && editableCell) {
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    }
  }, [form, editableCell, dataIndex, record]);

  const handleCalculated = useCallback(
    (values: CalculationResult) => {
      onSubOrderChange({
        id: record.id,
        ...values,
      });
    },
    [onSubOrderChange, record]
  );

  const grpEditable =
    record &&
    record.packageChoice === PackageChoiceEnum.Grps &&
    record.period &&
    (record.spotLength?.length ?? 0) > 0 &&
    record._package?.code &&
    record.targetGroup?.targetGroupId;

  const onlineEditable =
    record &&
    (record.packageChoice === PackageChoiceEnum.Display ||
      record.packageChoice === PackageChoiceEnum.Video) &&
    record.period &&
    record._package?.code;

  const editCell =
    editable &&
    editableCell &&
    (grpEditable || onlineEditable) &&
    !(dataIndex === "grp" && record._package.isYourTime) &&
    expandedItemKey?.[0] !== record.id &&
    ((!isEditCampaign ||
      record.editableFields?.includes(UpdateFields.Budget)) ??
      false);

  const [changedField, setChangedField] = useState<string>("");

  const handleActivateField = useCallback(
    (
      event:
        | React.MouseEvent<HTMLInputElement>
        | React.FocusEvent<HTMLInputElement>
    ) => {
      const name =
        (event as React.FocusEvent<HTMLInputElement>)?.target.name ??
        event.currentTarget.name;
      if (changedField === name) {
        return;
      }
      setChangedField(name);
    },
    [changedField]
  );

  const handleDeactivateField = useCallback(() => {
    setChangedField("");
  }, []);

  return (
    <td
      // Maak de td klikbaar op alle niet-wijzigbare cellen om de rij uit te klappen
      onClick={(): void => {
        if (editable && record && !editableCell && expandableCell) {
          onExpand(expandedItemKey !== record.id, record);
        }
      }}
      className={classNames(className, {
        [styles.editableCell]: editableCell,
      })}
      {...restProps}
    >
      {editCell ? (
        <Form.Item
          name={dataIndex}
          rules={[
            {
              required: true,
              message: i18n._(t`Voer een budget in`),
            },
          ]}
        >
          <CalculateGrpBudget
            subOrder={record}
            medium={medium}
            productId={productId}
            onCalculated={handleCalculated}
            name={dataIndex}
            isActive={changedField === dataIndex}
            onClick={handleActivateField}
            onBlur={handleDeactivateField}
            requestDate={requestDate}
            id={`${dataIndex}EditableTable`}
          />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

/**
 * Lijstweergave van deelorders met wijzigbare cel.
 */
const EditableTable = memo(({ ...props }: TableProps<SubOrderRequest>) => (
  <Table
    components={{
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    }}
    {...props}
  />
));

export default EditableTable;
