/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  OrderRequest,
  OrderRequestFromJSON,
  OrderRequestFromJSONTyped,
  OrderRequestToJSON,
} from "./";

/**
 *
 * @export
 * @interface ProposalOrderRequest
 */
export interface ProposalOrderRequest {
  /**
   *
   * @type {string}
   * @memberof ProposalOrderRequest
   */
  userEmail: string;
  /**
   *
   * @type {string}
   * @memberof ProposalOrderRequest
   */
  userFullName: string;
  /**
   *
   * @type {string}
   * @memberof ProposalOrderRequest
   */
  userCompany: string;
  /**
   *
   * @type {OrderRequest}
   * @memberof ProposalOrderRequest
   */
  orderRequest: OrderRequest;
  /**
   *
   * @type {string}
   * @memberof ProposalOrderRequest
   */
  clientId?: string | null;
}

export function ProposalOrderRequestFromJSON(json: any): ProposalOrderRequest {
  return ProposalOrderRequestFromJSONTyped(json, false);
}

export function ProposalOrderRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProposalOrderRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    userEmail: json["userEmail"],
    userFullName: json["userFullName"],
    userCompany: json["userCompany"],
    orderRequest: OrderRequestFromJSON(json["orderRequest"]),
    clientId: !exists(json, "clientId") ? undefined : json["clientId"],
  };
}

export function ProposalOrderRequestToJSON(
  value?: ProposalOrderRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    userEmail: value.userEmail,
    userFullName: value.userFullName,
    userCompany: value.userCompany,
    orderRequest: OrderRequestToJSON(value.orderRequest),
    clientId: value.clientId,
  };
}
