import "../styles.less";

import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { Icons, Row, Typography } from "@ster/ster-toolkit";
import { Col } from "antd";
import classNames from "classnames";
import moment from "moment";
import { useCallback } from "react";
import { Link } from "react-router-dom";

import { Organisation, PortalUser } from "../../../../api/models";
import CardStatistic from "../CardStatistic";
import styles from "./UserCard.module.less";

interface UserCardProps {
  cardData: PortalUser;
  organisation?: Organisation;
  onDelete: (userId: string) => void;
}

const nullDate = moment("0000-12-31T00:00:00+00:00");

const UserCard = ({ cardData, organisation, onDelete }: UserCardProps) => {
  const { id, lastLogin, email, userInfo, active } = cardData;

  const { Title } = Typography;
  const { DeleteIcon } = Icons;

  const formattedLastLogin =
    lastLogin && moment(lastLogin).isAfter(nullDate, "year")
      ? moment(lastLogin).fromNow()
      : i18n._(t`Nog niet ingelogd`);

  const handleDelete = useCallback(() => {
    onDelete(id);
  }, [id, onDelete]);

  return (
    <article
      className={classNames("card", "card--document", {
        [styles.userInactive]: !active,
      })}
    >
      <header className="card-header">
        <div className="card-header__icon">
          <Icons.UserIcon />
        </div>
        <div className="card-header__menu">
          <button
            type="button"
            className="card-header__delete-btn"
            onClick={handleDelete}
          >
            <DeleteIcon />
          </button>
        </div>
      </header>
      <div className="card-body">
        <Title level={2} className="card-title">
          <Link
            to={`/admin/users/${id}`}
            className="card-title__link"
            title={i18n._(t`Gebruiker bewerken`)}
          >
            {userInfo.fullName}
          </Link>
        </Title>
        <footer>
          <Row>
            <Col span={10}>
              <CardStatistic label={i18n._(t`E-mail`)} value={email} />
            </Col>
            <Col span={8}>
              <CardStatistic
                label={i18n._(t`Organisatie`)}
                value={
                  organisation
                    ? `${organisation.name} (${organisation.code})`
                    : "-"
                }
              />
            </Col>
            <Col span={6}>
              <CardStatistic
                label={i18n._(t`Laatst ingelogd op`)}
                value={formattedLastLogin}
              />
            </Col>
          </Row>
        </footer>
      </div>
    </article>
  );
};

export default UserCard;
