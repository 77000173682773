import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Icons, Input, Modal, Typography } from "@ster/ster-toolkit";
import { Popconfirm, Space } from "antd";
import { useCallback, useState } from "react";

import { CommercialUploadStatus } from "../../../api";

const buttonIconSize = { width: 20, height: 20 };

const RejectForm = ({
  message,
  setMessage,
}: {
  message: string;
  setMessage: (m: string) => void;
}) => {
  const { i18n } = useLingui();
  const handleChange = useCallback(
    ({ target: { value = "" } }) => {
      setMessage(value);
    },
    [setMessage]
  );
  return (
    <>
      <Typography.Paragraph>
        {i18n._(
          t`Geef in onderstaand tekstveld een reden op waarom deze commercial wordt afgekeurd. Dit wordt vervolgens per mail kenbaar gemaakt bij de gebruiker.`
        )}
      </Typography.Paragraph>
      <Input.TextArea
        value={message}
        onChange={handleChange}
        rows={4}
        placeholder={i18n._(t`Reden voor het afkeuren`)}
      />
    </>
  );
};

export const ReviewButtons = ({
  internalId,
  status,
  updateStatus,
}: {
  internalId: number;
  status: CommercialUploadStatus;
  updateStatus: (
    id: number,
    newStatus: CommercialUploadStatus,
    message?: string | null
  ) => void;
}) => {
  const { i18n } = useLingui();
  const handleReset = useCallback(
    () => updateStatus(internalId, CommercialUploadStatus.WaitForReview),
    [internalId, updateStatus]
  );

  const handleApprove = useCallback(
    () => updateStatus(internalId, CommercialUploadStatus.Approved),
    [internalId, updateStatus]
  );

  const [show, showConfirm] = useState(false);
  const [message, setMessage] = useState("");
  const handleRejectConfirm = useCallback(() => {
    setMessage("");
    showConfirm(true);
  }, []);

  const handleCancel = useCallback(() => {
    showConfirm(false);
    setMessage("");
  }, []);

  const handleReject = useCallback(() => {
    updateStatus(internalId, CommercialUploadStatus.Rejected, message);
    setMessage("");
    showConfirm(false);
  }, [internalId, message, updateStatus]);

  return (
    <Space direction="horizontal">
      {status === CommercialUploadStatus.WaitForReview ? (
        <>
          <Popconfirm
            title={i18n._(t`Weet je zeker dat je deze video wilt goedkeuren?`)}
            key="approve"
            onConfirm={handleApprove}
          >
            <Button
              icon={<Icons.CheckIcon {...buttonIconSize} />}
              mode="tertiary"
              type="link"
              size="small"
            >
              {i18n._(t`Goedkeuren`)}
            </Button>
          </Popconfirm>
          <Button
            icon={<Icons.ErrorIcon {...buttonIconSize} />}
            mode="tertiary"
            type="link"
            size="small"
            onClick={handleRejectConfirm}
          >
            {i18n._(t`Afkeuren`)}
          </Button>
          {show && (
            <Modal
              title={i18n._(t`Afkeuren?`)}
              okText={i18n._(t`Afkeuren`)}
              okButtonProps={{ disabled: message === "" }}
              onOk={handleReject}
              cancelText={i18n._(t`Annuleren`)}
              onCancel={handleCancel}
              destroyOnClose
              open
              width={480}
            >
              <RejectForm message={message} setMessage={setMessage} />
            </Modal>
          )}
        </>
      ) : (
        <Popconfirm
          title={i18n._(
            t`Weet je zeker dat je de status van deze video wilt herstellen?`
          )}
          key="reset"
          onConfirm={handleReset}
        >
          <Button
            icon={<Icons.InfoIcon {...buttonIconSize} />}
            mode="tertiary"
            type="link"
            size="small"
          >
            {i18n._(t`Herstellen`)}
          </Button>
        </Popconfirm>
      )}
    </Space>
  );
};

export const ApproveAllButton = ({
  disabled,
  handleApproveAll,
  handleRejectAll,
}: {
  disabled?: boolean;
  handleApproveAll: () => void;
  handleRejectAll: (message: string) => void;
}) => {
  const { i18n } = useLingui();

  const [show, showConfirm] = useState(false);
  const [message, setMessage] = useState("");
  const handleRejectConfirm = useCallback(() => {
    setMessage("");
    showConfirm(true);
  }, []);

  const handleCancel = useCallback(() => {
    showConfirm(false);
    setMessage("");
  }, []);

  const handleReject = useCallback(() => {
    handleRejectAll(message);
    setMessage("");
    showConfirm(false);
  }, [handleRejectAll, message]);

  return (
    <Space direction="horizontal">
      <Popconfirm
        title={i18n._(
          t`Weet je zeker dat je al het geselecteerde materiaal wilt goedkeuren?`
        )}
        key="approve"
        onConfirm={handleApproveAll}
      >
        <Button
          icon={<Icons.CheckIcon {...buttonIconSize} />}
          mode="tertiary"
          type="link"
          size="small"
          disabled={disabled}
        >
          {i18n._(t`Alles goedkeuren`)}
        </Button>
      </Popconfirm>
      <Button
        icon={<Icons.ErrorIcon {...buttonIconSize} />}
        mode="tertiary"
        type="link"
        size="small"
        onClick={handleRejectConfirm}
        disabled={disabled}
      >
        {i18n._(t`Alles afkeuren`)}
      </Button>
      {show && (
        <Modal
          title={i18n._(t`Afkeuren?`)}
          okText={i18n._(t`Afkeuren`)}
          okButtonProps={{ disabled: message === "" }}
          onOk={handleReject}
          cancelText={i18n._(t`Annuleren`)}
          onCancel={handleCancel}
          destroyOnClose
          open
          width={480}
        >
          <RejectForm message={message} setMessage={setMessage} />
        </Modal>
      )}
    </Space>
  );
};
