import { PlusOutlined } from "@ant-design/icons";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Form, Icons, Option, Select, Tag } from "@ster/ster-toolkit";
import { Form as AntForm, Space } from "antd";
import { memo, useMemo } from "react";

import { TagStatus, getPopupContainerSubOrders } from "../../../../utils";
import { SpotLengthProps } from "./models";
import styles from "./SpotLength.module.less";

interface FieldData {
  name: number;
  key: number;
}

interface SpotLengthInputProps extends SpotLengthProps {
  fields: FieldData[];
  remove: (index: number) => void;
  enabled: boolean;
}

/**
 * Invoervelden voor spotlengte
 */
const SpotLengthFields = memo(
  ({
    fields,
    spotIndexes,
    spotLength,
    remove,
    enabled,
  }: SpotLengthInputProps): React.ReactElement => {
    const { i18n } = useLingui();
    return (
      <>
        {fields.map((field, index) => {
          /**
           * Filter de spotlengtes die nog ingezet kunnen worden. Een spotlengte kan ingezet worden als:
           * - bij hoofdspot van 5 seconden, de tag-on maximaal 10 seconden is
           * - de spotlengte van een tag-on maximaal 1.5 keer de spotlengte van de hoofdspot is
           * - de totale spotlengte van de tag-on(s) en de hoofdspot gelijk is aan een van de beschikbare spotlengtes
           */
          const indicesLeft = spotIndexes.filter(
            (si) =>
              (index === 0 ||
                (spotLength[0] &&
                  ((spotLength[0] === 5 && si.length <= 10) ||
                    si.length <= spotLength[0] * 1.5))) &&
              spotIndexes.find(
                (s) =>
                  s.length ===
                  [
                    ...spotLength.slice(0, index),
                    si.length,
                    ...spotLength.slice(index + 1),
                  ].reduce((a, b) => a + (b ?? 0))
              )?.index
          );

          return (
            <Form.Item
              required={false}
              key={field.key}
              className={styles.spotLength}
              noStyle
            >
              <Form.Item
                {...field}
                validateTrigger={["onChange", "onBlur"]}
                rules={[
                  {
                    required: true,
                    message: i18n._(t`Kies een spotlengte`),
                  },
                ]}
                noStyle
              >
                <Select
                  style={{ width: "80px" }}
                  disabled={!enabled}
                  getPopupContainer={getPopupContainerSubOrders}
                >
                  {indicesLeft.map((s) => (
                    <Option key={s.length} value={s.length}>
                      {s.length}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {index > 0 && fields.length === index + 1 ? (
                <span
                  className={styles.delete}
                  onClick={(): void => {
                    remove(field.name);
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <Icons.DeleteIcon />
                </span>
              ) : null}
              {fields.length > 1 && index + 1 < fields.length ? (
                <span className={styles.plus}>+</span>
              ) : null}
            </Form.Item>
          );
        })}
      </>
    );
  }
);

/**
 * Invoer van spotlengte
 */
const SpotLength = ({ spotIndexes, spotLength, enabled }: SpotLengthProps) => {
  const { i18n } = useLingui();
  const spotLengthIndex = useMemo(
    () =>
      spotLength.length > 0 &&
      spotIndexes.find(
        (s) => s.length === spotLength.reduce((a, b) => a + (b ?? 0))
      )?.index,
    [spotIndexes, spotLength]
  );

  const hasIndicesLeft = useMemo(
    () =>
      spotIndexes.some(
        (si) =>
          spotIndexes.find(
            (s) =>
              s.length ===
              [...spotLength, si.length].reduce((a, b) => a + (b ?? 0))
          )?.index
      ),
    [spotIndexes, spotLength]
  );

  return (
    <AntForm.List
      name="spotLength"
      rules={[
        {
          validator: (_, values: number[]) => {
            if (!values || values.length === 0) {
              return Promise.reject(
                new Error(i18n._(t`Spotlengte is verplicht`))
              );
            }

            // Filtered out empty values
            const filteredValues = values.filter((v) => v) ?? [];
            if (filteredValues.length > 1) {
              // has tag-ons
              const mainLength = filteredValues[0];
              const tagOnLengths = filteredValues.slice(1);

              // check if length of tag-ons is less than 1.5 times the length of the main spot
              const valid = tagOnLengths.every(
                (length) =>
                  (mainLength === 5 && length <= 10) ||
                  length <= mainLength * 1.5
              );
              if (!valid) {
                return Promise.reject(
                  new Error(
                    i18n._(
                      t`De lengte van een tag-on mag maximaal 1,5x de lengte van de hoofdspot zijn.`
                    )
                  )
                );
              }
            }

            return Promise.resolve();
          },
        },
      ]}
    >
      {(fields, { add, remove }, { errors }) => (
        <div className={styles.spotLength}>
          <Space direction="horizontal">
            <SpotLengthFields
              fields={fields}
              remove={remove}
              spotIndexes={spotIndexes}
              spotLength={spotLength}
              enabled={enabled}
            />
            <Form.Item noStyle>
              {fields.length < 5 && ( // Maximaal 5 toe kunnen voegen
                <Button
                  mode="tertiary"
                  onClick={(): void => {
                    add();
                  }}
                  disabled={!enabled || !hasIndicesLeft}
                >
                  <PlusOutlined /> <Trans>tag-on</Trans>
                </Button>
              )}

              {spotLengthIndex && (
                <Tag
                  status={TagStatus.Info}
                  text={`${i18n._(t`Spotlengte-index`)}: ${spotLengthIndex}`} // Toon de index van de som van de spotlengtes
                  className={styles.spotLengthTag}
                />
              )}
            </Form.Item>
          </Space>
          <AntForm.ErrorList errors={errors} />
        </div>
      )}
    </AntForm.List>
  );
};

export default SpotLength;
