/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface DateSpan
 */
export interface DateSpan {
  /**
   *
   * @type {Date}
   * @memberof DateSpan
   */
  from: Date;
  /**
   *
   * @type {Date}
   * @memberof DateSpan
   */
  to: Date;
}

export function DateSpanFromJSON(json: any): DateSpan {
  return DateSpanFromJSONTyped(json, false);
}

export function DateSpanFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DateSpan {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    from: new Date(json["from"]),
    to: new Date(json["to"]),
  };
}

export function DateSpanToJSON(value?: DateSpan | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    from: value.from.toISOString(),
    to: value.to.toISOString(),
  };
}
