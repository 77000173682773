import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Divider, Form, formatNumber } from "@ster/ster-toolkit";
import { Alert, Form as AntForm } from "antd";
import { Store } from "antd/lib/form/interface";
import { memo, useCallback, useEffect, useState } from "react";

import { BreakSelection, CalculationResult, Package } from "../../../../api";
import { usePrevious } from "../../../../utils/hooks";
import PriceIndexTooltip from "../../../partials/Tooltips/PriceIndexTooltip/PriceIndexTooltip";
import BreakSelectionInput from "./BreakSelectionInput";
import styles from "./BreakSelectionWithBudget.module.less";
import CalculateGrpBudget from "./CalculateGrpBudget";
import { BreakBudgetProps } from "./models";

const BreakSelectionWithBudget = memo(
  ({
    subOrder,
    medium,
    productId,
    canSelectPreferredPosition,
    preferredPositionSurcharge,
    canSelectBlocks,
    onFinishFailed,
    onChange,
    calculateTimeStamp,
    minGrp,
    spotIndexes,
    packages,
    alreadySelected,
    showBlocksUnavailableMessage,
  }: BreakBudgetProps) => {
    const previousSuborder = usePrevious(subOrder);
    const { i18n } = useLingui();
    const [form] = AntForm.useForm();
    const [isDirty, setIsDirty] = useState<boolean>(false);

    useEffect((): void => {
      // Vul het formulier met de bestaande deelorder
      form.setFieldsValue({
        ...subOrder,
      });

      if (
        subOrder.breakSelection &&
        JSON.stringify(subOrder) !== JSON.stringify(previousSuborder)
      ) {
        // Als er een blokselectie is en het pakket wijzigt wijzigt moeten de blokken geupdated worden
        // Het kan namelijk zijn dat de spotprijzen van de blokken ook wijzigen
        setIsDirty(true);
      }
    }, [form, previousSuborder, subOrder]);

    const handleValuesChange = useCallback(
      (_: unknown, values: Store) => {
        onChange({ ...subOrder, ...values });
      },
      [onChange, subOrder]
    );

    const handleCalculated = useCallback(
      (values: CalculationResult) => {
        handleValuesChange(null, values);
      },
      [handleValuesChange]
    );

    // Bepaal de afwijking tussen het budget en de geselecteerde blokken
    const getBudgetSelectedBreaksRatio = useCallback(() => {
      const selectedBlockPrice =
        subOrder.breakSelection?.reduce(
          (sum, b) => sum + (b._break?.nettSpotPrice ?? 0),
          0
        ) ?? 0;

      const budget = subOrder.budget ?? 0;

      return Math.abs((budget - selectedBlockPrice) / budget);
    }, [subOrder]);

    const handleBreakSelectionChange = useCallback(
      (value: BreakSelection[]) => {
        form.setFieldsValue({ breakSelection: value });
        setIsDirty(false);
      },
      [form]
    );

    const spotLengthIndex = spotIndexes?.find(
      (s) => s.length === subOrder.spotLength?.reduce((a, b) => a + (b ?? 0), 0)
    )?.index;

    const selectedPackage =
      packages.find((p) => p.code === subOrder._package?.code) ??
      ({} as Package);

    const selectedTargetGroup = selectedPackage.targetGroups?.find(
      (g) => g.id === subOrder.targetGroup?.id
    );

    return (
      <Form
        layout="horizontal"
        labelCol={{ span: 4 }}
        onValuesChange={handleValuesChange}
        form={form}
        name={`breakBudget-${subOrder.id}`}
        onFinishFailed={onFinishFailed}
        className={styles.breakBudget}
      >
        <Divider />
        <Form.Item label={i18n._(t`Outputprijs`)}>
          {selectedTargetGroup && subOrder.period && (
            <PriceIndexTooltip
              pckg={selectedPackage}
              targetGroup={selectedTargetGroup}
              spotLengthIndex={spotLengthIndex ?? 0}
              period={subOrder.period}
              size="small"
              medium={medium}
            />
          )}
        </Form.Item>
        <Form.Item label={i18n._(t`Totaalbudget`)}>
          <Form.Item
            name="budget"
            wrapperCol={{ span: 4 }}
            rules={[
              {
                required: true,
                message: i18n._(t`Voer een budget in`),
              },
            ]}
          >
            <CalculateGrpBudget
              subOrder={subOrder}
              medium={medium}
              productId={productId}
              onCalculated={handleCalculated}
              name="budget"
              calculateTimeStamp={calculateTimeStamp}
              id="budgetBreakSelection"
            />
          </Form.Item>
        </Form.Item>
        <Form.Item label={i18n._(t`GRP's`)}>
          <span>{subOrder.grp ? formatNumber(subOrder.grp, 2) : ""}</span>
        </Form.Item>
        {canSelectBlocks && (
          <>
            <BreakSelectionInput
              canSelectPreferredPosition={canSelectPreferredPosition}
              preferredPositionSurcharge={preferredPositionSurcharge}
              handleBreakSelectionChange={handleBreakSelectionChange}
              medium={medium}
              subOrder={subOrder}
              productId={productId}
              selectionRequired={!subOrder._package?.isYourTime}
              isDirty={isDirty}
              alreadySelected={alreadySelected}
            />
            {(subOrder.grp ?? 0) < (minGrp ?? 0) && (
              <Form.Item
                wrapperCol={{ offset: 4, span: 14 }}
                name="grpWarning"
                rules={[
                  {
                    validator: (): Promise<void> =>
                      Promise.reject(
                        new Error(
                          i18n._(
                            t`Je pakket voldoet niet aan het minimale aantal GRP's.`
                          )
                        )
                      ),
                  },
                ]}
              >
                <Alert
                  showIcon
                  type="warning"
                  message=""
                  description={i18n._(
                    t`Je dient minimaal ${minGrp} GRP's af te nemen`
                  )}
                />
              </Form.Item>
            )}
            {getBudgetSelectedBreaksRatio() > 0.15 && (
              <Form.Item wrapperCol={{ offset: 4, span: 14 }}>
                <Alert
                  showIcon
                  type="warning"
                  message=""
                  description={i18n._(
                    t`De waarde van de blokselectie wijkt af van
                      het totaalbudget. Je kan nog een blok bij- of afboeken of het
                      totaalbudget aanpassen.`
                  )}
                />
              </Form.Item>
            )}
          </>
        )}
        {showBlocksUnavailableMessage && (
          <Alert
            showIcon
            type="warning"
            message=""
            description={
              <Trans>
                Op dit moment kun je nog geen blokken koppelen, dit kan pas
                zodra de periode is vrijgegeven door Ster.
              </Trans>
            }
          />
        )}
      </Form>
    );
  }
);

export default BreakSelectionWithBudget;
