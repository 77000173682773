/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ValueList,
  ValueListFromJSON,
  ValueListFromJSONTyped,
  ValueListToJSON,
} from "./";

/**
 *
 * @export
 * @interface ValueListIEnumerableValueResult
 */
export interface ValueListIEnumerableValueResult {
  /**
   *
   * @type {string}
   * @memberof ValueListIEnumerableValueResult
   */
  name?: string | null;
  /**
   *
   * @type {Array<ValueList>}
   * @memberof ValueListIEnumerableValueResult
   */
  data?: Array<ValueList> | null;
}

export function ValueListIEnumerableValueResultFromJSON(
  json: any
): ValueListIEnumerableValueResult {
  return ValueListIEnumerableValueResultFromJSONTyped(json, false);
}

export function ValueListIEnumerableValueResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ValueListIEnumerableValueResult {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, "name") ? undefined : json["name"],
    data: !exists(json, "data")
      ? undefined
      : json["data"] === null
        ? null
        : (json["data"] as Array<any>).map(ValueListFromJSON),
  };
}

export function ValueListIEnumerableValueResultToJSON(
  value?: ValueListIEnumerableValueResult | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    data:
      value.data === undefined
        ? undefined
        : value.data === null
          ? null
          : (value.data as Array<any>).map(ValueListToJSON),
  };
}
