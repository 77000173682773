/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  OnlinePackageChoice,
  OnlinePackageChoiceFromJSON,
  OnlinePackageChoiceFromJSONTyped,
  OnlinePackageChoiceToJSON,
} from "./";

/**
 *
 * @export
 * @interface SubOrderListItem
 */
export interface SubOrderListItem {
  /**
   *
   * @type {number}
   * @memberof SubOrderListItem
   */
  id: number;
  /**
   *
   * @type {OnlinePackageChoice}
   * @memberof SubOrderListItem
   */
  onlinePackageChoice: OnlinePackageChoice;
}

export function SubOrderListItemFromJSON(json: any): SubOrderListItem {
  return SubOrderListItemFromJSONTyped(json, false);
}

export function SubOrderListItemFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SubOrderListItem {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    onlinePackageChoice: OnlinePackageChoiceFromJSON(
      json["onlinePackageChoice"]
    ),
  };
}

export function SubOrderListItemToJSON(value?: SubOrderListItem | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    onlinePackageChoice: OnlinePackageChoiceToJSON(value.onlinePackageChoice),
  };
}
