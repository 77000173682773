import "../styles.less";
import "./documentcard.less";

import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  CollapseTillBreakpoint,
  Icons,
  Typography,
  formatDate,
} from "@ster/ster-toolkit";
import { App as AntApp } from "antd";
import moment from "moment";
import { useCallback } from "react";

import { Document } from "../../../../api/models/Document";
import { klantportalAdverteerder } from "../../../../utils/constants";
import CardStatistic from "../CardStatistic";
import FiletypeIcon from "./FiletypeIcon";

interface DocumentCardProps {
  cardData: Document;
  handleDeleteDocument?: (value: string) => void;
  editable: boolean;
  showAllDetails: boolean;
}

const DocumentCard = ({
  cardData,
  handleDeleteDocument,
  editable,
  showAllDetails,
}: DocumentCardProps) => {
  const {
    organisation,
    advertiserName,
    publicationEnd,
    lastModified,
    uploadedBy,
    filename,
    guid,
    type,
  } = cardData;

  const { Title } = Typography;
  const { DeleteIcon } = Icons;

  const formattedDate = moment(lastModified, "YYYYMMDD").fromNow();
  const formattedEndDate = publicationEnd
    ? formatDate(publicationEnd)
    : "Altijd";

  const { i18n } = useLingui();
  const { modal } = AntApp.useApp();
  const deleteDocument = useCallback(() => {
    modal.confirm({
      title: i18n._(t`Weet je het zeker?`),
      content: (
        <Typography.Paragraph>
          {i18n._(t`Weet je zeker dat je het document wil verwijderen?`)}
        </Typography.Paragraph>
      ),
      onOk: () => {
        if (handleDeleteDocument) {
          handleDeleteDocument(guid);
        }
      },
      cancelText: i18n._(t`Nee`),
      okText: i18n._(t`Ja`),
    });
  }, [guid, handleDeleteDocument, i18n, modal]);

  return (
    <article className="card card--document">
      <header className="card-header">
        <div className="card-header__icon">
          <FiletypeIcon medium={type} />
        </div>
        {editable && (
          <div className="card-header__menu">
            <button
              type="button"
              className="card-header__delete-btn"
              onClick={deleteDocument}
            >
              <DeleteIcon />
            </button>
          </div>
        )}
      </header>
      <div className="card-body">
        <Title level={2} className="card-title">
          <a
            href={`/api/v1/document/${guid}`}
            className="card-title__link"
            title={i18n._(t`Open het document`)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {filename}
          </a>
        </Title>
        <footer>
          <div className="card-body__data-wrapper">
            <CardStatistic label={i18n._(t`Geplaatst`)} value={formattedDate} />
            <CollapseTillBreakpoint value={576}>
              {uploadedBy.userName && (
                <CardStatistic
                  label={i18n._(t`Door`)}
                  value={uploadedBy.userName}
                />
              )}
              <CardStatistic
                label={i18n._(t`Beschikbaar tot`)}
                value={formattedEndDate}
              />
              {showAllDetails && (
                <CardStatistic
                  label={i18n._(t`Organisatie`)}
                  value={organisation}
                />
              )}
              {advertiserName &&
                (showAllDetails || // Verberg de adverteerdersnaam voor directe adverteerders
                  organisation !== klantportalAdverteerder) && (
                  <CardStatistic
                    label={i18n._(t`Adverteerder`)}
                    value={advertiserName}
                  />
                )}
            </CollapseTillBreakpoint>
          </div>
        </footer>
      </div>
    </article>
  );
};

export default DocumentCard;
