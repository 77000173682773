/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum MediumEnum {
  Tv = "tv",
  Radio = "radio",
  Inter = "inter",
}

export function MediumEnumFromJSON(json: any): MediumEnum {
  return MediumEnumFromJSONTyped(json, false);
}

export function MediumEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MediumEnum {
  return json as MediumEnum;
}

export function MediumEnumToJSON(value?: MediumEnum | null): any {
  return value as any;
}
