import "./SpotAnalysis.less";

import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Container,
  ContentContainer,
  Icons,
  Pageheader,
  Row,
  Typography,
} from "@ster/ster-toolkit";
import { Col } from "antd";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import PackageCard from "../partials/Cards/Package/PackageCard";

const Analysis = () => {
  const { i18n } = useLingui();
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>{i18n._(t`Analyses`)}</title>
      </Helmet>

      <Pageheader
        title={<Trans>Analyses</Trans>}
        icon={
          <Icons.CampaignIcon
            width="100%"
            height="100%"
            fill="rgba(129, 176, 210, 0.2)"
          />
        }
      />

      <ContentContainer className="content">
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Container>
              <Typography.Paragraph>
                <Trans>
                  Met de analyses binnen onze Ster Klantportal krijg je meer
                  inzicht in het bereik van jouw radio- en televisiecampagnes.
                  Dankzij het gebruik van een externe databron (BRON) krijg jij
                  een analyse van je totale campagne, niet alleen het deel dat
                  bij Ster gelopen heeft.
                </Trans>
              </Typography.Paragraph>
            </Container>
          </Col>
        </Row>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Container>
              <Row
                gutter={[0, 24]}
                style={{
                  justifyContent: "center",
                  placeContent: "space-evenly",
                }}
              >
                <Col>
                  <PackageCard
                    icon={<Icons.CampaignIcon fill="#FF6D00" />}
                    title={i18n._(t`Campagneanalyse`)}
                    text={
                      <Typography.Paragraph>
                        <Trans>
                          Een campagneanalyse geeft je – op basis van gewenste
                          doelgroepen en periode – inzicht in de bereikcijfers
                          van je campagne.
                        </Trans>
                      </Typography.Paragraph>
                    }
                    button={{
                      label: i18n._(t`Start analyse`),
                      onClick: () => {
                        navigate("/analysis/campaign");
                      },
                    }}
                  />
                </Col>
                <Col>
                  <PackageCard
                    icon={<Icons.StatsIcon fill="#FF6D00" />}
                    title={i18n._(t`Spotanalyse`)}
                    text={
                      <Typography.Paragraph>
                        <Trans>
                          Met onze spotanalyse krijg je een compleet overzicht
                          van het bruto bereik van alle uitgezonden spotjes op
                          de doelgroep die je selecteert.
                        </Trans>
                      </Typography.Paragraph>
                    }
                    button={{
                      label: i18n._(t`Start analyse`),
                      onClick: () => {
                        navigate("/analysis/spot");
                      },
                    }}
                  />
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </ContentContainer>
    </>
  );
};

export default Analysis;
