import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Checkbox, Form, Modal, Typography } from "@ster/ster-toolkit";
import { Form as AntDForm } from "antd";
import { memo } from "react";
import { useDispatch } from "react-redux";

import { postTermsAction } from "../../store/terms/actions";
import styles from "./TermsModal.module.less";

const TermsModal = memo(({ dateOfTerms }: { dateOfTerms: Date }) => {
  const { i18n } = useLingui();
  const [form] = AntDForm.useForm();
  const dispatch = useDispatch();

  return (
    <Modal
      title={i18n._(t`Ga je akkoord?`)}
      open
      forceRender
      className={styles.termsModal}
      footer={null /* remove default buttons */}
    >
      <Trans>
        <Typography.Paragraph>
          Om de Ster Klantportal te kunnen gebruiken dien je onze{" "}
          <a
            href="https://www.ster.nl/voorwaarden/klantportal/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Gebruiksvoorwaarden Klantportal
          </a>{" "}
          te accepteren. Lees de Gebruiksvoorwaarden zorgvuldig door voordat je
          deze aanvaardt.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Voor het aanmaken van jouw gebruikersaccount heb je persoonsgegevens
          aan Ster verstrekt, zoals je naam, zakelijk e-mailadres en
          telefoonnummer. Ster wenst deze gegevens te gebruiken voor de
          uitvoering van de overeenkomst, relatiebeheer, het verbeteren van de
          via de Ster Klantportal beschikbaar gestelde services en om je per
          e-mail te informeren over voor jou relevante diensten en
          inkoopmogelijkheden. Zie voor meer informatie ons{" "}
          <a
            href="https://www.ster.nl/privacystatement#klantportal"
            rel="noopener noreferrer"
            target="_blank"
          >
            Privacybeleid Ster Klantportal
          </a>
          .
        </Typography.Paragraph>
      </Trans>

      <Form
        form={form}
        initialValues={{
          agreed: false,
          privacy: false,
          canUseForMailing: false,
          canUseForMarketing: false,
        }}
        className={styles.termsForm}
        onFinish={(values): void => {
          dispatch(
            postTermsAction.request({
              termsAndConditionsModel: {
                dateOfTerms,
                canUseForMailing: values.canUseForMailing,
                canUseForMarketing: values.canUseForMarketing,
              },
            })
          );
        }}
      >
        <Form.Item
          name="agreed"
          valuePropName="checked"
          rules={[
            {
              required: true,
              validator: async (rule, value): Promise<void> => {
                if (value !== true) {
                  throw new Error(i18n._(t`Verplichte voorwaarde`));
                }
              },
            },
          ]}
        >
          <Checkbox type="checkbox">
            <Trans>
              Ja, ik ga akkoord met de{" "}
              <a
                href="https://www.ster.nl/voorwaarden/klantportal/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Gebruiksvoorwaarden Klantportal
              </a>{" "}
              <strong>(verplicht)</strong>
            </Trans>
          </Checkbox>
        </Form.Item>
        <Form.Item
          name="privacy"
          valuePropName="checked"
          rules={[
            {
              required: true,
              validator: async (rule, value): Promise<void> => {
                if (value !== true) {
                  throw new Error(i18n._(t`Verplichte voorwaarde`));
                }
              },
            },
          ]}
        >
          <Checkbox type="checkbox">
            <Trans>
              Ja, ik ga akkoord met het{" "}
              <a
                href="https://www.ster.nl/privacystatement#klantportal"
                rel="noopener noreferrer"
                target="_blank"
              >
                Privacybeleid Ster Klantportal
              </a>{" "}
              en de daar in genoemde verwerkingsdoeleinden{" "}
              <strong>(verplicht)</strong>
            </Trans>
          </Checkbox>
        </Form.Item>
        <Form.Item name="canUseForMailing" valuePropName="checked">
          <Checkbox type="checkbox">
            <Trans>
              Ja, ik wil graag de maandelijkse nieuwsbrief van Ster per e-mail
              ontvangen (optioneel)
            </Trans>
          </Checkbox>
        </Form.Item>
        <Form.Item name="canUseForMarketing" valuePropName="checked">
          <Checkbox type="checkbox">
            <Trans>
              Ja, ik wil graag relevante inkoopmogelijkheden bij Ster en
              uitnodigingen voor relatie-evenementen per e-mail ontvangen
              (optioneel)
            </Trans>
          </Checkbox>
        </Form.Item>
        <Form.Item shouldUpdate className={styles.buttonLayout}>
          {(): React.ReactElement => (
            <Button
              mode="primary"
              htmlType="submit"
              disabled={
                !(form.getFieldValue("agreed") && form.getFieldValue("privacy"))
              }
            >
              <Trans>Ik ga akkoord</Trans>
            </Button>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default TermsModal;
