import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

import { SpotTrackerApi } from "../../api";
import apiConfig from "../../apiConfig";
import { toError } from "../utils";
import { exportSpotTrackerAction, receiveSpotTrackerAction } from "./actions";

export function* receiveSpotTracker(
  action: ReturnType<typeof receiveSpotTrackerAction.request>
): SagaIterator {
  try {
    const api = new SpotTrackerApi(apiConfig());
    const response = yield call(() => api.apiV1SpotTrackerPost(action.payload));
    yield put(receiveSpotTrackerAction.success(response));
  } catch (err) {
    yield put(receiveSpotTrackerAction.failure(toError(err)));
  }
}

export function* exportSpotTracker(
  action: ReturnType<typeof exportSpotTrackerAction.request>
): SagaIterator {
  try {
    const api = new SpotTrackerApi(apiConfig());
    const response = yield call(() =>
      api.apiV1SpotTrackerExportPost(action.payload)
    );
    yield put(exportSpotTrackerAction.success(response));
  } catch (err) {
    yield put(exportSpotTrackerAction.failure(toError(err)));
  }
}
