import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Modal, Spinner, Typography } from "@ster/ster-toolkit";
import { Alert, App as AntApp, Space } from "antd";
import { isBefore } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  AnalysisTargetGroupRadio,
  AnalysisTargetGroupTV,
  MediumEnum,
} from "../../../../api";
import { ReduxStoreState } from "../../../../store/base";
import {
  clearCampaignAnalysisAction,
  clearRelatedCampaignsAction,
  receiveCampaignAnalysisTargetGroupsAction,
  requestCampaignAnalysisAction,
  requestRelatedCampaignsAction,
} from "../../../../store/campaignDetail/actions";
import {
  CampaignAnalysisInput,
  CampaignAnalysisOrder,
} from "../../../../store/campaignDetail/models";
import { StoreModel } from "../../../../store/models";
import {
  getFormattedDate,
  isSameOrAfterDay,
} from "../../../../utils/dateHelper";
import { useCampaignAnalysisDisabled } from "./CampaignAnalysisHooks";
import CampaignAnalysisTargetGroups from "./CampaignAnalysisTargetGroups";
import CampaignAnalyisText from "./CampaignAnalysisText";
import RelatedCampaings from "./RelatedCampaings";

export interface CampaignAnalysisProps {
  order: CampaignAnalysisOrder;
  medium: MediumEnum;
  targetGroupIds?: string[];
  nmoSwitchDate: Date;
}

const emptyAnalysisInput = {} as CampaignAnalysisInput;

const CampaignAnalysis = ({
  order: { id: orderId },
  order,
  medium,
  targetGroupIds,
  nmoSwitchDate,
}: CampaignAnalysisProps) => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { message } = AntApp.useApp();

  /**
   * Input for campaign analysis
   */
  const [input, setInput] = useState<CampaignAnalysisInput>(emptyAnalysisInput);
  const initialInput: CampaignAnalysisInput = useMemo(
    () => ({
      orderId,
      medium,
      analysisInput: {
        relatedOrderIds: [],
        secondaryTargetGroups: {
          tv: [
            {
              age: { min: 25, max: 67 },
              gender: "X",
            },
          ],
          radio: [
            {
              age: { min: 25, max: 67 },
              gender: "X",
            },
          ],
        },
      },
    }),
    [orderId, medium]
  );
  useEffect(() => {
    setInput(initialInput);
  }, [initialInput, medium, orderId, targetGroupIds]);

  /**
   * Dialog methods
   */
  const [open, setOpen] = useState(searchParams.get("analysis") !== null);
  const openDialog = useCallback(() => {
    setOpen(true);
  }, []);
  const closeDialog = useCallback(() => {
    setOpen(false);
    dispatch(clearRelatedCampaignsAction());
    setInput(initialInput);
  }, [dispatch, initialInput]);

  const disabled = useCampaignAnalysisDisabled(order);

  const handleChangeRelatedOrderId = useCallback(
    (value: number | undefined) => {
      const relatedOrderIds = [value].filter((id) => !!id) as number[];
      setInput({
        ...input,
        analysisInput: { ...input.analysisInput, relatedOrderIds },
      });
    },
    [input]
  );

  const handleChangeTargetGroup = useCallback(
    (targetGroups: AnalysisTargetGroupTV[] | AnalysisTargetGroupRadio[]) => {
      setInput({
        ...input,
        analysisInput: {
          ...input.analysisInput,
          secondaryTargetGroups: {
            tv: medium === MediumEnum.Tv ? targetGroups : [],
            radio: medium === MediumEnum.Radio ? targetGroups : [],
          },
        },
      });
    },
    [input, medium]
  );

  const handleRequestCampaignAnalysis = useCallback(() => {
    dispatch(requestCampaignAnalysisAction.request(input));
    closeDialog();
  }, [input, closeDialog, dispatch]);

  const { loading, state: loadingState } = useSelector(
    (store: StoreModel) => store.campaignAnalysis
  );

  const campaignAnalysisTargetGroups = useSelector(
    (store: StoreModel) => store.campaignAnalysisTargetGroups
  );

  useEffect(() => {
    if (
      !open ||
      campaignAnalysisTargetGroups.state !== ReduxStoreState.Initial
    ) {
      return;
    }

    dispatch(receiveCampaignAnalysisTargetGroupsAction.request());
  }, [campaignAnalysisTargetGroups.state, dispatch, open]);

  useEffect(() => {
    switch (loadingState) {
      case ReduxStoreState.Success:
        message.success({
          className: "succes",
          content: i18n._(
            t`De aanvraag voor je analyse is ontvangen en wordt behandeld.`
          ),
          duration: 10,
        });
        dispatch(clearCampaignAnalysisAction()); // reset
        break;
      case ReduxStoreState.Failure:
        message.error(
          i18n._(t`Er ging iets mis bij het aanvragen van de analyse.`)
        );
        break;
      default:
        break;
    }
  }, [dispatch, i18n, loadingState, message]);

  const relatedCampaigns = useSelector(
    (store: StoreModel) => store.relatedCampaigns
  );

  useEffect(() => {
    if (!open || relatedCampaigns.state !== ReduxStoreState.Initial) {
      return;
    }

    dispatch(requestRelatedCampaignsAction.request({ orderId, medium }));
  }, [dispatch, medium, open, orderId, relatedCampaigns.state]);

  const showNmoSplitNotification = useMemo(
    () =>
      medium === MediumEnum.Tv &&
      isBefore(order.startDate, nmoSwitchDate) &&
      isSameOrAfterDay(order.endDate, nmoSwitchDate),
    [medium, nmoSwitchDate, order.endDate, order.startDate]
  );

  return (
    <>
      {order.analysis && open && (
        <Modal
          open
          title="Campagneanalyse"
          onCancel={closeDialog}
          onOk={handleRequestCampaignAnalysis}
          okButtonProps={{ disabled: loading }}
          maskClosable={false}
          style={{ maxWidth: 750 }}
        >
          <Spinner spinning={loading || relatedCampaigns.loading}>
            <Space direction="vertical">
              <Typography.Paragraph>
                <Trans>
                  Wil je een analyse ontvangen voor deze campagne?
                  <br /> Klik dan op de OK-button. Je ontvangt de analyse dan
                  per e-mail.
                  <br /> Ook kun je je campagneanalyse altijd terugvinden in het
                  documenten-overzicht in de Klantportal.
                </Trans>
              </Typography.Paragraph>

              {showNmoSplitNotification && (
                <Alert
                  showIcon
                  type="warning"
                  message=""
                  description={
                    <Trans>
                      Op {getFormattedDate(nmoSwitchDate, "PPP")} start het
                      nieuwe kijkonderzoek van NMO. Daarom is het mogelijk dat
                      de campagneanalyse voor deze campagne opgesplitst wordt in
                      twee delen, namelijk voor en vanaf{" "}
                      {getFormattedDate(nmoSwitchDate, "PPP")}. Je ontvangt dus
                      twee analyses.
                    </Trans>
                  }
                  style={{ marginBottom: 16 }}
                />
              )}

              {relatedCampaigns.related &&
                relatedCampaigns.related.length > 0 && (
                  <RelatedCampaings
                    value={input.analysisInput?.relatedOrderIds?.[0]}
                    self={order}
                    related={relatedCampaigns.related}
                    onChange={handleChangeRelatedOrderId}
                  />
                )}

              <CampaignAnalysisTargetGroups
                value={
                  (medium === MediumEnum.Tv
                    ? input.analysisInput?.secondaryTargetGroups?.tv
                    : input.analysisInput?.secondaryTargetGroups?.radio) ?? []
                }
                targetGroups={
                  medium === MediumEnum.Tv
                    ? campaignAnalysisTargetGroups?.tv
                    : campaignAnalysisTargetGroups?.radio
                }
                onChange={handleChangeTargetGroup}
              />
            </Space>
          </Spinner>
        </Modal>
      )}
      <Button
        mode="primary"
        onClick={openDialog}
        disabled={disabled}
        loading={loading}
        className="analysis-btn"
      >
        <CampaignAnalyisText analysis={order.analysis} />
      </Button>
    </>
  );
};

export default CampaignAnalysis;
