/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  DocumentUser,
  DocumentUserFromJSON,
  DocumentUserFromJSONTyped,
  DocumentUserToJSON,
} from "./";

/**
 *
 * @export
 * @interface Document
 */
export interface Document {
  /**
   *
   * @type {string}
   * @memberof Document
   */
  organisation: string;
  /**
   *
   * @type {number}
   * @memberof Document
   */
  advertiser?: number | null;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  advertiserName?: string | null;
  /**
   *
   * @type {Date}
   * @memberof Document
   */
  publicationEnd?: Date | null;
  /**
   *
   * @type {Date}
   * @memberof Document
   */
  publicationStart: Date;
  /**
   *
   * @type {DocumentUser}
   * @memberof Document
   */
  uploadedBy: DocumentUser;
  /**
   *
   * @type {Date}
   * @memberof Document
   */
  lastModified: Date;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  filename: string;
  /**
   *
   * @type {number}
   * @memberof Document
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  guid: string;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  readonly type: string;
}

export function DocumentFromJSON(json: any): Document {
  return DocumentFromJSONTyped(json, false);
}

export function DocumentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Document {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    organisation: json["organisation"],
    advertiser: !exists(json, "advertiser") ? undefined : json["advertiser"],
    advertiserName: !exists(json, "advertiserName")
      ? undefined
      : json["advertiserName"],
    publicationEnd: !exists(json, "publicationEnd")
      ? undefined
      : json["publicationEnd"] === null
        ? null
        : new Date(json["publicationEnd"]),
    publicationStart: new Date(json["publicationStart"]),
    uploadedBy: DocumentUserFromJSON(json["uploadedBy"]),
    lastModified: new Date(json["lastModified"]),
    filename: json["filename"],
    id: json["id"],
    guid: json["guid"],
    type: json["type"],
  };
}

export function DocumentToJSON(value?: Document | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    organisation: value.organisation,
    advertiser: value.advertiser,
    advertiserName: value.advertiserName,
    publicationEnd:
      value.publicationEnd === undefined
        ? undefined
        : value.publicationEnd === null
          ? null
          : value.publicationEnd.toISOString(),
    publicationStart: value.publicationStart.toISOString(),
    uploadedBy: DocumentUserToJSON(value.uploadedBy),
    lastModified: value.lastModified.toISOString(),
    filename: value.filename,
    id: value.id,
    guid: value.guid,
  };
}
