/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Banner,
  BannerFromJSON,
  BannerFromJSONTyped,
  BannerToJSON,
  Commercial,
  CommercialFromJSON,
  CommercialFromJSONTyped,
  CommercialToJSON,
} from "./";

/**
 *
 * @export
 * @interface OnlineInstructionSpots
 */
export interface OnlineInstructionSpots {
  /**
   *
   * @type {number}
   * @memberof OnlineInstructionSpots
   */
  id: number;
  /**
   *
   * @type {Commercial}
   * @memberof OnlineInstructionSpots
   */
  commercial?: Commercial;
  /**
   *
   * @type {Banner}
   * @memberof OnlineInstructionSpots
   */
  banner?: Banner;
  /**
   *
   * @type {string}
   * @memberof OnlineInstructionSpots
   */
  homepage?: string | null;
}

export function OnlineInstructionSpotsFromJSON(
  json: any
): OnlineInstructionSpots {
  return OnlineInstructionSpotsFromJSONTyped(json, false);
}

export function OnlineInstructionSpotsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OnlineInstructionSpots {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    commercial: !exists(json, "commercial")
      ? undefined
      : CommercialFromJSON(json["commercial"]),
    banner: !exists(json, "banner")
      ? undefined
      : BannerFromJSON(json["banner"]),
    homepage: !exists(json, "homepage") ? undefined : json["homepage"],
  };
}

export function OnlineInstructionSpotsToJSON(
  value?: OnlineInstructionSpots | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    commercial: CommercialToJSON(value.commercial),
    banner: BannerToJSON(value.banner),
    homepage: value.homepage,
  };
}
