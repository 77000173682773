import { max } from "date-fns";
import moment from "moment";
import { createSelector, createStructuredSelector } from "reselect";

import {
  MediumEnum,
  OrderRequest,
  OrderStatus,
  SalesPeriod,
  SalesPeriodResult,
} from "../../api";
import {
  bookingDateSelector,
  initialRequestDetailSelector,
  orderRequestSelector,
  salesPeriodSelector,
  salesPeriodsFromStoreSelector,
  settingsSelector,
} from "../../shared/selectors";
import { ReduxStoreState } from "../../store/base";
import {
  BookingDateMapped,
  LoadingCustomerAccounts,
  LoadingCustomerContacts,
  LoadingCustomerOpportunities,
} from "../../store/campaignCreate/models";
import {
  LoadingInitialRequest,
  LoadingPortalUser,
  LoadingProducts,
  LoadingSalesPeriods,
  LoadingValidateCampaign,
  StoreModel,
} from "../../store/models";
import { instructionsLoadingSelector } from "../campaignDetail/selectors";
import { InitialRequestState } from "./models";

export const accountSelector = ({ account }: StoreModel): LoadingPortalUser =>
  account;

export const productsFromStoreSelector = ({
  products,
}: StoreModel): LoadingProducts => products;

export const productSelector = createSelector(
  [productsFromStoreSelector],
  (products: LoadingProducts) => products?.products ?? []
);

const stateSelector = createSelector(
  [initialRequestDetailSelector],
  (initialRequest: LoadingInitialRequest | undefined | null) =>
    initialRequest?.state ?? ReduxStoreState.Initial
);

export const validateCampaignFromStoreSelector = ({
  validateCampaign,
}: StoreModel): LoadingValidateCampaign => validateCampaign;

export const validateCampaignSelector = createSelector(
  [validateCampaignFromStoreSelector],
  (validateCampaign: LoadingValidateCampaign) => validateCampaign.valid
);

const loadingValidateCampaignSelector = createSelector(
  [validateCampaignFromStoreSelector],
  (validateCampaign: LoadingValidateCampaign): boolean =>
    validateCampaign?.loading ?? false
);

const loadingSelector = createSelector(
  [
    salesPeriodsFromStoreSelector,
    productsFromStoreSelector,
    initialRequestDetailSelector,
    (state: StoreModel) => state.saveMultiCampaignInstructions,
    (state: StoreModel) => state.deleteInstruction,
    (state: StoreModel) => state.proposalGenerate,
  ],
  (
    salesPeriods: LoadingSalesPeriods,
    products: LoadingProducts,
    initialRequest: LoadingInitialRequest | undefined | null,
    multiInstructions,
    deleteInstructions,
    proposalGenerate
  ): boolean =>
    (salesPeriods.loading ?? false) ||
    (products.loading ?? false) ||
    (initialRequest?.loading ?? false) ||
    (multiInstructions?.loading ?? false) ||
    (deleteInstructions?.loading ?? false) ||
    (proposalGenerate?.loading ?? false)
);

const loadingFinalizeSelector = createSelector(
  [initialRequestDetailSelector],
  (initialRequest: LoadingInitialRequest | undefined | null): boolean =>
    initialRequest?.loading ?? false
);

const selectedSalesPeriodSelector = createSelector(
  [salesPeriodSelector, orderRequestSelector, bookingDateSelector],
  (
    salesPeriods: SalesPeriodResult[],
    initialRequest: OrderRequest | undefined,
    bookingDate: BookingDateMapped
  ): SalesPeriod | undefined => {
    if (!initialRequest?.period) {
      return undefined;
    }

    const foundSalesPeriod =
      initialRequest.medium === MediumEnum.Inter
        ? // Voor internet geldt dat de periode van de campagne valt binnen de verkoopperiode
          salesPeriods
            .find((s) => s.medium === initialRequest.medium)
            ?.result.find(
              (s) =>
                s.salesPeriodStartDate <=
                  max([
                    initialRequest.period.from,
                    bookingDate.date ?? new Date(),
                  ]) && // Als de startdatum van de campagne voor de boekingsdatum ligt, dan nemen we de boekingsdatum
                s.salesPeriodEndDate >= initialRequest.period.to
            )
        : salesPeriods
            .find((s) => s.medium === initialRequest.medium)
            ?.result.find(
              (s) =>
                s.salesPeriodStartDate <= initialRequest.period.to &&
                s.salesPeriodEndDate >= initialRequest.period.from
            );

    return (
      foundSalesPeriod ??
      // Als de verkoopperiode onbekend is nemen we de periode van de campagne over
      ({
        salesPeriodStartDate: initialRequest.period.from,
        salesPeriodEndDate: initialRequest.period.to,
        requestStartDate: new Date(1, 1, 1),
        requestEndDate: new Date(1, 1, 1),
        canRequest: false,
      } as SalesPeriod)
    );
  }
);

const initialRequestStateSelector = createSelector(
  [orderRequestSelector, bookingDateSelector, selectedSalesPeriodSelector],
  (
    initialRequest: OrderRequest | undefined,
    bookingDate: BookingDateMapped,
    selectedSalesPeriod: SalesPeriod | undefined
  ): InitialRequestState | undefined => {
    if (!initialRequest || !initialRequest.productId) {
      return {
        canEdit: true,
        canFinalize: false,
        canDelete: false,
        isConcept: true,
        subOrdersBeforeFrozenPeriod: false,
        periodOpenForRequest: true,
      };
    }

    const isConcept =
      (initialRequest.requestStatus ?? OrderStatus.Concept) ===
      OrderStatus.Concept;
    const isSubmitted = initialRequest.requestStatus === OrderStatus.Submitted;
    const isBeforeRequestEndDate = selectedSalesPeriod?.requestEndDate
      ? moment().isBefore(selectedSalesPeriod.requestEndDate)
      : false;

    const periodOpenForRequest = selectedSalesPeriod?.canRequest ?? true;

    const minSubOrderStartDate =
      (initialRequest.subOrders ?? []).filter((s) => s.period?.from).length > 0
        ? Math.min(
            ...(initialRequest.subOrders ?? [])
              .filter((a) => a.period?.from)
              .map((a) => Number(a.period.from))
          )
        : moment(bookingDate.date);

    // Is de order nog te bewerken?
    // voor TV:    status 'Concept' of 'InBehandeling' en dan mag je nog
    //             bewerken, mits einde aanvraagperiode nog niet is bereikt
    // voor RADIO: status 'Concept'
    const canEdit =
      (initialRequest.medium === MediumEnum.Tv
        ? isConcept || (isSubmitted && isBeforeRequestEndDate)
        : isConcept) &&
      moment(initialRequest.period.to).isSameOrAfter(
        minSubOrderStartDate,
        "day"
      );

    const afterFrozenPeriod = minSubOrderStartDate
      ? moment(minSubOrderStartDate).isSameOrAfter(
          moment(bookingDate.date),
          "day"
        )
      : true;

    // Is de order in te dienen? Dan moet hij nog te bewerken zijn, moeten alle deelorders na de minimale boekingsdatum liggen
    // En mag hij nog niet ingediend zijn
    const canFinalize =
      canEdit && afterFrozenPeriod && !isSubmitted && periodOpenForRequest;

    const subOrdersBeforeFrozenPeriod =
      (initialRequest.subOrders?.length ?? 0) > 0 &&
      !afterFrozenPeriod &&
      isConcept;

    const canDelete =
      initialRequest?.requestStatus === OrderStatus.Concept &&
      (initialRequest?.id ?? 0) < 0;

    return {
      canEdit,
      canFinalize,
      canDelete,
      isConcept,
      subOrdersBeforeFrozenPeriod,
      periodOpenForRequest,
    };
  }
);

export const customerAccountsFromStoreSelector = ({
  customer: { accounts },
}: StoreModel): LoadingCustomerAccounts => accounts;

export const customerAccountsSelector = createSelector(
  [customerAccountsFromStoreSelector],
  (customerAccounts: LoadingCustomerAccounts) =>
    (customerAccounts.data ?? []).sort(
      (a, b) =>
        // First sort on #, then on name
        Number((a.name ?? "").startsWith("#")) -
          Number((b.name ?? "").startsWith("#")) ||
        (a.name ?? "").localeCompare(b.name ?? "")
    )
);

export const customerOpportunitiesFromStoreSelector = ({
  customer: { opportunities },
}: StoreModel): LoadingCustomerOpportunities => opportunities;

export const customerContactsFromStoreSelector = ({
  customer: { contacts },
}: StoreModel): LoadingCustomerContacts => contacts;

export const customerOpportunitiesSelector = createSelector(
  [customerOpportunitiesFromStoreSelector],
  (customerOpportunities: LoadingCustomerOpportunities) =>
    customerOpportunities.data ?? []
);

export const customerContactsSelector = createSelector(
  [customerContactsFromStoreSelector],
  (contacts: LoadingCustomerContacts) => contacts.data ?? []
);

const customerAccountsStateSelector = createSelector(
  [customerAccountsFromStoreSelector],
  (customerAccounts: LoadingCustomerAccounts): ReduxStoreState =>
    customerAccounts?.state
);

const customerOpportunitiesStateSelector = createSelector(
  [customerOpportunitiesFromStoreSelector],
  (customerOpportunities: LoadingCustomerOpportunities): ReduxStoreState =>
    customerOpportunities?.state
);

const customerContactsStateSelector = createSelector(
  [customerContactsFromStoreSelector],
  (contacts: LoadingCustomerContacts): ReduxStoreState => contacts?.state
);

export const campaignCreateRootSelector = createStructuredSelector({
  salesPeriods: salesPeriodSelector,
  productsByAdvertiser: productSelector,
  initialRequestState: stateSelector,
  loading: loadingSelector,
  bookingDate: bookingDateSelector,
  account: accountSelector,
  settings: settingsSelector,
});

export const campaignCreateStateSelector = createStructuredSelector({
  initialRequestState: initialRequestStateSelector,
  selectedSalesPeriod: selectedSalesPeriodSelector,
});

export const finalizeModalSelector = createStructuredSelector({
  account: accountSelector,
  loadingRequest: loadingFinalizeSelector,
  loadingInstructions: instructionsLoadingSelector,
  validCampaign: validateCampaignSelector,
  loadingValidateCampaign: loadingValidateCampaignSelector,
});

export const campaignCreateDetailSelector = createStructuredSelector({
  customerAccounts: customerAccountsSelector,
  customerAccountsState: customerAccountsStateSelector,
  customerOpportunities: customerOpportunitiesSelector,
  customerOpportunitiesState: customerOpportunitiesStateSelector,
  customerContacts: customerContactsSelector,
  customerContactsState: customerContactsStateSelector,
  account: accountSelector,
  settings: settingsSelector,
});
