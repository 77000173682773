import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

import { OrderRequest } from "../../api";
import { CampaignApi, PlanningApi } from "../../api/apis";
import apiConfig from "../../apiConfig";
import { redirectRequest } from "../campaignCreate/sagas";
import { toError } from "../utils";
import {
  copyCampaignAction,
  deleteCampaignAction,
  receiveCampaignsAction,
} from "./actions";
import { CopyCampaignPayload } from "./models";

export function* receiveCampaigns(
  action: ReturnType<typeof receiveCampaignsAction.request>
): SagaIterator {
  try {
    const campaignApi = new CampaignApi(apiConfig());
    const response = yield call(() =>
      campaignApi.apiV1CampaignGetordersGet(action.payload)
    );
    yield put(receiveCampaignsAction.success(response));
  } catch (err) {
    yield put(receiveCampaignsAction.failure(toError(err)));
  }
}

export function* deleteCampaign(
  action: ReturnType<typeof deleteCampaignAction.request>
): SagaIterator {
  try {
    const planningApi = new PlanningApi(apiConfig());
    yield call(() =>
      planningApi.apiV1PlanningDeleteInitialRequestIdPost(action.payload)
    );
    yield put(deleteCampaignAction.success());
  } catch (err) {
    yield put(deleteCampaignAction.failure(toError(err)));
  }
}

export function* copyCampaign(
  action: ReturnType<typeof copyCampaignAction.request>
): SagaIterator {
  try {
    const planningApi = new PlanningApi(apiConfig());
    const response: OrderRequest = yield call(() =>
      planningApi.apiV1PlanningCopyPost(
        action.payload as Omit<
          CopyCampaignPayload,
          "successProps" | "onSuccess"
        >
      )
    );
    yield put(copyCampaignAction.success(response));

    if (action.payload.onSuccess) {
      yield call(action.payload.onSuccess, {} as Record<string, never>);
    }

    yield call(redirectRequest, response);
  } catch (err) {
    yield put(copyCampaignAction.failure(toError(err)));
  }
}
