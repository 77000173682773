import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Icons, Input, Row, Tooltip } from "@ster/ster-toolkit";
import { Col, Space } from "antd";
import { ChangeEvent, FocusEvent, memo, useCallback, useState } from "react";
import ReactMarkdown from "react-markdown";

import { AnnouncementDto } from "../../api";
import styles from "./Announcements.module.less";

const EditableMessage = memo(
  ({
    item,
    onChange,
  }: {
    item: AnnouncementDto;
    onChange: (announcement: AnnouncementDto) => void;
  }) => {
    const { i18n } = useLingui();
    const [edit, setEdit] = useState(false);
    const toggleEdit = useCallback(() => {
      setEdit(!edit);
    }, [edit]);

    const [text, setText] = useState(item.message);
    const handleChange = useCallback(
      ({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) => {
        setText(value);
      },
      []
    );

    const [textInt, setTextInt] = useState(item.messageInt ?? "");
    const handleChangeInt = useCallback(
      ({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) => {
        setTextInt(value);
      },
      []
    );

    const handleCancel = useCallback(() => {
      setText(item.message);
      setTextInt(item.messageInt ?? "");
      toggleEdit();
    }, [item.message, item.messageInt, toggleEdit]);

    const handleSave = useCallback(() => {
      if (item.message !== text || item.messageInt !== textInt) {
        onChange({ ...item, message: text, messageInt: textInt });
      }

      toggleEdit();
    }, [item, onChange, text, textInt, toggleEdit]);

    const selectText = useCallback(
      (event: FocusEvent<HTMLTextAreaElement>) => event.target.select(),
      []
    );

    return edit ? (
      <Space direction="vertical" className={styles.fullWidth}>
        <Row gutter={[16, 0]}>
          <Col flex={1}>
            <div className={styles.messageLabel}>
              <Trans>Nederlandse tekst</Trans>
            </div>
            <Input.TextArea
              value={text}
              rows={5}
              onChange={handleChange}
              onFocus={selectText}
              autoFocus
              className={styles.fullWidth}
              placeholder={i18n._(t`Nederlandse tekst`)}
              style={{ width: "100%" }}
            />
          </Col>
          <Col flex={1}>
            <div className={styles.messageLabel}>
              <Trans>Engelse tekst</Trans>
            </div>
            <Input.TextArea
              value={textInt}
              rows={5}
              onChange={handleChangeInt}
              className={styles.fullWidth}
              placeholder={i18n._(t`Engelse tekst`)}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
        <Space>
          <Button onClick={handleCancel} mode="secondary">
            <Trans>Annuleren</Trans>
          </Button>
          <Button onClick={handleSave} mode="primary">
            <Trans>Opslaan</Trans>
          </Button>
          <Tooltip
            title={
              <Trans>
                Wil je opmaak gebruiken? Dat kan met behulp van{" "}
                <a
                  href="https://nl.wikipedia.org/wiki/Markdown"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Markdown
                </a>
                ,{" "}
                <a
                  href="https://daringfireball.net/projects/markdown/basics"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  de specificaties
                </a>
                .
              </Trans>
            }
          >
            <Icons.QuestionIcon width={16} height={16} /> <Trans>Opmaak?</Trans>
          </Tooltip>
        </Space>
      </Space>
    ) : (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div onClick={toggleEdit}>
        <Row gutter={[16, 0]}>
          <Col flex={1}>
            <div className={styles.messageLabel}>
              <Trans>Nederlandse tekst</Trans>
            </div>
            <ReactMarkdown>{text}</ReactMarkdown>
          </Col>
          <Col flex={1} style={{ fontStyle: "italic" }}>
            <div className={styles.messageLabel}>
              <Trans>Engelse tekst</Trans>
            </div>
            <ReactMarkdown>{textInt}</ReactMarkdown>
          </Col>
        </Row>
      </div>
    );
  }
);

export default EditableMessage;
