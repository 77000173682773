import { createSelector } from "reselect";

import { SpotsByMedium } from "../../api/models";
import {
  accountSelector,
  createStructuredAppSelector,
  salesPeriodSelector,
  salesPeriodsFromStoreSelector,
  settingsSelector,
} from "../../shared/selectors";
import { StoreModel } from "../../store/models";
import { emptyArray } from "../../utils/constants";

const currentSpotsSelector = ({
  currentSpots: currentSpotsFromStore,
}: StoreModel): SpotsByMedium[] =>
  currentSpotsFromStore.spotsByMedium ?? emptyArray;

const loadingSelector = createSelector(
  [
    ({ currentSpots: currentSpotsFromStore }: StoreModel): boolean =>
      currentSpotsFromStore.loading,
    salesPeriodsFromStoreSelector,
    ({ tips: tipsFromStore }: StoreModel) => tipsFromStore.loading,
  ],
  (currentSpotsLoading, salesPeriods, tips) => ({
    currentSpotsLoading,
    salesPeriodsLoading: salesPeriods.loading,
    tipsLoading: tips,
  })
);

const tipsSelector = ({ tips: tipsFromStore }: StoreModel) =>
  tipsFromStore.data ?? emptyArray;

// eslint-disable-next-line import/prefer-default-export
export const dashboardRootSelector = createStructuredAppSelector({
  account: accountSelector,
  spotsByMedium: currentSpotsSelector,
  salesPeriods: salesPeriodSelector,
  loading: loadingSelector,
  settings: settingsSelector,
  tips: tipsSelector,
});
