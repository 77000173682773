import { parseJSON } from "date-fns";

export const emptyArray = [];

export const minCommercialDate = parseJSON("2019-01-01T00:00:00+01:00");

export const minOnlineCommercialDate = parseJSON("2021-01-01T00:00:00+01:00");

export const minCampaignDate = new Date("1900-01-01T00:00:00+01:00");

export const minDate = new Date("0001-01-01T00:00:00+01:00");

export const klantportalAdverteerder = "ADVER";

export const internalAdverteerder = "TEST";

export const advertiserCookieName = "ster_advcode";

export const organisationCookieName = "ster_orgcode";

export const tenantCookieName = "ster_tenant";

export const impersonateCookieName = "ster_impersonate";

export const announcementsClosedCookieName = "ster_announcements_closed";

export const actionOrientedValue = 2;

export const brandAwerenessValue = 4;

export const campaignCostDecreaseWarning = 10;

export const campaignCostIncreaseWarning = 25;

/**
 * Zie User Storey #8654:
 * Voor online is het mogelijk om voor een ingekochte spotlengte te kiezen uit
 * verschillende lengte spots om in te zetten. Bijvoorbeeld: bij inkoop van 20
 * seconden, mag je een spot inzetten van 16, 17, 18, 19 of 20 seconden.
 */
export const onlineSpotLengthMap: { [key: number]: number[] } = {
  5: [1, 2, 3, 4, 5, 6],
  6: [1, 2, 3, 4, 5, 6],
  10: [7, 8, 9, 10],
  15: [11, 12, 13, 14, 15],
  20: [16, 17, 18, 19, 20],
  25: [21, 22, 23, 24, 25],
  30: [26, 27, 28, 29, 30],
  35: [31, 32, 33, 34, 35, 36],
};
