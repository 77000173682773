/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  AnalysisTargetGroupRadio,
  AnalysisTargetGroupRadioFromJSON,
  AnalysisTargetGroupRadioFromJSONTyped,
  AnalysisTargetGroupRadioToJSON,
  AnalysisTargetGroupTV,
  AnalysisTargetGroupTVFromJSON,
  AnalysisTargetGroupTVFromJSONTyped,
  AnalysisTargetGroupTVToJSON,
} from "./";

/**
 *
 * @export
 * @interface AnalysisTargetGroups
 */
export interface AnalysisTargetGroups {
  /**
   *
   * @type {Array<AnalysisTargetGroupTV>}
   * @memberof AnalysisTargetGroups
   */
  tv: Array<AnalysisTargetGroupTV>;
  /**
   *
   * @type {Array<AnalysisTargetGroupRadio>}
   * @memberof AnalysisTargetGroups
   */
  radio: Array<AnalysisTargetGroupRadio>;
}

export function AnalysisTargetGroupsFromJSON(json: any): AnalysisTargetGroups {
  return AnalysisTargetGroupsFromJSONTyped(json, false);
}

export function AnalysisTargetGroupsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AnalysisTargetGroups {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    tv: (json["tv"] as Array<any>).map(AnalysisTargetGroupTVFromJSON),
    radio: (json["radio"] as Array<any>).map(AnalysisTargetGroupRadioFromJSON),
  };
}

export function AnalysisTargetGroupsToJSON(
  value?: AnalysisTargetGroups | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    tv: (value.tv as Array<any>).map(AnalysisTargetGroupTVToJSON),
    radio: (value.radio as Array<any>).map(AnalysisTargetGroupRadioToJSON),
  };
}
