import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Channel, ChannelNames, Checkbox, Form } from "@ster/ster-toolkit";
import { memo, useCallback } from "react";

import { RadioShortChannelNames } from "../../../partials/Channel/Channel";
import styles from "./ChannelPicker.module.less";
import { ChannelPickerProps } from "./models";

export const allRadioChannels: string[] = [
  "R1",
  "R2",
  "R3",
  "R4",
  "R5",
  "FX",
  "RR",
];

const ChannelPicker = memo(
  ({
    channels,
    disabledChannels,
    value: selectedChannels,
    requiredDeselection = 0,
    validator,
    required,
  }: ChannelPickerProps) => {
    const { i18n } = useLingui();

    const channelValidator = useCallback(
      (value: string[]) => {
        const isValidSelection =
          requiredDeselection > 0
            ? (value?.length ?? 0) + requiredDeselection === channels.length
            : value?.length >= 1;

        return isValidSelection
          ? Promise.resolve()
          : Promise.reject(
              new Error(
                requiredDeselection > 0
                  ? i18n._(t`Je dient 1 zender uit te sluiten`)
                  : i18n._(t`Kies minimaal 1 zender`)
              )
            );
      },
      [channels.length, i18n, requiredDeselection]
    );

    const isChannelDisabled = useCallback(
      (channel: string, selected?: string[]) => {
        if (disabledChannels?.includes(channel)) {
          return true;
        }

        if (!selected?.includes(channel)) {
          return false;
        }

        if (requiredDeselection > 0) {
          // Als het maximaal aantal zenders is uitgesloten dan zijn de andere zenders disabled
          return (
            (selected?.length ?? 0) + requiredDeselection === channels.length
          );
        }

        return false;
      },
      [channels.length, disabledChannels, requiredDeselection]
    );

    return (
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => (
          <Form.Item
            name="channels"
            rules={[
              {
                required,
                validator:
                  validator ||
                  ((_, value): Promise<void> => channelValidator(value)),
              },
            ]}
          >
            <Checkbox.Group value={selectedChannels}>
              {channels.map((channel) => (
                <Checkbox
                  value={channel}
                  key={channel}
                  className={styles.checkbox}
                  disabled={isChannelDisabled(
                    channel,
                    getFieldValue("channels")
                  )}
                >
                  <Channel
                    type={
                      RadioShortChannelNames.find(({ key }) => key === channel)
                        ?.value as ChannelNames
                    }
                  />
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
        )}
      </Form.Item>
    );
  }
);

export default ChannelPicker;
