/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum CommercialUploadStatus {
  WaitForReview = "WaitForReview",
  Approved = "Approved",
  Rejected = "Rejected",
}

export function CommercialUploadStatusFromJSON(
  json: any
): CommercialUploadStatus {
  return CommercialUploadStatusFromJSONTyped(json, false);
}

export function CommercialUploadStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CommercialUploadStatus {
  return json as CommercialUploadStatus;
}

export function CommercialUploadStatusToJSON(
  value?: CommercialUploadStatus | null
): any {
  return value as any;
}
