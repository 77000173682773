import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  ComponentLocalization,
  Form,
  Input,
  MultiOption,
  Radio,
  RangePicker,
  Row,
  Select,
  TimeRangePicker,
} from "@ster/ster-toolkit";
import { Radio as AntRadio, Col } from "antd";
import { SelectValue } from "antd/lib/select";
import { parseISO } from "date-fns";
import { ChangeEvent, memo, useCallback } from "react";
import { useDebouncedCallback } from "use-debounce";

import { SecondaryTargetGroup } from "../../api/models";
import { RangeValueType, getLanguage } from "../../utils";
import { isBetweenDays } from "../../utils/dateHelper";
import styles from "./BreakFilterForm.module.less";
import { allDaysOfTheWeek } from "./constants";
import { BreakFilters, BreakShowSelection } from "./models";

const BreakFilterForm = memo(
  ({
    onFilter,
    filters,
    defaultFilters,
    showSelection,
    onShowSelectionChange,
    selectionCount,
    channels,
    secondaryTargetGroups,
    onSelectAll,
  }: {
    onFilter: (newFilters: BreakFilters) => void;
    filters: BreakFilters;
    defaultFilters: BreakFilters;
    showSelection: BreakShowSelection;
    onShowSelectionChange: (value: BreakShowSelection) => void;
    selectionCount: number;
    channels: string[];
    secondaryTargetGroups: SecondaryTargetGroup[];
    onSelectAll: () => void;
  }) => {
    const { i18n } = useLingui();
    const handleShowSelectionToggle = useCallback(() => {
      onShowSelectionChange(showSelection === "all" ? "selection" : "all");
    }, [onShowSelectionChange, showSelection]);

    /**
     * Filteren op programma naam
     */
    const programFilter = useCallback(
      (searchValue: string) => {
        if (searchValue) {
          onFilter({
            ...filters,
            programmeBefore: [searchValue],
            programmeAfter: [searchValue],
          });
        } else {
          onFilter({
            ...filters,
            programmeBefore: null,
            programmeAfter: null,
          });
        }
      },
      [filters, onFilter]
    );

    const debouncedProgramFilter = useDebouncedCallback(programFilter, 500);
    const handleProgramFilter = useCallback(
      ({ target: { value: searchValue } }: ChangeEvent<HTMLInputElement>) => {
        debouncedProgramFilter.cancel();
        debouncedProgramFilter(searchValue);
      },
      [debouncedProgramFilter]
    );

    const isDateDisabled = useCallback(
      (currentDate: Date): boolean =>
        !isBetweenDays(
          parseISO(defaultFilters.schedDate[0]),
          parseISO(defaultFilters.schedDate[1]),
          currentDate
        ),
      [defaultFilters.schedDate]
    );

    /**
     * Filteren op aangegeven periode
     */
    const handlePeriodChange = useCallback(
      (dates: RangeValueType<Date>) => {
        if (dates && dates[0] && dates[1]) {
          onFilter({
            ...filters,
            schedDate: [dates[0].toISOString(), dates[1].toISOString()],
          });
        } else {
          onFilter({
            ...filters,
            schedDate: defaultFilters.schedDate,
          });
        }
      },
      [defaultFilters.schedDate, filters, onFilter]
    );

    /**
     * Filteren op tijdvak
     */
    const handleTimeChange = useCallback(
      (_values: Date | Date[], times: string | string[]) => {
        const selectedTimes = Array.isArray(times) ? times : [times];
        if (times && times[0] && times[1]) {
          onFilter({
            ...filters,
            schedStartTime: selectedTimes,
          });
        } else {
          onFilter({
            ...filters,
            schedStartTime: null,
          });
        }
      },
      [filters, onFilter]
    );

    /**
     * Filteren op dagen van de week
     */
    const handleDaysChange = useCallback(
      (days: SelectValue) => {
        const daysArr = (days as number[]) ?? allDaysOfTheWeek;
        if (daysArr && daysArr.length === 1 && daysArr[0] === 0) {
          onFilter({
            ...filters,
            days: filters.days.length === 7 ? [] : allDaysOfTheWeek,
          });
        } else if (daysArr && daysArr.length > 0) {
          onFilter({
            ...filters,
            days: daysArr,
          });
        }
      },
      [filters, onFilter]
    );

    const handleChannelChange = useCallback(
      (value: SelectValue) => {
        const channelDescr =
          value && Array.isArray(value) && value.length > 0
            ? (value as string[])
            : undefined;
        onFilter({ ...filters, channelDescr });
      },
      [filters, onFilter]
    );

    const handleSecondaryTargetGroupChange = useCallback(
      (value: SelectValue) => {
        const secondaryTargetGroup =
          value === ""
            ? defaultFilters.secondaryTargetGroup
            : (value as string);
        onFilter({ ...filters, secondaryTargetGroup });
      },
      [defaultFilters.secondaryTargetGroup, filters, onFilter]
    );

    return (
      <Form>
        <Row
          justify="center"
          align="middle"
          gutter={[24, 0]}
          className={styles.filterRow}
        >
          <Col>
            <Form.Item label={i18n._(t`Periode`)}>
              <RangePicker
                allowClear={false}
                onChange={handlePeriodChange}
                value={[
                  parseISO(filters.schedDate[0]),
                  parseISO(filters.schedDate[1]),
                ]}
                disabledDate={isDateDisabled}
                className={styles.dateRangePicker}
                componentLocale={getLanguage() as ComponentLocalization}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label={i18n._(t`Tijdvak`)}>
              <TimeRangePicker
                onChange={handleTimeChange}
                minuteStep={15}
                startTimeLabel={i18n._(t`Start tijd`)}
                endTimeLabel={i18n._(t`Eind tijd`)}
              />
            </Form.Item>
          </Col>
          <Col flex="1">
            <Form.Item label={i18n._(t`Dagen`)}>
              <Select.Multi
                placeholder={i18n._(t`Kies je dag/dagen`)}
                onChange={handleDaysChange}
                value={filters.days}
              >
                <MultiOption value={0} title={i18n._(t`Alle dagen`)}>
                  <MultiOption value={1} title={i18n._(t`Maandag`)} />
                  <MultiOption value={2} title={i18n._(t`Dinsdag`)} />
                  <MultiOption value={3} title={i18n._(t`Woensdag`)} />
                  <MultiOption value={4} title={i18n._(t`Donderdag`)} />
                  <MultiOption value={5} title={i18n._(t`Vrijdag`)} />
                  <MultiOption value={6} title={i18n._(t`Zaterdag`)} />
                  <MultiOption value={7} title={i18n._(t`Zondag`)} />
                </MultiOption>
              </Select.Multi>
            </Form.Item>
          </Col>
          <Col flex="1">
            <Form.Item label={i18n._(t`Zenders`)}>
              <Select.Multi
                placeholder={i18n._(t`Filter op zenders`)}
                onChange={handleChannelChange}
                value={filters.channelDescr}
              >
                {channels.map((channel) => (
                  <MultiOption key={channel} value={channel} title={channel} />
                ))}
              </Select.Multi>
            </Form.Item>
          </Col>
          <Col flex="1">
            <Form.Item label={i18n._(t`Sec. doelgroep`)}>
              <Select.Search
                placeholder={i18n._(t`Kies een doelgroep`)}
                onChange={handleSecondaryTargetGroupChange}
                optionFilterProp="label"
                options={[
                  {
                    label: i18n._(t`Geen sec. doelgroep`),
                    value: "",
                  },
                  ...secondaryTargetGroups.map(({ mbsId, displayName }) => ({
                    label: displayName,
                    value: `${mbsId}`,
                  })),
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 8]}>
          <Col span={6}>
            <Button mode="secondary" onClick={onSelectAll}>
              <Trans>Selecteer alles</Trans>
            </Button>
          </Col>
          <Col span={6}>
            <Trans>Filter</Trans>&nbsp;
            <Radio.Group
              disabled={selectionCount === 0 && showSelection === "all"}
              value={showSelection}
              onChange={handleShowSelectionToggle}
            >
              <AntRadio.Button value="all">
                <Trans>Alles</Trans>
              </AntRadio.Button>
              <AntRadio.Button value="selection">
                <Trans>Geselecteerd ({selectionCount})</Trans>
              </AntRadio.Button>
            </Radio.Group>
          </Col>
          <Col span={12}>
            <Input.Search
              onChange={handleProgramFilter}
              onSearch={programFilter}
              placeholder={i18n._(t`Filter op programma`)}
              allowClear
            />
          </Col>
        </Row>
      </Form>
    );
  }
);

export default BreakFilterForm;
