import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  ContentContainer,
  Icons,
  NavLink,
  Pageheader,
  Row,
  Subnav,
} from "@ster/ster-toolkit";
import { Col } from "antd";
import { Helmet } from "react-helmet-async";
import { Outlet } from "react-router-dom";

const Admin = () => {
  const { i18n } = useLingui();
  return (
    <>
      <Helmet>
        <title>{i18n._(t`Beheer`)}</title>
      </Helmet>

      <Pageheader
        title={i18n._(t`Beheer`)}
        icon={
          <Icons.SpeechBalloonIcon
            width="100%"
            height="100%"
            fill="rgba(129, 176, 210, 0.2)"
          />
        }
      />

      <ContentContainer>
        <Row style={{ margin: "32px 0 16px 0" }}>
          <Col span={24}>
            <Subnav>
              <NavLink
                title={i18n._(t`Gebruikers`)}
                to="/admin/users"
                key="users"
              />
              <NavLink
                title={i18n._(t`Meldingen`)}
                to="/admin/announcements"
                key="announcements"
              />
              <NavLink
                title={i18n._(t`Instellingen`)}
                to="/admin/settings"
                key="settings"
              />
            </Subnav>
          </Col>
        </Row>

        <Outlet />
      </ContentContainer>
    </>
  );
};

export default Admin;
