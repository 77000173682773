import "@ster/ster-toolkit/dist/index.css";
import "moment/dist/locale/nl";

import "./utils/array";

import { i18n } from "@lingui/core";
import { SterThemeConfig } from "@ster/ster-toolkit";
import { ConfigProvider, ThemeConfig } from "antd";
import en from "antd/lib/locale/en_GB";
import nl from "antd/lib/locale/nl_NL";
import { setDefaultOptions } from "date-fns";
import { enGB as enDateFns, nl as nlDateFns } from "date-fns/locale";
import moment from "moment";
import * as momentTZ from "moment-timezone";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import AppContainer from "./components/AppContainer";
import { getLanguage } from "./utils";

momentTZ.tz.setDefault("Europe/Amsterdam");
// ISO-8601, Europe
moment.updateLocale("nl", {
  week: {
    dow: 1, // First day of week is Monday
    doy: 4, // First week of year must contain 4 January (7 + 1 - 4)
  },
});
moment.updateLocale("en", {
  week: {
    dow: 1, // First day of week is Monday
    doy: 4, // First week of year must contain 4 January (7 + 1 - 4)
  },
});

const lang = getLanguage();
moment.locale(lang);
setDefaultOptions({ locale: lang === "nl" ? nlDateFns : enDateFns });

// Dummy load of catalogs; needed for dynamicActivate
i18n.load({ en: {}, nl: {} });
i18n.activate(lang);

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: <R>(a: R) => R;
  }
}

const theme: ThemeConfig = {
  ...SterThemeConfig,
};

const root = createRoot(document.getElementById("app") as HTMLElement);
root.render(
  <StrictMode>
    <ConfigProvider locale={lang === "nl" ? nl : en} theme={theme}>
      <AppContainer />
    </ConfigProvider>
  </StrictMode>
);
