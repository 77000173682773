/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum VolumeDiscount {
  VolumeDiscount0 = "VolumeDiscount0",
  VolumeDiscount2 = "VolumeDiscount2",
  VolumeDiscount3 = "VolumeDiscount3",
  VolumeDiscount4 = "VolumeDiscount4",
  VolumeDiscount5 = "VolumeDiscount5",
}

export function VolumeDiscountFromJSON(json: any): VolumeDiscount {
  return VolumeDiscountFromJSONTyped(json, false);
}

export function VolumeDiscountFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VolumeDiscount {
  return json as VolumeDiscount;
}

export function VolumeDiscountToJSON(value?: VolumeDiscount | null): any {
  return value as any;
}
