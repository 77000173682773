import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  ComponentLocalization,
  Form,
  Input,
  RangePicker,
  Spinner,
} from "@ster/ster-toolkit";
import { Form as AntForm } from "antd";
import { Store } from "antd/lib/form/interface";
import moment from "moment";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SubOrderRequest } from "../../../api";
import { ReduxStoreState } from "../../../store/base";
import { receiveSpotIndexesAction } from "../../../store/campaignCreate/actions";
import { StoreModel } from "../../../store/models";
import { getLanguage, getPopupContainerSubOrders } from "../../../utils";
import { getDaysDiff, isBetweenDays } from "../../../utils/dateHelper";
import { formItemLayout } from "../../partials/Form";
import { SubOrderEditProps } from "./models";
import ChannelPicker, { allRadioChannels } from "./partials/ChannelPicker";
import ExcludeDays from "./partials/ExcludeDays";
import FormTimePicker from "./partials/FormTimePicker";
import NumberOfSpotsInput from "./partials/NumberOfSpotsInput";
import SpotLength from "./partials/SpotLength";
import { npoPromoSubOrderSelector } from "./selectors";

/**
 * Toevoegen/wijzigen van een pakket van het type NPO Promo Freq.
 */
const NpoPromoSubOrder = memo(
  ({
    onChange,
    subOrder,
    onFinishFailed,
    period,
    selectedSalesPeriod,
    medium,
  }: SubOrderEditProps) => {
    const { i18n } = useLingui();
    const [form] = AntForm.useForm();
    const dispatch = useDispatch();

    const {
      spotIndexes,
      packages,
      loading,
      states: { spotIndexes: spotIndexesState },
    } = useSelector((state: StoreModel) =>
      npoPromoSubOrderSelector(state, { period, medium })
    );

    const selectedPackage = useMemo(
      () => packages?.find((p) => p.code === subOrder._package?.code),
      [packages, subOrder]
    );

    // Haal spotindexen op
    useEffect(() => {
      if (spotIndexesState === ReduxStoreState.Initial) {
        dispatch(
          receiveSpotIndexesAction.request({
            // TODO: spotindexen per medium in store?
            medium,
            year: period.from.getFullYear(),
          })
        );
      }
    }, [dispatch, medium, period.from, spotIndexesState]);

    useEffect((): void => {
      if (subOrder) {
        // Vul het formulier met de bestaande deelorder
        form.setFieldsValue({
          spotLength: [undefined], // Spotlength is altijd minimaal een leeg array om de control te initialiseren
          ...subOrder,
          period: subOrder.period
            ? [subOrder.period.from, subOrder.period.to]
            : undefined,
          targetGroupId: subOrder.targetGroup?.id,
          packageCode: subOrder._package?.code,
        });

        // Re-validate de spotlength
        const currentSpotLength = (
          (form.getFieldValue("spotLength") ?? []) as (number | undefined)[]
        ).filter((l) => l !== undefined);
        if (currentSpotLength.length > 0) {
          form.validateFields(["spotLength"]);
        }
      }
    }, [subOrder, form]);

    // Wijzigen van het formulier
    const handleValuesChange = useCallback(
      (changedValues: Store, values: Store) => {
        const resetFields = [];
        const defaultValues: Partial<SubOrderRequest> = {};

        if (changedValues.period) {
          defaultValues.period = {
            from: values.period[0],
            to: values.period[1],
          };

          // Reset de uitgesloten dagen als de periode wijzigt
          resetFields.push("excluded");
        }

        if (changedValues.spotLength) {
          // Bij het wijzigen van de spotlengte komt alleen het gewijzigde veld door, dus we moeten de andere waardes ook toevoegen
          defaultValues.spotLength = values.spotLength;
        }

        const newValues: Partial<SubOrderRequest> = {
          ...subOrder,
          ...changedValues,
          ...Object.assign(
            // Alle velden die gereset moeten worden krijgen de waarde null
            {},
            ...resetFields.map((s) => ({ [s]: null }))
          ),
          ...defaultValues,
        };
        form.resetFields(resetFields);
        onChange(newValues);
      },
      [subOrder, form, onChange]
    );

    // Afhandelen van het optreden van een error bij het versturen van het formulier
    const handleFinishFailed = useCallback(() => {
      onFinishFailed(subOrder.id ?? 0);
    }, [onFinishFailed, subOrder]);

    // Bepaling van de niet beschikbare data in de periode-kiezer
    const isDateDisabled = useCallback(
      (currentDate: Date): boolean =>
        !isBetweenDays(
          selectedSalesPeriod.salesPeriodStartDate,
          selectedSalesPeriod.salesPeriodEndDate,
          currentDate
        ),
      [
        selectedSalesPeriod.salesPeriodEndDate,
        selectedSalesPeriod.salesPeriodStartDate,
      ]
    );

    return (
      <Spinner spinning={loading}>
        <Form
          {...formItemLayout}
          onValuesChange={handleValuesChange}
          onFinishFailed={handleFinishFailed}
          form={form}
          name={`suborder-${subOrder.id}`}
        >
          <Form.Item
            label={i18n._(t`Campagnenaam`)}
            name="campaignName"
            rules={[
              { required: true, message: i18n._(t`Voer een campagnenaam in`) },
            ]}
            wrapperCol={{ span: 12 }}
          >
            <Input type="text" maxLength={30} />
          </Form.Item>

          <Form.Item
            label={i18n._(t`Periode`)}
            name="period"
            rules={[
              {
                required: true,
                message: i18n._(t`Kies een periode`),
              },
            ]}
          >
            <RangePicker
              showWeek
              disabledDate={isDateDisabled}
              defaultPickerValue={[
                selectedSalesPeriod.salesPeriodStartDate,
                null!,
              ]}
              componentLocale={getLanguage() as ComponentLocalization}
              getPopupContainer={getPopupContainerSubOrders}
            />
          </Form.Item>
          {(!selectedPackage || selectedPackage.minDaysNoWeekly === 0) && (
            <Form.Item label={i18n._(t`Uitgesloten dagen`)} name="excluded">
              <ExcludeDays
                period={subOrder.period}
                weekDays={selectedPackage?.weekDays}
                canDisableSeperateDays
                disabled={!subOrder.period}
                disabledText={<Trans>Selecteer eerst een periode</Trans>}
              />
            </Form.Item>
          )}
          <Form.Item label={i18n._(t`Spotlengte`)}>
            <SpotLength
              spotIndexes={spotIndexes}
              spotLength={subOrder.spotLength ?? []}
              enabled={subOrder.period !== undefined}
            />
          </Form.Item>

          <Form.Item label={i18n._(t`Zenders`)} name="channels">
            <ChannelPicker
              channels={allRadioChannels}
              required
              validator={(_, value): Promise<void> =>
                value?.length >= 1
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error(
                        i18n._(t`Je dient minimaal 1 zenders te kiezen`)
                      )
                    )
              }
            />
          </Form.Item>

          {subOrder.period &&
            subOrder.channels &&
            subOrder.channels.length > 0 && (
              <Form.Item
                label={i18n._(t`Spots/dag/zender`)}
                helpText={i18n._(t`Aantal spots per dag per zender`)}
                name="spotNo"
                rules={[{ required: true, message: i18n._(t`Verplicht veld`) }]}
              >
                <NumberOfSpotsInput
                  totalDays={
                    getDaysDiff(
                      moment(subOrder.period.from),
                      moment(subOrder.period.to)
                    ) - (subOrder.excluded?.length ?? 0)
                  }
                  totalChannels={subOrder.channels?.length ?? 0}
                />
              </Form.Item>
            )}

          <Form.Item label={i18n._(t`Dagelijkse eindtijd`)} name="endTime">
            <FormTimePicker minuteStep={5} />
          </Form.Item>
        </Form>
      </Spinner>
    );
  }
);

export default NpoPromoSubOrder;
