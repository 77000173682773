import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Form,
  Icons,
  Option,
  Radio,
  Select,
  formatDate,
} from "@ster/ster-toolkit";
import { Alert } from "antd";
import { memo, useMemo } from "react";

import {
  Advertiser,
  MediumEnum,
  OrderRequest,
  SalesPeriod,
} from "../../../api";
import { LoadingPortalUser } from "../../../store/models";
import { getFormattedDate } from "../../../utils/dateHelper";
import { useAvailableMediumTypes } from "../../../utils/hooks";
import { canUseProspect } from "../../../utils/userHelper";
import { AdvertiserTypeEnum } from "./Details";
import styles from "./Details.module.less";
import VolumeDiscounts from "./VolumeDiscounts";

const DetailsEditPeriodAdvertiser = memo(
  ({
    order,
    productsByAdvertiser,
    advertiserType,
    account,
    selectedSalesPeriods,
    showDigitalReportWarning,
  }: {
    advertiserType: AdvertiserTypeEnum;
    productsByAdvertiser: Advertiser[];
    order: OrderRequest | undefined;
    account: LoadingPortalUser;
    selectedSalesPeriods: SalesPeriod[] | undefined;
    showDigitalReportWarning: boolean;
  }) => {
    const { i18n } = useLingui();

    const prospectAdvertisers = useMemo(
      () => productsByAdvertiser.filter(({ isProspect }) => isProspect),
      [productsByAdvertiser]
    );

    const [availableMediumTypes] = useAvailableMediumTypes(
      "aanvragen_formulier"
    );

    const showProspectOption = useMemo(
      () =>
        productsByAdvertiser?.some((s) => s.isProspect) &&
        canUseProspect(account),
      [account, productsByAdvertiser]
    );

    const selectedProspectAdvertiser = useMemo(
      () =>
        productsByAdvertiser?.find((p) => p.id === order?.advertiserId)
          ?.isProspect,
      [order?.advertiserId, productsByAdvertiser]
    );

    const year = useMemo(
      () => order?.period?.from.getFullYear(),
      [order?.period?.from]
    );

    // Korting voor 2024
    const showVolumeDiscounts = useMemo(
      () =>
        selectedProspectAdvertiser &&
        prospectAdvertisers.some((s) => s.volumeDiscount) &&
        year &&
        year >= 2024,
      [prospectAdvertisers, selectedProspectAdvertiser, year]
    );

    return (
      <>
        <Form.Item
          label={i18n._(t`Type`)}
          name="type"
          rules={[
            {
              required: true,
              message: i18n._(t`Kies een type`),
            },
          ]}
        >
          <Radio.Group mode="horizontal" name="radioTypeGroup">
            <Radio value="new" className={styles.fixedWidthOption}>
              <Trans>Nieuwe campagne</Trans>
            </Radio>
            <Radio value="code">
              <Trans>Campagnecode invoeren</Trans>
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={i18n._(t`Medium`)}
          name="medium"
          rules={[
            {
              required: true,
              message: i18n._(t`Kies een medium`),
            },
          ]}
        >
          <Radio.Group mode="horizontal" name="radioMediaGroup">
            {availableMediumTypes.includes(MediumEnum.Tv) && (
              <Radio value={MediumEnum.Tv}>
                <Trans>Televisie</Trans>
                <Icons.TVIcon width={20} height={20} />
              </Radio>
            )}
            {availableMediumTypes.includes(MediumEnum.Radio) && (
              <Radio value={MediumEnum.Radio}>
                <Trans>Radio</Trans>
                <Icons.RadioIcon width={20} height={20} />
              </Radio>
            )}
            {availableMediumTypes.includes(MediumEnum.Inter) && (
              <Radio value={MediumEnum.Inter}>
                <Trans>Online</Trans>
                <Icons.OnlineIcon width={20} height={20} />
              </Radio>
            )}
          </Radio.Group>
        </Form.Item>
        {showDigitalReportWarning && order?.medium === MediumEnum.Inter && (
          <Alert
            showIcon
            message=""
            type="warning"
            className={styles.alert}
            description={
              <Trans>
                <b>Let op:</b> Momenteel ervaren we enkele technische problemen
                met de rapportages van de online campagnes. Dit heeft echter
                geen invloed op de uitlevering van de campagne(s).
              </Trans>
            }
          />
        )}
        <Form.Item
          label={i18n._(t`Periode`)}
          name="period"
          rules={[
            {
              required: true,
              message: i18n._(t`Kies een periode`),
            },
          ]}
        >
          <Select
            disabled={!order?.medium}
            placeholder={i18n._(t`Kies een periode`)}
            className={`select ${styles.formInputWidth}`}
          >
            {selectedSalesPeriods?.map((period, index) => (
              <Option
                value={index}
                key={formatDate(period.salesPeriodStartDate)}
              >
                <>
                  {getFormattedDate(period.salesPeriodStartDate, "PPP")}{" "}
                  <Trans>t/m</Trans>{" "}
                  {getFormattedDate(period.salesPeriodEndDate, "PPP")}
                  {period.ratecardStatus &&
                    ` (${
                      // eslint-disable-next-line no-nested-ternary
                      period.ratecardStatus === "Vrijgegeven"
                        ? i18n._(t`Open om in te boeken`)
                        : period.ratecardStatus === "Voorbereiding"
                          ? i18n._(t`Voorbereiding`)
                          : period.ratecardStatus
                    })`}
                </>
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label={i18n._(t`Adverteerder`)}>
          {showProspectOption && (
            <Form.Item
              name="advertiserType"
              rules={[
                {
                  required: true,
                  message: i18n._(t`Kies een type`),
                },
              ]}
              noStyle={advertiserType === AdvertiserTypeEnum.Prospect}
            >
              <Radio.Group mode="horizontal" name="radioTypeGroup">
                <Radio value="existing" className={styles.fixedWidthOption}>
                  <Trans>Bestaande adverteerder</Trans>
                </Radio>
                <Radio value="prospect">
                  <Trans>Nieuwe adverteerder</Trans>
                </Radio>
              </Radio.Group>
            </Form.Item>
          )}
          {advertiserType === AdvertiserTypeEnum.Existing && (
            <Form.Item
              name="advertiserId"
              rules={[
                {
                  required: true,
                  message: i18n._(t`Kies een adverteerder`),
                },
              ]}
              noStyle
            >
              <Select.Search
                disabled={!productsByAdvertiser || !order?.period}
                placeholder={i18n._(t`Kies een adverteerder`)}
                optionFilterProp="children"
                className={styles.formInputWidth}
              >
                {productsByAdvertiser
                  .filter((s) => !s.isProspect)
                  .map((advertiser) => (
                    <Option value={advertiser.id} key={advertiser.id}>
                      {advertiser.name}
                    </Option>
                  ))}
              </Select.Search>
            </Form.Item>
          )}
        </Form.Item>
        {selectedProspectAdvertiser ? (
          showVolumeDiscounts && (
            <VolumeDiscounts prospectAdvertisers={prospectAdvertisers} />
          )
        ) : (
          <Form.Item
            label={i18n._(t`Sternummer`)}
            name="productId"
            rules={[
              {
                required: true,
                message: i18n._(t`Kies een sternummer`),
              },
            ]}
            shouldUpdate
          >
            <Select.Search
              disabled={!productsByAdvertiser || !order?.advertiserId}
              placeholder={i18n._(t`Kies een sternummer`)}
              optionFilterProp="children"
              className={styles.formInputWidth}
            >
              {productsByAdvertiser
                ?.find((p) => p.id === order?.advertiserId)
                ?.products?.map((product) => (
                  <Option value={product.id} key={product.id}>
                    {`${product.description} - ${product.id}`}
                  </Option>
                ))}
            </Select.Search>
          </Form.Item>
        )}
      </>
    );
  }
);

export default DetailsEditPeriodAdvertiser;
