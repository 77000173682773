import { Trans } from "@lingui/macro";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  CommercialStatus,
  CommercialsPerAdvertiser,
  OrderRequest,
  PackageChoiceEnum,
} from "../../../api";
import { receiveCommercialsForProductIdAction } from "../../../store/commercials/actions";
import { StoreModel } from "../../../store/models";
import {
  minOnlineCommercialDate,
  onlineSpotLengthMap,
} from "../../../utils/constants";
import { usePrevious } from "../../../utils/hooks";
import { campaignInstructionsRootSelector } from "../../campaignDetail/selectors";
import AddOnlineInstructions from "../../partials/OnlineInstructions/AddOnlineInstructions";
import OnlineInstructions from "../../partials/OnlineInstructions/OnlineInstructions";
import {
  OnlineInstructionsPerSubOrder,
  SimpleOnlineSubOrder,
} from "../../partials/OnlineInstructions/types";
import { getMinMax } from "./InstructionsForConcept";
import styles from "./InstructionsForConcept.module.less";

interface InstructionsProps {
  orderRequest: OrderRequest;
  onSave: (instructions: OnlineInstructionsPerSubOrder[]) => void;
  onDelete: (instructionId: number) => void;
  isEditCampaign?: boolean;
}

const OnlineInstructionsForConcept = memo(
  ({ orderRequest, onSave, onDelete, isEditCampaign }: InstructionsProps) => {
    const { min: minDate, max: maxDate } = useMemo(
      () => getMinMax(orderRequest),
      [orderRequest]
    );

    const dispatch = useDispatch();

    const handleDelete = useCallback(
      (instructionId: number) => {
        onDelete(instructionId);
      },
      [onDelete]
    );

    const prevMinDate = usePrevious(minDate);
    const prevMaxDate = usePrevious(maxDate);
    useEffect(() => {
      // Commercials alleen ophalen wanneer de periode wijzigt
      if (
        !prevMinDate ||
        !prevMaxDate ||
        prevMinDate.getTime() !== minDate.getTime() ||
        prevMaxDate.getTime() !== maxDate.getTime()
      ) {
        dispatch(
          receiveCommercialsForProductIdAction.request({
            medium: orderRequest.medium,
            productId: orderRequest.productId,
            from: minOnlineCommercialDate,
            to: orderRequest.period.to,
          })
        );
      }
    }, [
      dispatch,
      maxDate,
      minDate,
      orderRequest.medium,
      orderRequest.period,
      orderRequest.period.to,
      orderRequest.productId,
      prevMaxDate,
      prevMinDate,
    ]);

    const { loading, commercialsPerAdvertiser } = useSelector(
      (state: StoreModel) => campaignInstructionsRootSelector(state)
    );

    // Alleen actieve commercials kunnen ingezet worden. Inactieve commercials moeten eerst geactiveerd worden
    const activeCommercialAndBanners = useMemo(
      () =>
        ({
          ...commercialsPerAdvertiser,
          commercials: commercialsPerAdvertiser?.commercials.filter(
            (s) => s.status === CommercialStatus.Active
          ),
          banners: commercialsPerAdvertiser?.banners.filter(
            (s) => s.status === CommercialStatus.Active
          ),
        }) as CommercialsPerAdvertiser | undefined,
      [commercialsPerAdvertiser]
    );

    const subOrders = useMemo(
      () =>
        orderRequest.subOrders
          ?.filter(
            ({ id, period, spotLength, packageChoice, _package }) =>
              id &&
              period &&
              _package &&
              (spotLength || packageChoice === PackageChoiceEnum.Display)
          )
          .map(
            ({
              id,
              period,
              spotLength,
              _package,
              campaignName,
              subOrderRequestStatus,
              onlineInstructionsPerPeriod,
              packageChoice,
            }) =>
              ({
                id,
                dateRange: { startDate: period.from, endDate: period.to },
                spotLength,
                packageName: _package.name,
                campaignName,
                status: subOrderRequestStatus,
                onlineInstructionsPerPeriod,
                packageChoice,
              }) as SimpleOnlineSubOrder
          ) ?? [],
      [orderRequest.subOrders]
    );

    useEffect(() => {
      // Controleer of de spotlengte en periode van de uitzendinstructies overeenkomen met die van de deelorders
      if (orderRequest.subOrders) {
        orderRequest.subOrders?.forEach((s) => {
          const invalidSpotLength = s.onlineInstructionsPerPeriod?.some((o) =>
            o.instructions?.some(
              (i) =>
                s.spotLength &&
                i.commercial &&
                !onlineSpotLengthMap[s.spotLength[0]].includes(
                  i.commercial.length
                )
            )
          );

          if (invalidSpotLength && s.id) {
            handleDelete(s.id);
          }

          const invalidPeriod = s.onlineInstructionsPerPeriod?.some(
            (o) =>
              o.startDate &&
              o.endDate &&
              (o.startDate < s.period.from || o.endDate > s.period.to)
          );

          if (invalidPeriod && s.id) {
            const validPeriod = s.onlineInstructionsPerPeriod?.filter(
              (o) =>
                !o.startDate ||
                !o.endDate ||
                (o.startDate < s.period.to && o.endDate > s.period.from)
            );

            const onlineInstructionsPerPeriod = validPeriod
              ?.map((o) => {
                if (!o.startDate || !o.endDate) {
                  return o;
                }
                if (o.startDate < s.period.from) {
                  return { ...o, startDate: s.period.from };
                }
                if (o.endDate > s.period.to) {
                  return { ...o, endDate: s.period.to };
                }
                return o;
              })
              .filter((x) => x != null);

            if (
              onlineInstructionsPerPeriod &&
              (onlineInstructionsPerPeriod.map((o) => o.instructions).length ??
                0) > 0
            ) {
              onSave([
                {
                  subOrderId: s.id ?? 0,
                  instructionsPerPeriod: onlineInstructionsPerPeriod,
                },
              ]);
            } else {
              handleDelete(s.id ?? 0);
            }
          }
        });
      }
    }, [handleDelete, onSave, orderRequest.subOrders]);

    return (
      <>
        <OnlineInstructions
          loading={loading}
          commercialsPerAdvertiser={activeCommercialAndBanners}
          className={styles.instructionsForConcept}
          maxDate={maxDate}
          onSave={onSave}
          onDelete={handleDelete}
          subOrders={subOrders}
          productId={orderRequest.productId}
          isEditCampaign={isEditCampaign}
        />

        <AddOnlineInstructions
          enabled={!loading}
          commercialsPerAdvertiser={activeCommercialAndBanners}
          maxDate={maxDate}
          newInstructionText={
            orderRequest.subOrders?.some(
              (s) => s.onlineInstructionsPerPeriod
            ) ? (
              <Trans>Instructie bewerken</Trans>
            ) : (
              <Trans>Instructie toevoegen</Trans>
            )
          }
          newButtonMode="secondary"
          okText={<Trans>OK</Trans>}
          onSave={onSave}
          subOrders={subOrders}
        />
      </>
    );
  }
);

export default OnlineInstructionsForConcept;
