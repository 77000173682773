/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { Status, StatusFromJSON, StatusFromJSONTyped, StatusToJSON } from "./";

/**
 *
 * @export
 * @interface CommercialUploadResult
 */
export interface CommercialUploadResult {
  /**
   *
   * @type {Status}
   * @memberof CommercialUploadResult
   */
  status: Status;
}

export function CommercialUploadResultFromJSON(
  json: any
): CommercialUploadResult {
  return CommercialUploadResultFromJSONTyped(json, false);
}

export function CommercialUploadResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CommercialUploadResult {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    status: StatusFromJSON(json["status"]),
  };
}

export function CommercialUploadResultToJSON(
  value?: CommercialUploadResult | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    status: StatusToJSON(value.status),
  };
}
