import { AnyAction } from "redux";
import { Reducer, createReducer } from "typesafe-actions";

import { genericReducer } from "../../utils";
import { Loading, ReduxStoreState } from "../base";
import {
  LoadingAcceptAvailableCampaigns,
  LoadingAvailableCampaignDetail,
  LoadingAvailableCampaigns,
  LoadingCampaignForwarded,
  LoadingContacts,
  LoadingProductsByAdvertiser,
} from "../models";
import {
  acceptAvailableCampaignAction,
  clearAcceptAvailableCampaignsAction,
  clearForwardCampaignAction,
  clearRejectAvailableCampaignsAction,
  forwardCampaignAction,
  receiveAvailableCampaignDetailAction,
  receiveAvailableCampaignsAction,
  receiveContactsForForwardAction,
  receiveProductsForForwardAction,
  rejectAvailableCampaignAction,
} from "./actions";

export const receiveContactsReducer: Reducer<LoadingContacts, AnyAction> =
  genericReducer(
    receiveContactsForForwardAction.request,
    receiveContactsForForwardAction.success,
    receiveContactsForForwardAction.failure,
    "contacts"
  );

export const receiveProductsReducer: Reducer<
  LoadingProductsByAdvertiser,
  AnyAction
> = genericReducer(
  receiveProductsForForwardAction.request,
  receiveProductsForForwardAction.success,
  receiveProductsForForwardAction.failure,
  "advertisers"
);

export const forwardCampaignReducer: Reducer<
  LoadingCampaignForwarded,
  AnyAction
> = genericReducer(
  forwardCampaignAction.request,
  forwardCampaignAction.success,
  forwardCampaignAction.failure,
  undefined,
  clearForwardCampaignAction
);

export const receiveAvailableCampaignsReducer: Reducer<
  LoadingAvailableCampaigns,
  AnyAction
> = genericReducer(
  receiveAvailableCampaignsAction.request,
  receiveAvailableCampaignsAction.success,
  receiveAvailableCampaignsAction.failure,
  "availableCampaigns"
);

export const receiveAvailableCampaignDetailReducer: Reducer<
  Record<number, LoadingAvailableCampaignDetail>,
  AnyAction
> = createReducer({
  state: ReduxStoreState.Initial,
})
  .handleAction(
    receiveAvailableCampaignDetailAction.request,
    (
      state: LoadingAvailableCampaignDetail,
      action: ReturnType<typeof receiveAvailableCampaignDetailAction.request>
    ) => ({
      ...state,
      [action.payload]: {
        loading: true,
        state: ReduxStoreState.Loading,
      },
    })
  )
  .handleAction(
    receiveAvailableCampaignDetailAction.failure,
    (
      state: LoadingAvailableCampaignDetail,
      action: ReturnType<typeof receiveAvailableCampaignDetailAction.failure>
    ) => ({
      ...state,
      [action.payload.id]: {
        loading: false,
        state: ReduxStoreState.Failure,
      },
    })
  )
  .handleAction(
    receiveAvailableCampaignDetailAction.success,
    (
      state: LoadingAvailableCampaignDetail,
      action: ReturnType<typeof receiveAvailableCampaignDetailAction.success>
    ) => ({
      ...state,
      [action.payload.id ?? 0]: {
        campaign: action.payload,
        loading: false,
        state: ReduxStoreState.Success,
      },
    })
  );

export const acceptAvailableCampaignReducer: Reducer<
  LoadingAcceptAvailableCampaigns,
  AnyAction
> = genericReducer(
  acceptAvailableCampaignAction.request,
  acceptAvailableCampaignAction.success,
  acceptAvailableCampaignAction.failure,
  "campaign",
  clearAcceptAvailableCampaignsAction
);

export const rejectAvailableCampaignReducer: Reducer<Loading, AnyAction> =
  genericReducer(
    rejectAvailableCampaignAction.request,
    rejectAvailableCampaignAction.success,
    rejectAvailableCampaignAction.failure,
    undefined,
    clearRejectAvailableCampaignsAction
  );
