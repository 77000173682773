import { MessageDescriptor } from "@lingui/core";
import { msg, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form, VisibleChangeFunction } from "@ster/ster-toolkit";
import { Form as AntForm, Modal, Select, Spin, Tooltip } from "antd";
import Cookies from "js-cookie";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { OperationalContextState } from "../api";
import { operationalContextStateSelector } from "../shared/selectors";
import { setOperationalContextStateAction } from "../store/account/actions";
import { LoadingPortalUser } from "../store/models";
import { defaultCookieOptions, getOrganisationCode } from "../utils";
import { impersonateCookieName } from "../utils/constants";
import { isImpersonating, isInternalUser } from "../utils/userHelper";
import OperationalContextStateForm from "./organisation/OperationalContextStateForm";

type FormValues = {
  role?: string;
  preferredOperationalContextState: OperationalContextState;
};

export const roleLabel: Record<string, MessageDescriptor> = {
  user: msg`Gebruiker`,
  intermediair: msg`Intermediair`,
};

const Impersonate = ({
  account,
  onVisibleChange,
}: {
  account: LoadingPortalUser;
  onVisibleChange: VisibleChangeFunction;
}) => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();

  const impersonating = isImpersonating(account);
  const internalUser = isInternalUser(account);

  const [form] = AntForm.useForm<FormValues>();
  const [show, setShow] = useState(false);
  const [
    operationalContextFormLoadingState,
    setOperationalContextFormLoadingState,
  ] = useState(false);

  const operationalContextState = useSelector(operationalContextStateSelector);

  const applyState = useCallback(
    (
      role: string | undefined,
      newState: OperationalContextState | null
    ): void => {
      Cookies.remove(impersonateCookieName);
      if (role) {
        Cookies.set(impersonateCookieName, role, defaultCookieOptions);
      }

      dispatch(setOperationalContextStateAction(newState));

      // setOperationalContextStateAction does a reload or navigate when the selection has changed
      // Reloading does not happen when the selection did not change and only role is changed.
      // Since role is "always" changed, we should be able to safely reload the window.
      window.location.reload();
    },
    [dispatch]
  );

  /* Show dialog with form to impersonate */
  const handleImpersonate = useCallback(() => {
    onVisibleChange(false);
    setShow(true);
  }, [onVisibleChange]);

  /* Reset impersonate cookies */
  const handleReset = useCallback(() => {
    onVisibleChange(false);

    applyState(undefined, null);
  }, [onVisibleChange, applyState]);

  const handleOk = useCallback(() => {
    form.submit();
  }, [form]);
  const handleCancel = useCallback(() => {
    form.resetFields();
    setShow(false);
  }, [form]);

  const handleFinish = useCallback(() => {
    setShow(false);
    const formData = form.getFieldsValue();
    if (!formData) {
      return;
    }

    applyState(formData.role, formData.preferredOperationalContextState);
  }, [form, applyState]);

  if (!internalUser && !impersonating) {
    return null;
  }

  return (
    <>
      {impersonating &&
        roleLabel[Cookies.get(impersonateCookieName) as string] && (
          <Tooltip title={i18n._(t`Rol en organisatie resetten?`)}>
            <button
              type="button"
              className="link context-menu__link"
              onClick={handleReset}
            >
              {i18n._(t`Rol`)}:{" "}
              {i18n._(roleLabel[Cookies.get(impersonateCookieName) as string])}{" "}
              ({getOrganisationCode(account)})
            </button>
          </Tooltip>
        )}
      {internalUser && !impersonating && (
        <button
          type="button"
          className="link context-menu__link"
          onClick={handleImpersonate}
        >
          {i18n._(t`Rol aanpassen`)}
        </button>
      )}
      {show && operationalContextState && (
        <Modal
          open
          title={i18n._(t`Rol aanpassen naar`)}
          onOk={handleOk}
          onCancel={handleCancel}
          maskClosable={false}
        >
          <Spin spinning={operationalContextFormLoadingState}>
            <Form
              layout="vertical"
              name="impersonateForm"
              form={form}
              onFinish={handleFinish}
              initialValues={{
                preferredOperationalContextState: {
                  tenant: operationalContextState.tenant,
                  organisationCode: operationalContextState.organisationCode,
                },
              }}
            >
              <Form.Item
                label={i18n._(t`Rol`)}
                name="role"
                rules={[{ required: true }]}
              >
                <Select
                  options={[
                    { label: i18n._(roleLabel.user), value: "user" },
                    {
                      label: i18n._(roleLabel.intermediair),
                      value: "intermediair",
                    },
                  ]}
                />
              </Form.Item>
              <OperationalContextStateForm
                setLoadingState={setOperationalContextFormLoadingState}
                fieldName="preferredOperationalContextState"
              />
            </Form>
          </Spin>
        </Modal>
      )}
    </>
  );
};

export default Impersonate;
