import { Icons } from "@ster/ster-toolkit";

import { MediumEnum } from "../../../../api";

interface FallbackIconProps {
  medium: MediumEnum;
}

const FallbackIcon = ({ medium }: FallbackIconProps) => {
  const { OnlineIcon, RadioIcon, TVIcon } = Icons;

  switch (medium) {
    case "radio":
      return <RadioIcon width="171" height="156" fill="#81b0d2" />;
    case "tv":
      return <TVIcon width="171" height="156" fill="#81b0d2" />;
    case "inter":
      return <OnlineIcon width="171" height="156" fill="#81b0d2" />;
    default:
      return null;
  }
};

export default FallbackIcon;
