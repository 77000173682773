/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  DateSpan,
  DateSpanFromJSON,
  DateSpanFromJSONTyped,
  DateSpanToJSON,
  GrpPricePerMonth,
  GrpPricePerMonthFromJSON,
  GrpPricePerMonthFromJSONTyped,
  GrpPricePerMonthToJSON,
  OrderStatus,
  OrderStatusFromJSON,
  OrderStatusFromJSONTyped,
  OrderStatusToJSON,
  PackageChoiceEnum,
  PackageChoiceEnumFromJSON,
  PackageChoiceEnumFromJSONTyped,
  PackageChoiceEnumToJSON,
  PackageRequest,
  PackageRequestFromJSON,
  PackageRequestFromJSONTyped,
  PackageRequestToJSON,
  RunningState,
  RunningStateFromJSON,
  RunningStateFromJSONTyped,
  RunningStateToJSON,
  Spot,
  SpotFromJSON,
  SpotFromJSONTyped,
  SpotToJSON,
  TargetGroupRequest,
  TargetGroupRequestFromJSON,
  TargetGroupRequestFromJSONTyped,
  TargetGroupRequestToJSON,
  TimeBand,
  TimeBandFromJSON,
  TimeBandFromJSONTyped,
  TimeBandToJSON,
  UpdateFields,
  UpdateFieldsFromJSON,
  UpdateFieldsFromJSONTyped,
  UpdateFieldsToJSON,
} from "./";

/**
 *
 * @export
 * @interface SubOrderDetail
 */
export interface SubOrderDetail {
  /**
   *
   * @type {string}
   * @memberof SubOrderDetail
   */
  campaignName?: string | null;
  /**
   *
   * @type {number}
   * @memberof SubOrderDetail
   */
  bookedBudget: number;
  /**
   *
   * @type {number}
   * @memberof SubOrderDetail
   */
  id: number;
  /**
   *
   * @type {Array<number>}
   * @memberof SubOrderDetail
   */
  spotLength?: Array<number> | null;
  /**
   *
   * @type {DateSpan}
   * @memberof SubOrderDetail
   */
  period: DateSpan;
  /**
   *
   * @type {PackageRequest}
   * @memberof SubOrderDetail
   */
  _package: PackageRequest;
  /**
   *
   * @type {number}
   * @memberof SubOrderDetail
   */
  requestedGrp: number;
  /**
   *
   * @type {number}
   * @memberof SubOrderDetail
   */
  requestedBudget: number;
  /**
   *
   * @type {TargetGroupRequest}
   * @memberof SubOrderDetail
   */
  targetGroup: TargetGroupRequest;
  /**
   *
   * @type {Array<GrpPricePerMonth>}
   * @memberof SubOrderDetail
   */
  grpPricePerMonth?: Array<GrpPricePerMonth> | null;
  /**
   *
   * @type {number}
   * @memberof SubOrderDetail
   */
  bookedGrp: number;
  /**
   *
   * @type {OrderStatus}
   * @memberof SubOrderDetail
   */
  status: OrderStatus;
  /**
   *
   * @type {RunningState}
   * @memberof SubOrderDetail
   */
  runningState: RunningState;
  /**
   *
   * @type {number}
   * @memberof SubOrderDetail
   */
  requestedImpressions?: number;
  /**
   *
   * @type {number}
   * @memberof SubOrderDetail
   */
  bookedImpressions?: number;
  /**
   *
   * @type {number}
   * @memberof SubOrderDetail
   */
  cpmPrice?: number;
  /**
   *
   * @type {string}
   * @memberof SubOrderDetail
   */
  lineItemId?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubOrderDetail
   */
  internetFormatId?: string | null;
  /**
   *
   * @type {number}
   * @memberof SubOrderDetail
   */
  clicks?: number | null;
  /**
   *
   * @type {number}
   * @memberof SubOrderDetail
   */
  starts?: number | null;
  /**
   *
   * @type {number}
   * @memberof SubOrderDetail
   */
  complete?: number | null;
  /**
   *
   * @type {number}
   * @memberof SubOrderDetail
   */
  readonly ctr?: number | null;
  /**
   *
   * @type {number}
   * @memberof SubOrderDetail
   */
  readonly cvr?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof SubOrderDetail
   */
  spotsEditable: boolean;
  /**
   *
   * @type {string}
   * @memberof SubOrderDetail
   */
  format?: string | null;
  /**
   *
   * @type {PackageChoiceEnum}
   * @memberof SubOrderDetail
   */
  packageChoice: PackageChoiceEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof SubOrderDetail
   */
  channels?: Array<string> | null;
  /**
   *
   * @type {number}
   * @memberof SubOrderDetail
   */
  achievedNettBudget: number;
  /**
   *
   * @type {number}
   * @memberof SubOrderDetail
   */
  achievedGrp: number;
  /**
   *
   * @type {Array<TimeBand>}
   * @memberof SubOrderDetail
   */
  timeBands: Array<TimeBand>;
  /**
   *
   * @type {number}
   * @memberof SubOrderDetail
   */
  nacalcGrp?: number | null;
  /**
   *
   * @type {Array<UpdateFields>}
   * @memberof SubOrderDetail
   */
  editableFields?: Array<UpdateFields> | null;
  /**
   *
   * @type {string}
   * @memberof SubOrderDetail
   */
  adScienceId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof SubOrderDetail
   */
  hasMaterial?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof SubOrderDetail
   */
  bonusBudgetRequested?: number;
  /**
   *
   * @type {number}
   * @memberof SubOrderDetail
   */
  bonusBudgetBooked?: number;
  /**
   *
   * @type {Array<Spot>}
   * @memberof SubOrderDetail
   */
  spotSelection?: Array<Spot> | null;
  /**
   *
   * @type {boolean}
   * @memberof SubOrderDetail
   */
  hasBreakSelection?: boolean;
  /**
   *
   * @type {Date}
   * @memberof SubOrderDetail
   */
  requestDate?: Date;
}

export function SubOrderDetailFromJSON(json: any): SubOrderDetail {
  return SubOrderDetailFromJSONTyped(json, false);
}

export function SubOrderDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SubOrderDetail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    campaignName: !exists(json, "campaignName")
      ? undefined
      : json["campaignName"],
    bookedBudget: json["bookedBudget"],
    id: json["id"],
    spotLength: !exists(json, "spotLength") ? undefined : json["spotLength"],
    period: DateSpanFromJSON(json["period"]),
    _package: PackageRequestFromJSON(json["package"]),
    requestedGrp: json["requestedGrp"],
    requestedBudget: json["requestedBudget"],
    targetGroup: TargetGroupRequestFromJSON(json["targetGroup"]),
    grpPricePerMonth: !exists(json, "grpPricePerMonth")
      ? undefined
      : json["grpPricePerMonth"] === null
        ? null
        : (json["grpPricePerMonth"] as Array<any>).map(
            GrpPricePerMonthFromJSON
          ),
    bookedGrp: json["bookedGrp"],
    status: OrderStatusFromJSON(json["status"]),
    runningState: RunningStateFromJSON(json["runningState"]),
    requestedImpressions: !exists(json, "requestedImpressions")
      ? undefined
      : json["requestedImpressions"],
    bookedImpressions: !exists(json, "bookedImpressions")
      ? undefined
      : json["bookedImpressions"],
    cpmPrice: !exists(json, "cpmPrice") ? undefined : json["cpmPrice"],
    lineItemId: !exists(json, "lineItemId") ? undefined : json["lineItemId"],
    internetFormatId: !exists(json, "internetFormatId")
      ? undefined
      : json["internetFormatId"],
    clicks: !exists(json, "clicks") ? undefined : json["clicks"],
    starts: !exists(json, "starts") ? undefined : json["starts"],
    complete: !exists(json, "complete") ? undefined : json["complete"],
    ctr: !exists(json, "ctr") ? undefined : json["ctr"],
    cvr: !exists(json, "cvr") ? undefined : json["cvr"],
    spotsEditable: json["spotsEditable"],
    format: !exists(json, "format") ? undefined : json["format"],
    packageChoice: PackageChoiceEnumFromJSON(json["packageChoice"]),
    channels: !exists(json, "channels") ? undefined : json["channels"],
    achievedNettBudget: json["achievedNettBudget"],
    achievedGrp: json["achievedGrp"],
    timeBands: (json["timeBands"] as Array<any>).map(TimeBandFromJSON),
    nacalcGrp: !exists(json, "nacalcGrp") ? undefined : json["nacalcGrp"],
    editableFields: !exists(json, "editableFields")
      ? undefined
      : json["editableFields"] === null
        ? null
        : (json["editableFields"] as Array<any>).map(UpdateFieldsFromJSON),
    adScienceId: !exists(json, "adScienceId") ? undefined : json["adScienceId"],
    hasMaterial: !exists(json, "hasMaterial") ? undefined : json["hasMaterial"],
    bonusBudgetRequested: !exists(json, "bonusBudgetRequested")
      ? undefined
      : json["bonusBudgetRequested"],
    bonusBudgetBooked: !exists(json, "bonusBudgetBooked")
      ? undefined
      : json["bonusBudgetBooked"],
    spotSelection: !exists(json, "spotSelection")
      ? undefined
      : json["spotSelection"] === null
        ? null
        : (json["spotSelection"] as Array<any>).map(SpotFromJSON),
    hasBreakSelection: !exists(json, "hasBreakSelection")
      ? undefined
      : json["hasBreakSelection"],
    requestDate: !exists(json, "requestDate")
      ? undefined
      : new Date(json["requestDate"]),
  };
}

export function SubOrderDetailToJSON(value?: SubOrderDetail | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    campaignName: value.campaignName,
    bookedBudget: value.bookedBudget,
    id: value.id,
    spotLength: value.spotLength,
    period: DateSpanToJSON(value.period),
    package: PackageRequestToJSON(value._package),
    requestedGrp: value.requestedGrp,
    requestedBudget: value.requestedBudget,
    targetGroup: TargetGroupRequestToJSON(value.targetGroup),
    grpPricePerMonth:
      value.grpPricePerMonth === undefined
        ? undefined
        : value.grpPricePerMonth === null
          ? null
          : (value.grpPricePerMonth as Array<any>).map(GrpPricePerMonthToJSON),
    bookedGrp: value.bookedGrp,
    status: OrderStatusToJSON(value.status),
    runningState: RunningStateToJSON(value.runningState),
    requestedImpressions: value.requestedImpressions,
    bookedImpressions: value.bookedImpressions,
    cpmPrice: value.cpmPrice,
    lineItemId: value.lineItemId,
    internetFormatId: value.internetFormatId,
    clicks: value.clicks,
    starts: value.starts,
    complete: value.complete,
    spotsEditable: value.spotsEditable,
    format: value.format,
    packageChoice: PackageChoiceEnumToJSON(value.packageChoice),
    channels: value.channels,
    achievedNettBudget: value.achievedNettBudget,
    achievedGrp: value.achievedGrp,
    timeBands: (value.timeBands as Array<any>).map(TimeBandToJSON),
    nacalcGrp: value.nacalcGrp,
    editableFields:
      value.editableFields === undefined
        ? undefined
        : value.editableFields === null
          ? null
          : (value.editableFields as Array<any>).map(UpdateFieldsToJSON),
    adScienceId: value.adScienceId,
    hasMaterial: value.hasMaterial,
    bonusBudgetRequested: value.bonusBudgetRequested,
    bonusBudgetBooked: value.bonusBudgetBooked,
    spotSelection:
      value.spotSelection === undefined
        ? undefined
        : value.spotSelection === null
          ? null
          : (value.spotSelection as Array<any>).map(SpotToJSON),
    hasBreakSelection: value.hasBreakSelection,
    requestDate:
      value.requestDate === undefined
        ? undefined
        : value.requestDate.toISOString(),
  };
}
