import { createAsyncAction } from "typesafe-actions";

import {
  MediumEnum,
  SpotResultOutput,
  SpotTrackerRequestInput,
} from "../../api";
import { SpotAnalysisColumn } from "../../components/analysis/SpotAnalysisList";
import { ResponseError } from "../base";
import { actionNameAsync } from "../utils";

export const receiveSpotTrackerAction = createAsyncAction(
  actionNameAsync("spotAnalysis", "REQUEST"),
  actionNameAsync("spotAnalysis", "SUCCESS"),
  actionNameAsync("spotAnalysis", "FAILURE")
)<
  {
    spotTrackerRequestInput: SpotTrackerRequestInput;
    medium: MediumEnum;
  },
  SpotResultOutput[],
  ResponseError
>();

export const exportSpotTrackerAction = createAsyncAction(
  actionNameAsync("exportSpotAnalysis", "REQUEST"),
  actionNameAsync("exportSpotAnalysis", "SUCCESS"),
  actionNameAsync("exportSpotAnalysis", "FAILURE")
)<
  {
    spotTrackerRequestInput: SpotTrackerRequestInput;
    selectedColumns: SpotAnalysisColumn[];
    medium: MediumEnum;
  },
  Blob,
  ResponseError
>();
