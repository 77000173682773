export const loginUrl =
  process.env.NODE_ENV !== "development"
    ? "https://inloggen.ster.nl/"
    : "http://localhost:5000/";

const getNextUrl = () => window.sessionStorage.getItem("ster-portal-next");

export const loginWithRedirect = (): string => {
  const nextFromStorage = getNextUrl();
  const encodedRedirect = encodeURIComponent(
    nextFromStorage ?? `${window.location.protocol}//${window.location.host}`
  );
  return `${loginUrl}?next=${encodedRedirect}`;
};
