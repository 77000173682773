/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum BookSpotAction {
  Book = "Book",
  Cancel = "Cancel",
}

export function BookSpotActionFromJSON(json: any): BookSpotAction {
  return BookSpotActionFromJSONTyped(json, false);
}

export function BookSpotActionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BookSpotAction {
  return json as BookSpotAction;
}

export function BookSpotActionToJSON(value?: BookSpotAction | null): any {
  return value as any;
}
