import { i18n } from "@lingui/core";
import { Trans, t } from "@lingui/macro";
import {
  Button,
  Container,
  ContentContainer,
  Radio,
  Row,
  Spinner,
  Typography,
} from "@ster/ster-toolkit";
import { Col, List, Space } from "antd";
import { RadioChangeEvent } from "antd/lib";
import moment from "moment";
import {
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";

import { AnnouncementDto, TenantEnum } from "../../api/models";
import {
  deleteAnnouncementAction,
  receiveAnnouncementsAction,
  upsertAnnouncementAction,
} from "../../store/announcements/actions";
import { emptyAnnouncements } from "../../store/announcements/reducers";
import { ReduxStoreState } from "../../store/base";
import { StoreModel } from "../../store/models";
import { tenantTranslations } from "../organisation/OperationalContextStateForm";
import shared from "../Shared.module.less";
import AnnouncementItem from "./AnnouncementItem";
import styles from "./Announcements.module.less";

const AnnouncementsContainer = memo(() => {
  const [selectedTenant, setSelectedTenant] = useState<TenantEnum>(
    TenantEnum.National
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(receiveAnnouncementsAction.request());
  }, [dispatch]);

  const { state, announcements = emptyAnnouncements } = useSelector(
    ({ announcements: fromStore }: StoreModel) => ({
      ...fromStore,
    })
  );

  const filteredAnnouncements = useMemo(
    () => announcements.filter((v) => v.tenant === selectedTenant),
    [announcements, selectedTenant]
  );

  const upsertAnnouncement = useCallback(
    (announcement: AnnouncementDto) => {
      dispatch(upsertAnnouncementAction.request(announcement));
    },
    [dispatch]
  );

  const handleNewAnnouncement = useCallback(() => {
    dispatch(
      upsertAnnouncementAction.request({
        message: "Voer hier de Nederlandse tekst van je bericht in...",
        messageInt: "Enter the English text of your message here...",
        startDate: moment().startOf("day").toDate(),
        endDate: moment().add(1, "week").endOf("day").toDate(),
        tenant: selectedTenant,
        show: false,
      })
    );
  }, [dispatch, selectedTenant]);

  const toggleShow = useCallback(
    (id: number) => {
      const announcement = announcements.find((a) => a.id === id);
      if (!announcement) {
        // do nothing
        return;
      }

      dispatch(
        upsertAnnouncementAction.request({
          ...announcement,
          show: !announcement.show,
        })
      );
    },
    [announcements, dispatch]
  );

  const deleteAnnouncement = useCallback(
    (id: number) => {
      dispatch(deleteAnnouncementAction.request(id));
    },
    [dispatch]
  );

  const onTenantChange = useCallback(
    (e: RadioChangeEvent) => {
      const selectedValue = e.target.value as TenantEnum;
      setSelectedTenant(selectedValue);
    },
    [setSelectedTenant]
  );

  return (
    <>
      <Helmet>
        <title>{i18n._(t`Meldingen`)}</title>
      </Helmet>

      <ContentContainer>
        <Spinner spinning={state === ReduxStoreState.Loading}>
          <Row
            className={shared.filterrow}
            justify="end"
            align="middle"
            gutter={[24, 24]}
          >
            <Col span={12}>
              <Space direction="horizontal">
                <Radio.Group
                  value={selectedTenant}
                  onChange={onTenantChange}
                  options={[
                    {
                      label: i18n._(tenantTranslations[TenantEnum.National]),
                      value: TenantEnum.National,
                    },
                    {
                      label: i18n._(tenantTranslations[TenantEnum.Regional]),
                      value: TenantEnum.Regional,
                    },
                  ]}
                  mode="horizontal"
                  optionType="button"
                  buttonStyle="solid"
                />
              </Space>
            </Col>
            <Col span={12}>
              <Space className={shared.filterspacer} direction="horizontal">
                <Button mode="primary" onClick={handleNewAnnouncement}>
                  <Trans>Nieuw bericht</Trans>
                </Button>
              </Space>
            </Col>
          </Row>
          <Row className={shared.filterrow}>
            <Col span={24}>
              <Container>
                {filteredAnnouncements.length === 0 ? (
                  <Trans>
                    <Typography.Paragraph>
                      <Trans>Er zijn geen berichten.</Trans>
                    </Typography.Paragraph>
                  </Trans>
                ) : (
                  <List
                    itemLayout="vertical"
                    size="large"
                    className={styles.fullWidth}
                    pagination={false}
                    dataSource={filteredAnnouncements}
                    renderItem={(item: AnnouncementDto): ReactNode => (
                      <AnnouncementItem
                        key={item.id}
                        item={item}
                        onChange={upsertAnnouncement}
                        toggleShow={toggleShow}
                        deleteItem={deleteAnnouncement}
                      />
                    )}
                  />
                )}
              </Container>
            </Col>
          </Row>
        </Spinner>
      </ContentContainer>
    </>
  );
});

export default AnnouncementsContainer;
