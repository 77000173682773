/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Break
 */
export interface Break {
  /**
   *
   * @type {string}
   * @memberof Break
   */
  breakId?: string | null;
  /**
   *
   * @type {Date}
   * @memberof Break
   */
  schedDate?: Date;
  /**
   *
   * @type {number}
   * @memberof Break
   */
  baseBreakPrice?: number;
  /**
   *
   * @type {string}
   * @memberof Break
   */
  channelDescr?: string | null;
  /**
   *
   * @type {number}
   * @memberof Break
   */
  grossSpotPrice?: number;
  /**
   *
   * @type {number}
   * @memberof Break
   */
  nettSpotPrice?: number;
  /**
   *
   * @type {Date}
   * @memberof Break
   */
  schedStartTime?: Date;
  /**
   *
   * @type {string}
   * @memberof Break
   */
  preferredPosition?: string | null;
  /**
   *
   * @type {string}
   * @memberof Break
   */
  programmeBefore?: string | null;
  /**
   *
   * @type {string}
   * @memberof Break
   */
  programmeAfter?: string | null;
  /**
   *
   * @type {number}
   * @memberof Break
   */
  selectivitySurcharge?: number;
  /**
   *
   * @type {string}
   * @memberof Break
   */
  readonly key?: string | null;
}

export function BreakFromJSON(json: any): Break {
  return BreakFromJSONTyped(json, false);
}

export function BreakFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Break {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    breakId: !exists(json, "breakId") ? undefined : json["breakId"],
    schedDate: !exists(json, "schedDate")
      ? undefined
      : new Date(json["schedDate"]),
    baseBreakPrice: !exists(json, "baseBreakPrice")
      ? undefined
      : json["baseBreakPrice"],
    channelDescr: !exists(json, "channelDescr")
      ? undefined
      : json["channelDescr"],
    grossSpotPrice: !exists(json, "grossSpotPrice")
      ? undefined
      : json["grossSpotPrice"],
    nettSpotPrice: !exists(json, "nettSpotPrice")
      ? undefined
      : json["nettSpotPrice"],
    schedStartTime: !exists(json, "schedStartTime")
      ? undefined
      : new Date(json["schedStartTime"]),
    preferredPosition: !exists(json, "preferredPosition")
      ? undefined
      : json["preferredPosition"],
    programmeBefore: !exists(json, "programmeBefore")
      ? undefined
      : json["programmeBefore"],
    programmeAfter: !exists(json, "programmeAfter")
      ? undefined
      : json["programmeAfter"],
    selectivitySurcharge: !exists(json, "selectivitySurcharge")
      ? undefined
      : json["selectivitySurcharge"],
    key: !exists(json, "key") ? undefined : json["key"],
  };
}

export function BreakToJSON(value?: Break | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    breakId: value.breakId,
    schedDate:
      value.schedDate === undefined ? undefined : value.schedDate.toISOString(),
    baseBreakPrice: value.baseBreakPrice,
    channelDescr: value.channelDescr,
    grossSpotPrice: value.grossSpotPrice,
    nettSpotPrice: value.nettSpotPrice,
    schedStartTime:
      value.schedStartTime === undefined
        ? undefined
        : value.schedStartTime.toISOString(),
    preferredPosition: value.preferredPosition,
    programmeBefore: value.programmeBefore,
    programmeAfter: value.programmeAfter,
    selectivitySurcharge: value.selectivitySurcharge,
  };
}
