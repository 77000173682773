import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Divider } from "@ster/ster-toolkit";
import { memo } from "react";

import { FormReadOnlyItem } from "../../partials/Form";
import { CampaignDetailReadProps } from "../models";
import DetailsCrm from "./DetailsCrm";
import DetailsReadPeriodAdvertiser from "./DetailsReadPeriodAdvertiser";

const DetailsRead = memo(
  ({ order, productsByAdvertiser, settings }: CampaignDetailReadProps) => {
    const { i18n } = useLingui();

    return (
      <>
        <DetailsReadPeriodAdvertiser
          order={order}
          productsByAdvertiser={productsByAdvertiser}
          showDigitalReportWarning={settings.showDigitalReportWarning}
        />
        <DetailsCrm editable={false} {...order} />
        <FormReadOnlyItem
          label={i18n._(t`Campagnenaam`)}
          value={order?.campaignName}
        />
        <FormReadOnlyItem
          label={i18n._(t`Opmerkingen`)}
          value={order?.comments}
        />
        <FormReadOnlyItem
          label={i18n._(t`Factuurnummer`)}
          value={order?.invoice}
        />
        {order?.contact && (
          <>
            <Divider plain orientation="left">
              {i18n._(t`Contactpersoon`)}
            </Divider>
            <FormReadOnlyItem label={i18n._(t`Naam`)} value={order?.contact} />
            <FormReadOnlyItem
              label={i18n._(t`E-mailadres`)}
              value={order?.contactEmailExtra}
            />
          </>
        )}
      </>
    );
  }
);

export default DetailsRead;
