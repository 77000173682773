import { createAsyncAction } from "typesafe-actions";

import { EditableSetting } from "../../api";
import { ResponseError } from "../base";
import { actionNameAsync } from "../utils";
import { EditableSettingPayload } from "./models";

export const getEditableSettingsAction = createAsyncAction(
  actionNameAsync("getEditableSettings", "REQUEST"),
  actionNameAsync("getEditableSettings", "SUCCESS"),
  actionNameAsync("getEditableSettings", "FAILURE")
)<undefined, EditableSetting[], ResponseError>();

export const updateEditableSettingAction = createAsyncAction(
  actionNameAsync("updateEditableSetting", "REQUEST"),
  actionNameAsync("updateEditableSetting", "SUCCESS"),
  actionNameAsync("updateEditableSetting", "FAILURE")
)<EditableSettingPayload, EditableSetting, ResponseError>();
