import { Trans } from "@lingui/macro";
import { Alert } from "antd";
import { useMemo } from "react";

import { SubOrderRequest } from "../../api";
import {
  campaignCostDecreaseWarning,
  campaignCostIncreaseWarning,
} from "../../utils/constants";
import styles from "./BudgetDecreaseIncreaseWarning.module.less";

const BudgetDecreaseIncreaseWarning = ({
  subOrders,
  originalBudget,
}: {
  subOrders?: SubOrderRequest[] | null;
  originalBudget?: number;
}) => {
  const showBudgetDecreaseWarning = useMemo(
    () =>
      subOrders &&
      subOrders?.reduce((sum, s) => sum + s.budget, 0) <
        (originalBudget ?? 0) * ((100 - campaignCostDecreaseWarning) / 100),
    [originalBudget, subOrders]
  );

  const showBudgetIncreaseWarning = useMemo(
    () =>
      subOrders &&
      subOrders?.reduce((sum, s) => sum + s.budget, 0) >
        (originalBudget ?? 0) * ((100 + campaignCostIncreaseWarning) / 100),
    [originalBudget, subOrders]
  );

  return (
    <>
      {showBudgetDecreaseWarning && (
        <div className={styles.budgetWarning}>
          <Alert
            showIcon
            type="warning"
            message=""
            description={
              <Trans>
                <b>Let op:</b> je hebt het budget van de campagne met meer dan
                10% verlaagd. Conform onze{" "}
                <a
                  href="https://www.ster.nl/voorwaarden/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Algemene Voorwaarden
                </a>{" "}
                zullen er annuleringskosten in rekening gebracht worden.
              </Trans>
            }
          />
        </div>
      )}
      {showBudgetIncreaseWarning && (
        <div className={styles.budgetWarning}>
          <Alert
            showIcon
            type="warning"
            message=""
            description={
              <Trans>
                <b>Let op:</b> je hebt het budget van de campagne met meer dan
                25% verhoogd. Conform onze{" "}
                <a
                  href="https://www.ster.nl/voorwaarden/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Algemene Voorwaarden
                </a>{" "}
                kan er een marktindex van toepassing zijn op een deel van de
                campagne. Deze eventuele marktindex is nog niet meegenomen in de
                huidige berekening.
              </Trans>
            }
          />
        </div>
      )}
    </>
  );
};

export default BudgetDecreaseIncreaseWarning;
