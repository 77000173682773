/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum RuleType {
  Discount = "Discount",
  Surtax = "Surtax",
}

export function RuleTypeFromJSON(json: any): RuleType {
  return RuleTypeFromJSONTyped(json, false);
}

export function RuleTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RuleType {
  return json as RuleType;
}

export function RuleTypeToJSON(value?: RuleType | null): any {
  return value as any;
}
