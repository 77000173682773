import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  ContentContainer,
  Icons,
  Pageheader,
  Spinner,
  Typography,
} from "@ster/ster-toolkit";
import { App as AntApp, Space, Tooltip } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import classnames from "classnames";
import { parseJSON } from "date-fns";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import TagManager from "react-gtm-module";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { MediumEnum, OrderListItem, OrderStatus } from "../../api";
import { Period } from "../../shared/models";
import { ReduxStoreState } from "../../store/base";
import {
  clearDeleteCampaignAction,
  deleteCampaignAction,
  receiveCampaignsAction,
} from "../../store/campaigns/actions";
import { StoreModel } from "../../store/models";
import { getOrganisationCode, isNumber, useQuery } from "../../utils";
import { internalAdverteerder } from "../../utils/constants";
import { getDateFromString, getFormattedDate } from "../../utils/dateHelper";
import { useAvailableMediumTypes } from "../../utils/hooks";
import { canProposeMultipleCampaigns } from "../../utils/userHelper";
import Notification from "../Notification";
import CampaignAnalysisNmoNotification from "../partials/CampaignAnalysis/CampaignAnalysisNmoNotification";
import GenerateFormattedDocument from "../proposal/GenerateFormattedDocument";
import ProposalRequest from "../proposal/ProposalRequest";
import CampaignList from "./CampaignList";
import styles from "./CampaignList.module.less";
import { campaignsAccountSelector, campaignsRootSelector } from "./selectors";

const emptyStatusFilters: CheckboxValueType[] = [];

const CampaignContainer = memo(() => {
  const params = useQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // fill state from querystring
  const prevEmail = params.get("email");
  const prevMedium = params.get("medium");
  const prevAdvertiser = params.get("advertiser");
  const prevProduct = params.get("product");
  const prevFrom = params.get("from");
  const prevTo = params.get("to");
  const prevStatus = params.get("status");
  const prevSelected = params.get("selected");
  const prevEditable = params.get("editable");

  const { i18n } = useLingui();
  const { modal, notification } = AntApp.useApp();
  const [mediumFilter, updateMediumFilter] = useState("");
  const [availableMediumTypes, firstMediumType] =
    useAvailableMediumTypes("campagnes");
  const [availableMediumTypesRequest] = useAvailableMediumTypes(
    "aanvragen_formulier"
  );

  const [showFilters, updateShowFilters] = useState(false);
  const [advertiserFilter, updateAdvertiserFilter] = useState<
    string | number | null
  >(null);
  const [productFilter, updateProductFilter] = useState<number | null>(null);
  const [statusFilter, updateStatusFilter] =
    useState<CheckboxValueType[]>(emptyStatusFilters);
  const [allStatusFilter, updateAllStatusFilter] = useState<boolean>(true);
  const [editableFilter, updateEditableFilter] = useState<boolean>(false);
  const [selectCampaigns, updateSelectCampaigns] = useState(
    (prevSelected?.length ?? 0) > 0
  );
  const [selectedCampaigns, updateSelectedCampaigns] = useState<
    OrderListItem[]
  >([]);

  const { account } = useSelector(campaignsAccountSelector);
  // Voor de test organisatie tonen we default alleen de eigen campagnes
  const [emailFilter, updateEmailFilter] = useState<boolean>(
    getOrganisationCode(account) === internalAdverteerder
  );

  const {
    campaignsData: { campaigns, advertisers, prospectAdvertisers },
    period: initialPeriod,
    deleteCampaignState,
    loading,
    settings,
  } = useSelector((state: StoreModel) =>
    campaignsRootSelector(state, emailFilter)
  );

  const getPeriodFromQueryStringParams = (
    from: string | null,
    to: string | null,
    fallback: Period
  ): Period =>
    from && to
      ? {
          from: getDateFromString(from),
          to: getDateFromString(to),
        }
      : fallback;

  const [period, setPeriod] = useState<Period>(
    getPeriodFromQueryStringParams(prevFrom, prevTo, initialPeriod)
  );

  useEffect(
    () =>
      setPeriod(
        getPeriodFromQueryStringParams(prevFrom, prevTo, initialPeriod)
      ),
    [prevFrom, prevTo, initialPeriod]
  );

  useEffect(() => {
    // Als de gekozen adverteerder niet bestaat resetten we de keuze
    if (
      !(
        advertisers.some((s) => s.id === advertiserFilter) ||
        prospectAdvertisers.some((s) => s.id === advertiserFilter)
      )
    ) {
      updateAdvertiserFilter(null);
    }
  }, [advertiserFilter, advertisers, prospectAdvertisers]);

  useEffect(() => {
    // Vul de geselecteerde campagnes vanuit de querystring
    if (campaigns.length > 0 && prevSelected) {
      updateSelectedCampaigns(
        campaigns.filter(({ id }) =>
          prevSelected.split(",").includes(id.toString())
        )
      );
    }
  }, [campaigns, prevSelected]);

  /**
   * Set initial filter-state from query-string + update state
   */
  useEffect(() => {
    updateMediumFilter(
      prevMedium ??
        (availableMediumTypes.length === 1 && firstMediumType
          ? firstMediumType
          : "")
    );

    if (prevEmail) {
      updateEmailFilter(prevEmail === "true");
    }

    if (
      prevAdvertiser &&
      isNumber(prevAdvertiser) &&
      advertisers.some((s) => s.id.toString() === prevAdvertiser)
    ) {
      updateAdvertiserFilter(parseInt(prevAdvertiser, 10));
    } else if (prospectAdvertisers.some((s) => s.id === prevAdvertiser)) {
      updateAdvertiserFilter(prevAdvertiser);
    } else {
      updateAdvertiserFilter(null);
    }

    updateProductFilter(prevProduct ? parseInt(prevProduct, 10) : null);

    if (prevStatus) {
      updateStatusFilter(prevStatus.split(","));
      updateAllStatusFilter(false);
    } else {
      updateStatusFilter(emptyStatusFilters);
      updateAllStatusFilter(true);
    }

    updateEditableFilter(prevEditable === "true");
  }, [
    advertiserFilter,
    advertisers,
    availableMediumTypes,
    prospectAdvertisers,
    firstMediumType,
    initialPeriod,
    prevAdvertiser,
    prevEmail,
    prevFrom,
    prevMedium,
    prevProduct,
    prevStatus,
    prevTo,
    prevEditable,
  ]);

  const getCampaigns = useCallback(
    (campaignPeriod: Period) => {
      const { from, to } = campaignPeriod;
      if (from && to) {
        dispatch(receiveCampaignsAction.request({ from, to }));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (deleteCampaignState === ReduxStoreState.Success) {
      notification.success({
        message: i18n._(t`Concept verwijderd`),
        description: i18n._(t`Het concept is verwijderd.`),
        duration: 10,
      });
      getCampaigns(period);
      dispatch(clearDeleteCampaignAction());
    }
  }, [deleteCampaignState, dispatch, getCampaigns, i18n, notification, period]);

  const newCampaign = useCallback(() => {
    navigate("/campaigns/new", { state: { from: window.location.search } });
    TagManager.dataLayer({
      dataLayer: {
        event: "Concept aangemaakt",
      },
    });
  }, [navigate]);

  const toggleFilters = useCallback(() => {
    updateShowFilters(!showFilters);
  }, [showFilters]);

  const handleDeleteCampaign = useCallback(
    ({ medium, id, startDate, endDate }: OrderListItem) => {
      if (id) {
        modal.confirm({
          title: i18n._(t`Weet je het zeker?`),
          content: (
            <Typography.Paragraph>
              {i18n._(
                t`Weet je zeker dat je dit concept wilt verwijderen? Deze actie is niet terug te draaien.`
              )}
            </Typography.Paragraph>
          ),
          onOk: () => {
            dispatch(
              deleteCampaignAction.request({
                initialRequestId: id,
                medium,
                dateFrom: startDate,
                dateUntil: endDate,
              })
            );
          },
          cancelText: i18n._(t`Behouden`),
          okText: i18n._(t`Verwijderen`),
        });
      }
    },
    [dispatch, i18n, modal]
  );

  const filters = useMemo(
    () => ({
      advertisers: {
        items: advertisers,
        extra: prospectAdvertisers,
        selected: advertiserFilter,
      },
      products: { selected: productFilter },
      medium: { selected: mediumFilter },
      date: { selected: period },
      statusses: { selected: statusFilter, allSelected: allStatusFilter },
      email: {
        value: emailFilter,
        show: getOrganisationCode(account) === internalAdverteerder,
      },
      editable: editableFilter,
    }),
    [
      advertisers,
      prospectAdvertisers,
      advertiserFilter,
      productFilter,
      mediumFilter,
      period,
      statusFilter,
      allStatusFilter,
      emailFilter,
      account,
      editableFilter,
    ]
  );

  useEffect(() => {
    getCampaigns(period);
  }, [getCampaigns, period]);

  const clearSelectCampaigns = useCallback(() => {
    updateSelectedCampaigns([]);
    updateSelectCampaigns(false);
  }, []);

  const handleSelectCampaign = useCallback(
    (campaign: OrderListItem) => {
      const idx = selectedCampaigns.findIndex((s) => s.id === campaign.id);
      if (idx !== -1) {
        // Verwijderen
        updateSelectedCampaigns(
          selectedCampaigns.filter((s) => s.id !== campaign.id)
        );
      } else {
        // Toevoegen
        updateSelectedCampaigns([...selectedCampaigns, campaign]);
      }
    },
    [selectedCampaigns]
  );

  const selectedCampaignsFirstStartDate = useMemo(
    () =>
      selectedCampaigns
        .map((s) => s.startDate)
        .sort((a, b) => Number(new Date(a)) - Number(new Date(b)))[0],
    [selectedCampaigns]
  );

  const selectedCampaignsLastEndDate = useMemo(
    () =>
      selectedCampaigns
        .map((s) => s.endDate)
        .sort((a, b) => Number(new Date(b)) - Number(new Date(a)))[0],
    [selectedCampaigns]
  );

  const filterEditable = useCallback(
    (campaign: OrderListItem) =>
      campaign.isEditable &&
      campaign.spreadedOrderId == null &&
      availableMediumTypesRequest.includes(campaign.medium) &&
      settings?.settings?.enableEditCampaign?.[campaign.medium],
    [availableMediumTypesRequest, settings?.settings?.enableEditCampaign]
  );

  const filteredCampaigns = useMemo(() => {
    let filtered = campaigns;
    if (filters.medium.selected !== "") {
      filtered = filtered.filter(
        (campaign) => campaign.medium === filters.medium.selected
      );
    }

    if (
      filters.advertisers.selected &&
      isNumber(filters.advertisers.selected)
    ) {
      filtered = filtered.filter(
        (campaign) =>
          campaign.advertiserId === filters.advertisers.selected &&
          !campaign.isProspect
      );
    } else if (filters.advertisers.selected) {
      filtered = filtered.filter(
        (campaign) => campaign.prospectId === filters.advertisers.selected
      );
    }

    if (filters.email.value) {
      filtered = filtered.filter(
        (campaign) => campaign.contactPersonAgencyEmail === account.userName
      );
    }

    if (filters.statusses.selected?.length > 0) {
      filtered = filtered.filter((campaign) =>
        filters.statusses.selected.includes(campaign.status.toString())
      );
    }

    if (filters.products.selected) {
      filtered = filtered.filter(
        (campaign) => campaign.productId === filters.products.selected
      );
    }

    if (filters.editable) {
      filtered = filtered.filter((campaign) => filterEditable(campaign));
    }

    return filtered;
  }, [
    account.userName,
    campaigns,
    filterEditable,
    filters.advertisers.selected,
    filters.editable,
    filters.email.value,
    filters.medium.selected,
    filters.products.selected,
    filters.statusses.selected,
  ]);

  const proposalDisabled = useMemo(() => {
    const orders = filteredCampaigns.filter(
      (s) => statusFilter.length === 0 || statusFilter.includes(s.status)
    );

    // Een voorstel kan alleen op 1 mediumtype en 1 adverteerder uitgedraaid worden
    const mediumTypes = new Set(orders.map((s) => s.medium));

    return (
      loading ||
      orders.length === 0 ||
      orders.find((s) => s.status === OrderStatus.Concept) === undefined ||
      mediumTypes.size > 1 ||
      new Set(orders.map((s) => s.advertiserId)).size > 1
    );
  }, [filteredCampaigns, loading, statusFilter]);

  const handleProposalGenerate = useCallback(() => {
    if (proposalDisabled) {
      modal.warning({
        title: i18n._(t`Voorstel aanvragen`),
        content: (
          <Typography.Paragraph>
            {i18n._(
              t`Kies één medium en één adverteerder met concepten om een voorstel aan te vragen.`
            )}
          </Typography.Paragraph>
        ),
      });
    } else {
      modal.confirm({
        title: i18n._(t`Voorstel aanvragen`),
        content: (
          <Typography.Paragraph>
            {i18n._(
              t`Wil je één of meer conceptcampagnes als voorstel in je mail ontvangen?`
            )}
            <br />
            {i18n._(
              t`Selecteer de gewenste concepten en druk daarna nogmaals op de knop 'Voorstel'.`
            )}
          </Typography.Paragraph>
        ),
        onOk: () => {
          updateSelectCampaigns(true);
        },
        onCancel: () => {
          clearSelectCampaigns();
        },
        cancelText: i18n._(t`Annuleren`),
        okText: i18n._(t`Selecteer concepten`),
      });
    }
  }, [clearSelectCampaigns, i18n, modal, proposalDisabled]);

  const showCampaignAnalysisNmoNotification = useMemo(
    () =>
      Boolean(
        settings.settings?.enableCampaignAnalysis?.[MediumEnum.Tv] ?? true
      ) &&
      Boolean(
        settings.settings?.enableCampaignAnalysisNmoNotification ?? true
      ) &&
      settings.settings?.nmoSwitchDate,
    [
      settings.settings?.enableCampaignAnalysis,
      settings.settings?.enableCampaignAnalysisNmoNotification,
      settings.settings?.nmoSwitchDate,
    ]
  );

  return (
    <>
      <Helmet>
        <title>{i18n._(t`Campagnes`)}</title>
      </Helmet>

      <Pageheader
        title={<Trans>Campagnes</Trans>}
        icon={
          <Icons.CampaignIcon
            width="100%"
            height="100%"
            fill="rgba(129, 176, 210, 0.2)"
          />
        }
      >
        <Space wrap className={styles.buttonBar}>
          <Button
            mode="tertiary"
            className={styles.filterButton}
            onClick={toggleFilters}
          >
            <Trans>Filters</Trans>
          </Button>
          {account.sterInlog.internalUser && !selectCampaigns && (
            <GenerateFormattedDocument
              advertisers={advertisers}
              organisation={getOrganisationCode(account)}
            />
          )}
          {!selectCampaigns && (
            <Tooltip
              title={
                proposalDisabled ? (
                  <Trans>
                    Kies één medium en één adverteerder met concepten om een
                    voorstel aan te vragen.
                  </Trans>
                ) : undefined
              }
              placement="bottom"
              overlayClassName={styles.proposalTooltip}
            >
              <span
                className={classnames(styles.proposalContainer, {
                  [styles.proposalDisabled]: proposalDisabled,
                })}
              >
                <Button mode="tertiary" onClick={handleProposalGenerate}>
                  <Icons.PdfIcon fill="#008ccc" />
                  <Trans>Voorstel</Trans>
                </Button>
              </span>
            </Tooltip>
          )}
          {selectCampaigns && (
            <>
              <Button mode="tertiary" onClick={clearSelectCampaigns}>
                <Trans>Annuleer selectie</Trans>
              </Button>
              {canProposeMultipleCampaigns(account) ? (
                <Link
                  to={{
                    pathname: `/campaigns/proposal/${
                      selectedCampaigns[0]?.medium
                    }/${selectedCampaigns
                      .map((s) => s.id)
                      .join(",")}/${getFormattedDate(
                      selectedCampaignsFirstStartDate
                    )}/${getFormattedDate(selectedCampaignsLastEndDate)}`,
                  }}
                  state={{ from: window.location.search }}
                >
                  <Button
                    mode="tertiary"
                    disabled={selectedCampaigns.length === 0}
                  >
                    <Icons.PdfIcon fill="#008ccc" />
                    <Trans>
                      Voorstel en prognose voor {selectedCampaigns.length}{" "}
                      concept(en)
                    </Trans>
                  </Button>
                </Link>
              ) : (
                <ProposalRequest
                  selectedCampaigns={selectedCampaigns}
                  clearSelectCampaigns={clearSelectCampaigns}
                />
              )}
            </>
          )}
          {!selectCampaigns &&
            account &&
            account.claims &&
            account.claims.some((value) =>
              [
                "aanvragen_formulier_inter",
                "aanvragen_formulier_radio",
                "aanvragen_formulier_tv",
              ].includes(value)
            ) && (
              <Button
                mode="primary"
                onClick={newCampaign}
                className={styles.newCampaign}
              >
                <Trans>Nieuwe campagne</Trans>
              </Button>
            )}
        </Space>
      </Pageheader>
      <ContentContainer className="content">
        <Notification
          renderBefore={({ currentlyClosed, onClose }) =>
            showCampaignAnalysisNmoNotification && settings.settings ? (
              <CampaignAnalysisNmoNotification
                nmoSwitchDate={parseJSON(settings.settings?.nmoSwitchDate)}
                currentlyClosed={currentlyClosed}
                onClose={onClose}
              />
            ) : null
          }
        />
        <Spinner spinning={loading}>
          {settings.settings && (
            <CampaignList
              data={{
                campaigns: filteredCampaigns,
                filters,
                account,
              }}
              showFilters={showFilters}
              onShowFilters={(): void => updateShowFilters(!showFilters)}
              availableMediumTypes={availableMediumTypes}
              onDelete={handleDeleteCampaign}
              selectCampaigns={selectCampaigns}
              onSelectCampaign={handleSelectCampaign}
              filtersDisabled={selectCampaigns}
              selectedCampaigns={selectedCampaigns}
              settings={settings.settings}
            />
          )}
        </Spinner>
      </ContentContainer>
    </>
  );
});

export default CampaignContainer;
