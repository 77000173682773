import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form, TimePickerRange } from "@ster/ster-toolkit";
import { memo } from "react";

import { TimeSelectionProps } from "./models";

const TimeSelection = memo(({ medium, _package }: TimeSelectionProps) => {
  const { i18n } = useLingui();
  return (
    <Form.Item
      name="timeSelection"
      rules={[
        {
          validator: (_, value): Promise<void> =>
            value?.length >= _package.minDailyHours
              ? Promise.resolve()
              : Promise.reject(
                  new Error(
                    i18n._(
                      t`Je dient minimaal ${_package.minDailyHours} uren te selecteren`
                    )
                  )
                ),
        },
      ]}
    >
      <TimePickerRange
        consecutive={_package.hasConsecutiveHours}
        medium={medium}
        minimumAmount={_package.minDailyHours ?? 0}
        selectPlaceholder={i18n._(t`selecteer...`)}
      />
    </Form.Item>
  );
});

export default TimeSelection;
