/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface OrderIdWithDate
 */
export interface OrderIdWithDate {
  /**
   *
   * @type {number}
   * @memberof OrderIdWithDate
   */
  orderId?: number;
  /**
   *
   * @type {Date}
   * @memberof OrderIdWithDate
   */
  from?: Date;
  /**
   *
   * @type {Date}
   * @memberof OrderIdWithDate
   */
  to?: Date;
}

export function OrderIdWithDateFromJSON(json: any): OrderIdWithDate {
  return OrderIdWithDateFromJSONTyped(json, false);
}

export function OrderIdWithDateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OrderIdWithDate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    orderId: !exists(json, "orderId") ? undefined : json["orderId"],
    from: !exists(json, "from") ? undefined : new Date(json["from"]),
    to: !exists(json, "to") ? undefined : new Date(json["to"]),
  };
}

export function OrderIdWithDateToJSON(value?: OrderIdWithDate | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    orderId: value.orderId,
    from: value.from === undefined ? undefined : value.from.toISOString(),
    to: value.to === undefined ? undefined : value.to.toISOString(),
  };
}
