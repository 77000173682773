import { Invoice } from "../../api";
import {
  createStructuredAppSelector,
  previousMonthSelector,
} from "../../shared/selectors";
import { StoreModel } from "../../store/models";
import { emptyArray } from "../../utils/constants";

const invoicesSelector = ({
  invoices: invoicesFromStore,
}: StoreModel): Invoice[] => invoicesFromStore.invoices ?? emptyArray;

const invoicesLoadingSelector = ({
  invoices: invoicesFromStore,
}: StoreModel): boolean => invoicesFromStore.loading;

// eslint-disable-next-line import/prefer-default-export
export const invoicesRootSelector = createStructuredAppSelector({
  period: previousMonthSelector,
  invoices: invoicesSelector,
  loading: invoicesLoadingSelector,
});
