/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SpotResultGrpOutput
 */
export interface SpotResultGrpOutput {
  /**
   *
   * @type {string}
   * @memberof SpotResultGrpOutput
   */
  targetGroup?: string | null;
  /**
   *
   * @type {string}
   * @memberof SpotResultGrpOutput
   */
  displayName?: string | null;
  /**
   *
   * @type {number}
   * @memberof SpotResultGrpOutput
   */
  grps?: number;
  /**
   *
   * @type {number}
   * @memberof SpotResultGrpOutput
   */
  blockAvarage?: number | null;
}

export function SpotResultGrpOutputFromJSON(json: any): SpotResultGrpOutput {
  return SpotResultGrpOutputFromJSONTyped(json, false);
}

export function SpotResultGrpOutputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SpotResultGrpOutput {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    targetGroup: !exists(json, "targetGroup") ? undefined : json["targetGroup"],
    displayName: !exists(json, "displayName") ? undefined : json["displayName"],
    grps: !exists(json, "grps") ? undefined : json["grps"],
    blockAvarage: !exists(json, "blockAvarage")
      ? undefined
      : json["blockAvarage"],
  };
}

export function SpotResultGrpOutputToJSON(
  value?: SpotResultGrpOutput | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    targetGroup: value.targetGroup,
    displayName: value.displayName,
    grps: value.grps,
    blockAvarage: value.blockAvarage,
  };
}
