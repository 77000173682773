/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ClaimValue
 */
export interface ClaimValue {
  /**
   *
   * @type {string}
   * @memberof ClaimValue
   */
  type?: string | null;
  /**
   *
   * @type {string}
   * @memberof ClaimValue
   */
  description?: string | null;
}

export function ClaimValueFromJSON(json: any): ClaimValue {
  return ClaimValueFromJSONTyped(json, false);
}

export function ClaimValueFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ClaimValue {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: !exists(json, "type") ? undefined : json["type"],
    description: !exists(json, "description") ? undefined : json["description"],
  };
}

export function ClaimValueToJSON(value?: ClaimValue | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: value.type,
    description: value.description,
  };
}
