import { createSelector, createStructuredSelector } from "reselect";

import { AvailableBreak } from "../../api/models";
import { settingsSelector } from "../../shared/selectors";
import { ReduxStoreState } from "../../store/base";
import { LoadingBreaks, StoreModel } from "../../store/models";

export const breaksFromStoreSelector = (
  { breaks }: StoreModel,
  key: number
): LoadingBreaks | undefined => breaks[key];

const channelsFromBreaksSelector = createSelector(
  [breaksFromStoreSelector],
  (breaks: LoadingBreaks | undefined): string[] =>
    Array.from(new Set(breaks?.breaks?.map((b) => b.channelDescr))).sort(
      (a, b) => a.localeCompare(b)
    )
);

const breaksSelector = createSelector(
  [breaksFromStoreSelector],
  (breaks: LoadingBreaks | undefined): AvailableBreak[] => breaks?.breaks ?? []
);

const breaksStateSelector = createSelector(
  [breaksFromStoreSelector],
  (breaks: LoadingBreaks | undefined): ReduxStoreState =>
    breaks?.state ?? ReduxStoreState.Initial
);

export const breakSelectRootSelector = createStructuredSelector({
  breaks: breaksSelector,
  channels: channelsFromBreaksSelector,
  breaksState: breaksStateSelector,
  settings: settingsSelector,
});
