import { Trans } from "@lingui/macro";
import { Button, Form, Row, Spinner } from "@ster/ster-toolkit";
import { Col } from "antd";
import { addMonths, isEqual, min } from "date-fns";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  MediumEnum,
  OrderRequest,
  PackageChoiceEnum,
  SalesPeriod,
  SubOrderRequest,
  UpdateFields,
} from "../../api";
import { ReduxStoreState } from "../../store/base";
import {
  clearPackagesAction,
  receiveBookingDateAction,
  receiveForecastConversionGroupsAction,
  receivePackagesAction,
} from "../../store/campaignCreate/actions";
import { usePrevious } from "../../utils/hooks";
import { PackageStep } from "../campaignCreate/subOrder/models";
import SubOrderList from "../campaignCreate/subOrder/SubOrderList";
import Forecast from "../partials/Forecast/Forecast";
import { StepStatus } from "../partials/Steps/Steps";
import PackageCards from "./PackageCards";
import { subOrdersSelector } from "./selectors";

interface SubOrdersContainerProps {
  onSubOrderChange: (subOrder: Partial<SubOrderRequest>) => void;
  onDeleteSubOrder: (id: number) => void;
  onAddForecastConversionGroup: (value: string) => void;
  onDeleteForecastConversionGroup: (value: string) => void;
  onAddNewSubOrder: (subOrder: Partial<SubOrderRequest>) => void;
  onScrollToComments?: () => void;
  orderRequest: OrderRequest;
  orderRequestOriginal?: OrderRequest;
  stepStatusSubOrders?: StepStatus;
  selectedSalesPeriod: SalesPeriod;
  subOrderId?: number;
  isEditCampaign?: boolean;
}

const SubOrdersContainer = memo(
  ({
    onAddForecastConversionGroup,
    onDeleteForecastConversionGroup,
    onSubOrderChange,
    onDeleteSubOrder,
    onAddNewSubOrder,
    onScrollToComments,
    orderRequest,
    orderRequestOriginal,
    stepStatusSubOrders,
    selectedSalesPeriod,
    subOrderId,
    isEditCampaign,
  }: SubOrdersContainerProps) => {
    const { subOrders, medium, productId, period, originalBudget } =
      orderRequest;
    // Bij concepten wordt er geen peildatum gebruikt
    const requestDate = isEditCampaign ? orderRequest.requestDate : undefined;

    const [expandedItemKey, setExpandedItemKey] = useState<number[]>();
    const [mode, setMode] = useState<PackageStep>("list");
    const dispatch = useDispatch();
    const prevMedium = usePrevious<MediumEnum>(medium);
    const prevProductId = usePrevious<number>(productId);
    const prevSalesPeriod = usePrevious<SalesPeriod>(selectedSalesPeriod);

    const {
      packages,
      bookingDate,
      loading,
      forecast,
      loadingForecast,
      forecastConversionGroups,
      forecastConversionGroupsState,
    } = useSelector(subOrdersSelector);

    useEffect(() => {
      setExpandedItemKey((prevKey) => {
        // Sluit de deelorders als de deelorder stap niet in bewerking is
        if (stepStatusSubOrders !== "process" && prevKey) {
          return [];
        }
        return prevKey;
      });
    }, [stepStatusSubOrders]);

    useEffect(() => {
      // Als er al suborders zijn dan tonen we de lijstweergave
      setMode((subOrders?.length ?? 0) > 0 ? "list" : "initial");
    }, [subOrders]);

    const editable = useMemo(
      () => !!isEditCampaign || stepStatusSubOrders === "process",
      [isEditCampaign, stepStatusSubOrders]
    );

    useEffect(() => {
      if (!editable) {
        return;
      }

      if (expandedItemKey === undefined) {
        // Klap initieel alle deelorders open om er voor te zorgen dat de validatie werkt
        setExpandedItemKey(subOrders?.map((s) => s.id ?? 0));
      } else if (expandedItemKey.length > 1) {
        // Klap alle deelorders dicht als alles opengeklapt is
        setExpandedItemKey(subOrderId ? [subOrderId] : []);
      }
    }, [editable, expandedItemKey, subOrderId, subOrders]);

    // Toevoegen van een nieuwe deelorder
    const handleAddNewSubOrder = useCallback(
      (subOrder: Partial<SubOrderRequest>) => {
        const lowestId =
          (subOrders?.length &&
            subOrders?.reduce((prev, curr) =>
              (prev.id ?? 0) < (curr.id ?? 0) ? prev : curr
            ).id) ??
          0;
        const id = lowestId < 0 ? lowestId - 1 : -1; // Geef de kopie een negatief id wat nog niet bestaat, starlight zal hier een nieuw id voor uitgeven

        // Bij een nieuwe deelorder bij het wijzigen van een bestaande campagne gaan we er van uit dat alle velden wijzigbaar zijn
        onAddNewSubOrder({
          ...subOrder,
          id,
          editableFields: isEditCampaign ? Object.values(UpdateFields) : [],
        });
        setExpandedItemKey([id]);
      },
      [isEditCampaign, onAddNewSubOrder, subOrders]
    );

    // Dupliceren van een deelorder
    const handleDuplicateSubOrder = useCallback(
      (id: number) => {
        handleAddNewSubOrder(
          subOrders?.find((s) => s.id === id) as SubOrderRequest
        );
      },
      [handleAddNewSubOrder, subOrders]
    );

    // Toevoegen van een deelorder van type GRP
    const addNewGrpSubOrder = useCallback(() => {
      handleAddNewSubOrder({
        packageChoice: PackageChoiceEnum.Grps,
      } as SubOrderRequest);
    }, [handleAddNewSubOrder]);

    // Toevoegen van een deelorder van type fixed costs
    const addNewFixedCostsSubOrder = useCallback(() => {
      const fixedCostsPackages = packages.find(
        (p) => p.type === PackageChoiceEnum.FixedCosts
      );
      const fixedCostsPackage =
        fixedCostsPackages?.packages.length === 1
          ? fixedCostsPackages?.packages[0]
          : undefined;
      handleAddNewSubOrder({
        packageChoice: PackageChoiceEnum.FixedCosts,
        _package: fixedCostsPackage,
        targetGroup: fixedCostsPackage?.targetGroups[0],
      } as unknown as SubOrderRequest);
    }, [packages, handleAddNewSubOrder]);

    // Toevoegen van een deelorder van type fixed costs
    const addNewFixedCostsCultSubOrder = useCallback(() => {
      const fixedCostsCultPackage = packages.find(
        (p) => p.type === PackageChoiceEnum.FixedCostsCult
      )?.packages[0];
      handleAddNewSubOrder({
        packageChoice: PackageChoiceEnum.FixedCostsCult,
        _package: fixedCostsCultPackage,
        targetGroup: fixedCostsCultPackage?.targetGroups[0],
      } as unknown as SubOrderRequest);
    }, [packages, handleAddNewSubOrder]);

    // Toevoegen van een deelorder van type NPO Promo
    const addNewNpoPromoSubOrder = useCallback(() => {
      const npoPromoPackage = packages.find(
        (p) => p.type === PackageChoiceEnum.NpoPromo
      )?.packages[0];
      handleAddNewSubOrder({
        packageChoice: PackageChoiceEnum.NpoPromo,
        _package: npoPromoPackage,
        targetGroup: npoPromoPackage?.targetGroups[0],
      } as unknown as SubOrderRequest);
    }, [handleAddNewSubOrder, packages]);

    // Toevoegen van een deelorder van type Display
    const addNewDisplaySubOrder = useCallback(() => {
      handleAddNewSubOrder({
        packageChoice: PackageChoiceEnum.Display,
      } as SubOrderRequest);
    }, [handleAddNewSubOrder]);

    // Toevoegen van een deelorder van type Display
    const addNewVideoSubOrder = useCallback(() => {
      handleAddNewSubOrder({
        packageChoice: PackageChoiceEnum.Video,
      } as SubOrderRequest);
    }, [handleAddNewSubOrder]);

    // Toevoegen van een nieuwe deelorder op basis van pakkettype
    const handleAddNewSubOrderByType = useCallback(
      (type: PackageChoiceEnum) => {
        switch (type) {
          case PackageChoiceEnum.FixedCosts:
            addNewFixedCostsSubOrder();
            break;
          case PackageChoiceEnum.FixedCostsCult:
            addNewFixedCostsCultSubOrder();
            break;
          case PackageChoiceEnum.Grps:
            addNewGrpSubOrder();
            break;
          case PackageChoiceEnum.NpoPromo:
            addNewNpoPromoSubOrder();
            break;
          case PackageChoiceEnum.Display:
            addNewDisplaySubOrder();
            break;
          case PackageChoiceEnum.Video:
            addNewVideoSubOrder();
            break;
          default:
            break;
        }
      },
      [
        addNewDisplaySubOrder,
        addNewFixedCostsCultSubOrder,
        addNewFixedCostsSubOrder,
        addNewGrpSubOrder,
        addNewNpoPromoSubOrder,
        addNewVideoSubOrder,
      ]
    );

    useEffect(() => {
      const triggerNewDate = prevMedium !== medium;
      const triggerNewPackages =
        triggerNewDate ||
        prevProductId !== productId ||
        !isEqual(
          prevSalesPeriod?.salesPeriodStartDate ?? new Date(),
          selectedSalesPeriod.salesPeriodStartDate
        ) ||
        !isEqual(
          prevSalesPeriod?.salesPeriodEndDate ?? new Date(),
          selectedSalesPeriod.salesPeriodEndDate
        );

      if (triggerNewPackages) {
        dispatch(clearPackagesAction());
        dispatch(
          receivePackagesAction.request({
            medium,
            product: productId.toString(),
            orderRequestDate: requestDate,
            from: min([
              orderRequest.period.from,
              selectedSalesPeriod.salesPeriodStartDate,
            ]),
            to: addMonths(
              selectedSalesPeriod.salesPeriodEndDate,
              selectedSalesPeriod.salesPeriodEndDate.getMonth() < 11 ? 1 : 0
            ), // Haal de pakketten voor een maand langer op om maandoverschrijdende deelorders te ondersteunen
          })
        );
      }

      if (triggerNewDate) {
        dispatch(
          receiveBookingDateAction.request({
            medium,
          })
        );
      }
    }, [
      dispatch,
      isEditCampaign,
      medium,
      orderRequest.period.from,
      orderRequest.requestDate,
      prevMedium,
      prevProductId,
      prevSalesPeriod,
      productId,
      requestDate,
      selectedSalesPeriod,
    ]);

    useEffect(() => {
      if (forecastConversionGroupsState === ReduxStoreState.Initial) {
        dispatch(receiveForecastConversionGroupsAction.request());
      }
    }, [dispatch, forecastConversionGroupsState]);

    return (
      <Spinner spinning={loading}>
        {editable && mode === "initial" ? (
          <PackageCards
            medium={medium}
            onAddNewSubOrderByType={handleAddNewSubOrderByType}
            salesPeriod={selectedSalesPeriod}
          />
        ) : (
          <>
            <Row>
              <Col span={24}>
                <SubOrderList
                  onChange={onSubOrderChange}
                  subOrders={subOrders}
                  subOrdersOriginal={orderRequestOriginal?.subOrders}
                  productId={productId}
                  period={{
                    from: new Date(
                      Math.max(
                        // Startdatum van deelorders mag niet eerder dan de booking date liggen
                        period.from.getTime(),
                        bookingDate.date?.getTime() ?? null
                      )
                    ),
                    to: period.to,
                  }}
                  medium={medium}
                  onAddNewSubOrder={handleAddNewSubOrderByType}
                  onDuplicateSubOrder={handleDuplicateSubOrder}
                  onDeleteSubOrder={onDeleteSubOrder}
                  expandedItemKey={expandedItemKey}
                  setExpandedItemKey={setExpandedItemKey}
                  onScrollToComments={onScrollToComments}
                  editable={editable || (expandedItemKey?.length ?? 0) > 1}
                  packageChoices={packages
                    .filter((p) => p.packages.length > 0)
                    .map((p) => p.type)}
                  selectedSalesPeriod={selectedSalesPeriod}
                  isEditCampaign={isEditCampaign}
                  requestDate={requestDate}
                  originalBudget={originalBudget}
                />
              </Col>
            </Row>
            <Row justify="end">
              {!isEditCampaign && (
                <>
                  <Col style={{ marginTop: "2rem" }}>
                    <Form name="saveSubOrdersPrevious" hidden={!editable}>
                      <Form.Item>
                        <Button mode="tertiary" htmlType="submit">
                          <Trans>Vorige</Trans>
                        </Button>
                      </Form.Item>
                    </Form>
                  </Col>
                  <Col style={{ marginTop: "2rem", marginLeft: "2rem" }}>
                    <Form name="saveSubOrdersNext" hidden={!editable}>
                      <Form.Item>
                        <Button mode="secondary" htmlType="submit">
                          <Trans>Volgende</Trans>
                        </Button>
                      </Form.Item>
                    </Form>
                  </Col>
                </>
              )}
              {!loading && medium !== MediumEnum.Inter && (
                <Forecast
                  medium={medium}
                  forecast={forecast}
                  loading={loadingForecast}
                  conversionGroups={forecastConversionGroups[medium] ?? []}
                  onAddConversionGroup={onAddForecastConversionGroup}
                  onDeleteConversionGroup={onDeleteForecastConversionGroup}
                />
              )}
            </Row>
          </>
        )}
      </Spinner>
    );
  }
);

export default SubOrdersContainer;
