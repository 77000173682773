/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface BlockSpot
 */
export interface BlockSpot {
  /**
   *
   * @type {string}
   * @memberof BlockSpot
   */
  advertiserName: string;
  /**
   *
   * @type {string}
   * @memberof BlockSpot
   */
  commercialCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof BlockSpot
   */
  commercialDescr?: string | null;
  /**
   *
   * @type {number}
   * @memberof BlockSpot
   */
  orderId: number;
  /**
   *
   * @type {number}
   * @memberof BlockSpot
   */
  subOrderId: number;
  /**
   *
   * @type {string}
   * @memberof BlockSpot
   */
  packageDescr: string;
  /**
   *
   * @type {string}
   * @memberof BlockSpot
   */
  productDescr: string;
  /**
   *
   * @type {number}
   * @memberof BlockSpot
   */
  productId: number;
  /**
   *
   * @type {number}
   * @memberof BlockSpot
   */
  spotId: number;
  /**
   *
   * @type {number}
   * @memberof BlockSpot
   */
  spotLength: number;
}

export function BlockSpotFromJSON(json: any): BlockSpot {
  return BlockSpotFromJSONTyped(json, false);
}

export function BlockSpotFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BlockSpot {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    advertiserName: json["advertiserName"],
    commercialCode: !exists(json, "commercialCode")
      ? undefined
      : json["commercialCode"],
    commercialDescr: !exists(json, "commercialDescr")
      ? undefined
      : json["commercialDescr"],
    orderId: json["orderId"],
    subOrderId: json["subOrderId"],
    packageDescr: json["packageDescr"],
    productDescr: json["productDescr"],
    productId: json["productId"],
    spotId: json["spotId"],
    spotLength: json["spotLength"],
  };
}

export function BlockSpotToJSON(value?: BlockSpot | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    advertiserName: value.advertiserName,
    commercialCode: value.commercialCode,
    commercialDescr: value.commercialDescr,
    orderId: value.orderId,
    subOrderId: value.subOrderId,
    packageDescr: value.packageDescr,
    productDescr: value.productDescr,
    productId: value.productId,
    spotId: value.spotId,
    spotLength: value.spotLength,
  };
}
