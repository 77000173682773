import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { TimePicker, ToolkitTimePicker } from "@ster/ster-toolkit";
import { format as DateFormat, parse } from "date-fns";
import { memo, useCallback, useMemo } from "react";

const FormTimePicker = memo(
  ({
    value,
    onChange,
    format = "HH:mm",
    defaultValue,
    ...props
  }: ToolkitTimePicker & {
    value?: string;
    format?: string;
    onChange?: (v: string) => void;
  }) => {
    const { i18n } = useLingui();
    const handleChange = useCallback(
      (_date: Date | Date[], timeString: string | string[]): void => {
        if (onChange) {
          const selectedTimeString = Array.isArray(timeString)
            ? timeString[0]
            : timeString;
          onChange(selectedTimeString);
        }
      },
      [onChange]
    );

    const handleSelect = useCallback(
      (time?: Date | Date[] | undefined): void => {
        if (onChange && time) {
          const selectedTime = Array.isArray(time) ? time[0] : time;
          onChange(DateFormat(selectedTime, format));
        }
      },
      [format, onChange]
    );

    const currentValue = useMemo(
      () => (value ? parse(value, "HH:mm", new Date()) : null),
      [value]
    );

    return (
      <TimePicker
        format={format}
        value={currentValue}
        defaultValue={defaultValue ?? undefined}
        {...props}
        onChange={handleChange}
        onOk={handleSelect}
        allowClear
        selectTimeLabel={i18n._(t`Selecteer tijd`)}
      />
    );
  }
);

export default FormTimePicker;
