import { action, createAsyncAction } from "typesafe-actions";

import { PortalUser, UserModel } from "../../api/models";
import { ResponseError } from "../base";
import { actionNameAsync } from "../utils";

export const receiveUsersAction = createAsyncAction(
  actionNameAsync("users", "REQUEST"),
  actionNameAsync("users", "SUCCESS"),
  actionNameAsync("users", "FAILURE")
)<undefined, PortalUser[], ResponseError>();

export const createUserAction = createAsyncAction(
  actionNameAsync("userCreate", "REQUEST"),
  actionNameAsync("userCreate", "SUCCESS"),
  actionNameAsync("userCreate", "FAILURE")
)<UserModel, PortalUser, ResponseError>();

export const updateUserAction = createAsyncAction(
  actionNameAsync("userUpdate", "REQUEST"),
  actionNameAsync("userUpdate", "SUCCESS"),
  actionNameAsync("userUpdate", "FAILURE")
)<{ userId: string; userModel: UserModel }, PortalUser, ResponseError>();

export const receiveUserAction = createAsyncAction(
  actionNameAsync("user", "REQUEST"),
  actionNameAsync("user", "SUCCESS"),
  actionNameAsync("user", "FAILURE")
)<string, PortalUser, ResponseError>();

export const clearUserAction = action("USER_CLEAR");
export const deleteUserAction = createAsyncAction(
  actionNameAsync("userDelete", "REQUEST"),
  actionNameAsync("userDelete", "SUCCESS"),
  actionNameAsync("userDelete", "FAILURE")
)<{ userId: string; active: boolean }, PortalUser, ResponseError>();

export const receiveUsersExportAction = createAsyncAction(
  actionNameAsync("usersExport", "REQUEST"),
  actionNameAsync("usersExport", "SUCCESS"),
  actionNameAsync("usersExport", "FAILURE")
)<undefined, Blob, ResponseError>();
