/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface PortalSettingsDisableEditCampaignFieldsTv
 */
export interface PortalSettingsDisableEditCampaignFieldsTv {
  /**
   *
   * @type {boolean}
   * @memberof PortalSettingsDisableEditCampaignFieldsTv
   */
  SpotLength: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PortalSettingsDisableEditCampaignFieldsTv
   */
  Impressions: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PortalSettingsDisableEditCampaignFieldsTv
   */
  Budget: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PortalSettingsDisableEditCampaignFieldsTv
   */
  EndDate: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PortalSettingsDisableEditCampaignFieldsTv
   */
  StartDate: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PortalSettingsDisableEditCampaignFieldsTv
   */
  Package: boolean;
}

export function PortalSettingsDisableEditCampaignFieldsTvFromJSON(
  json: any
): PortalSettingsDisableEditCampaignFieldsTv {
  return PortalSettingsDisableEditCampaignFieldsTvFromJSONTyped(json, false);
}

export function PortalSettingsDisableEditCampaignFieldsTvFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PortalSettingsDisableEditCampaignFieldsTv {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    SpotLength: json["SpotLength"],
    Impressions: json["Impressions"],
    Budget: json["Budget"],
    EndDate: json["EndDate"],
    StartDate: json["StartDate"],
    Package: json["Package"],
  };
}

export function PortalSettingsDisableEditCampaignFieldsTvToJSON(
  value?: PortalSettingsDisableEditCampaignFieldsTv | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    SpotLength: value.SpotLength,
    Impressions: value.Impressions,
    Budget: value.Budget,
    EndDate: value.EndDate,
    StartDate: value.StartDate,
    Package: value.Package,
  };
}
