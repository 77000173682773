import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Spinner, formatDate } from "@ster/ster-toolkit";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CampaignForwarded } from "../../api";
import { receiveAvailableCampaignDetailAction } from "../../store/campaignForward/actions";
import { StoreModel } from "../../store/models";
import AvailableCampaignDetail from "./AvailableCampaignDetail";
import styles from "./AvailableCampaignNotification.module.less";

const AvailableCampaignNotification = ({
  campaign,
}: {
  campaign: CampaignForwarded;
}) => {
  const { i18n } = useLingui();
  const [showDetail, setShowDetail] = useState(false);
  const dispatch = useDispatch();

  const openModal = useCallback(() => {
    setShowDetail(true);
  }, []);

  const closeModal = useCallback(() => {
    setShowDetail(false);
  }, []);

  const medium = useMemo(() => {
    switch (campaign.campaignConfiguration?._configuration?.[0].medium) {
      case "inter":
        return i18n._(t`Online`);
      case "tv":
        return i18n._(t`TV`);
      case "radio":
      default:
        return i18n._(t`Radio`);
    }
  }, [campaign.campaignConfiguration?._configuration, i18n]);

  useEffect(() => {
    if (campaign.id) {
      dispatch(receiveAvailableCampaignDetailAction.request(campaign.id));
    }
  }, [campaign.id, dispatch]);

  const campaignDetail = useSelector(
    (store: StoreModel) =>
      store.campaignForward.availableCampaignDetail?.[campaign.id ?? 0] ?? {
        loading: true,
      }
  );

  return campaignDetail.loading ? (
    <div className={styles.notificationLoading}>
      <Spinner />
    </div>
  ) : (
    <>
      <div
        className={styles.notification}
        onClick={openModal}
        role="button"
        tabIndex={0}
      >
        <span className={styles.availableCampaign}>
          <Trans>Er is een campagne met je gedeeld:</Trans>
          <br />
          {medium} - {campaignDetail.campaign.productDescr} (
          {campaignDetail.campaign.advertiserName})
        </span>
        <br />
        <span className={styles.availableDate}>
          {formatDate(campaignDetail.campaign.forwardedOn, "PPPp")}
        </span>
      </div>
      {showDetail && (
        <AvailableCampaignDetail
          campaign={campaignDetail.campaign}
          handleClose={closeModal}
        />
      )}
    </>
  );
};

export default memo(AvailableCampaignNotification);
