/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  DateSpan,
  DateSpanFromJSON,
  DateSpanFromJSONTyped,
  DateSpanToJSON,
  OrderIdWithDate,
  OrderIdWithDateFromJSON,
  OrderIdWithDateFromJSONTyped,
  OrderIdWithDateToJSON,
} from "./";

/**
 *
 * @export
 * @interface CopyOrderInput
 */
export interface CopyOrderInput {
  /**
   *
   * @type {string}
   * @memberof CopyOrderInput
   */
  campaignName?: string | null;
  /**
   *
   * @type {Array<OrderIdWithDate>}
   * @memberof CopyOrderInput
   */
  orders?: Array<OrderIdWithDate> | null;
  /**
   *
   * @type {DateSpan}
   * @memberof CopyOrderInput
   */
  newPeriod?: DateSpan;
  /**
   *
   * @type {Date}
   * @memberof CopyOrderInput
   */
  startDate?: Date;
}

export function CopyOrderInputFromJSON(json: any): CopyOrderInput {
  return CopyOrderInputFromJSONTyped(json, false);
}

export function CopyOrderInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CopyOrderInput {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    campaignName: !exists(json, "campaignName")
      ? undefined
      : json["campaignName"],
    orders: !exists(json, "orders")
      ? undefined
      : json["orders"] === null
        ? null
        : (json["orders"] as Array<any>).map(OrderIdWithDateFromJSON),
    newPeriod: !exists(json, "newPeriod")
      ? undefined
      : DateSpanFromJSON(json["newPeriod"]),
    startDate: !exists(json, "startDate")
      ? undefined
      : new Date(json["startDate"]),
  };
}

export function CopyOrderInputToJSON(value?: CopyOrderInput | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    campaignName: value.campaignName,
    orders:
      value.orders === undefined
        ? undefined
        : value.orders === null
          ? null
          : (value.orders as Array<any>).map(OrderIdWithDateToJSON),
    newPeriod: DateSpanToJSON(value.newPeriod),
    startDate:
      value.startDate === undefined ? undefined : value.startDate.toISOString(),
  };
}
