/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  MediumEnum,
  MediumEnumFromJSON,
  MediumEnumFromJSONTyped,
  MediumEnumToJSON,
  SalesPeriod,
  SalesPeriodFromJSON,
  SalesPeriodFromJSONTyped,
  SalesPeriodToJSON,
} from "./";

/**
 *
 * @export
 * @interface SalesPeriodResult
 */
export interface SalesPeriodResult {
  /**
   *
   * @type {MediumEnum}
   * @memberof SalesPeriodResult
   */
  medium: MediumEnum;
  /**
   *
   * @type {Array<SalesPeriod>}
   * @memberof SalesPeriodResult
   */
  result: Array<SalesPeriod>;
}

export function SalesPeriodResultFromJSON(json: any): SalesPeriodResult {
  return SalesPeriodResultFromJSONTyped(json, false);
}

export function SalesPeriodResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SalesPeriodResult {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    medium: MediumEnumFromJSON(json["medium"]),
    result: (json["result"] as Array<any>).map(SalesPeriodFromJSON),
  };
}

export function SalesPeriodResultToJSON(value?: SalesPeriodResult | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    medium: MediumEnumToJSON(value.medium),
    result: (value.result as Array<any>).map(SalesPeriodToJSON),
  };
}
