import { I18n } from "@lingui/core";
import { t } from "@lingui/macro";
import {
  Channel,
  ChannelNames,
  Tooltip,
  formatNumber,
  formatToEuro,
} from "@ster/ster-toolkit";
import { ColumnGroupType, ColumnType, ColumnsType } from "antd/lib/table";
import { ColumnTitle } from "antd/lib/table/interface";
import moment from "moment";
import { Fragment, memo } from "react";

import { Spot, SubOrderDetail, Tagon } from "../../../api/models";
import { getBlockPositionText } from "../../../utils";

const Spotlength = memo(({ value }: { value: number[] }) => (
  <span className="ster-spotlength">{value.join("+")}&quot;</span>
));

export const optionalColumns: string[] = [
  "package",
  "targetgroup",
  "grossSpotPrice",
  "totalSpotDiscount",
  "commercialDescr",
  "commercialCode",
  "preferredPosition",
  "predictedRatingSecondaryTargetGroup",
  "selectivitySecondaryTargetGroup",
  "achievedRatingSecondaryTargetGroup",
  "selectivity",
];

export const allColumnKeys: string[] = [
  "breakId",
  "schedDate",
  "schedStartTime",
  "channelDescr",
  "programmeBefore",
  "programmeAfter",
  "spotLength",
  "package",
  "targetgroup",
  "nettSpotPrice",
  "grossSpotPrice",
  "predictedRating",
  "selectivity",
  "predictedRatingSecondaryTargetGroup",
  "selectivitySecondaryTargetGroup",
  "achievedRating",
  "achievedRatingSecondaryTargetGroup",
  "totalSpotDiscount",
  "commercialDescr",
  "commercialCode",
  "preferredPosition",
];

const columns = (
  i18n: I18n,
  predictedRatings: boolean,
  subOrders?: SubOrderDetail[] | null
): ColumnsType<Spot> => [
  {
    title: i18n._(t`Blok`),
    key: "breakId",
    width: 85,
    dataIndex: "breakId",
    sorter: (a, b): number => `${a.breakId}`.localeCompare(b.breakId),
  },
  {
    title: i18n._(t`Datum`),
    key: "schedDate",
    width: 155,
    render: (_, { schedDate }) => (
      <>{moment(schedDate).format("dd D MMM YYYY")}</>
    ),
    sorter: (a, b): number => a.schedDate.getTime() - b.schedDate.getTime(),
  },
  {
    title: i18n._(t`Tijd`),
    key: "schedStartTime",
    width: 95,
    render: (_, { schedStartTime }) => (
      <>{moment(schedStartTime).format("LT")}</>
    ),
    sorter: (a, b): number =>
      a.schedStartTime.getTime() - b.schedStartTime.getTime(),
  },
  {
    title: i18n._(t`Zender`),
    key: "channelDescr",
    width: 100,
    render: (_, { channelDescr }) => (
      <Channel type={channelDescr as ChannelNames} />
    ),
    sorter: (a, b): number =>
      (a.channelDescr ?? "").localeCompare(b.channelDescr ?? ""),
  },
  {
    title: i18n._(t`Voor`),
    key: "programmeBefore",
    dataIndex: "programmeBefore",
    width: 200,
    ellipsis: true,
    sorter: (a, b): number =>
      a.programmeBefore.localeCompare(b.programmeBefore),
  },
  {
    title: i18n._(t`Na`),
    key: "programmeAfter",
    dataIndex: "programmeAfter",
    width: 200,
    ellipsis: true,
    sorter: (a, b): number => a.programmeAfter.localeCompare(b.programmeAfter),
  },
  {
    title: i18n._(t`Spotlengte`),
    key: "spotLength",
    width: 125,
    render: (_, { spotLength }) => <Spotlength value={spotLength} />,
    sorter: (a, b): number =>
      (a.spotLength.join("+") ?? "").localeCompare(
        b.spotLength.join("+") ?? ""
      ),
  },
  {
    title: i18n._(t`Pakketkeuze`),
    key: "package",
    width: 140,
    align: "right",
    render: (_, { subOrderId }): string =>
      subOrders?.find((s) => s.id === subOrderId)?._package.name ?? "",
    sorter: (a, b): number =>
      (
        subOrders?.find((s) => s.id === a.subOrderId)?._package.name ?? ""
      ).localeCompare(
        subOrders?.find((s) => s.id === b.subOrderId)?._package.name ?? ""
      ),
  },
  {
    title: i18n._(t`Doelgroep pakket`),
    key: "targetgroup",
    width: 140,
    align: "right",
    render: (_, { subOrderId }): string =>
      subOrders?.find((s) => s.id === subOrderId)?.targetGroup.targetGroupId ??
      "",
    sorter: (a, b): number =>
      (
        subOrders?.find((s) => s.id === a.subOrderId)?.targetGroup
          .targetGroupId ?? ""
      ).localeCompare(
        subOrders?.find((s) => s.id === b.subOrderId)?.targetGroup
          .targetGroupId ?? ""
      ),
  },
  {
    title: i18n._(t`Netto spotprijs`),
    key: "nettSpotPrice",
    width: 115,
    align: "right",
    render: (_, { nettSpotPrice }) => <>{formatToEuro(nettSpotPrice, false)}</>,
    sorter: (a, b): number => a.nettSpotPrice - b.nettSpotPrice,
  },
  {
    title: i18n._(t`Bruto spotprijs`),
    key: "grossSpotPrice",
    width: 115,
    align: "right",
    render: (_, { grossSpotPrice }) => <>{formatToEuro(grossSpotPrice)}</>,
    sorter: (a, b): number => a.grossSpotPrice - b.grossSpotPrice,
  },
  {
    title: i18n._(t`Prognose`),
    key: "predictedRating",
    width: 115,
    align: "right",
    render: (_, { predictedRating }) => <>{formatNumber(predictedRating, 2)}</>,
    sorter: (a, b): number => a.predictedRating - b.predictedRating,
  },
  {
    title: i18n._(t`Selectiviteit`),
    key: "selectivity",
    width: 115,
    align: "right",
    render: (_, { selectivity }) =>
      selectivity !== undefined && selectivity !== null ? (
        <>{formatNumber(selectivity, 1)}</>
      ) : (
        <>-</>
      ),
    sorter: (a, b): number => (a.selectivity ?? 0) - (b.selectivity ?? 0),
  },
  {
    title: (
      <Tooltip title={i18n._(t`Prognose voor de gekozen secundaire doelgroep`)}>
        {i18n._(t`Prog. sec. dlgrp.`)}
      </Tooltip>
    ),
    key: "predictedRatingSecondaryTargetGroup",
    width: 115,
    align: "right",
    render: (_, { predictedRatingSecondaryTargetGroup }) =>
      predictedRatingSecondaryTargetGroup !== undefined &&
      predictedRatingSecondaryTargetGroup !== null ? (
        <>{formatNumber(predictedRatingSecondaryTargetGroup, 2)}</>
      ) : (
        <>-</>
      ),
    sorter: (a, b): number =>
      (a.predictedRatingSecondaryTargetGroup ?? 0) -
      (b.predictedRatingSecondaryTargetGroup ?? 0),
  },
  {
    title: predictedRatings
      ? i18n._(t`Uitgezonden GRP's`)
      : i18n._(t`Realisatie`),
    key: "achievedRating",
    width: 115,
    align: "right",
    render: (_, { runningState, achievedRating, nacalcRating }) =>
      // eslint-disable-next-line no-nested-ternary
      nacalcRating !== null && nacalcRating !== undefined ? (
        <>{formatNumber(nacalcRating, 2)}</>
      ) : runningState === "Realization" && achievedRating !== undefined ? (
        <>{formatNumber(achievedRating, 2)}</>
      ) : (
        <>-</>
      ),
    sorter: (a, b): number =>
      (a.nacalcRating ?? a.achievedRating) -
      (b.nacalcRating ?? b.achievedRating),
  },
  {
    title: (
      <Tooltip
        title={i18n._(t`Selectiviteit voor de gekozen secundaire doelgroep`)}
      >
        {i18n._(t`Sel. sec. dlgrp.`)}
      </Tooltip>
    ),
    key: "selectivitySecondaryTargetGroup",
    width: 115,
    align: "right",
    render: (_, { selectivitySecondaryTargetGroup }) =>
      selectivitySecondaryTargetGroup !== undefined &&
      selectivitySecondaryTargetGroup !== null ? (
        <>{formatNumber(selectivitySecondaryTargetGroup)}</>
      ) : (
        <>-</>
      ),
    sorter: (a, b): number =>
      (a.selectivitySecondaryTargetGroup ?? 0) -
      (b.selectivitySecondaryTargetGroup ?? 0),
  },
  {
    title: (
      <Tooltip
        title={i18n._(t`Realisatie voor de gekozen secundaire doelgroep`)}
      >
        {i18n._(t`Rea. sec. dlgrp.`)}
      </Tooltip>
    ),
    key: "achievedRatingSecondaryTargetGroup",
    width: 115,
    align: "right",
    render: (_, { runningState, achievedRatingSecondaryTargetGroup }) =>
      runningState === "Realization" &&
      achievedRatingSecondaryTargetGroup !== undefined &&
      achievedRatingSecondaryTargetGroup !== null ? (
        <>{formatNumber(achievedRatingSecondaryTargetGroup, 2)}</>
      ) : (
        <>-</>
      ),
    sorter: (a, b): number =>
      (a.achievedRatingSecondaryTargetGroup ?? 0) -
      (b.achievedRatingSecondaryTargetGroup ?? 0),
  },
  {
    title: i18n._(t`Korting of toeslag`),
    key: "totalSpotDiscount",
    width: 225,
    align: "right",
    render: (
      _,
      { totalSpotDiscount, grossSpotPrice, nettSpotPrice, bonusAmount }
    ) =>
      !totalSpotDiscount ? (
        <>-</>
      ) : (
        <>
          {formatToEuro(grossSpotPrice - nettSpotPrice - bonusAmount)} (
          {totalSpotDiscount > 0 ? i18n._(t`Korting`) : i18n._(t`Toeslag`)} :
          {formatNumber(totalSpotDiscount)}%)
        </>
      ),
    sorter: (a, b): number => a.totalSpotDiscount - b.totalSpotDiscount,
  },
  {
    title: i18n._(t`Commercialcode`),
    key: "commercialCode",
    width: 225,
    align: "left",
    render: (_, { commercialCode, compositionSeqNr, spotLength, tagons }) => (
      <CommercialView
        name={commercialCode}
        spotLength={spotLength[0]}
        compositionSeqNr={compositionSeqNr}
        tagons={tagons}
        useCode
      />
    ),
  },
  {
    title: i18n._(t`Commercial`),
    key: "commercialDescr",
    width: 300,
    align: "left",
    render: (_, { commercialDescr, compositionSeqNr, spotLength, tagons }) => (
      <CommercialView
        name={commercialDescr}
        spotLength={spotLength[0]}
        compositionSeqNr={compositionSeqNr}
        tagons={tagons}
      />
    ),
  },
  {
    title: i18n._(t`Voorkeurspositie`),
    key: "preferredPosition",
    width: 200,
    align: "left",
    render: (_, { preferredPosition }) =>
      preferredPosition ? (
        <>
          {getBlockPositionText(preferredPosition)
            .map((id) => i18n._(id))
            .join(", ")}
        </>
      ) : (
        <>-</>
      ),
  },
];

const CommercialView = memo(
  ({
    name,
    spotLength,
    compositionSeqNr,
    tagons,
    useCode,
  }: {
    name?: string | null;
    spotLength: number;
    compositionSeqNr: number;
    tagons?: Tagon[] | null;
    useCode?: boolean;
  }) => {
    if (!name) {
      return <>-</>;
    }

    return (
      <>
        {name.length < 15 ? (
          name
        ) : (
          <Tooltip title={name}>{name.slice(0, 15)}...</Tooltip>
        )}{" "}
        ({spotLength}&quot; - {compositionSeqNr})
        {tagons?.map((tagon) => (
          <Fragment key={JSON.stringify(tagon)}>
            ,{" "}
            <CommercialView
              name={useCode ? tagon.commercialCode : tagon.commercialDescr}
              spotLength={tagon.spotLength}
              compositionSeqNr={tagon.compositionSeqNr}
            />
          </Fragment>
        ))}
      </>
    );
  }
);

export const getColumnLabel = (
  columnKey: string,
  i18n: I18n,
  predictedRatings: boolean
): ColumnTitle<Spot> =>
  columns(i18n, predictedRatings).find(({ key }) => key === columnKey)?.title;

export const renderColumn = (
  columnKey: string,
  i18n: I18n,
  predictedRatings: boolean,
  subOrders?: SubOrderDetail[] | null
): ColumnType<Spot> | ColumnGroupType<Spot> => {
  const column = columns(i18n, predictedRatings, subOrders).find(
    ({ key }) => key === columnKey
  );
  if (!column) {
    throw new Error("Column not found!");
  }

  return column;
};
