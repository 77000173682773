import { SagaIterator } from "redux-saga";
import { call, put, select } from "redux-saga/effects";
import { ActionType } from "typesafe-actions";

import { OperationalContextState } from "../../api";
import { AccountApi } from "../../api/apis";
import apiConfig from "../../apiConfig";
import { accountSelector } from "../../shared/selectors";
import {
  getAdvertiserFromCookie,
  getOrganisationFromCookie,
  getTenantFromCookie,
  isAccessTokenValid,
  logout,
  saveOperationalContextStateInCookie,
} from "../../utils";
import getNextFromUrlSearchParameters from "../../utils/location";
import { isImpersonating, isInternalUser } from "../../utils/userHelper";
import { LoadingPortalUser } from "../models";
import { receiveSettingsAction } from "../settings/actions";
import { toError } from "../utils";
import {
  logoutMeAction,
  receiveMeAction,
  setOperationalContextStateAction,
} from "./actions";

export function* receiveMe(): SagaIterator {
  try {
    const accountApi = new AccountApi(apiConfig());
    if (isAccessTokenValid()) {
      const response = yield call(() => accountApi.apiV1AccountMeGet());
      yield put(receiveMeAction.success(response));
    } else {
      yield put(receiveMeAction.failure(toError({ status: 401 } as Response)));
    }
  } catch (err) {
    yield put(receiveMeAction.failure(toError(err)));
  }
}

export function* logoutMe(): SagaIterator {
  try {
    logout();
    yield put(receiveSettingsAction.request());
    yield put(logoutMeAction.success());
  } catch (err) {
    yield put(logoutMeAction.failure(toError(err)));
  }
}

// Side effect when current tenant is set into the store
// - store in api config (to send as header)
// - store in cookie (only if it is different)
// - refresh window (unless there was no value set yet, in which case it is the initial load)
export function* setOperationalContextStateSaga(
  action: ActionType<typeof setOperationalContextStateAction>
): SagaIterator {
  // Get current cookie state
  const previousState = getOperationalContextStateFromCookie();
  const newState = action.payload;

  // Save operational context state in cookie
  saveOperationalContextStateInCookie(newState);

  // if there was a previous state and the new state is different, then we reload the window
  if (
    previousState &&
    newState &&
    (newState.tenant !== previousState.tenant ||
      newState.organisationCode !== previousState.organisationCode ||
      newState.advertiserCode !== previousState.advertiserCode)
  ) {
    const nextUrl = getNextFromUrlSearchParameters();
    if (nextUrl) {
      window.location.href = nextUrl;
    } else {
      window.location.reload();
    }
  }

  yield call(() => {});
}

// Saga to conditionally check and set current tenant
export function* checkAndSetCurrentOperationalContextStateSaga(): SagaIterator {
  // Data to work with
  const account: ReturnType<typeof accountSelector> =
    yield select(accountSelector);

  if (account.userInfo) {
    // Create operational context state
    const operationalContextState =
      (isInternalUser(account) || isImpersonating(account)
        ? getOperationalContextStateFromCookie()
        : null) ?? getOperationalContextStateFromAccount(account);

    // Store it in the model
    yield put(setOperationalContextStateAction(operationalContextState));
  }
}

// Returns an operational context state built from data found in cookies
const getOperationalContextStateFromCookie = ():
  | OperationalContextState
  | undefined => {
  const tenantFromCookie = getTenantFromCookie();
  if (!tenantFromCookie) {
    return undefined;
  }

  const organisationFromCookie = getOrganisationFromCookie();
  if (!organisationFromCookie) {
    return undefined;
  }

  const advertiserFromCookie = getAdvertiserFromCookie();
  const result: OperationalContextState = {
    tenant: tenantFromCookie,
    organisationCode: organisationFromCookie,
    advertiserCode: advertiserFromCookie,
  };
  return result;
};

// Returns an operational context state built from data found in the account
const getOperationalContextStateFromAccount = (
  account: LoadingPortalUser
): OperationalContextState => ({
  tenant: account.userInfo.tenant,
  organisationCode: account.userInfo.organisationCode,
  advertiserCode: account.userInfo.advertiserCode,
});
