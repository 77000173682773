import {
  MediumEnum,
  OrderStatus,
  PackageChoiceEnum,
  PortalSettings,
  RunningState,
  SubOrderDetail,
} from "../../../../api";

export interface SubOrderCardProps {
  medium: MediumEnum;
  productId: number;
  orderId: number;
  cardData: SubOrderDetail;
  spotsEditable: boolean;
  canSelectPreferredPosition: boolean;
  preferredPositionSurcharge: number;
  settings: PortalSettings;
  isSpreaded?: boolean;
}

export const baseSpotLength = {
  tv: 30,
  radio: 20,
};

export interface SubOrderDetailsProps {
  status: OrderStatus;
  medium: MediumEnum;
  startDate: Date;
  endDate: Date;
  requestedBudget: number;
  bookedBudget: number;
  achievedNettBudget: number;
  requestedGrp: number;
  bookedGrp: number;
  achievedGrp: number;
  nacalcGrp?: number | null;
  individual?: boolean;
  requestedImpressions?: number;
  bookedImpressions?: number;
  packageChoice: PackageChoiceEnum;
  runningState: RunningState;
  bonusBudgetBooked?: number;
  bonusBudgetRequested?: number;
}
