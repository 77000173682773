/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  GetAdvertisersResponseData,
  GetAdvertisersResponseDataFromJSON,
  GetAdvertisersResponseDataFromJSONTyped,
  GetAdvertisersResponseDataToJSON,
} from "./";

/**
 *
 * @export
 * @interface GetAdvertisersResponse
 */
export interface GetAdvertisersResponse {
  /**
   *
   * @type {GetAdvertisersResponseData}
   * @memberof GetAdvertisersResponse
   */
  data: GetAdvertisersResponseData;
}

export function GetAdvertisersResponseFromJSON(
  json: any
): GetAdvertisersResponse {
  return GetAdvertisersResponseFromJSONTyped(json, false);
}

export function GetAdvertisersResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAdvertisersResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: GetAdvertisersResponseDataFromJSON(json["data"]),
  };
}

export function GetAdvertisersResponseToJSON(
  value?: GetAdvertisersResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: GetAdvertisersResponseDataToJSON(value.data),
  };
}
