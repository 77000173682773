import { i18n } from "@lingui/core";
import { Trans, t } from "@lingui/macro";
import {
  Container,
  Row,
  Typography,
  formatNumber,
  formatToEuro,
} from "@ster/ster-toolkit";
import { Col } from "antd";
import { memo } from "react";

import { CampaignTotals, MediumEnum } from "../../api";
import { getFormattedDate } from "../../utils/dateHelper";
import styles from "./ProposalCampaignTotals.module.less";

const ProposalCampaignTotals = memo(
  ({
    campaignTotals,
    medium,
  }: {
    campaignTotals: CampaignTotals;
    medium: MediumEnum;
  }) => (
    <Row gutter={[40, 40]} key="all">
      <Col span={24}>
        <Typography.Title>
          <Trans>Campagne totaal</Trans>
        </Typography.Title>
        <Container>
          <Row>
            <Col>
              <dl className={styles.dl}>
                <dt>{i18n._(t`Periode`)}</dt>
                <dd>
                  {getFormattedDate(campaignTotals.period.from, "PPP")}{" "}
                  <Trans>t/m</Trans>{" "}
                  {getFormattedDate(campaignTotals.period.to, "PPP")}
                </dd>

                <dt>{i18n._(t`Totaal budget`)}</dt>
                <dd>{formatToEuro(campaignTotals.budget)}</dd>

                {medium === MediumEnum.Inter ? (
                  <>
                    <dt>{i18n._(t`Totaal impressies`)}</dt>
                    <dd>{formatNumber(campaignTotals.impressions, 0)}</dd>
                  </>
                ) : null}
              </dl>
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
  )
);

export default ProposalCampaignTotals;
