import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  Icons,
  InputNumber,
  Modal,
  Select,
  Typography,
} from "@ster/ster-toolkit";
import { Button as AntButton, List, Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio/interface";
import { SelectValue } from "antd/lib/select";
import { CSSProperties, useCallback, useMemo, useState } from "react";

import {
  AnalysisTargetGroupRadio,
  AnalysisTargetGroupTV,
} from "../../../../api";
import { isNumber } from "../../../../utils";
import { targetGroupDisplayName } from "../../../../utils/analysis";

type TargetGroupGender = "M" | "V" | undefined;

export interface CreatedTargetGroup {
  gender?: TargetGroupGender;
  ageFrom?: number;
  ageTo?: number;
}

const CampaignAnalysisTargetGroups = ({
  value,
  targetGroups,
  onChange,
  asFormField,
  style,
}: {
  value?: AnalysisTargetGroupTV[] | AnalysisTargetGroupRadio[];
  targetGroups: AnalysisTargetGroupTV[] | AnalysisTargetGroupRadio[];
  onChange?: (
    changed: AnalysisTargetGroupTV[] | AnalysisTargetGroupRadio[]
  ) => void;
  asFormField?: boolean;
  style?: CSSProperties;
}) => {
  const { i18n } = useLingui();

  const innerValue = useMemo(() => value ?? [], [value]);

  const [open, setOpen] = useState(false);
  const openDialog = useCallback(() => {
    setOpen(true);
  }, []);
  const closeDialog = useCallback(() => {
    setType("list");
    setCreatedTargetGroup({ gender: undefined });
    setTargetGroup(undefined);
    setOpen(false);
  }, []);

  const [type, setType] = useState<"advanced" | "list">("list");

  const handleTypeChange = useCallback(
    ({ target: { value: chosenType } }: RadioChangeEvent) => {
      setType(chosenType);
      setTargetGroup(undefined);
    },
    []
  );

  const [createdTargetGroup, setCreatedTargetGroup] =
    useState<CreatedTargetGroup>({ gender: undefined });

  const handleCreateTargetGroup = useCallback((ctg: CreatedTargetGroup) => {
    setCreatedTargetGroup(ctg);
    if (
      ctg.ageFrom !== undefined &&
      (!ctg.ageTo || (ctg.ageTo && ctg.ageFrom + 5 <= ctg.ageTo))
    ) {
      setTargetGroup({
        age: { min: ctg.ageFrom, max: ctg.ageTo },
        gender: ctg.gender ?? null,
      });
    } else {
      setTargetGroup(undefined);
    }
  }, []);

  const [targetGroup, setTargetGroup] = useState<
    AnalysisTargetGroupTV | AnalysisTargetGroupRadio | undefined
  >(undefined);
  const handleSelectTargetGroup = useCallback(
    (selected: SelectValue) => {
      setTargetGroup(targetGroups[selected as number]);
    },
    [targetGroups]
  );

  const handleRemoveTargetGroup = useCallback(
    (toRemove: number) => {
      onChange?.([
        ...innerValue.slice(0, toRemove),
        ...innerValue.slice(toRemove + 1),
      ]);
    },
    [onChange, innerValue]
  );

  const handleOk = useCallback(() => {
    if (targetGroup) {
      onChange?.([...innerValue, targetGroup]);
      closeDialog();
    }
  }, [closeDialog, onChange, targetGroup, innerValue]);

  return (
    <div style={style}>
      {open && (
        <Modal
          open
          title={i18n._(t`Doelgroep`)}
          onCancel={closeDialog}
          onOk={handleOk}
          okButtonProps={{ disabled: !targetGroup }}
          maskClosable={false}
        >
          <Typography.Paragraph>
            <Trans>
              Kies een doelgroep uit de lijst of stel er zelf een samen.
            </Trans>
          </Typography.Paragraph>
          <Typography.Paragraph>
            <Trans>
              Kies een leeftijdsrange tussen 6 en 97 jaar die minimaal 5 jaar
              uit elkaar ligt.
            </Trans>
          </Typography.Paragraph>

          <Radio.Group onChange={handleTypeChange} value={type}>
            <Radio value="list">
              <Trans>Standaard doelgroepen</Trans>
            </Radio>
            <Radio value="advanced">
              <Trans>Geavanceerd</Trans>
            </Radio>
          </Radio.Group>

          {type === "advanced" && (
            <div
              style={{
                display: "flex",
                justifyContent: "stretch",
                marginTop: 16,
              }}
            >
              <Select
                options={[
                  { label: i18n._(t`Geen voorkeur`), value: null },
                  { label: i18n._(t`Man`), value: "M" },
                  { label: i18n._(t`Vrouw`), value: "V" },
                ]}
                defaultValue={null}
                style={{ width: 148 }}
                value={createdTargetGroup.gender}
                onChange={(selectedValue) =>
                  handleCreateTargetGroup({
                    gender: selectedValue as TargetGroupGender,
                    ageFrom: createdTargetGroup.ageFrom,
                    ageTo: createdTargetGroup.ageTo,
                  })
                }
              />

              <InputNumber
                value={createdTargetGroup.ageFrom}
                placeholder={i18n._(t`Leeftijd vanaf`)}
                required
                onChange={(v) =>
                  handleCreateTargetGroup({
                    gender: createdTargetGroup.gender,
                    ageFrom: v && isNumber(v) ? Number(v) : undefined,
                    ageTo: createdTargetGroup.ageTo,
                  })
                }
                style={{ flex: "1 0 0", marginLeft: 8 }}
                min={6}
                max={97}
              />

              <InputNumber
                value={createdTargetGroup.ageTo}
                placeholder={i18n._(t`Leeftijd t/m`)}
                onChange={(v) =>
                  handleCreateTargetGroup({
                    gender: createdTargetGroup.gender,
                    ageFrom: createdTargetGroup.ageFrom,
                    ageTo: v && isNumber(v) ? Number(v) : undefined,
                  })
                }
                style={{ flex: "1 0 0", marginLeft: 8 }}
                status={
                  createdTargetGroup.ageTo &&
                  createdTargetGroup.ageFrom &&
                  createdTargetGroup.ageFrom + 5 > createdTargetGroup.ageTo
                    ? "error"
                    : undefined
                }
                min={6}
                max={97}
              />
            </div>
          )}
          {type === "list" && (
            <Select.Search
              placeholder={i18n._(t`Kies een doelgroep`)}
              optionFilterProp="label"
              options={[
                {
                  label: i18n._(t`Geen doelgroep geselecteerd`),
                  value: "",
                },
                ...targetGroups.map((tg, index) => ({
                  label: targetGroupDisplayName(tg),
                  value: index,
                  disabled: innerValue.some(
                    (v) =>
                      targetGroupDisplayName(v) === targetGroupDisplayName(tg)
                  ),
                })),
              ]}
              style={{ maxWidth: "unset", marginTop: 16 }}
              onChange={handleSelectTargetGroup}
            />
          )}
        </Modal>
      )}

      {!asFormField && (
        <>
          <Typography.Title level={2}>
            <Trans>Doelgroepen</Trans>
          </Typography.Title>
          <Typography.Paragraph>
            <Trans>
              Hieronder kun je aangeven voor welke doelgroep(en) je, naast de
              standaard inkoopdoelgroep 25-67 jaar, een campagneanalyse zou
              willen ontvangen.
            </Trans>
          </Typography.Paragraph>
        </>
      )}

      {value && (
        <List size="small">
          {value.map((group, index) => (
            <List.Item
              key={JSON.stringify(group)}
              actions={[
                <AntButton
                  key="delete"
                  type="link"
                  icon={<Icons.DeleteIcon />}
                  onClick={() => handleRemoveTargetGroup(index)}
                  disabled={index === 0}
                />,
              ]}
            >
              <span>{targetGroupDisplayName(group)}</span>
            </List.Item>
          ))}
        </List>
      )}

      <div style={{ marginTop: 16 }}>
        <Button
          mode="tertiary"
          onClick={openDialog}
          disabled={innerValue.length === 4}
        >
          <span style={{ marginRight: 8 }}>+</span>
          <Trans>Voeg doelgroep toe</Trans>
        </Button>
      </div>
    </div>
  );
};

export default CampaignAnalysisTargetGroups;
