import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form, Switch } from "@ster/ster-toolkit";
import { memo, useCallback } from "react";

import { PackagePropertiesRadio } from "../../../../api";
import { hasKey } from "../../../../utils";

type PackageSwitchProperties = {
  name: string;
  label?: React.ReactNode;
  helpText?: string;
  selectedPackageProperties?: PackagePropertiesRadio;
  packagesProperties: PackagePropertiesRadio[];
};

const PackageSwitch = memo(
  ({
    name,
    label,
    helpText,
    selectedPackageProperties,
    packagesProperties,
  }: PackageSwitchProperties) => {
    const { i18n } = useLingui();

    const enabledForOption = useCallback(
      (option: string, toggled: boolean) => {
        if (!selectedPackageProperties) {
          return false;
        }

        // Bepaal of er een pakket beschikbaar met de betreffende optie
        const newSelection = {
          ...selectedPackageProperties,
          [option]: toggled,
        };

        return (
          packagesProperties.filter(
            ({ hasSpread, hasHours, hasCombi, hasChannelChoice, hasDays }) =>
              hasSpread === newSelection.hasSpread &&
              hasHours === newSelection.hasHours &&
              hasCombi === newSelection.hasCombi &&
              hasChannelChoice === newSelection.hasChannelChoice &&
              hasDays === newSelection.hasDays
          ).length > 0
        );
      },
      [packagesProperties, selectedPackageProperties]
    );

    return (
      <Form.Item
        label={label}
        name={name}
        valuePropName="checked"
        helpText={helpText}
      >
        <Switch
          disabled={
            !enabledForOption(
              name,
              selectedPackageProperties !== undefined &&
                hasKey(selectedPackageProperties, name) &&
                !selectedPackageProperties[name]
            )
          }
          checkedChildren={i18n._(t`aan`)}
          unCheckedChildren={i18n._(t`uit`)}
        />
      </Form.Item>
    );
  }
);

export default PackageSwitch;
