import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Icons, Tooltip } from "@ster/ster-toolkit";
import { memo, useCallback, useState } from "react";

import { CommercialsPerAdvertiser } from "../../../api";
import styles from "./EditOnlineInstruction.module.less";
import OnlineInstructionModal from "./OnlineInstructionModal";
import { OnlineInstructionsPerSubOrder, SimpleOnlineSubOrder } from "./types";

const EditOnlineInstruction = ({
  commercialsPerAdvertiser,
  maxDate,
  onSave,
  subOrders,
}: {
  commercialsPerAdvertiser?: CommercialsPerAdvertiser;
  maxDate: Date;
  onSave: (instructions: OnlineInstructionsPerSubOrder[]) => void;
  subOrders: SimpleOnlineSubOrder[];
}) => {
  const { i18n } = useLingui();
  const [show, setShow] = useState(false);

  const handleOpenDialog = useCallback(() => {
    setShow(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setShow(false);
  }, []);

  return (
    <>
      <Tooltip
        title={i18n._(t`Bewerk deze uitzendinstructie`)}
        placement="bottom"
      >
        <button
          type="button"
          onClick={handleOpenDialog}
          className={styles["instructions-edit-btn"]}
        >
          <Icons.EditIcon />
        </button>
      </Tooltip>
      {show && (
        <OnlineInstructionModal
          onCancel={handleCloseDialog}
          onOk={handleCloseDialog}
          commercialsPerAdvertiser={commercialsPerAdvertiser}
          maxDate={maxDate}
          okText={<Trans>OK</Trans>}
          onSave={onSave}
          subOrders={subOrders}
        />
      )}
    </>
  );
};

export default memo(EditOnlineInstruction);
