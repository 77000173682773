/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum InvoiceStatus {
  Factureerbaar = "Factureerbaar",
  Gefactureerd = "Gefactureerd",
  DeelsBetaald = "DeelsBetaald",
  Betaald = "Betaald",
}

export function InvoiceStatusFromJSON(json: any): InvoiceStatus {
  return InvoiceStatusFromJSONTyped(json, false);
}

export function InvoiceStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InvoiceStatus {
  return json as InvoiceStatus;
}

export function InvoiceStatusToJSON(value?: InvoiceStatus | null): any {
  return value as any;
}
