/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum CommercialInstructionAlternationType {
  Percentage = "Percentage",
  Channel = "Channel",
}

export function CommercialInstructionAlternationTypeFromJSON(
  json: any
): CommercialInstructionAlternationType {
  return CommercialInstructionAlternationTypeFromJSONTyped(json, false);
}

export function CommercialInstructionAlternationTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CommercialInstructionAlternationType {
  return json as CommercialInstructionAlternationType;
}

export function CommercialInstructionAlternationTypeToJSON(
  value?: CommercialInstructionAlternationType | null
): any {
  return value as any;
}
