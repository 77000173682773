import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  Container,
  ContentContainer,
  Form,
  Icons,
  Input,
  Option,
  Pageheader,
  Radio,
  Row,
  Select,
  Spinner,
  Typography,
} from "@ster/ster-toolkit";
import { Alert, Form as AntForm, Col, RadioChangeEvent, Space } from "antd";
import { isBefore, startOfDay } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import TagManager from "react-gtm-module";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { CampaignConfigurationInput, VolumeDiscount } from "../../api";
import { ReduxStoreState } from "../../store/base";
import {
  campaignConfigurationClearAction,
  campaignConfigurationFetchAction,
  importConfigurationAction,
  importConfigurationClearAction,
  receiveProductsAction,
} from "../../store/campaignCreate/actions";
import { StoreModel } from "../../store/models";
import { usePrevious } from "../../utils/hooks";
import { canUseProspect, isIntermediair } from "../../utils/userHelper";
import { validateForbiddenWords } from "../../utils/validators";
import { errorMessageMapping } from "../errorMessages";
import CampaignBackButton from "../partials/CampaignBackButton";
import { formItemLayout } from "../partials/Form";
import styles from "./CampaignCreateFromCode.module.less";
import DetailsCrm from "./details/DetailsCrm";
import { AdvertiserTypeEnum } from "./details/DetailsEdit";
import VolumeDiscounts from "./details/VolumeDiscounts";
import { productsFromStoreSelector } from "./selectors";

export interface CampaignCodeForm {
  campaignCode: string;
  advertiserId?: number;
  volumeDiscount?: VolumeDiscount;
  productId?: number;
  campaignName?: string;
  comment?: string;
  invoice?: string;
  crmAccount?: string;
  crmOpportunity?: string;
  crmContact?: string;
}

const today = startOfDay(new Date());

const CampaignCreateFromCode = () => {
  const { campaignCode } = useParams<{ campaignCode?: string }>();
  const [fetchedConfig, setFetchedConfig] = useState(false);
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [form] = AntForm.useForm<CampaignCodeForm>();
  const codeValue = AntForm.useWatch("campaignCode", form);
  const advertiserType = AntForm.useWatch("advertiserType", form);
  const advertiserId = AntForm.useWatch("advertiserId", form);
  const volumeDiscount = AntForm.useWatch("volumeDiscount", form);
  const prevAdvertiserType = usePrevious(advertiserType);

  const handleFetchCode = useCallback(() => {
    if (codeValue && codeValue.length === 6) {
      dispatch(importConfigurationClearAction());
      form.setFieldsValue({
        advertiserId: undefined,
        productId: undefined,
      });

      dispatch(
        campaignConfigurationFetchAction.request({ campaignCode: codeValue })
      );
    }
  }, [codeValue, dispatch, form]);

  const configuration = useSelector(
    (store: StoreModel) => store.campaignConfiguration
  );

  const importConfiguration = useSelector(
    (store: StoreModel) => store.importConfiguration
  );

  useEffect(() => {
    if (
      !codeValue &&
      (configuration?.code ||
        importConfiguration.state !== ReduxStoreState.Initial)
    ) {
      dispatch(campaignConfigurationClearAction());
      dispatch(importConfigurationClearAction());
    }
  }, [codeValue, configuration?.code, dispatch, importConfiguration.state]);

  const minStartDate = useMemo(() => {
    const startDates =
      configuration?._configuration?.map((s) => s.subOrderPeriod?.from) ?? [];
    return startDates.sort(
      (a, b) => (a?.getTime() ?? 0) - (b?.getTime() ?? 0)
    )[0];
  }, [configuration?._configuration]);
  const invalidPeriod = useMemo(
    () => minStartDate && isBefore(new Date(minStartDate), today),
    [minStartDate]
  );

  const invalidResult = useMemo(
    () =>
      codeValue &&
      !configuration?.code &&
      configuration.state === ReduxStoreState.Failure,
    [codeValue, configuration]
  );

  useEffect(() => {
    if (
      !fetchedConfig &&
      campaignCode &&
      !codeValue &&
      campaignCode.length === 6
    ) {
      form.setFieldsValue({ campaignCode });
      dispatch(campaignConfigurationFetchAction.request({ campaignCode }));

      // fetch data only once
      setFetchedConfig(true);
    }
  }, [campaignCode, codeValue, dispatch, fetchedConfig, form]);

  useEffect(() => {
    if (
      configuration?._configuration?.[0].medium &&
      configuration?.orderPeriod &&
      !invalidPeriod
    ) {
      dispatch(
        receiveProductsAction.request({
          medium: configuration?._configuration[0].medium,
          from: new Date(configuration.orderPeriod.from),
          to: new Date(configuration.orderPeriod.to),
        })
      );
    }
  }, [configuration, dispatch, invalidPeriod]);

  const productsFromStore = useSelector(productsFromStoreSelector);
  const productsByAdvertiser = useMemo(
    () => productsFromStore.products ?? [],
    [productsFromStore.products]
  );

  const prospectAdvertisers = useMemo(
    () => productsByAdvertiser.filter(({ isProspect }) => isProspect),
    [productsByAdvertiser]
  );

  const account = useSelector((store: StoreModel) => store.account);
  const showProspectOption = useMemo(
    () => prospectAdvertisers.length > 0 && canUseProspect(account),
    [account, prospectAdvertisers.length]
  );

  const selectedProspectAdvertiser = useMemo(
    () =>
      !!productsByAdvertiser?.find((p) => p.id === advertiserId)?.isProspect,
    [advertiserId, productsByAdvertiser]
  );

  const year = useMemo(
    () => configuration.orderPeriod?.from.getFullYear() ?? 0,
    [configuration.orderPeriod?.from]
  );

  // Korting voor 2024
  const showVolumeDiscounts = useMemo(
    () =>
      selectedProspectAdvertiser &&
      prospectAdvertisers.some((s) => s.volumeDiscount) &&
      year >= 2024,
    [prospectAdvertisers, selectedProspectAdvertiser, year]
  );

  const canSetCustomContact = useMemo(() => isIntermediair(account), [account]);

  useEffect(() => {
    if (advertiserType === AdvertiserTypeEnum.Prospect) {
      if (volumeDiscount) {
        // Pak de prospect adverteerder met gelijke kortingen
        const prospectAdvertiserId = prospectAdvertisers.find(
          (s) => s.volumeDiscount === volumeDiscount
        )?.id;
        if (prospectAdvertiserId !== advertiserId) {
          form.resetFields(["advertiserId", "productId"]);
          form.setFieldsValue({ advertiserId: prospectAdvertiserId });
        }
      } else {
        // Pak de prospect adverteerder zonder kortingen
        const prospectAdvertiserId = prospectAdvertisers.find(
          (s) => s.volumeDiscount === VolumeDiscount.VolumeDiscount0
        )?.id;
        if (prospectAdvertiserId !== advertiserId) {
          form.resetFields([
            "advertiserId",
            "productId",
            "crmAccount",
            "crmOpportunity",
            "crmContact",
          ]);
          form.setFieldsValue({
            advertiserId: prospectAdvertiserId,
            volumeDiscount: VolumeDiscount.VolumeDiscount0,
          });
        }
      }
    } else if (advertiserType !== prevAdvertiserType) {
      form.resetFields([
        "advertiserId",
        "productId",
        "crmAccount",
        "crmOpportunity",
        "crmContact",
      ]);
    }
  }, [
    advertiserId,
    advertiserType,
    form,
    prevAdvertiserType,
    prospectAdvertisers,
    volumeDiscount,
    year,
  ]);

  useEffect(() => {
    if (advertiserId) {
      const products = productsByAdvertiser.find(
        (p) => p.id === advertiserId
      )?.products;
      if (products?.length === 1) {
        // if only one product, select it
        form.setFieldsValue({
          productId: products?.[0].id,
        });
      } else if (Number(products?.length) > 0) {
        // reset `productId` value
        form.setFieldsValue({ productId: undefined });
      }
    }
  }, [advertiserId, form, productsByAdvertiser]);

  const showForm = useMemo(
    () => configuration?.code && !invalidPeriod && !invalidResult,
    [configuration?.code, invalidPeriod, invalidResult]
  );

  const handleCancel = useCallback(() => {
    navigate("/campaigns");
  }, [navigate]);

  const handleSubmit = useCallback(
    (values: CampaignCodeForm) => {
      if (!showForm) {
        return;
      }

      dispatch(
        importConfigurationAction.request(values as CampaignConfigurationInput)
      );
    },
    [dispatch, showForm]
  );

  useEffect(() => {
    if (importConfiguration.initialRequestId && !codeValue) {
      dispatch(importConfigurationClearAction());
    }
  }, [codeValue, dispatch, importConfiguration.initialRequestId]);

  const changeCampaignType = useCallback(
    (e: RadioChangeEvent) => {
      if (e.target.value === "new") {
        navigate("/campaigns/new", { state: { from: window.location.search } });
        TagManager.dataLayer({
          dataLayer: {
            event: "Concept aangemaakt",
          },
        });
      }
    },
    [navigate]
  );

  return (
    <>
      <Helmet>
        <title>{i18n._(t`Nieuwe Campagne`)}</title>
      </Helmet>

      <Pageheader
        title={i18n._(t`Nieuwe Campagne`)}
        icon={
          <Icons.CampaignIcon
            width="100%"
            height="100%"
            fill="rgba(129, 176, 210, 0.2)"
          />
        }
      >
        <Space wrap>
          <Button mode="tertiary" onClick={handleCancel}>
            <Icons.CloseIcon fill="#008ccc" />
            <Trans>Annuleren</Trans>
          </Button>
        </Space>
      </Pageheader>

      <Spinner
        spinning={
          Boolean(configuration.loading) || Boolean(importConfiguration.loading)
        }
      >
        <ContentContainer className="content">
          <div className={styles.topContainer}>
            <div className="back-link">
              <CampaignBackButton />
            </div>
          </div>

          <Container>
            <Typography.Title>
              <Trans>Campagne importeren</Trans>
            </Typography.Title>

            <Typography.Paragraph>
              <Trans>
                Met behulp van een eerder verkregen campagnecode, is het
                mogelijk om een campagne te importeren wat vervolgens als basis
                dient voor een nieuwe aanvraag.
              </Trans>
            </Typography.Paragraph>

            <Row>
              <Col xl={14} lg={18} md={24}>
                <Space direction="vertical" style={{ width: "100%" }}>
                  {importConfiguration.error?.detail && (
                    <Alert
                      showIcon
                      type="error"
                      message=""
                      description={i18n._(
                        errorMessageMapping[importConfiguration.error.detail]
                      )}
                    />
                  )}

                  <Form
                    name="code-importer"
                    form={form}
                    onFinish={handleSubmit}
                    initialValues={{
                      type: "code",
                      advertiserType: AdvertiserTypeEnum.Existing,
                    }}
                    {...formItemLayout}
                  >
                    <Form.Item
                      label={i18n._(t`Type`)}
                      name="type"
                      rules={[
                        {
                          required: true,
                          message: i18n._(t`Kies een type`),
                        },
                      ]}
                    >
                      <Radio.Group
                        mode="horizontal"
                        name="radioTypeGroup"
                        onChange={changeCampaignType}
                      >
                        <Radio value="new">
                          <Trans>Nieuwe campagne</Trans>
                        </Radio>
                        <Radio value="code">
                          <Trans>Campagnecode invoeren</Trans>
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item label={i18n._(t`Campagnecode`)}>
                      <Space.Compact className={styles.flexInputGroup}>
                        <Form.Item
                          name="campaignCode"
                          noStyle
                          rules={[
                            {
                              required: true,
                              message: i18n._(
                                t`Voer een code van de te importeren campagne in`
                              ),
                            },
                          ]}
                        >
                          <Input
                            placeholder={i18n._(t`Code van de campagne`)}
                            maxLength={6}
                          />
                        </Form.Item>
                        <Button
                          mode={showForm ? "tertiary" : "primary"}
                          disabled={
                            Boolean(configuration.loading) ||
                            !(codeValue && codeValue.length === 6)
                          }
                          onClick={handleFetchCode}
                        >
                          <Trans>Ophalen</Trans>
                        </Button>
                      </Space.Compact>

                      {invalidPeriod && (
                        <Alert
                          showIcon
                          type="warning"
                          message=""
                          description={
                            <Trans>
                              De periode van de order die hoort bij de opgegeven
                              code, ligt in het verleden en kan niet meer
                              aangemaakt worden.
                            </Trans>
                          }
                          className={styles.alertMargin}
                        />
                      )}

                      {invalidResult && (
                        <Alert
                          showIcon
                          type="warning"
                          message=""
                          description={
                            <Trans>
                              Er is geen campagne gevonden bij de opgegeven
                              code.
                            </Trans>
                          }
                          className={styles.alertMargin}
                        />
                      )}
                    </Form.Item>
                    {showForm && (
                      <>
                        <Form.Item
                          name="advertiserType"
                          label={i18n._(t`Adverteerder`)}
                          rules={[
                            {
                              required: true,
                              message: i18n._(t`Kies een type`),
                            },
                          ]}
                          hidden={!showProspectOption}
                        >
                          <Radio.Group mode="horizontal" name="radioTypeGroup">
                            <Radio value={AdvertiserTypeEnum.Existing}>
                              <Trans>Bestaande adverteerder</Trans>
                            </Radio>
                            <Radio value={AdvertiserTypeEnum.Prospect}>
                              <Trans>Nieuwe adverteerder</Trans>
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                        {advertiserType === AdvertiserTypeEnum.Existing ? (
                          <>
                            <Form.Item
                              label={
                                !showProspectOption
                                  ? i18n._(t`Adverteerder`)
                                  : " "
                              }
                              name="advertiserId"
                              rules={[
                                {
                                  required: true,
                                  message: i18n._(t`Kies een adverteerder`),
                                },
                              ]}
                              shouldUpdate
                            >
                              <Select.Search
                                disabled={
                                  productsByAdvertiser.length === 0 ||
                                  !configuration?.orderPeriod
                                }
                                placeholder={i18n._(t`Kies een adverteerder`)}
                                optionFilterProp="children"
                                loading={productsFromStore.loading}
                              >
                                {productsByAdvertiser
                                  ?.filter((s) => !s.isProspect)
                                  .map((advertiser) => (
                                    <Option
                                      value={advertiser.id}
                                      key={advertiser.id}
                                    >
                                      {advertiser.name}
                                    </Option>
                                  ))}
                              </Select.Search>
                            </Form.Item>
                            <Form.Item
                              label={i18n._(t`Sternummer`)}
                              name="productId"
                              rules={[
                                {
                                  required: true,
                                  message: i18n._(t`Kies een sternummer`),
                                },
                              ]}
                              shouldUpdate
                            >
                              <Select.Search
                                disabled={
                                  productsByAdvertiser.length === 0 ||
                                  !advertiserId
                                }
                                placeholder={i18n._(t`Kies een sternummer`)}
                                optionFilterProp="children"
                                loading={productsFromStore.loading}
                              >
                                {productsByAdvertiser
                                  ?.find((p) => p.id === advertiserId)
                                  ?.products?.map((product) => (
                                    <Option value={product.id} key={product.id}>
                                      {`${product.description} - ${product.id}`}
                                    </Option>
                                  ))}
                              </Select.Search>
                            </Form.Item>
                          </>
                        ) : (
                          <>
                            <Form.Item
                              name="advertiserId"
                              rules={[{ required: true }]}
                              shouldUpdate
                              hidden
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              name="productId"
                              rules={[{ required: true }]}
                              shouldUpdate
                              hidden
                            >
                              <Input />
                            </Form.Item>
                            {showVolumeDiscounts && (
                              <VolumeDiscounts
                                prospectAdvertisers={prospectAdvertisers}
                              />
                            )}
                          </>
                        )}

                        <DetailsCrm advertiserId={advertiserId} editable />
                        {canSetCustomContact && (
                          <Form.Item
                            name="contact"
                            label={i18n._(t`Contactpersoon`)}
                            rules={[
                              {
                                required:
                                  selectedProspectAdvertiser &&
                                  isIntermediair(account),
                                message: i18n._(t`Vul een contactpersoon in`),
                              },
                            ]}
                          >
                            <Input
                              placeholder={i18n._(
                                t`Naam voor je contactpersoon...`
                              )}
                              className={styles.formInputWidth}
                            />
                          </Form.Item>
                        )}
                        <Form.OptionalItem
                          name="campaignName"
                          label={i18n._(t`Campagnenaam`)}
                          optionalLabel={i18n._(t`(optioneel)`)}
                          rules={[{ validator: validateForbiddenWords(i18n) }]}
                        >
                          <Input
                            placeholder={i18n._(t`Naam voor je campagne...`)}
                            maxLength={30}
                          />
                        </Form.OptionalItem>
                        <Form.OptionalItem
                          name="comments"
                          label={i18n._(t`Opmerkingen`)}
                          optionalLabel={i18n._(t`(optioneel)`)}
                          rules={[{ validator: validateForbiddenWords(i18n) }]}
                        >
                          <Input.TextArea
                            placeholder={i18n._(t`Eventuele opmerkingen...`)}
                            maxLength={1500}
                          />
                        </Form.OptionalItem>
                        <Form.OptionalItem
                          name="invoice"
                          label={i18n._(t`Factuurnummer`)}
                          optionalLabel={i18n._(t`(optioneel)`)}
                          rules={[{ validator: validateForbiddenWords(i18n) }]}
                        >
                          <Input
                            placeholder={i18n._(t`Factuurnummer...`)}
                            maxLength={30}
                          />
                        </Form.OptionalItem>

                        <Row justify="end">
                          <Col>
                            <Form.Item shouldUpdate>
                              {({ getFieldsValue }): React.ReactElement => {
                                const values = getFieldsValue();
                                return (
                                  <Button
                                    mode="primary"
                                    htmlType="submit"
                                    disabled={
                                      !form.isFieldsTouched(false) ||
                                      !values.campaignCode ||
                                      !values.advertiserId ||
                                      !values.productId ||
                                      Boolean(importConfiguration.loading)
                                    }
                                  >
                                    <Trans>Nieuwe campagne aanmaken</Trans>
                                  </Button>
                                );
                              }}
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Form>
                </Space>
              </Col>
            </Row>
          </Container>
        </ContentContainer>
      </Spinner>
    </>
  );
};

export default CampaignCreateFromCode;
