/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  DateSpan,
  DateSpanFromJSON,
  DateSpanFromJSONTyped,
  DateSpanToJSON,
  MediumEnum,
  MediumEnumFromJSON,
  MediumEnumFromJSONTyped,
  MediumEnumToJSON,
  Package,
  PackageChoiceEnum,
  PackageChoiceEnumFromJSON,
  PackageChoiceEnumFromJSONTyped,
  PackageChoiceEnumToJSON,
  PackageFromJSON,
  PackageFromJSONTyped,
  PackageToJSON,
  SimpleBreakSelection,
  SimpleBreakSelectionFromJSON,
  SimpleBreakSelectionFromJSONTyped,
  SimpleBreakSelectionToJSON,
} from "./";

/**
 *
 * @export
 * @interface SubOrderConfiguration
 */
export interface SubOrderConfiguration {
  /**
   *
   * @type {MediumEnum}
   * @memberof SubOrderConfiguration
   */
  medium?: MediumEnum;
  /**
   *
   * @type {DateSpan}
   * @memberof SubOrderConfiguration
   */
  subOrderPeriod?: DateSpan;
  /**
   *
   * @type {string}
   * @memberof SubOrderConfiguration
   */
  packageCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubOrderConfiguration
   */
  targetGroupId?: string | null;
  /**
   *
   * @type {Array<number>}
   * @memberof SubOrderConfiguration
   */
  spotLength?: Array<number> | null;
  /**
   *
   * @type {number}
   * @memberof SubOrderConfiguration
   */
  budget?: number;
  /**
   *
   * @type {number}
   * @memberof SubOrderConfiguration
   */
  grps?: number;
  /**
   *
   * @type {number}
   * @memberof SubOrderConfiguration
   */
  impressions?: number;
  /**
   *
   * @type {string}
   * @memberof SubOrderConfiguration
   */
  portalCriteria?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof SubOrderConfiguration
   */
  channels?: Array<string> | null;
  /**
   *
   * @type {number}
   * @memberof SubOrderConfiguration
   */
  spreadIndex?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof SubOrderConfiguration
   */
  spread?: boolean;
  /**
   *
   * @type {number}
   * @memberof SubOrderConfiguration
   */
  spotNo?: number;
  /**
   *
   * @type {string}
   * @memberof SubOrderConfiguration
   */
  campaignName?: string | null;
  /**
   *
   * @type {Array<number>}
   * @memberof SubOrderConfiguration
   */
  daysOfWeek?: Array<number> | null;
  /**
   *
   * @type {string}
   * @memberof SubOrderConfiguration
   */
  format?: string | null;
  /**
   *
   * @type {number}
   * @memberof SubOrderConfiguration
   */
  impressionsDiscount?: number | null;
  /**
   *
   * @type {Array<number>}
   * @memberof SubOrderConfiguration
   */
  timeSelection?: Array<number> | null;
  /**
   *
   * @type {Array<SimpleBreakSelection>}
   * @memberof SubOrderConfiguration
   */
  breakSelection?: Array<SimpleBreakSelection> | null;
  /**
   *
   * @type {Package}
   * @memberof SubOrderConfiguration
   */
  _package?: Package;
  /**
   *
   * @type {PackageChoiceEnum}
   * @memberof SubOrderConfiguration
   */
  packageChoice?: PackageChoiceEnum;
  /**
   *
   * @type {number}
   * @memberof SubOrderConfiguration
   */
  id?: number;
}

export function SubOrderConfigurationFromJSON(
  json: any
): SubOrderConfiguration {
  return SubOrderConfigurationFromJSONTyped(json, false);
}

export function SubOrderConfigurationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SubOrderConfiguration {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    medium: !exists(json, "medium")
      ? undefined
      : MediumEnumFromJSON(json["medium"]),
    subOrderPeriod: !exists(json, "subOrderPeriod")
      ? undefined
      : DateSpanFromJSON(json["subOrderPeriod"]),
    packageCode: !exists(json, "packageCode") ? undefined : json["packageCode"],
    targetGroupId: !exists(json, "targetGroupId")
      ? undefined
      : json["targetGroupId"],
    spotLength: !exists(json, "spotLength") ? undefined : json["spotLength"],
    budget: !exists(json, "budget") ? undefined : json["budget"],
    grps: !exists(json, "grps") ? undefined : json["grps"],
    impressions: !exists(json, "impressions") ? undefined : json["impressions"],
    portalCriteria: !exists(json, "portalCriteria")
      ? undefined
      : json["portalCriteria"],
    channels: !exists(json, "channels") ? undefined : json["channels"],
    spreadIndex: !exists(json, "spreadIndex") ? undefined : json["spreadIndex"],
    spread: !exists(json, "spread") ? undefined : json["spread"],
    spotNo: !exists(json, "spotNo") ? undefined : json["spotNo"],
    campaignName: !exists(json, "campaignName")
      ? undefined
      : json["campaignName"],
    daysOfWeek: !exists(json, "daysOfWeek") ? undefined : json["daysOfWeek"],
    format: !exists(json, "format") ? undefined : json["format"],
    impressionsDiscount: !exists(json, "impressionsDiscount")
      ? undefined
      : json["impressionsDiscount"],
    timeSelection: !exists(json, "timeSelection")
      ? undefined
      : json["timeSelection"],
    breakSelection: !exists(json, "breakSelection")
      ? undefined
      : json["breakSelection"] === null
        ? null
        : (json["breakSelection"] as Array<any>).map(
            SimpleBreakSelectionFromJSON
          ),
    _package: !exists(json, "package")
      ? undefined
      : PackageFromJSON(json["package"]),
    packageChoice: !exists(json, "packageChoice")
      ? undefined
      : PackageChoiceEnumFromJSON(json["packageChoice"]),
    id: !exists(json, "id") ? undefined : json["id"],
  };
}

export function SubOrderConfigurationToJSON(
  value?: SubOrderConfiguration | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    medium: MediumEnumToJSON(value.medium),
    subOrderPeriod: DateSpanToJSON(value.subOrderPeriod),
    packageCode: value.packageCode,
    targetGroupId: value.targetGroupId,
    spotLength: value.spotLength,
    budget: value.budget,
    grps: value.grps,
    impressions: value.impressions,
    portalCriteria: value.portalCriteria,
    channels: value.channels,
    spreadIndex: value.spreadIndex,
    spread: value.spread,
    spotNo: value.spotNo,
    campaignName: value.campaignName,
    daysOfWeek: value.daysOfWeek,
    format: value.format,
    impressionsDiscount: value.impressionsDiscount,
    timeSelection: value.timeSelection,
    breakSelection:
      value.breakSelection === undefined
        ? undefined
        : value.breakSelection === null
          ? null
          : (value.breakSelection as Array<any>).map(
              SimpleBreakSelectionToJSON
            ),
    package: PackageToJSON(value._package),
    packageChoice: PackageChoiceEnumToJSON(value.packageChoice),
    id: value.id,
  };
}
