import {
  ComponentLocalization,
  RangePicker,
  RangePickerProps,
} from "@ster/ster-toolkit";
import { endOfDay, startOfDay } from "date-fns";
import moment from "moment";
import { useCallback, useMemo } from "react";

import { DateSpan } from "../../../../api";
import {
  getLanguage,
  getPopupContainerSubOrders,
  range,
} from "../../../../utils";
import styles from "../OnlineSubOrder.module.less";

export interface PeriodSelectOnlineProps extends RangePickerProps {
  period: DateSpan;
  useTime: boolean;
}

const PeriodSelectOnline = ({
  period,
  value,
  useTime,
  ...props
}: PeriodSelectOnlineProps) => {
  // Bepaling van de niet beschikbare data in de periode-kiezer
  const isDateDisabled = useCallback(
    (currentDate: Date): boolean =>
      !moment(currentDate).isBetween(
        value?.[1]
          ? moment.max(
              moment(value[1]).add(-1, "month").startOf("month"),
              moment(period.from)
            )
          : moment(period.from),
        value?.[0]
          ? moment.min(
              moment(value[0]).add(1, "month").endOf("month"),
              moment(period.to)
            )
          : moment(period.to), // Online deelorders mogen maximaal 2 maanden lang zijn
        "day",
        "[]"
      ),
    [value, period.from, period.to]
  );

  const defaultPickerValue = useMemo(
    (): [Date, Date] | undefined => (value ? undefined : [period.from, null!]),
    [period.from, value]
  );

  return useTime ? (
    <RangePicker
      {...props}
      showWeek
      value={value}
      disabledDate={isDateDisabled}
      disabledTime={() => ({
        disabledMinutes: () =>
          range(0, 60).filter((min) => ![0, 15, 30, 45, 59].includes(min)),
      })}
      getPopupContainer={getPopupContainerSubOrders}
      componentLocale={getLanguage() as ComponentLocalization}
      format="PPp"
      showTime={{
        format: "HH:mm",
        hideDisabledOptions: true,
        defaultValue: [startOfDay(new Date()), endOfDay(new Date())],
      }}
      className={styles.rangeWithTime}
      defaultPickerValue={defaultPickerValue}
    />
  ) : (
    <RangePicker
      {...props}
      showWeek
      disabledDate={isDateDisabled}
      getPopupContainer={getPopupContainerSubOrders}
      value={value}
      defaultPickerValue={defaultPickerValue}
      componentLocale={getLanguage() as ComponentLocalization}
    />
  );
};

export default PeriodSelectOnline;
