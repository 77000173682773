/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum TenantEnum {
  National = "National",
  Regional = "Regional",
}

export function TenantEnumFromJSON(json: any): TenantEnum {
  return TenantEnumFromJSONTyped(json, false);
}

export function TenantEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TenantEnum {
  return json as TenantEnum;
}

export function TenantEnumToJSON(value?: TenantEnum | null): any {
  return value as any;
}
