import "./cardstatistic.less";

interface CardStatisticProps {
  className?: string;
  label?: string | React.ReactNode;
  value: string | number | React.ReactNode;
  subValue?: string | number | React.ReactNode;
  detailValue?: string | number;
}

const CardStatistic = ({
  className,
  label,
  value,
  detailValue,
  subValue,
}: CardStatisticProps) => (
  <div
    className={`card-stat ${className || ""}
    `}
  >
    {label && <div className="card-stat__label">{label}</div>}
    <div className="card-stat__value">
      {value}
      {subValue && <div className="card-stat__subvalue">{subValue}</div>}
    </div>
    {detailValue && (
      <div className="card-stat__value card-stat__detail-value">
        {detailValue}
      </div>
    )}
  </div>
);

export default CardStatistic;
