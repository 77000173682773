import { i18n } from "@lingui/core";
import { Trans, t } from "@lingui/macro";
import {
  Button,
  Checkbox,
  Container,
  Form,
  Input,
  Typography,
} from "@ster/ster-toolkit";
import { List } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { FormInstance } from "antd/lib/form/Form";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";

import { PortalUser, TenantEnum, UserModel } from "../../api";
import { disabledClaims } from "../../shared/models";
import { LoadingSettings } from "../../store/models";
import { usePrevious } from "../../utils/hooks";
import OperationalContextStateForm from "../organisation/OperationalContextStateForm";
import styles from "./UserForm.module.less";

const UserForm = memo(
  ({
    form,
    onSave,
    onInValidEmail,
    user,
    settings,
    isEditAction,
  }: {
    form: FormInstance<UserModel>;
    onSave: (userModel: UserModel) => void;
    onInValidEmail: (email: string) => boolean;
    user?: PortalUser;
    settings: LoadingSettings;
    isEditAction: boolean;
  }) => {
    const [isAdvertiser, setShowAdvertisers] = useState(false);
    const prevIsAdvertiser = usePrevious(isAdvertiser);

    const allClaims = useMemo(
      () =>
        (settings.claims ?? [])
          .filter(
            (c) => !isAdvertiser || !disabledClaims.includes(c.type as string)
          )
          .map((c) => c.type as string),
      [isAdvertiser, settings.claims]
    );

    const handleAllClaims = useCallback(
      ({ target: { checked } }: CheckboxChangeEvent) => {
        if (checked) {
          // alles aan
          form.setFieldsValue({ claims: allClaims });
        } else {
          form.setFieldsValue({ claims: [] });
        }
      },
      [allClaims, form]
    );

    useEffect(() => {
      if (prevIsAdvertiser !== isAdvertiser) {
        // If the user is switching from advertiser to non-advertiser,
        // we need to remove all claims that are disabled for advertisers
        const claims = (form.getFieldValue("claims") ?? []) as string[];
        const previousAllClaims = (settings.claims ?? [])
          .filter(
            (c) =>
              !prevIsAdvertiser || !disabledClaims.includes(c.type as string)
          )
          .map((c) => c.type as string);
        const currentlyAllSelected = claims.length === previousAllClaims.length;
        const newClaimsValue = (
          currentlyAllSelected ? allClaims : claims
        ).filter((c) => !isAdvertiser || !disabledClaims.includes(c));

        form.setFieldsValue({
          claims: newClaimsValue,
        });
      }
    }, [allClaims, form, isAdvertiser, prevIsAdvertiser, settings.claims]);

    useEffect(() => {
      form.setFieldsValue({
        active: user?.active ?? true,
        firstName: user?.userInfo?.firstName ?? "",
        lastName: user?.userInfo?.lastName ?? "",
        email: user?.email,
        phoneNumber: user?.phoneNumber,
        clientIdList: user?.userInfo?.clientIdList ?? null,
        claims: user?.claims ?? [],
        roles: user?.roles ?? [],
        operationalContextState: {
          tenant: user?.userInfo?.tenant ?? TenantEnum.National,
          organisationCode: user?.userInfo?.organisationCode ?? "",
          advertiserCode: user?.userInfo?.advertiserCode ?? null,
        },
      });
    }, [form, user]);

    return (
      <>
        <Helmet>
          <title>
            {user && user.userInfo
              ? i18n._(t`Gebruiker: ${user.userInfo.fullName}`)
              : i18n._(t`Nieuwe gebruiker`)}
          </title>
        </Helmet>

        <Container>
          <Typography.Paragraph>
            <Trans>
              Wijzigingen in gebruikersgegevens, worden pas actief nadat de
              betreffende gebruiker opnieuw is ingelogd.
            </Trans>
          </Typography.Paragraph>
          <Form form={form} onFinish={onSave} scrollToFirstError>
            <Form.Item
              label={i18n._(t`E-mailadres`)}
              rules={[
                {
                  required: true,
                  message: i18n._(t`Veld is verplicht`),
                },
                {
                  required: true,
                  type: "email",
                  message: i18n._(t`Ongeldig e-mailadres`),
                },
                {
                  required: true,
                  validator: (rule, value): Promise<void> =>
                    !isEditAction && value && onInValidEmail(value)
                      ? Promise.reject(
                          new Error(i18n._(t`E-mailadres is al in gebruik`))
                        )
                      : Promise.resolve(),
                },
              ]}
              name="email"
            >
              <Input type="email" disabled={isEditAction} />
            </Form.Item>
            <Form.Item
              label={i18n._(t`Actief`)}
              name="active"
              valuePropName="checked"
            >
              <Checkbox>
                <Trans>Is deze gebruiker actief?</Trans>
              </Checkbox>
            </Form.Item>
            <Form.Item
              label={i18n._(t`Voornaam`)}
              rules={[
                { required: true, message: i18n._(t`Veld is verplicht`) },
              ]}
              name="firstName"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={i18n._(t`Achternaam`)}
              rules={[
                { required: true, message: i18n._(t`Veld is verplicht`) },
              ]}
              name="lastName"
            >
              <Input />
            </Form.Item>
            <Form.Item label={i18n._(t`Telefoonnummer`)} name="phoneNumber">
              <Input />
            </Form.Item>
            <OperationalContextStateForm
              allowClear
              setShowAdvertisers={setShowAdvertisers}
              fieldName="operationalContextState"
            />
            <Form.Item
              label={i18n._(t`Client ID lijst`)}
              helpText={i18n._(
                t`Komma-gescheiden lijst van ClientID's (uit STARlight.NET)`
              )}
              name="clientIdList"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={i18n._(t`Rollen`)}
              rules={[
                { required: true, message: i18n._(t`Veld is verplicht`) },
              ]}
              name="roles"
            >
              <Checkbox.Group options={["User", "Admin", "Intermediair"]} />
            </Form.Item>
            <Form.Item
              label={i18n._(t`Rechten`)}
              extra={
                <Checkbox onChange={handleAllClaims}>
                  <Trans>Alle rechten aan-/uitzetten</Trans>
                </Checkbox>
              }
              dependencies={["organisationCode"]}
            >
              {() => (
                <Form.Item
                  noStyle
                  rules={[
                    { required: true, message: i18n._(t`Veld is verplicht`) },
                  ]}
                  name="claims"
                  dependencies={["organisationCode"]}
                >
                  <Checkbox.Group className={styles.claimsCheckboxes}>
                    <List
                      dataSource={settings.claims ?? []}
                      renderItem={(claim) => (
                        <List.Item key={claim.type}>
                          <Checkbox
                            value={claim.type}
                            disabled={
                              isAdvertiser &&
                              disabledClaims.includes(claim.type as string)
                            }
                          >
                            {claim.description}
                          </Checkbox>
                        </List.Item>
                      )}
                    />
                  </Checkbox.Group>
                </Form.Item>
              )}
            </Form.Item>

            <Form.Item>
              <Button htmlType="submit" mode="primary">
                <Trans>Opslaan</Trans>
              </Button>
            </Form.Item>
          </Form>
        </Container>
      </>
    );
  }
);
export default UserForm;
