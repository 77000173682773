/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  IdentityError,
  IdentityErrorFromJSON,
  IdentityErrorFromJSONTyped,
  IdentityErrorToJSON,
} from "./";

/**
 *
 * @export
 * @interface IdentityResult
 */
export interface IdentityResult {
  /**
   *
   * @type {boolean}
   * @memberof IdentityResult
   */
  readonly succeeded?: boolean;
  /**
   *
   * @type {Array<IdentityError>}
   * @memberof IdentityResult
   */
  readonly errors?: Array<IdentityError> | null;
}

export function IdentityResultFromJSON(json: any): IdentityResult {
  return IdentityResultFromJSONTyped(json, false);
}

export function IdentityResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IdentityResult {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    succeeded: !exists(json, "succeeded") ? undefined : json["succeeded"],
    errors: !exists(json, "errors")
      ? undefined
      : json["errors"] === null
        ? null
        : (json["errors"] as Array<any>).map(IdentityErrorFromJSON),
  };
}

export function IdentityResultToJSON(value?: IdentityResult | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {};
}
