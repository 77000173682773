import "../styles.less";
import "./materialcard.less";

import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Tag, Tooltip, Typography, formatDate } from "@ster/ster-toolkit";
import classNames from "classnames";
import { useState } from "react";

import { Commercial, MediumEnum } from "../../../../api";
import { getCommercialStatusText, tagStatusMapper } from "../../../../utils";
import MaterialModal from "./MaterialModal";
import PlayButton from "./PlayButton";
import ThumbnailSlider from "./ThumbnailSlider";

export interface MatericalCardProps {
  cardData: Commercial;
  player: React.ReactNode;
  footer?: React.ReactNode;
  disabled?: boolean;
  advertiserId?: number;
}

const MaterialCard = ({
  advertiserId,
  cardData,
  player,
  footer,
  disabled,
}: MatericalCardProps) => {
  const { i18n } = useLingui();
  const [visible, setVisible] = useState(false);
  const openModal = (): void => {
    setVisible(true);
  };

  const closeModal = (): void => {
    setVisible(false);
  };

  const { description, creationDate, medium } = cardData;
  const { thumbnails, status, code, productId, length } =
    cardData as Commercial;

  const { Caption, Footnote, Title } = Typography;

  const formattedCreationDate = creationDate
    ? formatDate(creationDate)
    : undefined;

  return (
    <>
      <article className="card card--material">
        <PlayButton
          disabled={disabled}
          className={classNames("card__media", { "btn-disabled": disabled })}
          onClick={openModal}
        >
          <ThumbnailSlider
            images={thumbnails}
            title={`${productId}${code}`}
            medium={medium as MediumEnum}
          />
        </PlayButton>
        <div className="card__data">
          <Tooltip overlayClassName="card__tooltip" title={description}>
            {medium === MediumEnum.Inter ? (
              <Title level={3}>
                <span className="card__product-id">{code}: </span>
                {description}
              </Title>
            ) : (
              <Title level={3}>
                <span className="card__product-id">{productId}</span>
                {code}: {description}
              </Title>
            )}
          </Tooltip>
          <div className="card__meta-data">
            <Tag
              status={tagStatusMapper(status)}
              text={i18n._(getCommercialStatusText(status))}
            />
            <Caption>{length}&ldquo;</Caption>
            <Footnote>
              {formattedCreationDate && (
                <Trans>toegevoegd op {formattedCreationDate}</Trans>
              )}
            </Footnote>
          </div>
          {footer}
        </div>
      </article>
      <MaterialModal
        title={
          medium === MediumEnum.Inter ? (
            <Title level={3} className="material-modal__title">
              <span className="material-modal__id">{code}: </span>
              {description}
            </Title>
          ) : (
            <Title level={3} className="material-modal__title">
              <span className="material-modal__id">{productId}</span>
              {code}: {description}
            </Title>
          )
        }
        open={visible}
        onCancel={closeModal}
        materialData={cardData as Commercial}
        creationDate={formattedCreationDate}
        player={player}
        advertiserId={advertiserId}
      />
    </>
  );
};

export default MaterialCard;
