import "../styles.less";

import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, DatePickerExtended, Icons, Tooltip } from "@ster/ster-toolkit";
import { App as AntApp } from "antd";
import classNames from "classnames";
import { isSameDay, startOfDay } from "date-fns";
import { useCallback, useMemo } from "react";

import {
  CommercialInstructionSpots,
  CommercialsPerAdvertiser,
  MediumEnum,
} from "../../../../api";
import { removeAndSortDuplicateDates } from "../../../../utils";
import {
  getDaysInsidePeriod,
  getExcludedDaysString,
  isBetweenDays,
} from "../../../../utils/dateHelper";
import { SpotLengthWithDates } from "../../Instructions/types";
import {
  emptyCommercialSelection,
  getRotationFrequencyPercentage,
} from "../../Instructions/utils";
import InstructionsContainer from "../../Steps/InstructionsContainer";
import styles from "./commercialpicker.module.less";
import CommercialRow from "./CommercialRow";

export interface CommercialPickerProps {
  commercialsPerAdvertiser?: CommercialsPerAdvertiser;
  enableAdd?: boolean;
  enableRemove?: boolean;
  productId: number;
  medium: MediumEnum;
  spotLengthWithDates: SpotLengthWithDates;
  onChange: (spotLengthWithDates: SpotLengthWithDates) => void;
  onDelete: (spotLengthWithDates: SpotLengthWithDates) => void;
  canDelete: boolean;
}

export const CommercialPicker = ({
  commercialsPerAdvertiser,
  onChange,
  enableAdd,
  enableRemove,
  productId,
  medium,
  spotLengthWithDates,
  onDelete,
  canDelete,
}: CommercialPickerProps) => {
  const { spotLength, dateRanges, excluded, commercialSelection } =
    spotLengthWithDates;
  const { i18n } = useLingui();
  const { message } = AntApp.useApp();

  const commercialIds = useMemo(
    () => commercialSelection.map((c) => c.id),
    [commercialSelection]
  );

  const handleAdd = useCallback(() => {
    onChange({
      ...spotLengthWithDates,
      commercialSelection: [
        ...commercialSelection,
        {
          ...emptyCommercialSelection(spotLength)[0],
          id: Math.max(...commercialIds) + 1,
        },
      ],
    });
  }, [
    onChange,
    spotLengthWithDates,
    commercialSelection,
    spotLength,
    commercialIds,
  ]);

  const handleRemove = useCallback(
    (id: number) => {
      if (commercialSelection.length === 1) {
        onChange({
          ...spotLengthWithDates,
          commercialSelection: emptyCommercialSelection(spotLength),
        });
      } else {
        onChange({
          ...spotLengthWithDates,
          commercialSelection: commercialSelection.filter((r) => r.id !== id),
        });
      }
    },
    [commercialSelection, onChange, spotLength, spotLengthWithDates]
  );

  const handleReady = useCallback(
    (row: CommercialInstructionSpots) => {
      const idx = commercialIds.indexOf(row.id);
      const newArr = [
        ...commercialSelection.slice(0, idx),
        row,
        ...commercialSelection.slice(idx + 1, commercialSelection.length),
      ];

      onChange({
        ...spotLengthWithDates,
        commercialSelection: newArr,
      });
    },
    [commercialIds, commercialSelection, onChange, spotLengthWithDates]
  );

  const isDayDisabled = useCallback(
    (date: Date): boolean =>
      !dateRanges.some((s) => date >= s.startDate && date <= s.endDate),
    [dateRanges]
  );

  const allDates = useMemo(
    () =>
      dateRanges.flatMap((s) => getDaysInsidePeriod(s.startDate, s.endDate)),
    [dateRanges]
  );

  const handleExcludeDays = useCallback(
    (days: Date[]) => {
      // Filter de dagen die voor of na de periode liggen
      const daysInRange = days
        .map((d) => startOfDay(d))
        .filter((d) =>
          dateRanges.some((s) => isBetweenDays(s.startDate, s.endDate, d))
        );

      // Als de huidige waarde leeg is, of als 1 van de geselecteerde dagen niet in de selectie zit voegen we de hele week toe
      const toAdd =
        excluded.length === 0 ||
        daysInRange
          .map((v) =>
            excluded.findIndex((a) => new Date(a).valueOf() === v.valueOf())
          )
          .some((s) => s === -1);

      let newExcluded = [];
      if (toAdd) {
        newExcluded = removeAndSortDuplicateDates([
          ...excluded,
          ...daysInRange,
        ]);
      } else {
        newExcluded = excluded.filter(
          (s) => !daysInRange.some((d) => d.getTime() === s.getTime())
        );
      }

      // Er moet altijd minimaal 1 dag geselecteerd zijn
      if (allDates.length === newExcluded.length) {
        message.error(
          i18n._(t`Je dient minimaal 1 dag over te houden in je selectie.`)
        );
        return;
      }

      onChange({
        ...spotLengthWithDates,
        excluded: newExcluded,
      });
    },
    [
      allDates.length,
      dateRanges,
      excluded,
      i18n,
      message,
      onChange,
      spotLengthWithDates,
    ]
  );

  const excludedDaysString = useMemo(() => {
    const value = getExcludedDaysString(
      allDates
        .filter((s) => !excluded.some((e) => isSameDay(e, s)))
        .sort((a, b) => Number(a) - Number(b))
    );
    return value ? i18n._(value) : "";
  }, [allDates, excluded, i18n]);

  const onDeleteClick = useCallback(() => {
    onDelete(spotLengthWithDates);
  }, [onDelete, spotLengthWithDates]);

  return (
    <section className="picker commercial-picker">
      <InstructionsContainer title={excludedDaysString}>
        <Tooltip
          className="picker-row__tooltip"
          title={<Trans>Periode verwijderen</Trans>}
        >
          <button
            className={classNames(styles.delete, "link")}
            type="button"
            onClick={onDeleteClick}
            hidden={!canDelete}
          >
            <Icons.DeleteIcon />
          </button>
        </Tooltip>
        <div className={styles.row}>
          <DatePickerExtended
            mode="multiple"
            disabled={isDayDisabled}
            today={dateRanges[0].startDate}
            selected={excluded}
            onDayClick={(day) => handleExcludeDays([day])}
            className={classNames(styles.calendar, "excluded")}
            onWeekNumberClick={(_, days) => handleExcludeDays(days)}
            onWeekdayClick={(_, days) => handleExcludeDays(days)}
          />
          <span className="frequency__title">
            <Trans>Verdeling</Trans>
          </span>
          <div className={styles.commercials}>
            {commercialSelection.map((row) => (
              <CommercialRow
                key={row.id}
                selection={row}
                onRemove={enableRemove ? handleRemove : undefined}
                onReady={handleReady}
                commercialsPerAdvertiser={commercialsPerAdvertiser}
                canRemove
                spotLength={spotLength}
                productId={productId}
                medium={medium}
                rotationPercentage={getRotationFrequencyPercentage(
                  row.rotationFrequency,
                  commercialSelection
                )}
              />
            ))}

            {enableAdd && (
              <Button
                mode="tertiary"
                disabled={commercialSelection.some(({ commercial, tagOns }) => {
                  const tagOnsToAdd =
                    spotLength.length - 1 - (tagOns?.length ?? 0);
                  return !commercial || tagOnsToAdd > 0;
                })}
                onClick={handleAdd}
              >
                <span className="more-menu-button__label">
                  + <Trans>Commercial toevoegen</Trans>
                </span>
              </Button>
            )}
          </div>
        </div>
      </InstructionsContainer>
    </section>
  );
};
