import { ChannelNames } from "@ster/ster-toolkit";

import { BreakSelection, Spot } from "../api";
import { RadioShortChannelNames } from "../components/partials/Channel/Channel";
import { compact } from "./array";
import { isArray } from ".";

export type getDistinctChannelNamesArgs = {
  spotSelection?: Array<Spot> | null;
  breakSelection?: Array<BreakSelection> | null;
  channels?: Array<string> | null;
};

export const getDistinctChannelNames = (
  input: getDistinctChannelNamesArgs | getDistinctChannelNamesArgs[]
) => {
  const useInput = isArray(input)
    ? input
    : ([input] as getDistinctChannelNamesArgs[]);

  // Collect all channel names from the input
  // 1. channels
  // 2. spotSelection
  // 3. breakSelection
  const fallbackChannelNames = useInput
    .flatMap((inputItem) => inputItem.channels ?? [])
    .map(
      (radioShortChannelName) =>
        RadioShortChannelNames.find(({ key }) => key === radioShortChannelName)
          ?.value as ChannelNames
    );

  const fallbackSpotSelectionChannelNames = useInput
    .flatMap((inputItem) => [
      ...(inputItem.spotSelection?.map(
        (spotSelection) => spotSelection.channelDescr
      ) || []),
      ...(inputItem.breakSelection?.map(
        (breakSelection) => breakSelection._break?.channelDescr
      ) || []),
    ])
    .map((channelDescr) => channelDescr as ChannelNames);

  const fallbackChannels = Array.from(
    new Set([...fallbackChannelNames, ...fallbackSpotSelectionChannelNames])
  ).sort((a, b) =>
    // Sorteer de radio zenders op basis van de key in RadioShortChannelNames, voor TV sorteren we alfabetisch
    (
      RadioShortChannelNames.find(({ value }) => value === a)?.key ?? a
    ).localeCompare(
      RadioShortChannelNames.find(({ value }) => value === b)?.key ?? b
    )
  );

  return compact(fallbackChannels);
};
