import { createSelector } from "reselect";

import { createStructuredAppSelector } from "../../../shared/selectors";
import { LoadingBookSpot, StoreModel } from "../../../store/models";

const bookSpotFromStoreSelector = ({
  bookSpot: bookSpotFromStore,
}: StoreModel): LoadingBookSpot => bookSpotFromStore;

const bookSpotSuccessSelector = createSelector(
  [bookSpotFromStoreSelector],
  (bookSpot: LoadingBookSpot): boolean | undefined => bookSpot.success
);

const loadingSelector = createSelector(
  [bookSpotFromStoreSelector],
  (bookSpot: LoadingBookSpot): boolean => bookSpot.loading ?? false
);

// eslint-disable-next-line import/prefer-default-export
export const bookSpotRootSelector = createStructuredAppSelector({
  loading: loadingSelector,
  success: bookSpotSuccessSelector,
});
