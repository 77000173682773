import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Typography } from "@ster/ster-toolkit";
import { App as AntApp } from "antd";
import moment from "moment";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";

import { AvailableBreak, BreakSelection } from "../../api/models";
import { secondaryTargetGroupsSelectorByMedium } from "../../shared/selectors";
import { ReduxStoreState } from "../../store/base";
import {
  clearBreaksAction,
  receiveBreaksAction,
} from "../../store/breakselect/actions";
import { StoreModel } from "../../store/models";
import { router } from "../AppContainer";
import BookSpotFooter from "./bookSpot/BookSpotFooter";
import BreakSelectModal from "./BreakSelectModal";
import { emptyFilters, emptySelectedBreaks } from "./constants";
import {
  BreakFilters,
  BreakSelectProps,
  BreakSelectQueryProps,
  BreakShowSelection,
} from "./models";
import { breakSelectRootSelector } from "./selectors";

const BreakSelectContainer = memo(
  ({
    query,
    value: originalValue = emptySelectedBreaks,
    canSelectPreferredPosition,
    preferredPositionSurcharge,
    buttonText,
    onChange,
    currentBalance,
    currentGRPBalance,
    isDirty,
    alreadySelected,
  }: BreakSelectProps) => {
    const { i18n } = useLingui();
    const { modal } = AntApp.useApp();
    const [previousQuery, setPreviousQuery] = useState<
      BreakSelectQueryProps | undefined
    >(undefined);

    // intern de waarde opslaan en bijwerken; pas bij "OK" => `onChange` triggeren
    const [innerValue, updateInnerValue] = useState<BreakSelection[]>(
      originalValue ?? emptySelectedBreaks
    );
    useEffect(() => updateInnerValue(originalValue), [originalValue]);

    const initialPeriod = useMemo(
      () => [
        moment(query.from).toISOString(true),
        moment(query.to).toISOString(true),
      ],
      [query.from, query.to]
    );

    const initialFilters = useMemo<BreakFilters>(
      () => ({
        ...emptyFilters,
        schedDate: initialPeriod,
      }),
      [initialPeriod]
    );

    const [visible, setVisible] = useState(false);
    const [showSelection, setShowSelection] =
      useState<BreakShowSelection>("all");
    const [filters, setFilters] = useState<BreakFilters>(initialFilters);

    const handleOpen = useCallback(() => {
      setVisible(true);
    }, [setVisible]);

    const dispatch = useDispatch();

    /**
     * (re)set initial states
     */
    const resetState = useCallback(() => {
      if (query.orderId) {
        // Als we vanuit een bestaande order resetten legen we geselecteerde spotjes
        updateInnerValue(emptySelectedBreaks);
      } else {
        updateInnerValue(originalValue ?? emptySelectedBreaks);
      }

      setShowSelection("all");
      setFilters(initialFilters);
    }, [initialFilters, originalValue, query.orderId]);

    const handleCancel = useCallback(() => {
      modal.confirm({
        title: i18n._(t`Weet je het zeker?`),
        content: (
          <Typography.Paragraph>
            {i18n._(
              t`Jouw gemaakte blokselectie gaat verloren. Met de OK-knop bevestig jij jouw selectie.`
            )}
          </Typography.Paragraph>
        ),
        onOk: () => {
          resetState();
          setVisible(false);
        },
        cancelText: i18n._(t`Nee`),
        okText: i18n._(t`Ja`),
      });
    }, [i18n, modal, resetState]);

    const handleOk = useCallback(() => {
      onChange(innerValue);
      setVisible(false);
      if (query.orderId) {
        // Als we vanuit een bestaande order selecteren (bookspot) resetten we alles als de dialog sluit
        resetState();
      }
    }, [innerValue, onChange, query.orderId, resetState]);

    const handleChange = useCallback((newSelection: BreakSelection[]) => {
      updateInnerValue(newSelection);
    }, []);

    const { breaks, channels, breaksState, settings } = useSelector(
      (state: StoreModel) => breakSelectRootSelector(state, query.key)
    );

    const secondaryTargetGroupsByMedium = useSelector((store: StoreModel) =>
      secondaryTargetGroupsSelectorByMedium(store, { medium: query.medium })
    );

    useEffect(() => {
      // Wijzig de filterperiode als de periode van de deelorder wijzigt
      setFilters((currentFilters) => ({
        ...currentFilters,
        schedDate: initialPeriod,
      }));
    }, [initialPeriod]);

    const receiveBreaks = useCallback(() => {
      dispatch(
        receiveBreaksAction.request({
          medium: query.medium,
          from: query.from,
          to: query.to,
          product: `${query.productId}`,
          packageCode: query.packageCode,
          targetGroup: query.targetGroup,
          secondaryTargetGroup: filters.secondaryTargetGroup,
          length: query.spotLength,
          channels:
            (query.channels?.length ?? 0) > 0
              ? query.channels.join(",")
              : undefined,
          orderId: query.orderId?.toString(),
          subOrderId: query.subOrderId?.toString(),
          excludedDays: query.excludedDays,
          key: query.key,
        })
      );
    }, [dispatch, filters.secondaryTargetGroup, query]);

    const debouncedReceiveBreaks = useDebouncedCallback(receiveBreaks, 100);

    useEffect(() => {
      if (visible) {
        debouncedReceiveBreaks.cancel();
        debouncedReceiveBreaks();
      }
    }, [debouncedReceiveBreaks, visible]);

    useEffect(() => {
      if (isDirty) {
        if (JSON.stringify(query) !== JSON.stringify(previousQuery)) {
          debouncedReceiveBreaks.cancel();
          debouncedReceiveBreaks();
          setPreviousQuery(query);
        }
      }
    }, [debouncedReceiveBreaks, isDirty, previousQuery, query, receiveBreaks]);

    useEffect(() => {
      router.subscribe((routerState) => {
        if (
          (routerState.historyAction === "POP" ||
            routerState.historyAction === "PUSH") &&
          breaksState !== ReduxStoreState.Initial
        ) {
          // cleanup on leave
          dispatch(clearBreaksAction());
        }
      });
    }, [dispatch, breaksState]);

    useEffect(() => {
      // Haal de spotjes opnieuw op als de secundaire doelgroep wijzigt
      if (filters.secondaryTargetGroup) {
        receiveBreaks();
      }
    }, [filters.secondaryTargetGroup, receiveBreaks]);

    const handleFilters = useCallback((newFilters: BreakFilters) => {
      setFilters(newFilters);
    }, []);

    const filteredBreaks = useMemo(
      () =>
        breaks.filter(
          ({ uniqueId, booked }) =>
            (showSelection === "selection" &&
              innerValue.map((s) => s.key).includes(uniqueId)) ||
            booked ||
            showSelection === "all"
        ),
      [breaks, innerValue, showSelection]
    );

    const breakSelectionValue = useMemo(
      () =>
        innerValue.filter(({ key }) =>
          breaks.map(({ uniqueId }) => uniqueId).includes(key ?? "")
        ),
      [breaks, innerValue]
    );

    const showTop2000Warning = useMemo(
      () =>
        Boolean(settings.settings?.enableTop2000Warning) &&
        breaks.find((s) => s.isDisabledTop2000) !== undefined,
      [breaks, settings]
    );

    const loading = useMemo(
      () => breaksState === ReduxStoreState.Loading,
      [breaksState]
    );

    useEffect(() => {
      if (
        visible &&
        breaksState === ReduxStoreState.Success &&
        breaks.length > 0
      ) {
        // Voeg de spotjes die al geboekt zijn toe aan de huidige selectie
        const bookedBreaks =
          breaks
            .filter((b) => b.booked)
            .map(
              (b: AvailableBreak): BreakSelection => ({
                key: b.uniqueId,
                _break: b,
                preferredPosition: b.preferredPosition,
              })
            ) ?? [];
        if (bookedBreaks.length > 0) {
          updateInnerValue(bookedBreaks);
        }
      }
    }, [breaks, breaksState, visible]);

    const updateSelectedBreaks = useCallback(() => {
      // Update de bestaande blokselectie adhv de huidige blokken in de store op basis van het id
      // Door het wijzigen van pakketparameters kunnen prijzen gewijzigd zijn
      const selectedBreaks =
        breaks
          .filter((b) => originalValue.some((s) => s.key === b.uniqueId))
          .map(
            (b: AvailableBreak): BreakSelection => ({
              key: b.uniqueId,
              _break: b,
              preferredPosition: originalValue.find((s) => s.key === b.uniqueId)
                ?.preferredPosition,
            })
          ) ?? [];

      if (JSON.stringify(selectedBreaks) !== JSON.stringify(originalValue)) {
        onChange(selectedBreaks);
      }
    }, [breaks, onChange, originalValue]);

    useEffect(() => {
      if (breaksState === ReduxStoreState.Success) {
        updateSelectedBreaks();
      }
    }, [breaksState, updateSelectedBreaks]);

    return (
      <>
        <Button
          mode="secondary"
          onClick={handleOpen}
          disabled={visible}
          loading={loading}
        >
          {buttonText ?? <Trans>Selecteer blokken</Trans>}
        </Button>
        {visible && (
          <BreakSelectModal
            onOk={handleOk}
            onCancel={handleCancel}
            selectionCount={innerValue.length}
            breaks={filteredBreaks}
            value={breakSelectionValue}
            channels={channels}
            onChange={handleChange}
            onFilter={handleFilters}
            defaultFilters={initialFilters}
            filters={filters}
            showSelection={showSelection}
            setShowSelection={setShowSelection}
            secondaryTargetGroups={secondaryTargetGroupsByMedium}
            loading={loading}
            showTop2000Warning={showTop2000Warning}
            canSelectPreferredPosition={canSelectPreferredPosition}
            preferredPositionSurcharge={preferredPositionSurcharge}
            budget={query.budget}
            footer={
              query.orderId && query.subOrderId ? (
                <BookSpotFooter
                  breaks={breaks}
                  value={innerValue}
                  currentBalance={currentBalance}
                  currentGRPBalance={currentGRPBalance}
                  onCancel={handleCancel}
                  onOk={handleOk}
                  orderId={query.orderId}
                  subOrderId={query.subOrderId}
                  showTop2000Warning={showTop2000Warning}
                />
              ) : undefined
            }
            alreadySelected={alreadySelected}
          />
        )}
      </>
    );
  }
);

export default BreakSelectContainer;
