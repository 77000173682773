import { memo, useEffect, useMemo, useState } from "react";

import styles from "./AdScienceTagPlayer.module.less";

interface AdScienceTagPlayerProps {
  width: number;
  height: number;
  tag: string;
  autoSizeToComponentRef?: React.RefObject<HTMLDivElement>;
}
const AdScienceTagPlayer = memo(
  ({ width, height, tag, autoSizeToComponentRef }: AdScienceTagPlayerProps) => {
    const [autoSizeTo, setAutoSizeTo] = useState<{
      width: number;
      height: number;
    } | null>(null);

    useEffect(() => {
      if (autoSizeToComponentRef?.current) {
        setAutoSizeTo({
          width: autoSizeToComponentRef.current.offsetWidth,
          height: autoSizeToComponentRef.current.offsetHeight,
        });
      }
    }, [autoSizeToComponentRef, setAutoSizeTo]);

    useEffect(() => {
      const element = autoSizeToComponentRef?.current;
      if (!element) return () => ({});

      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const el = entry.target as HTMLElement;
          setAutoSizeTo({
            width: el?.offsetWidth,
            height: el?.offsetHeight,
          });
        }
      });

      resizeObserver.observe(element);

      return () => {
        resizeObserver.disconnect();
      };
    }, [autoSizeToComponentRef]);

    const zoomRatio = useMemo(() => {
      if (!autoSizeTo) return 1;

      if (!width || !height) {
        return 1;
      }

      const widthRatio = autoSizeTo.width / width;
      const heightRatio = autoSizeTo.height / height;

      return Math.min(widthRatio, heightRatio);
    }, [autoSizeTo, width, height]);

    const zoomStyle = useMemo(
      () =>
        autoSizeToComponentRef?.current
          ? {
              transform: `scale(${zoomRatio})`,
              transformOrigin: "top left",
            }
          : {},
      [autoSizeToComponentRef, zoomRatio]
    );

    const scaleContainerStyle = useMemo(
      () => ({
        width: zoomRatio * width,
        height: zoomRatio * height,
      }),
      [zoomRatio, width, height]
    );

    return (
      <div className={styles.center}>
        <div style={scaleContainerStyle}>
          <div
            className={`${styles.disablePointerEvents}`}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: tag }}
            style={{ ...zoomStyle, width, height }}
          />
        </div>
      </div>
    );
  }
);

export default AdScienceTagPlayer;
