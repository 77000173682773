import "./styles.less";

import { Trans } from "@lingui/macro";
import {
  Channel as ChannelIcon,
  ChannelNames,
  Typography,
  formatDate,
} from "@ster/ster-toolkit";
import { Carousel as AntDCarousel } from "antd";
import { NavLink } from "react-router-dom";

import { Block, Channel, SpotsByMedium } from "../../../api/models";
import { NextArrow, PrevArrow } from "./Arrows";
import MediumIcon from "./MediumIcon";
import { CarouselItem, Slide } from "./Slide";

interface CarouselProps {
  data: SpotsByMedium;
}

const Carousel = ({ data }: CarouselProps) => {
  const { channels, medium } = data;

  const mapBlocks = (blocks: Block[]): CarouselItem[] => {
    let carouselItems: CarouselItem[] = [];
    // Programma er voor
    blocks.forEach((block) => {
      carouselItems.push({
        time: block.programmeBeforeTime,
        title: block.programmeBefore,
        type: "before",
        id: block.breakId,
      });

      // Spotjes
      carouselItems = carouselItems.concat(
        ...block.spots.map(
          (s) =>
            ({
              time: formatDate(block.schedStartTime, "p"),
              id: s.spotId,
              title: s.advertiserName,
              name: s.productDescr,
              subTitle: s.packageDescr,
              type: "blockspot",
            }) as CarouselItem
        )
      );

      // Programma er na
      carouselItems.push({
        time: block.programmeAfterTime,
        title: block.programmeAfter,
        type: "after",
        id: block.breakId,
      });
    });
    return carouselItems;
  };

  return (
    <section className="carousel">
      <div className="carousel__header">
        <MediumIcon medium={medium} />
        <Typography.Text>
          <NavLink to="/campaigns" className="carousel__header-link">
            <Trans>Alle campagnes</Trans>
          </NavLink>
        </Typography.Text>
      </div>
      {channels.map((c: Channel) => (
        <div className="carousel__channel" key={c.channelAka}>
          <div className="carousel__channel-icon">
            <ChannelIcon
              type={c.channelDescr as ChannelNames}
              width="100%"
              height="100%"
            />
          </div>
          <div className="carousel__track">
            <AntDCarousel
              className="carousel"
              arrows
              dots={false}
              infinite={false}
              draggable
              variableWidth
              nextArrow={<NextArrow />}
              prevArrow={<PrevArrow />}
            >
              {mapBlocks(c.blocks).map((b: CarouselItem) => (
                <Slide {...b} key={b.id} />
              ))}
            </AntDCarousel>
          </div>
        </div>
      ))}
    </section>
  );
};

export default Carousel;
