import "./steps.less";

import { PropsWithChildren } from "react";

export type StepStatus = "wait" | "process" | "finished";

const Steps = ({ children }: PropsWithChildren<unknown>) => (
  <div className="steps">{children}</div>
);

export default Steps;
