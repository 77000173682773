import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Icons, Tooltip, Typography } from "@ster/ster-toolkit";
import { App as AntApp } from "antd";
import { memo, useCallback } from "react";

import styles from "./DeleteInstruction.module.less";

const DeleteInstruction = memo(
  ({
    instructionId,
    onDelete,
  }: {
    instructionId: number;
    onDelete: (id: number) => void;
  }) => {
    const { i18n } = useLingui();
    const { modal } = AntApp.useApp();
    const handleDelete = useCallback(() => {
      modal.confirm({
        title: i18n._(t`Weet je het zeker?`),
        content: (
          <Typography.Paragraph>
            {i18n._(
              t`Weet je zeker dat je deze uitzendinstructie wilt verwijderen? Deze actie is niet terug te draaien.`
            )}
          </Typography.Paragraph>
        ),
        onOk: () => {
          onDelete(instructionId);
        },
        cancelText: i18n._(t`Behouden`),
        okText: i18n._(t`Verwijderen`),
      });
    }, [i18n, instructionId, modal, onDelete]);

    return (
      <Tooltip
        title={i18n._(t`Verwijder deze uitzendinstructie`)}
        placement="bottom"
      >
        <button
          type="button"
          onClick={handleDelete}
          className={styles["instructions-delete-btn"]}
        >
          <Icons.DeleteIcon />
        </button>
      </Tooltip>
    );
  }
);

export default DeleteInstruction;
