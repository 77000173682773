import { UnknownAction } from "redux-saga";
import { action, createAsyncAction } from "typesafe-actions";

import { ResponseError } from "../base";
import { SecondaryTargetGroups, StoreModel } from "../models";
import { actionNameAsync } from "../utils";

export const receiveSecondaryTargetGroupsAction = createAsyncAction(
  actionNameAsync("secondaryTargetGroups", "REQUEST"),
  actionNameAsync("secondaryTargetGroups", "SUCCESS"),
  actionNameAsync("secondaryTargetGroups", "FAILURE")
)<void, SecondaryTargetGroups, ResponseError>();

export const invalidateActionType = "INVALIDATE";
export const invalidate = (propertyName: keyof StoreModel): UnknownAction =>
  action(invalidateActionType, { propertyName });
