/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { Break, BreakFromJSON, BreakFromJSONTyped, BreakToJSON } from "./";

/**
 *
 * @export
 * @interface BreakSelection
 */
export interface BreakSelection {
  /**
   *
   * @type {string}
   * @memberof BreakSelection
   */
  key?: string | null;
  /**
   *
   * @type {Break}
   * @memberof BreakSelection
   */
  _break?: Break;
  /**
   *
   * @type {string}
   * @memberof BreakSelection
   */
  preferredPosition?: string | null;
}

export function BreakSelectionFromJSON(json: any): BreakSelection {
  return BreakSelectionFromJSONTyped(json, false);
}

export function BreakSelectionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BreakSelection {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    key: !exists(json, "key") ? undefined : json["key"],
    _break: !exists(json, "break") ? undefined : BreakFromJSON(json["break"]),
    preferredPosition: !exists(json, "preferredPosition")
      ? undefined
      : json["preferredPosition"],
  };
}

export function BreakSelectionToJSON(value?: BreakSelection | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    key: value.key,
    break: BreakToJSON(value._break),
    preferredPosition: value.preferredPosition,
  };
}
