/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  CommercialInstructionRequest,
  CommercialInstructionRequestFromJSON,
  CommercialInstructionRequestToJSON,
  MediumEnum,
  MediumEnumFromJSON,
  MediumEnumToJSON,
  OnlineInstructionPerSubOrder,
  OnlineInstructionPerSubOrderFromJSON,
  OnlineInstructionPerSubOrderToJSON,
  ProblemDetails,
  ProblemDetailsFromJSON,
  ProblemDetailsToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface ApiV1CommercialInstructionsDeleteInstructionIdDeleteRequest {
  medium: MediumEnum;
  instructionId: number;
}

export interface ApiV1CommercialInstructionsGetRequest {
  medium?: MediumEnum;
  productId?: number;
  from?: Date;
  to?: Date;
  orderId?: number;
}

export interface ApiV1CommercialInstructionsMultiPostRequest {
  commercialInstructionRequest: Array<CommercialInstructionRequest>;
}

export interface ApiV1CommercialInstructionsOnlineOrderIdGetRequest {
  orderId: number;
}

export interface ApiV1CommercialInstructionsPostRequest {
  commercialInstructionRequest: CommercialInstructionRequest;
}

export interface ApiV1CommercialInstructionsSaveOnlineOrderIdPostRequest {
  onlineInstructionPerSubOrder: Array<OnlineInstructionPerSubOrder>;
  orderId: number;
}

/**
 *
 */
export class CommercialInstructionsApi extends runtime.BaseAPI {
  /**
   */
  async apiV1CommercialInstructionsDeleteInstructionIdDeleteRaw(
    requestParameters: ApiV1CommercialInstructionsDeleteInstructionIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.medium === null ||
      requestParameters.medium === undefined
    ) {
      throw new runtime.RequiredError(
        "medium",
        "Required parameter requestParameters.medium was null or undefined when calling apiV1CommercialInstructionsDeleteInstructionIdDelete."
      );
    }

    if (
      requestParameters.instructionId === null ||
      requestParameters.instructionId === undefined
    ) {
      throw new runtime.RequiredError(
        "instructionId",
        "Required parameter requestParameters.instructionId was null or undefined when calling apiV1CommercialInstructionsDeleteInstructionIdDelete."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.medium !== undefined) {
      queryParameters["medium"] = requestParameters.medium;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/CommercialInstructions/delete/{instructionId}`.replace(
        `{${"instructionId"}}`,
        encodeURIComponent(String(requestParameters.instructionId))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async apiV1CommercialInstructionsDeleteInstructionIdDelete(
    requestParameters: ApiV1CommercialInstructionsDeleteInstructionIdDeleteRequest
  ): Promise<void> {
    await this.apiV1CommercialInstructionsDeleteInstructionIdDeleteRaw(
      requestParameters
    );
  }

  /**
   */
  async apiV1CommercialInstructionsGetRaw(
    requestParameters: ApiV1CommercialInstructionsGetRequest
  ): Promise<runtime.ApiResponse<Array<CommercialInstructionRequest>>> {
    const queryParameters: any = {};

    if (requestParameters.medium !== undefined) {
      queryParameters["medium"] = requestParameters.medium;
    }

    if (requestParameters.productId !== undefined) {
      queryParameters["productId"] = requestParameters.productId;
    }

    if (requestParameters.from !== undefined) {
      queryParameters["from"] = (requestParameters.from as any).toISOString();
    }

    if (requestParameters.to !== undefined) {
      queryParameters["to"] = (requestParameters.to as any).toISOString();
    }

    if (requestParameters.orderId !== undefined) {
      queryParameters["orderId"] = requestParameters.orderId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/CommercialInstructions`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(CommercialInstructionRequestFromJSON)
    );
  }

  /**
   */
  async apiV1CommercialInstructionsGet(
    requestParameters: ApiV1CommercialInstructionsGetRequest
  ): Promise<Array<CommercialInstructionRequest>> {
    const response =
      await this.apiV1CommercialInstructionsGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1CommercialInstructionsMultiPostRaw(
    requestParameters: ApiV1CommercialInstructionsMultiPostRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.commercialInstructionRequest === null ||
      requestParameters.commercialInstructionRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "commercialInstructionRequest",
        "Required parameter requestParameters.commercialInstructionRequest was null or undefined when calling apiV1CommercialInstructionsMultiPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/CommercialInstructions/multi`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: requestParameters.commercialInstructionRequest.map(
        CommercialInstructionRequestToJSON
      ),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async apiV1CommercialInstructionsMultiPost(
    requestParameters: ApiV1CommercialInstructionsMultiPostRequest
  ): Promise<void> {
    await this.apiV1CommercialInstructionsMultiPostRaw(requestParameters);
  }

  /**
   */
  async apiV1CommercialInstructionsOnlineOrderIdGetRaw(
    requestParameters: ApiV1CommercialInstructionsOnlineOrderIdGetRequest
  ): Promise<runtime.ApiResponse<Array<OnlineInstructionPerSubOrder>>> {
    if (
      requestParameters.orderId === null ||
      requestParameters.orderId === undefined
    ) {
      throw new runtime.RequiredError(
        "orderId",
        "Required parameter requestParameters.orderId was null or undefined when calling apiV1CommercialInstructionsOnlineOrderIdGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/CommercialInstructions/online/{orderId}`.replace(
        `{${"orderId"}}`,
        encodeURIComponent(String(requestParameters.orderId))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(OnlineInstructionPerSubOrderFromJSON)
    );
  }

  /**
   */
  async apiV1CommercialInstructionsOnlineOrderIdGet(
    requestParameters: ApiV1CommercialInstructionsOnlineOrderIdGetRequest
  ): Promise<Array<OnlineInstructionPerSubOrder>> {
    const response =
      await this.apiV1CommercialInstructionsOnlineOrderIdGetRaw(
        requestParameters
      );
    return await response.value();
  }

  /**
   */
  async apiV1CommercialInstructionsPostRaw(
    requestParameters: ApiV1CommercialInstructionsPostRequest
  ): Promise<runtime.ApiResponse<CommercialInstructionRequest>> {
    if (
      requestParameters.commercialInstructionRequest === null ||
      requestParameters.commercialInstructionRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "commercialInstructionRequest",
        "Required parameter requestParameters.commercialInstructionRequest was null or undefined when calling apiV1CommercialInstructionsPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/CommercialInstructions`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: CommercialInstructionRequestToJSON(
        requestParameters.commercialInstructionRequest
      ),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CommercialInstructionRequestFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1CommercialInstructionsPost(
    requestParameters: ApiV1CommercialInstructionsPostRequest
  ): Promise<CommercialInstructionRequest> {
    const response =
      await this.apiV1CommercialInstructionsPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1CommercialInstructionsSaveOnlineOrderIdPostRaw(
    requestParameters: ApiV1CommercialInstructionsSaveOnlineOrderIdPostRequest
  ): Promise<runtime.ApiResponse<boolean>> {
    if (
      requestParameters.onlineInstructionPerSubOrder === null ||
      requestParameters.onlineInstructionPerSubOrder === undefined
    ) {
      throw new runtime.RequiredError(
        "onlineInstructionPerSubOrder",
        "Required parameter requestParameters.onlineInstructionPerSubOrder was null or undefined when calling apiV1CommercialInstructionsSaveOnlineOrderIdPost."
      );
    }

    if (
      requestParameters.orderId === null ||
      requestParameters.orderId === undefined
    ) {
      throw new runtime.RequiredError(
        "orderId",
        "Required parameter requestParameters.orderId was null or undefined when calling apiV1CommercialInstructionsSaveOnlineOrderIdPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/CommercialInstructions/saveOnline/{orderId}`.replace(
        `{${"orderId"}}`,
        encodeURIComponent(String(requestParameters.orderId))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: requestParameters.onlineInstructionPerSubOrder.map(
        OnlineInstructionPerSubOrderToJSON
      ),
    });

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   */
  async apiV1CommercialInstructionsSaveOnlineOrderIdPost(
    requestParameters: ApiV1CommercialInstructionsSaveOnlineOrderIdPostRequest
  ): Promise<boolean> {
    const response =
      await this.apiV1CommercialInstructionsSaveOnlineOrderIdPostRaw(
        requestParameters
      );
    return await response.value();
  }
}
