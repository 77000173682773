/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  CommercialUploadStatus,
  CommercialUploadStatusFromJSON,
  CommercialUploadStatusFromJSONTyped,
  CommercialUploadStatusToJSON,
} from "./";

/**
 *
 * @export
 * @interface CommercialUploadRegistrationReview
 */
export interface CommercialUploadRegistrationReview {
  /**
   *
   * @type {string}
   * @memberof CommercialUploadRegistrationReview
   */
  message?: string | null;
  /**
   *
   * @type {CommercialUploadStatus}
   * @memberof CommercialUploadRegistrationReview
   */
  newStatus: CommercialUploadStatus;
  /**
   *
   * @type {number}
   * @memberof CommercialUploadRegistrationReview
   */
  internalId: number;
}

export function CommercialUploadRegistrationReviewFromJSON(
  json: any
): CommercialUploadRegistrationReview {
  return CommercialUploadRegistrationReviewFromJSONTyped(json, false);
}

export function CommercialUploadRegistrationReviewFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CommercialUploadRegistrationReview {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    message: !exists(json, "message") ? undefined : json["message"],
    newStatus: CommercialUploadStatusFromJSON(json["newStatus"]),
    internalId: json["internalId"],
  };
}

export function CommercialUploadRegistrationReviewToJSON(
  value?: CommercialUploadRegistrationReview | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    message: value.message,
    newStatus: CommercialUploadStatusToJSON(value.newStatus),
    internalId: value.internalId,
  };
}
