import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

import { SettingsGetResponse } from "../../api";
import { SettingsApi } from "../../api/apis";
import apiConfig from "../../apiConfig";
import { toError } from "../utils";
import { receiveSettingsAction } from "./actions";

// eslint-disable-next-line import/prefer-default-export
export function* receiveSettings(): SagaIterator {
  try {
    const settingsApi = new SettingsApi(apiConfig());
    const response: SettingsGetResponse = yield call(() =>
      settingsApi.apiV1SettingsGet()
    );
    yield put(receiveSettingsAction.success(response));
  } catch (err) {
    yield put(receiveSettingsAction.failure(toError(err)));
  }
}
