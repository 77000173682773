import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

import { CampaignApi, CommercialInstructionsApi } from "../../api/apis";
import apiConfig from "../../apiConfig";
import { toError } from "../utils";
import {
  receiveCampaignInstructionsOnlineAction,
  receiveEditableCampaignAction,
  updateCampaignAction,
} from "./actions";

export function* updateCampaign(
  action: ReturnType<typeof updateCampaignAction.request>
): SagaIterator {
  try {
    const campaignApi = new CampaignApi(apiConfig());
    const response: void = yield call(() =>
      campaignApi.apiV1CampaignUpdatePost({
        orderRequest: action.payload.orderRequest,
      })
    );
    yield put(updateCampaignAction.success());

    if (action.payload.onSuccess) {
      yield call(action.payload.onSuccess, response);
    }
  } catch (err) {
    yield put(
      updateCampaignAction.failure({
        orderId: action.payload.orderRequest?.id ?? 0,
        ...toError(err),
      })
    );
  }
}

export function* receiveCampaignInstructionsOnline(
  action: ReturnType<typeof receiveCampaignInstructionsOnlineAction.request>
): SagaIterator {
  try {
    const campaignApi = new CommercialInstructionsApi(apiConfig());
    const response = yield call(() =>
      campaignApi.apiV1CommercialInstructionsOnlineOrderIdGet(action.payload)
    );
    yield put(receiveCampaignInstructionsOnlineAction.success(response));
  } catch (err) {
    yield put(
      receiveCampaignInstructionsOnlineAction.failure({
        ...toError(err),
      })
    );
  }
}

export function* receiveEditableCampaign(
  action: ReturnType<typeof receiveEditableCampaignAction.request>
): SagaIterator {
  try {
    const campaignApi = new CampaignApi(apiConfig());
    const response = yield call(() =>
      campaignApi.apiV1CampaignGeteditablecampaignGet(action.payload)
    );
    yield put(receiveEditableCampaignAction.success(response));
  } catch (err) {
    yield put(
      receiveEditableCampaignAction.failure({
        orderId: action.payload.orderId,
        ...toError(err),
      })
    );
  }
}
