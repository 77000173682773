/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  ProblemDetails,
  ProblemDetailsFromJSON,
  ProblemDetailsToJSON,
  ProposalData,
  ProposalDataFromJSON,
  ProposalDataToJSON,
  ProposalOrderRequest,
  ProposalOrderRequestFromJSON,
  ProposalOrderRequestToJSON,
  ProposalRequest,
  ProposalRequestFromJSON,
  ProposalRequestToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface ApiV1ProposalGenerateProposalPostRequest {
  proposalOrderRequest: ProposalOrderRequest;
}

export interface ApiV1ProposalGetProposalDataPostRequest {
  proposalRequest: ProposalRequest;
}

export interface ApiV1ProposalPostRequest {
  proposalRequest: ProposalRequest;
}

export interface ApiV1ProposalTestYearGetRequest {
  year: number;
}

export interface ApiV1ProposalUploadForecastPostRequest {
  files: Array<Blob>;
  medium: string;
  year: number;
  advertiser?: string;
}

/**
 *
 */
export class ProposalApi extends runtime.BaseAPI {
  /**
   */
  async apiV1ProposalGenerateProposalPostRaw(
    requestParameters: ApiV1ProposalGenerateProposalPostRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.proposalOrderRequest === null ||
      requestParameters.proposalOrderRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "proposalOrderRequest",
        "Required parameter requestParameters.proposalOrderRequest was null or undefined when calling apiV1ProposalGenerateProposalPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Proposal/generateProposal`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ProposalOrderRequestToJSON(requestParameters.proposalOrderRequest),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async apiV1ProposalGenerateProposalPost(
    requestParameters: ApiV1ProposalGenerateProposalPostRequest
  ): Promise<void> {
    await this.apiV1ProposalGenerateProposalPostRaw(requestParameters);
  }

  /**
   */
  async apiV1ProposalGetProposalDataPostRaw(
    requestParameters: ApiV1ProposalGetProposalDataPostRequest
  ): Promise<runtime.ApiResponse<ProposalData>> {
    if (
      requestParameters.proposalRequest === null ||
      requestParameters.proposalRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "proposalRequest",
        "Required parameter requestParameters.proposalRequest was null or undefined when calling apiV1ProposalGetProposalDataPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Proposal/getProposalData`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ProposalRequestToJSON(requestParameters.proposalRequest),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProposalDataFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1ProposalGetProposalDataPost(
    requestParameters: ApiV1ProposalGetProposalDataPostRequest
  ): Promise<ProposalData> {
    const response =
      await this.apiV1ProposalGetProposalDataPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1ProposalPostRaw(
    requestParameters: ApiV1ProposalPostRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.proposalRequest === null ||
      requestParameters.proposalRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "proposalRequest",
        "Required parameter requestParameters.proposalRequest was null or undefined when calling apiV1ProposalPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Proposal`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ProposalRequestToJSON(requestParameters.proposalRequest),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async apiV1ProposalPost(
    requestParameters: ApiV1ProposalPostRequest
  ): Promise<void> {
    await this.apiV1ProposalPostRaw(requestParameters);
  }

  /**
   */
  async apiV1ProposalTestYearGetRaw(
    requestParameters: ApiV1ProposalTestYearGetRequest
  ): Promise<runtime.ApiResponse<Blob>> {
    if (
      requestParameters.year === null ||
      requestParameters.year === undefined
    ) {
      throw new runtime.RequiredError(
        "year",
        "Required parameter requestParameters.year was null or undefined when calling apiV1ProposalTestYearGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Proposal/test/{year}`.replace(
        `{${"year"}}`,
        encodeURIComponent(String(requestParameters.year))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async apiV1ProposalTestYearGet(
    requestParameters: ApiV1ProposalTestYearGetRequest
  ): Promise<Blob> {
    const response = await this.apiV1ProposalTestYearGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1ProposalUploadForecastPostRaw(
    requestParameters: ApiV1ProposalUploadForecastPostRequest
  ): Promise<runtime.ApiResponse<Blob>> {
    if (
      requestParameters.files === null ||
      requestParameters.files === undefined
    ) {
      throw new runtime.RequiredError(
        "files",
        "Required parameter requestParameters.files was null or undefined when calling apiV1ProposalUploadForecastPost."
      );
    }

    if (
      requestParameters.medium === null ||
      requestParameters.medium === undefined
    ) {
      throw new runtime.RequiredError(
        "medium",
        "Required parameter requestParameters.medium was null or undefined when calling apiV1ProposalUploadForecastPost."
      );
    }

    if (
      requestParameters.year === null ||
      requestParameters.year === undefined
    ) {
      throw new runtime.RequiredError(
        "year",
        "Required parameter requestParameters.year was null or undefined when calling apiV1ProposalUploadForecastPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const consumes: runtime.Consume[] = [
      { contentType: "multipart/form-data" },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.files) {
      requestParameters.files.forEach((element) => {
        formParams.append("Files", element as any);
      });
    }

    if (requestParameters.medium !== undefined) {
      formParams.append("Medium", requestParameters.medium as any);
    }

    if (requestParameters.advertiser !== undefined) {
      formParams.append("Advertiser", requestParameters.advertiser as any);
    }

    if (requestParameters.year !== undefined) {
      formParams.append("Year", requestParameters.year as any);
    }

    const response = await this.request({
      path: `/api/v1/Proposal/uploadForecast`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async apiV1ProposalUploadForecastPost(
    requestParameters: ApiV1ProposalUploadForecastPostRequest
  ): Promise<Blob> {
    const response =
      await this.apiV1ProposalUploadForecastPostRaw(requestParameters);
    return await response.value();
  }
}
