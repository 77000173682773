import { plural, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form } from "@ster/ster-toolkit";
import { memo } from "react";

import DayOfWeekPicker from "./DayOfWeekPicker";
import { DaySelectionProps } from "./models";

const DaySelection = memo(
  ({
    _package,
    enabledDaysOfWeek,
    enabled,
    shortDayNames,
  }: DaySelectionProps) => {
    const { i18n } = useLingui();
    return (
      <Form.Item
        name="daysOfWeek"
        rules={[
          {
            validator: (_, value): Promise<void> =>
              value?.length >= _package.minDaysNoWeekly
                ? Promise.resolve()
                : Promise.reject(
                    new Error(
                      i18n._(
                        t`Je dient minimaal ${_package.minDaysNoWeekly} ${plural(_package.minDaysNoWeekly, { one: "dag", other: "dagen" })} te selecteren`
                      )
                    )
                  ),
          },
        ]}
      >
        <DayOfWeekPicker
          enabledDays={enabledDaysOfWeek}
          disabled={!enabled}
          shortDayNames={shortDayNames}
        />
      </Form.Item>
    );
  }
);

export default DaySelection;
