/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  OutputPricePerMonth,
  OutputPricePerMonthFromJSON,
  OutputPricePerMonthFromJSONTyped,
  OutputPricePerMonthToJSON,
} from "./";

/**
 *
 * @export
 * @interface InternetFormat
 */
export interface InternetFormat {
  /**
   *
   * @type {string}
   * @memberof InternetFormat
   */
  id?: string | null;
  /**
   *
   * @type {string}
   * @memberof InternetFormat
   */
  description?: string | null;
  /**
   *
   * @type {number}
   * @memberof InternetFormat
   */
  spotLength?: number;
  /**
   *
   * @type {number}
   * @memberof InternetFormat
   */
  index?: number;
  /**
   *
   * @type {number}
   * @memberof InternetFormat
   */
  cpmPrice?: number;
  /**
   *
   * @type {Array<OutputPricePerMonth>}
   * @memberof InternetFormat
   */
  outputPrice?: Array<OutputPricePerMonth> | null;
}

export function InternetFormatFromJSON(json: any): InternetFormat {
  return InternetFormatFromJSONTyped(json, false);
}

export function InternetFormatFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InternetFormat {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    description: !exists(json, "description") ? undefined : json["description"],
    spotLength: !exists(json, "spotLength") ? undefined : json["spotLength"],
    index: !exists(json, "index") ? undefined : json["index"],
    cpmPrice: !exists(json, "cpmPrice") ? undefined : json["cpmPrice"],
    outputPrice: !exists(json, "outputPrice")
      ? undefined
      : json["outputPrice"] === null
        ? null
        : (json["outputPrice"] as Array<any>).map(OutputPricePerMonthFromJSON),
  };
}

export function InternetFormatToJSON(value?: InternetFormat | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    description: value.description,
    spotLength: value.spotLength,
    index: value.index,
    cpmPrice: value.cpmPrice,
    outputPrice:
      value.outputPrice === undefined
        ? undefined
        : value.outputPrice === null
          ? null
          : (value.outputPrice as Array<any>).map(OutputPricePerMonthToJSON),
  };
}
