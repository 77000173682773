/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  DateSpan,
  DateSpanFromJSON,
  DateSpanFromJSONTyped,
  DateSpanToJSON,
} from "./";

/**
 *
 * @export
 * @interface CampaignTotals
 */
export interface CampaignTotals {
  /**
   *
   * @type {DateSpan}
   * @memberof CampaignTotals
   */
  period: DateSpan;
  /**
   *
   * @type {number}
   * @memberof CampaignTotals
   */
  budget: number;
  /**
   *
   * @type {number}
   * @memberof CampaignTotals
   */
  impressions: number;
}

export function CampaignTotalsFromJSON(json: any): CampaignTotals {
  return CampaignTotalsFromJSONTyped(json, false);
}

export function CampaignTotalsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CampaignTotals {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    period: DateSpanFromJSON(json["period"]),
    budget: json["budget"],
    impressions: json["impressions"],
  };
}

export function CampaignTotalsToJSON(value?: CampaignTotals | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    period: DateSpanToJSON(value.period),
    budget: value.budget,
    impressions: value.impressions,
  };
}
