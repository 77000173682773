import Cookies from "js-cookie";

import { PortalUser } from "../api";
import { ReduxStoreState } from "../store/base";
import { LoadingPortalUser } from "../store/models";
import { impersonateCookieName } from "./constants";

export const isAccountReady = (account: LoadingPortalUser) =>
  account.state !== ReduxStoreState.Loading;
export const isLoggedIn = (account: LoadingPortalUser) =>
  Boolean(account && account.email && account.active) &&
  isAccountReady(account);
export const isAdmin = (account: LoadingPortalUser) =>
  Boolean(isLoggedIn(account) && account.roles.includes("Admin"));
export const isInternalUser = (account: LoadingPortalUser) =>
  Boolean(isLoggedIn(account) && account.sterInlog?.internalUser);
export const isInternalUserOrAdmin = (account: LoadingPortalUser) =>
  isInternalUser(account) || isAdmin(account);
export const isIntermediair = (account: PortalUser) =>
  account.roles.includes("Intermediair");
export const isMedewerker = (account: PortalUser) =>
  account.roles.includes("Medewerker");
export const isImpersonating = (account: LoadingPortalUser) =>
  Boolean(
    isLoggedIn(account) &&
      ["user", "intermediair"].includes(
        Cookies.get(impersonateCookieName) ?? ""
      )
  );

export const canProposeMultipleCampaigns = (account: LoadingPortalUser) =>
  isIntermediair(account) || isInternalUser(account);
export const canUseProspect = (account: LoadingPortalUser) =>
  isIntermediair(account) || isInternalUser(account);
