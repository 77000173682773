import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { ContentContainer, Row, Select, Typography } from "@ster/ster-toolkit";
import { Col, Divider } from "antd";
import classNames from "classnames";
import { memo, useCallback, useState } from "react";

import DocumentCard from "../partials/Cards/Document/DocumentCard";
import shared from "../Shared.module.less";
import styles from "./Document.module.less";
import { DocumentListProps } from "./models";

const DocumentList = memo(
  ({
    organisations,
    onSelectOrganisation,
    selectedOrganisation,
    advertisers,
    onSelectAdvertiser,
    selectedAdvertiser,
    documents,
    handleDeleteDocument,
    editable,
    showAllDetails,
  }: DocumentListProps): React.ReactElement => {
    const { i18n } = useLingui();
    const [orgsOpened, setOrgsOpened] = useState(false);

    const selectAllOrgs = useCallback(() => {
      setOrgsOpened(false);
      onSelectOrganisation("");
    }, [onSelectOrganisation]);

    return (
      <ContentContainer>
        <Row
          className={classNames(shared.filterrow, "form-filtering-paging")}
          gutter={[24, 24]}
          justify="end"
        >
          {organisations.length > 1 && (
            <Col sm={24} md={6}>
              <Select.Search
                onChange={onSelectOrganisation}
                value={selectedOrganisation}
                placeholder={i18n._(t`Kies een organisatie`)}
                allowClear={false}
                optionFilterProp="label"
                open={orgsOpened}
                onDropdownVisibleChange={setOrgsOpened}
                options={[
                  {
                    label: i18n._(t`Alle organisaties`),
                    value: "",
                  },
                  ...organisations.map(({ code, name }) => ({
                    label: name,
                    value: code ?? "",
                  })),
                ]}
                dropdownRender={(menu) => (
                  <div>
                    {selectedOrganisation !== "" && (
                      <>
                        <div className={styles.defaultOption}>
                          <button
                            type="button"
                            onClick={selectAllOrgs}
                            className="link"
                          >
                            <Trans>Alle organisaties</Trans>
                          </button>
                        </div>
                        <Divider />
                      </>
                    )}
                    {menu}
                  </div>
                )}
              />
            </Col>
          )}
          <Col sm={24} md={6}>
            <Select.Search
              onChange={onSelectAdvertiser}
              value={selectedAdvertiser}
              placeholder={i18n._(t`Kies een adverteerder`)}
              allowClear
              optionFilterProp="label"
              options={[
                {
                  label: i18n._(t`Alle adverteerders`),
                  value: 0,
                },
                ...advertisers.map(({ id, name }) => ({
                  label: name,
                  value: id,
                })),
              ]}
              disabled={advertisers.length <= 1}
            />
          </Col>
        </Row>
        <Row className={shared.filterrow} gutter={[24, 24]}>
          <Col span={24}>
            {documents.length === 0 ? (
              <Trans>
                <Typography.Paragraph>
                  Er zijn geen documenten beschikbaar.
                </Typography.Paragraph>
              </Trans>
            ) : (
              documents.map((document) => (
                <Row gutter={[40, 40]} key={document.guid}>
                  <Col span={24}>
                    <DocumentCard
                      cardData={document}
                      handleDeleteDocument={handleDeleteDocument}
                      editable={editable}
                      showAllDetails={showAllDetails}
                    />
                  </Col>
                </Row>
              ))
            )}
          </Col>
        </Row>
      </ContentContainer>
    );
  }
);

export default DocumentList;
