import "./thumbnailslider.less";

import { useCallback, useEffect, useState } from "react";

import { MediumEnum } from "../../../../api/models";
import FallbackIcon from "./FallbackIcon";

interface ThumbnailSliderProps {
  images: string[];
  title: string;
  medium: MediumEnum;
}

const ThumbnailSlider = ({ images, title, medium }: ThumbnailSliderProps) => {
  const [hover, updateHover] = useState(false);
  const [imgIdx, updateImgIdx] = useState(0);

  const handleMouseOver = useCallback(() => {
    updateHover(true);
  }, []);

  const handleMouseOut = useCallback(() => {
    updateHover(false);
    updateImgIdx(0);
  }, []);

  const handleNext = useCallback(() => {
    updateImgIdx(imgIdx === images.length - 1 ? 0 : imgIdx + 1);
  }, [images.length, imgIdx]);

  useEffect(() => {
    if (!hover) {
      return;
    }

    const intervalId = setInterval(handleNext, 500);
    // eslint-disable-next-line consistent-return
    return (): void => {
      clearInterval(intervalId);
    };
  }, [handleNext, hover]);

  return (
    <figure className="thumbnail-slider">
      {images.length > 0 ? (
        <div
          onMouseOver={handleMouseOver}
          onFocus={handleMouseOver}
          onMouseOut={handleMouseOut}
          onBlur={handleMouseOut}
        >
          <img className="thumbnail-slide" src={images[imgIdx]} alt={title} />
        </div>
      ) : (
        <FallbackIcon medium={medium} />
      )}
    </figure>
  );
};

export default ThumbnailSlider;
