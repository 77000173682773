import { Trans } from "@lingui/macro";
import { Icons, Typography } from "@ster/ster-toolkit";

import { MediumEnum } from "../../../api/models";

interface MediumIconProps {
  medium: MediumEnum;
}

const MediumIcon = ({ medium }: MediumIconProps) => {
  const { Title } = Typography;
  const { TVIcon, RadioIcon } = Icons;

  switch (medium) {
    case "tv":
      return (
        <div className="medium-icon__wrapper medium-icon__wrapper--tv">
          <TVIcon width="100%" height="100%" />
          <Title level={3}>
            <Trans>TV</Trans>
          </Title>
        </div>
      );
    case "radio":
      return (
        <div className="medium-icon__wrapper medium-icon__wrapper--radio">
          <RadioIcon width="100%" height="100%" />
          <Title level={3}>
            <Trans>Radio</Trans>
          </Title>
        </div>
      );

    default:
      return null;
  }
};

export default MediumIcon;
