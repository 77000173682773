/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  SecondaryTargetGroup,
  SecondaryTargetGroupFromJSON,
  SecondaryTargetGroupFromJSONTyped,
  SecondaryTargetGroupToJSON,
} from "./";

/**
 *
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
  /**
   *
   * @type {Array<SecondaryTargetGroup>}
   * @memberof InlineResponse200
   */
  tv?: Array<SecondaryTargetGroup>;
  /**
   *
   * @type {Array<SecondaryTargetGroup>}
   * @memberof InlineResponse200
   */
  radio?: Array<SecondaryTargetGroup>;
  /**
   *
   * @type {Array<SecondaryTargetGroup>}
   * @memberof InlineResponse200
   */
  inter?: Array<SecondaryTargetGroup>;
}

export function InlineResponse200FromJSON(json: any): InlineResponse200 {
  return InlineResponse200FromJSONTyped(json, false);
}

export function InlineResponse200FromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InlineResponse200 {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    tv: !exists(json, "tv")
      ? undefined
      : (json["tv"] as Array<any>).map(SecondaryTargetGroupFromJSON),
    radio: !exists(json, "radio")
      ? undefined
      : (json["radio"] as Array<any>).map(SecondaryTargetGroupFromJSON),
    inter: !exists(json, "inter")
      ? undefined
      : (json["inter"] as Array<any>).map(SecondaryTargetGroupFromJSON),
  };
}

export function InlineResponse200ToJSON(value?: InlineResponse200 | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    tv:
      value.tv === undefined
        ? undefined
        : (value.tv as Array<any>).map(SecondaryTargetGroupToJSON),
    radio:
      value.radio === undefined
        ? undefined
        : (value.radio as Array<any>).map(SecondaryTargetGroupToJSON),
    inter:
      value.inter === undefined
        ? undefined
        : (value.inter as Array<any>).map(SecondaryTargetGroupToJSON),
  };
}
