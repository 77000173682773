import { Trans } from "@lingui/macro";
import { memo, useState } from "react";

import { BreakSelection, MediumEnum } from "../../api";
import BreakSelect from "../breakselect/BreakSelect";
import { emptySelectedBreaks } from "../breakselect/constants";
import { BookSpotProps } from "./models";

const BookSpot = memo(
  ({
    subOrder,
    medium,
    productId,
    orderId,
    canSelectPreferredPosition,
    preferredPositionSurcharge,
  }: BookSpotProps) => {
    const [selectedBreaks, setSelectedBreaks] =
      useState<BreakSelection[]>(emptySelectedBreaks);

    const currentBalance = subOrder.bookedBudget - subOrder.requestedBudget;
    const currentGRPBalance = subOrder.bookedGrp - subOrder.requestedGrp;

    return (
      <BreakSelect
        query={{
          packageCode: subOrder._package.code ?? "",
          targetGroup: subOrder.targetGroup.intomartTargetGroupId ?? "",
          channels: medium === MediumEnum.Radio ? subOrder.channels ?? [] : [],
          productId,
          medium,
          spotLength: subOrder.spotLength?.reduce((a, b) => a + (b ?? 0)) ?? 0,
          ...subOrder.period,
          orderId,
          subOrderId: subOrder.id,
          key: subOrder.id ?? 0,
        }}
        value={selectedBreaks}
        canSelectPreferredPosition={canSelectPreferredPosition}
        preferredPositionSurcharge={preferredPositionSurcharge}
        buttonText={<Trans>Spots bij- of afboeken</Trans>}
        currentBalance={currentBalance}
        currentGRPBalance={currentGRPBalance}
        onChange={setSelectedBreaks}
      />
    );
  }
);

export default BookSpot;
