/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ProductTourModel
 */
export interface ProductTourModel {
  /**
   *
   * @type {number}
   * @memberof ProductTourModel
   */
  lastStep: number;
  /**
   *
   * @type {number}
   * @memberof ProductTourModel
   */
  lastStepViewed: number;
}

export function ProductTourModelFromJSON(json: any): ProductTourModel {
  return ProductTourModelFromJSONTyped(json, false);
}

export function ProductTourModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProductTourModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    lastStep: json["lastStep"],
    lastStepViewed: json["lastStepViewed"],
  };
}

export function ProductTourModelToJSON(value?: ProductTourModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    lastStep: value.lastStep,
    lastStepViewed: value.lastStepViewed,
  };
}
