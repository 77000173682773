import { Icons } from "@ster/ster-toolkit";
import { ButtonHTMLAttributes, ReactElement } from "react";

import styles from "./PlayButton.module.less";

const { PlayIcon } = Icons;

interface PlayButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: ReactElement;
}

const PlayButton: React.FC<PlayButtonProps> = ({
  children,
  icon = <PlayIcon />,
  ...props
}) => (
  <div className={styles.playButtonWrapper}>
    <button type="button" {...props}>
      <div className={styles.play}>{icon}</div>
      {children}
    </button>
  </div>
);

export default PlayButton;
