/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum OrderStatus {
  Concept = "Concept",
  Submitted = "Submitted",
  Planned = "Planned",
  Active = "Active",
  Ready = "Ready",
}

export function OrderStatusFromJSON(json: any): OrderStatus {
  return OrderStatusFromJSONTyped(json, false);
}

export function OrderStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OrderStatus {
  return json as OrderStatus;
}

export function OrderStatusToJSON(value?: OrderStatus | null): any {
  return value as any;
}
