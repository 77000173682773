/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ApplicationFromSterInlog
 */
export interface ApplicationFromSterInlog {
  /**
   *
   * @type {number}
   * @memberof ApplicationFromSterInlog
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationFromSterInlog
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationFromSterInlog
   */
  alias: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationFromSterInlog
   */
  url: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationFromSterInlog
   */
  newWindow: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationFromSterInlog
   */
  partOfDefaultSet: boolean;
}

export function ApplicationFromSterInlogFromJSON(
  json: any
): ApplicationFromSterInlog {
  return ApplicationFromSterInlogFromJSONTyped(json, false);
}

export function ApplicationFromSterInlogFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApplicationFromSterInlog {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    name: json["name"],
    alias: json["alias"],
    url: json["url"],
    newWindow: json["newWindow"],
    partOfDefaultSet: json["partOfDefaultSet"],
  };
}

export function ApplicationFromSterInlogToJSON(
  value?: ApplicationFromSterInlog | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    alias: value.alias,
    url: value.url,
    newWindow: value.newWindow,
    partOfDefaultSet: value.partOfDefaultSet,
  };
}
