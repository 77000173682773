/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface AnalysisAvailability
 */
export interface AnalysisAvailability {
  /**
   *
   * @type {Date}
   * @memberof AnalysisAvailability
   */
  from?: Date | null;
  /**
   *
   * @type {boolean}
   * @memberof AnalysisAvailability
   */
  isAvailable?: boolean;
}

export function AnalysisAvailabilityFromJSON(json: any): AnalysisAvailability {
  return AnalysisAvailabilityFromJSONTyped(json, false);
}

export function AnalysisAvailabilityFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AnalysisAvailability {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    from: !exists(json, "from")
      ? undefined
      : json["from"] === null
        ? null
        : new Date(json["from"]),
    isAvailable: !exists(json, "isAvailable") ? undefined : json["isAvailable"],
  };
}

export function AnalysisAvailabilityToJSON(
  value?: AnalysisAvailability | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    from:
      value.from === undefined
        ? undefined
        : value.from === null
          ? null
          : value.from.toISOString(),
    isAvailable: value.isAvailable,
  };
}
