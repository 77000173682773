/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  BookingDate,
  BookingDateFromJSON,
  BookingDateToJSON,
  CopyOrderInput,
  CopyOrderInputFromJSON,
  CopyOrderInputToJSON,
  MediumEnum,
  MediumEnumFromJSON,
  MediumEnumToJSON,
  OrderRequest,
  OrderRequestFromJSON,
  OrderRequestToJSON,
  ProblemDetails,
  ProblemDetailsFromJSON,
  ProblemDetailsToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface ApiV1PlanningBookingdateGetRequest {
  medium?: MediumEnum;
}

export interface ApiV1PlanningCopyPostRequest {
  copyOrderInput: CopyOrderInput;
  medium?: MediumEnum;
}

export interface ApiV1PlanningDeleteInitialRequestIdPostRequest {
  initialRequestId: number;
  medium?: MediumEnum;
  dateFrom?: Date;
  dateUntil?: Date;
}

export interface ApiV1PlanningFinalizePostRequest {
  orderRequest?: OrderRequest;
}

export interface ApiV1PlanningInitialRequestIdGetRequest {
  initialRequestId: number;
  medium?: MediumEnum;
  from?: Date;
  to?: Date;
}

export interface ApiV1PlanningSavePostRequest {
  orderRequest?: OrderRequest;
}

export interface ApiV1PlanningValidatePostRequest {
  orderRequest?: OrderRequest;
}

/**
 *
 */
export class PlanningApi extends runtime.BaseAPI {
  /**
   */
  async apiV1PlanningBookingdateGetRaw(
    requestParameters: ApiV1PlanningBookingdateGetRequest
  ): Promise<runtime.ApiResponse<BookingDate>> {
    const queryParameters: any = {};

    if (requestParameters.medium !== undefined) {
      queryParameters["medium"] = requestParameters.medium;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Planning/bookingdate`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BookingDateFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1PlanningBookingdateGet(
    requestParameters: ApiV1PlanningBookingdateGetRequest
  ): Promise<BookingDate> {
    const response =
      await this.apiV1PlanningBookingdateGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1PlanningCopyPostRaw(
    requestParameters: ApiV1PlanningCopyPostRequest
  ): Promise<runtime.ApiResponse<OrderRequest>> {
    if (
      requestParameters.copyOrderInput === null ||
      requestParameters.copyOrderInput === undefined
    ) {
      throw new runtime.RequiredError(
        "copyOrderInput",
        "Required parameter requestParameters.copyOrderInput was null or undefined when calling apiV1PlanningCopyPost."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.medium !== undefined) {
      queryParameters["medium"] = requestParameters.medium;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Planning/copy`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: CopyOrderInputToJSON(requestParameters.copyOrderInput),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OrderRequestFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1PlanningCopyPost(
    requestParameters: ApiV1PlanningCopyPostRequest
  ): Promise<OrderRequest> {
    const response = await this.apiV1PlanningCopyPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1PlanningDeleteInitialRequestIdPostRaw(
    requestParameters: ApiV1PlanningDeleteInitialRequestIdPostRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.initialRequestId === null ||
      requestParameters.initialRequestId === undefined
    ) {
      throw new runtime.RequiredError(
        "initialRequestId",
        "Required parameter requestParameters.initialRequestId was null or undefined when calling apiV1PlanningDeleteInitialRequestIdPost."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.medium !== undefined) {
      queryParameters["medium"] = requestParameters.medium;
    }

    if (requestParameters.dateFrom !== undefined) {
      queryParameters["dateFrom"] = (
        requestParameters.dateFrom as any
      ).toISOString();
    }

    if (requestParameters.dateUntil !== undefined) {
      queryParameters["dateUntil"] = (
        requestParameters.dateUntil as any
      ).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Planning/delete/{initialRequestId}`.replace(
        `{${"initialRequestId"}}`,
        encodeURIComponent(String(requestParameters.initialRequestId))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async apiV1PlanningDeleteInitialRequestIdPost(
    requestParameters: ApiV1PlanningDeleteInitialRequestIdPostRequest
  ): Promise<void> {
    await this.apiV1PlanningDeleteInitialRequestIdPostRaw(requestParameters);
  }

  /**
   */
  async apiV1PlanningFinalizePostRaw(
    requestParameters: ApiV1PlanningFinalizePostRequest
  ): Promise<runtime.ApiResponse<OrderRequest>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Planning/finalize`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: OrderRequestToJSON(requestParameters.orderRequest),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OrderRequestFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1PlanningFinalizePost(
    requestParameters: ApiV1PlanningFinalizePostRequest
  ): Promise<OrderRequest> {
    const response = await this.apiV1PlanningFinalizePostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1PlanningInitialRequestIdGetRaw(
    requestParameters: ApiV1PlanningInitialRequestIdGetRequest
  ): Promise<runtime.ApiResponse<OrderRequest>> {
    if (
      requestParameters.initialRequestId === null ||
      requestParameters.initialRequestId === undefined
    ) {
      throw new runtime.RequiredError(
        "initialRequestId",
        "Required parameter requestParameters.initialRequestId was null or undefined when calling apiV1PlanningInitialRequestIdGet."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.medium !== undefined) {
      queryParameters["medium"] = requestParameters.medium;
    }

    if (requestParameters.from !== undefined) {
      queryParameters["from"] = (requestParameters.from as any).toISOString();
    }

    if (requestParameters.to !== undefined) {
      queryParameters["to"] = (requestParameters.to as any).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Planning/{initialRequestId}`.replace(
        `{${"initialRequestId"}}`,
        encodeURIComponent(String(requestParameters.initialRequestId))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OrderRequestFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1PlanningInitialRequestIdGet(
    requestParameters: ApiV1PlanningInitialRequestIdGetRequest
  ): Promise<OrderRequest> {
    const response =
      await this.apiV1PlanningInitialRequestIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1PlanningSavePostRaw(
    requestParameters: ApiV1PlanningSavePostRequest
  ): Promise<runtime.ApiResponse<OrderRequest>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Planning/save`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: OrderRequestToJSON(requestParameters.orderRequest),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OrderRequestFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1PlanningSavePost(
    requestParameters: ApiV1PlanningSavePostRequest
  ): Promise<OrderRequest> {
    const response = await this.apiV1PlanningSavePostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1PlanningValidatePostRaw(
    requestParameters: ApiV1PlanningValidatePostRequest
  ): Promise<runtime.ApiResponse<boolean>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Planning/validate`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: OrderRequestToJSON(requestParameters.orderRequest),
    });

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   */
  async apiV1PlanningValidatePost(
    requestParameters: ApiV1PlanningValidatePostRequest
  ): Promise<boolean> {
    const response = await this.apiV1PlanningValidatePostRaw(requestParameters);
    return await response.value();
  }
}
