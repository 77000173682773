import {
  AnalysisAvailability,
  AnalysisInput,
  AnalysisTargetGroups,
  CommercialInstructionRequest,
  ExportOutputSettings,
  GenericAnalysisInput,
  MediumEnum,
  OrderListItem,
} from "../../api/models";
import { Loading, OnSuccessPayload, ResponseError } from "../base";
import { SaveRequestSuccessProps } from "../campaignCreate/models";

export interface CampaignDetailRequestPayload {
  medium: MediumEnum;
  orderId: number;
}

export interface CampaignDetailFailurePayload extends ResponseError {
  orderId: number;
}

export interface CampaignDownload {
  orderId: number;
  fileType: string;
  data: Blob;
}

export interface CampaignSpotsRequestPayload {
  medium: MediumEnum;
  orderId: number;
  secondaryTargetGroupId?: string;
}

export interface CampaignInstructionsRequestPayload {
  productId: number;
  medium: MediumEnum;
  from: Date;
  to: Date;
  orderId?: number;
}

export interface CampaignInstructionsResponsePayload {
  instructions: CommercialInstructionRequest[];
}

export interface CampaignSpotsFailurePayload extends ResponseError {
  orderId: number;
}

// eslint-disable-next-line no-shadow
export enum DownloadFileType {
  pdf = "pdf",
  xls = "xls",
  csv = "csv",
}

export interface CampaignSpotsDownloadRequestPayload {
  orderId: number;
  subOrderId?: number;
  fileType: DownloadFileType;
}

export interface CampaignExportPayload {
  medium: MediumEnum;
  exportOutputSettings: ExportOutputSettings;
}

export interface DeleteInstructionPayload {
  medium: MediumEnum;
  instructionId: number;
}

export interface CommercialInstructionsPayload
  extends OnSuccessPayload<SaveRequestSuccessProps> {
  instructions: CommercialInstructionRequest[];
}

export interface ValidateCampaignResult {
  valid: boolean;
}

export interface CampaignAnalysisOrder {
  id: number;
  startDate: Date;
  endDate: Date;
  analysis: AnalysisAvailability;
  name?: string;
  productDescr: string;
  productId: number;
  budget: number;
  bookedBudget: number;
}

export interface CampaignAnalysisInput {
  medium: MediumEnum;
  orderId: number;
  analysisInput: AnalysisInput;
}

export interface CampaignAnalysisGenericInput {
  medium: MediumEnum;
  analysisInput: GenericAnalysisInput;
}

export type LoadingCampaignAnalysisTargetGroups = AnalysisTargetGroups &
  Loading;

export interface RelatedCampaignsInput {
  medium: MediumEnum;
  orderId: number;
}

export type RelatedCampaignsOutput = {
  related: Array<OrderListItem>;
} & Loading;
