import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

import { ProductTourApi } from "../../api/apis";
import apiConfig from "../../apiConfig";
import { toError } from "../utils";
import { postProductTourAction, receiveProductTourAction } from "./actions";

export function* receiveProductTour(): SagaIterator {
  try {
    const productTourApi = new ProductTourApi(apiConfig());
    const response = yield call(() => productTourApi.apiV1ProductTourGet());
    yield put(receiveProductTourAction.success(response));
  } catch (err) {
    yield put(receiveProductTourAction.failure(toError(err)));
  }
}

export function* postProductTour(
  action: ReturnType<typeof postProductTourAction.request>
): SagaIterator {
  try {
    const productTourApi = new ProductTourApi(apiConfig());
    const response = yield call(() =>
      productTourApi.apiV1ProductTourPost(action.payload)
    );
    yield put(postProductTourAction.success(response));
  } catch (err) {
    yield put(postProductTourAction.failure(toError(err)));
  }
}
