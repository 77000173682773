/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  MediumEnum,
  MediumEnumFromJSON,
  MediumEnumFromJSONTyped,
  MediumEnumToJSON,
} from "./";

/**
 *
 * @export
 * @interface Product
 */
export interface Product {
  /**
   *
   * @type {number}
   * @memberof Product
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  description: string;
  /**
   *
   * @type {number}
   * @memberof Product
   */
  brandId?: number;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  brandDescr?: string | null;
  /**
   *
   * @type {MediumEnum}
   * @memberof Product
   */
  medium?: MediumEnum;
}

export function ProductFromJSON(json: any): Product {
  return ProductFromJSONTyped(json, false);
}

export function ProductFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Product {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    description: json["description"],
    brandId: !exists(json, "brandId") ? undefined : json["brandId"],
    brandDescr: !exists(json, "brandDescr") ? undefined : json["brandDescr"],
    medium: !exists(json, "medium")
      ? undefined
      : MediumEnumFromJSON(json["medium"]),
  };
}

export function ProductToJSON(value?: Product | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    description: value.description,
    brandId: value.brandId,
    brandDescr: value.brandDescr,
    medium: MediumEnumToJSON(value.medium),
  };
}
