/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  TenantEnum,
  TenantEnumFromJSON,
  TenantEnumFromJSONTyped,
  TenantEnumToJSON,
} from "./";

/**
 *
 * @export
 * @interface AnnouncementDto
 */
export interface AnnouncementDto {
  /**
   *
   * @type {number}
   * @memberof AnnouncementDto
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof AnnouncementDto
   */
  userId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof AnnouncementDto
   */
  show?: boolean;
  /**
   *
   * @type {TenantEnum}
   * @memberof AnnouncementDto
   */
  tenant: TenantEnum;
  /**
   *
   * @type {Date}
   * @memberof AnnouncementDto
   */
  created?: Date;
  /**
   *
   * @type {Date}
   * @memberof AnnouncementDto
   */
  modified?: Date;
  /**
   *
   * @type {Date}
   * @memberof AnnouncementDto
   */
  startDate: Date;
  /**
   *
   * @type {Date}
   * @memberof AnnouncementDto
   */
  endDate: Date;
  /**
   *
   * @type {string}
   * @memberof AnnouncementDto
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof AnnouncementDto
   */
  messageInt?: string | null;
}

export function AnnouncementDtoFromJSON(json: any): AnnouncementDto {
  return AnnouncementDtoFromJSONTyped(json, false);
}

export function AnnouncementDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AnnouncementDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    userId: !exists(json, "userId") ? undefined : json["userId"],
    show: !exists(json, "show") ? undefined : json["show"],
    tenant: TenantEnumFromJSON(json["tenant"]),
    created: !exists(json, "created") ? undefined : new Date(json["created"]),
    modified: !exists(json, "modified")
      ? undefined
      : new Date(json["modified"]),
    startDate: new Date(json["startDate"]),
    endDate: new Date(json["endDate"]),
    message: json["message"],
    messageInt: !exists(json, "messageInt") ? undefined : json["messageInt"],
  };
}

export function AnnouncementDtoToJSON(value?: AnnouncementDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    userId: value.userId,
    show: value.show,
    tenant: TenantEnumToJSON(value.tenant),
    created:
      value.created === undefined ? undefined : value.created.toISOString(),
    modified:
      value.modified === undefined ? undefined : value.modified.toISOString(),
    startDate: value.startDate.toISOString(),
    endDate: value.endDate.toISOString(),
    message: value.message,
    messageInt: value.messageInt,
  };
}
