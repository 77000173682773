/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  SpotIndexItem,
  SpotIndexItemFromJSON,
  SpotIndexItemFromJSONTyped,
  SpotIndexItemToJSON,
} from "./";

/**
 *
 * @export
 * @interface SpotIndexesResult
 */
export interface SpotIndexesResult {
  /**
   *
   * @type {Array<SpotIndexItem>}
   * @memberof SpotIndexesResult
   */
  data?: Array<SpotIndexItem> | null;
}

export function SpotIndexesResultFromJSON(json: any): SpotIndexesResult {
  return SpotIndexesResultFromJSONTyped(json, false);
}

export function SpotIndexesResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SpotIndexesResult {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data")
      ? undefined
      : json["data"] === null
        ? null
        : (json["data"] as Array<any>).map(SpotIndexItemFromJSON),
  };
}

export function SpotIndexesResultToJSON(value?: SpotIndexesResult | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data:
      value.data === undefined
        ? undefined
        : value.data === null
          ? null
          : (value.data as Array<any>).map(SpotIndexItemToJSON),
  };
}
