import { PlusOutlined } from "@ant-design/icons";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  Channel,
  ChannelNames,
  Modal,
  Row,
  Table,
  formatToEuro,
} from "@ster/ster-toolkit";
import { Col } from "antd";
import { ColumnsType } from "antd/lib/table";
import classNames from "classnames";
import moment from "moment";
import { memo, useCallback, useState } from "react";

import { BreakSelection } from "../../../../api";
import { getBlockPositionText, getSpotPrice } from "../../../../utils";
import styles from "./BlockList.module.less";
import { BlockListProps } from "./models";

const BlockList = memo(
  ({ breakSelection, preferredPositionSurcharge }: BlockListProps) => {
    const [blocksVisible, setBlocksVisible] = useState(false);
    const changeBlocksVisible = useCallback(() => {
      setBlocksVisible(!blocksVisible);
    }, [blocksVisible]);

    const { i18n } = useLingui();

    const columns: ColumnsType<BreakSelection> = [
      {
        title: i18n._(t`Blok`),
        width: 80,
        key: "breakId",
        dataIndex: ["_break", "breakId"],
      },
      {
        title: i18n._(t`Datum`),
        width: 150,
        key: "schedDate",
        dataIndex: "_break",
        render: ({ schedDate, schedStartTime }) => (
          <>
            {moment(schedDate).format("dd D MMM YYYY")}{" "}
            {moment(schedStartTime).format("LT")}
          </>
        ),
      },
      {
        title: i18n._(t`Zender`),
        width: 70,
        key: "channelDescr",
        dataIndex: "_break",
        render: ({ channelDescr }) => (
          <Channel type={channelDescr as ChannelNames} />
        ),
      },
      {
        title: i18n._(t`Voor`),
        width: 200,
        dataIndex: ["_break", "programmeBefore"],
        ellipsis: true,
      },
      {
        title: i18n._(t`Na`),
        width: 200,
        key: "programmeAfter",
        dataIndex: ["_break", "programmeAfter"],
        ellipsis: true,
      },
      {
        title: i18n._(t`Netto spotprijs`),
        key: "nettSpotPrice",
        align: "right",
        dataIndex: "_break",
        width: 120,
        render: (_, currentBreak) => (
          <>
            {formatToEuro(
              getSpotPrice(currentBreak, preferredPositionSurcharge ?? 0),
              false
            )}
          </>
        ),
      },
      {
        title: i18n._(t`Voorkeurspositie`),
        key: "preferredPosition",
        width: 140,
        align: "left",
        render: (_, { preferredPosition }) =>
          preferredPosition ? (
            <>
              {getBlockPositionText(preferredPosition)
                .map((id) => i18n._(id))
                .join(", ")}
            </>
          ) : (
            <>-</>
          ),
      },
    ];

    if (!(breakSelection && (breakSelection?.length ?? 0) > 0)) {
      return null;
    }

    return (
      <div>
        <Trans>Aantal blokken: {breakSelection.length}</Trans>
        <button
          className={classNames("link", styles.link)}
          type="button"
          onClick={changeBlocksVisible}
        >
          <PlusOutlined />
        </button>
        <Modal
          title={<Trans>Geselecteerde blokken</Trans>}
          footer={[
            <Button key="submit" mode="primary" onClick={changeBlocksVisible}>
              Ok
            </Button>,
          ]}
          onCancel={changeBlocksVisible}
          cancelText={i18n._(t`Annuleren`)}
          open={blocksVisible}
          className={styles.modal}
        >
          <Row>
            <Col span={24}>
              <Table
                columns={columns.filter((s) =>
                  s.key === "preferredPosition"
                    ? breakSelection.find((b) => b.preferredPosition) !==
                      undefined
                    : true
                )}
                dataSource={breakSelection}
                rowKey={({ key }: BreakSelection): string => key ?? ""}
                pagination={false}
                className={styles.blocks}
                scroll={{ x: "max-content", y: "max-content" }}
              />
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
);

export default BlockList;
