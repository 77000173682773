import { createSelector } from "reselect";

import { Document } from "../../api/models";
import {
  accountSelector,
  advertisersLoadingSelector,
  advertisersSelector,
  createStructuredAppSelector,
  organisationsLoadingSelector,
  organisationsSelector,
} from "../../shared/selectors";
import { LoadingDocuments, StoreModel } from "../../store/models";

const documentsFromStoreSelector = ({
  documents: documentsFromStore,
}: StoreModel): LoadingDocuments => documentsFromStore;

const documentsSelector = createSelector(
  [documentsFromStoreSelector],
  (documents: LoadingDocuments): Document[] => documents.documents ?? []
);

const loadingSelector = createSelector(
  [documentsFromStoreSelector, organisationsLoadingSelector],
  (documents: LoadingDocuments, organisationsLoading): boolean =>
    organisationsLoading || (documents.loading ?? false)
);

// eslint-disable-next-line import/prefer-default-export
export const documentsRootSelector = createStructuredAppSelector({
  account: accountSelector,
  documents: documentsSelector,
  organisations: organisationsSelector,
  loading: loadingSelector,
  advertisers: advertisersSelector,
  advertisersLoading: advertisersLoadingSelector,
});
