import { Container, Typography } from "@ster/ster-toolkit";
import { Carousel } from "antd";
import ReactMarkdown from "react-markdown";

import { TipDto } from "../../api";
import TipSlide from "./TipSlide";
import styles from "./TipSlide.module.less";

const TipSlider = ({ tips }: { tips?: TipDto[] }) =>
  tips && tips.length > 0 ? (
    <Container className={styles.sliderWrapper}>
      <Carousel
        className={styles.slider}
        autoplay
        autoplaySpeed={10000}
        adaptiveHeight
      >
        {tips
          .sort((a, b) => a.sortOrder - b.sortOrder)
          .map((tip) => (
            <TipSlide
              key={tip.alias}
              className={tip.cssClassName}
              image={tip.imageUrl ?? undefined}
            >
              <Typography.Title level={3}>{tip.title}</Typography.Title>
              <Typography.Paragraph>
                <ReactMarkdown
                  components={{
                    a: ({ href, children, ...props }) => (
                      <a
                        href={href}
                        target="_blank"
                        rel="noopener noreferrer"
                        {...props}
                      >
                        {children}
                      </a>
                    ),
                  }}
                >
                  {tip.description}
                </ReactMarkdown>
              </Typography.Paragraph>
            </TipSlide>
          ))}
      </Carousel>
    </Container>
  ) : null;

export default TipSlider;
