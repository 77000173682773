import { saveAs } from "file-saver";
import moment from "moment/moment";
import { AnyAction } from "redux";
import { Reducer, createReducer } from "typesafe-actions";

import { genericReducer } from "../../utils";
import { Loading, ReduxStoreState } from "../base";
import { LoadingSpotTrackerResult } from "../models";
import { exportSpotTrackerAction, receiveSpotTrackerAction } from "./actions";

export const receiveSpotTrackerReducer: Reducer<
  LoadingSpotTrackerResult,
  AnyAction
> = genericReducer(
  receiveSpotTrackerAction.request,
  receiveSpotTrackerAction.success,
  receiveSpotTrackerAction.failure,
  "spots"
);

export const exportSpotTrackerReducer: Reducer<Loading, AnyAction> =
  createReducer({
    state: ReduxStoreState.Initial,
  })
    .handleAction(exportSpotTrackerAction.request, () => ({
      loading: true,
      state: ReduxStoreState.Loading,
    }))
    .handleAction(exportSpotTrackerAction.failure, () => ({
      loading: false,
      state: ReduxStoreState.Failure,
    }))
    .handleAction(
      exportSpotTrackerAction.success,
      (
        _state: Loading,
        action: ReturnType<typeof exportSpotTrackerAction.success>
      ) => {
        const filename = `spotanalyse-${moment().format("YYYYMMDDhhmm")}.xlsx`;
        const fileContents = action.payload as Blob;
        saveAs(fileContents, filename);

        return {
          loading: false,
          state: ReduxStoreState.Success,
        };
      }
    );
