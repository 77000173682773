import { KeyValuePair } from "../../../utils/types";

// eslint-disable-next-line import/prefer-default-export
export const RadioShortChannelNames: KeyValuePair[] = [
  { key: "R1", value: "NPO Radio 1" },
  { key: "R2", value: "NPO Radio 2" },
  { key: "R3", value: "NPO 3FM" },
  { key: "R4", value: "NPO Klassiek" },
  { key: "R5", value: "NPO Radio 5" },
  { key: "FX", value: "FunX" },
  { key: "R7", value: "FunX" },
  { key: "RR", value: "Radio Regio" },
];
