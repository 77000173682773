import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

import { InvoiceApi } from "../../api/apis";
import apiConfig from "../../apiConfig";
import { toError } from "../utils";
import { receiveInvoicesAction } from "./actions";

// eslint-disable-next-line import/prefer-default-export
export function* receiveInvoices(
  action: ReturnType<typeof receiveInvoicesAction.request>
): SagaIterator {
  try {
    const invoiceApi = new InvoiceApi(apiConfig());
    const response = yield call(() =>
      invoiceApi.apiV1InvoiceGet(action.payload)
    );
    yield put(receiveInvoicesAction.success(response));
  } catch (err) {
    yield put(receiveInvoicesAction.failure(toError(err)));
  }
}
