import "../styles.less";
import "./campaigndetailcard.less";

import { Plural, Trans, t } from "@lingui/macro";
import moment from "moment";

import { AnalysisAvailability } from "../../../../api";

const CampaignAnalyisText = ({
  analysis,
  shortText,
}: {
  analysis: AnalysisAvailability;
  shortText?: boolean;
}) => {
  if (!analysis?.from) {
    return null;
  }

  const today = moment();
  const date = moment(analysis.from);
  const diffDays = date.startOf("day").diff(today.startOf("day"), "days");

  const prefix = shortText ? t`Campagneanalyse` : t`Campagneanalyse aanvragen`;

  return (
    <>
      {prefix}{" "}
      {diffDays > 0 && (
        <Trans>
          <Plural value={diffDays} one="(over # dag)" other="(over # dagen)" />
        </Trans>
      )}
    </>
  );
};

export default CampaignAnalyisText;
