import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Channel, ChannelNames, Table, formatNumber } from "@ster/ster-toolkit";
import { Space, TableColumnsType } from "antd";
import { format } from "date-fns";

import { RatingRegio } from "../../../api";
import styles from "../CampaignSchedule.module.less";

export interface CampaignRegioScheduleProps {
  ratingsRegio: RatingRegio[];
  expanded: boolean;
}

const CampaignRegioSchedule = ({
  ratingsRegio,
  expanded,
}: CampaignRegioScheduleProps) => {
  const { i18n } = useLingui();

  if (!expanded) {
    // Fix for issue with SVG icons not being rendered correctly
    return null;
  }

  const regioRolumns: TableColumnsType<RatingRegio> = [
    {
      title: i18n._(t`Datum`),
      key: "schedDate",
      width: 155,
      render: (_, { actualStartTime }) => (
        <>{format(actualStartTime, "EEEEEE d MMM yyyy")}</>
      ),
      sorter: (a, b): number =>
        a.actualStartTime.getTime() - b.actualStartTime.getTime(),
    },
    {
      title: i18n._(t`Tijd`),
      key: "actualStartTime",
      width: 95,
      render: (_, { actualStartTime }) => (
        <>{format(actualStartTime, "HH:mm")}</>
      ),
      sorter: (a, b): number =>
        a.actualStartTime.getTime() - b.actualStartTime.getTime(),
    },
    {
      title: i18n._(t`Zender`),
      key: "channelDescr",
      render: (_, { channelDescr }) => (
        <Space direction="horizontal">
          <Channel type={channelDescr as ChannelNames} />
          <span>{channelDescr}</span>
        </Space>
      ),
      sorter: (a, b): number => a.channelDescr!.localeCompare(b.channelDescr!),
    },
    {
      title: i18n._(t`Doelgroep`),
      dataIndex: "targetGroupId",
      key: "targetGroupId",
      width: 140,
      align: "right",
      sorter: (a, b): number =>
        a.targetGroupId!.localeCompare(b.targetGroupId!),
    },
    {
      title: i18n._(t`Realisatie`),
      dataIndex: "achievedRating",
      key: "achievedRating",
      width: 115,
      align: "right",
      render: (_, { achievedRating }) =>
        achievedRating !== undefined ? (
          <>{formatNumber(achievedRating, 6)}</>
        ) : (
          <>-</>
        ),
      sorter: (a, b): number =>
        (a.achievedRating ?? 0) - (b.achievedRating ?? 0),
    },
  ];

  return (
    <Table
      columns={regioRolumns}
      dataSource={ratingsRegio.sort(
        (a, b) => a.actualStartTime.getTime() - b.actualStartTime.getTime()
      )}
      pagination={false}
      rowKey={({ channelNr, actualStartTime }): string =>
        `${channelNr}:${actualStartTime.getTime()}`
      }
      className={styles.regioTable}
    />
  );
};

export default CampaignRegioSchedule;
