import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Typography, formatToEuro } from "@ster/ster-toolkit";
import { Select } from "antd";
import { SelectValue } from "antd/lib/select";
import classNames from "classnames";
import { useCallback } from "react";

import { OrderListItem } from "../../../../api";
import { CampaignAnalysisOrder } from "../../../../store/campaignDetail/models";
import { formatDatePeriod } from "../utils";
import styles from "./CampaignView.module.less";

export interface RelatedCampaingsProps {
  value?: number | null;
  self: CampaignAnalysisOrder;
  related: OrderListItem[];
  onChange: (orderId: number | undefined) => void;
}

const RelatedCampaings = ({
  value = null,
  self,
  related,
  onChange,
}: RelatedCampaingsProps) => {
  const { i18n } = useLingui();

  const handleChange = useCallback(
    (selectedValue: SelectValue | undefined) => {
      onChange(selectedValue as number | undefined);
    },
    [onChange]
  );

  return (
    <div style={{ marginBottom: 16 }}>
      <Typography.Title level={2}>
        <Trans>Gerelateerde campagne</Trans>
      </Typography.Title>

      <Typography.Paragraph>
        <Trans>
          Wil je een gerelateerde campagne meenemen in de analyse? Kies deze dan
          uit de onderstaande selectie.
        </Trans>
      </Typography.Paragraph>

      <Select
        style={{ width: "100%" }}
        onSelect={handleChange}
        value={value}
        defaultValue={null}
      >
        <Select.Option value={null}>
          <>{i18n._(t`Geen extra campagne meenemen in analyse`)}</>
        </Select.Option>
        {[...related, self]
          .sort((a, b) => b.startDate.getTime() - a.startDate.getTime())
          .map((order, index) => (
            <Select.Option
              value={order.id}
              key={order.id}
              disabled={self.id === order.id}
              style={{ padding: 0 }}
            >
              <CampaignView
                order={order}
                disabled={self.id === order.id}
                even={index % 2 === 0}
              />
            </Select.Option>
          ))}
      </Select>
    </div>
  );
};

const CampaignView = ({
  order,
  disabled = false,
  even = false,
}: {
  order: CampaignAnalysisOrder | OrderListItem;
  disabled?: boolean;
  even?: boolean;
}) => {
  const { i18n } = useLingui();
  return (
    <div
      className={classNames(styles.campaignView, "campaign-view", {
        disabled,
        even,
      })}
    >
      <div className={classNames(styles.campaignRow, styles.campaignMain)}>
        <div className={classNames(styles.campaignName)}>
          <strong>
            {order.name && order.name !== "" ? order.name : order.productDescr}
          </strong>
        </div>
        <div className={classNames(styles.campaignPeriod)}>
          {formatDatePeriod(order.startDate, order.endDate)}
          {disabled && <> ({i18n._(t`huidige campagne`)})</>}
        </div>
      </div>
      <div className={classNames(styles.campaignRow, styles.campaignSmall)}>
        <div
          className={classNames(styles.campaignStats, styles.campaignBudget)}
        >
          <strong>{i18n._(t`Budget:`)}</strong>
          <span>{formatToEuro(order.budget)}</span>
        </div>
        <div
          className={classNames(styles.campaignStats, styles.campaignBudget)}
        >
          <strong>{i18n._(t`Geboekt:`)}</strong>
          <span>{formatToEuro(order.bookedBudget ?? 0)}</span>
        </div>
      </div>
    </div>
  );
};

export default RelatedCampaings;
