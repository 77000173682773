/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface TermsAndConditionsModel
 */
export interface TermsAndConditionsModel {
  /**
   *
   * @type {Date}
   * @memberof TermsAndConditionsModel
   */
  dateOfTerms: Date;
  /**
   *
   * @type {boolean}
   * @memberof TermsAndConditionsModel
   */
  canUseForMailing?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TermsAndConditionsModel
   */
  canUseForMarketing?: boolean;
}

export function TermsAndConditionsModelFromJSON(
  json: any
): TermsAndConditionsModel {
  return TermsAndConditionsModelFromJSONTyped(json, false);
}

export function TermsAndConditionsModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TermsAndConditionsModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    dateOfTerms: new Date(json["dateOfTerms"]),
    canUseForMailing: !exists(json, "canUseForMailing")
      ? undefined
      : json["canUseForMailing"],
    canUseForMarketing: !exists(json, "canUseForMarketing")
      ? undefined
      : json["canUseForMarketing"],
  };
}

export function TermsAndConditionsModelToJSON(
  value?: TermsAndConditionsModel | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    dateOfTerms: value.dateOfTerms.toISOString(),
    canUseForMailing: value.canUseForMailing,
    canUseForMarketing: value.canUseForMarketing,
  };
}
