import { memo, useCallback, useMemo } from "react";

import { CommercialsPerAdvertiser, MediumEnum } from "../../../api";
import { groupBy } from "../../../utils";
import { CommercialPicker } from "../Pickers";
import InnerStep from "../Steps/InnerStep";
import { SpotLengthWithDates } from "./types";
import { SimpleSubOrder } from "./utils";

interface InstructionsForSpotlengthProps {
  spotLengthsWithDates: SpotLengthWithDates[];
  commercialsPerAdvertiser?: CommercialsPerAdvertiser;
  productId: number;
  medium: MediumEnum;
  subOrders: SimpleSubOrder[];
  onChange: (spotLengthWithDates: SpotLengthWithDates) => void;
  onDelete: (spotLengthWithDates: SpotLengthWithDates) => void;
}

const InstructionsForSpotlength = memo(
  ({
    spotLengthsWithDates,
    commercialsPerAdvertiser,
    productId,
    medium,
    onChange,
    onDelete,
    subOrders,
  }: InstructionsForSpotlengthProps) => {
    const spotLengthsWithDatesGrouped = useMemo(
      () =>
        Object.entries(
          groupBy(spotLengthsWithDates, (s) => s.spotLength.join("+"))
        ),
      [spotLengthsWithDates]
    );

    const packageNamesForSpotLength = useCallback(
      (spotlength: number[]) =>
        Array.from(
          new Set(
            subOrders
              .filter((s) => s.spotLength.join("+") === spotlength.join("+"))
              .map((s) => s.packageName)
          )
        ).join(", "),
      [subOrders]
    );

    return (
      <>
        {spotLengthsWithDatesGrouped.map(([key, value]) => (
          <InnerStep
            title={value[0].spotLength.map((s) => `${s}"`).join("+")}
            subTitle={packageNamesForSpotLength(value[0].spotLength)}
            key={key}
          >
            {value.map((s) => (
              <CommercialPicker
                key={s.id}
                commercialsPerAdvertiser={commercialsPerAdvertiser}
                spotLengthWithDates={s}
                onChange={onChange}
                productId={productId}
                medium={medium}
                enableAdd
                enableRemove
                onDelete={onDelete}
                canDelete={
                  value
                    .filter((sl) => sl.spotLength.join("+") === key)
                    .findIndex((sl) => sl.id === s.id) !== 0
                }
              />
            ))}
          </InnerStep>
        ))}
      </>
    );
  }
);

export default InstructionsForSpotlength;
