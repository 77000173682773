/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Forecast,
  ForecastFromJSON,
  ForecastFromJSONTyped,
  ForecastToJSON,
} from "./";

/**
 *
 * @export
 * @interface ForecastResult
 */
export interface ForecastResult {
  /**
   *
   * @type {Array<Forecast>}
   * @memberof ForecastResult
   */
  conversionForecasts?: Array<Forecast> | null;
  /**
   *
   * @type {Array<Forecast>}
   * @memberof ForecastResult
   */
  forecasts?: Array<Forecast> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ForecastResult
   */
  readonly unknownPackageCodes?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ForecastResult
   */
  unknownChannels?: Array<string> | null;
  /**
   *
   * @type {boolean}
   * @memberof ForecastResult
   */
  yourTimeRequirementsNotMet?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ForecastResult
   */
  top2000RequirementsNotMet?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ForecastResult
   */
  regioChannelForecastNotAvailable?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ForecastResult
   */
  regioPackageForecastNotAvailable?: boolean;
}

export function ForecastResultFromJSON(json: any): ForecastResult {
  return ForecastResultFromJSONTyped(json, false);
}

export function ForecastResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ForecastResult {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    conversionForecasts: !exists(json, "conversionForecasts")
      ? undefined
      : json["conversionForecasts"] === null
        ? null
        : (json["conversionForecasts"] as Array<any>).map(ForecastFromJSON),
    forecasts: !exists(json, "forecasts")
      ? undefined
      : json["forecasts"] === null
        ? null
        : (json["forecasts"] as Array<any>).map(ForecastFromJSON),
    unknownPackageCodes: !exists(json, "unknownPackageCodes")
      ? undefined
      : json["unknownPackageCodes"],
    unknownChannels: !exists(json, "unknownChannels")
      ? undefined
      : json["unknownChannels"],
    yourTimeRequirementsNotMet: !exists(json, "yourTimeRequirementsNotMet")
      ? undefined
      : json["yourTimeRequirementsNotMet"],
    top2000RequirementsNotMet: !exists(json, "top2000RequirementsNotMet")
      ? undefined
      : json["top2000RequirementsNotMet"],
    regioChannelForecastNotAvailable: !exists(
      json,
      "regioChannelForecastNotAvailable"
    )
      ? undefined
      : json["regioChannelForecastNotAvailable"],
    regioPackageForecastNotAvailable: !exists(
      json,
      "regioPackageForecastNotAvailable"
    )
      ? undefined
      : json["regioPackageForecastNotAvailable"],
  };
}

export function ForecastResultToJSON(value?: ForecastResult | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    conversionForecasts:
      value.conversionForecasts === undefined
        ? undefined
        : value.conversionForecasts === null
          ? null
          : (value.conversionForecasts as Array<any>).map(ForecastToJSON),
    forecasts:
      value.forecasts === undefined
        ? undefined
        : value.forecasts === null
          ? null
          : (value.forecasts as Array<any>).map(ForecastToJSON),
    unknownChannels: value.unknownChannels,
    yourTimeRequirementsNotMet: value.yourTimeRequirementsNotMet,
    top2000RequirementsNotMet: value.top2000RequirementsNotMet,
    regioChannelForecastNotAvailable: value.regioChannelForecastNotAvailable,
    regioPackageForecastNotAvailable: value.regioPackageForecastNotAvailable,
  };
}
