import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Typography } from "@ster/ster-toolkit";
import { memo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { logoutMeAction } from "../../store/account/actions";
import { StoreModel } from "../../store/models";
import SimplePage from "../SimplePage";
import { loginWithRedirect } from "./constants";

const Logout = memo(() => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const { email } = useSelector(({ account }: StoreModel) => account);
  const gotoLogin = useCallback(() => {
    window.location.href = loginWithRedirect();
  }, []);

  useEffect(() => {
    if (email) {
      dispatch(logoutMeAction.request());
    }
  }, [dispatch, email]);

  return (
    <SimplePage title={i18n._(t`Uitgelogd`)}>
      <Typography.Paragraph>
        <Trans>Je bent uitgelogd.</Trans>
      </Typography.Paragraph>

      <Button onClick={gotoLogin} mode="primary">
        <Trans>Opnieuw inloggen</Trans>
      </Button>
    </SimplePage>
  );
});

export default Logout;
