/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  BookSpotAction,
  BookSpotActionFromJSON,
  BookSpotActionFromJSONTyped,
  BookSpotActionToJSON,
} from "./";

/**
 *
 * @export
 * @interface BookSpotItem
 */
export interface BookSpotItem {
  /**
   *
   * @type {BookSpotAction}
   * @memberof BookSpotItem
   */
  action: BookSpotAction;
  /**
   *
   * @type {Date}
   * @memberof BookSpotItem
   */
  schedDate: Date;
  /**
   *
   * @type {string}
   * @memberof BookSpotItem
   */
  breakId: string;
  /**
   *
   * @type {number}
   * @memberof BookSpotItem
   */
  spotId?: number;
  /**
   *
   * @type {string}
   * @memberof BookSpotItem
   */
  preferredPositionString?: string | null;
}

export function BookSpotItemFromJSON(json: any): BookSpotItem {
  return BookSpotItemFromJSONTyped(json, false);
}

export function BookSpotItemFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BookSpotItem {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    action: BookSpotActionFromJSON(json["action"]),
    schedDate: new Date(json["schedDate"]),
    breakId: json["breakId"],
    spotId: !exists(json, "spotId") ? undefined : json["spotId"],
    preferredPositionString: !exists(json, "preferredPositionString")
      ? undefined
      : json["preferredPositionString"],
  };
}

export function BookSpotItemToJSON(value?: BookSpotItem | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    action: BookSpotActionToJSON(value.action),
    schedDate: value.schedDate.toISOString(),
    breakId: value.breakId,
    spotId: value.spotId,
    preferredPositionString: value.preferredPositionString,
  };
}
