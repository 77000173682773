/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  CommercialInstructionSpots,
  CommercialInstructionSpotsFromJSON,
  CommercialInstructionSpotsFromJSONTyped,
  CommercialInstructionSpotsToJSON,
  DateSpan,
  DateSpanFromJSON,
  DateSpanFromJSONTyped,
  DateSpanToJSON,
} from "./";

/**
 *
 * @export
 * @interface CommercialInstructionCommercialsPerPeriod
 */
export interface CommercialInstructionCommercialsPerPeriod {
  /**
   *
   * @type {number}
   * @memberof CommercialInstructionCommercialsPerPeriod
   */
  id: number;
  /**
   *
   * @type {Array<number>}
   * @memberof CommercialInstructionCommercialsPerPeriod
   */
  spotLength: Array<number>;
  /**
   *
   * @type {Array<DateSpan>}
   * @memberof CommercialInstructionCommercialsPerPeriod
   */
  dateRanges: Array<DateSpan>;
  /**
   *
   * @type {Array<Date>}
   * @memberof CommercialInstructionCommercialsPerPeriod
   */
  excluded: Array<Date>;
  /**
   *
   * @type {Array<CommercialInstructionSpots>}
   * @memberof CommercialInstructionCommercialsPerPeriod
   */
  commercialSelection: Array<CommercialInstructionSpots>;
}

export function CommercialInstructionCommercialsPerPeriodFromJSON(
  json: any
): CommercialInstructionCommercialsPerPeriod {
  return CommercialInstructionCommercialsPerPeriodFromJSONTyped(json, false);
}

export function CommercialInstructionCommercialsPerPeriodFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CommercialInstructionCommercialsPerPeriod {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    spotLength: json["spotLength"],
    dateRanges: (json["dateRanges"] as Array<any>).map(DateSpanFromJSON),
    excluded: json["excluded"],
    commercialSelection: (json["commercialSelection"] as Array<any>).map(
      CommercialInstructionSpotsFromJSON
    ),
  };
}

export function CommercialInstructionCommercialsPerPeriodToJSON(
  value?: CommercialInstructionCommercialsPerPeriod | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    spotLength: value.spotLength,
    dateRanges: (value.dateRanges as Array<any>).map(DateSpanToJSON),
    excluded: value.excluded,
    commercialSelection: (value.commercialSelection as Array<any>).map(
      CommercialInstructionSpotsToJSON
    ),
  };
}
