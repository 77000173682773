import {
  BreakSelection,
  Commercial,
  CommercialInstructionSpots,
  PackageRequest,
  Spot,
} from "../../../api";
import { Period } from "../../../shared/models";
import { DateRange } from "../../../utils/types";

export interface SimpleSubOrder {
  id: number;
  spotLength: number[];
  dateRange: DateRange;
  packageName: string;
  channels: string[];
  breakSelection: BreakSelection[];
  spotSelection: Spot[];
}

const emptySimpleSubOrder = new Array<SimpleSubOrder>();

export const createSimpleSubOrder = (
  subOrders?: Array<{
    id?: number | null;
    period: Period;
    spotLength?: number[] | null;
    spotSelection?: Array<Spot> | null;
    breakSelection?: Array<BreakSelection> | null;
    _package: PackageRequest;
    channels?: Array<string> | null;
  }> | null
) =>
  subOrders
    ?.filter(
      ({ id, period, spotLength, _package }) =>
        id && period && spotLength && _package
    )
    .map(
      ({
        id,
        period,
        spotLength,
        _package,
        channels,
        spotSelection,
        breakSelection,
      }) =>
        ({
          id,
          dateRange: {
            startDate: period.from,
            endDate: period.to,
          },
          spotLength,
          spotSelection: spotSelection ?? [],
          breakSelection: breakSelection ?? [],
          packageName: _package.name,
          channels: channels ?? [],
        }) as SimpleSubOrder
    ) ?? emptySimpleSubOrder;

export const getRotationFrequencyPercentage = (
  frequency: number | null | undefined,
  commercialSelection: CommercialInstructionSpots[]
) => {
  if (!frequency) {
    return null;
  }

  // Bepaal het totaal van alle rotatie frequenties om het relatieve percentage te berekenen
  const percentage =
    100 /
    (commercialSelection.reduce((a, b) => a + (b.rotationFrequency ?? 0), 0) /
      frequency);
  return Math.round(percentage);
};

export const emptyCommercialSelection = (
  spotLength: number[]
): CommercialInstructionSpots[] => [
  {
    id: 0,
    commercial: undefined,
    tagOns: spotLength.slice(1).map((_) => ({}) as Commercial),
  },
];
