import "./innerstep.less";

import { Typography } from "@ster/ster-toolkit";
import { PropsWithChildren } from "react";

interface InnerStepProps {
  title: string;
  subTitle?: string;
}

const InnerStep = ({
  children,
  title,
  subTitle,
}: PropsWithChildren<InnerStepProps>) => (
  <section className="innerstep">
    <Typography.Title level={3} className="innerstep__title">
      {title}
    </Typography.Title>
    {subTitle && <div className="innerstep__subtitle">{subTitle}</div>}
    <div className="innerstep__content">{children}</div>
  </section>
);

export default InnerStep;
