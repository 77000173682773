/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CampaignConfigurationInput
 */
export interface CampaignConfigurationInput {
  /**
   *
   * @type {string}
   * @memberof CampaignConfigurationInput
   */
  campaignName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CampaignConfigurationInput
   */
  comments?: string | null;
  /**
   *
   * @type {string}
   * @memberof CampaignConfigurationInput
   */
  invoice?: string | null;
  /**
   *
   * @type {string}
   * @memberof CampaignConfigurationInput
   */
  crmAccount?: string | null;
  /**
   *
   * @type {string}
   * @memberof CampaignConfigurationInput
   */
  crmOpportunity?: string | null;
  /**
   *
   * @type {string}
   * @memberof CampaignConfigurationInput
   */
  crmContact?: string | null;
  /**
   *
   * @type {string}
   * @memberof CampaignConfigurationInput
   */
  contact?: string | null;
  /**
   *
   * @type {string}
   * @memberof CampaignConfigurationInput
   */
  contactEmailExtra?: string | null;
  /**
   *
   * @type {number}
   * @memberof CampaignConfigurationInput
   */
  productId: number;
  /**
   *
   * @type {string}
   * @memberof CampaignConfigurationInput
   */
  campaignCode: string;
  /**
   *
   * @type {number}
   * @memberof CampaignConfigurationInput
   */
  advertiserId: number;
}

export function CampaignConfigurationInputFromJSON(
  json: any
): CampaignConfigurationInput {
  return CampaignConfigurationInputFromJSONTyped(json, false);
}

export function CampaignConfigurationInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CampaignConfigurationInput {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    campaignName: !exists(json, "campaignName")
      ? undefined
      : json["campaignName"],
    comments: !exists(json, "comments") ? undefined : json["comments"],
    invoice: !exists(json, "invoice") ? undefined : json["invoice"],
    crmAccount: !exists(json, "crmAccount") ? undefined : json["crmAccount"],
    crmOpportunity: !exists(json, "crmOpportunity")
      ? undefined
      : json["crmOpportunity"],
    crmContact: !exists(json, "crmContact") ? undefined : json["crmContact"],
    contact: !exists(json, "contact") ? undefined : json["contact"],
    contactEmailExtra: !exists(json, "contactEmailExtra")
      ? undefined
      : json["contactEmailExtra"],
    productId: json["productId"],
    campaignCode: json["campaignCode"],
    advertiserId: json["advertiserId"],
  };
}

export function CampaignConfigurationInputToJSON(
  value?: CampaignConfigurationInput | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    campaignName: value.campaignName,
    comments: value.comments,
    invoice: value.invoice,
    crmAccount: value.crmAccount,
    crmOpportunity: value.crmOpportunity,
    crmContact: value.crmContact,
    contact: value.contact,
    contactEmailExtra: value.contactEmailExtra,
    productId: value.productId,
    campaignCode: value.campaignCode,
    advertiserId: value.advertiserId,
  };
}
