import { createSelector } from "reselect";

import { PortalUser } from "../../api/models";
import {
  advertisersLoadingSelector,
  advertisersSelector,
  createStructuredAppSelector,
  organisationsLoadingSelector,
  organisationsSelector,
  settingsSelector,
} from "../../shared/selectors";
import { ReduxStoreState } from "../../store/base";
import { StoreModel } from "../../store/models";

const emptyUsers: PortalUser[] = [];

const usersSelector = ({ users: usersFromStore }: StoreModel): PortalUser[] =>
  usersFromStore.users || emptyUsers;

const loadingSelector = createSelector(
  [
    ({ users: usersFromStore }: StoreModel): boolean => usersFromStore.loading,
    organisationsLoadingSelector,
    advertisersLoadingSelector,
  ],
  (usersLoading, organisationsLoading, advertisersLoading) =>
    usersLoading || organisationsLoading || advertisersLoading
);

const downloadingSelector = ({ usersExport: { state } }: StoreModel): boolean =>
  state === ReduxStoreState.Loading;

// eslint-disable-next-line import/prefer-default-export
export const usersRootSelector = createStructuredAppSelector({
  users: usersSelector,
  organisations: organisationsSelector,
  advertisers: advertisersSelector,
  settings: settingsSelector,
  loading: loadingSelector,
  downloading: downloadingSelector,
});
