import { SagaIterator } from "redux-saga";
import { SagaReturnType, call, put } from "redux-saga/effects";

import { AccountApi } from "../../api/apis";
import apiConfig from "../../apiConfig";
import { toError } from "../utils";
import { receiveOrganisationsAction } from "./actions";

// eslint-disable-next-line import/prefer-default-export
export function* receiveOrganisations(): SagaIterator {
  try {
    const accountApi = new AccountApi(apiConfig());
    const response: SagaReturnType<
      typeof accountApi.apiV1AccountOrganisationsGet
    > = yield call(() => accountApi.apiV1AccountOrganisationsGet());
    yield put(receiveOrganisationsAction.success(response.data));
  } catch (err) {
    yield put(receiveOrganisationsAction.failure(toError(err)));
  }
}
