import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

import { TermsAndConditionsApi } from "../../api/apis";
import apiConfig from "../../apiConfig";
import { toError } from "../utils";
import { postTermsAction, receiveTermsAction } from "./actions";

export function* receiveTerms(): SagaIterator {
  try {
    const termsApi = new TermsAndConditionsApi(apiConfig());
    const response = yield call(() => termsApi.apiV1TermsGet());
    yield put(receiveTermsAction.success(response));
  } catch (err) {
    yield put(receiveTermsAction.failure(toError(err)));
  }
}

export function* postTerms(
  action: ReturnType<typeof postTermsAction.request>
): SagaIterator {
  try {
    const termsApi = new TermsAndConditionsApi(apiConfig());
    const response = yield call(() => termsApi.apiV1TermsPost(action.payload));
    yield put(postTermsAction.success(response));
  } catch (err) {
    yield put(postTermsAction.failure(toError(err)));
  }
}
