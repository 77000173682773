import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

import { BookSpotApi, RequestApi } from "../../api/apis";
import apiConfig from "../../apiConfig";
import { toError } from "../utils";
import { bookSpotAction, receiveBreaksAction } from "./actions";

export function* receiveBreaks(
  action: ReturnType<typeof receiveBreaksAction.request>
): SagaIterator {
  try {
    const requestApi = new RequestApi(apiConfig());
    const response = yield call(() =>
      requestApi.apiV1RequestGetavailablespotsPost({
        availableBreaksRequest: action.payload,
      })
    );
    yield put(
      receiveBreaksAction.success({ breaks: response, key: action.payload.key })
    );
  } catch (err) {
    yield put(
      receiveBreaksAction.failure({ key: action.payload.key, ...toError(err) })
    );
  }
}

export function* bookSpot(
  action: ReturnType<typeof bookSpotAction.request>
): SagaIterator {
  try {
    const bookSpotApi = new BookSpotApi(apiConfig());
    const response = yield call(() =>
      bookSpotApi.apiV1BookSpotPost(action.payload)
    );
    yield put(bookSpotAction.success(response));
  } catch (err) {
    yield put(bookSpotAction.failure(toError(err)));
  }
}
