/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum AuditActionType {
  RequestCreate = "RequestCreate",
  RequestUpdate = "RequestUpdate",
  RequestDelete = "RequestDelete",
  RequestFinalize = "RequestFinalize",
  OrderUpdate = "OrderUpdate",
}

export function AuditActionTypeFromJSON(json: any): AuditActionType {
  return AuditActionTypeFromJSONTyped(json, false);
}

export function AuditActionTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AuditActionType {
  return json as AuditActionType;
}

export function AuditActionTypeToJSON(value?: AuditActionType | null): any {
  return value as any;
}
