/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  MediumEnum,
  MediumEnumFromJSON,
  MediumEnumToJSON,
  SpotIndexesResult,
  SpotIndexesResultFromJSON,
  SpotIndexesResultToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface ApiV1RatecardSpotindexesGetRequest {
  medium?: MediumEnum;
  year?: number;
}

/**
 *
 */
export class RatecardApi extends runtime.BaseAPI {
  /**
   */
  async apiV1RatecardSpotindexesGetRaw(
    requestParameters: ApiV1RatecardSpotindexesGetRequest
  ): Promise<runtime.ApiResponse<SpotIndexesResult>> {
    const queryParameters: any = {};

    if (requestParameters.medium !== undefined) {
      queryParameters["medium"] = requestParameters.medium;
    }

    if (requestParameters.year !== undefined) {
      queryParameters["year"] = requestParameters.year;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Ratecard/spotindexes`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SpotIndexesResultFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1RatecardSpotindexesGet(
    requestParameters: ApiV1RatecardSpotindexesGetRequest
  ): Promise<SpotIndexesResult> {
    const response =
      await this.apiV1RatecardSpotindexesGetRaw(requestParameters);
    return await response.value();
  }
}
