/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum PackageChoiceEnum {
  FixedCosts = "FixedCosts",
  Grps = "Grps",
  Display = "Display",
  Video = "Video",
  NpoPromo = "NpoPromo",
  FixedCostsCult = "FixedCostsCult",
  None = "None",
}

export function PackageChoiceEnumFromJSON(json: any): PackageChoiceEnum {
  return PackageChoiceEnumFromJSONTyped(json, false);
}

export function PackageChoiceEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PackageChoiceEnum {
  return json as PackageChoiceEnum;
}

export function PackageChoiceEnumToJSON(value?: PackageChoiceEnum | null): any {
  return value as any;
}
