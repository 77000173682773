import { createAction, createAsyncAction } from "typesafe-actions";

import { ApiV1CommercialsUploadPostRequest } from "../../api";
import {
  CommercialUploadRegistration,
  CommercialUploadRegistrationReactivate,
  CommercialUploadRegistrationReview,
  CommercialsPerAdvertiser,
  NpoPlayerResult,
} from "../../api/models";
import { ResponseError } from "../base";
import { actionNameAsync } from "../utils";
import {
  CommercialRequestPayload,
  CommercialsForAuditRequestPayload,
  CommercialsForProductIdRequestPayload,
  NpoPlayerPayload,
} from "./models";

export const initNpoPlayerAction = createAsyncAction(
  actionNameAsync("npoPlayer", "REQUEST"),
  actionNameAsync("npoPlayer", "SUCCESS"),
  actionNameAsync("npoPlayer", "FAILURE")
)<NpoPlayerPayload, NpoPlayerResult, ResponseError>();

export const receiveCommercialsAction = createAsyncAction(
  actionNameAsync("commercials", "REQUEST"),
  actionNameAsync("commercials", "SUCCESS"),
  actionNameAsync("commercials", "FAILURE")
)<CommercialRequestPayload, CommercialsPerAdvertiser[], ResponseError>();

export const receiveCommercialsForProductIdAction = createAsyncAction(
  actionNameAsync("commercialsForProductId", "REQUEST"),
  actionNameAsync("commercialsForProductId", "SUCCESS"),
  actionNameAsync("commercialsForProductId", "FAILURE")
)<
  CommercialsForProductIdRequestPayload,
  CommercialsPerAdvertiser,
  ResponseError
>();

export const receiveCommercialsForAuditAction = createAsyncAction(
  actionNameAsync("commercialsForAudit", "REQUEST"),
  actionNameAsync("commercialsForAudit", "SUCCESS"),
  actionNameAsync("commercialsForAudit", "FAILURE")
)<
  CommercialsForAuditRequestPayload,
  CommercialUploadRegistration[],
  ResponseError
>();

export const updateCommercialRegistrationStatusAction = createAsyncAction(
  actionNameAsync("updateCommercialRegistrationStatus", "REQUEST"),
  actionNameAsync("updateCommercialRegistrationStatus", "SUCCESS"),
  actionNameAsync("updateCommercialRegistrationStatus", "FAILURE")
)<
  { data: CommercialUploadRegistrationReview; showAll?: boolean },
  undefined,
  ResponseError
>();

export const reactivateCommercialAction = createAsyncAction(
  actionNameAsync("reactivateCommercial", "REQUEST"),
  actionNameAsync("reactivateCommercial", "SUCCESS"),
  actionNameAsync("reactivateCommercial", "FAILURE")
)<CommercialUploadRegistrationReactivate, undefined, ResponseError>();

export const clearReactivateCommercialAction = createAction(
  "REACTIVATECOMMERCIAL_CLEAR"
)();

export const uploadCommercialAction = createAsyncAction(
  actionNameAsync("uploadCommercial", "REQUEST"),
  actionNameAsync("uploadCommercial", "SUCCESS"),
  actionNameAsync("uploadCommercial", "FAILURE")
)<ApiV1CommercialsUploadPostRequest, undefined, ResponseError>();

export const uploadCommercialClearAction = createAction(
  "UPLOADCOMMERCIAL_CLEAR"
)();
