/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  Advertiser,
  AdvertiserFromJSON,
  AdvertiserToJSON,
  CampaignForwardInput,
  CampaignForwardInputFromJSON,
  CampaignForwardInputToJSON,
  CampaignForwarded,
  CampaignForwardedFromJSON,
  CampaignForwardedToJSON,
  CampaignImportOutput,
  CampaignImportOutputFromJSON,
  CampaignImportOutputToJSON,
  MediumEnum,
  MediumEnumFromJSON,
  MediumEnumToJSON,
  ProblemDetails,
  ProblemDetailsFromJSON,
  ProblemDetailsToJSON,
  SimplePortalUser,
  SimplePortalUserFromJSON,
  SimplePortalUserToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface ApiV1CampaignForwardAcceptPostRequest {
  id?: number;
}

export interface ApiV1CampaignForwardContactsGetRequest {
  organisationCode: string;
}

export interface ApiV1CampaignForwardDetailsIdGetRequest {
  id: number;
}

export interface ApiV1CampaignForwardInitialRequestIdPostRequest {
  campaignForwardInput: CampaignForwardInput;
  medium: MediumEnum;
  initialRequestId: number;
  from: Date;
  to: Date;
}

export interface ApiV1CampaignForwardProductsMediumGetRequest {
  medium: MediumEnum;
  organisationCode: string;
  dateFrom: Date;
  dateTo: Date;
}

export interface ApiV1CampaignForwardRejectPostRequest {
  id?: number;
}

/**
 *
 */
export class CampaignForwardApi extends runtime.BaseAPI {
  /**
   */
  async apiV1CampaignForwardAcceptPostRaw(
    requestParameters: ApiV1CampaignForwardAcceptPostRequest
  ): Promise<runtime.ApiResponse<CampaignImportOutput>> {
    const queryParameters: any = {};

    if (requestParameters.id !== undefined) {
      queryParameters["id"] = requestParameters.id;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/CampaignForward/accept`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CampaignImportOutputFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1CampaignForwardAcceptPost(
    requestParameters: ApiV1CampaignForwardAcceptPostRequest
  ): Promise<CampaignImportOutput> {
    const response =
      await this.apiV1CampaignForwardAcceptPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1CampaignForwardAvailableGetRaw(): Promise<
    runtime.ApiResponse<Array<CampaignForwarded>>
  > {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/CampaignForward/available`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(CampaignForwardedFromJSON)
    );
  }

  /**
   */
  async apiV1CampaignForwardAvailableGet(): Promise<Array<CampaignForwarded>> {
    const response = await this.apiV1CampaignForwardAvailableGetRaw();
    return await response.value();
  }

  /**
   */
  async apiV1CampaignForwardContactsGetRaw(
    requestParameters: ApiV1CampaignForwardContactsGetRequest
  ): Promise<runtime.ApiResponse<Array<SimplePortalUser>>> {
    if (
      requestParameters.organisationCode === null ||
      requestParameters.organisationCode === undefined
    ) {
      throw new runtime.RequiredError(
        "organisationCode",
        "Required parameter requestParameters.organisationCode was null or undefined when calling apiV1CampaignForwardContactsGet."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.organisationCode !== undefined) {
      queryParameters["organisationCode"] = requestParameters.organisationCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/CampaignForward/contacts`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(SimplePortalUserFromJSON)
    );
  }

  /**
   */
  async apiV1CampaignForwardContactsGet(
    requestParameters: ApiV1CampaignForwardContactsGetRequest
  ): Promise<Array<SimplePortalUser>> {
    const response =
      await this.apiV1CampaignForwardContactsGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1CampaignForwardDetailsIdGetRaw(
    requestParameters: ApiV1CampaignForwardDetailsIdGetRequest
  ): Promise<runtime.ApiResponse<CampaignForwarded>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1CampaignForwardDetailsIdGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/CampaignForward/details/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CampaignForwardedFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1CampaignForwardDetailsIdGet(
    requestParameters: ApiV1CampaignForwardDetailsIdGetRequest
  ): Promise<CampaignForwarded> {
    const response =
      await this.apiV1CampaignForwardDetailsIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1CampaignForwardInitialRequestIdPostRaw(
    requestParameters: ApiV1CampaignForwardInitialRequestIdPostRequest
  ): Promise<runtime.ApiResponse<CampaignForwarded>> {
    if (
      requestParameters.campaignForwardInput === null ||
      requestParameters.campaignForwardInput === undefined
    ) {
      throw new runtime.RequiredError(
        "campaignForwardInput",
        "Required parameter requestParameters.campaignForwardInput was null or undefined when calling apiV1CampaignForwardInitialRequestIdPost."
      );
    }

    if (
      requestParameters.medium === null ||
      requestParameters.medium === undefined
    ) {
      throw new runtime.RequiredError(
        "medium",
        "Required parameter requestParameters.medium was null or undefined when calling apiV1CampaignForwardInitialRequestIdPost."
      );
    }

    if (
      requestParameters.initialRequestId === null ||
      requestParameters.initialRequestId === undefined
    ) {
      throw new runtime.RequiredError(
        "initialRequestId",
        "Required parameter requestParameters.initialRequestId was null or undefined when calling apiV1CampaignForwardInitialRequestIdPost."
      );
    }

    if (
      requestParameters.from === null ||
      requestParameters.from === undefined
    ) {
      throw new runtime.RequiredError(
        "from",
        "Required parameter requestParameters.from was null or undefined when calling apiV1CampaignForwardInitialRequestIdPost."
      );
    }

    if (requestParameters.to === null || requestParameters.to === undefined) {
      throw new runtime.RequiredError(
        "to",
        "Required parameter requestParameters.to was null or undefined when calling apiV1CampaignForwardInitialRequestIdPost."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.medium !== undefined) {
      queryParameters["medium"] = requestParameters.medium;
    }

    if (requestParameters.from !== undefined) {
      queryParameters["from"] = (requestParameters.from as any).toISOString();
    }

    if (requestParameters.to !== undefined) {
      queryParameters["to"] = (requestParameters.to as any).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/CampaignForward/{initialRequestId}`.replace(
        `{${"initialRequestId"}}`,
        encodeURIComponent(String(requestParameters.initialRequestId))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: CampaignForwardInputToJSON(requestParameters.campaignForwardInput),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CampaignForwardedFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1CampaignForwardInitialRequestIdPost(
    requestParameters: ApiV1CampaignForwardInitialRequestIdPostRequest
  ): Promise<CampaignForwarded> {
    const response =
      await this.apiV1CampaignForwardInitialRequestIdPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1CampaignForwardProductsMediumGetRaw(
    requestParameters: ApiV1CampaignForwardProductsMediumGetRequest
  ): Promise<runtime.ApiResponse<Array<Advertiser>>> {
    if (
      requestParameters.medium === null ||
      requestParameters.medium === undefined
    ) {
      throw new runtime.RequiredError(
        "medium",
        "Required parameter requestParameters.medium was null or undefined when calling apiV1CampaignForwardProductsMediumGet."
      );
    }

    if (
      requestParameters.organisationCode === null ||
      requestParameters.organisationCode === undefined
    ) {
      throw new runtime.RequiredError(
        "organisationCode",
        "Required parameter requestParameters.organisationCode was null or undefined when calling apiV1CampaignForwardProductsMediumGet."
      );
    }

    if (
      requestParameters.dateFrom === null ||
      requestParameters.dateFrom === undefined
    ) {
      throw new runtime.RequiredError(
        "dateFrom",
        "Required parameter requestParameters.dateFrom was null or undefined when calling apiV1CampaignForwardProductsMediumGet."
      );
    }

    if (
      requestParameters.dateTo === null ||
      requestParameters.dateTo === undefined
    ) {
      throw new runtime.RequiredError(
        "dateTo",
        "Required parameter requestParameters.dateTo was null or undefined when calling apiV1CampaignForwardProductsMediumGet."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.organisationCode !== undefined) {
      queryParameters["organisationCode"] = requestParameters.organisationCode;
    }

    if (requestParameters.dateFrom !== undefined) {
      queryParameters["dateFrom"] = (
        requestParameters.dateFrom as any
      ).toISOString();
    }

    if (requestParameters.dateTo !== undefined) {
      queryParameters["dateTo"] = (
        requestParameters.dateTo as any
      ).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/CampaignForward/products/{medium}`.replace(
        `{${"medium"}}`,
        encodeURIComponent(String(requestParameters.medium))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(AdvertiserFromJSON)
    );
  }

  /**
   */
  async apiV1CampaignForwardProductsMediumGet(
    requestParameters: ApiV1CampaignForwardProductsMediumGetRequest
  ): Promise<Array<Advertiser>> {
    const response =
      await this.apiV1CampaignForwardProductsMediumGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1CampaignForwardRejectPostRaw(
    requestParameters: ApiV1CampaignForwardRejectPostRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    if (requestParameters.id !== undefined) {
      queryParameters["id"] = requestParameters.id;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/CampaignForward/reject`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async apiV1CampaignForwardRejectPost(
    requestParameters: ApiV1CampaignForwardRejectPostRequest
  ): Promise<void> {
    await this.apiV1CampaignForwardRejectPostRaw(requestParameters);
  }
}
