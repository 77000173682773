/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  CampaignImportMessage,
  CampaignImportMessageFromJSON,
  CampaignImportMessageFromJSONTyped,
  CampaignImportMessageToJSON,
  DateSpan,
  DateSpanFromJSON,
  DateSpanFromJSONTyped,
  DateSpanToJSON,
  MediumEnum,
  MediumEnumFromJSON,
  MediumEnumFromJSONTyped,
  MediumEnumToJSON,
} from "./";

/**
 *
 * @export
 * @interface CampaignImportOutput
 */
export interface CampaignImportOutput {
  /**
   *
   * @type {MediumEnum}
   * @memberof CampaignImportOutput
   */
  mediumEnum?: MediumEnum;
  /**
   *
   * @type {number}
   * @memberof CampaignImportOutput
   */
  initialRequestId?: number;
  /**
   *
   * @type {DateSpan}
   * @memberof CampaignImportOutput
   */
  orderPeriod?: DateSpan;
  /**
   *
   * @type {Array<CampaignImportMessage>}
   * @memberof CampaignImportOutput
   */
  messages?: Array<CampaignImportMessage> | null;
}

export function CampaignImportOutputFromJSON(json: any): CampaignImportOutput {
  return CampaignImportOutputFromJSONTyped(json, false);
}

export function CampaignImportOutputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CampaignImportOutput {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    mediumEnum: !exists(json, "mediumEnum")
      ? undefined
      : MediumEnumFromJSON(json["mediumEnum"]),
    initialRequestId: !exists(json, "initialRequestId")
      ? undefined
      : json["initialRequestId"],
    orderPeriod: !exists(json, "orderPeriod")
      ? undefined
      : DateSpanFromJSON(json["orderPeriod"]),
    messages: !exists(json, "messages")
      ? undefined
      : json["messages"] === null
        ? null
        : (json["messages"] as Array<any>).map(CampaignImportMessageFromJSON),
  };
}

export function CampaignImportOutputToJSON(
  value?: CampaignImportOutput | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    mediumEnum: MediumEnumToJSON(value.mediumEnum),
    initialRequestId: value.initialRequestId,
    orderPeriod: DateSpanToJSON(value.orderPeriod),
    messages:
      value.messages === undefined
        ? undefined
        : value.messages === null
          ? null
          : (value.messages as Array<any>).map(CampaignImportMessageToJSON),
  };
}
