/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  AuditActionType,
  AuditActionTypeFromJSON,
  AuditActionTypeFromJSONTyped,
  AuditActionTypeToJSON,
  MediumEnum,
  MediumEnumFromJSON,
  MediumEnumFromJSONTyped,
  MediumEnumToJSON,
  SimplePortalUser,
  SimplePortalUserFromJSON,
  SimplePortalUserFromJSONTyped,
  SimplePortalUserToJSON,
} from "./";

/**
 *
 * @export
 * @interface AuditlogModel
 */
export interface AuditlogModel {
  /**
   *
   * @type {number}
   * @memberof AuditlogModel
   */
  id?: number | null;
  /**
   *
   * @type {string}
   * @memberof AuditlogModel
   */
  userId?: string | null;
  /**
   *
   * @type {SimplePortalUser}
   * @memberof AuditlogModel
   */
  user?: SimplePortalUser;
  /**
   *
   * @type {Date}
   * @memberof AuditlogModel
   */
  created?: Date;
  /**
   *
   * @type {string}
   * @memberof AuditlogModel
   */
  organisationCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof AuditlogModel
   */
  emailadress?: string | null;
  /**
   *
   * @type {number}
   * @memberof AuditlogModel
   */
  advertiserCode?: number | null;
  /**
   *
   * @type {MediumEnum}
   * @memberof AuditlogModel
   */
  medium?: MediumEnum;
  /**
   *
   * @type {number}
   * @memberof AuditlogModel
   */
  productId?: number | null;
  /**
   *
   * @type {number}
   * @memberof AuditlogModel
   */
  orderId?: number | null;
  /**
   *
   * @type {number}
   * @memberof AuditlogModel
   */
  requestId?: number | null;
  /**
   *
   * @type {AuditActionType}
   * @memberof AuditlogModel
   */
  actionType?: AuditActionType;
  /**
   *
   * @type {string}
   * @memberof AuditlogModel
   */
  actionMessage?: string | null;
}

export function AuditlogModelFromJSON(json: any): AuditlogModel {
  return AuditlogModelFromJSONTyped(json, false);
}

export function AuditlogModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AuditlogModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    userId: !exists(json, "userId") ? undefined : json["userId"],
    user: !exists(json, "user")
      ? undefined
      : SimplePortalUserFromJSON(json["user"]),
    created: !exists(json, "created") ? undefined : new Date(json["created"]),
    organisationCode: !exists(json, "organisationCode")
      ? undefined
      : json["organisationCode"],
    emailadress: !exists(json, "emailadress") ? undefined : json["emailadress"],
    advertiserCode: !exists(json, "advertiserCode")
      ? undefined
      : json["advertiserCode"],
    medium: !exists(json, "medium")
      ? undefined
      : MediumEnumFromJSON(json["medium"]),
    productId: !exists(json, "productId") ? undefined : json["productId"],
    orderId: !exists(json, "orderId") ? undefined : json["orderId"],
    requestId: !exists(json, "requestId") ? undefined : json["requestId"],
    actionType: !exists(json, "actionType")
      ? undefined
      : AuditActionTypeFromJSON(json["actionType"]),
    actionMessage: !exists(json, "actionMessage")
      ? undefined
      : json["actionMessage"],
  };
}

export function AuditlogModelToJSON(value?: AuditlogModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    userId: value.userId,
    user: SimplePortalUserToJSON(value.user),
    created:
      value.created === undefined ? undefined : value.created.toISOString(),
    organisationCode: value.organisationCode,
    emailadress: value.emailadress,
    advertiserCode: value.advertiserCode,
    medium: MediumEnumToJSON(value.medium),
    productId: value.productId,
    orderId: value.orderId,
    requestId: value.requestId,
    actionType: AuditActionTypeToJSON(value.actionType),
    actionMessage: value.actionMessage,
  };
}
