/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  SettingDataType,
  SettingDataTypeFromJSON,
  SettingDataTypeFromJSONTyped,
  SettingDataTypeToJSON,
} from "./";

/**
 *
 * @export
 * @interface EditableSetting
 */
export interface EditableSetting {
  /**
   *
   * @type {string}
   * @memberof EditableSetting
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof EditableSetting
   */
  alias?: string | null;
  /**
   *
   * @type {SettingDataType}
   * @memberof EditableSetting
   */
  type?: SettingDataType;
  /**
   *
   * @type {any}
   * @memberof EditableSetting
   */
  value?: any | null;
}

export function EditableSettingFromJSON(json: any): EditableSetting {
  return EditableSettingFromJSONTyped(json, false);
}

export function EditableSettingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EditableSetting {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, "name") ? undefined : json["name"],
    alias: !exists(json, "alias") ? undefined : json["alias"],
    type: !exists(json, "type")
      ? undefined
      : SettingDataTypeFromJSON(json["type"]),
    value: !exists(json, "value") ? undefined : json["value"],
  };
}

export function EditableSettingToJSON(value?: EditableSetting | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    alias: value.alias,
    type: SettingDataTypeToJSON(value.type),
    value: value.value,
  };
}
