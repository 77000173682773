import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form, formatToEuro } from "@ster/ster-toolkit";
import { Alert } from "antd";
import { memo, useMemo } from "react";

import { PackageType } from "../../../../api";
import { getSpotPrice } from "../../../../utils";
import BreakSelect from "../../../breakselect/BreakSelect";
import { emptySelectedBreaks } from "../../../breakselect/constants";
import { BreakSelectionInputProps } from "./models";

const BreakSelectionInput = memo(
  ({
    subOrder,
    medium,
    productId,
    canSelectPreferredPosition,
    preferredPositionSurcharge,
    handleBreakSelectionChange,
    selectionRequired,
    isDirty,
    alreadySelected,
  }: BreakSelectionInputProps) => {
    const { i18n } = useLingui();
    const isSpecifiek = useMemo(
      () => subOrder._package?.type === PackageType.Specifiek,
      [subOrder._package]
    );

    return (
      <>
        <Form.Item
          label={i18n._(t`Blokkeuze`)}
          name="breakSelection"
          rules={[
            {
              validator: (_, value): Promise<void> =>
                selectionRequired && (value?.length ?? 0) === 0
                  ? Promise.reject(
                      new Error(i18n._(t`Selecteer minimaal 1 blok`))
                    )
                  : Promise.resolve(),
            },
          ]}
          shouldUpdate
        >
          <BreakSelect
            query={{
              budget: !isSpecifiek ? subOrder.budget : undefined,
              packageCode: subOrder._package?.code ?? "",
              targetGroup: subOrder.targetGroup?.intomartTargetGroupId ?? "",
              channels: subOrder.channels ?? [],
              productId,
              medium,
              spotLength:
                subOrder.spotLength?.reduce((a, b) => a + (b ?? 0)) ?? 0,
              excludedDays: subOrder.excluded ?? [],
              ...(subOrder.period ?? { from: new Date(), to: new Date() }),
              key: subOrder.id ?? 0,
            }}
            value={subOrder.breakSelection ?? emptySelectedBreaks}
            canSelectPreferredPosition={canSelectPreferredPosition}
            preferredPositionSurcharge={preferredPositionSurcharge}
            onChange={handleBreakSelectionChange}
            isDirty={isDirty}
            alreadySelected={alreadySelected}
          />
        </Form.Item>
        {(selectionRequired || subOrder.breakSelection) && (
          <Form.Item wrapperCol={{ offset: 4, span: 14 }}>
            <Alert
              showIcon
              type="info"
              message=""
              description={
                (subOrder.breakSelection?.length ?? 0) > 0
                  ? i18n._(
                      t`Je hebt ${
                        subOrder.breakSelection?.length
                      } blok(ken) geselecteerd met een totale waarde van ${formatToEuro(
                        subOrder.breakSelection?.reduce(
                          (sum, b) =>
                            sum +
                            (b._break
                              ? getSpotPrice(b, preferredPositionSurcharge)
                              : 0),
                          0
                        ) ?? 0,
                        false
                      )}`
                    )
                  : i18n._(t`Selecteer je blokken om door te gaan.`)
              }
            />
          </Form.Item>
        )}
      </>
    );
  }
);

export default BreakSelectionInput;
