/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  AnalysisTargetGroups,
  AnalysisTargetGroupsFromJSON,
  AnalysisTargetGroupsFromJSONTyped,
  AnalysisTargetGroupsToJSON,
} from "./";

/**
 *
 * @export
 * @interface AnalysisInput
 */
export interface AnalysisInput {
  /**
   *
   * @type {AnalysisTargetGroups}
   * @memberof AnalysisInput
   */
  secondaryTargetGroups?: AnalysisTargetGroups;
  /**
   *
   * @type {Array<number>}
   * @memberof AnalysisInput
   */
  relatedOrderIds?: Array<number> | null;
}

export function AnalysisInputFromJSON(json: any): AnalysisInput {
  return AnalysisInputFromJSONTyped(json, false);
}

export function AnalysisInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AnalysisInput {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    secondaryTargetGroups: !exists(json, "secondaryTargetGroups")
      ? undefined
      : AnalysisTargetGroupsFromJSON(json["secondaryTargetGroups"]),
    relatedOrderIds: !exists(json, "relatedOrderIds")
      ? undefined
      : json["relatedOrderIds"],
  };
}

export function AnalysisInputToJSON(value?: AnalysisInput | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    secondaryTargetGroups: AnalysisTargetGroupsToJSON(
      value.secondaryTargetGroups
    ),
    relatedOrderIds: value.relatedOrderIds,
  };
}
