import { ActionType, createReducer } from "typesafe-actions";

import { ReduxStoreState } from "../base";
import { LoadingSecondaryTargetGroups } from "../models";
import { receiveSecondaryTargetGroupsAction } from "./actions";

const initialState: LoadingSecondaryTargetGroups = {
  state: ReduxStoreState.Initial,
  loading: false,
};

// eslint-disable-next-line import/prefer-default-export
export const secondaryTargetGroupsReducer = createReducer<
  LoadingSecondaryTargetGroups,
  ActionType<typeof receiveSecondaryTargetGroupsAction>
>(initialState)
  .handleAction(receiveSecondaryTargetGroupsAction.request, (state) => ({
    ...state,
    loading: true,
    state: ReduxStoreState.Loading,
  }))
  .handleAction(receiveSecondaryTargetGroupsAction.failure, (state) => ({
    ...state,
    loading: false,
    state: ReduxStoreState.Failure,
  }))
  .handleAction(
    receiveSecondaryTargetGroupsAction.success,
    (state, action) => ({
      ...action.payload,
      loading: false,
      state: ReduxStoreState.Success,
    })
  );
