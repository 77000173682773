import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  ContentContainer,
  NavLink,
  Row,
  Spinner,
  Subnav,
} from "@ster/ster-toolkit";
import { Alert, Col } from "antd";
import classNames from "classnames";
import moment from "moment";
import { memo, useCallback, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, Route, Routes, useParams } from "react-router-dom";

import { PackageResult } from "../../api";
import {
  MediumEnum,
  OrderDetail,
  OrderStatus,
  PortalSettings,
  SubOrderDetail,
} from "../../api/models";
import { secondaryTargetGroupsSelectorByMedium } from "../../shared/selectors";
import { ReduxStoreState } from "../../store/base";
import {
  receiveBookingDateAction,
  receivePackagesAction,
} from "../../store/campaignCreate/actions";
import { receiveCampaignDetailAction } from "../../store/campaignDetail/actions";
import { StoreModel } from "../../store/models";
import { isInternalUserOrAdmin } from "../../utils/userHelper";
import Notification from "../Notification";
import CampaignBackButton from "../partials/CampaignBackButton";
import CampaignInitialRequestButton from "../partials/CampaignInitialRequestButton";
import CampaignDetailCard from "../partials/Cards/CampaignDetail/CampaignDetailCard";
import SubOrderCard from "../partials/Cards/SubOrder/SubOrderCard";
import styles from "./CampaignDetail.module.less";
import CampaignSchedule from "./CampaignSchedule";
import CampaignInstructions from "./instructions/CampaignInstructions";
import CampaignOnlineInstructions from "./instructions/CampaignOnlineInstructions";
import { campaignDetailRootSelector } from "./selectors";

const CampaignNavigation = memo(
  ({
    medium,
    orderId,
    hasInstructions,
  }: {
    medium: MediumEnum;
    orderId: number;
    hasInstructions: boolean;
  }) => {
    const { i18n } = useLingui();
    return (
      <Subnav>
        <NavLink
          title={i18n._(t`Overzicht`)}
          to={`/campaigns/${medium}/${orderId}`}
          key="overzicht"
        />
        <>
          {medium !== MediumEnum.Inter && (
            <NavLink
              title={i18n._(t`Uitzendschema`)}
              to={`/campaigns/schedule/${medium}/${orderId}`}
              key="uitzendschema"
            />
          )}
          {hasInstructions && (
            <NavLink
              title={i18n._(t`Uitzendinstructies`)}
              to={`/campaigns/instructions/${medium}/${orderId}`}
              key="uitzendinstructies"
            />
          )}
        </>
      </Subnav>
    );
  }
);

const CampaignOverview = memo(
  ({
    campaign,
    packages,
    spotsEditable,
    medium,
    orderId,
    settings,
  }: {
    campaign: OrderDetail;
    packages: PackageResult[];
    spotsEditable: (order: OrderDetail, subOrder: SubOrderDetail) => boolean;
    medium: MediumEnum;
    orderId: number;
    settings: PortalSettings;
  }) => (
    <div>
      {campaign.subOrders?.map((suborder) => {
        const selectedPackage = packages
          ?.find((p) => p.type === suborder.packageChoice)
          ?.packages.find((p) => p.code === suborder._package?.code);

        return (
          <Row key={suborder.id} gutter={[30, 30]}>
            <Col span={24}>
              <SubOrderCard
                cardData={suborder}
                key={suborder.id}
                medium={medium}
                productId={campaign.productId}
                orderId={orderId}
                spotsEditable={spotsEditable(campaign, suborder)}
                canSelectPreferredPosition={
                  selectedPackage?.canSelectPreferredPosition ?? false
                }
                preferredPositionSurcharge={
                  selectedPackage?.preferredPositionSurcharge ?? 0
                }
                settings={settings}
                isSpreaded={campaign.isSpreaded}
              />
            </Col>
          </Row>
        );
      })}
    </div>
  )
);

const CampaignDetail = memo(() => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();

  const { medium: mediumFromParams, orderId: orderIdFromParams } = useParams<{
    medium?: string;
    orderId?: string;
  }>();

  const medium = mediumFromParams as MediumEnum;
  const orderId = Number.parseInt(orderIdFromParams as string, 10);

  const { campaign, loading, spotsEditableDate, packages, account, settings } =
    useSelector((state: StoreModel) =>
      campaignDetailRootSelector(state, orderId)
    );

  const packagesState = useSelector(
    ({ packages: { state } }: StoreModel) => state
  );

  const secondaryTargetGroupsByMedium = useSelector((state: StoreModel) =>
    secondaryTargetGroupsSelectorByMedium(state, { medium })
  );

  useEffect((): void => {
    if (!campaign) {
      dispatch(
        receiveCampaignDetailAction.request({
          medium,
          orderId,
        })
      );
    }

    if (!spotsEditableDate) {
      dispatch(
        receiveBookingDateAction.request({
          medium,
        })
      );
    }
  }, [campaign, dispatch, medium, orderId, spotsEditableDate]);

  const { claims } = account;
  const hasBookSpot = useMemo(() => claims?.includes("bookspot"), [claims]);
  const hasInstructions = useMemo(
    () => claims?.includes(`instructions_${medium.toLowerCase()}`),
    [claims, medium]
  );
  const canEdit = useMemo(
    () => claims?.includes(`aanvragen_formulier_${medium.toLowerCase()}`),
    [claims, medium]
  );

  useEffect(() => {
    // De pakketten worden gebruikt voor bookspot, dus alleen ophalen als je de juiste claim hebt
    if (hasBookSpot && campaign && packagesState === ReduxStoreState.Initial) {
      dispatch(
        receivePackagesAction.request({
          medium,
          product: `${campaign.productId}`,
          from: campaign.orderStartDate,
          to: campaign.orderEndDate,
        })
      );
    }
  }, [campaign, dispatch, hasBookSpot, medium, packagesState]);

  const spotsEditable = useCallback(
    (order: OrderDetail, subOrder: SubOrderDetail): boolean =>
      hasBookSpot &&
      (order.status === OrderStatus.Planned ||
        order.status === OrderStatus.Active) &&
      moment(subOrder.period.to).isSameOrAfter(moment(spotsEditableDate)) &&
      subOrder.spotsEditable,
    [hasBookSpot, spotsEditableDate]
  );

  const title = campaign
    ? `${campaign?.campaignName || campaign?.productDescr} (${medium})`
    : "...";

  return (
    <>
      <Helmet>
        <title>{i18n._(t`Campagne: ${title}`)}</title>
      </Helmet>

      <ContentContainer className="content">
        <Notification />
        <Spinner spinning={loading}>
          <Row gutter={[30, 30]}>
            <Col className={classNames(styles.links, "back-link")}>
              <CampaignBackButton />
              {isInternalUserOrAdmin(account) && campaign && (
                <CampaignInitialRequestButton campaign={campaign} />
              )}
            </Col>
          </Row>
          {campaign && (
            <>
              <Row gutter={[30, 30]}>
                <Col span={24}>
                  {settings.settings && (
                    <CampaignDetailCard
                      cardData={campaign}
                      settings={settings.settings}
                    />
                  )}
                </Col>
              </Row>
              <Row gutter={[30, 30]}>
                <Col span={24}>
                  <CampaignNavigation
                    medium={medium}
                    orderId={orderId}
                    hasInstructions={hasInstructions}
                  />
                </Col>
              </Row>
              <Routes>
                <Route
                  path="/schedule/:medium/:orderId"
                  element={
                    <Row gutter={[30, 30]}>
                      <Col span={24}>
                        <CampaignSchedule
                          campaign={campaign}
                          secondaryTargetGroups={secondaryTargetGroupsByMedium}
                          showPrices={(claims ?? []).includes("toon_prijzen")}
                        />
                      </Col>
                    </Row>
                  }
                >
                  <Route
                    path="/schedule/:medium/:orderId/:subOrderId"
                    element={<Outlet />}
                  />
                </Route>
                <Route
                  path="/instructions/inter/:orderId"
                  element={
                    <Row gutter={[30, 30]}>
                      <Col span={24}>
                        <CampaignOnlineInstructions campaign={campaign} />
                      </Col>
                    </Row>
                  }
                />
                <Route
                  path="/instructions/:medium/:orderId"
                  element={
                    <Row gutter={[30, 30]}>
                      <Col span={24}>
                        <CampaignInstructions
                          campaign={campaign}
                          canEdit={canEdit}
                        />
                      </Col>
                    </Row>
                  }
                />
                <Route
                  path="/:medium/:orderId"
                  element={
                    settings.settings && (
                      <CampaignOverview
                        campaign={campaign}
                        orderId={orderId}
                        medium={medium}
                        spotsEditable={spotsEditable}
                        packages={packages}
                        settings={settings.settings}
                      />
                    )
                  }
                />
              </Routes>
            </>
          )}
          {!campaign && !loading && (
            <Row gutter={[30, 30]}>
              <Col span={12} className="center">
                <Alert
                  message=""
                  description={i18n._(
                    t`Deze campagne kon niet gevonden worden.`
                  )}
                  type="error"
                  showIcon
                />
              </Col>
            </Row>
          )}
        </Spinner>
      </ContentContainer>
    </>
  );
});

export default CampaignDetail;
