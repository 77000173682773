import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  Modal,
  Table,
  Typography,
  formatNumber,
  formatToEuro,
} from "@ster/ster-toolkit";
import { ColumnsType } from "antd/lib/table";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  CampaignForwarded,
  DateSpan,
  MediumEnum,
  PackageChoiceEnum,
  PackageRequest,
  SubOrderConfiguration,
} from "../../api";
import { ReduxStoreState } from "../../store/base";
import {
  acceptAvailableCampaignAction,
  clearAcceptAvailableCampaignsAction,
  clearRejectAvailableCampaignsAction,
  receiveAvailableCampaignsAction,
  rejectAvailableCampaignAction,
} from "../../store/campaignForward/actions";
import { StoreModel } from "../../store/models";
import { getMediumIcon, getMediumName } from "../../utils";
import {
  getFormattedDate,
  getFormattedDateShort,
} from "../../utils/dateHelper";
import styles from "./AvailableCampaignDetail.module.less";

const AvailableCampaignDetail = ({
  campaign,
  handleClose,
}: {
  campaign: CampaignForwarded;
  handleClose: () => void;
}) => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();

  const subOrders = useMemo(
    () => campaign.campaignConfiguration?._configuration,
    [campaign.campaignConfiguration?._configuration]
  );

  const handleAccept = useCallback(() => {
    if (campaign.id) {
      dispatch(acceptAvailableCampaignAction.request(campaign.id));
    }
  }, [campaign.id, dispatch]);

  const handleReject = useCallback(() => {
    if (campaign.id) {
      dispatch(rejectAvailableCampaignAction.request(campaign.id));
    }
  }, [campaign.id, dispatch]);

  const acceptAvailable = useSelector(
    ({ campaignForward: { acceptAvailableCampaign } }: StoreModel) =>
      acceptAvailableCampaign
  );

  const rejectAvailable = useSelector(
    ({ campaignForward: { rejectAvailableCampaign } }: StoreModel) =>
      rejectAvailableCampaign
  );

  useEffect(() => {
    if (rejectAvailable.state === ReduxStoreState.Success) {
      dispatch(receiveAvailableCampaignsAction.request());
      dispatch(clearRejectAvailableCampaignsAction());
    }
  }, [dispatch, rejectAvailable.state]);

  useEffect(() => {
    if (acceptAvailable.state === ReduxStoreState.Success) {
      dispatch(receiveAvailableCampaignsAction.request());
      dispatch(clearAcceptAvailableCampaignsAction());
    }
  }, [acceptAvailable.state, dispatch]);

  const filterColumns = useCallback(
    (mergedColumns: ColumnsType<SubOrderConfiguration>) => {
      let columnsToFilter: string[] = [];
      if (subOrders) {
        // Voor NPO promo worden er afwijkende kolommen getoond
        if (
          subOrders.find((s) => s.packageChoice === PackageChoiceEnum.NpoPromo)
        ) {
          columnsToFilter = [
            ...columnsToFilter,
            "targetGroup",
            "grp",
            "budget",
          ];
        } else {
          columnsToFilter = [
            ...columnsToFilter,
            "campaignName",
            "spotNo",
            "endTime",
            "channels",
          ];
        }
      }
      if (subOrders?.[0].medium === MediumEnum.Inter) {
        columnsToFilter = [...columnsToFilter, "targetGroup", "grp"];
      } else {
        columnsToFilter = [...columnsToFilter, "impressions"];
      }
      return mergedColumns.filter(
        (s) => !columnsToFilter.find((c) => c === s.key)
      );
    },
    [subOrders]
  );

  const columns = [
    {
      title: i18n._(t`Periode`),
      dataIndex: "subOrderPeriod",
      key: "period",
      render: (dates: DateSpan) => (
        <span>
          {dates &&
            `${getFormattedDateShort(dates.from)} - ${getFormattedDateShort(
              dates.to
            )}`}
        </span>
      ),
    },
    {
      title: i18n._(t`Spotlengte`),
      dataIndex: "spotLength",
      key: "spotLength",
      render: (spotLength: number[]) => (
        <span>
          {spotLength?.length > 0
            ? spotLength.map((s) => s && `${s}"`).join(" + ")
            : "n.v.t."}
        </span>
      ),
    },
    {
      title: i18n._(t`Pakket`),
      dataIndex: "_package",
      key: "_package",
      expandableCell: false,
      render: (
        { name }: Partial<PackageRequest> = {},
        { breakSelection }: SubOrderConfiguration
      ) => (
        <>
          {name ?? ""}
          {(breakSelection?.length ?? 0) > 0 && (
            <div>
              <Trans>Aantal blokken: {breakSelection?.length}</Trans>
            </div>
          )}
        </>
      ),
    },
    {
      title: i18n._(t`Inkoopdoelgroep`),
      dataIndex: "targetGroupId",
      key: "targetGroup",
      render: (
        targetGroupId: string,
        { packageChoice }: SubOrderConfiguration
      ): string =>
        packageChoice === PackageChoiceEnum.Grps
          ? targetGroupId ?? ""
          : "n.v.t.",
    },
    {
      title: i18n._(t`Campagnenaam`),
      dataIndex: "campaignName",
      key: "campaignName",
      render: (campaignName: string): string => campaignName,
    },
    {
      title: i18n._(t`GRP's`),
      dataIndex: "grps",
      key: "grp",
      editableCell: true,
      render: (
        grp: number,
        { packageChoice }: SubOrderConfiguration
      ): string =>
        packageChoice === PackageChoiceEnum.Grps
          ? formatNumber(grp ?? 0, 2)
          : i18n._(t`n.v.t.`),
    },
    {
      title: i18n._(t`Impressies`),
      dataIndex: "impressions",
      key: "impressions",
      render: (impressions: number): string => formatNumber(impressions ?? 0),
    },
    {
      title: i18n._(t`Budget`),
      dataIndex: "budget",
      key: "budget",
      editableCell: true,
      render: (value: number): string =>
        value ? formatToEuro(value, false) : formatToEuro(0, false),
    },
    {
      title: i18n._(t`Zenders`),
      dataIndex: "channels",
      key: "channels",
      render: (value: string[]): string =>
        value?.sort((a, b) => a.localeCompare(b)).join(", "),
    },
  ];

  return (
    <Modal
      open
      title={i18n._(t`Campagnevoorstel`)}
      onCancel={handleClose}
      className={styles.details}
      footer={
        <div className={styles.footer}>
          <Button
            mode="tertiary"
            onClick={handleReject}
            loading={rejectAvailable.loading}
          >
            <Trans>Weigeren</Trans>
          </Button>

          <Button
            key="submit"
            mode="primary"
            onClick={handleAccept}
            loading={acceptAvailable.loading}
          >
            <Trans>Accepteren</Trans>
          </Button>
        </div>
      }
    >
      <Typography.Paragraph>
        <Typography.Title level={2}>
          <Trans>gegevens</Trans>
        </Typography.Title>
        <table>
          <tbody>
            <tr>
              <td>
                <b>
                  <Trans>Medium</Trans>
                </b>
              </td>
              <td>
                {i18n._(getMediumName(subOrders?.[0].medium))}
                {getMediumIcon(subOrders?.[0].medium)}
              </td>
            </tr>
            <tr>
              <td>
                <b>
                  <Trans>Periode</Trans>
                </b>
              </td>
              <td>
                {i18n._(t`${getFormattedDate(
                  campaign.campaignConfiguration?.orderPeriod?.from,
                  "PPP"
                )} tot en met
                ${getFormattedDate(
                  campaign.campaignConfiguration?.orderPeriod?.to,
                  "PPP"
                )}`)}
              </td>
            </tr>
            <tr>
              <td>
                <b>
                  <Trans>Adverteerder</Trans>
                </b>
              </td>
              <td>{campaign.advertiserName}</td>
            </tr>
            <tr>
              <td>
                <b>
                  <Trans>Sternummer</Trans>
                </b>
              </td>
              <td>
                {campaign.productDescr} - {campaign.productId}
              </td>
            </tr>
          </tbody>
        </table>
        <Typography.Title level={2}>
          <Trans>pakketten</Trans>
        </Typography.Title>
        <Table
          rowKey={(record) => record.id ?? 0}
          columns={filterColumns(columns)}
          dataSource={subOrders ?? []}
          pagination={false}
        />
      </Typography.Paragraph>
    </Modal>
  );
};

export default memo(AvailableCampaignDetail);
