/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface NpoPlayerResult
 */
export interface NpoPlayerResult {
  /**
   *
   * @type {string}
   * @memberof NpoPlayerResult
   */
  token?: string | null;
  /**
   *
   * @type {string}
   * @memberof NpoPlayerResult
   */
  embedUrl?: string | null;
  /**
   *
   * @type {string}
   * @memberof NpoPlayerResult
   */
  embedCode?: string | null;
}

export function NpoPlayerResultFromJSON(json: any): NpoPlayerResult {
  return NpoPlayerResultFromJSONTyped(json, false);
}

export function NpoPlayerResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): NpoPlayerResult {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    token: !exists(json, "token") ? undefined : json["token"],
    embedUrl: !exists(json, "embedUrl") ? undefined : json["embedUrl"],
    embedCode: !exists(json, "embedCode") ? undefined : json["embedCode"],
  };
}

export function NpoPlayerResultToJSON(value?: NpoPlayerResult | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    token: value.token,
    embedUrl: value.embedUrl,
    embedCode: value.embedCode,
  };
}
