/* eslint-disable no-nested-ternary */
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form, MultiOption, Radio, Select } from "@ster/ster-toolkit";
import { Alert, Radio as AntRadio } from "antd";
import { memo } from "react";

import {
  DateSpan,
  DigitalProgram,
  Package,
  PackageChoiceEnum,
  ValueList,
} from "../../../../api";
import {
  cb2025t11453RuleEnabled,
  getPopupContainerSubOrders,
} from "../../../../utils";
import { FormReadOnlyItem } from "../../../partials/Form";
import styles from "../../details/Details.module.less";
import selectStyles from "../OnlineSubOrder.module.less";

const ProgramSiteOrContextTargeting = memo(
  ({
    selectedPackage,
    period,
    packageChoice,
    programs,
    contextTargets,
    isDisplay,
    handleSelectAllSites,
    sites,
  }: {
    selectedPackage?: Package;
    period?: DateSpan;
    packageChoice?: PackageChoiceEnum;
    programs: DigitalProgram[];
    contextTargets: ValueList[];
    isDisplay: boolean;
    handleSelectAllSites: (selectAll: boolean) => void;
    sites: ValueList[];
  }) => {
    const { i18n } = useLingui();

    // CB2025: (11453) Voor het eerste deel van 2025 bieden we geen DISPLAY ROS en contexten aan omdat de voorraad te klein is
    const cb2025ROSdisplayRuleEnabled = cb2025t11453RuleEnabled(
      period,
      selectedPackage,
      packageChoice
    );
    const displayTargeting = !cb2025ROSdisplayRuleEnabled;
    const enabledSites = cb2025ROSdisplayRuleEnabled
      ? sites.filter((s) => s.name === "nos.nl")
      : sites;

    return (
      <>
        {cb2025ROSdisplayRuleEnabled && (
          <Alert
            showIcon
            message=""
            type="warning"
            className={styles.alert}
            description={
              <Trans>
                <b>Let op:</b> Op dit moment is het alleen mogelijk om ROS op
                nos.nl in te kopen. Zodra er meer mogelijk is worden de sites en
                contexten weer beschikbaar.
              </Trans>
            }
          />
        )}
        {displayTargeting && (
          <Form.Item
            label={i18n._(t`Targeting`)}
            name="targeting"
            rules={[
              {
                required: true,
                message: i18n._(t`Kies een targeting optie`),
              },
            ]}
          >
            <Radio.Group>
              {isDisplay ? (
                <AntRadio value="site">
                  <Trans>Site</Trans>
                </AntRadio>
              ) : (
                <AntRadio value="program">
                  <Trans>Programma</Trans>
                </AntRadio>
              )}
              <AntRadio value="context">
                <Trans>Context</Trans>
              </AntRadio>
            </Radio.Group>
          </Form.Item>
        )}
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.targeting !== currentValues.targeting
          }
        >
          {({ getFieldValue }) =>
            getFieldValue("targeting") === "program" ? (
              <Form.Item
                label={i18n._(t`Programma's`)}
                name="program"
                rules={[
                  {
                    required: true,
                    message: i18n._(t`Kies 1 of meer programma's`),
                  },
                ]}
              >
                <Select.Multi
                  placeholder={i18n._(t`Kies 1 of meer programma's`)}
                  showSearch
                  singleLine={false}
                  className={selectStyles["selection-max-width"]}
                  getPopupContainer={getPopupContainerSubOrders}
                >
                  {programs.map((s) => (
                    <MultiOption key={s.name} value={s.name} title={s.name} />
                  ))}
                </Select.Multi>
              </Form.Item>
            ) : getFieldValue("targeting") === "site" ? (
              cb2025ROSdisplayRuleEnabled ? (
                <FormReadOnlyItem
                  label={i18n._(t`Sites`)}
                  name="site"
                  value="nos.nl"
                  className={selectStyles.readOnlySites}
                />
              ) : (
                <Form.Item
                  label={i18n._(t`Sites`)}
                  name="site"
                  rules={[
                    {
                      required: true,
                      message: i18n._(t`Kies 1 of meer sites`),
                    },
                  ]}
                >
                  <Select.Multi
                    placeholder={i18n._(t`Kies 1 of meer sites`)}
                    showSearch
                    singleLine={false}
                    className={selectStyles["selection-max-width"]}
                    getPopupContainer={getPopupContainerSubOrders}
                    onSelectAll={handleSelectAllSites}
                    selectAllLabel={i18n._(t`Alles selecteren`)}
                    deselectAllLabel={i18n._(t`Niets selecteren`)}
                  >
                    {enabledSites.map((s) => (
                      <MultiOption
                        key={s.name}
                        value={s.name ?? ""}
                        title={s.name}
                      />
                    ))}
                  </Select.Multi>
                </Form.Item>
              )
            ) : getFieldValue("targeting") === "context" ? (
              <Form.Item
                label={i18n._(t`Context targeting`)}
                name="contextTargets"
                rules={[
                  {
                    validator: (_, value): Promise<void> =>
                      value && value.length >= 2
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error(
                              i18n._(
                                t`Je dient minimaal 2 items te selecteren.`
                              )
                            )
                          ),
                  },
                ]}
              >
                <Select.Multi
                  placeholder={i18n._(t`Kies 2 of meer contexten`)}
                  showSearch
                  singleLine={false}
                  className={selectStyles["selection-max-width"]}
                >
                  {contextTargets.map((s) => (
                    <MultiOption
                      key={s.aka}
                      value={s.aka ?? ""}
                      title={s.name}
                    />
                  ))}
                </Select.Multi>
              </Form.Item>
            ) : null
          }
        </Form.Item>
      </>
    );
  }
);

export default ProgramSiteOrContextTargeting;
