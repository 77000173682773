/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CustomerAccount
 */
export interface CustomerAccount {
  /**
   *
   * @type {string}
   * @memberof CustomerAccount
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CustomerAccount
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof CustomerAccount
   */
  starlightId: string;
}

export function CustomerAccountFromJSON(json: any): CustomerAccount {
  return CustomerAccountFromJSONTyped(json, false);
}

export function CustomerAccountFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CustomerAccount {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json["name"],
    id: json["id"],
    starlightId: json["starlightId"],
  };
}

export function CustomerAccountToJSON(value?: CustomerAccount | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    id: value.id,
    starlightId: value.starlightId,
  };
}
