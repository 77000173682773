/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  BookSpotItem,
  BookSpotItemFromJSON,
  BookSpotItemFromJSONTyped,
  BookSpotItemToJSON,
} from "./";

/**
 *
 * @export
 * @interface BookSpotCollection
 */
export interface BookSpotCollection {
  /**
   *
   * @type {string}
   * @memberof BookSpotCollection
   */
  emailPlannerAgency?: string | null;
  /**
   *
   * @type {string}
   * @memberof BookSpotCollection
   */
  emailPlannerSalesHouse?: string | null;
  /**
   *
   * @type {number}
   * @memberof BookSpotCollection
   */
  orderId: number;
  /**
   *
   * @type {number}
   * @memberof BookSpotCollection
   */
  subOrderId: number;
  /**
   *
   * @type {Array<BookSpotItem>}
   * @memberof BookSpotCollection
   */
  items: Array<BookSpotItem>;
}

export function BookSpotCollectionFromJSON(json: any): BookSpotCollection {
  return BookSpotCollectionFromJSONTyped(json, false);
}

export function BookSpotCollectionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BookSpotCollection {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    emailPlannerAgency: !exists(json, "emailPlannerAgency")
      ? undefined
      : json["emailPlannerAgency"],
    emailPlannerSalesHouse: !exists(json, "emailPlannerSalesHouse")
      ? undefined
      : json["emailPlannerSalesHouse"],
    orderId: json["orderId"],
    subOrderId: json["subOrderId"],
    items: (json["items"] as Array<any>).map(BookSpotItemFromJSON),
  };
}

export function BookSpotCollectionToJSON(
  value?: BookSpotCollection | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    emailPlannerAgency: value.emailPlannerAgency,
    emailPlannerSalesHouse: value.emailPlannerSalesHouse,
    orderId: value.orderId,
    subOrderId: value.subOrderId,
    items: (value.items as Array<any>).map(BookSpotItemToJSON),
  };
}
