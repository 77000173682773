/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ResultActivated
 */
export interface ResultActivated {
  /**
   *
   * @type {boolean}
   * @memberof ResultActivated
   */
  activated?: boolean;
}

export function ResultActivatedFromJSON(json: any): ResultActivated {
  return ResultActivatedFromJSONTyped(json, false);
}

export function ResultActivatedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ResultActivated {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    activated: !exists(json, "activated") ? undefined : json["activated"],
  };
}

export function ResultActivatedToJSON(value?: ResultActivated | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    activated: value.activated,
  };
}
