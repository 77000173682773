/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ForecastResult,
  ForecastResultFromJSON,
  ForecastResultFromJSONTyped,
  ForecastResultToJSON,
} from "./";

/**
 *
 * @export
 * @interface ForecastsResult
 */
export interface ForecastsResult {
  /**
   *
   * @type {{ [key: string]: ForecastResult; }}
   * @memberof ForecastsResult
   */
  byId: { [key: string]: ForecastResult };
  /**
   *
   * @type {ForecastResult}
   * @memberof ForecastsResult
   */
  combined: ForecastResult;
}

export function ForecastsResultFromJSON(json: any): ForecastsResult {
  return ForecastsResultFromJSONTyped(json, false);
}

export function ForecastsResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ForecastsResult {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    byId: mapValues(json["byId"], ForecastResultFromJSON),
    combined: ForecastResultFromJSON(json["combined"]),
  };
}

export function ForecastsResultToJSON(value?: ForecastsResult | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    byId: mapValues(value.byId, ForecastResultToJSON),
    combined: ForecastResultToJSON(value.combined),
  };
}
