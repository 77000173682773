import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

import {
  CampaignForwardApi,
  CampaignImportOutput,
  ProblemDetails,
} from "../../api";
import apiConfig from "../../apiConfig";
import { redirectRequest } from "../campaignCreate/sagas";
import { toError } from "../utils";
import {
  acceptAvailableCampaignAction,
  forwardCampaignAction,
  receiveAvailableCampaignDetailAction,
  receiveAvailableCampaignsAction,
  receiveContactsForForwardAction,
  receiveProductsForForwardAction,
  rejectAvailableCampaignAction,
} from "./actions";

export function* receiveContactsForForward(
  action: ReturnType<typeof receiveContactsForForwardAction.request>
): SagaIterator {
  try {
    const campaignForwardApi = new CampaignForwardApi(apiConfig());
    const response = yield call(() =>
      campaignForwardApi.apiV1CampaignForwardContactsGet(action.payload)
    );

    yield put(receiveContactsForForwardAction.success(response));
  } catch (err) {
    yield put(receiveContactsForForwardAction.failure(toError(err)));
  }
}

export function* receiveProductsForForward(
  action: ReturnType<typeof receiveProductsForForwardAction.request>
): SagaIterator {
  try {
    const campaignForwardApi = new CampaignForwardApi(apiConfig());
    const response = yield call(() =>
      campaignForwardApi.apiV1CampaignForwardProductsMediumGet(action.payload)
    );

    yield put(receiveProductsForForwardAction.success(response));
  } catch (err) {
    yield put(receiveProductsForForwardAction.failure(toError(err)));
  }
}

export function* forwardCampaign(
  action: ReturnType<typeof forwardCampaignAction.request>
): SagaIterator {
  try {
    const campaignForwardApi = new CampaignForwardApi(apiConfig());
    const response = yield call(() =>
      campaignForwardApi.apiV1CampaignForwardInitialRequestIdPost(
        action.payload
      )
    );

    yield put(forwardCampaignAction.success(response));
  } catch (err) {
    if ((err as Response).status === 409) {
      const { detail }: ProblemDetails = yield call(() =>
        (err as Response).json()
      );
      yield put(
        forwardCampaignAction.failure({
          ...toError(err),
          detail,
        })
      );
    } else {
      yield put(forwardCampaignAction.failure(toError(err)));
    }
  }
}

export function* receiveAvailableCampaigns(): SagaIterator {
  try {
    const campaignForwardApi = new CampaignForwardApi(apiConfig());
    const response = yield call(() =>
      campaignForwardApi.apiV1CampaignForwardAvailableGet()
    );

    yield put(receiveAvailableCampaignsAction.success(response));
  } catch (err) {
    yield put(receiveAvailableCampaignsAction.failure(toError(err)));
  }
}

export function* acceptAvailableCampaign(
  action: ReturnType<typeof acceptAvailableCampaignAction.request>
): SagaIterator {
  try {
    const campaignApi = new CampaignForwardApi(apiConfig());
    const response: CampaignImportOutput = yield call(() =>
      campaignApi.apiV1CampaignForwardAcceptPost({
        id: action.payload,
      })
    );
    yield put(acceptAvailableCampaignAction.success(response));
    if (response.orderPeriod) {
      yield call(redirectRequest, {
        id: response.initialRequestId,
        medium: response.mediumEnum,
        period: response.orderPeriod,
      });
    }
  } catch (err) {
    if ((err as Response).status === 409) {
      const { detail }: ProblemDetails = yield call(() =>
        (err as Response).json()
      );
      yield put(
        acceptAvailableCampaignAction.failure({
          ...toError(err),
          detail,
        })
      );
    } else {
      yield put(acceptAvailableCampaignAction.failure(toError(err)));
    }
  }
}

export function* rejectAvailableCampaigns(
  action: ReturnType<typeof rejectAvailableCampaignAction.request>
): SagaIterator {
  try {
    const campaignForwardApi = new CampaignForwardApi(apiConfig());
    yield call(() =>
      campaignForwardApi.apiV1CampaignForwardRejectPost({
        id: action.payload,
      })
    );

    yield put(rejectAvailableCampaignAction.success());
  } catch (err) {
    yield put(rejectAvailableCampaignAction.failure(toError(err)));
  }
}

export function* receiveAvailableCampaignDetail(
  action: ReturnType<typeof receiveAvailableCampaignDetailAction.request>
): SagaIterator {
  try {
    const campaignForwardApi = new CampaignForwardApi(apiConfig());
    const response = yield call(() =>
      campaignForwardApi.apiV1CampaignForwardDetailsIdGet({
        id: action.payload,
      })
    );

    yield put(receiveAvailableCampaignDetailAction.success(response));
  } catch (err) {
    yield put(
      receiveAvailableCampaignDetailAction.failure({
        id: action.payload,
        ...toError(err),
      })
    );
  }
}
