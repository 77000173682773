import { Trans } from "@lingui/macro";
import { Button, Icons } from "@ster/ster-toolkit";
import { Dropdown } from "antd";
import classNames from "classnames";
import { memo } from "react";

import { InitialRequestState } from "./models";
import styles from "./MoreMenu.module.less";

interface MoreMenuProps {
  handleCancel: () => void;
  handleDelete: () => void;
  initialRequestState: InitialRequestState | undefined;
  loading: boolean;
}

const MoreMenu = memo(
  ({
    handleCancel,
    handleDelete,
    initialRequestState,
    loading,
  }: MoreMenuProps) => {
    const showCancelButton = initialRequestState?.isConcept ?? true;
    const showDeleteButton = initialRequestState?.canDelete;

    if (showCancelButton && showDeleteButton) {
      return (
        <Dropdown
          disabled={loading}
          menu={{
            items: [
              {
                label: <Trans>Annuleren</Trans>,
                key: "cancel",
                icon: <Icons.CloseIcon fill="#000" />,
                className: "more-menu-item",
                onClick: handleCancel,
              },
              {
                label: <Trans>Verwijderen</Trans>,
                key: "delete",
                icon: <Icons.DeleteIcon fill="#000" />,
                className: "more-menu-item",
                onClick: handleDelete,
              },
            ],
          }}
          trigger={["click"]}
          overlayClassName={classNames(styles.dropdown, "more-menu")}
        >
          <span className="more-menu-button-wrapper">
            <Button mode="outline" className="more-menu-button">
              <Trans>
                <span className="more-menu-button__label">Meer</span>
              </Trans>
              <Icons.MoreIcon fill="#FFF" />
            </Button>
          </span>
        </Dropdown>
      );
    }

    if (showCancelButton) {
      return (
        <Button mode="tertiary" disabled={loading} onClick={handleCancel}>
          <Icons.CloseIcon fill="#008ccc" />
          <Trans>Annuleren</Trans>
        </Button>
      );
    }

    if (showDeleteButton) {
      return (
        <Button mode="tertiary" disabled={loading} onClick={handleDelete}>
          <Icons.DeleteIcon fill="#000" />
          <Trans>Verwijderen</Trans>
        </Button>
      );
    }

    return null;
  }
);

export default MoreMenu;
