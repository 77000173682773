/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  DiscountProps,
  DiscountPropsFromJSON,
  DiscountPropsFromJSONTyped,
  DiscountPropsToJSON,
} from "./";

/**
 *
 * @export
 * @interface CalculationRequest
 */
export interface CalculationRequest {
  /**
   *
   * @type {number}
   * @memberof CalculationRequest
   */
  productId?: number;
  /**
   *
   * @type {Date}
   * @memberof CalculationRequest
   */
  dateFrom?: Date;
  /**
   *
   * @type {Date}
   * @memberof CalculationRequest
   */
  dateTo?: Date;
  /**
   *
   * @type {Array<Date>}
   * @memberof CalculationRequest
   */
  excludedDays?: Array<Date> | null;
  /**
   *
   * @type {string}
   * @memberof CalculationRequest
   */
  packageCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof CalculationRequest
   */
  targetGroupId?: string | null;
  /**
   *
   * @type {number}
   * @memberof CalculationRequest
   */
  spotLength?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof CalculationRequest
   */
  channels?: Array<string> | null;
  /**
   *
   * @type {number}
   * @memberof CalculationRequest
   */
  budget?: number;
  /**
   *
   * @type {number}
   * @memberof CalculationRequest
   */
  grp?: number;
  /**
   *
   * @type {number}
   * @memberof CalculationRequest
   */
  spotsPerDay?: number;
  /**
   *
   * @type {number}
   * @memberof CalculationRequest
   */
  spotsPerDayChannel?: number;
  /**
   *
   * @type {number}
   * @memberof CalculationRequest
   */
  impressions?: number;
  /**
   *
   * @type {string}
   * @memberof CalculationRequest
   */
  format?: string | null;
  /**
   *
   * @type {DiscountProps}
   * @memberof CalculationRequest
   */
  discountProperties?: DiscountProps;
  /**
   *
   * @type {number}
   * @memberof CalculationRequest
   */
  startTime?: number | null;
  /**
   *
   * @type {number}
   * @memberof CalculationRequest
   */
  endTime?: number | null;
  /**
   *
   * @type {Date}
   * @memberof CalculationRequest
   */
  requestDate?: Date | null;
}

export function CalculationRequestFromJSON(json: any): CalculationRequest {
  return CalculationRequestFromJSONTyped(json, false);
}

export function CalculationRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CalculationRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    productId: !exists(json, "productId") ? undefined : json["productId"],
    dateFrom: !exists(json, "dateFrom")
      ? undefined
      : new Date(json["dateFrom"]),
    dateTo: !exists(json, "dateTo") ? undefined : new Date(json["dateTo"]),
    excludedDays: !exists(json, "excludedDays")
      ? undefined
      : json["excludedDays"],
    packageCode: !exists(json, "packageCode") ? undefined : json["packageCode"],
    targetGroupId: !exists(json, "targetGroupId")
      ? undefined
      : json["targetGroupId"],
    spotLength: !exists(json, "spotLength") ? undefined : json["spotLength"],
    channels: !exists(json, "channels") ? undefined : json["channels"],
    budget: !exists(json, "budget") ? undefined : json["budget"],
    grp: !exists(json, "grp") ? undefined : json["grp"],
    spotsPerDay: !exists(json, "spotsPerDay") ? undefined : json["spotsPerDay"],
    spotsPerDayChannel: !exists(json, "spotsPerDayChannel")
      ? undefined
      : json["spotsPerDayChannel"],
    impressions: !exists(json, "impressions") ? undefined : json["impressions"],
    format: !exists(json, "format") ? undefined : json["format"],
    discountProperties: !exists(json, "discountProperties")
      ? undefined
      : DiscountPropsFromJSON(json["discountProperties"]),
    startTime: !exists(json, "startTime") ? undefined : json["startTime"],
    endTime: !exists(json, "endTime") ? undefined : json["endTime"],
    requestDate: !exists(json, "requestDate")
      ? undefined
      : json["requestDate"] === null
        ? null
        : new Date(json["requestDate"]),
  };
}

export function CalculationRequestToJSON(
  value?: CalculationRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    productId: value.productId,
    dateFrom:
      value.dateFrom === undefined ? undefined : value.dateFrom.toISOString(),
    dateTo: value.dateTo === undefined ? undefined : value.dateTo.toISOString(),
    excludedDays: value.excludedDays,
    packageCode: value.packageCode,
    targetGroupId: value.targetGroupId,
    spotLength: value.spotLength,
    channels: value.channels,
    budget: value.budget,
    grp: value.grp,
    spotsPerDay: value.spotsPerDay,
    spotsPerDayChannel: value.spotsPerDayChannel,
    impressions: value.impressions,
    format: value.format,
    discountProperties: DiscountPropsToJSON(value.discountProperties),
    startTime: value.startTime,
    endTime: value.endTime,
    requestDate:
      value.requestDate === undefined
        ? undefined
        : value.requestDate === null
          ? null
          : value.requestDate.toISOString(),
  };
}
