import { createAction, createAsyncAction } from "typesafe-actions";

import { OperationalContextState, PortalUser } from "../../api/models";
import { ResponseError } from "../base";
import { actionNameAsync } from "../utils";

export const receiveMeAction = createAsyncAction(
  actionNameAsync("accountme", "REQUEST"),
  actionNameAsync("accountme", "SUCCESS"),
  actionNameAsync("accountme", "FAILURE")
)<undefined, PortalUser, ResponseError>();

export const logoutMeAction = createAsyncAction(
  actionNameAsync("logoutMe", "REQUEST"),
  actionNameAsync("logoutMe", "SUCCESS"),
  actionNameAsync("logoutMe", "FAILURE")
)<undefined, undefined, ResponseError>();

export const setOperationalContextStateAction = createAction(
  "SET_OPERATIONAL_CONTEXT_STATE"
)<OperationalContextState | null>();
export const loadOperationalContextStateAction = createAction(
  "LOAD_OPERATIONAL_CONTEXT_STATE"
)();
