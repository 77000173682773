import { memo } from "react";

import { ForecastResult, OrderListItem } from "../../api";
import CampaignCard from "../partials/Cards/Campaign/CampaignCard";
import Forecast from "../partials/Forecast/Forecast";

const ProposalCampaign = memo(
  ({
    campaign,
    forecast,
  }: {
    campaign: OrderListItem;
    forecast?: ForecastResult;
  }) => (
    <>
      <CampaignCard cardData={campaign} openDetailsInNewTab />
      {forecast && (
        <Forecast
          medium={campaign.medium}
          forecast={forecast}
          loading={false}
        />
      )}
    </>
  )
);

export default ProposalCampaign;
