import { i18n } from "@lingui/core";
import { Trans, t } from "@lingui/macro";
import {
  Button,
  ComponentLocalization,
  RangePicker,
  Tooltip,
  formatDate,
} from "@ster/ster-toolkit";
import { Badge, Space } from "antd";
import { isSameDay } from "date-fns";
import moment from "moment";
// eslint-disable-next-line import/no-extraneous-dependencies
import { RangeValueType } from "rc-picker/lib/PickerInput/RangePicker";
import { memo, useCallback, useMemo, useState } from "react";

import { AnnouncementDto } from "../../api/models";
import { getLanguage } from "../../utils";

const EditablePeriod = memo(
  ({
    item,
    onChange,
  }: {
    item: AnnouncementDto;
    onChange: (announcement: AnnouncementDto) => void;
  }) => {
    const [edit, setEdit] = useState(false);
    const toggleEdit = useCallback(() => {
      setEdit(!edit);
    }, [edit]);

    const [period, setPeriod] = useState<RangeValueType<Date>>([
      item.startDate,
      item.endDate,
    ]);
    const handleChange = useCallback((values: RangeValueType<Date>) => {
      setPeriod(values);
    }, []);

    const handleCancel = useCallback(() => {
      setPeriod([item.startDate, item.endDate]);
      toggleEdit();
    }, [item.endDate, item.startDate, toggleEdit]);

    const handleSave = useCallback(() => {
      if (
        period &&
        period[0] &&
        period[1] &&
        (!isSameDay(item.startDate, period[0]) ||
          !isSameDay(item.endDate, period[1]))
      ) {
        onChange({
          ...item,
          startDate: period[0],
          endDate: period[1],
        });
      }

      toggleEdit();
    }, [item, onChange, period, toggleEdit]);

    const isCurrentlyVisisble = useMemo(
      () =>
        item.show &&
        moment().isBetween(
          moment(item.startDate),
          moment(item.endDate),
          "day",
          "[]"
        ),
      [item.show, item.startDate, item.endDate]
    );

    return edit ? (
      <Space>
        <RangePicker
          allowClear={false}
          value={[period?.[0] ?? item.startDate, period?.[1] ?? item.endDate]}
          onChange={handleChange}
          autoFocus
          componentLocale={getLanguage() as ComponentLocalization}
        />
        <Button onClick={handleCancel} mode="secondary">
          <Trans>Annuleren</Trans>
        </Button>
        <Button onClick={handleSave} mode="primary">
          <Trans>Opslaan</Trans>
        </Button>
      </Space>
    ) : (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div onClick={toggleEdit}>
        <Badge
          status={isCurrentlyVisisble ? "success" : "default"}
          text={
            <Tooltip
              title={
                isCurrentlyVisisble
                  ? i18n._(
                      t`Momenteel is dit bericht zichtbaar op de site. Klik om datum aan te passen.`
                    )
                  : i18n._(
                      t`Momenteel is dit bericht niet zichtbaar op de site. Klik om datum aan te passen.`
                    )
              }
              placement="bottom"
            >
              <Trans>
                Gepubliceerd van {formatDate(item.startDate, "d MMMM yyyy")} t/m{" "}
                {formatDate(item.endDate, "d MMMM yyyy")}
              </Trans>
            </Tooltip>
          }
        />
      </div>
    );
  }
);

export default EditablePeriod;
