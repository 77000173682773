import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button } from "@ster/ster-toolkit";
import { Dropdown, Space, Tooltip } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { memo, useRef, useState } from "react";

import { OnlinePackageChoice, PackageChoiceEnum } from "../../../api";
import DownloadHandler, { DownloadHandlerRef } from "../DownloadHandler";

export type ButtonType = "primary" | "secondary" | "tertiary" | "outline";

// eslint-disable-next-line no-shadow
export enum EndReportType {
  PowerPoint,
  Excel,
}

export interface BaseButtonOnlineEndReportProps {
  mode: ButtonType;
  orderId: number;
  isReady: boolean;
}

export interface ButonOnlineInterimEndReportProps
  extends BaseButtonOnlineEndReportProps {
  subOrder: {
    id: number;
    packageChoice?: PackageChoiceEnum;
    onlinePackageChoice?: OnlinePackageChoice;
  };
}

export interface ButonOnlineFinalEndReportProps
  extends BaseButtonOnlineEndReportProps {
  subOrders: {
    id: number;
    packageChoice?: PackageChoiceEnum;
    onlinePackageChoice?: OnlinePackageChoice;
  }[];
}

export type ButtonOnlineEndReportProps =
  | ButonOnlineInterimEndReportProps
  | ButonOnlineFinalEndReportProps;

const ButtonOnlineEndReport = memo(
  ({ mode, orderId, isReady, ...props }: ButtonOnlineEndReportProps) => {
    const { i18n } = useLingui();
    const [isDownloading, setIsDownloading] = useState(false);
    const downloadHandlerRef = useRef<DownloadHandlerRef>(null);

    const { subOrders } = props as ButonOnlineFinalEndReportProps;
    const { subOrder } = props as ButonOnlineInterimEndReportProps;

    // Evaluate input
    const subOrdersToEvaluate = subOrder ? [subOrder] : subOrders;

    const packageChoices = subOrdersToEvaluate.map((s) =>
      s.onlinePackageChoice ? s.onlinePackageChoice : s.packageChoice
    );
    const hasDisplay =
      packageChoices.includes(PackageChoiceEnum.Display) ||
      packageChoices.includes(OnlinePackageChoice.Display);
    const hasVideo =
      packageChoices.includes(PackageChoiceEnum.Video) ||
      packageChoices.includes(OnlinePackageChoice.Video);

    const isSubOrderEndReport = subOrder !== undefined;

    const buildReportTypeLabel = (endReportType: EndReportType) => {
      switch (endReportType) {
        case EndReportType.PowerPoint:
          return i18n._(t`PowerPoint`);
        default:
          return i18n._(t`Excel`);
      }
    };

    const buildPackageChoiceLabel = (packageChoice: PackageChoiceEnum) =>
      packageChoice === PackageChoiceEnum.Display
        ? i18n._(t`Display`)
        : i18n._(t`Video`);

    const buildButtonLabel = (
      packageChoice?: PackageChoiceEnum,
      endReportType?: EndReportType
    ) => {
      if (packageChoice === undefined || endReportType === undefined) {
        return isReady
          ? i18n._(t`Eindrapportage downloaden`)
          : i18n._(t`Tussenrapportage downloaden`);
      }

      return isReady
        ? i18n._(
            t`${buildPackageChoiceLabel(packageChoice)} (${buildReportTypeLabel(endReportType)})`
          )
        : i18n._(
            t`${buildPackageChoiceLabel(packageChoice)} (${buildReportTypeLabel(endReportType)})`
          );
    };

    const orderLabel = () =>
      isSubOrderEndReport ? i18n._(t`deelorder`) : i18n._(t`campagne`);

    const buildTooltip = () =>
      isReady
        ? i18n._(t`Download een eindrapportage van deze ${orderLabel()}`)
        : i18n._(t`Download een tussenrapportage van deze ${orderLabel()}`);

    const buildButtonHref = (
      packageChoice: PackageChoiceEnum,
      endReportType: EndReportType
    ) => {
      const reportTypePart =
        endReportType === EndReportType.PowerPoint ? "/pptx" : "";

      const subOrderPart = isSubOrderEndReport
        ? `subOrderId=${subOrder.id}`
        : "";

      return `/api/v1/campaign/endreport/inter/${packageChoice}/${orderId}${reportTypePart}?${subOrderPart}`;
    };

    const handleDownload = async (url: string) => {
      if (downloadHandlerRef.current) {
        setIsDownloading(true);
        downloadHandlerRef.current.beginDownload?.(url).finally(() => {
          setIsDownloading(false);
        });
      }
    };

    const createMenuItem = (
      packageChoice: PackageChoiceEnum,
      endReportType: EndReportType
    ) => ({
      key: `${packageChoice}_${endReportType}`,
      onClick: () => {
        handleDownload(buildButtonHref(packageChoice, endReportType));
      },
      label: buildReportTypeLabel(endReportType),
    });

    const createMenuItemSection = (packageChoice: PackageChoiceEnum) => {
      const displayInGroup = hasVideo && hasDisplay;

      const menuItems = [
        createMenuItem(packageChoice, EndReportType.PowerPoint),
        createMenuItem(packageChoice, EndReportType.Excel),
      ];

      if (!displayInGroup) {
        return menuItems;
      }

      return [
        {
          type: "group",
          label: buildPackageChoiceLabel(packageChoice),
          children: menuItems,
        },
      ];
    };

    const createMenuItems = () => {
      const items = [];
      if (hasDisplay) {
        items.push(...createMenuItemSection(PackageChoiceEnum.Display));
      }
      if (hasDisplay && hasVideo) {
        items.push({
          type: "divider",
        });
      }
      if (hasVideo) {
        items.push(...createMenuItemSection(PackageChoiceEnum.Video));
      }
      return items as ItemType[];
    };

    const menuProps = {
      items: createMenuItems(),
    };

    return (
      (hasDisplay || hasVideo) && (
        <>
          <Tooltip title={buildTooltip()}>
            <Dropdown menu={menuProps} trigger={["click"]}>
              <Button mode={mode} loading={isDownloading}>
                <Space>{buildButtonLabel()}</Space>
              </Button>
            </Dropdown>
          </Tooltip>
          <DownloadHandler ref={downloadHandlerRef} />
        </>
      )
    );
  }
);

export default ButtonOnlineEndReport;
