import { memo } from "react";

import styles from "./SimpleSpinner.module.less";

interface SimpleSpinnerProps {
  className?: string;
}

const SimpleSpinner = memo(({ className }: SimpleSpinnerProps) => (
  <div className={`${styles.simpleSpinner} ${className}`}>
    <div className={styles.ldsEllipsis}>
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
));

export default SimpleSpinner;
