import "../styles.less";
import "./materialcard.less";

import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Icons,
  Tag,
  Tooltip,
  Typography,
  formatDate,
} from "@ster/ster-toolkit";
import { useRef, useState } from "react";

import { Banner } from "../../../../api";
import { getCommercialStatusText, tagStatusMapper } from "../../../../utils";
import BannerPlayer from "../Players/BannerPlayer";
import MaterialBannerModal from "./MaterialBannerModal";
import styles from "./MaterialCardBanner.module.less";
import online from "./online.svg";
import PlayButton from "./PlayButton";

export interface MatericalCardBannerProps {
  cardData: Banner;
  footer?: React.ReactNode;
}

const { EyeIcon } = Icons;

const MaterialCardBanner = ({ cardData, footer }: MatericalCardBannerProps) => {
  const { i18n } = useLingui();

  const { description, creationDate, id, width, height, status } = cardData;
  const { Caption, Footnote, Title } = Typography;

  const formattedCreationDate = creationDate
    ? formatDate(creationDate)
    : undefined;

  const [visible, setVisible] = useState(false);

  const autoSizeComponentRef = useRef<HTMLDivElement | null>(null);

  const openModal = (): void => {
    setVisible(true);
  };

  const closeModal = (): void => {
    setVisible(false);
  };

  return (
    <>
      <article className="card card--material">
        <div
          ref={autoSizeComponentRef}
          className={styles.playButtonAndPreviewContainer}
          style={{ backgroundImage: `url(${online})` }}
        >
          <PlayButton
            className={styles.playButton}
            icon={<EyeIcon />}
            onClick={openModal}
          >
            <BannerPlayer
              banner={cardData}
              autoSizeToComponentRef={autoSizeComponentRef}
            />
          </PlayButton>
        </div>

        <div className="card__data">
          <Tooltip overlayClassName="card__tooltip" title={description}>
            <Title level={3}>
              <span className="card__product-id">{id}: </span>
              {description}
            </Title>
          </Tooltip>
          <div className="card__meta-data">
            {status && (
              <Tag
                status={tagStatusMapper(status)}
                text={i18n._(getCommercialStatusText(status))}
              />
            )}
            <Caption>
              {width} x {height}
            </Caption>
            <Footnote>
              {formattedCreationDate && (
                <Trans>toegevoegd op {formattedCreationDate}</Trans>
              )}
            </Footnote>
          </div>
          {footer}
        </div>
      </article>
      <MaterialBannerModal
        title={
          <Title level={3} className="material-modal__title">
            <span className="material-modal__id">{id}: </span>
            {description}
          </Title>
        }
        open={visible}
        onCancel={closeModal}
        materialBannerData={cardData}
      />
    </>
  );
};

export default MaterialCardBanner;
