/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ClaimValue,
  ClaimValueFromJSON,
  ClaimValueFromJSONTyped,
  ClaimValueToJSON,
  PortalSettings,
  PortalSettingsFromJSON,
  PortalSettingsFromJSONTyped,
  PortalSettingsToJSON,
} from "./";

/**
 *
 * @export
 * @interface SettingsGetResponse
 */
export interface SettingsGetResponse {
  /**
   *
   * @type {PortalSettings}
   * @memberof SettingsGetResponse
   */
  settings: PortalSettings;
  /**
   *
   * @type {Array<ClaimValue>}
   * @memberof SettingsGetResponse
   */
  claims?: Array<ClaimValue> | null;
}

export function SettingsGetResponseFromJSON(json: any): SettingsGetResponse {
  return SettingsGetResponseFromJSONTyped(json, false);
}

export function SettingsGetResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SettingsGetResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    settings: PortalSettingsFromJSON(json["settings"]),
    claims: !exists(json, "claims")
      ? undefined
      : json["claims"] === null
        ? null
        : (json["claims"] as Array<any>).map(ClaimValueFromJSON),
  };
}

export function SettingsGetResponseToJSON(
  value?: SettingsGetResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    settings: PortalSettingsToJSON(value.settings),
    claims:
      value.claims === undefined
        ? undefined
        : value.claims === null
          ? null
          : (value.claims as Array<any>).map(ClaimValueToJSON),
  };
}
