import { createAsyncAction } from "typesafe-actions";

import { ResponseError } from "../base";
import { actionNameAsync } from "../utils";
import { AnalysisNmoNotificationResult } from "./models";

// eslint-disable-next-line import/prefer-default-export
export const getAnalysisNmoNotificationAction = createAsyncAction(
  actionNameAsync("getAnalysisNmoNotification", "REQUEST"),
  actionNameAsync("getAnalysisNmoNotification", "SUCCESS"),
  actionNameAsync("getAnalysisNmoNotification", "FAILURE")
)<undefined, AnalysisNmoNotificationResult, ResponseError>();
