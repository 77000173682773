/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Product,
  ProductFromJSON,
  ProductFromJSONTyped,
  ProductToJSON,
  VolumeDiscount,
  VolumeDiscountFromJSON,
  VolumeDiscountFromJSONTyped,
  VolumeDiscountToJSON,
} from "./";

/**
 *
 * @export
 * @interface Advertiser
 */
export interface Advertiser {
  /**
   *
   * @type {number}
   * @memberof Advertiser
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Advertiser
   */
  name: string;
  /**
   *
   * @type {Array<Product>}
   * @memberof Advertiser
   */
  products?: Array<Product> | null;
  /**
   *
   * @type {boolean}
   * @memberof Advertiser
   */
  isProspect?: boolean;
  /**
   *
   * @type {VolumeDiscount}
   * @memberof Advertiser
   */
  volumeDiscount?: VolumeDiscount;
}

export function AdvertiserFromJSON(json: any): Advertiser {
  return AdvertiserFromJSONTyped(json, false);
}

export function AdvertiserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Advertiser {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    name: json["name"],
    products: !exists(json, "products")
      ? undefined
      : json["products"] === null
        ? null
        : (json["products"] as Array<any>).map(ProductFromJSON),
    isProspect: !exists(json, "isProspect") ? undefined : json["isProspect"],
    volumeDiscount: !exists(json, "volumeDiscount")
      ? undefined
      : VolumeDiscountFromJSON(json["volumeDiscount"]),
  };
}

export function AdvertiserToJSON(value?: Advertiser | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    products:
      value.products === undefined
        ? undefined
        : value.products === null
          ? null
          : (value.products as Array<any>).map(ProductToJSON),
    isProspect: value.isProspect,
    volumeDiscount: VolumeDiscountToJSON(value.volumeDiscount),
  };
}
