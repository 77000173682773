/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Invoice, InvoiceFromJSON, InvoiceToJSON } from "../models";
import * as runtime from "../runtime";

export interface ApiV1InvoiceGetRequest {
  from?: Date;
  to?: Date;
}

/**
 *
 */
export class InvoiceApi extends runtime.BaseAPI {
  /**
   */
  async apiV1InvoiceGetRaw(
    requestParameters: ApiV1InvoiceGetRequest
  ): Promise<runtime.ApiResponse<Array<Invoice>>> {
    const queryParameters: any = {};

    if (requestParameters.from !== undefined) {
      queryParameters["from"] = (requestParameters.from as any).toISOString();
    }

    if (requestParameters.to !== undefined) {
      queryParameters["to"] = (requestParameters.to as any).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Invoice`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(InvoiceFromJSON)
    );
  }

  /**
   */
  async apiV1InvoiceGet(
    requestParameters: ApiV1InvoiceGetRequest
  ): Promise<Array<Invoice>> {
    const response = await this.apiV1InvoiceGetRaw(requestParameters);
    return await response.value();
  }
}
