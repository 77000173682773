import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  ComponentLocalization,
  Form,
  Icons,
  MultiOption,
  RangePicker,
  Row,
  Select,
} from "@ster/ster-toolkit";
import { Alert, Col, Dropdown } from "antd";
import { SelectValue } from "antd/lib/select";
import { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ExportFileType, MediumEnum, SecondaryTargetGroup } from "../../../api";
import {
  receiveCampaignExportAction,
  receiveCampaignSpotsDownloadAction,
} from "../../../store/campaignDetail/actions";
import { DownloadFileType } from "../../../store/campaignDetail/models";
import { StoreModel } from "../../../store/models";
import { RangeValueType, UniqueStrings, getLanguage } from "../../../utils";
import { SchemaFilter, SubOrderFilterItem } from "../models";
import { allColumnKeys, getColumnLabel } from "./columns";
import styles from "./ScheduleFilter.module.less";

export const emptySubOrders: SubOrderFilterItem[] = [];

interface ScheduleFilterProps {
  medium: MediumEnum;
  orderId: number;
  subOrders: SubOrderFilterItem[];
  filter: SchemaFilter;
  onChange: (filter: SchemaFilter) => void;
  targetGroup: string;
  secondaryTargetGroups: SecondaryTargetGroup[];
  showPrices: boolean;
  predictedRatings: boolean;
}

const ScheduleFilter = memo(
  ({
    medium,
    orderId,
    subOrders,
    filter: { subOrderId, dateFrom, dateTo, columns: selectedColumns },
    filter,
    onChange,
    targetGroup,
    secondaryTargetGroups,
    showPrices,
    predictedRatings,
  }: ScheduleFilterProps) => {
    const { i18n } = useLingui();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSubOrderChange = useCallback(
      (value: SelectValue) => {
        if (value === "") {
          // redirect to main order-URL
          navigate(`/campaigns/schedule/${medium}/${orderId}`);
        } else {
          // redirect to selected subOrder-URL
          navigate(`/campaigns/schedule/${medium}/${orderId}/${value}`);
        }
      },
      [medium, navigate, orderId]
    );

    const handleSecondaryTargetGroupChange = useCallback(
      (value: SelectValue) => {
        if (value === "") {
          onChange({ ...filter, secondaryTargetGroup: undefined });
        } else {
          onChange({
            ...filter,
            secondaryTargetGroup: secondaryTargetGroups.find(
              (s) => s.id === Number(value)
            ),
            columns: UniqueStrings([
              ...filter.columns,
              ...allColumnKeys.filter((c) =>
                c.endsWith("SecondaryTargetGroup")
              ),
            ]),
          });
        }
      },
      [filter, onChange, secondaryTargetGroups]
    );

    const handlePeriodChange = useCallback(
      (values: RangeValueType<Date>) => {
        onChange({
          ...filter,
          dateFrom: values && values[0] ? values[0] : filter.dateFrom,
          dateTo: values && values[1] ? values[1] : filter.dateTo,
        });
      },
      [filter, onChange]
    );

    const handleColumnsChange = useCallback(
      (values: SelectValue) => {
        const columns = (values as string[]) ?? [];
        if (columns && columns.length > 0) {
          onChange({
            ...filter,
            columns,
          });
        }
      },
      [filter, onChange]
    );

    const handleDownloadClick = useCallback(
      ({ key }: { key: string }) => {
        switch (key as DownloadFileType) {
          case DownloadFileType.pdf:
            dispatch(
              receiveCampaignSpotsDownloadAction.request({
                orderId,
                subOrderId,
                fileType: key as DownloadFileType,
              })
            );

            break;
          case DownloadFileType.xls:
            dispatch(
              receiveCampaignExportAction.request({
                medium,
                exportOutputSettings: {
                  orderId,
                  subOrderId,
                  dateFrom: filter.dateFrom,
                  dateTo: filter.dateTo,
                  fileType: ExportFileType.Xlsx,
                  targetGroup: filter.secondaryTargetGroup?.code ?? targetGroup,
                  columns: allColumnKeys.filter((c) =>
                    filter.columns.includes(c)
                  ),
                },
              })
            );

            break;
          case DownloadFileType.csv:
            dispatch(
              receiveCampaignExportAction.request({
                medium,
                exportOutputSettings: {
                  orderId,
                  subOrderId,
                  dateFrom: filter.dateFrom,
                  dateTo: filter.dateTo,
                  fileType: ExportFileType.Csv,
                  targetGroup: filter.secondaryTargetGroup?.code ?? targetGroup,
                  columns: allColumnKeys.filter((c) =>
                    filter.columns.includes(c)
                  ),
                },
              })
            );

            break;
          default:
            break;
        }
      },
      [
        dispatch,
        filter.columns,
        filter.dateFrom,
        filter.dateTo,
        filter.secondaryTargetGroup,
        medium,
        orderId,
        subOrderId,
        targetGroup,
      ]
    );

    const downloading = useSelector(
      (store: StoreModel) =>
        store.campaignSpotsDownload.loading ||
        store.campaignExport.loading ||
        false
    );

    return (
      <Form className={styles.filters}>
        <Row justify="center" align="middle" gutter={[24, 0]}>
          <Col flex="3">
            <Form.Item label={i18n._(t`Uitzendschema van`)}>
              <Select
                placeholder={i18n._(t`Kies een deelorder`)}
                value={subOrderId ?? ""}
                options={[
                  { label: i18n._(t`Volledige order`), value: "" },
                  ...subOrders,
                ]}
                onChange={handleSubOrderChange}
                disabled={subOrders.length <= 1}
                popupMatchSelectWidth={false}
              />
            </Form.Item>
          </Col>
          <Col flex="3">
            <Form.Item label={i18n._(t`Periode`)}>
              <RangePicker
                allowClear={false}
                onChange={handlePeriodChange}
                value={[dateFrom, dateTo]}
                className={styles.date}
                componentLocale={getLanguage() as ComponentLocalization}
              />
            </Form.Item>
          </Col>
          <Col flex="1">
            <Form.Item label={i18n._(t`Sec. doelgroep`)}>
              <Select.Search
                placeholder={i18n._(t`Kies een doelgroep`)}
                onChange={handleSecondaryTargetGroupChange}
                optionFilterProp="label"
                options={[
                  {
                    label: i18n._(t`Geen sec. doelgroep`),
                    value: "",
                  },
                  ...secondaryTargetGroups.map(({ id, displayName }) => ({
                    label: displayName,
                    value: `${id}`,
                  })),
                ]}
                disabled={secondaryTargetGroups.length === 0}
                className={styles.secondaryTargetGroup}
              />
            </Form.Item>
          </Col>
          <Col flex="3">
            <Form.Item label={i18n._(t`Toon/verberg kolommen`)}>
              <Select.Multi
                placeholder={i18n._(t`Kies kolommen`)}
                onChange={handleColumnsChange}
                value={selectedColumns}
                style={{ maxWidth: 320 }}
              >
                {allColumnKeys.map((c) => (
                  <MultiOption
                    key={c}
                    value={c}
                    title={getColumnLabel(c, i18n, predictedRatings)}
                  />
                ))}
              </Select.Multi>
            </Form.Item>
          </Col>
          <Col flex="1">
            <Form.Item label={i18n._(t`Download overzicht`)}>
              <Dropdown
                menu={{
                  items: [
                    ...(showPrices
                      ? [
                          {
                            label: <Trans>PDF-bestand</Trans>,
                            key: DownloadFileType.pdf,
                            className: "more-menu-item",
                            onClick: handleDownloadClick,
                          },
                        ]
                      : []),
                    ...(showPrices
                      ? [
                          {
                            label: <Trans>Excel bestand</Trans>,
                            key: DownloadFileType.xls,
                            className: "more-menu-item",
                            onClick: handleDownloadClick,
                          },
                        ]
                      : []),
                    {
                      label: <Trans>CSV-bestand</Trans>,
                      key: DownloadFileType.csv,
                      className: "more-menu-item",
                      onClick: handleDownloadClick,
                    },
                  ],
                }}
              >
                <Button mode="secondary" loading={downloading}>
                  <Trans>Download als</Trans>{" "}
                  <Icons.ChevronDownIcon fill="white" width={16} height={16} />
                </Button>
              </Dropdown>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center" align="middle" gutter={[24, 24]}>
          <Col>
            {filter.secondaryTargetGroup &&
              !filter.columns.some((c) =>
                c.endsWith("SecondaryTargetGroup")
              ) && (
                <Alert
                  type="info"
                  showIcon
                  message={
                    <Trans>
                      Om de prognoses en realisaties van de gekozen secundaire
                      doelgroep te tonen, dien je nog wel de betreffende
                      kolommen toe te voegen.
                    </Trans>
                  }
                />
              )}
          </Col>
        </Row>
      </Form>
    );
  }
);

export default ScheduleFilter;
