import { CommercialInstructionSpots } from "../../../api";

export const validateLandingsPage = (
  url: string | undefined | null
): boolean => {
  // Landingspagina is verplicht en mag geen spaties bevatten
  if (!url || url.includes(" ")) {
    return false;
  }

  // url's dienen met https:// te beginnen
  return /^https:\/\/([^/])/.test(url);
};

export const emptyOnlineCommercialSelection: CommercialInstructionSpots[] = [
  {
    id: 0,
    commercial: undefined,
  },
];

export const emptyOnlineSpotsPerPeriod = [
  { id: 0, instructions: emptyOnlineCommercialSelection },
];
