/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum CommercialInstructionStatus {
  Concept = "Concept",
  InBehandeling = "InBehandeling",
  Behandeld = "Behandeld",
  Incorrect = "Incorrect",
}

export function CommercialInstructionStatusFromJSON(
  json: any
): CommercialInstructionStatus {
  return CommercialInstructionStatusFromJSONTyped(json, false);
}

export function CommercialInstructionStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CommercialInstructionStatus {
  return json as CommercialInstructionStatus;
}

export function CommercialInstructionStatusToJSON(
  value?: CommercialInstructionStatus | null
): any {
  return value as any;
}
