import classNames from "classnames";
import React, { useCallback, useState } from "react";

import styles from "./Ribbon.module.less";

type RibbonPosition = "top" | "bottom";

const Ribbon = ({ text }: { text: string }) => {
  const [position, setPosition] = useState<RibbonPosition>("top");
  const togglePosition = useCallback(() => {
    setPosition((prev) => (prev === "top" ? "bottom" : "top"));
  }, []);

  return (
    <div
      className={classNames(styles.ribbonContainer, {
        [styles.bottom]: position === "bottom",
      })}
      onMouseEnter={togglePosition}
    >
      <div className={styles.ribbon}>{text}</div>
    </div>
  );
};

export default Ribbon;
