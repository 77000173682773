/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  AppInsights,
  AppInsightsFromJSON,
  AppInsightsFromJSONTyped,
  AppInsightsToJSON,
  GoogleTagManager,
  GoogleTagManagerFromJSON,
  GoogleTagManagerFromJSONTyped,
  GoogleTagManagerToJSON,
  PortalSettingsDisableEditCampaignFields,
  PortalSettingsDisableEditCampaignFieldsFromJSON,
  PortalSettingsDisableEditCampaignFieldsFromJSONTyped,
  PortalSettingsDisableEditCampaignFieldsToJSON,
  PortalSettingsEnableEditCampaign,
  PortalSettingsEnableEditCampaignFromJSON,
  PortalSettingsEnableEditCampaignFromJSONTyped,
  PortalSettingsEnableEditCampaignToJSON,
  TenantEnum,
  TenantEnumFromJSON,
  TenantEnumFromJSONTyped,
  TenantEnumToJSON,
} from "./";

/**
 *
 * @export
 * @interface PortalSettings
 */
export interface PortalSettings {
  /**
   *
   * @type {TenantEnum}
   * @memberof PortalSettings
   */
  tenant?: TenantEnum;
  /**
   *
   * @type {PortalSettingsDisableEditCampaignFields}
   * @memberof PortalSettings
   */
  disableEditCampaignFields?: PortalSettingsDisableEditCampaignFields | null;
  /**
   *
   * @type {boolean}
   * @memberof PortalSettings
   */
  enableInitialRequests: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PortalSettings
   */
  enableTerms: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PortalSettings
   */
  enableConcept: boolean;
  /**
   *
   * @type {Date}
   * @memberof PortalSettings
   */
  termsDate: Date;
  /**
   *
   * @type {boolean}
   * @memberof PortalSettings
   */
  enableTop2000Warning: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PortalSettings
   */
  enableDigitalPrograms: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PortalSettings
   */
  enableDashboardCalendar: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PortalSettings
   */
  enableDashboardSpots: boolean;
  /**
   *
   * @type {number}
   * @memberof PortalSettings
   */
  monthsInThePast: number;
  /**
   *
   * @type {number}
   * @memberof PortalSettings
   */
  monthsInTheFuture: number;
  /**
   *
   * @type {number}
   * @memberof PortalSettings
   */
  materialMonthsInThePast: number;
  /**
   *
   * @type {number}
   * @memberof PortalSettings
   */
  materialMonthsInTheFuture: number;
  /**
   *
   * @type {boolean}
   * @memberof PortalSettings
   */
  enableNewCommercialsForRotations: boolean;
  /**
   *
   * @type {number}
   * @memberof PortalSettings
   */
  maxSalesperiodsOnDashboard: number;
  /**
   *
   * @type {boolean}
   * @memberof PortalSettings
   */
  enableGenerateProposal: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PortalSettings
   */
  enableInstructionsFromRequest: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PortalSettings
   */
  enableChat: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PortalSettings
   */
  enableForward: boolean;
  /**
   *
   * @type {string}
   * @memberof PortalSettings
   */
  chatBaseUrl: string;
  /**
   *
   * @type {string}
   * @memberof PortalSettings
   */
  chatToken: string;
  /**
   *
   * @type {boolean}
   * @memberof PortalSettings
   */
  enableTenantSwitching: boolean;
  /**
   *
   * @type {PortalSettingsEnableEditCampaign}
   * @memberof PortalSettings
   */
  enableEditCampaign: PortalSettingsEnableEditCampaign;
  /**
   *
   * @type {PortalSettingsEnableEditCampaign}
   * @memberof PortalSettings
   */
  enableCampaignAnalysis: PortalSettingsEnableEditCampaign;
  /**
   *
   * @type {boolean}
   * @memberof PortalSettings
   */
  enableCampaignAnalysisNmoNotification: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PortalSettings
   */
  enableAnalysis: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PortalSettings
   */
  showDigitalReportWarning: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PortalSettings
   */
  showDigitalMarketindexWarning: boolean;
  /**
   *
   * @type {AppInsights}
   * @memberof PortalSettings
   */
  appInsights: AppInsights;
  /**
   *
   * @type {GoogleTagManager}
   * @memberof PortalSettings
   */
  google: GoogleTagManager;
  /**
   *
   * @type {boolean}
   * @memberof PortalSettings
   */
  crmEnabled: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PortalSettings
   */
  budgetOrBreakSelectionChoiceEnabled: boolean;
  /**
   *
   * @type {Date}
   * @memberof PortalSettings
   */
  nmoSwitchDate: Date;
}

export function PortalSettingsFromJSON(json: any): PortalSettings {
  return PortalSettingsFromJSONTyped(json, false);
}

export function PortalSettingsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PortalSettings {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    tenant: !exists(json, "tenant")
      ? undefined
      : TenantEnumFromJSON(json["tenant"]),
    disableEditCampaignFields: !exists(json, "disableEditCampaignFields")
      ? undefined
      : PortalSettingsDisableEditCampaignFieldsFromJSON(
          json["disableEditCampaignFields"]
        ),
    enableInitialRequests: json["enableInitialRequests"],
    enableTerms: json["enableTerms"],
    enableConcept: json["enableConcept"],
    termsDate: new Date(json["termsDate"]),
    enableTop2000Warning: json["enableTop2000Warning"],
    enableDigitalPrograms: json["enableDigitalPrograms"],
    enableDashboardCalendar: json["enableDashboardCalendar"],
    enableDashboardSpots: json["enableDashboardSpots"],
    monthsInThePast: json["monthsInThePast"],
    monthsInTheFuture: json["monthsInTheFuture"],
    materialMonthsInThePast: json["materialMonthsInThePast"],
    materialMonthsInTheFuture: json["materialMonthsInTheFuture"],
    enableNewCommercialsForRotations: json["enableNewCommercialsForRotations"],
    maxSalesperiodsOnDashboard: json["maxSalesperiodsOnDashboard"],
    enableGenerateProposal: json["enableGenerateProposal"],
    enableInstructionsFromRequest: json["enableInstructionsFromRequest"],
    enableChat: json["enableChat"],
    enableForward: json["enableForward"],
    chatBaseUrl: json["chatBaseUrl"],
    chatToken: json["chatToken"],
    enableTenantSwitching: json["enableTenantSwitching"],
    enableEditCampaign: PortalSettingsEnableEditCampaignFromJSON(
      json["enableEditCampaign"]
    ),
    enableCampaignAnalysis: PortalSettingsEnableEditCampaignFromJSON(
      json["enableCampaignAnalysis"]
    ),
    enableCampaignAnalysisNmoNotification:
      json["enableCampaignAnalysisNmoNotification"],
    enableAnalysis: json["enableAnalysis"],
    showDigitalReportWarning: json["showDigitalReportWarning"],
    showDigitalMarketindexWarning: json["showDigitalMarketindexWarning"],
    appInsights: AppInsightsFromJSON(json["appInsights"]),
    google: GoogleTagManagerFromJSON(json["google"]),
    crmEnabled: json["crmEnabled"],
    budgetOrBreakSelectionChoiceEnabled:
      json["budgetOrBreakSelectionChoiceEnabled"],
    nmoSwitchDate: new Date(json["nmoSwitchDate"]),
  };
}

export function PortalSettingsToJSON(value?: PortalSettings | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    tenant: TenantEnumToJSON(value.tenant),
    disableEditCampaignFields: PortalSettingsDisableEditCampaignFieldsToJSON(
      value.disableEditCampaignFields
    ),
    enableInitialRequests: value.enableInitialRequests,
    enableTerms: value.enableTerms,
    enableConcept: value.enableConcept,
    termsDate: value.termsDate.toISOString(),
    enableTop2000Warning: value.enableTop2000Warning,
    enableDigitalPrograms: value.enableDigitalPrograms,
    enableDashboardCalendar: value.enableDashboardCalendar,
    enableDashboardSpots: value.enableDashboardSpots,
    monthsInThePast: value.monthsInThePast,
    monthsInTheFuture: value.monthsInTheFuture,
    materialMonthsInThePast: value.materialMonthsInThePast,
    materialMonthsInTheFuture: value.materialMonthsInTheFuture,
    enableNewCommercialsForRotations: value.enableNewCommercialsForRotations,
    maxSalesperiodsOnDashboard: value.maxSalesperiodsOnDashboard,
    enableGenerateProposal: value.enableGenerateProposal,
    enableInstructionsFromRequest: value.enableInstructionsFromRequest,
    enableChat: value.enableChat,
    enableForward: value.enableForward,
    chatBaseUrl: value.chatBaseUrl,
    chatToken: value.chatToken,
    enableTenantSwitching: value.enableTenantSwitching,
    enableEditCampaign: PortalSettingsEnableEditCampaignToJSON(
      value.enableEditCampaign
    ),
    enableCampaignAnalysis: PortalSettingsEnableEditCampaignToJSON(
      value.enableCampaignAnalysis
    ),
    enableCampaignAnalysisNmoNotification:
      value.enableCampaignAnalysisNmoNotification,
    enableAnalysis: value.enableAnalysis,
    showDigitalReportWarning: value.showDigitalReportWarning,
    showDigitalMarketindexWarning: value.showDigitalMarketindexWarning,
    appInsights: AppInsightsToJSON(value.appInsights),
    google: GoogleTagManagerToJSON(value.google),
    crmEnabled: value.crmEnabled,
    budgetOrBreakSelectionChoiceEnabled:
      value.budgetOrBreakSelectionChoiceEnabled,
    nmoSwitchDate: value.nmoSwitchDate.toISOString(),
  };
}
