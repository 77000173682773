import { LaptopOutlined, PlaySquareOutlined } from "@ant-design/icons";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Icons, Row } from "@ster/ster-toolkit";
import { Alert, Col } from "antd";
import { memo } from "react";
import { useSelector } from "react-redux";

import { MediumEnum, PackageChoiceEnum, SalesPeriod } from "../../api";
import { ReduxStoreState } from "../../store/base";
import PackageCard from "../partials/Cards/Package/PackageCard";
import styles from "./PackageCards.module.less";
import { packageCardSelector } from "./selectors";

interface PackageCardsProps {
  medium: MediumEnum;
  salesPeriod: SalesPeriod;
  onAddNewSubOrderByType: (type: PackageChoiceEnum) => void;
}

const PackageCards = memo(
  ({ medium, onAddNewSubOrderByType, salesPeriod }: PackageCardsProps) => {
    const { i18n } = useLingui();
    const { packages, packagesState } = useSelector(packageCardSelector);

    return (
      <Row className={styles.packageSelection} gutter={[0, 24]}>
        {(packagesState === ReduxStoreState.Success ||
          packagesState === ReduxStoreState.Failure) &&
          packages.length === 0 && (
            <Col>
              <Alert
                showIcon
                type="warning"
                message=""
                description={i18n._(
                  t`Er zijn geen pakketten beschikbaar voor de gekozen periode.`
                )}
              />
            </Col>
          )}
        {packages.find((p) => p.type === PackageChoiceEnum.Grps) && (
          <Col>
            <PackageCard
              icon={<Icons.SmileyIcon />}
              title={i18n._(t`Inkopen met GRP's`)}
              text={
                <Trans>
                  Koop in op basis van GRP’s. Prijzen worden berekend op basis
                  van{" "}
                  <a
                    href="https://www.ster.nl/tarieven-npo-reclame-en-adverteren/"
                    target="_blank"
                    rel="noopener nofollow noreferrer"
                  >
                    onze actuele indexprijzen.
                  </a>
                </Trans>
              }
              mostPicked
              button={{
                label: i18n._(t`Inkopen met GRP's`),
                onClick: () => onAddNewSubOrderByType(PackageChoiceEnum.Grps),
              }}
            />
          </Col>
        )}
        {packages.find((p) => p.type === PackageChoiceEnum.FixedCosts) && (
          <Col>
            <PackageCard
              icon={<Icons.HourglassIcon />}
              title={i18n._(t`Vaste kosten per spot`)}
              text={
                <Trans>
                  Iedere maand vind je hier het{" "}
                  <a
                    href="https://www.ster.nl/tarieven-npo-reclame-en-adverteren/"
                    target="_blank"
                    rel="noopener nofollow noreferrer"
                  >
                    overzicht van tarieven.
                  </a>{" "}
                  Weet wat je betaalt voor {medium === "radio" ? 20 : 30}{" "}
                  seconden per zender, dag en blok.
                </Trans>
              }
              button={{
                label: i18n._(t`Inkopen met vaste kosten`),
                onClick: () =>
                  onAddNewSubOrderByType(PackageChoiceEnum.FixedCosts),
              }}
            />
          </Col>
        )}
        {packages.find((p) => p.type === PackageChoiceEnum.FixedCostsCult) &&
          (salesPeriod.salesPeriodStartDate.getFullYear() >= 2025 ? (
            <Col>
              <PackageCard
                icon={<Icons.EldersIcon fill="#FF6D00" height="40" />}
                title={i18n._(t`Instappakket Cultuur Specifiek`)}
                text={
                  <Trans>
                    Specifieke inkoop met 65% korting. <br />
                    Culturele reclameblokken die openen en sluiten met een
                    Loeki-filmpje dat speciaal is gemaakt voor deze inkoopoptie.
                  </Trans>
                }
                button={{
                  label: i18n._(t`Inkopen met Ster & Cultuur`),
                  onClick: () =>
                    onAddNewSubOrderByType(PackageChoiceEnum.FixedCostsCult),
                }}
              />
            </Col>
          ) : (
            <Col>
              <PackageCard
                icon={<Icons.EldersIcon fill="#FF6D00" height="40" />}
                title={i18n._(t`Ster & Cultuur`)}
                text={
                  <Trans>
                    Specifieke inkoop met 60% korting. Per week circa 21 blokken
                    met een netto tarief van circa €6.000 (excl. maandindex)
                  </Trans>
                }
                button={{
                  label: i18n._(t`Inkopen met Ster & Cultuur`),
                  onClick: () =>
                    onAddNewSubOrderByType(PackageChoiceEnum.FixedCostsCult),
                }}
              />
            </Col>
          ))}
        {packages.find((p) => p.type === PackageChoiceEnum.NpoPromo) && (
          <Col>
            <PackageCard
              icon={
                <Icons.CampaignIcon width={40} height={40} fill="#FF6D00" />
              }
              title={i18n._(t`NPO Promo Frequency`)}
              text=""
              button={{
                label: i18n._(t`Inkopen met NPO Promo`),
                onClick: () =>
                  onAddNewSubOrderByType(PackageChoiceEnum.NpoPromo),
              }}
            />
          </Col>
        )}
        {packages.find((p) => p.type === PackageChoiceEnum.Display) && (
          <Col>
            <PackageCard
              icon={<LaptopOutlined className={styles.icon} />}
              title={i18n._(t`Display`)}
              text={i18n._(
                t`Koop banners in op sites en/of apps. Exclusieve zichtbaarheid doordat we slechts één banner op een pagina te tonen.`
              )}
              button={{
                label: i18n._(t`Inkopen op display`),
                onClick: () =>
                  onAddNewSubOrderByType(PackageChoiceEnum.Display),
              }}
            />
          </Col>
        )}
        {packages.find((p) => p.type === PackageChoiceEnum.Video) && (
          <Col>
            <PackageCard
              icon={<PlaySquareOutlined className={styles.icon} />}
              title={i18n._(t`Video`)}
              text={i18n._(
                t`Non-skippable pre-rolls van verschillende lengtes met een maximale lengte van 36 seconden.`
              )}
              button={{
                label: i18n._(t`Inkopen op video`),
                onClick: () => onAddNewSubOrderByType(PackageChoiceEnum.Video),
              }}
            />
          </Col>
        )}
      </Row>
    );
  }
);

export default PackageCards;
