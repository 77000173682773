import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form, Select } from "@ster/ster-toolkit";
import { Alert, Checkbox, Typography } from "antd";
import { memo, useCallback } from "react";

import { PackageChoiceEnum, PackageType, UpdateFields } from "../../../api";
import { getPopupContainerSubOrders } from "../../../utils";
import { getFormattedDate } from "../../../utils/dateHelper";
import { FormReadOnlyItem, formItemLayout } from "../../partials/Form";
import { OnlineSubOrderEditFormProps } from "./models";
import styles from "./OnlineSubOrder.module.less";
import PeriodSelectOnline from "./partials/PeriodSelectOnline";

/**
 * Wijzigen van een online deelorder in een bestaande campagne.
 */
const OnlineSubOrderEditForm = memo(
  ({
    subOrder,
    packageChoice,
    handleFinishFailed,
    handleValuesChange,
    form,
    selectedPackage,
    selectedSalesPeriod,
  }: OnlineSubOrderEditFormProps) => {
    const { i18n } = useLingui();

    const canUpdateField = useCallback(
      (updateField: UpdateFields) =>
        subOrder.editableFields?.includes(updateField) ?? false,
      [subOrder.editableFields]
    );

    return (
      <Form
        {...formItemLayout}
        onValuesChange={handleValuesChange}
        onFinishFailed={handleFinishFailed}
        form={form}
        name={`suborder-${subOrder.id}`}
        wrapperCol={{ span: 12 }}
      >
        <FormReadOnlyItem
          label={i18n._(t`Campagnenaam`)}
          value={subOrder.campaignName}
          className={styles.readOnly}
        />

        {canUpdateField(UpdateFields.StartDate) ||
        canUpdateField(UpdateFields.EndDate) ? (
          <>
            <Form.Item label={i18n._(t`Periode`)} shouldUpdate>
              {({ getFieldValue }) => {
                const useTime = getFieldValue("useTime");
                return (
                  <>
                    <Form.Item
                      name="period"
                      rules={[
                        {
                          required: true,
                          message: i18n._(t`Kies een periode`),
                        },
                      ]}
                      noStyle
                    >
                      <PeriodSelectOnline
                        period={{
                          from: selectedSalesPeriod.salesPeriodStartDate,
                          to: selectedSalesPeriod.salesPeriodEndDate,
                        }}
                        useTime={useTime}
                        disabled={[
                          !canUpdateField(UpdateFields.StartDate),
                          !canUpdateField(UpdateFields.EndDate),
                        ]}
                        allowClear={{ clearIcon: false }}
                      />
                    </Form.Item>
                    {useTime && (
                      <>
                        <br />
                        <Typography.Text>
                          {i18n._(
                            t`Vergeet niet een start- en eindtijd op te geven.`
                          )}
                        </Typography.Text>
                      </>
                    )}
                  </>
                );
              }}
            </Form.Item>

            <Form.Item
              label={i18n._(t`Start-/eindtijd`)}
              name="useTime"
              valuePropName="checked"
              helpText={i18n._(
                t`Zodra dit aan staat, kun je in bovenstaande periode veld een start- en eindtijd opgeven voor de gehele periode.`
              )}
            >
              <Checkbox />
            </Form.Item>
          </>
        ) : (
          <FormReadOnlyItem
            label={i18n._(t`Periode`)}
            value={`${getFormattedDate(subOrder.period?.from, "d MMM yyyy")} -
            ${getFormattedDate(subOrder.period?.to, "d MMM yyyy")}`}
            className={styles.readOnly}
          />
        )}

        <FormReadOnlyItem
          label={i18n._(t`Pakket`)}
          value={subOrder._package?.name}
          className={styles.readOnly}
        />

        {packageChoice === PackageChoiceEnum.Video &&
          (canUpdateField(UpdateFields.SpotLength) ? (
            <Form.Item
              label={i18n._(t`Spotlengte`)}
              name="videoLength"
              rules={[
                {
                  required: true,
                  message: i18n._(t`Kies een spotlengte`),
                },
              ]}
            >
              <Select
                placeholder={i18n._(t`Kies een spotlengte`)}
                options={selectedPackage?.internetFormat?.map((s) => ({
                  label: `${s.spotLength}"`,
                  value: s.spotLength ?? "",
                }))}
                disabled={!selectedPackage}
                getPopupContainer={getPopupContainerSubOrders}
                className={styles.spotlength}
              />
            </Form.Item>
          ) : (
            <FormReadOnlyItem
              label={i18n._(t`Spotlengte`)}
              value={subOrder.spotLength?.join("+ ")}
            />
          ))}

        {selectedPackage?.type === PackageType.HouseDisplay && (
          <div className={styles.houseDisplayAlert}>
            <Alert
              showIcon
              type="warning"
              message=""
              description={
                <Trans>
                  Voor de inzet van gratis banners op het NPO Netwerk: Graag
                  eerst zelf afstemmen met de andere omroepen of het ok is om de
                  banner hier uit te serveren. Je eigen domein is natuurlijk
                  geen probleem.
                </Trans>
              }
            />
          </div>
        )}
      </Form>
    );
  }
);

export default OnlineSubOrderEditForm;
