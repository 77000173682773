import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Modal, Spinner, Typography } from "@ster/ster-toolkit";
import { Carousel } from "antd";
import { CarouselRef } from "antd/lib/carousel";
import classNames from "classnames";
import {
  ReactElement,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useDispatch } from "react-redux";

import { postProductTourAction } from "../../store/productTour/actions";
import { getLanguage } from "../../utils";
import { minDate } from "../../utils/constants";
import styles from "./ProductTourModal.module.less";
import Tour1EN from "./videos/en/1.mp4";
import Tour2EN from "./videos/en/2.mp4";
import Tour3EN from "./videos/en/3.mp4";
import Tour4EN from "./videos/en/4.mp4";
import Tour5EN from "./videos/en/5.mp4";
import Tour6EN from "./videos/en/6.mp4";
import Tour1NL from "./videos/nl/1.mp4";
import Tour2NL from "./videos/nl/2.mp4";
import Tour3NL from "./videos/nl/3.mp4";
import Tour4NL from "./videos/nl/4.mp4";
import Tour5NL from "./videos/nl/5.mp4";
import Tour6NL from "./videos/nl/6.mp4";

interface ProductTourStep {
  title: string;
  image: string;
  description: ReactElement;
  button: ReactElement;
}

const ProductTourModal = memo(
  ({
    lastStep,
    lastLogin,
    visible,
    setVisible,
  }: {
    lastStep: number;
    lastLogin?: Date | null;
    visible: boolean;
    setVisible: (show: boolean) => void;
  }) => {
    const dispatch = useDispatch();
    const { i18n } = useLingui();

    const isDutch = useMemo(() => getLanguage() === "nl", []);

    const steps: ProductTourStep[] = useMemo(
      () => [
        {
          title: i18n._(t`Welkom`),
          image: isDutch ? Tour1NL : Tour1EN,
          description: (
            <Trans>
              Welkom bij de Ster Klantportal! Onze gebruiksvriendelijke
              campagnetool.
              <br /> We laten je graag kort zien wat je er allemaal mogelijk is
              en hoe het werkt.
            </Trans>
          ),
          button: <>{i18n._(t`Aan de slag`)} ➝</>,
        },
        {
          title: i18n._(t`Wij staan voor je klaar`),
          image: isDutch ? Tour2NL : Tour2EN,
          description: (
            <Trans>
              Wat je ook gaat doen in onze Ster Klantportal: onze planners staan
              voor je klaar.
              <br /> Via de chatfunctie kun je met al je vragen bij hen terecht.
            </Trans>
          ),
          button: <>{i18n._(t`Volgende`)} ➝</>,
        },
        {
          title: i18n._(t`Campagnes: aanvragen en prognoses`),
          image: isDutch ? Tour3NL : Tour3EN,
          description: (
            <Trans>
              Je kan in de Ster Klantportal zelf campagnes aanmaken voor
              televisie, radio en/of online. Op basis van de door jou ingevulde
              informatie, ontvang je (op basis van machine learning) direct een
              bereik prognose.
            </Trans>
          ),
          button: <>{i18n._(t`Volgende`)} ➝</>,
        },
        {
          title: i18n._(t`Tussentijds opslaan`),
          image: isDutch ? Tour4NL : Tour4EN,
          description: (
            <Trans>
              Wil je de campagne nog niet direct indienen? Kies dan voor de
              optie om het voorstel aan jezelf te sturen. Je ontvangt je
              voorstel per e-mail en in het voorstel vind je een campagnecode.
              Met deze campagnecode kun je op een later moment jouw eerder
              samengestelde voorstel makkelijk terugvinden in de Ster
              Klantportal, zodat je het alsnog kunt indienen of kunt wijzigen.
            </Trans>
          ),
          button: <>{i18n._(t`Volgende`)}</>,
        },
        {
          title: i18n._(t`Overzicht van jouw campagnes`),
          image: isDutch ? Tour5NL : Tour5EN,
          description: (
            <Trans>
              Onder het menu “Campagnes” heb je inzicht in al jouw campagnes:
              lopende campagnes of campagnes uit het verleden.
              <br />
              <br />
              Na afloop van je campagne kun je onder dit menu ook de resultaten
              van jouw campagne opvragen. Dan zie je onder andere welk bereik er
              is gerealiseerd en hoe vaak je jouw doelgroep bereikt hebt met je
              boodschap.
            </Trans>
          ),
          button: <>{i18n._(t`Volgende`)} ➝</>,
        },
        {
          title: i18n._(t`24/7 inzicht`),
          image: isDutch ? Tour6NL : Tour6EN,
          description: (
            <Trans>
              Van uitzendschema tot campagne-overzicht, van facturen tot het
              direct aanvragen van campagnes: de Ster Klantportal biedt het
              allemaal. In je account is er ook nog een persoonlijke bibliotheek
              beschikbaar waarin alle eerdere campagnes en materiaal terug te
              vinden zijn.
            </Trans>
          ),
          button: <>{i18n._(t`Naar de Ster Klantportal`)}</>,
        },
      ],
      [i18n, isDutch]
    );

    const carouselRef = useRef<CarouselRef | null>();

    const onFinish = useCallback(() => {
      setVisible(false);
      dispatch(
        postProductTourAction.request({
          productTourModel: {
            lastStep: steps.length,
            lastStepViewed:
              (carouselRef.current?.innerSlider.state.currentSlide ?? 0) + 1,
          },
        })
      );
    }, [dispatch, setVisible, steps.length]);

    const goToNext = useCallback(() => {
      if (
        (carouselRef.current?.innerSlider.state.currentSlide ?? 0) + 1 ===
        steps.length
      ) {
        onFinish();
      } else {
        carouselRef.current?.next();
      }
    }, [onFinish, steps.length]);

    const firstLogin = useMemo(
      () => lastLogin?.getTime() === minDate.getTime(),
      [lastLogin]
    );

    useEffect(() => {
      //  We tonen de producttour alleen voor nieuwe gebruikers
      if (firstLogin && lastStep === 0) {
        setVisible(true);
      }
    }, [firstLogin, lastStep, setVisible, steps]);

    return (
      <Modal
        forceRender
        className={styles.tourModal}
        footer={null /* remove default buttons */}
        onCancel={onFinish}
        maskClosable={false}
        open={visible}
      >
        <Carousel
          lazyLoad="progressive"
          className={styles.tour}
          ref={(node) => {
            carouselRef.current = node;
          }}
        >
          {steps.map((s) => (
            <div className={styles.tourStep} key={s.title}>
              <Typography.Title level={1}>{s.title}</Typography.Title>
              <div className={styles.image}>
                <Spinner spinning />
                {visible && (
                  <video src={s.image} controls={false} autoPlay muted loop />
                )}
              </div>
              <div className={styles.text}>
                <Typography.Paragraph>{s.description}</Typography.Paragraph>
                <Button mode="primary" onClick={goToNext}>
                  {s.button}
                </Button>
              </div>
            </div>
          ))}
        </Carousel>
        <button
          type="button"
          className={classNames(styles.skip, "link")}
          onClick={onFinish}
        >
          <Trans>Uitleg overslaan</Trans>
        </button>
      </Modal>
    );
  }
);

export default ProductTourModal;
