/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  CampaignConfiguration,
  CampaignConfigurationFromJSON,
  CampaignConfigurationFromJSONTyped,
  CampaignConfigurationToJSON,
  CampaignForwardedStatus,
  CampaignForwardedStatusFromJSON,
  CampaignForwardedStatusFromJSONTyped,
  CampaignForwardedStatusToJSON,
  Package,
  PackageFromJSON,
  PackageFromJSONTyped,
  PackageToJSON,
} from "./";

/**
 *
 * @export
 * @interface CampaignForwarded
 */
export interface CampaignForwarded {
  /**
   *
   * @type {number}
   * @memberof CampaignForwarded
   */
  id?: number | null;
  /**
   *
   * @type {number}
   * @memberof CampaignForwarded
   */
  campaignConfigurationId: number;
  /**
   *
   * @type {CampaignForwardedStatus}
   * @memberof CampaignForwarded
   */
  status: CampaignForwardedStatus;
  /**
   *
   * @type {Date}
   * @memberof CampaignForwarded
   */
  modified: Date;
  /**
   *
   * @type {Date}
   * @memberof CampaignForwarded
   */
  forwardedOn: Date;
  /**
   *
   * @type {string}
   * @memberof CampaignForwarded
   */
  forwardedBy: string;
  /**
   *
   * @type {string}
   * @memberof CampaignForwarded
   */
  forwardedTo: string;
  /**
   *
   * @type {string}
   * @memberof CampaignForwarded
   */
  organisationCode: string;
  /**
   *
   * @type {number}
   * @memberof CampaignForwarded
   */
  advertiserId: number;
  /**
   *
   * @type {number}
   * @memberof CampaignForwarded
   */
  productId: number;
  /**
   *
   * @type {string}
   * @memberof CampaignForwarded
   */
  readonly advertiserName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CampaignForwarded
   */
  readonly productDescr?: string | null;
  /**
   *
   * @type {Package}
   * @memberof CampaignForwarded
   */
  _package?: Package;
  /**
   *
   * @type {CampaignConfiguration}
   * @memberof CampaignForwarded
   */
  campaignConfiguration?: CampaignConfiguration;
  /**
   *
   * @type {boolean}
   * @memberof CampaignForwarded
   */
  budgetChanged?: boolean;
}

export function CampaignForwardedFromJSON(json: any): CampaignForwarded {
  return CampaignForwardedFromJSONTyped(json, false);
}

export function CampaignForwardedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CampaignForwarded {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    campaignConfigurationId: json["campaignConfigurationId"],
    status: CampaignForwardedStatusFromJSON(json["status"]),
    modified: new Date(json["modified"]),
    forwardedOn: new Date(json["forwardedOn"]),
    forwardedBy: json["forwardedBy"],
    forwardedTo: json["forwardedTo"],
    organisationCode: json["organisationCode"],
    advertiserId: json["advertiserId"],
    productId: json["productId"],
    advertiserName: !exists(json, "advertiserName")
      ? undefined
      : json["advertiserName"],
    productDescr: !exists(json, "productDescr")
      ? undefined
      : json["productDescr"],
    _package: !exists(json, "package")
      ? undefined
      : PackageFromJSON(json["package"]),
    campaignConfiguration: !exists(json, "campaignConfiguration")
      ? undefined
      : CampaignConfigurationFromJSON(json["campaignConfiguration"]),
    budgetChanged: !exists(json, "budgetChanged")
      ? undefined
      : json["budgetChanged"],
  };
}

export function CampaignForwardedToJSON(value?: CampaignForwarded | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    campaignConfigurationId: value.campaignConfigurationId,
    status: CampaignForwardedStatusToJSON(value.status),
    modified: value.modified.toISOString(),
    forwardedOn: value.forwardedOn.toISOString(),
    forwardedBy: value.forwardedBy,
    forwardedTo: value.forwardedTo,
    organisationCode: value.organisationCode,
    advertiserId: value.advertiserId,
    productId: value.productId,
    package: PackageToJSON(value._package),
    campaignConfiguration: CampaignConfigurationToJSON(
      value.campaignConfiguration
    ),
    budgetChanged: value.budgetChanged,
  };
}
