/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  Document,
  DocumentFromJSON,
  DocumentResultDeleted,
  DocumentResultDeletedFromJSON,
  DocumentResultDeletedToJSON,
  DocumentResultSuccess,
  DocumentResultSuccessFromJSON,
  DocumentResultSuccessToJSON,
  DocumentToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface ApiV1DocumentDeleteGuidPostRequest {
  guid: string;
}

export interface ApiV1DocumentGuidGetRequest {
  guid: string;
}

export interface ApiV1DocumentPostRequest {
  file: Blob;
  organisationCode: string;
  userName: string;
  publicationStart?: Date;
  publicationEnd?: Date;
  advertiserCode?: number;
}

/**
 *
 */
export class DocumentApi extends runtime.BaseAPI {
  /**
   */
  async apiV1DocumentDeleteGuidPostRaw(
    requestParameters: ApiV1DocumentDeleteGuidPostRequest
  ): Promise<runtime.ApiResponse<DocumentResultDeleted>> {
    if (
      requestParameters.guid === null ||
      requestParameters.guid === undefined
    ) {
      throw new runtime.RequiredError(
        "guid",
        "Required parameter requestParameters.guid was null or undefined when calling apiV1DocumentDeleteGuidPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Document/delete/{guid}`.replace(
        `{${"guid"}}`,
        encodeURIComponent(String(requestParameters.guid))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      DocumentResultDeletedFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1DocumentDeleteGuidPost(
    requestParameters: ApiV1DocumentDeleteGuidPostRequest
  ): Promise<DocumentResultDeleted> {
    const response =
      await this.apiV1DocumentDeleteGuidPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1DocumentGetRaw(): Promise<runtime.ApiResponse<Array<Document>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Document`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(DocumentFromJSON)
    );
  }

  /**
   */
  async apiV1DocumentGet(): Promise<Array<Document>> {
    const response = await this.apiV1DocumentGetRaw();
    return await response.value();
  }

  /**
   */
  async apiV1DocumentGuidGetRaw(
    requestParameters: ApiV1DocumentGuidGetRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.guid === null ||
      requestParameters.guid === undefined
    ) {
      throw new runtime.RequiredError(
        "guid",
        "Required parameter requestParameters.guid was null or undefined when calling apiV1DocumentGuidGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Document/{guid}`.replace(
        `{${"guid"}}`,
        encodeURIComponent(String(requestParameters.guid))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async apiV1DocumentGuidGet(
    requestParameters: ApiV1DocumentGuidGetRequest
  ): Promise<void> {
    await this.apiV1DocumentGuidGetRaw(requestParameters);
  }

  /**
   */
  async apiV1DocumentPostRaw(
    requestParameters: ApiV1DocumentPostRequest
  ): Promise<runtime.ApiResponse<DocumentResultSuccess>> {
    if (
      requestParameters.file === null ||
      requestParameters.file === undefined
    ) {
      throw new runtime.RequiredError(
        "file",
        "Required parameter requestParameters.file was null or undefined when calling apiV1DocumentPost."
      );
    }

    if (
      requestParameters.organisationCode === null ||
      requestParameters.organisationCode === undefined
    ) {
      throw new runtime.RequiredError(
        "organisationCode",
        "Required parameter requestParameters.organisationCode was null or undefined when calling apiV1DocumentPost."
      );
    }

    if (
      requestParameters.userName === null ||
      requestParameters.userName === undefined
    ) {
      throw new runtime.RequiredError(
        "userName",
        "Required parameter requestParameters.userName was null or undefined when calling apiV1DocumentPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const consumes: runtime.Consume[] = [
      { contentType: "multipart/form-data" },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.file !== undefined) {
      formParams.append("File", requestParameters.file as any);
    }

    if (requestParameters.publicationStart !== undefined) {
      formParams.append(
        "PublicationStart",
        requestParameters.publicationStart as any
      );
    }

    if (requestParameters.publicationEnd !== undefined) {
      formParams.append(
        "PublicationEnd",
        requestParameters.publicationEnd as any
      );
    }

    if (requestParameters.organisationCode !== undefined) {
      formParams.append(
        "OrganisationCode",
        requestParameters.organisationCode as any
      );
    }

    if (requestParameters.userName !== undefined) {
      formParams.append("UserName", requestParameters.userName as any);
    }

    if (requestParameters.advertiserCode !== undefined) {
      formParams.append(
        "AdvertiserCode",
        requestParameters.advertiserCode as any
      );
    }

    const response = await this.request({
      path: `/api/v1/Document`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      DocumentResultSuccessFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1DocumentPost(
    requestParameters: ApiV1DocumentPostRequest
  ): Promise<DocumentResultSuccess> {
    const response = await this.apiV1DocumentPostRaw(requestParameters);
    return await response.value();
  }
}
