import "./commercialpicker.module.less";

import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Modal, Row } from "@ster/ster-toolkit";
import { Col } from "antd";
import { ModalProps } from "antd/lib/modal";
import { memo, useCallback, useMemo } from "react";

import { Commercial, CommercialInstructionSpots } from "../../../../api/models";
import { getCommercialPlayer } from "../../../commercials/Commercials";
import MaterialCard from "../../Cards/Material/MaterialCard";

interface CommercialsModalProps extends ModalProps {
  onSelect: (commercial: Commercial) => void;
  onCancel: () => void;
  commercials: Commercial[];
  selected: CommercialInstructionSpots;
}

const CommercialsModal = memo(
  ({
    open,
    onSelect,
    commercials,
    onCancel,
    selected,
  }: CommercialsModalProps) => {
    const { i18n } = useLingui();
    const commercialsPerRow = useMemo(
      () => (commercials.length === 0 ? 1 : Math.min(commercials.length, 3)),
      [commercials.length]
    );

    const handleSelect = useCallback(
      (commercial: Commercial) => {
        // set de geselecteerde commercial en geef die door
        onSelect(commercial);
        if (onCancel) {
          onCancel();
        }
      },
      [onCancel, onSelect]
    );

    if (!open) {
      return null;
    }

    return (
      <Modal
        open={open}
        onCancel={onCancel}
        cancelText={<Trans>Annuleren</Trans>}
        onOk={onCancel}
        width={`${(1336 / 3) * commercialsPerRow}px`}
        title={i18n._(t`Selecteer een commercial`)}
      >
        <Row gutter={[24, 32]}>
          {commercials.map((commercial) => {
            const currentSelected = selected?.commercial?.id === commercial.id;
            return (
              <Col
                xs={24 / commercialsPerRow}
                key={commercial.id}
                className="commercials-modal_col"
              >
                <MaterialCard
                  cardData={commercial}
                  player={getCommercialPlayer(commercial)}
                  footer={
                    <Button
                      className="commercials-modal__button"
                      mode="secondary"
                      onClick={() => handleSelect(commercial)}
                      data-index={commercial.id}
                      disabled={currentSelected}
                    >
                      {currentSelected && <Trans>Geselecteerd</Trans>}
                      {!currentSelected && (
                        <>
                          <Trans>Selecteer</Trans> {commercial.id}
                        </>
                      )}
                    </Button>
                  }
                  disabled={commercial.id === "Nieuw"}
                />
              </Col>
            );
          })}
          {commercials.length === 0 && (
            <div>
              <Trans>
                Er zijn (nog) geen commercials beschikbaar voor deze spotlengte.
                Deze kunnen via{" "}
                <a
                  href="https://portal.ster.nl/material"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Materiaal uploaden
                </a>{" "}
                aangeleverd worden.
              </Trans>
            </div>
          )}
        </Row>
      </Modal>
    );
  }
);

export default CommercialsModal;
