import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  ComponentLocalization,
  DatePicker,
  Form,
  Input,
  Modal,
  Option,
  Select,
  Spinner,
  Typography,
  formatDate,
} from "@ster/ster-toolkit";
import { Form as AntForm } from "antd";
import { Store } from "antd/lib/form/interface";
import { SelectValue } from "antd/lib/select";
import { addMonths, startOfDay } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { OrderListItem, SalesPeriod, SalesPeriodResult } from "../../../../api";
import { ReduxStoreState } from "../../../../store/base";
import { receiveSalesPeriodsAction } from "../../../../store/campaignCreate/actions";
import { copyCampaignAction } from "../../../../store/campaigns/actions";
import { StoreModel } from "../../../../store/models";
import { getLanguage } from "../../../../utils";
import { getFormattedDate, isBetweenDays } from "../../../../utils/dateHelper";

export interface CampaignCopyModalProps {
  order: OrderListItem;
  onClose: () => void;
}

const emptySalesPeriodResult: SalesPeriodResult[] = [];

const emptySalesPeriods: SalesPeriod[] = [];

const CampaignCopyModal = ({ order, onClose }: CampaignCopyModalProps) => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const [form] = AntForm.useForm();

  useEffect(() => {
    dispatch(receiveSalesPeriodsAction.request());
  }, [dispatch]);

  const {
    salesPeriods = emptySalesPeriodResult,
    loading: salesPeriodsLoading = false,
  } = useSelector((state: StoreModel) => state.salesPeriods);

  const {
    state: copyCampaignState = ReduxStoreState.Initial,
    loading: copyCampaignLoading = false,
  } = useSelector((state: StoreModel) => state.copyCampaign);

  const loading = useMemo(
    () => salesPeriodsLoading || copyCampaignLoading,
    [copyCampaignLoading, salesPeriodsLoading]
  );

  const periods = useMemo(
    () =>
      (
        salesPeriods.find((s) => s.medium === order.medium)?.result ??
        emptySalesPeriods
      ).filter(
        (p) =>
          p.salesPeriodStartDate.getFullYear() === order.startDate.getFullYear()
      ),
    [order.medium, order.startDate, salesPeriods]
  );

  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (initialized) {
      return;
    }

    form.setFieldsValue({
      campaignName: order.name ?? "",
    });

    if (!loading && periods.length === 1) {
      form.setFieldsValue({
        period: 0,
        startDate: periods[0].salesPeriodStartDate,
      });
    }

    setInitialized(true);
  }, [form, initialized, loading, order.name, periods, periods.length]);

  const handleSelectPeriod = useCallback(
    (value: SelectValue) => {
      const selectedPeriod = value as number;
      const startDate = periods[selectedPeriod].salesPeriodStartDate;
      form.setFieldsValue({ startDate });
    },
    [form, periods]
  );

  const handleSubmit = useCallback(
    (values: Store) => {
      const { salesPeriodStartDate, salesPeriodEndDate } =
        periods[values.period as number];
      dispatch(
        copyCampaignAction.request({
          medium: order.medium,
          dateFrom: order.startDate,
          dateUntil: order.endDate,
          copyOrderInput: {
            campaignName: values.campaignName,
            orders: (
              [order.id, order.spreadedOrderId].filter(
                (id) => id !== undefined && id !== null
              ) as number[]
            ).map((s) => ({
              orderId: s,
              from:
                s === order.id
                  ? order.startDate
                  : addMonths(order.startDate, 1),
              to: s === order.id ? order.endDate : addMonths(order.endDate, 1),
            })),
            newPeriod: { from: salesPeriodStartDate, to: salesPeriodEndDate },
            startDate: startOfDay(values.startDate),
          },
          onSuccess: onClose,
        })
      );
    },
    [
      dispatch,
      onClose,
      order.endDate,
      order.id,
      order.medium,
      order.spreadedOrderId,
      order.startDate,
      periods,
    ]
  );

  return (
    <Modal
      open
      centered
      maskClosable={false}
      destroyOnClose
      onCancel={onClose}
      cancelText={<Trans>Annuleren</Trans>}
      cancelButtonProps={{ disabled: loading }}
      onOk={() => form.submit()}
      okText={<Trans>Kopiëren</Trans>}
      okButtonProps={{ disabled: loading }}
      title={i18n._(t`Campagne kopiëren`)}
      width={520}
    >
      <Spinner spinning={loading}>
        <Typography.Paragraph>
          <Trans>
            Kies een periode waarnaar je de campagne wilt kopiëren. Het is
            alleen maar mogelijk om orders binnen het jaar te kopiëren, in
            verband met de wisselende tariefkaarten.
          </Trans>
        </Typography.Paragraph>
        {copyCampaignState === ReduxStoreState.Failure && (
          <Typography.Paragraph type="danger">
            <Trans>Er is iets misgegaan met het kopiëren.</Trans>
          </Typography.Paragraph>
        )}
        <Form form={form} onFinish={handleSubmit}>
          <Form.OptionalItem
            label={i18n._(t`Campagnenaam`)}
            name="campaignName"
          >
            <Input
              placeholder={i18n._(t`Naam voor je campagne...`)}
              maxLength={30}
            />
          </Form.OptionalItem>
          <Form.Item
            label={i18n._(t`Periode`)}
            name="period"
            rules={[
              {
                required: true,
                message: i18n._(t`Kies een periode`),
              },
            ]}
          >
            <Select
              placeholder={i18n._(t`Kies een periode`)}
              className="select"
              onChange={handleSelectPeriod}
            >
              {periods.map((period, index) => (
                <Option
                  value={index}
                  key={formatDate(period.salesPeriodStartDate)}
                >
                  <>
                    {getFormattedDate(period.salesPeriodStartDate, "PPP")}{" "}
                    <Trans>t/m</Trans>{" "}
                    {getFormattedDate(period.salesPeriodEndDate, "PPP")}
                    {period.ratecardStatus &&
                      ` (${
                        // eslint-disable-next-line no-nested-ternary
                        period.ratecardStatus === "Vrijgegeven"
                          ? i18n._(t`Open om in te boeken`)
                          : period.ratecardStatus === "Voorbereiding"
                            ? i18n._(t`Voorbereiding`)
                            : period.ratecardStatus
                      })`}
                  </>
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item noStyle dependencies={["period"]}>
            {({ getFieldValue }) => {
              const hasPeriod = getFieldValue("period") !== undefined;

              const startDate = hasPeriod
                ? periods[getFieldValue("period") as number]
                    .salesPeriodStartDate
                : undefined;
              const endDate = hasPeriod
                ? periods[getFieldValue("period") as number].salesPeriodEndDate
                : undefined;

              return (
                <Form.Item
                  label={i18n._(t`Startdatum van de campagne`)}
                  name="startDate"
                  rules={[
                    {
                      required: true,
                      message: i18n._(t`Startdatum is verplicht`),
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    format="D MMMM yyyy"
                    componentLocale={getLanguage() as ComponentLocalization}
                    allowClear={false}
                    disabled={!hasPeriod}
                    disabledDate={(current: Date): boolean => {
                      if (!startDate || !endDate) {
                        return true;
                      }

                      return !isBetweenDays(startDate, endDate, current);
                    }}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Form>
      </Spinner>
    </Modal>
  );
};

export default CampaignCopyModal;
