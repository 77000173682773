/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CampaignForwardInput
 */
export interface CampaignForwardInput {
  /**
   *
   * @type {string}
   * @memberof CampaignForwardInput
   */
  organisationCode?: string | null;
  /**
   *
   * @type {number}
   * @memberof CampaignForwardInput
   */
  advertiserId?: number;
  /**
   *
   * @type {number}
   * @memberof CampaignForwardInput
   */
  productId?: number;
  /**
   *
   * @type {string}
   * @memberof CampaignForwardInput
   */
  email?: string | null;
}

export function CampaignForwardInputFromJSON(json: any): CampaignForwardInput {
  return CampaignForwardInputFromJSONTyped(json, false);
}

export function CampaignForwardInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CampaignForwardInput {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    organisationCode: !exists(json, "organisationCode")
      ? undefined
      : json["organisationCode"],
    advertiserId: !exists(json, "advertiserId")
      ? undefined
      : json["advertiserId"],
    productId: !exists(json, "productId") ? undefined : json["productId"],
    email: !exists(json, "email") ? undefined : json["email"],
  };
}

export function CampaignForwardInputToJSON(
  value?: CampaignForwardInput | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    organisationCode: value.organisationCode,
    advertiserId: value.advertiserId,
    productId: value.productId,
    email: value.email,
  };
}
