import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, PathRouteProps } from "react-router-dom";

import { ClaimType, RoleType } from "../../shared/models";
// import { operationalContextStateSelector } from "../../shared/selectors";
import { operationalContextStateSelector } from "../../shared/selectors";
import { logoutMeAction } from "../../store/account/actions";
import { ReduxStoreState } from "../../store/base";
import { LoadingPortalUser } from "../../store/models";
import { getToken, isAccessTokenValid } from "../../utils";

export const privateRoutePaths = {
  notLoggedIn: "/not-logged-in",
  forbidden: "/forbidden",
  loggedIn: "/",
  noPortalAccount: "/no-account",
  noTenant: "/no-tenant",
};

interface PrivateRouteProps extends PathRouteProps {
  user?: LoadingPortalUser;
  requiredRoles?: RoleType[];
  requiredClaims?: ClaimType[];
}

const setCurrentUrl = () => {
  window.sessionStorage.setItem("ster-portal-next", window.location.href);
};

const PrivateRoute = memo(
  ({ user, requiredRoles, requiredClaims }: PrivateRouteProps) => {
    const accessToken = getToken();
    const dispatch = useDispatch();
    const operationalContextState = useSelector(
      operationalContextStateSelector
    );

    useEffect(() => {
      if (
        !accessToken ||
        !user ||
        user.state === ReduxStoreState.Unauthorized
      ) {
        dispatch(logoutMeAction.request());
      }
    }, [accessToken, dispatch, user]);

    if (!accessToken || !user || user.state === ReduxStoreState.Unauthorized) {
      setCurrentUrl();
      return <Navigate to={privateRoutePaths.notLoggedIn} />;
    }

    if (user.state === ReduxStoreState.Forbidden) {
      return <Navigate to={privateRoutePaths.forbidden} />;
    }

    if (accessToken && user && user.id === "-1") {
      // logged in, but not valid yet
      return <Navigate to={privateRoutePaths.noPortalAccount} />;
    }

    if (
      requiredRoles &&
      requiredRoles.length > 0 &&
      user.roles &&
      !user.roles.some((value) => requiredRoles.includes(value as RoleType))
    ) {
      return <Navigate to={privateRoutePaths.forbidden} />;
    }

    if (
      requiredClaims &&
      requiredClaims.length > 0 &&
      user.claims &&
      !user.claims.some((value) => requiredClaims.includes(value as ClaimType))
    ) {
      return <Navigate to={privateRoutePaths.forbidden} />;
    }

    if (!isAccessTokenValid()) {
      setCurrentUrl();
      return <Navigate to={privateRoutePaths.notLoggedIn} />;
    }

    return operationalContextState && <Outlet />;
  }
);

export default PrivateRoute;
