/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";

export interface TypeIdExtensionGetRequest {
  type: string;
  id: number;
  extension: string;
}

export interface TypeIdSubIdExtensionGetRequest {
  type: string;
  id: number;
  subId: number;
  extension: string;
}

/**
 *
 */
export class DownloadApi extends runtime.BaseAPI {
  /**
   */
  async typeIdExtensionGetRaw(
    requestParameters: TypeIdExtensionGetRequest
  ): Promise<runtime.ApiResponse<Blob>> {
    if (
      requestParameters.type === null ||
      requestParameters.type === undefined
    ) {
      throw new runtime.RequiredError(
        "type",
        "Required parameter requestParameters.type was null or undefined when calling typeIdExtensionGet."
      );
    }

    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling typeIdExtensionGet."
      );
    }

    if (
      requestParameters.extension === null ||
      requestParameters.extension === undefined
    ) {
      throw new runtime.RequiredError(
        "extension",
        "Required parameter requestParameters.extension was null or undefined when calling typeIdExtensionGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/{type}/{id}.{extension}`
        .replace(
          `{${"type"}}`,
          encodeURIComponent(String(requestParameters.type))
        )
        .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
        .replace(
          `{${"extension"}}`,
          encodeURIComponent(String(requestParameters.extension))
        ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async typeIdExtensionGet(
    requestParameters: TypeIdExtensionGetRequest
  ): Promise<Blob> {
    const response = await this.typeIdExtensionGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async typeIdSubIdExtensionGetRaw(
    requestParameters: TypeIdSubIdExtensionGetRequest
  ): Promise<runtime.ApiResponse<Blob>> {
    if (
      requestParameters.type === null ||
      requestParameters.type === undefined
    ) {
      throw new runtime.RequiredError(
        "type",
        "Required parameter requestParameters.type was null or undefined when calling typeIdSubIdExtensionGet."
      );
    }

    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling typeIdSubIdExtensionGet."
      );
    }

    if (
      requestParameters.subId === null ||
      requestParameters.subId === undefined
    ) {
      throw new runtime.RequiredError(
        "subId",
        "Required parameter requestParameters.subId was null or undefined when calling typeIdSubIdExtensionGet."
      );
    }

    if (
      requestParameters.extension === null ||
      requestParameters.extension === undefined
    ) {
      throw new runtime.RequiredError(
        "extension",
        "Required parameter requestParameters.extension was null or undefined when calling typeIdSubIdExtensionGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/{type}/{id}/{subId}.{extension}`
        .replace(
          `{${"type"}}`,
          encodeURIComponent(String(requestParameters.type))
        )
        .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
        .replace(
          `{${"subId"}}`,
          encodeURIComponent(String(requestParameters.subId))
        )
        .replace(
          `{${"extension"}}`,
          encodeURIComponent(String(requestParameters.extension))
        ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async typeIdSubIdExtensionGet(
    requestParameters: TypeIdSubIdExtensionGetRequest
  ): Promise<Blob> {
    const response = await this.typeIdSubIdExtensionGetRaw(requestParameters);
    return await response.value();
  }
}
