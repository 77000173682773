/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface TermsAndConditionsResult
 */
export interface TermsAndConditionsResult {
  /**
   *
   * @type {number}
   * @memberof TermsAndConditionsResult
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof TermsAndConditionsResult
   */
  userId: string;
  /**
   *
   * @type {Date}
   * @memberof TermsAndConditionsResult
   */
  dateOfAgreement: Date;
  /**
   *
   * @type {Date}
   * @memberof TermsAndConditionsResult
   */
  dateOfTerms: Date;
  /**
   *
   * @type {boolean}
   * @memberof TermsAndConditionsResult
   */
  canUseForMailing?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TermsAndConditionsResult
   */
  canUseForMarketing?: boolean;
}

export function TermsAndConditionsResultFromJSON(
  json: any
): TermsAndConditionsResult {
  return TermsAndConditionsResultFromJSONTyped(json, false);
}

export function TermsAndConditionsResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TermsAndConditionsResult {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    userId: json["userId"],
    dateOfAgreement: new Date(json["dateOfAgreement"]),
    dateOfTerms: new Date(json["dateOfTerms"]),
    canUseForMailing: !exists(json, "canUseForMailing")
      ? undefined
      : json["canUseForMailing"],
    canUseForMarketing: !exists(json, "canUseForMarketing")
      ? undefined
      : json["canUseForMarketing"],
  };
}

export function TermsAndConditionsResultToJSON(
  value?: TermsAndConditionsResult | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    userId: value.userId,
    dateOfAgreement: value.dateOfAgreement.toISOString(),
    dateOfTerms: value.dateOfTerms.toISOString(),
    canUseForMailing: value.canUseForMailing,
    canUseForMarketing: value.canUseForMarketing,
  };
}
