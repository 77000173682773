/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  MediumEnum,
  MediumEnumFromJSON,
  MediumEnumFromJSONTyped,
  MediumEnumToJSON,
} from "./";

/**
 *
 * @export
 * @interface AvailableBreaksRequest
 */
export interface AvailableBreaksRequest {
  /**
   *
   * @type {MediumEnum}
   * @memberof AvailableBreaksRequest
   */
  medium?: MediumEnum;
  /**
   *
   * @type {string}
   * @memberof AvailableBreaksRequest
   */
  product: string;
  /**
   *
   * @type {Date}
   * @memberof AvailableBreaksRequest
   */
  from: Date;
  /**
   *
   * @type {Date}
   * @memberof AvailableBreaksRequest
   */
  to: Date;
  /**
   *
   * @type {string}
   * @memberof AvailableBreaksRequest
   */
  packageCode: string;
  /**
   *
   * @type {number}
   * @memberof AvailableBreaksRequest
   */
  length: number;
  /**
   *
   * @type {string}
   * @memberof AvailableBreaksRequest
   */
  targetGroup?: string | null;
  /**
   *
   * @type {string}
   * @memberof AvailableBreaksRequest
   */
  secondaryTargetGroup?: string | null;
  /**
   *
   * @type {string}
   * @memberof AvailableBreaksRequest
   */
  channels?: string | null;
  /**
   *
   * @type {string}
   * @memberof AvailableBreaksRequest
   */
  orderId?: string | null;
  /**
   *
   * @type {string}
   * @memberof AvailableBreaksRequest
   */
  subOrderId?: string | null;
  /**
   *
   * @type {Array<Date>}
   * @memberof AvailableBreaksRequest
   */
  excludedDays?: Array<Date> | null;
}

export function AvailableBreaksRequestFromJSON(
  json: any
): AvailableBreaksRequest {
  return AvailableBreaksRequestFromJSONTyped(json, false);
}

export function AvailableBreaksRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AvailableBreaksRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    medium: !exists(json, "medium")
      ? undefined
      : MediumEnumFromJSON(json["medium"]),
    product: json["product"],
    from: new Date(json["from"]),
    to: new Date(json["to"]),
    packageCode: json["packageCode"],
    length: json["length"],
    targetGroup: !exists(json, "targetGroup") ? undefined : json["targetGroup"],
    secondaryTargetGroup: !exists(json, "secondaryTargetGroup")
      ? undefined
      : json["secondaryTargetGroup"],
    channels: !exists(json, "channels") ? undefined : json["channels"],
    orderId: !exists(json, "orderId") ? undefined : json["orderId"],
    subOrderId: !exists(json, "subOrderId") ? undefined : json["subOrderId"],
    excludedDays: !exists(json, "excludedDays")
      ? undefined
      : json["excludedDays"],
  };
}

export function AvailableBreaksRequestToJSON(
  value?: AvailableBreaksRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    medium: MediumEnumToJSON(value.medium),
    product: value.product,
    from: value.from.toISOString(),
    to: value.to.toISOString(),
    packageCode: value.packageCode,
    length: value.length,
    targetGroup: value.targetGroup,
    secondaryTargetGroup: value.secondaryTargetGroup,
    channels: value.channels,
    orderId: value.orderId,
    subOrderId: value.subOrderId,
    excludedDays: value.excludedDays,
  };
}
