/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SalesPeriod
 */
export interface SalesPeriod {
  /**
   *
   * @type {Date}
   * @memberof SalesPeriod
   */
  salesPeriodStartDate: Date;
  /**
   *
   * @type {Date}
   * @memberof SalesPeriod
   */
  salesPeriodEndDate: Date;
  /**
   *
   * @type {Date}
   * @memberof SalesPeriod
   */
  requestStartDate?: Date | null;
  /**
   *
   * @type {Date}
   * @memberof SalesPeriod
   */
  requestEndDate?: Date | null;
  /**
   *
   * @type {Date}
   * @memberof SalesPeriod
   */
  confirmationDate?: Date | null;
  /**
   *
   * @type {Date}
   * @memberof SalesPeriod
   */
  releaseDate?: Date | null;
  /**
   *
   * @type {string}
   * @memberof SalesPeriod
   */
  ratecardStatus?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof SalesPeriod
   */
  canRequest: boolean;
}

export function SalesPeriodFromJSON(json: any): SalesPeriod {
  return SalesPeriodFromJSONTyped(json, false);
}

export function SalesPeriodFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SalesPeriod {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    salesPeriodStartDate: new Date(json["salesPeriodStartDate"]),
    salesPeriodEndDate: new Date(json["salesPeriodEndDate"]),
    requestStartDate: !exists(json, "requestStartDate")
      ? undefined
      : json["requestStartDate"] === null
        ? null
        : new Date(json["requestStartDate"]),
    requestEndDate: !exists(json, "requestEndDate")
      ? undefined
      : json["requestEndDate"] === null
        ? null
        : new Date(json["requestEndDate"]),
    confirmationDate: !exists(json, "confirmationDate")
      ? undefined
      : json["confirmationDate"] === null
        ? null
        : new Date(json["confirmationDate"]),
    releaseDate: !exists(json, "releaseDate")
      ? undefined
      : json["releaseDate"] === null
        ? null
        : new Date(json["releaseDate"]),
    ratecardStatus: !exists(json, "ratecardStatus")
      ? undefined
      : json["ratecardStatus"],
    canRequest: json["canRequest"],
  };
}

export function SalesPeriodToJSON(value?: SalesPeriod | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    salesPeriodStartDate: value.salesPeriodStartDate.toISOString(),
    salesPeriodEndDate: value.salesPeriodEndDate.toISOString(),
    requestStartDate:
      value.requestStartDate === undefined
        ? undefined
        : value.requestStartDate === null
          ? null
          : value.requestStartDate.toISOString(),
    requestEndDate:
      value.requestEndDate === undefined
        ? undefined
        : value.requestEndDate === null
          ? null
          : value.requestEndDate.toISOString(),
    confirmationDate:
      value.confirmationDate === undefined
        ? undefined
        : value.confirmationDate === null
          ? null
          : value.confirmationDate.toISOString(),
    releaseDate:
      value.releaseDate === undefined
        ? undefined
        : value.releaseDate === null
          ? null
          : value.releaseDate.toISOString(),
    ratecardStatus: value.ratecardStatus,
    canRequest: value.canRequest,
  };
}
