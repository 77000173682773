import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Container,
  Spinner,
  Table,
  formatNumber,
  formatToEuro,
} from "@ster/ster-toolkit";
import { Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import classNames from "classnames";
import { endOfDay, format, startOfDay } from "date-fns";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  MediumEnum,
  PackageType,
  SecondaryTargetGroup,
  Spot,
} from "../../api/models";
import { ReduxStoreState } from "../../store/base";
import { receiveCampaignSpotsAction } from "../../store/campaignDetail/actions";
import { StoreModel } from "../../store/models";
import { isBetweenDays } from "../../utils/dateHelper";
import CardStatistic from "../partials/Cards/CardStatistic";
import SubOrderCardDetails from "../partials/Cards/SubOrder/SubOrderCardDetails";
import styles from "./CampaignSchedule.module.less";
import { CampaignScheduleProps, SchemaFilter } from "./models";
import CampaignRegioSchedule from "./schedule/CampaignRegioSchedule";
import {
  allColumnKeys,
  optionalColumns,
  renderColumn,
} from "./schedule/columns";
import ScheduleFilter, { emptySubOrders } from "./schedule/ScheduleFilter";
import { campaignSpotsRootSelector } from "./selectors";

const emptySpots: Spot[] = [];

const CampaignTotals = memo(
  ({
    spots,
    fieldname,
    fieldnameSecondaryTargetGroup,
    hasSecondaryTargetGroup,
    tooltipTitle,
  }: {
    spots: Spot[];
    fieldname: keyof Spot;
    fieldnameSecondaryTargetGroup?: keyof Spot;
    hasSecondaryTargetGroup: boolean;
    tooltipTitle?: string;
  }) => (
    <span
      className={classNames(styles["schedule-totals"], {
        [styles["has-secondarytargetgroup"]]: hasSecondaryTargetGroup,
      })}
    >
      <span>
        {formatNumber(
          spots
            .filter((b) => !b.isTagon)
            .reduce((sum, b) => sum + (b[fieldname] as number), 0),
          2
        )}
      </span>
      {fieldnameSecondaryTargetGroup && hasSecondaryTargetGroup && (
        <Tooltip
          title={tooltipTitle}
          placement="bottom"
          className={styles.secondarytargetgroup}
        >
          {formatNumber(
            spots
              .filter((b) => !b.isTagon)
              .reduce(
                (sum, b) =>
                  sum +
                  ((b[fieldnameSecondaryTargetGroup] as number | undefined) ??
                    0),
                0
              ),
            2
          )}
        </Tooltip>
      )}
    </span>
  )
);

const expandedRowRender = (
  { ratingsRegio }: Spot,
  _index: number,
  _indent: number,
  expanded: boolean
) => <CampaignRegioSchedule ratingsRegio={ratingsRegio} expanded={expanded} />;

const CampaignSchedule = memo(
  ({ campaign, secondaryTargetGroups, showPrices }: CampaignScheduleProps) => {
    const { i18n } = useLingui();
    const dispatch = useDispatch();

    const { subOrderId: subOrderIdFromParams } = useParams<{
      subOrderId?: string;
    }>();
    const subOrderId = subOrderIdFromParams
      ? Number.parseInt(subOrderIdFromParams as string, 10)
      : undefined;

    const [filter, updateFilter] = useState<SchemaFilter>({
      dateFrom: campaign.orderStartDate,
      dateTo: campaign.orderEndDate,
      columns: allColumnKeys.filter((c) => !optionalColumns.includes(c)),
    });

    const {
      spots: { spots, state: spotsState } = { spots: emptySpots },
      loading,
    } = useSelector((state: StoreModel) =>
      campaignSpotsRootSelector(state, campaign.id)
    );

    useEffect(() => {
      updateFilter({ ...filter, subOrderId });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subOrderId]); // only when `subOrderId` changes; otherwise endless-loop

    const receiveCampaignSpots = useCallback(
      (secondaryTargetGroup?: SecondaryTargetGroup) => {
        dispatch(
          receiveCampaignSpotsAction.request({
            medium: campaign.medium,
            orderId: campaign.id,
            secondaryTargetGroupId:
              secondaryTargetGroup?.code ??
              filter.secondaryTargetGroup?.code ??
              undefined,
          })
        );
      },
      [
        campaign.id,
        campaign.medium,
        dispatch,
        filter.secondaryTargetGroup?.code,
      ]
    );

    const handleFilterChange = useCallback(
      (f: SchemaFilter) => {
        if (f.secondaryTargetGroup !== filter.secondaryTargetGroup) {
          receiveCampaignSpots(f.secondaryTargetGroup);
        }
        updateFilter(f);
      },
      [filter.secondaryTargetGroup, receiveCampaignSpots]
    );

    useEffect((): void => {
      if (spotsState !== ReduxStoreState.Success) {
        receiveCampaignSpots();
      }
    }, [receiveCampaignSpots, spotsState]);

    const nacalcRatingAvailable = useMemo(
      () =>
        campaign.medium === MediumEnum.Radio &&
        campaign.subOrders?.some(
          (sub) => sub.nacalcGrp !== null && sub.nacalcGrp !== undefined
        ),
      [campaign.medium, campaign.subOrders]
    );

    const columns: ColumnsType<Spot> = useMemo(
      () =>
        allColumnKeys
          .filter((k) => filter.columns.includes(k))
          .map((spot) =>
            renderColumn(
              spot,
              i18n,
              campaign.medium === MediumEnum.Radio && !nacalcRatingAvailable,
              campaign.subOrders
            )
          ),
      [
        campaign.medium,
        campaign.subOrders,
        filter.columns,
        i18n,
        nacalcRatingAvailable,
      ]
    );

    const filteredSpots = useMemo(() => {
      const { dateFrom, dateTo, subOrderId: filterSubOrderId } = filter;
      const start = startOfDay(dateFrom);
      const end = endOfDay(dateTo);
      return (spots ?? emptySpots).filter(
        (spot) =>
          (filterSubOrderId === undefined ||
            spot.subOrderId === filterSubOrderId) &&
          isBetweenDays(start, end, spot.schedDate)
      );
    }, [filter, spots]);

    const selectedSubOrder = useMemo(() => {
      if (subOrderId) {
        return campaign.subOrders?.find((sub) => sub.id === subOrderId);
      }
      if (campaign.subOrders?.length === 1) {
        return campaign.subOrders[0];
      }
      return undefined;
    }, [campaign.subOrders, subOrderId]);

    /**
     * `showPredictedRating` is a boolean value to indicate that we are only looking at a SPECIFIEK package type.
     */
    const showPredictedRating = useMemo(() => {
      const subOrdersToEvaluate = selectedSubOrder
        ? [selectedSubOrder]
        : campaign.subOrders ?? [];
      const someSubOrdersAreNotSpecifiek = subOrdersToEvaluate.some(
        (subOrder) => subOrder._package.type !== PackageType.Specifiek
      );

      // when some suborders are not specifiek: display prognose
      return someSubOrdersAreNotSpecifiek;
    }, [campaign.subOrders, selectedSubOrder]);

    const showRatings = useMemo(
      () =>
        // Er moet 1 deelorder geselecteerd zijn, of alle deelorders hebben dezelfde doelgroep
        selectedSubOrder ||
        campaign.subOrders?.every(
          (s) =>
            s.targetGroup.targetGroupId ===
            campaign.subOrders?.[0].targetGroup.targetGroupId
        ),
      [campaign.subOrders, selectedSubOrder]
    );

    const title = campaign
      ? `${campaign?.campaignName || campaign?.productDescr} (${
          campaign?.medium
        })`
      : "...";

    const hasRegioSpots = useMemo(
      () => filteredSpots.some((s) => s.ratingsRegio.length > 0),
      [filteredSpots]
    );

    /**
     * `filteredColumns` contains the actual displayed columns for the campaign schedule table.
     */
    const filteredColumns = useMemo(
      () =>
        columns.filter(({ key }) => {
          switch (key) {
            case "predictedRating":
              return showPredictedRating;
            case "nacalcRating":
              return campaign.medium === MediumEnum.Radio;
            default:
              return true;
          }
        }),
      [columns, campaign, showPredictedRating]
    );

    return (
      <>
        <Helmet>
          <title>{i18n._(t`Uitzendschema: ${title}`)}</title>
        </Helmet>
        <Container>
          <ScheduleFilter
            medium={campaign.medium}
            orderId={campaign.id}
            subOrders={
              campaign.subOrders?.map(
                ({ id, _package, spotLength, period }) => {
                  const sameMonth =
                    period.from.getMonth() === period.to.getMonth();
                  const periodDescr = sameMonth
                    ? `${format(period.from, "d")} t/m ${format(
                        period.to,
                        "d MMM"
                      )}`
                    : `${format(period.from, "d MMM")} t/m ${format(
                        period.to,
                        "d MMM"
                      )}`;
                  return {
                    label: `${_package.name} ${spotLength?.join(
                      "+"
                    )}" - ${periodDescr}`,
                    value: id,
                  };
                }
              ) ?? emptySubOrders
            }
            filter={filter}
            onChange={handleFilterChange}
            targetGroup={selectedSubOrder?.targetGroup.targetGroupId ?? "25-67"}
            secondaryTargetGroups={secondaryTargetGroups}
            showPrices={showPrices}
            predictedRatings={
              campaign.medium === MediumEnum.Radio && !nacalcRatingAvailable
            }
          />

          {selectedSubOrder && (
            <article
              className={classNames(
                "card card--suborder",
                styles.individualSubOrder
              )}
            >
              <SubOrderCardDetails
                medium={campaign.medium}
                startDate={selectedSubOrder.period.from}
                endDate={selectedSubOrder.period.to}
                {...selectedSubOrder}
              />
            </article>
          )}

          {showPrices && (
            <section className={styles["selected-row"]}>
              <div className={styles.title}>Geselecteerd</div>

              <div className={styles.statistic}>
                <CardStatistic
                  label={i18n._(t`Bruto spotprijs`)}
                  value={formatToEuro(
                    filteredSpots.reduce((sum, b) => sum + b.grossSpotPrice, 0),
                    false
                  )}
                />
              </div>
              <div className={styles.statistic}>
                <CardStatistic
                  label={i18n._(t`Korting/toeslag`)}
                  value={formatToEuro(
                    filteredSpots.reduce((sum, b) => sum + b.discountAmount, 0),
                    false
                  )}
                />
              </div>
              <div className={styles.statistic}>
                <CardStatistic
                  label={i18n._(t`Netto spotprijs`)}
                  value={formatToEuro(
                    filteredSpots.reduce((sum, b) => sum + b.nettSpotPrice, 0),
                    false
                  )}
                />
              </div>
              <div className={styles.statistic}>
                <CardStatistic
                  label={i18n._(t`Uitgezonden netto spotprijs`)}
                  value={formatToEuro(
                    filteredSpots
                      .filter(
                        (b) =>
                          b.spotStatus === "Uitgezonden" ||
                          b.spotStatus === "Factureerbaar"
                      )
                      .reduce((sum, b) => sum + b.nettSpotPrice, 0),
                    false
                  )}
                />
              </div>
              {showRatings && (
                <>
                  <div className={styles.statistic}>
                    <CardStatistic
                      label={i18n._(t`Prognose`)}
                      value={
                        <CampaignTotals
                          spots={filteredSpots}
                          hasSecondaryTargetGroup={
                            filter.secondaryTargetGroup?.code !== undefined
                          }
                          fieldname="predictedRating"
                          fieldnameSecondaryTargetGroup="predictedRatingSecondaryTargetGroup"
                          tooltipTitle={i18n._(
                            t`Prognose secundaire doelgroep`
                          )}
                        />
                      }
                    />
                  </div>
                  <div className={styles.statistic}>
                    {nacalcRatingAvailable ? (
                      /* Toon de nacalculatie bij Radio */
                      <CardStatistic
                        label={i18n._(t`Realisatie`)}
                        value={
                          <CampaignTotals
                            spots={filteredSpots}
                            hasSecondaryTargetGroup={false}
                            fieldname="nacalcRating"
                          />
                        }
                      />
                    ) : (
                      <CardStatistic
                        label={
                          campaign.medium === MediumEnum.Radio
                            ? i18n._(t`Uitgezonden GRP's`)
                            : i18n._(t`Realisatie`)
                        }
                        value={
                          <CampaignTotals
                            spots={filteredSpots}
                            hasSecondaryTargetGroup={
                              filter.secondaryTargetGroup?.code !== undefined
                            }
                            fieldname="achievedRating"
                            fieldnameSecondaryTargetGroup="achievedRatingSecondaryTargetGroup"
                            tooltipTitle={
                              campaign.medium === MediumEnum.Radio
                                ? i18n._(
                                    t`Uitgezonden GRP's voor de secundaire doelgroep`
                                  )
                                : i18n._(
                                    t`Realisatie voor de secundaire doelgroep`
                                  )
                            }
                          />
                        }
                      />
                    )}
                  </div>
                </>
              )}
            </section>
          )}

          <Spinner spinning={loading}>
            <Table
              columns={filteredColumns}
              dataSource={filteredSpots}
              scroll={{ y: 480 }}
              rowKey={({ uniqueId }: Spot): string => uniqueId ?? ""}
              pagination={false}
              expandable={
                hasRegioSpots
                  ? {
                      columnWidth: 50,
                      expandedRowRender,
                      rowExpandable: ({ ratingsRegio }) =>
                        (ratingsRegio ?? []).length > 0,
                    }
                  : undefined
              }
            />
          </Spinner>
        </Container>
      </>
    );
  }
);

export default CampaignSchedule;
