import { createAction, createAsyncAction } from "typesafe-actions";

import {
  AnalysisTargetGroups,
  ApiV1CommercialInstructionsSaveOnlineOrderIdPostRequest,
  CommercialInstructionRequest,
  OrderDetail,
  OrderRequest,
  SpotsResult,
} from "../../api";
import { ResponseError } from "../base";
import { actionNameAsync } from "../utils";
import {
  CampaignAnalysisGenericInput,
  CampaignAnalysisInput,
  CampaignDetailFailurePayload,
  CampaignDetailRequestPayload,
  CampaignDownload,
  CampaignExportPayload,
  CampaignInstructionsRequestPayload,
  CampaignInstructionsResponsePayload,
  CampaignSpotsDownloadRequestPayload,
  CampaignSpotsFailurePayload,
  CampaignSpotsRequestPayload,
  CommercialInstructionsPayload,
  DeleteInstructionPayload,
  RelatedCampaignsInput,
  RelatedCampaignsOutput,
  ValidateCampaignResult,
} from "./models";

export const receiveCampaignDetailAction = createAsyncAction(
  actionNameAsync("campaignDetail", "REQUEST"),
  actionNameAsync("campaignDetail", "SUCCESS"),
  actionNameAsync("campaignDetail", "FAILURE")
)<CampaignDetailRequestPayload, OrderDetail, CampaignDetailFailurePayload>();

export const receiveCampaignDetailWithSpotsAction = createAsyncAction(
  actionNameAsync("campaignDetailWithSpots", "REQUEST"),
  actionNameAsync("campaignDetailWithSpots", "SUCCESS"),
  actionNameAsync("campaignDetailWithSpots", "FAILURE")
)<CampaignDetailRequestPayload, OrderDetail, CampaignDetailFailurePayload>();

export const receiveCampaignSpotsAction = createAsyncAction(
  actionNameAsync("campaignSpots", "REQUEST"),
  actionNameAsync("campaignSpots", "SUCCESS"),
  actionNameAsync("campaignSpots", "FAILURE")
)<CampaignSpotsRequestPayload, SpotsResult, CampaignSpotsFailurePayload>();

export const receiveCampaignSpotsDownloadAction = createAsyncAction(
  actionNameAsync("campaignSpotsDownload", "REQUEST"),
  actionNameAsync("campaignSpotsDownload", "SUCCESS"),
  actionNameAsync("campaignSpotsDownload", "FAILURE")
)<CampaignSpotsDownloadRequestPayload, CampaignDownload, ResponseError>();

export const receiveCampaignExportAction = createAsyncAction(
  actionNameAsync("campaignExport", "REQUEST"),
  actionNameAsync("campaignExport", "SUCCESS"),
  actionNameAsync("campaignExport", "FAILURE")
)<CampaignExportPayload, CampaignDownload, ResponseError>();

export const receiveCampaignInstructionsAction = createAsyncAction(
  actionNameAsync("campaignInstructions", "REQUEST"),
  actionNameAsync("campaignInstructions", "SUCCESS"),
  actionNameAsync("campaignInstructions", "FAILURE")
)<
  CampaignInstructionsRequestPayload,
  CampaignInstructionsResponsePayload,
  ResponseError
>();

export const clearCampaignInstructionsAction = createAction(
  "CAMPAIGNINSTRUCTIONS_CLEAR"
)();

export const clearSaveCampaignInstructionsAction = createAction(
  "SAVECAMPAIGNINSTRUCTIONS_CLEAR"
)();

export const saveCampaignInstructionsAction = createAsyncAction(
  actionNameAsync("saveCampaignInstructions", "REQUEST"),
  actionNameAsync("saveCampaignInstructions", "SUCCESS"),
  actionNameAsync("saveCampaignInstructions", "FAILURE")
)<CommercialInstructionRequest, CommercialInstructionRequest, ResponseError>();

export const saveMultiCampaignInstructionsAction = createAsyncAction(
  actionNameAsync("saveMultiCampaignInstructions", "REQUEST"),
  actionNameAsync("saveMultiCampaignInstructions", "SUCCESS"),
  actionNameAsync("saveMultiCampaignInstructions", "FAILURE")
)<CommercialInstructionsPayload, undefined, ResponseError>();

export const deleteInstructionAction = createAsyncAction(
  actionNameAsync("deleteInstruction", "REQUEST"),
  actionNameAsync("deleteInstruction", "SUCCESS"),
  actionNameAsync("deleteInstruction", "FAILURE")
)<DeleteInstructionPayload, undefined, ResponseError>();

export const validateCampaignAction = createAsyncAction(
  actionNameAsync("validateCampaign", "REQUEST"),
  actionNameAsync("validateCampaign", "SUCCESS"),
  actionNameAsync("validateCampaign", "FAILURE")
)<OrderRequest, ValidateCampaignResult, ResponseError>();

export const requestCampaignAnalysisAction = createAsyncAction(
  actionNameAsync("requestCampaignAnalysis", "REQUEST"),
  actionNameAsync("requestCampaignAnalysis", "SUCCESS"),
  actionNameAsync("requestCampaignAnalysis", "FAILURE")
)<CampaignAnalysisInput, undefined, ResponseError>();

export const requestCampaignAnalysisGenericAction = createAsyncAction(
  actionNameAsync("requestCampaignGenericAnalysis", "REQUEST"),
  actionNameAsync("requestCampaignGenericAnalysis", "SUCCESS"),
  actionNameAsync("requestCampaignGenericAnalysis", "FAILURE")
)<CampaignAnalysisGenericInput, undefined, ResponseError>();

export const clearCampaignAnalysisAction = createAction(
  "REQUESTCAMPAIGNANALYSIS_CLEAR"
)();

export const receiveCampaignAnalysisTargetGroupsAction = createAsyncAction(
  actionNameAsync("receiveCampaignAnalysisTargetGroups", "REQUEST"),
  actionNameAsync("receiveCampaignAnalysisTargetGroups", "SUCCESS"),
  actionNameAsync("receiveCampaignAnalysisTargetGroups", "FAILURE")
)<undefined, AnalysisTargetGroups, ResponseError>();

export const saveCampaignInstructionsOnlineAction = createAsyncAction(
  actionNameAsync("saveCampaignInstructionsOnline", "REQUEST"),
  actionNameAsync("saveCampaignInstructionsOnline", "SUCCESS"),
  actionNameAsync("saveCampaignInstructionsOnline", "FAILURE")
)<
  ApiV1CommercialInstructionsSaveOnlineOrderIdPostRequest,
  { succes: boolean },
  ResponseError
>();

export const clearSaveCampaignInstructionsOnlineAction = createAction(
  "SAVECAMPAIGNINSTRUCTIONSONLINE_CLEAR"
)();

export const requestRelatedCampaignsAction = createAsyncAction(
  actionNameAsync("requestRelatedCampaigns", "REQUEST"),
  actionNameAsync("requestRelatedCampaigns", "SUCCESS"),
  actionNameAsync("requestRelatedCampaigns", "FAILURE")
)<RelatedCampaignsInput, RelatedCampaignsOutput, ResponseError>();

export const clearRelatedCampaignsAction = createAction(
  "REQUESTRELATEDCAMPAIGNS_CLEAR"
)();
