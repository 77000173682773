/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  PortalSettingsDisableEditCampaignFieldsTv,
  PortalSettingsDisableEditCampaignFieldsTvFromJSON,
  PortalSettingsDisableEditCampaignFieldsTvFromJSONTyped,
  PortalSettingsDisableEditCampaignFieldsTvToJSON,
} from "./";

/**
 *
 * @export
 * @interface PortalSettingsDisableEditCampaignFields
 */
export interface PortalSettingsDisableEditCampaignFields {
  /**
   *
   * @type {PortalSettingsDisableEditCampaignFieldsTv}
   * @memberof PortalSettingsDisableEditCampaignFields
   */
  tv: PortalSettingsDisableEditCampaignFieldsTv;
  /**
   *
   * @type {PortalSettingsDisableEditCampaignFieldsTv}
   * @memberof PortalSettingsDisableEditCampaignFields
   */
  radio: PortalSettingsDisableEditCampaignFieldsTv;
  /**
   *
   * @type {PortalSettingsDisableEditCampaignFieldsTv}
   * @memberof PortalSettingsDisableEditCampaignFields
   */
  inter: PortalSettingsDisableEditCampaignFieldsTv;
}

export function PortalSettingsDisableEditCampaignFieldsFromJSON(
  json: any
): PortalSettingsDisableEditCampaignFields {
  return PortalSettingsDisableEditCampaignFieldsFromJSONTyped(json, false);
}

export function PortalSettingsDisableEditCampaignFieldsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PortalSettingsDisableEditCampaignFields {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    tv: PortalSettingsDisableEditCampaignFieldsTvFromJSON(json["tv"]),
    radio: PortalSettingsDisableEditCampaignFieldsTvFromJSON(json["radio"]),
    inter: PortalSettingsDisableEditCampaignFieldsTvFromJSON(json["inter"]),
  };
}

export function PortalSettingsDisableEditCampaignFieldsToJSON(
  value?: PortalSettingsDisableEditCampaignFields | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    tv: PortalSettingsDisableEditCampaignFieldsTvToJSON(value.tv),
    radio: PortalSettingsDisableEditCampaignFieldsTvToJSON(value.radio),
    inter: PortalSettingsDisableEditCampaignFieldsTvToJSON(value.inter),
  };
}
