/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Channel,
  ChannelFromJSON,
  ChannelFromJSONTyped,
  ChannelToJSON,
  MediumEnum,
  MediumEnumFromJSON,
  MediumEnumFromJSONTyped,
  MediumEnumToJSON,
} from "./";

/**
 *
 * @export
 * @interface SpotsByMedium
 */
export interface SpotsByMedium {
  /**
   *
   * @type {Array<Channel>}
   * @memberof SpotsByMedium
   */
  channels: Array<Channel>;
  /**
   *
   * @type {MediumEnum}
   * @memberof SpotsByMedium
   */
  medium: MediumEnum;
}

export function SpotsByMediumFromJSON(json: any): SpotsByMedium {
  return SpotsByMediumFromJSONTyped(json, false);
}

export function SpotsByMediumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SpotsByMedium {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    channels: (json["channels"] as Array<any>).map(ChannelFromJSON),
    medium: MediumEnumFromJSON(json["medium"]),
  };
}

export function SpotsByMediumToJSON(value?: SpotsByMedium | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    channels: (value.channels as Array<any>).map(ChannelToJSON),
    medium: MediumEnumToJSON(value.medium),
  };
}
