/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum OnlinePackageChoice {
  Display = "Display",
  Video = "Video",
}

export function OnlinePackageChoiceFromJSON(json: any): OnlinePackageChoice {
  return OnlinePackageChoiceFromJSONTyped(json, false);
}

export function OnlinePackageChoiceFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OnlinePackageChoice {
  return json as OnlinePackageChoice;
}

export function OnlinePackageChoiceToJSON(
  value?: OnlinePackageChoice | null
): any {
  return value as any;
}
