import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  Container,
  ContentContainer,
  Icons,
  Modal,
  Spinner,
  Table,
  Typography,
} from "@ster/ster-toolkit";
import { Button as AntButton, Popover, Space, Switch } from "antd";
import { Key } from "antd/es/table/interface";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  AdScienceMediaFile,
  CommercialUploadRegistration,
  CommercialUploadStatus,
  MediumEnum,
} from "../../../api";
import { receiveAdvertisersAction } from "../../../store/advertisers/actions";
import {
  receiveCommercialsForAuditAction,
  updateCommercialRegistrationStatusAction,
} from "../../../store/commercials/actions";
import { receiveOrganisationsAction } from "../../../store/organisations/actions";
import AdScienceTagPlayer from "../../partials/Cards/Players/AdScienceTagPlayer";
import { commercialAuditSelector } from "../selectors";
import styles from "./CommercialAudit.module.less";
import { ApproveAllButton, ReviewButtons } from "./ReviewButtons";

const buttonIconSize = { width: 20, height: 20 };

const MediaFilePreview = ({ mediaFile }: { mediaFile: AdScienceMediaFile }) => {
  const { i18n } = useLingui();
  const [visible, setVisible] = useState(false);
  const handleOpen = useCallback(() => setVisible(true), []);
  const handleClose = useCallback(() => setVisible(false), []);

  return (
    <div className={styles.auditButtonBar}>
      <Button
        mode="tertiary"
        type="link"
        size="small"
        icon={<Icons.EyeIcon {...buttonIconSize} />}
        onClick={handleOpen}
      >
        {i18n._(t`Bekijken`)}
      </Button>
      {visible && (
        <Modal
          open
          onCancel={handleClose}
          cancelText={i18n._(t`Sluiten`)}
          footer={(_, { CancelBtn }) => <CancelBtn />}
        >
          {mediaFile.url && (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video
              width={mediaFile.width}
              height={mediaFile.height}
              controls
              autoPlay
            >
              <source src={mediaFile.url ?? ""} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}

          {mediaFile.tag && mediaFile.width && mediaFile.height && (
            <div className={styles.embeddedContent}>
              <AdScienceTagPlayer
                width={mediaFile.width}
                height={mediaFile.height}
                tag={mediaFile.tag}
              />
            </div>
          )}
        </Modal>
      )}
    </div>
  );
};

const CommercialAudit = () => {
  const { medium } = useParams();
  const { i18n } = useLingui();
  const dispatch = useDispatch();

  const [selectedRows, setSelectedRows] = useState<Key[]>([]);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    dispatch(receiveOrganisationsAction.request());
    dispatch(receiveAdvertisersAction.request());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      receiveCommercialsForAuditAction.request({
        medium: medium as MediumEnum,
        showAll,
      })
    );
  }, [dispatch, medium, showAll]);

  const state = useSelector(commercialAuditSelector);

  const updateStatus = useCallback(
    (
      internalId: number,
      newStatus: CommercialUploadStatus,
      message: string | null | undefined = null
    ) => {
      dispatch(
        updateCommercialRegistrationStatusAction.request({
          data: {
            internalId,
            newStatus,
            message,
          },
          showAll,
        })
      );
    },
    [dispatch, showAll]
  );

  const handleRowSelectionChange = useCallback(
    (newSelectedRows: Key[]): void => {
      setSelectedRows(newSelectedRows);
    },
    []
  );

  const handleApproveAll = useCallback(() => {
    state.commercialsForAudit
      .filter(({ id }) => selectedRows.includes(id))
      .map(({ id }) => updateStatus(id, CommercialUploadStatus.Approved));
    setSelectedRows([]);
  }, [selectedRows, state.commercialsForAudit, updateStatus]);

  const handleRejectAll = useCallback(
    (message: string) => {
      state.commercialsForAudit
        .filter(({ id }) => selectedRows.includes(id))
        .map(({ id }) =>
          updateStatus(id, CommercialUploadStatus.Rejected, message)
        );
      setSelectedRows([]);
    },
    [selectedRows, state.commercialsForAudit, updateStatus]
  );

  return (
    <ContentContainer>
      <Helmet>
        <title>{i18n._(t`Materiaal goed-/afkeuren`)}</title>
      </Helmet>

      <Container>
        <Spinner spinning={state.loading}>
          <Typography.Title>
            <Trans>Materiaal goed-/afkeuren</Trans>
          </Typography.Title>

          <Typography.Paragraph>
            <Trans>
              Met behulp van dit scherm is het mogelijk om het geüploade
              materiaal te beoordelen en vervolgens goed of af te keuren.
            </Trans>
          </Typography.Paragraph>

          <Space className={styles.auditSwitch}>
            Toon alles: <Switch checked={showAll} onChange={setShowAll} />
          </Space>

          <Space className={styles.auditButtonBar} direction="vertical">
            <ApproveAllButton
              disabled={selectedRows.length === 0}
              handleApproveAll={handleApproveAll}
              handleRejectAll={handleRejectAll}
            />
          </Space>
          <Table
            rowKey={({ id }) => id}
            pagination={false}
            className={styles.auditTable}
            rowSelection={{
              selectedRowKeys: selectedRows,
              hideSelectAll: false,
              onChange: handleRowSelectionChange,
              getCheckboxProps: ({ status }: CommercialUploadRegistration) => ({
                disabled: status !== CommercialUploadStatus.WaitForReview,
              }),
            }}
            columns={[
              {
                title: i18n._(t`Geüpload op`),
                key: "uploadedOn",
                width: 50,
                fixed: "left",
                ellipsis: true,
                render: (_, { uploadedOn }) => moment(uploadedOn).format("LLL"),
                defaultSortOrder: "descend",
                sorter: (a, b) =>
                  a.uploadedOn.getTime() - b.uploadedOn.getTime(),
              },
              {
                title: i18n._(t`Door`),
                key: "uploader",
                ellipsis: true,
                width: 50,
                render: (_, { uploader }) => (
                  <a href={`mailto:${uploader.email}`}>{uploader.name}</a>
                ),
              },
              {
                title: i18n._(t`Naam`),
                key: "name",
                width: 200,
                dataIndex: ["mediaFile", "name"],
              },
              {
                title: i18n._(t`Adverteerder`),
                key: "advertiserId",
                width: 200,
                render: (_, { advertiserId, uploader }) => (
                  <Space>
                    <span title={i18n._(t`Adverteerder ID: ${advertiserId}`)}>
                      {state.advertisers.find((a) => a.id === advertiserId)
                        ?.name ?? advertiserId}
                    </span>
                    <span
                      title={i18n._(
                        t`Organisatie code: ${uploader.organisationCode}`
                      )}
                    >
                      (
                      {state.organisations.find(
                        (o) => o.code === uploader.organisationCode
                      )?.name ?? uploader.organisationCode}
                      )
                    </span>
                  </Space>
                ),
              },
              {
                title: i18n._(t`Status`),
                key: "status",
                ellipsis: true,
                width: 50,
                render: (_, { status, message }) => {
                  switch (status) {
                    case CommercialUploadStatus.Approved:
                      return i18n._(`Goedgekeurd`);
                    case CommercialUploadStatus.Rejected:
                      return (
                        <Space align="center" direction="horizontal">
                          <span>{i18n._(`Afgekeurd`)}</span>
                          <Popover
                            title={i18n._(t`Reden voor het afkeuren`)}
                            content={
                              <ReactMarkdown>{message ?? ""}</ReactMarkdown>
                            }
                          >
                            <AntButton
                              icon={<Icons.QuestionIcon {...buttonIconSize} />}
                              type="link"
                              size="small"
                              shape="circle"
                            />
                          </Popover>
                        </Space>
                      );
                    case CommercialUploadStatus.WaitForReview:
                      return i18n._(`In afwachting`);
                    default:
                      return i18n._(`Onbekend`);
                  }
                },
              },
              {
                title: i18n._(t`Acties`),
                key: "actions",
                width: 360,
                fixed: "right",
                render: (
                  _,
                  { id, status, mediaFile }: CommercialUploadRegistration
                ) => (
                  <Space
                    className={styles.auditButtonBar}
                    direction="horizontal"
                  >
                    <MediaFilePreview mediaFile={mediaFile} />
                    <ReviewButtons
                      internalId={id}
                      status={status}
                      updateStatus={updateStatus}
                    />
                  </Space>
                ),
              },
            ]}
            dataSource={state.commercialsForAudit}
            scroll={{ x: "max-content", y: window.innerHeight }}
          />
        </Spinner>
      </Container>
    </ContentContainer>
  );
};

export default CommercialAudit;
