/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SimpleBreakSelection
 */
export interface SimpleBreakSelection {
  /**
   *
   * @type {string}
   * @memberof SimpleBreakSelection
   */
  breakId?: string | null;
  /**
   *
   * @type {Date}
   * @memberof SimpleBreakSelection
   */
  schedDate?: Date;
  /**
   *
   * @type {Date}
   * @memberof SimpleBreakSelection
   */
  schedStartTime?: Date;
  /**
   *
   * @type {number}
   * @memberof SimpleBreakSelection
   */
  grossSpotPrice?: number;
  /**
   *
   * @type {number}
   * @memberof SimpleBreakSelection
   */
  nettSpotPrice?: number;
  /**
   *
   * @type {string}
   * @memberof SimpleBreakSelection
   */
  preferredPosition?: string | null;
}

export function SimpleBreakSelectionFromJSON(json: any): SimpleBreakSelection {
  return SimpleBreakSelectionFromJSONTyped(json, false);
}

export function SimpleBreakSelectionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SimpleBreakSelection {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    breakId: !exists(json, "breakId") ? undefined : json["breakId"],
    schedDate: !exists(json, "schedDate")
      ? undefined
      : new Date(json["schedDate"]),
    schedStartTime: !exists(json, "schedStartTime")
      ? undefined
      : new Date(json["schedStartTime"]),
    grossSpotPrice: !exists(json, "grossSpotPrice")
      ? undefined
      : json["grossSpotPrice"],
    nettSpotPrice: !exists(json, "nettSpotPrice")
      ? undefined
      : json["nettSpotPrice"],
    preferredPosition: !exists(json, "preferredPosition")
      ? undefined
      : json["preferredPosition"],
  };
}

export function SimpleBreakSelectionToJSON(
  value?: SimpleBreakSelection | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    breakId: value.breakId,
    schedDate:
      value.schedDate === undefined ? undefined : value.schedDate.toISOString(),
    schedStartTime:
      value.schedStartTime === undefined
        ? undefined
        : value.schedStartTime.toISOString(),
    grossSpotPrice: value.grossSpotPrice,
    nettSpotPrice: value.nettSpotPrice,
    preferredPosition: value.preferredPosition,
  };
}
