import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

import { AnnouncementsApi } from "../../api/apis";
import apiConfig from "../../apiConfig";
import { toError } from "../utils";
import {
  deleteAnnouncementAction,
  receiveAnnouncementsAction,
  receivePublishedAnnouncementsAction,
  upsertAnnouncementAction,
} from "./actions";

export function* receiveAnnouncements(): SagaIterator {
  try {
    const announcementsApi = new AnnouncementsApi(apiConfig());
    const response = yield call(() =>
      announcementsApi.apiV1AnnouncementsAllGet()
    );
    yield put(receiveAnnouncementsAction.success(response));
  } catch (err) {
    yield put(receiveAnnouncementsAction.failure(toError(err)));
  }
}

export function* receivePublishedAnnouncements(): SagaIterator {
  try {
    const announcementsApi = new AnnouncementsApi(apiConfig());
    const response = yield call(() => announcementsApi.apiV1AnnouncementsGet());
    yield put(receivePublishedAnnouncementsAction.success(response));
  } catch (err) {
    yield put(receivePublishedAnnouncementsAction.failure(toError(err)));
  }
}

export function* upsertAnnouncement(
  action: ReturnType<typeof upsertAnnouncementAction.request>
): SagaIterator {
  try {
    const announcementsApi = new AnnouncementsApi(apiConfig());
    const response = yield call(() =>
      announcementsApi.apiV1AnnouncementsPost({
        announcementDto: action.payload,
      })
    );

    yield put(upsertAnnouncementAction.success(response));
    yield put(receivePublishedAnnouncementsAction.request());
  } catch (err) {
    yield put(upsertAnnouncementAction.failure(toError(err)));
  }
}

export function* deleteAnnouncement(
  action: ReturnType<typeof deleteAnnouncementAction.request>
): SagaIterator {
  try {
    const announcementsApi = new AnnouncementsApi(apiConfig());
    yield call(() =>
      announcementsApi.apiV1AnnouncementsIdDelete({ id: action.payload })
    );

    yield put(deleteAnnouncementAction.success());
    yield put(receivePublishedAnnouncementsAction.request());
  } catch (err) {
    yield put(deleteAnnouncementAction.failure(toError(err)));
  }
}
