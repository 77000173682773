import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

import { CampaignAnalysisApi } from "../../api";
import apiConfig from "../../apiConfig";
import { toError } from "../utils";
import { getAnalysisNmoNotificationAction } from "./actions";

// eslint-disable-next-line import/prefer-default-export
export function* getAnalysisNmoNotification(): SagaIterator {
  try {
    const settingsApi = new CampaignAnalysisApi(apiConfig());
    const response = yield call(() =>
      settingsApi.apiV1CampaignAnalysisHasTVCampaignOnNMOSwitchGet()
    );
    yield put(
      getAnalysisNmoNotificationAction.success({ result: response === "true" })
    );
  } catch (err) {
    yield put(getAnalysisNmoNotificationAction.failure(toError(err)));
  }
}
