import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { isSameDay } from "date-fns";
import moment from "moment";
import { memo, useCallback, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";

import { OrderDetail, OrderStatus, SubOrderDetail } from "../../../api";
import { receiveCampaignInstructionsAction } from "../../../store/campaignDetail/actions";
import { receiveCommercialsForProductIdAction } from "../../../store/commercials/actions";
import { StoreModel } from "../../../store/models";
import { InstructionConstants } from "../../../utils";
import { minCommercialDate } from "../../../utils/constants";
import {
  datesToDateRanges,
  getDaysInsidePeriod,
} from "../../../utils/dateHelper";
import AddInstructions from "../../partials/Instructions/AddInstructions";
import Instructions from "../../partials/Instructions/Instructions";
import { createSimpleSubOrder } from "../../partials/Instructions/utils";
import { campaignInstructionsRootSelector } from "../selectors";

const CampaignInstructions = memo(
  ({ campaign, canEdit }: { campaign: OrderDetail; canEdit: boolean }) => {
    const { i18n } = useLingui();

    /**
     * Construct the minimum start date for all suborders
     */
    const minDateSubOrders = useMemo(() => {
      const min = Math.min(
        ...(campaign.subOrders
          ?.filter((s) => moment(s.period.from))
          .map((s: SubOrderDetail) => s.period.from.getTime()) ??
          InstructionConstants.emptyTicks)
      );

      // Math.min(...[]) => Infinity
      return Math.abs(min) !== Infinity
        ? new Date(min)
        : campaign.orderStartDate;
    }, [campaign.orderStartDate, campaign.subOrders]);

    /**
     * Construct the maximum end date for an instruction
     */
    const maxDate = useMemo(() => {
      const max = Math.max(
        ...(campaign.subOrders
          ?.filter((s) => moment(s.period.to).isAfter(InstructionConstants.now))
          .map((s: SubOrderDetail) => s.period.to.getTime()) ??
          InstructionConstants.emptyTicks)
      );

      // Math.max(...[]) => Infinity
      return Math.abs(max) !== Infinity ? new Date(max) : campaign.orderEndDate;
    }, [campaign.orderEndDate, campaign.subOrders]);

    /**
     * Construct the minimum start date for an instruction
     */
    const minDateNewInstruction = useMemo(() => {
      const min = Math.min(
        ...(campaign.subOrders
          ?.filter((s) =>
            moment(s.period.to).isAfter(InstructionConstants.firstMoment)
          )
          .map((s: SubOrderDetail) =>
            moment(s.period.from).isAfter(InstructionConstants.firstMoment)
              ? s.period.from.getTime()
              : InstructionConstants.now.getTime()
          ) ?? InstructionConstants.emptyTicks)
      );

      // Math.min(...[]) => Infinity
      return Math.abs(min) !== Infinity
        ? new Date(min)
        : campaign.orderStartDate;
    }, [campaign.orderStartDate, campaign.subOrders]);

    const dispatch = useDispatch();
    const refresh = useCallback(() => {
      dispatch(
        receiveCampaignInstructionsAction.request({
          medium: campaign.medium,
          productId: campaign.productId,
          from: minDateSubOrders,
          to: maxDate,
          orderId: campaign.id,
        })
      );
    }, [
      campaign.id,
      campaign.medium,
      campaign.productId,
      dispatch,
      maxDate,
      minDateSubOrders,
    ]);

    useEffect(() => {
      refresh();
      dispatch(
        receiveCommercialsForProductIdAction.request({
          medium: campaign.medium,
          productId: campaign.productId,
          from: minCommercialDate,
          to: campaign.orderEndDate,
        })
      );
    }, [
      campaign.medium,
      campaign.orderEndDate,
      campaign.productId,
      dispatch,
      refresh,
    ]);

    const { loading, instructions, commercialsPerAdvertiser } = useSelector(
      (state: StoreModel) => campaignInstructionsRootSelector(state)
    );

    const title = campaign
      ? `${campaign?.campaignName || campaign?.productDescr} (${
          campaign?.medium
        })`
      : "...";

    const subOrders = useMemo(
      () => createSimpleSubOrder(campaign.subOrders),
      [campaign.subOrders]
    );

    const dateRangesWithoutInstructions = useMemo(() => {
      if (instructions) {
        // Bepaal de startdatum en eindatum van nieuwe instructies, deze mogen niet overlappen met andere instructies
        const daysInInstructions = instructions.flatMap(
          (s) => s.period && getDaysInsidePeriod(s.period.from, s.period.to)
        );
        const daysInPeriod = getDaysInsidePeriod(
          minDateNewInstruction,
          maxDate
        );

        const daysWithoutInstruction = daysInPeriod.filter(
          (s) => !daysInInstructions.some((d) => d && isSameDay(d, s))
        );

        return datesToDateRanges(daysWithoutInstruction);
      }
      return [];
    }, [instructions, maxDate, minDateNewInstruction]);

    const noInstructionPossible = useMemo(
      () => dateRangesWithoutInstructions.length === 0,
      [dateRangesWithoutInstructions.length]
    );

    return (
      <>
        <Helmet>
          <title>{i18n._(t`Uitzendinstructies: ${title}`)}</title>
        </Helmet>

        <AddInstructions
          enabled={
            canEdit &&
            [OrderStatus.Planned, OrderStatus.Active].includes(campaign.status)
          }
          instructions={instructions}
          commercialsPerAdvertiser={commercialsPerAdvertiser}
          onOk={refresh}
          maxDate={maxDate}
          medium={campaign.medium}
          productId={campaign.productId}
          subOrders={subOrders}
          dateRangesWithoutInstructions={dateRangesWithoutInstructions}
          okText={
            noInstructionPossible ? <Trans>OK</Trans> : <Trans>Indienen</Trans>
          }
        />

        <Instructions
          loading={loading}
          instructions={instructions}
          commercialsPerAdvertiser={commercialsPerAdvertiser}
          campaignPeriod={[
            moment(campaign.orderStartDate),
            moment(campaign.orderEndDate),
          ]}
          subOrders={subOrders}
        />
      </>
    );
  }
);

export default CampaignInstructions;
