/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ForecastTargetGroup,
  ForecastTargetGroupFromJSON,
  ForecastTargetGroupFromJSONTyped,
  ForecastTargetGroupToJSON,
} from "./";

/**
 *
 * @export
 * @interface ForecastConversionGroupsConversionGroups
 */
export interface ForecastConversionGroupsConversionGroups {
  /**
   *
   * @type {Array<ForecastTargetGroup>}
   * @memberof ForecastConversionGroupsConversionGroups
   */
  tv?: Array<ForecastTargetGroup>;
  /**
   *
   * @type {Array<ForecastTargetGroup>}
   * @memberof ForecastConversionGroupsConversionGroups
   */
  radio?: Array<ForecastTargetGroup>;
  /**
   *
   * @type {Array<ForecastTargetGroup>}
   * @memberof ForecastConversionGroupsConversionGroups
   */
  inter?: Array<ForecastTargetGroup>;
}

export function ForecastConversionGroupsConversionGroupsFromJSON(
  json: any
): ForecastConversionGroupsConversionGroups {
  return ForecastConversionGroupsConversionGroupsFromJSONTyped(json, false);
}

export function ForecastConversionGroupsConversionGroupsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ForecastConversionGroupsConversionGroups {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    tv: !exists(json, "tv")
      ? undefined
      : (json["tv"] as Array<any>).map(ForecastTargetGroupFromJSON),
    radio: !exists(json, "radio")
      ? undefined
      : (json["radio"] as Array<any>).map(ForecastTargetGroupFromJSON),
    inter: !exists(json, "inter")
      ? undefined
      : (json["inter"] as Array<any>).map(ForecastTargetGroupFromJSON),
  };
}

export function ForecastConversionGroupsConversionGroupsToJSON(
  value?: ForecastConversionGroupsConversionGroups | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    tv:
      value.tv === undefined
        ? undefined
        : (value.tv as Array<any>).map(ForecastTargetGroupToJSON),
    radio:
      value.radio === undefined
        ? undefined
        : (value.radio as Array<any>).map(ForecastTargetGroupToJSON),
    inter:
      value.inter === undefined
        ? undefined
        : (value.inter as Array<any>).map(ForecastTargetGroupToJSON),
  };
}
