import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Modal } from "@ster/ster-toolkit";
import { forwardRef, memo, useImperativeHandle, useState } from "react";

import errorMessages from "../errorMessages";

export type DownloadHandlerRef = {
  beginDownload?: (url: string) => Promise<void>;
};

const DownloadHandler = memo(
  forwardRef<DownloadHandlerRef>((_, ref) => {
    const [displayDownloadError, setDisplayDownloadError] = useState(false);
    const { i18n } = useLingui();

    useImperativeHandle(
      ref,
      () => ({
        beginDownload: (url: string) =>
          new Promise<void>((resolve, reject) => {
            (async () => {
              try {
                const response = await fetch(url);
                if (!response.ok) {
                  throw new Error("Network response was not ok");
                }

                // Generate download url
                const blob = await response.blob();
                const downloadUrl = window.URL.createObjectURL(blob);

                // Extract filename from Content-Disposition header if available
                const contentDisposition = response.headers.get(
                  "Content-Disposition"
                );
                let filename = url.split("/").pop() || "download";

                if (contentDisposition) {
                  const filenameMatch = contentDisposition.match(
                    /filename="?(.+?)"?($|;)/
                  );
                  const filenameStarMatch = contentDisposition.match(
                    /filename\*=UTF-8''(.+)/
                  );

                  if (filenameStarMatch) {
                    filename = decodeURIComponent(
                      filenameStarMatch[1].replace(/['"]/g, "")
                    );
                  } else if (filenameMatch) {
                    [, filename] = filenameMatch;
                    filename = decodeURIComponent(
                      filename.replace(/['"]/g, "")
                    );
                  }
                }

                // Generate temporary link, click and remove
                const a = document.createElement("a");
                a.href = downloadUrl;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(downloadUrl);
                resolve();
              } catch (error) {
                setDisplayDownloadError(true);
                reject(error);
              }
            })();
          }),
      }),
      [setDisplayDownloadError]
    );

    const handleOk = () => {
      setDisplayDownloadError(false);
    };

    return (
      <Modal
        open={displayDownloadError}
        title={<Trans>Download mislukt</Trans>}
        onCancel={handleOk}
        onOk={handleOk}
        footer={[
          <Button onClick={handleOk} mode="primary">
            OK
          </Button>,
        ]}
      >
        <Trans>Er ging iets mis bij het downloaden.</Trans>{" "}
        {i18n._(errorMessages.defaultMessage)}{" "}
        <a href="mailto:klantportal@ster.nl">klantportal@ster.nl</a>
      </Modal>
    );
  })
);

export default DownloadHandler;
