// eslint-disable-next-line no-shadow
export enum ReduxStoreState {
  Initial = "Initial",
  Loading = "Loading",
  Success = "Success",
  Failure = "Failure",
  IgnoredFailure = "IgnoredFailure",
  Unauthorized = "Unauthorized",
  Forbidden = "Forbidden",
}

export interface Loading {
  loading: boolean;
  state: ReduxStoreState;
  error?: ResponseError;
}

export interface ResponseError extends Error {
  status: number;
  detail?: string | null;
  validationResult?: Record<number, Record<number, string>>;
}

export interface OnSuccessPayload<T> {
  successProps?: T;
  onSuccess?: (props: T) => void;
}
