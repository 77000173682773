import { Trans } from "@lingui/macro";
import { Divider, Typography } from "@ster/ster-toolkit";

import styles from "./CommercialUpload.module.less";

const CommercialRequirements = () => (
  <>
    <Typography.Title>
      <Trans>Commercial uploaden</Trans>
    </Typography.Title>

    <Typography.Paragraph>
      <Trans>
        Met behulp van onderstaande formulier kun je een commercial uploaden
        voor specifieke adverteerders.
      </Trans>
    </Typography.Paragraph>

    <Typography.Paragraph>
      <Trans>
        <strong>Let op:</strong> Wacht tot je een bevestiging per mail ontvangt,
        vraag daarna pas je campagne aan. Op het moment dat je de campagne
        inboekt kun je de spot koppelen. Dit kan niet meer naderhand.
      </Trans>
    </Typography.Paragraph>

    <Typography.Title level={2}>
      <Trans>Aanleverspecificaties</Trans>
    </Typography.Title>
    <Typography.Paragraph className={styles.specifications}>
      <b>
        <Trans>Let op:</Trans>
      </b>{" "}
      <a
        href="https://www.ster.nl/hoe-werkt-het/maatschappelijke-organisaties-algemeen-nut/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Trans>
          bij het inkopen van online video of display mag de creatie (pre-roll
          of banner) geen betrekking hebben op het kopen van een bepaald product
          of het gebruikmaken van een bepaalde dienst die verkrijgbaar is.
        </Trans>
      </a>{" "}
      <Trans>
        De creatie mag wel oproepen tot steun aan of het gunstig stemmen ten
        aanzien van de adverteerder. Ster hanteert hiervoor de volgende
        richtlijnen:
      </Trans>
      <br />
      <ol>
        <li>
          <Trans>
            Adverteerder is een instelling met een wetenschappelijk, cultureel,
            godsdienstig, levensbeschouwelijk, politiek of liefdadig karakter;
          </Trans>
        </li>
        <li>
          <Trans>
            Adverteerder heeft een keurmerk van het Centraal Bureau
            Fondsenwerving (CBF) of de status van algemeen nut beogende
            instelling (ANBI-status);
          </Trans>
        </li>
        <li>
          <Trans>
            De Pre-roll of Banner van Adverteerder heeft geen betrekking op het
            kopen van een bepaald product of het gebruik maken van een bepaalde
            dienst die in de handel verkrijgbaar is, maar op het oproepen tot
            steun aan of het gunstig stemmen ten aanzien van Adverteerder.
          </Trans>
          <ol>
            <li>
              <Trans>
                In de spot mag nooit worden opgeroepen tot het kopen /
                reserveren van tickets of het aanmelden voor een opleiding,
                cursus of event;
              </Trans>
            </li>
            <li>
              <Trans>
                In de spot mag nooit een ‘call to action’ zitten. Dus niet
                “Bezoek nu het Ster Museum of “Neem zondag deel aan de
                livestream van de Ster Opera”, maar “Nu in het Ster Museum” of
                “De Ster Opera presenteert”;
              </Trans>
            </li>
            <li>
              <Trans>
                In de spot mag de website van de adverteerder worden vermeld,
                maar dit dient altijd de homepage te zijn. Er mag dus nooit
                worden verwezen naar een specifieke ticketpagina, zoals
                www.ster.nl/tickets;
              </Trans>
            </li>
          </ol>
        </li>
      </ol>
      <Divider />
      <Typography.Title level={3}>
        <Trans>Pre-roll (online video)</Trans>
      </Typography.Title>
      <ul>
        <li>
          <Trans>Extensie: MP4 (maximaal 100 MB)</Trans>
        </li>
        <li>
          <Trans>Lengte: maximaal 36 seconden</Trans>
        </li>
        <li>
          <Trans>Beelden per seconde (frame rate): 25 fps</Trans>
        </li>
        <li>
          <Trans>Audionorm: Full scale digital audio</Trans>
        </li>
        <li>
          <Trans>Resolutie: Full HD (1920x1080)</Trans>
        </li>
        <li>
          <Trans>
            Geen leestekens in de benaming van het aan te leveren bestand
          </Trans>
        </li>
        <li>
          <Trans>
            Het Ster-logo en het aantal videosecondes zijn zichtbaar bij het
            afspelen van de video. Het advies is daarom om het merklogo
            rechtsboven te plaatsen.
          </Trans>
        </li>
      </ul>
      <Divider />
      <Typography.Title level={3}>
        <Trans>Display</Trans>
      </Typography.Title>
      <ul>
        <li>
          <Trans>JPG, PNG of (geanimeerde) GIF</Trans>
        </li>
        <li>
          <Trans>
            HTML (in de code van een HTML- banner moet een “window.open()”
            functie zitten, tevens dient het HTML-bestand in de root van de ZIP
            te zitten)
          </Trans>
        </li>
        <li>
          <Trans>
            Gelieve altijd minimaal het formaat 300x250 (medium rectangle)
            aanleveren
          </Trans>
        </li>
      </ul>
      <table className={styles.bannerSizes}>
        <thead>
          <tr>
            <th>
              <Trans>Formaat</Trans>
            </th>
            <th>
              <Trans>Pixels</Trans>
            </th>
            <th>
              <Trans>Max Grootte</Trans>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Billboard</td> <td>970x250</td> <td>1 MB</td>
          </tr>
          <tr>
            <td>Halfpage ad</td> <td>300x600</td>
            <td>500 KB</td>
          </tr>
          <tr>
            <td>Medium rectangle</td> <td>300x250</td> <td>500 KB</td>
          </tr>
          <tr>
            <td>Skyscraper</td> <td>120x600</td> <td>500 KB</td>
          </tr>
          <tr>
            <td>Wide skyscraper</td> <td>160x600</td>
            <td>500 KB</td>
          </tr>
          <tr>
            <td>Leaderboard</td> <td>728x90</td> <td>500 KB</td>
          </tr>
          <tr>
            <td>Mobile banner</td> <td>300x100</td> <td>500 KB</td>
          </tr>
          <tr>
            <td>Mobile midscroller</td> <td>320x240</td> <td>500 KB</td>
          </tr>
          <tr>
            <td>Mobile portrait</td> <td>320x400</td> <td>500 KB</td>
          </tr>
        </tbody>
      </table>
      <Trans>
        <p>
          Voor het aanleveren van video in display neem contact op met{" "}
          <a href="mailto:digital@ster.nl">digital@ster.nl</a>
        </p>
        <p>
          Het materiaal mag in geen gevallen cookies bevatten. <br />
          Eventuele UTM-codes zijn toegestaan in de landingspagina.
        </p>
      </Trans>
    </Typography.Paragraph>
  </>
);

export default CommercialRequirements;
