import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Modal } from "@ster/ster-toolkit";
import { Alert, Typography } from "antd";
import moment from "moment";
import { ReactNode, memo, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import {
  CommercialsPerAdvertiser,
  OnlineInstructionPerPeriod,
} from "../../../api";
import { ReduxStoreState } from "../../../store/base";
import { StoreModel } from "../../../store/models";
import { InstructionConstants } from "../../../utils";
import styles from "./OnlineInstructionModal.module.less";
import { OnlineCommercialPicker } from "./Pickers/OnlineCommercialPicker";
import { OnlineInstructionsPerSubOrder, SimpleOnlineSubOrder } from "./types";
import { emptyOnlineSpotsPerPeriod, validateLandingsPage } from "./utils";

interface OnlineInstructionModalProps {
  onCancel: (e: React.MouseEvent<HTMLElement>) => void;
  onOk: (e?: React.MouseEvent<HTMLElement>) => void;
  commercialsPerAdvertiser?: CommercialsPerAdvertiser;
  maxDate: Date;
  okText?: ReactNode;
  onSave?: (instructions: OnlineInstructionsPerSubOrder[]) => void;
  subOrders: SimpleOnlineSubOrder[];
}

const OnlineInstructionModal = memo(
  ({
    onCancel,
    onOk,
    commercialsPerAdvertiser,
    maxDate,
    okText,
    onSave,
    subOrders,
  }: OnlineInstructionModalProps) => {
    const { i18n } = useLingui();

    const { loadingState } = useSelector(
      ({
        account: { email },
        saveCampaignInstructionsOnline: { state },
      }: StoreModel) => ({
        contactPersonAgencyEmail: email,
        loadingState: state,
      })
    );

    const [spotsPerSubOrder, setSpotsPerSubOrder] = useState<
      OnlineInstructionsPerSubOrder[]
    >(() =>
      subOrders.map((s) => ({
        subOrderId: s.id ?? 0,
        instructionsPerPeriod:
          s.onlineInstructionsPerPeriod?.length > 0
            ? s.onlineInstructionsPerPeriod
            : emptyOnlineSpotsPerPeriod,
      }))
    );

    const handleSelectedSpotsPerPeriod = useCallback(
      (subOrderId: number, values: OnlineInstructionPerPeriod[]) => {
        const index = spotsPerSubOrder
          .map((c) => c.subOrderId)
          .indexOf(subOrderId);
        const newValue = [
          ...spotsPerSubOrder.slice(0, index),
          { ...spotsPerSubOrder[index], instructionsPerPeriod: values },
          ...spotsPerSubOrder.slice(index + 1, spotsPerSubOrder.length),
        ];
        setSpotsPerSubOrder(newValue);
      },
      [spotsPerSubOrder]
    );

    const saveBusy = useMemo(
      () => loadingState === ReduxStoreState.Loading,
      [loadingState]
    );

    const hasInvalidUrl = useMemo(
      () =>
        spotsPerSubOrder.some((s) =>
          s.instructionsPerPeriod?.some((o) =>
            o.instructions?.some(
              (sp) => sp.commercial && !validateLandingsPage(sp.homepage)
            )
          )
        ),
      [spotsPerSubOrder]
    );

    const saveDisabled = useMemo(
      () =>
        hasInvalidUrl ||
        !spotsPerSubOrder.some((s) =>
          s.instructionsPerPeriod?.some((x) =>
            x.instructions?.some((i) => i.commercial || i.banner)
          )
        ),
      [hasInvalidUrl, spotsPerSubOrder]
    );

    const handleOk = useCallback(() => {
      if (saveDisabled || hasInvalidUrl) {
        return;
      }

      const values = spotsPerSubOrder
        .map((s) => ({
          ...s,
          instructionsPerPeriod: s.instructionsPerPeriod
            ?.map((i) => ({
              ...i,
              instructions: i.instructions?.filter(
                (x) => x.commercial || x.banner
              ),
            }))
            .filter((x) => (x.instructions?.length ?? 0) > 0),
        }))
        .filter((x) => (x.instructionsPerPeriod?.length ?? 0) > 0);

      onSave?.(values);
      onOk();
    }, [hasInvalidUrl, onOk, onSave, saveDisabled, spotsPerSubOrder]);

    return (
      <Modal
        open
        onCancel={onCancel}
        cancelText={<Trans>Annuleren</Trans>}
        onOk={handleOk}
        okText={okText ?? <Trans>Indienen</Trans>}
        okButtonProps={{ disabled: saveDisabled, loading: saveBusy }}
        title={i18n._(
          subOrders.filter((s) => s.onlineInstructionsPerPeriod).length > 0
            ? t`Instructie bewerken`
            : t`Instructie toevoegen`
        )}
        width="100%"
        className={styles.instructionModal}
      >
        <div className={styles.intro}>
          <Trans>
            Wijkt je instructie af? Neem dan contact op met{" "}
            <a href="mailto:digital@ster.nl">Ster</a> en we helpen je graag.
          </Trans>
        </div>
        {moment(maxDate).isAfter(InstructionConstants.now) ? (
          <>
            {subOrders.map((subOrder) => {
              const spotsPerPeriod = spotsPerSubOrder.find(
                (s) => s.subOrderId === subOrder.id
              );
              return (
                <OnlineCommercialPicker
                  key={subOrder.id}
                  subOrder={subOrder}
                  commercialsPerAdvertiser={commercialsPerAdvertiser}
                  spotLength={subOrder.spotLength ?? []}
                  value={spotsPerPeriod?.instructionsPerPeriod ?? []}
                  onChange={(values) =>
                    handleSelectedSpotsPerPeriod(subOrder.id ?? 0, values)
                  }
                  enableAdd
                  enableRemove
                />
              );
            })}

            {hasInvalidUrl && (
              <Alert
                className={styles.warning}
                showIcon
                type="error"
                message=""
                description={i18n._(
                  t`Niet alle instructies bevatten de verplichte landingspagina.`
                )}
              />
            )}
          </>
        ) : (
          <Typography.Paragraph>
            <Trans>
              Het is niet meer mogelijk om uitzendinstructies door te geven.
            </Trans>
          </Typography.Paragraph>
        )}
      </Modal>
    );
  }
);

export default OnlineInstructionModal;
