import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form, Radio } from "@ster/ster-toolkit";
import { memo, useMemo } from "react";

import { Advertiser, VolumeDiscount } from "../../../api";
import { UniqueStrings, getVolumeDiscountTranslation } from "../../../utils";

const VolumeDiscounts = memo(
  ({ prospectAdvertisers }: { prospectAdvertisers: Advertiser[] }) => {
    const { i18n } = useLingui();

    const discounts = useMemo(
      () =>
        UniqueStrings(
          prospectAdvertisers
            .filter((s) => s.volumeDiscount)
            .map((s) => s.volumeDiscount!.toString())
        )
          .sort()
          .map((s) => ({
            key: s,
            translation: getVolumeDiscountTranslation(
              VolumeDiscount[s as keyof typeof VolumeDiscount]
            ),
          })),
      [prospectAdvertisers]
    );

    return (
      <Form.Item label={i18n._(t`Volumekorting`)} name="volumeDiscount">
        <Radio.Group mode="vertical">
          {discounts.map((discount) => (
            <Radio value={discount.key} key={discount.key}>
              <Trans>{i18n._(discount.translation)}</Trans>
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
    );
  }
);

export default VolumeDiscounts;
