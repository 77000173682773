import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form, Option, Select } from "@ster/ster-toolkit";
import { memo, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ReduxStoreState } from "../../../store/base";
import {
  receiveCustomerAccountsAction,
  receiveCustomerContactClearAction,
  receiveCustomerContactsAction,
  receiveCustomerOpportunitiesAction,
  receiveCustomerOpportunitiesClearAction,
} from "../../../store/campaignCreate/actions";
import { getOrganisationCode } from "../../../utils";
import { internalAdverteerder } from "../../../utils/constants";
import { usePrevious } from "../../../utils/hooks";
import { FormReadOnlyItem } from "../../partials/Form";
import { campaignCreateDetailSelector } from "../selectors";
import styles from "./Details.module.less";

const DetailsCrm = memo(
  ({
    advertiserId,
    crmAccount,
    crmOpportunity,
    crmContact,
    editable,
  }: {
    advertiserId?: number;
    crmAccount?: string | null;
    crmOpportunity?: string | null;
    crmContact?: string | null;
    editable?: boolean;
  }) => {
    const { i18n } = useLingui();
    const previousAdvertiserId = usePrevious(advertiserId);
    const previousCrmAccount = usePrevious(crmAccount);
    const dispatch = useDispatch();
    const {
      customerAccounts,
      customerOpportunities,
      customerAccountsState,
      customerOpportunitiesState,
      customerContacts,
      customerContactsState,
      account,
      settings,
    } = useSelector(campaignCreateDetailSelector);

    const isProspectOrganisation = useMemo(
      () => getOrganisationCode(account) === internalAdverteerder,
      [account]
    );

    const loadingCustomerAccounts = useMemo(
      () => customerAccountsState === ReduxStoreState.Loading,
      [customerAccountsState]
    );

    useEffect(() => {
      if (
        isProspectOrganisation &&
        customerAccountsState === ReduxStoreState.Initial
      ) {
        dispatch(receiveCustomerAccountsAction.request());
      }
    }, [
      customerAccounts,
      customerAccountsState,
      dispatch,
      isProspectOrganisation,
    ]);

    useEffect(() => {
      if (
        advertiserId !== previousAdvertiserId ||
        crmAccount !== previousCrmAccount
      ) {
        dispatch(receiveCustomerOpportunitiesClearAction());
        dispatch(receiveCustomerContactClearAction());
      }
    }, [
      advertiserId,
      crmAccount,
      dispatch,
      previousAdvertiserId,
      previousCrmAccount,
    ]);

    useEffect(() => {
      if (isProspectOrganisation) {
        if (crmAccount) {
          if (customerOpportunitiesState === ReduxStoreState.Initial) {
            dispatch(
              receiveCustomerOpportunitiesAction.request({
                accountId: crmAccount,
              })
            );
          }

          if (customerContactsState === ReduxStoreState.Initial) {
            dispatch(
              receiveCustomerContactsAction.request({
                accountId: crmAccount,
              })
            );
          }
        }
      } else if (advertiserId) {
        if (customerOpportunitiesState === ReduxStoreState.Initial) {
          dispatch(
            receiveCustomerOpportunitiesAction.request({
              advertiserId,
            })
          );
        }
        if (customerContactsState === ReduxStoreState.Initial) {
          dispatch(
            receiveCustomerContactsAction.request({
              advertiserId,
            })
          );
        }
      }
    }, [
      advertiserId,
      crmAccount,
      customerContactsState,
      customerOpportunitiesState,
      dispatch,
      isProspectOrganisation,
    ]);

    if (!account.sterInlog.internalUser || !settings.settings?.crmEnabled) {
      return null;
    }

    return editable ? (
      <>
        {isProspectOrganisation && (
          <Form.Item
            label={i18n._(t`CRM Account`)}
            name="crmAccount"
            rules={[
              {
                required:
                  loadingCustomerAccounts || customerAccounts?.length > 0,
                message: i18n._(t`Kies een account`),
              },
            ]}
          >
            <Select.Search
              disabled={
                !customerAccounts || !advertiserId || loadingCustomerAccounts
              }
              placeholder={i18n._(t`Kies een account`)}
              optionFilterProp="children"
              className={styles.formInputWidth}
              loading={loadingCustomerAccounts}
            >
              {customerAccounts.map((s) => (
                <Option value={s.id} key={s.id}>
                  {s.name}
                </Option>
              ))}
            </Select.Search>
          </Form.Item>
        )}
        <Form.OptionalItem
          label={i18n._(t`CRM Verkoopkans`)}
          name="crmOpportunity"
          optionalLabel={i18n._(t`(optioneel)`)}
        >
          <Select.Search
            disabled={
              !advertiserId ||
              (isProspectOrganisation && !crmAccount) ||
              !customerOpportunities ||
              customerOpportunitiesState === ReduxStoreState.Loading
            }
            placeholder={i18n._(t`Kies een verkoopkans`)}
            optionFilterProp="children"
            className={styles.formInputWidth}
            loading={customerOpportunitiesState === ReduxStoreState.Loading}
          >
            <Option value="" key={null}>
              <Trans>Nieuwe verkoopkans</Trans>
            </Option>
            {customerOpportunities.map((s) => (
              <Option value={s.id} key={s.id}>
                {s.name}
              </Option>
            ))}
          </Select.Search>
        </Form.OptionalItem>
        {!crmOpportunity && (
          <Form.OptionalItem
            label={i18n._(t`CRM Contact`)}
            name="crmContact"
            optionalLabel={i18n._(t`(optioneel)`)}
          >
            <Select.Search
              disabled={
                !advertiserId ||
                (isProspectOrganisation && !crmAccount) ||
                customerContactsState === ReduxStoreState.Loading
              }
              placeholder={i18n._(t`Kies een contactpersoon`)}
              optionFilterProp="children"
              className={styles.formInputWidth}
              loading={customerContactsState === ReduxStoreState.Loading}
            >
              {customerContacts.map((s) => (
                <Option value={s.contactId} key={s.contactId}>
                  {s.name}
                </Option>
              ))}
            </Select.Search>
          </Form.OptionalItem>
        )}
      </>
    ) : (
      <>
        {isProspectOrganisation && (
          <FormReadOnlyItem
            label={i18n._(t`CRM Account`)}
            value={customerAccounts.find((s) => s.id === crmAccount)?.name}
          />
        )}
        <FormReadOnlyItem
          label={i18n._(t`CRM Verkoopkans`)}
          value={
            customerOpportunities.find((s) => s.id === crmOpportunity)?.name ??
            i18n._(t`Nieuwe verkoopkans`)
          }
          hidden={customerOpportunitiesState !== ReduxStoreState.Success}
        />
        {crmContact && (
          <FormReadOnlyItem
            label={i18n._(t`CRM Contact`)}
            value={
              customerContacts.find((s) => s.contactId === crmContact)?.name
            }
            hidden={customerContactsState !== ReduxStoreState.Success}
          />
        )}
      </>
    );
  }
);

export default DetailsCrm;
