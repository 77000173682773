import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { memo } from "react";

import SimplePage from "./SimplePage";

const NotFound = memo(() => {
  const { i18n } = useLingui();
  return (
    <SimplePage title={i18n._(t`Niet gevonden`)}>
      <Trans>
        <p>Deze pagina is niet gevonden.</p>
      </Trans>
    </SimplePage>
  );
});

export default NotFound;
