import { createAction, createAsyncAction } from "typesafe-actions";

import {
  Advertiser,
  ApiV1CampaignForwardContactsGetRequest,
  ApiV1CampaignForwardInitialRequestIdPostRequest,
  ApiV1CampaignForwardProductsMediumGetRequest,
  CampaignForwarded,
  CampaignImportOutput,
  SimplePortalUser,
} from "../../api";
import { ResponseError } from "../base";
import { actionNameAsync } from "../utils";
import { AvailableCampaignDetailFailurePayload } from "./models";

export const receiveContactsForForwardAction = createAsyncAction(
  actionNameAsync("receiveContactsForForward", "REQUEST"),
  actionNameAsync("receiveContactsForForward", "SUCCESS"),
  actionNameAsync("receiveContactsForForward", "FAILURE")
)<ApiV1CampaignForwardContactsGetRequest, SimplePortalUser[], ResponseError>();

export const receiveProductsForForwardAction = createAsyncAction(
  actionNameAsync("receiveProductsForForward", "REQUEST"),
  actionNameAsync("receiveProductsForForward", "SUCCESS"),
  actionNameAsync("receiveProductsForForward", "FAILURE")
)<ApiV1CampaignForwardProductsMediumGetRequest, Advertiser[], ResponseError>();

export const forwardCampaignAction = createAsyncAction(
  actionNameAsync("forwardCampaign", "REQUEST"),
  actionNameAsync("forwardCampaign", "SUCCESS"),
  actionNameAsync("forwardCampaign", "FAILURE")
)<
  ApiV1CampaignForwardInitialRequestIdPostRequest,
  CampaignForwarded,
  ResponseError
>();

export const clearForwardCampaignAction = createAction(
  "FORWARDCAMPAIGN_CLEAR"
)();

export const receiveAvailableCampaignsAction = createAsyncAction(
  actionNameAsync("availableCampaigns", "REQUEST"),
  actionNameAsync("availableCampaigns", "SUCCESS"),
  actionNameAsync("availableCampaigns", "FAILURE")
)<undefined, CampaignForwarded[], ResponseError>();

export const receiveAvailableCampaignDetailAction = createAsyncAction(
  actionNameAsync("availableCampaignDetail", "REQUEST"),
  actionNameAsync("availableCampaignDetail", "SUCCESS"),
  actionNameAsync("availableCampaignDetail", "FAILURE")
)<number, CampaignForwarded, AvailableCampaignDetailFailurePayload>();

export const acceptAvailableCampaignAction = createAsyncAction(
  actionNameAsync("acceptAvailableCampaign", "REQUEST"),
  actionNameAsync("acceptAvailableCampaign", "SUCCESS"),
  actionNameAsync("acceptAvailableCampaign", "FAILURE")
)<number, CampaignImportOutput, ResponseError>();

export const clearAcceptAvailableCampaignsAction = createAction(
  "ACCEPTAVAILABLECAMPAIGN_CLEAR"
)();

export const rejectAvailableCampaignAction = createAsyncAction(
  actionNameAsync("rejectAvailableCampaign", "REQUEST"),
  actionNameAsync("rejectAvailableCampaign", "SUCCESS"),
  actionNameAsync("rejectAvailableCampaign", "FAILURE")
)<number, undefined, ResponseError>();

export const clearRejectAvailableCampaignsAction = createAction(
  "REJECTAVAILABLECAMPAIGN_CLEAR"
)();
