import { Form, Spinner } from "@ster/ster-toolkit";
import { Form as AntForm } from "antd";
import { Store } from "antd/lib/form/interface";
import classNames from "classnames";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import TagManager from "react-gtm-module";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { SalesPeriod, VolumeDiscount } from "../../../api";
import {
  receiveCustomerContactClearAction,
  receiveCustomerOpportunitiesClearAction,
} from "../../../store/campaignCreate/actions";
import { formItemLayout } from "../../partials/Form";
import { CampaignDetailProps } from "../models";
import styles from "./Details.module.less";
import DetailsEdit from "./DetailsEdit";
import DetailsRead from "./DetailsRead";

// eslint-disable-next-line no-shadow
export enum AdvertiserTypeEnum {
  Existing = "existing",
  Prospect = "prospect",
}

const Details = memo(
  ({
    onFinish,
    onUpdate,
    order,
    editable,
    loading,
    salesPeriods,
    productsByAdvertiser,
    settings,
  }: CampaignDetailProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedSalesPeriods, updateSelectedSalesPeriods] =
      useState<SalesPeriod[]>();
    const [form] = AntForm.useForm();
    const [advertiserType, setAdvertiserType] = useState<AdvertiserTypeEnum>(
      AdvertiserTypeEnum.Existing
    );

    useEffect((): void => {
      if (order) {
        // Vul het formulier met de bestaande order
        form.setFieldsValue({
          ...order,
        });

        if (order.period) {
          // Zoek de index die hoort bij de geselecteerde periode
          form.setFieldsValue({
            period: salesPeriods
              ?.find((s) => s.medium === order.medium)
              ?.result.findIndex(
                (s) =>
                  order.period.from >= s.salesPeriodStartDate &&
                  order.period.to <= s.salesPeriodEndDate
              ),
          });
        }

        // Selecteer de verkoopperiode's voor het geselecteerde medium
        updateSelectedSalesPeriods(
          salesPeriods?.find((s) => s.medium === order.medium)?.result ??
            undefined
        );
      }
    }, [
      order,
      form,
      updateSelectedSalesPeriods,
      salesPeriods,
      productsByAdvertiser,
    ]);

    const codeCampaign = useCallback(() => {
      navigate("/campaigns/code", { state: { from: window.location.search } });
      TagManager.dataLayer({
        dataLayer: {
          event: "Importeren met een code",
        },
      });
    }, [navigate]);

    const prospectAdvertisers = useMemo(
      () => productsByAdvertiser.filter(({ isProspect }) => isProspect),
      [productsByAdvertiser]
    );

    const handleFormChange = useCallback(
      (changedValues: Store, values: Store) => {
        const resetFields = [];
        const defaultValues: Record<string, unknown> = {};

        if (changedValues.advertiserType) {
          setAdvertiserType(changedValues.advertiserType as AdvertiserTypeEnum);

          if (
            (changedValues.advertiserType as AdvertiserTypeEnum) ===
            AdvertiserTypeEnum.Prospect
          ) {
            // Pak de prospect adverteerder zonder kortingen
            defaultValues.advertiserId = prospectAdvertisers.find(
              (s) => s.volumeDiscount === VolumeDiscount.VolumeDiscount0
            )?.id;
            defaultValues.volumeDiscount = VolumeDiscount.VolumeDiscount0;
          } else {
            resetFields.push("advertiserId", "productId");
          }
        }

        // Volume korting (vanaf 2024)
        if (changedValues.volumeDiscount) {
          resetFields.push("productId");

          // Kies de prospect adverteerder met de betreffende korting
          defaultValues.advertiserId = prospectAdvertisers.find(
            ({ volumeDiscount }) =>
              volumeDiscount === changedValues.volumeDiscount
          )?.id;
          if (!defaultValues.advertiserId) {
            // Als er geen prospect adverteerder met de betreffende kortingen gevonden kan worden resetten we de kortingen
            defaultValues.advertiserId = prospectAdvertisers.find(
              (s) => !s.volumeDiscount
            )?.id;
            resetFields.push("volumeDiscount");
          }
        }

        if (changedValues.type === "code") {
          codeCampaign();
        }

        if (changedValues.medium) {
          resetFields.push("period", "advertiserId", "productId");
          setAdvertiserType(AdvertiserTypeEnum.Existing);
        }

        if (changedValues.period !== undefined) {
          resetFields.push("advertiserId", "productId");
          defaultValues.advertiserType = "existing";
          setAdvertiserType(AdvertiserTypeEnum.Existing);
        }

        if (changedValues.advertiserId) {
          resetFields.push("productId");
        }

        if (changedValues.crmAccount || changedValues.advertiserId) {
          resetFields.push("crmOpportunity", "crmContact");
          dispatch(receiveCustomerOpportunitiesClearAction());
          dispatch(receiveCustomerContactClearAction());
        }

        if (changedValues.crmOpportunity) {
          // Reset het contact als de verkoopkans wijzigt.
          resetFields.push("crmContact");
        }

        let newValues = { ...values };
        if (values.period !== undefined) {
          // De value van de period die teruggegeven wordt is de index van de dropdown, pas deze aan naar de daadwerkelijke periode
          const { salesPeriodStartDate, salesPeriodEndDate } =
            salesPeriods?.find((s) => s.medium === order?.medium)?.result[
              values.period
            ] ?? {};
          newValues.period = {
            from: salesPeriodStartDate,
            to: salesPeriodEndDate,
          };
        }

        newValues = {
          ...newValues,
          ...Object.assign(
            // Alle velden die gereset moeten worden krijgen de waarde undefined
            {},
            ...resetFields.map((s) => ({ [s]: undefined }))
          ),
          ...defaultValues,
        };
        onUpdate(newValues);
      },
      [
        codeCampaign,
        dispatch,
        onUpdate,
        order?.medium,
        prospectAdvertisers,
        salesPeriods,
      ]
    );

    return (
      <Spinner spinning={loading}>
        <Form
          {...formItemLayout}
          layout="horizontal"
          className={classNames(styles.form, formItemLayout.className)}
          form={form}
          onValuesChange={handleFormChange}
          onFinish={onFinish}
          name="details"
          initialValues={{ type: "new" }}
        >
          {editable ? (
            <DetailsEdit
              advertiserType={advertiserType}
              form={form}
              order={order}
              productsByAdvertiser={productsByAdvertiser}
              selectedSalesPeriods={selectedSalesPeriods}
              onUpdate={onUpdate}
              settings={settings}
            />
          ) : (
            <DetailsRead
              order={order}
              productsByAdvertiser={productsByAdvertiser}
              settings={settings}
            />
          )}
        </Form>
      </Spinner>
    );
  }
);

export default Details;
