/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface PortalSettingsEnableEditCampaign
 */
export interface PortalSettingsEnableEditCampaign {
  /**
   *
   * @type {boolean}
   * @memberof PortalSettingsEnableEditCampaign
   */
  tv: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PortalSettingsEnableEditCampaign
   */
  radio: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PortalSettingsEnableEditCampaign
   */
  inter: boolean;
}

export function PortalSettingsEnableEditCampaignFromJSON(
  json: any
): PortalSettingsEnableEditCampaign {
  return PortalSettingsEnableEditCampaignFromJSONTyped(json, false);
}

export function PortalSettingsEnableEditCampaignFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PortalSettingsEnableEditCampaign {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    tv: json["tv"],
    radio: json["radio"],
    inter: json["inter"],
  };
}

export function PortalSettingsEnableEditCampaignToJSON(
  value?: PortalSettingsEnableEditCampaign | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    tv: value.tv,
    radio: value.radio,
    inter: value.inter,
  };
}
