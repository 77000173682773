import { Trans } from "@lingui/macro";
import { formatNumber, formatToEuro } from "@ster/ster-toolkit";
import { Alert } from "antd";
import { memo } from "react";

import { AvailableBreak } from "../../../api";

const BookSpotStatus = memo(
  ({
    addedItems,
    deletedItems,
    balance,
    grpBalance,
    handleDetailsClick,
  }: {
    addedItems: AvailableBreak[];
    deletedItems: AvailableBreak[];
    balance: number;
    grpBalance: number;
    handleDetailsClick: () => void;
  }) => (
    <Alert
      showIcon
      type="info"
      message=""
      description={
        <div>
          <Trans>
            Af: {deletedItems.length} spot(s), netto:{" "}
            {formatToEuro(
              deletedItems.reduce((a, b) => a + b.nettSpotPrice, 0),
              false
            )}{" "}
            | Bij: {addedItems.length} spot(s), netto:{" "}
            {formatToEuro(
              addedItems.reduce((a, b) => a + b.nettSpotPrice, 0),
              false
            )}
          </Trans>{" "}
          {(deletedItems.length > 0 || addedItems.length > 0) && (
            <>
              |{" "}
              <button
                type="button"
                className="link"
                onClick={handleDetailsClick}
              >
                <Trans>Details</Trans>
              </button>{" "}
            </>
          )}
          |{" "}
          <span>
            {balance > 0 ? <Trans>Overscore</Trans> : <Trans>Onderscore</Trans>}
          </span>
          : {formatToEuro(balance, false)} ({formatNumber(grpBalance ?? 0, 2)}{" "}
          GRP&apos;s)
        </div>
      }
    />
  )
);

export default BookSpotStatus;
