/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface AppInsights
 */
export interface AppInsights {
  /**
   *
   * @type {string}
   * @memberof AppInsights
   */
  instrumentationKey?: string;
  /**
   *
   * @type {string}
   * @memberof AppInsights
   */
  cloudRoleNameApi?: string | null;
  /**
   *
   * @type {string}
   * @memberof AppInsights
   */
  cloudRoleNameUi?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof AppInsights
   */
  enableDebug?: boolean;
}

export function AppInsightsFromJSON(json: any): AppInsights {
  return AppInsightsFromJSONTyped(json, false);
}

export function AppInsightsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AppInsights {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    instrumentationKey: !exists(json, "instrumentationKey")
      ? undefined
      : json["instrumentationKey"],
    cloudRoleNameApi: !exists(json, "cloudRoleNameApi")
      ? undefined
      : json["cloudRoleNameApi"],
    cloudRoleNameUi: !exists(json, "cloudRoleNameUi")
      ? undefined
      : json["cloudRoleNameUi"],
    enableDebug: !exists(json, "enableDebug") ? undefined : json["enableDebug"],
  };
}

export function AppInsightsToJSON(value?: AppInsights | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    instrumentationKey: value.instrumentationKey,
    cloudRoleNameApi: value.cloudRoleNameApi,
    cloudRoleNameUi: value.cloudRoleNameUi,
    enableDebug: value.enableDebug,
  };
}
