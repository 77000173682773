import { createAsyncAction } from "typesafe-actions";

import { Invoice } from "../../api/models";
import { ResponseError } from "../base";
import { actionNameAsync } from "../utils";
import { InvoiceRequestPayload } from "./models";

// eslint-disable-next-line import/prefer-default-export
export const receiveInvoicesAction = createAsyncAction(
  actionNameAsync("invoices", "REQUEST"),
  actionNameAsync("invoices", "SUCCESS"),
  actionNameAsync("invoices", "FAILURE")
)<InvoiceRequestPayload, Invoice[], ResponseError>();
