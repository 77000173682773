import "./steps.less";

import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Icons, Tooltip, Typography } from "@ster/ster-toolkit";
import { PropsWithChildren } from "react";

import { StepStatus } from "./Steps";

interface StepProps {
  title: string;
  status: StepStatus;
  onEditClick?: () => void;
  allowEdit: boolean;
  showEdit?: boolean;
}

const StatusIcon = ({ status }: { status: StepStatus }) =>
  status === "finished" ? (
    <Icons.CheckIcon fill="#008ccc" />
  ) : (
    <span className="step-icon--empty" />
  );

const Step = ({
  title,
  status,
  children,
  onEditClick,
  allowEdit,
  showEdit = true,
}: PropsWithChildren<StepProps>) => {
  const { i18n } = useLingui();
  return (
    <section className={`step ${status === "process" ? "step--active" : ""}`}>
      <div className="step-container">
        <div className="step-titlewrapper">
          <StatusIcon status={status} />
          <Typography.Title className="step-title" level={2}>
            {title}
          </Typography.Title>
        </div>
        {showEdit && status === "finished" && (
          <Tooltip
            title={
              allowEdit
                ? ""
                : i18n._(
                    t`Je kunt deze gegevens bewerken als je de andere stap afgesloten hebt`
                  )
            }
            placement="bottom"
          >
            <button
              type="button"
              onClick={onEditClick}
              className="step-edit-btn"
              disabled={!allowEdit}
            >
              <Icons.EditIcon />
            </button>
          </Tooltip>
        )}
      </div>
      {(status === "process" || status === "finished") && (
        <div className="step-content">{children}</div>
      )}
    </section>
  );
};

export default Step;
