import { Trans } from "@lingui/macro";
import { Button, Icons, formatNumber, formatToEuro } from "@ster/ster-toolkit";
import { Dropdown, Space } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { MutableRefObject, memo, useCallback } from "react";

import { MediumEnum, PackageChoiceEnum, SubOrderRequest } from "../../api";
import BudgetDecreaseIncreaseWarning from "../partials/BudgetDecreaseIncreaseWarning";
import styles from "./SubOrderListFooter.module.less";

interface FooterProps {
  subOrders?: SubOrderRequest[] | null;
  onAddNewSubOrder: (type: PackageChoiceEnum) => void;
  onScrollToComments?: () => void;
  packageChoices: PackageChoiceEnum[];
  medium: MediumEnum;
  packageRef: MutableRefObject<HTMLDivElement | null | undefined>;
  canAddSubOrder: boolean;
  isEditCampaign: boolean;
  originalBudget?: number;
  isSubOrderExpanded: boolean;
}

const SubOrderListFooter = memo(
  ({
    subOrders,
    onAddNewSubOrder,
    onScrollToComments,
    packageChoices,
    medium,
    packageRef,
    canAddSubOrder,
    isEditCampaign,
    originalBudget,
    isSubOrderExpanded,
  }: FooterProps) => {
    const scrollIntoView = useCallback(() => {
      packageRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }, [packageRef]);

    const addNewGrpSubOrder = useCallback(() => {
      onAddNewSubOrder(PackageChoiceEnum.Grps);
      scrollIntoView();
    }, [onAddNewSubOrder, scrollIntoView]);

    const addNewFixedCostsSubOrder = useCallback(() => {
      onAddNewSubOrder(PackageChoiceEnum.FixedCosts);
      scrollIntoView();
    }, [onAddNewSubOrder, scrollIntoView]);

    const addNewFixedCostsCultSubOrder = useCallback(() => {
      onAddNewSubOrder(PackageChoiceEnum.FixedCostsCult);
      scrollIntoView();
    }, [onAddNewSubOrder, scrollIntoView]);

    const addNewNpoPromoSubOrder = useCallback(() => {
      onAddNewSubOrder(PackageChoiceEnum.NpoPromo);
      scrollIntoView();
    }, [onAddNewSubOrder, scrollIntoView]);

    const addNewDisplaySubOrder = useCallback(() => {
      onAddNewSubOrder(PackageChoiceEnum.Display);
      scrollIntoView();
    }, [onAddNewSubOrder, scrollIntoView]);

    const addNewVideoSubOrder = useCallback(() => {
      onAddNewSubOrder(PackageChoiceEnum.Video);
      scrollIntoView();
    }, [onAddNewSubOrder, scrollIntoView]);

    const PackageMenu: ItemType[] = [
      ...(packageChoices.includes(PackageChoiceEnum.Grps)
        ? [
            {
              label: <Trans>Inkopen met GRP&apos;s</Trans>,
              key: "grps",
              className: "more-menu-item",
              onClick: addNewGrpSubOrder,
            },
          ]
        : []),
      ...(packageChoices.includes(PackageChoiceEnum.FixedCosts)
        ? [
            {
              label: <Trans>Vaste kosten per spot</Trans>,
              key: "fixedCosts",
              className: "more-menu-item",
              onClick: addNewFixedCostsSubOrder,
            },
          ]
        : []),
      ...(packageChoices.includes(PackageChoiceEnum.FixedCostsCult)
        ? [
            {
              label: <Trans>Ster & Cultuur</Trans>,
              key: "ster&cultuur",
              className: "more-menu-item",
              onClick: addNewFixedCostsCultSubOrder,
            },
          ]
        : []),
      ...(packageChoices.includes(PackageChoiceEnum.NpoPromo)
        ? [
            {
              label: <Trans>NPO Promo Frequency</Trans>,
              key: "npopromo",
              className: "more-menu-item",
              onClick: addNewNpoPromoSubOrder,
            },
          ]
        : []),
      ...(packageChoices.includes(PackageChoiceEnum.Display)
        ? [
            {
              label: <Trans>Display</Trans>,
              key: "display",
              className: "more-menu-item",
              onClick: addNewDisplaySubOrder,
            },
          ]
        : []),
      ...(packageChoices.includes(PackageChoiceEnum.Video)
        ? [
            {
              label: <Trans>Video</Trans>,
              key: "video",
              className: "more-menu-item",
              onClick: addNewVideoSubOrder,
            },
          ]
        : []),
    ];

    return (
      <>
        <div className={styles.footer}>
          <Space>
            {canAddSubOrder && (
              <Dropdown
                menu={{ items: PackageMenu }}
                overlayClassName="more-menu"
                trigger={["click"]}
              >
                <span className="more-menu-button-wrapper">
                  <Button mode="tertiary" className="more-menu-button">
                    <span className="more-menu-button__label">
                      + <Trans>Pakket toevoegen</Trans>
                    </span>
                    <Icons.ChevronDownIcon />
                  </Button>
                </span>
              </Dropdown>
            )}

            {isEditCampaign && (
              <Button mode="tertiary" onClick={onScrollToComments}>
                <Trans>Opmerking toevoegen</Trans>
              </Button>
            )}
          </Space>

          <div className={styles.totals}>
            {medium === MediumEnum.Inter && (
              <span>
                <Trans>Impressies:</Trans>{" "}
                {formatNumber(
                  (subOrders?.length &&
                    subOrders
                      .map((s) => s.impressions ?? 0)
                      .reduce((a, b) => a + b)) ??
                    1
                )}
              </span>
            )}
            <span>
              <Trans>Budget:</Trans>{" "}
              {formatToEuro(
                (subOrders?.length &&
                  subOrders
                    .map((s) => s.budget ?? 0)
                    .reduce((a, b) => a + b)) ??
                  0,
                false
              )}
            </span>
          </div>
        </div>
        {!isSubOrderExpanded && isEditCampaign && (
          <BudgetDecreaseIncreaseWarning
            subOrders={subOrders}
            originalBudget={originalBudget}
          />
        )}
      </>
    );
  }
);

export default SubOrderListFooter;
