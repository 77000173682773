import { Trans } from "@lingui/macro";
import { Row } from "@ster/ster-toolkit";
import { Alert, Col } from "antd";
import { isSameDay } from "date-fns";
import moment from "moment";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  CommercialInstructionRequest,
  CommercialInstructionStatus,
  OrderRequest,
  OrderStatus,
} from "../../../api";
import { ReduxStoreState } from "../../../store/base";
import { receiveCampaignInstructionsAction } from "../../../store/campaignDetail/actions";
import { receiveCommercialsForProductIdAction } from "../../../store/commercials/actions";
import { StoreModel } from "../../../store/models";
import {
  InstructionConstants,
  emptyNumbers,
  removeDuplicateObjects,
} from "../../../utils";
import { minCommercialDate } from "../../../utils/constants";
import {
  datesToDateRanges,
  getDaysInsidePeriod,
} from "../../../utils/dateHelper";
import { usePrevious } from "../../../utils/hooks";
import { campaignInstructionsRootSelector } from "../../campaignDetail/selectors";
import AddInstructions from "../../partials/Instructions/AddInstructions";
import Instructions from "../../partials/Instructions/Instructions";
import { createSimpleSubOrder } from "../../partials/Instructions/utils";
import styles from "./InstructionsForConcept.module.less";
import { instructionsForConceptSelector } from "./selectors";

interface InstructionsProps {
  orderRequest: OrderRequest;
  onSave: (instruction: CommercialInstructionRequest, onOk: () => void) => void;
  onDelete: (instructionId: number) => void;
  concepts: CommercialInstructionRequest[];
}

export const getMinMax = (order: OrderRequest): { min: Date; max: Date } => {
  /**
   * Construct the minimum start date for an instruction
   */

  const { subOrders } = order;

  const minDates =
    subOrders
      ?.filter(
        (s) =>
          s.period && moment(s.period.from).isAfter(InstructionConstants.now)
      )
      .map((s) => s.period.from.getTime()) ?? InstructionConstants.emptyTicks;

  const min =
    subOrders && minDates.length > 0
      ? new Date(Math.min(...minDates))
      : order.period.from;

  /**
   * Construct the maximum end date for an instruction
   */
  const maxDates =
    subOrders
      ?.filter(
        (s) => s.period && moment(s.period.to).isAfter(InstructionConstants.now)
      )
      .map((s) => s.period.to.getTime()) ?? InstructionConstants.emptyTicks;

  const max =
    subOrders && maxDates.length > 0
      ? new Date(Math.max(...maxDates))
      : order.period.to;

  return { min, max };
};

const InstructionsForConcept = memo(
  ({ orderRequest, onSave, onDelete, concepts }: InstructionsProps) => {
    const { deleteInstructionsState } = useSelector(
      instructionsForConceptSelector
    );

    const { loading, instructions, commercialsPerAdvertiser } = useSelector(
      (state: StoreModel) => campaignInstructionsRootSelector(state)
    );

    const mergedInstructions = useMemo(
      () =>
        [
          ...instructions.filter((s) => !concepts.some((c) => c.id === s.id)),
          ...concepts,
        ].sort(
          (a, b) =>
            (a.period?.from.getTime() ?? 0) - (b.period?.from.getTime() ?? 0)
        ),
      [concepts, instructions]
    );

    const { min: minDate, max: maxDate } = useMemo(
      () => getMinMax(orderRequest),
      [orderRequest]
    );

    const dateRangesWithoutInstructions = useMemo(() => {
      if (minDate && maxDate && mergedInstructions) {
        // Bepaal de startdatum en eindatum van nieuwe instructies, deze mogen niet overlappen met andere instructies
        const daysInInstructions = mergedInstructions.flatMap((s) =>
          s.commercialsPerPeriod.flatMap((c) =>
            c.dateRanges.flatMap((d) => getDaysInsidePeriod(d.from, d.to))
          )
        );
        const daysInPeriod = getDaysInsidePeriod(minDate, maxDate);

        const daysWithoutInstruction = daysInPeriod.filter(
          (s) => !daysInInstructions.some((d) => d && isSameDay(d, s))
        );

        return datesToDateRanges(daysWithoutInstruction);
      }
      return [];
    }, [maxDate, mergedInstructions, minDate]);

    const spotLengths = useMemo(
      () =>
        (
          removeDuplicateObjects(
            orderRequest.subOrders?.map(
              (s): number[] => s.spotLength?.filter(Boolean) ?? emptyNumbers
            ) ?? emptyNumbers
          ) as number[][]
        ).sort((a, b) => a[0] - b[0]),
      [orderRequest.subOrders]
    );

    const dispatch = useDispatch();
    const refreshInstructions = useCallback(() => {
      dispatch(
        receiveCampaignInstructionsAction.request({
          medium: orderRequest.medium,
          productId: orderRequest.productId,
          from: minDate,
          to: maxDate,
        })
      );
    }, [
      dispatch,
      maxDate,
      minDate,
      orderRequest.medium,
      orderRequest.productId,
    ]);

    const handleDelete = useCallback(
      (instructionId: number) => {
        onDelete(instructionId);
      },
      [onDelete]
    );

    const prevMinDate = usePrevious(minDate);
    const prevMaxDate = usePrevious(maxDate);
    const prevSpotLengths = usePrevious(spotLengths);
    useEffect(() => {
      // Instructies alleen ophalen wanneer de periode of spotlengtes wijzigen
      if (
        !prevMinDate ||
        !prevMaxDate ||
        prevMinDate.getTime() !== minDate.getTime() ||
        prevMaxDate.getTime() !== maxDate.getTime() ||
        JSON.stringify(prevSpotLengths) !== JSON.stringify(spotLengths)
      ) {
        refreshInstructions();

        dispatch(
          receiveCommercialsForProductIdAction.request({
            medium: orderRequest.medium,
            productId: orderRequest.productId,
            from: minCommercialDate,
            to: orderRequest.period.to,
          })
        );
      }
    }, [
      dispatch,
      maxDate,
      minDate,
      orderRequest.medium,
      orderRequest.period,
      orderRequest.period.to,
      orderRequest.productId,
      prevMaxDate,
      prevMinDate,
      prevSpotLengths,
      refreshInstructions,
      spotLengths,
    ]);

    useEffect(() => {
      if (deleteInstructionsState === ReduxStoreState.Success) {
        refreshInstructions();
      }
    }, [deleteInstructionsState, refreshInstructions]);

    const subOrders = useMemo(
      () => createSimpleSubOrder(orderRequest.subOrders),
      [orderRequest.subOrders]
    );

    const conceptInstructionsInvalidSpotLength = useMemo(
      () =>
        mergedInstructions.some(
          (s) =>
            s.instructionStatus === CommercialInstructionStatus.Concept &&
            spotLengths.some((sl) =>
              s.commercialsPerPeriod.every(
                (c) => c.spotLength.join("+") !== sl.join("+")
              )
            )
        ),
      [mergedInstructions, spotLengths]
    );

    return (
      <>
        {conceptInstructionsInvalidSpotLength && (
          <Row className="center" gutter={[0, 32]}>
            <Col span={20}>
              <Alert
                showIcon
                type="warning"
                message=""
                description={
                  <Trans>
                    <b>Let op:</b> je hebt uitzendinstructies voor spotlengtes
                    die niet in deze campagne voorkomen. Deze kunnen onderdeel
                    zijn van een andere campagne, of zijn blijven staan na het
                    wijzigen van de spotlengte. In het tweede geval dien je de
                    betreffende instructie te verwijderen en een nieuwe toe te
                    voegen.
                  </Trans>
                }
              />
            </Col>
          </Row>
        )}
        <Instructions
          loading={loading}
          instructions={mergedInstructions}
          commercialsPerAdvertiser={commercialsPerAdvertiser}
          className={styles.instructionsForConcept}
          minDate={minDate}
          maxDate={maxDate}
          spotLengths={spotLengths}
          onSave={onSave}
          onDelete={handleDelete}
          campaignPeriod={[
            moment(orderRequest.period.from),
            moment(orderRequest.period.to),
          ]}
          subOrders={subOrders}
        />

        {(!orderRequest.requestStatus ||
          orderRequest.requestStatus === OrderStatus.Concept) &&
          mergedInstructions.some(
            (s) => s.instructionStatus !== CommercialInstructionStatus.Concept
          ) && (
            <Row className="center" gutter={[0, 32]}>
              <Col span={20}>
                <Alert
                  showIcon
                  type="warning"
                  message=""
                  className={styles.existingInstructions}
                  description={
                    <Trans>
                      <b>Let op:</b> er zijn al uitzendinstructies aanwezig in
                      deze periode. Alleen voor eventuele andere periodes kun je
                      nog nieuwe instructies toevoegen. In andere gevallen of
                      voor vragen kun je contact opnemen met{" "}
                      <a href="mailto:klantportal@ster.nl">
                        klantportal@ster.nl
                      </a>
                      .
                    </Trans>
                  }
                />
              </Col>
            </Row>
          )}

        <AddInstructions
          enabled={
            !loading &&
            (!orderRequest.requestStatus ||
              orderRequest.requestStatus === OrderStatus.Concept) &&
            dateRangesWithoutInstructions.length > 0 &&
            subOrders.length > 0
          }
          instructions={mergedInstructions}
          commercialsPerAdvertiser={commercialsPerAdvertiser}
          maxDate={maxDate}
          medium={orderRequest.medium}
          productId={orderRequest.productId}
          instructionStatus={CommercialInstructionStatus.Concept}
          newInstructionText={<Trans>Instructie toevoegen</Trans>}
          newButtonMode="secondary"
          okText={<Trans>OK</Trans>}
          onSave={onSave}
          subOrders={subOrders}
          dateRangesWithoutInstructions={dateRangesWithoutInstructions}
        />
      </>
    );
  }
);

export default InstructionsForConcept;
