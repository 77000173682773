import "./materialmodal.less";

import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  Icons,
  Modal,
  Select,
  Tag,
  Typography,
  formatDate,
} from "@ster/ster-toolkit";
import { Space } from "antd";
import { ModalProps } from "antd/lib/modal";
import { PropsWithChildren, useCallback, useState } from "react";

import { Banner } from "../../../../api";
import { getCommercialStatusText, tagStatusMapper } from "../../../../utils";
import BannerPlayer from "../Players/BannerPlayer";
import styles from "./MaterialBannerModal.module.less";

interface MaterialBannerModalProps extends ModalProps {
  materialBannerData: Banner;
}

const MaterialBannerModal = ({
  materialBannerData,
  open,
  title,
  onCancel,
}: PropsWithChildren<MaterialBannerModalProps>) => {
  const { status, width, height, creationDate, previews } = materialBannerData;

  const { i18n } = useLingui();

  const [previewUrl, setPreviewUrl] = useState<string | undefined>(undefined);
  const openPreview = useCallback(() => {
    if (previewUrl) {
      window.open(previewUrl, "_blank");
    }
  }, [previewUrl]);

  const { Caption, Footnote } = Typography;

  return (
    <Modal
      destroyOnClose
      footer={null}
      title={title}
      open={open}
      onCancel={onCancel}
      cancelText={<Trans>Annuleren</Trans>}
      wrapClassName="material-modal"
    >
      {status && creationDate && width && height && (
        <>
          <div className={styles.bannerPreviewContainer}>
            <Space.Compact direction="horizontal">
              <BannerPlayer banner={materialBannerData} />
            </Space.Compact>
          </div>
          <div className={styles.bannerPreviewContainer}>
            {previews && (
              <Space.Compact
                direction="horizontal"
                style={{ backgroundColor: "#fff" }}
              >
                <Select
                  placeholder={i18n._(t`Selecteer site`)}
                  options={previews.map(({ name, url }) => ({
                    label: name,
                    value: url,
                  }))}
                  onSelect={(url) => setPreviewUrl(url as string)}
                  style={{ width: 200 }}
                />
                <Button
                  mode="primary"
                  onClick={openPreview}
                  icon={<Icons.OnlineIcon width={16} height={16} fill="#fff" />}
                  style={{ padding: 8, width: "auto" }}
                  disabled={!previewUrl}
                >
                  {i18n._(t`Voorbeeld`)}
                </Button>
              </Space.Compact>
            )}
          </div>
          <div className="material-metadata">
            <div className="material-metadata--main">
              <Tag
                status={tagStatusMapper(status)}
                text={i18n._(getCommercialStatusText(status))}
              />
              <Caption>
                {width} x {height}
              </Caption>
              <Footnote>
                <Trans>
                  toegevoegd op <strong>{formatDate(creationDate)}</strong>
                </Trans>
              </Footnote>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default MaterialBannerModal;
