/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface AdScienceMediaFile
 */
export interface AdScienceMediaFile {
  /**
   *
   * @type {number}
   * @memberof AdScienceMediaFile
   */
  width?: number;
  /**
   *
   * @type {number}
   * @memberof AdScienceMediaFile
   */
  height?: number;
  /**
   *
   * @type {string}
   * @memberof AdScienceMediaFile
   */
  type?: string | null;
  /**
   *
   * @type {string}
   * @memberof AdScienceMediaFile
   */
  url?: string | null;
  /**
   *
   * @type {number}
   * @memberof AdScienceMediaFile
   */
  adScienceId?: number;
  /**
   *
   * @type {number}
   * @memberof AdScienceMediaFile
   */
  duration?: number;
  /**
   *
   * @type {string}
   * @memberof AdScienceMediaFile
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof AdScienceMediaFile
   */
  tag?: string | null;
}

export function AdScienceMediaFileFromJSON(json: any): AdScienceMediaFile {
  return AdScienceMediaFileFromJSONTyped(json, false);
}

export function AdScienceMediaFileFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AdScienceMediaFile {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    width: !exists(json, "width") ? undefined : json["width"],
    height: !exists(json, "height") ? undefined : json["height"],
    type: !exists(json, "type") ? undefined : json["type"],
    url: !exists(json, "url") ? undefined : json["url"],
    adScienceId: !exists(json, "adScienceId") ? undefined : json["adScienceId"],
    duration: !exists(json, "duration") ? undefined : json["duration"],
    name: !exists(json, "name") ? undefined : json["name"],
    tag: !exists(json, "tag") ? undefined : json["tag"],
  };
}

export function AdScienceMediaFileToJSON(
  value?: AdScienceMediaFile | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    width: value.width,
    height: value.height,
    type: value.type,
    url: value.url,
    adScienceId: value.adScienceId,
    duration: value.duration,
    name: value.name,
    tag: value.tag,
  };
}
