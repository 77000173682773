import "./commercialpicker.module.less";

import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Modal, Row } from "@ster/ster-toolkit";
import { Col } from "antd";
import { ModalProps } from "antd/lib/modal";
import { memo, useCallback, useMemo } from "react";

import { Banner, CommercialInstructionSpots } from "../../../../api/models";
import MaterialCardBanner from "../../Cards/Material/MaterialCardBanner";

interface BannersModalProps extends ModalProps {
  onSelect: (banner: Banner) => void;
  onCancel: () => void;
  banners: Banner[];
  selected: CommercialInstructionSpots;
}

const BannersModal = memo(
  ({ open, onSelect, banners, onCancel, selected }: BannersModalProps) => {
    const { i18n } = useLingui();
    const commercialsPerRow = useMemo(
      () => (banners.length === 0 ? 1 : Math.min(banners.length, 3)),
      [banners.length]
    );

    const handleSelect = useCallback(
      (banner: Banner) => {
        // set de geselecteerde commercial en geef die door
        onSelect(banner);
        if (onCancel) {
          onCancel();
        }
      },
      [onCancel, onSelect]
    );

    if (!open) {
      return null;
    }

    return (
      <Modal
        open={open}
        onCancel={onCancel}
        cancelText={<Trans>Annuleren</Trans>}
        onOk={onCancel}
        width={`${(1336 / 3) * commercialsPerRow}px`}
        title={i18n._(t`Selecteer een banner`)}
      >
        <Row gutter={[24, 32]}>
          {banners.map((banner) => {
            const currentSelected = selected?.banner?.id === banner.id;
            return (
              <Col
                xs={24 / commercialsPerRow}
                key={banner.id}
                className="commercials-modal_col"
              >
                <MaterialCardBanner
                  cardData={banner}
                  footer={
                    <Button
                      className="commercials-modal__button"
                      mode="secondary"
                      onClick={() => handleSelect(banner)}
                      data-index={banner.id}
                      disabled={currentSelected}
                    >
                      {currentSelected && <Trans>Geselecteerd</Trans>}
                      {!currentSelected && (
                        <>
                          <Trans>Selecteer</Trans> {banner.id}
                        </>
                      )}
                    </Button>
                  }
                />
              </Col>
            );
          })}
          {banners.length === 0 && (
            <div>
              <Trans>
                Er zijn (nog) geen banners beschikbaar. Deze kunnen via{" "}
                <a
                  href="https://portal.ster.nl/material"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Materiaal uploaden
                </a>{" "}
                aangeleverd worden.
              </Trans>
            </div>
          )}
        </Row>
      </Modal>
    );
  }
);

export default BannersModal;
