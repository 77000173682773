/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface DocumentResultDeleted
 */
export interface DocumentResultDeleted {
  /**
   *
   * @type {string}
   * @memberof DocumentResultDeleted
   */
  deleted?: string | null;
}

export function DocumentResultDeletedFromJSON(
  json: any
): DocumentResultDeleted {
  return DocumentResultDeletedFromJSONTyped(json, false);
}

export function DocumentResultDeletedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DocumentResultDeleted {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    deleted: !exists(json, "deleted") ? undefined : json["deleted"],
  };
}

export function DocumentResultDeletedToJSON(
  value?: DocumentResultDeleted | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    deleted: value.deleted,
  };
}
