import { createAsyncAction } from "typesafe-actions";

import { ApiV1ProductTourPostRequest } from "../../api";
import { ProductTourResult } from "../../api/models";
import { ResponseError } from "../base";
import { actionNameAsync } from "../utils";

export const receiveProductTourAction = createAsyncAction(
  actionNameAsync("productTour", "REQUEST"),
  actionNameAsync("productTour", "SUCCESS"),
  actionNameAsync("productTour", "FAILURE")
)<undefined, ProductTourResult, ResponseError>();

export const postProductTourAction = createAsyncAction(
  actionNameAsync("productTourPost", "REQUEST"),
  actionNameAsync("productTourPost", "SUCCESS"),
  actionNameAsync("productTourPost", "FAILURE")
)<ApiV1ProductTourPostRequest, ProductTourResult, ResponseError>();
