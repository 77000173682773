/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  OperationalContextState,
  OperationalContextStateFromJSON,
  OperationalContextStateFromJSONTyped,
  OperationalContextStateToJSON,
} from "./";

/**
 *
 * @export
 * @interface UserModel
 */
export interface UserModel {
  /**
   *
   * @type {string}
   * @memberof UserModel
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserModel
   */
  phoneNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserModel
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof UserModel
   */
  lastName: string;
  /**
   *
   * @type {OperationalContextState}
   * @memberof UserModel
   */
  operationalContextState: OperationalContextState;
  /**
   *
   * @type {string}
   * @memberof UserModel
   */
  clientIdList?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UserModel
   */
  active?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof UserModel
   */
  roles?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof UserModel
   */
  claims?: Array<string> | null;
}

export function UserModelFromJSON(json: any): UserModel {
  return UserModelFromJSONTyped(json, false);
}

export function UserModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: json["email"],
    phoneNumber: !exists(json, "phoneNumber") ? undefined : json["phoneNumber"],
    firstName: json["firstName"],
    lastName: json["lastName"],
    operationalContextState: OperationalContextStateFromJSON(
      json["operationalContextState"]
    ),
    clientIdList: !exists(json, "clientIdList")
      ? undefined
      : json["clientIdList"],
    active: !exists(json, "active") ? undefined : json["active"],
    roles: !exists(json, "roles") ? undefined : json["roles"],
    claims: !exists(json, "claims") ? undefined : json["claims"],
  };
}

export function UserModelToJSON(value?: UserModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    phoneNumber: value.phoneNumber,
    firstName: value.firstName,
    lastName: value.lastName,
    operationalContextState: OperationalContextStateToJSON(
      value.operationalContextState
    ),
    clientIdList: value.clientIdList,
    active: value.active,
    roles: value.roles,
    claims: value.claims,
  };
}
