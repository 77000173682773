/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ProductTourResult
 */
export interface ProductTourResult {
  /**
   *
   * @type {number}
   * @memberof ProductTourResult
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ProductTourResult
   */
  userId: string;
  /**
   *
   * @type {Date}
   * @memberof ProductTourResult
   */
  dateFinished: Date;
  /**
   *
   * @type {number}
   * @memberof ProductTourResult
   */
  lastStep?: number;
  /**
   *
   * @type {number}
   * @memberof ProductTourResult
   */
  lastStepViewed?: number;
}

export function ProductTourResultFromJSON(json: any): ProductTourResult {
  return ProductTourResultFromJSONTyped(json, false);
}

export function ProductTourResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProductTourResult {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    userId: json["userId"],
    dateFinished: new Date(json["dateFinished"]),
    lastStep: !exists(json, "lastStep") ? undefined : json["lastStep"],
    lastStepViewed: !exists(json, "lastStepViewed")
      ? undefined
      : json["lastStepViewed"],
  };
}

export function ProductTourResultToJSON(value?: ProductTourResult | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    userId: value.userId,
    dateFinished: value.dateFinished.toISOString(),
    lastStep: value.lastStep,
    lastStepViewed: value.lastStepViewed,
  };
}
