import { I18n } from "@lingui/core";
import { t } from "@lingui/macro";
import moment from "moment";

import { DateSpan, Package, SubOrderRequest } from "../../../../api";
import {
  getDaysDiff,
  getSpecificDaysInsidePeriod,
} from "../../../../utils/dateHelper";

interface PackageProperties {
  periods: DateSpan[];
  salesLockPeriods: DateSpan[];
  daysInPeriod: number;
  minDays: number;
  periodEnabled: boolean;
  salesLock: boolean;
  isSpreadEnabled: boolean;
  yourTimeDisabled: boolean;
}

export const isPackageOptionDisabled = (
  selectedPackage: Package,
  { from, to }: DateSpan,
  numberOfExcludedDays: number,
  subOrders?: SubOrderRequest[] | null
): PackageProperties => {
  // Bepaal de periode van het pakket die overlapt met de gekozen periode
  const period =
    selectedPackage.periods.find((p) => p.to >= from && p.from <= to) ??
    selectedPackage.periods[0];
  const perFrom = moment(period.from);
  const perUntil = moment(period.to);
  // Bepaal het aantal dagen in de geselecteerde periode wat niet binnen de weekdagen van het pakket ligt
  const daysNotInWeekDays = selectedPackage.weekDays
    ? getSpecificDaysInsidePeriod(
        from,
        to,
        [1, 2, 3, 4, 5, 6, 7].filter(
          (key) => selectedPackage.weekDays?.[key - 1] === 0
        )
      ).length
    : 0;
  const daysInPeriod =
    getDaysDiff(moment(from), moment(to)) -
    numberOfExcludedDays -
    daysNotInWeekDays;

  const minDays = selectedPackage.minDays ?? 0;
  const periodEnabled =
    perFrom.isSameOrBefore(from, "day") &&
    perUntil.isSameOrAfter(to, "day") &&
    daysInPeriod >= minDays;

  const salesLock = selectedPackage.salesLockPeriods.some(
    (p) => p.from <= to && p.to >= from
  );

  let yourTimeDisabled = false;
  if (selectedPackage.yourTimeType) {
    yourTimeDisabled =
      subOrders?.some(
        (s) =>
          s._package?.yourTimeType &&
          s._package?.yourTimeType !== selectedPackage.yourTimeType
      ) ?? false;
  }

  return {
    periods: selectedPackage.periods,
    salesLockPeriods: selectedPackage.salesLockPeriods,
    daysInPeriod,
    minDays,
    periodEnabled,
    salesLock,
    yourTimeDisabled,
    isSpreadEnabled: selectedPackage.isSpreadEnabled,
  };
};

export const getExtraPackageText = (
  {
    salesLock,
    periodEnabled,
    daysInPeriod,
    minDays,
    periods,
    salesLockPeriods,
    yourTimeDisabled,
    isSpreadEnabled: isSpreadEnabledForPackage,
  }: PackageProperties,
  spreadEnabled: boolean,
  i18n: I18n,
  _package: Package
): string => {
  const getPeriodDescr = (dates: DateSpan[]) =>
    dates
      .map((p) => {
        const from = moment(p.from);
        const until = moment(p.to);
        const sameMonth = from.month() === until.month();
        return sameMonth
          ? i18n._(t`${from.format("D")} t/m ${until.format("D MMM")}`)
          : i18n._(t`${from.format("D MMM")} t/m ${until.format("D MMM")}`);
      })
      .join(", ");

  let periodDescr = "";
  if (daysInPeriod < minDays) {
    periodDescr = i18n._(t`minimaal ${minDays} dagen`);
  } else if (!periodEnabled) {
    periodDescr = getPeriodDescr(periods);
  }

  let extra = "";
  if (salesLock) {
    extra = i18n._(t`verkoopstop ${getPeriodDescr(salesLockPeriods)}`);
  } else {
    extra += periodDescr;
    if (!isSpreadEnabledForPackage && spreadEnabled) {
      if (extra !== "") {
        extra += "; ";
      }
      extra += i18n._(t`niet beschikbaar bij Maandoverschrijdend`);
    }

    if (yourTimeDisabled) {
      if (extra !== "") {
        extra += "; ";
      }
      extra +=
        _package.yourTimeType?.toLocaleLowerCase() === "vast"
          ? i18n._(t`niet i.c.m. YT Variabel`)
          : i18n._(t`niet i.c.m. YT Vast`);
    }
  }

  return extra !== "" ? ` (${extra})` : "";
};
