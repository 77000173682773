/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CustomerOpportunity
 */
export interface CustomerOpportunity {
  /**
   *
   * @type {string}
   * @memberof CustomerOpportunity
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CustomerOpportunity
   */
  id: string;
}

export function CustomerOpportunityFromJSON(json: any): CustomerOpportunity {
  return CustomerOpportunityFromJSONTyped(json, false);
}

export function CustomerOpportunityFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CustomerOpportunity {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json["name"],
    id: json["id"],
  };
}

export function CustomerOpportunityToJSON(
  value?: CustomerOpportunity | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    id: value.id,
  };
}
