import { useCallback, useEffect, useRef, useState } from "react";
import TagManager from "react-gtm-module";

import { PortalSettings, SterInlogUser } from "../api";
import { getLanguage } from "../utils";

declare global {
  interface Window {
    chatwootSettings: unknown;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chatwootSDK: any;
  }
}

const ChatwootWidget = ({
  sterInlog,
  settings,
}: {
  sterInlog: SterInlogUser;
  settings: PortalSettings;
}) => {
  const [chatWootReset, setChatWootReset] = useState(false);
  const messagesSent = useRef(0);

  const handleNewChat = useCallback(() => {
    // Stuur een event naar GTM bij het starten van een nieuwe chat
    TagManager.dataLayer({
      dataLayer: {
        event: "Chat gestart",
      },
    });
  }, []);

  useEffect(() => {
    window.chatwootSettings = {
      hideMessageBubble: false,
      position: "right",
      locale: "en",
      type: "expanded_bubble",
    };
    ((d, t) => {
      const BASE_URL = settings.chatBaseUrl;
      const g = d.createElement(t) as HTMLScriptElement;
      const s = d.getElementsByTagName(t)[0];
      g.src = `${BASE_URL}/packs/js/sdk.js`;
      g.defer = true;
      g.async = true;
      s.parentNode?.insertBefore(g, s);
      g.onload = () => {
        window.chatwootSDK.run({
          websiteToken: settings.chatToken,
          baseUrl: BASE_URL,
        });
      };
    })(document, "script");

    window.addEventListener("chatwoot:ready", () => {
      if (window.$chatwoot?.resetTriggered) {
        setChatWootReset(true);
        return;
      }
      // Reset de sessie van de gebruiker bij een pagina refresh zodat er een nieuwe chat onstaat
      window.$chatwoot?.reset();
    });

    window.chatwootSettings = {
      type: "expanded_bubble",
      launcherTitle: "Chat",
      locale: "nl",
    };
  }, [settings.chatBaseUrl, settings.chatToken]);

  useEffect(() => {
    window.addEventListener("chatwoot:on-message", () => {
      messagesSent.current += 1;
      if (messagesSent.current === 1) {
        handleNewChat();
      }
    });
  }, [handleNewChat]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (chatWootReset) {
      timer = setTimeout(() => {
        if (sterInlog) {
          window.$chatwoot?.setUser(sterInlog.email, {
            email: sterInlog.email,
            name: sterInlog.fullName,
          });
        } else {
          window.$chatwoot?.setUser("", {
            name: "Onbekende Gebruiker",
          });
        }

        window.$chatwoot?.setLocale(getLanguage());
      }, 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [chatWootReset, sterInlog]);

  useEffect(() => {
    if (chatWootReset) {
      window.$chatwoot?.toggleBubbleVisibility(
        settings.enableChat ? "show" : "hide"
      );
    }
  }, [chatWootReset, settings]);

  return null;
};

export default ChatwootWidget;
