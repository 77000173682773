import "../styles.less";
import "./invoicecard.less";

import { MessageDescriptor } from "@lingui/core";
import { Trans, msg, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  CollapseTillBreakpoint,
  Tag,
  Typography,
  formatDate,
  formatToEuro,
} from "@ster/ster-toolkit";

import { Invoice, InvoiceStatus } from "../../../../api";
import { tagStatusMapper } from "../../../../utils";
import CardStatistic from "../CardStatistic";
import MediumIcon from "../MediumIcon";

interface InvoiceCardProps {
  cardData: Invoice;
}

const getStatus = (status: InvoiceStatus): MessageDescriptor => {
  switch (status) {
    case InvoiceStatus.Factureerbaar:
      return msg`Factureerbaar`;
    case InvoiceStatus.Gefactureerd:
      return msg`Gefactureerd`;
    case InvoiceStatus.DeelsBetaald:
      return msg`Deels betaald`;
    case InvoiceStatus.Betaald:
      return msg`Betaald`;
    default:
      return msg`Onbekend`;
  }
};

const InvoiceCard = ({ cardData }: InvoiceCardProps) => {
  const {
    id,
    internalId,
    medium,
    status,
    productDescr,
    advertiserName,
    invoiceDate,
    dueDate,
    invoiceAmount,
    vatAmount,
  } = cardData;

  const { i18n } = useLingui();

  const { Title } = Typography;

  const formattedInvoiceDate = formatDate(invoiceDate);
  const formattedDueDate = formatDate(dueDate);

  const formattedInvoiceAmount = formatToEuro(invoiceAmount, true);
  const formattedVatAmount = formatToEuro(vatAmount, true);
  const exVatAmount = formatToEuro(invoiceAmount - vatAmount, true);

  return (
    <article className="card card--invoice">
      <header className="card-header">
        <div className="card-header__icon">
          <MediumIcon medium={medium} />
        </div>
        <div className="card-header__menu">
          <Typography.Text>
            <Tag
              text={i18n._(getStatus(status))}
              status={tagStatusMapper(status)}
            />
            <a
              className="card-header__download-link"
              href={`/invoice/${internalId}.pdf`}
            >
              <Trans>Download als PDF</Trans>
            </a>
          </Typography.Text>
        </div>
      </header>
      <div className="card-body">
        <Title level={2} className="card-title">
          {productDescr}
        </Title>
        <span className="card-body__text">{advertiserName}</span>
        <span className="card-body__text">{id}</span>
        <footer>
          <div className="card-body__data-wrapper">
            <CardStatistic
              label={i18n._(t`Factuurdatum`)}
              value={formattedInvoiceDate}
            />
            <CollapseTillBreakpoint value={576}>
              <CardStatistic
                label={i18n._(t`Vervaldatum`)}
                value={formattedDueDate}
              />
              <CardStatistic label={i18n._(t`Ex. BTW`)} value={exVatAmount} />
              <CardStatistic
                label={i18n._(t`BTW`)}
                value={formattedVatAmount}
              />
              <CardStatistic
                label={i18n._(t`Totaalbedrag`)}
                value={formattedInvoiceAmount}
              />
            </CollapseTillBreakpoint>
          </div>
        </footer>
      </div>
    </article>
  );
};

export default InvoiceCard;
