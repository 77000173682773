/* eslint-disable jsx-a11y/media-has-caption */
import { memo } from "react";

import { Commercial } from "../../api/models";

const VideoPlayer = memo(({ commercial }: { commercial: Commercial }) => (
  <video width="100%" height="100%" controls autoPlay>
    <source src={commercial.url ?? ""} type="video/mp4" />
    Your browser does not support the video tag.
  </video>
));

export default VideoPlayer;
