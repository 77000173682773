import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  Container,
  ContentContainer,
  Icons,
  Typography,
  VisualHeader,
} from "@ster/ster-toolkit";
import { memo, useCallback } from "react";
import { Navigate } from "react-router-dom";

import { PortalUser } from "../../api/models";
import { isAccessTokenValid } from "../../utils";
import { loginWithRedirect } from "./constants";
import styles from "./NotLoggedIn.module.less";
import { privateRoutePaths } from "./PrivateRoute";

interface NotLoggedInProps {
  user: PortalUser | undefined;
}

const NotLoggedIn = memo(({ user }: NotLoggedInProps) => {
  const { i18n } = useLingui();
  const gotoLogin = useCallback(() => {
    window.location.href = loginWithRedirect();
  }, []);

  if (user && user.email && isAccessTokenValid()) {
    return <Navigate to={privateRoutePaths.loggedIn} />;
  }

  return (
    <ContentContainer className="content">
      <Container className={styles.container}>
        <VisualHeader
          title={i18n._(t`Welkom`)}
          subtitle={i18n._(t`Ster Klantportal`)}
          type="index"
          imageUrl="/klantportal.jpg"
        />
        <div className={styles.content}>
          <Trans>
            <Typography.Paragraph>
              Van uitzendschema tot campagne-overzicht, van facturen tot het
              direct aanvragen van campagnes: de Ster Klantportal biedt het
              allemaal. Onze gebruiksvriendelijke tool biedt 24/7 inzicht in
              jouw campagnes. Elke gebruiker kan zelf campagnes boeken voor één
              of meerdere mediumtypen - of eerst bekijken wat de prijs ervan zal
              zijn.
            </Typography.Paragraph>
            <Typography.Paragraph>
              Heb je al een account? Log dan in via onderstaande button. Maak je
              nog geen gebruik van onze Ster Klantportal, maar zou je dat wel
              graag willen? Gebruik dan ook onderstaande link en klik vervolgens
              op ‘maak een account aan’.
            </Typography.Paragraph>
          </Trans>
          <Button onClick={gotoLogin} mode="primary">
            <Trans>Inloggen of registreren</Trans>
          </Button>
          <div className={styles.iconRow}>
            <span className={styles.icon}>
              <Icons.StatsIcon />
              <Typography.Title level={3}>
                <Trans>24/7 inzicht in campagnes</Trans>
              </Typography.Title>
            </span>
            <span className={styles.icon}>
              <Icons.ProfileIcon />
              <Typography.Title level={3}>
                <Trans>Bereiksprognose door machine learning</Trans>
              </Typography.Title>
            </span>
            <span className={styles.icon}>
              <Icons.ShoppingIcon />
              <Typography.Title level={3}>
                <Trans>Zelf campagnes aanvragen</Trans>
              </Typography.Title>
            </span>
            <span className={styles.icon}>
              <Icons.SpeechBalloonIcon />
              <Typography.Title level={3}>
                <Trans>Chatten met onze planners</Trans>
              </Typography.Title>
            </span>
          </div>
        </div>
      </Container>
    </ContentContainer>
  );
});

export default NotLoggedIn;
