import { endOfMonth, startOfMonth } from "date-fns";

import { BreakSelection } from "../../api";
import { BreakFilters } from "./models";

export const allDaysOfTheWeek: number[] = [1, 2, 3, 4, 5, 6, 7];

export const firstDayOfCurrentMonth: Date = startOfMonth(new Date());
export const lastDayOfCurrentMonth: Date = endOfMonth(new Date());

export const emptyFilters: BreakFilters = {
  channelDescr: undefined,
  programmeBefore: null,
  programmeAfter: null,
  schedDate: [
    firstDayOfCurrentMonth.toISOString(),
    lastDayOfCurrentMonth.toISOString(),
  ],
  schedStartTime: null,
  days: allDaysOfTheWeek,
  secondaryTargetGroup: undefined,
};

export const emptySelectedBreaks: BreakSelection[] = [];
