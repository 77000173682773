import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Container,
  ContentContainer,
  Row,
  Spinner,
  Table,
} from "@ster/ster-toolkit";
import { Col, Tooltip, Typography } from "antd";
import { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";

import { EditableSetting, MediumEnum } from "../../api";
import { getEditableSettingsAction } from "../../store/admin/actions";
import { StoreModel } from "../../store/models";
import EditableSettingField from "./EditableSettingField";

// eslint-disable-next-line no-shadow
enum AdditionalEditableSettingCategory {
  other = "other",
}

type EditableSettingCategory = MediumEnum | AdditionalEditableSettingCategory;
type AllEditableSettings = {
  [key in EditableSettingCategory]: EditableSetting[];
};

const labels: Record<EditableSettingCategory, string> = {
  [AdditionalEditableSettingCategory.other]: t`Anders`,
  [MediumEnum.Tv]: t`Televisie`,
  [MediumEnum.Radio]: t`Radio`,
  [MediumEnum.Inter]: t`Online`,
};

function getEditableSettingCategory(alias: string): EditableSettingCategory {
  const editableSettingFieldSeparatorChar = ":";

  if (!alias.includes(editableSettingFieldSeparatorChar)) {
    return AdditionalEditableSettingCategory.other;
  }

  const catAsString = alias.split(editableSettingFieldSeparatorChar)[1];
  return (catAsString as MediumEnum) || AdditionalEditableSettingCategory.other;
}

const Settings = () => {
  const { i18n } = useLingui();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEditableSettingsAction.request());
  }, [dispatch]);

  const { editable, loading } = useSelector(
    (state: StoreModel) => state.admin.editableSettings
  );

  const allEditableSettings = useMemo(
    () =>
      editable &&
      editable
        .map((item) => ({
          ...item,
          category: getEditableSettingCategory(item.alias ?? ""),
        }))
        .reduce((acc: AllEditableSettings, item) => {
          acc[item.category] = [...(acc[item.category] ?? []), item];
          return acc;
        }, {} as AllEditableSettings),
    [editable]
  );

  return (
    <>
      <Helmet>
        <title>{i18n._(t`Beheer`)}</title>
      </Helmet>

      <ContentContainer>
        <Spinner spinning={loading}>
          {allEditableSettings &&
            Object.entries(allEditableSettings).map(([key, value]) => (
              <Row gutter={[32, 32]} key={key}>
                <Col span={24}>
                  <Container>
                    <Typography.Title level={2}>
                      {i18n._(labels[key as EditableSettingCategory])}
                    </Typography.Title>
                    <Table<EditableSetting>
                      pagination={false}
                      columns={[
                        {
                          title: i18n._(t`Naam`),
                          key: "name",
                          dataIndex: "name",
                          ellipsis: true,
                          sorter: (a, b): number =>
                            `${a.name}`.localeCompare(`${b.name}`),
                          render: (_, setting) => (
                            <Tooltip title={setting.alias}>
                              {setting.name}
                            </Tooltip>
                          ),
                        },
                        {
                          title: i18n._(t`Waarde`),
                          key: "value",
                          dataIndex: "value",
                          width: 320,
                          render: (_, s) => (
                            <EditableSettingField setting={s} />
                          ),
                        },
                      ]}
                      dataSource={value}
                      rowKey="alias"
                    />
                  </Container>
                </Col>
              </Row>
            ))}
        </Spinner>
      </ContentContainer>
    </>
  );
};

export default Settings;
