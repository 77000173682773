import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Icons, Tooltip, Typography } from "@ster/ster-toolkit";
import { App as AntApp, List, Space } from "antd";
import classNames from "classnames";
import { memo, useCallback } from "react";

import { AnnouncementDto } from "../../api/models";
import styles from "./Announcements.module.less";
import EditableMessage from "./EditableMessage";
import EditablePeriod from "./EditablePeriod";

const AnnouncementItem = memo(
  ({
    item,
    onChange,
    toggleShow,
    deleteItem,
  }: {
    item: AnnouncementDto;
    onChange: (announcement: AnnouncementDto) => void;
    toggleShow: (id: number) => void;
    deleteItem: (id: number) => void;
  }) => {
    const handleToggleShow = useCallback(() => {
      if (item.id) {
        toggleShow(item.id);
      }
    }, [item.id, toggleShow]);

    const { i18n } = useLingui();

    const { modal } = AntApp.useApp();
    const handleDelete = useCallback(() => {
      modal.confirm({
        title: i18n._(t`Weet je het zeker?`),
        content: (
          <Typography.Paragraph>
            {i18n._(t`Dit bericht wordt verwijderd.`)}
          </Typography.Paragraph>
        ),
        onOk: () => {
          if (item.id) {
            deleteItem(item.id);
          }
        },
        cancelText: i18n._(t`Nee`),
        okText: i18n._(t`Ja`),
      });
    }, [modal, i18n, item.id, deleteItem]);

    return (
      <List.Item
        key={item.id}
        actions={[
          <Tooltip
            title={
              item.show
                ? i18n._(t`Klik om het bericht te verbergen`)
                : i18n._(t`Klik om het bericht zichtbaar te maken`)
            }
          >
            <Button
              type="link"
              mode="tertiary"
              className={styles.smallButton}
              onClick={handleToggleShow}
            >
              <Space key="toggle">
                <span
                  className={classNames(styles["icon-button"], {
                    [styles["not-visible"]]: !item.show,
                  })}
                >
                  <Icons.EyeIcon />
                </span>
                {item.show ? (
                  <Trans>Zichtbaar</Trans>
                ) : (
                  <Trans>Verborgen</Trans>
                )}
              </Space>
            </Button>
          </Tooltip>,

          <Tooltip title={i18n._(t`Verwijder dit bericht`)}>
            <Button
              type="link"
              mode="tertiary"
              className={styles.smallButton}
              onClick={handleDelete}
            >
              <Space key="delete">
                <span className={styles["icon-button"]}>
                  <Icons.DeleteIcon />
                </span>
                <Trans>Verwijderen</Trans>
              </Space>
            </Button>
          </Tooltip>,
        ]}
      >
        <List.Item.Meta
          title={<EditablePeriod item={item} onChange={onChange} />}
        />
        <Typography.Paragraph>
          <EditableMessage item={item} onChange={onChange} />
        </Typography.Paragraph>
      </List.Item>
    );
  }
);

export default AnnouncementItem;
