import { Trans } from "@lingui/macro";
import { Row, Typography } from "@ster/ster-toolkit";
import { Col, InputNumber } from "antd";
import { InputNumberProps } from "antd/lib/input-number";
import { memo, useCallback, useEffect } from "react";

import { usePrevious } from "../../../../utils/hooks";
import styles from "./NumberOfSpotsInput.module.less";

const NumberOfSpotsInput = memo(
  ({
    value,
    totalDays,
    totalChannels,
    onChange,
    ...inputProps
  }: InputNumberProps & { totalDays: number; totalChannels: number }) => {
    const prevTotalDays = usePrevious<number>(totalDays);
    const perDayChannel = value
      ? Math.round((value as number) / totalDays / totalChannels)
      : 0;
    const prevPerDayChannel = usePrevious<number>(perDayChannel);

    const handleInputChange = useCallback(
      (newValue: number | string | undefined | null) => {
        const newPerDayChannel = (newValue ?? 0) as number;
        if (onChange && newValue && newPerDayChannel) {
          onChange(newPerDayChannel * totalDays * totalChannels);
        }
      },
      [onChange, totalChannels, totalDays]
    );

    useEffect(() => {
      // Voer een herberekening uit als het aantal dagen wijzigt
      if (totalDays !== prevTotalDays) {
        handleInputChange(prevPerDayChannel);
      }
    }, [
      handleInputChange,
      perDayChannel,
      prevPerDayChannel,
      prevTotalDays,
      totalDays,
    ]);

    return (
      <Row align="middle">
        <Col>
          <InputNumber
            value={perDayChannel}
            {...inputProps}
            onChange={handleInputChange}
            step={1}
          />
        </Col>
        <Col>
          <span className={styles.spotText}>
            <Typography.Text>
              <Trans>Totaal aantal spots:</Trans>
            </Typography.Text>{" "}
            <Typography.Text>{value}</Typography.Text>
          </span>
        </Col>
      </Row>
    );
  }
);

export default NumberOfSpotsInput;
