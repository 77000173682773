import { Button } from "@ster/ster-toolkit";
import { PropsWithChildren, useCallback, useState } from "react";

import {
  Banner,
  Commercial,
  CommercialInstructionSpots,
  CommercialsPerAdvertiser,
} from "../../../../api";
import BannersModal from "./BannersModal";
import CommercialsModal from "./CommercialsModal";

interface CommercialButtonProps {
  selected: CommercialInstructionSpots;
  onSelectCommercial: (index: number, item: Commercial) => void;
  onSelectBanner?: (index: number, item: Banner) => void;
  commercialsPerAdvertiser: CommercialsPerAdvertiser;
  index: number;
  useBanners?: boolean;
}

const CommercialButton = ({
  commercialsPerAdvertiser,
  onSelectCommercial,
  onSelectBanner,
  children,
  index,
  selected,
  useBanners,
}: PropsWithChildren<CommercialButtonProps>) => {
  const [visible, setVisible] = useState(false);

  const toggleModal = useCallback((): void => {
    setVisible(!visible);
  }, [visible]);

  const handleSelectCommercial = useCallback(
    (commercial: Commercial) => {
      onSelectCommercial(index, commercial);
    },
    [onSelectCommercial, index]
  );

  const handleSelectBanner = useCallback(
    (banner: Banner) => {
      onSelectBanner?.(index, banner);
    },
    [onSelectBanner, index]
  );

  return (
    <>
      <Button mode="tertiary" onClick={toggleModal}>
        {children}
      </Button>
      {useBanners ? (
        <BannersModal
          onSelect={handleSelectBanner}
          banners={commercialsPerAdvertiser.banners}
          onCancel={toggleModal}
          open={visible}
          selected={selected}
        />
      ) : (
        <CommercialsModal
          onSelect={handleSelectCommercial}
          commercials={commercialsPerAdvertiser.commercials}
          onCancel={toggleModal}
          open={visible}
          selected={selected}
        />
      )}
    </>
  );
};

export default CommercialButton;
