/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SpotIndexItem
 */
export interface SpotIndexItem {
  /**
   *
   * @type {number}
   * @memberof SpotIndexItem
   */
  index: number;
  /**
   *
   * @type {number}
   * @memberof SpotIndexItem
   */
  length: number;
}

export function SpotIndexItemFromJSON(json: any): SpotIndexItem {
  return SpotIndexItemFromJSONTyped(json, false);
}

export function SpotIndexItemFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SpotIndexItem {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    index: json["index"],
    length: json["length"],
  };
}

export function SpotIndexItemToJSON(value?: SpotIndexItem | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    index: value.index,
    length: value.length,
  };
}
