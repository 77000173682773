/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line import/no-unresolved
import { ActionMatchingPattern, Task } from "@redux-saga/types";
import {
  ActionPattern,
  ForkEffect,
  HelperWorkerParameters,
  call,
  cancel,
  fork,
  take,
} from "redux-saga/effects";

import { ResponseError } from "./base";

export type ActionTypeSuffix = "REQUEST" | "SUCCESS" | "FAILURE" | "CANCEL";

export const actionNameAsync = (
  groupName: string,
  suffix: ActionTypeSuffix
): string => `${groupName.toUpperCase()}_${suffix}`;

export const toError = (resp: any): ResponseError => ({
  name: "ResponseError",
  message: resp.statusText,
  status: resp.status,
});

export const takeLeadingPerKey = <
  P extends ActionPattern,
  Fn extends (...args: unknown[]) => unknown,
>(
  patternOrChannel: P,
  worker: any,
  keySelector: (...args: any[]) => any,
  ...args: HelperWorkerParameters<ActionMatchingPattern<P>, Fn>
): ForkEffect<never> =>
  // eslint-disable-next-line func-names
  fork(function* () {
    const tasks: Task[] = [];

    while (true) {
      const action: unknown = yield take(patternOrChannel);
      const key: number = yield call(keySelector, action);

      if (!(tasks[key] && tasks[key].isRunning())) {
        tasks[key] = yield fork(worker, ...args, action);
      }
    }
  });

export const takeLatestPerKey = <
  P extends ActionPattern,
  Fn extends (...args: unknown[]) => unknown,
>(
  patternOrChannel: P,
  worker: any,
  keySelector: (...args: any[]) => any,
  ...args: HelperWorkerParameters<ActionMatchingPattern<P>, Fn>
): ForkEffect<never> =>
  // eslint-disable-next-line func-names
  fork(function* () {
    const tasks: Task[] = [];

    while (true) {
      const action: unknown = yield take(patternOrChannel);
      const key: number = yield call(keySelector, action);

      if (tasks[key]) {
        yield cancel(tasks[key]);
      }

      tasks[key] = yield fork(worker, ...args, action);
    }
  });
