/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  AnnouncementDto,
  AnnouncementDtoFromJSON,
  AnnouncementDtoToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface ApiV1AnnouncementsIdDeleteRequest {
  id: number;
}

export interface ApiV1AnnouncementsPostRequest {
  announcementDto: AnnouncementDto;
}

/**
 *
 */
export class AnnouncementsApi extends runtime.BaseAPI {
  /**
   */
  async apiV1AnnouncementsAllGetRaw(): Promise<
    runtime.ApiResponse<Array<AnnouncementDto>>
  > {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Announcements/all`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(AnnouncementDtoFromJSON)
    );
  }

  /**
   */
  async apiV1AnnouncementsAllGet(): Promise<Array<AnnouncementDto>> {
    const response = await this.apiV1AnnouncementsAllGetRaw();
    return await response.value();
  }

  /**
   */
  async apiV1AnnouncementsGetRaw(): Promise<
    runtime.ApiResponse<Array<AnnouncementDto>>
  > {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Announcements`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(AnnouncementDtoFromJSON)
    );
  }

  /**
   */
  async apiV1AnnouncementsGet(): Promise<Array<AnnouncementDto>> {
    const response = await this.apiV1AnnouncementsGetRaw();
    return await response.value();
  }

  /**
   */
  async apiV1AnnouncementsIdDeleteRaw(
    requestParameters: ApiV1AnnouncementsIdDeleteRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1AnnouncementsIdDelete."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Announcements/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async apiV1AnnouncementsIdDelete(
    requestParameters: ApiV1AnnouncementsIdDeleteRequest
  ): Promise<void> {
    await this.apiV1AnnouncementsIdDeleteRaw(requestParameters);
  }

  /**
   */
  async apiV1AnnouncementsPostRaw(
    requestParameters: ApiV1AnnouncementsPostRequest
  ): Promise<runtime.ApiResponse<AnnouncementDto>> {
    if (
      requestParameters.announcementDto === null ||
      requestParameters.announcementDto === undefined
    ) {
      throw new runtime.RequiredError(
        "announcementDto",
        "Required parameter requestParameters.announcementDto was null or undefined when calling apiV1AnnouncementsPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Announcements`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: AnnouncementDtoToJSON(requestParameters.announcementDto),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AnnouncementDtoFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1AnnouncementsPost(
    requestParameters: ApiV1AnnouncementsPostRequest
  ): Promise<AnnouncementDto> {
    const response = await this.apiV1AnnouncementsPostRaw(requestParameters);
    return await response.value();
  }
}
