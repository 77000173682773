import "./packagecard.less";

import { Trans } from "@lingui/macro";
import { Button, Typography } from "@ster/ster-toolkit";

interface PackageCardProps {
  icon?: React.ReactNode;
  title: string;
  text: React.ReactNode;
  mostPicked?: boolean;
  button: {
    onClick: () => void;
    label: string;
  };
}

const PackageCard = ({
  icon,
  title,
  text,
  mostPicked,
  button,
}: PackageCardProps) => {
  const { Title } = Typography;
  return (
    <article className="package-card">
      {mostPicked && (
        <span className="package-card__label">
          <Trans>Meest gekozen</Trans>
        </span>
      )}
      {icon}
      <Title level={3}>{title}</Title>
      <p className="package-card__text">{text}</p>
      <Button mode="tertiary" onClick={button.onClick}>
        {button.label}
      </Button>
    </article>
  );
};

export default PackageCard;
