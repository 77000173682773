import { createAsyncAction } from "typesafe-actions";

import { SpotsByMedium, TipDto } from "../../api";
import { ResponseError } from "../base";
import { actionNameAsync } from "../utils";

export const receiveCurrentSpotsAction = createAsyncAction(
  actionNameAsync("currentSpots", "REQUEST"),
  actionNameAsync("currentSpots", "SUCCESS"),
  actionNameAsync("currentSpots", "FAILURE")
)<undefined, SpotsByMedium[], ResponseError>();

export const receiveTipsAction = createAsyncAction(
  actionNameAsync("tips", "REQUEST"),
  actionNameAsync("tips", "SUCCESS"),
  actionNameAsync("tips", "FAILURE")
)<undefined, TipDto[], ResponseError>();
