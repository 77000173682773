import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Icons } from "@ster/ster-toolkit";
import { App as AntApp } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { MediumEnum } from "../../../api";
import { ReduxStoreState } from "../../../store/base";
import { clearForwardCampaignAction } from "../../../store/campaignForward/actions";
import { StoreModel } from "../../../store/models";
import CampaignForwardModal from "./CampaignForwardModal";

const CampaignForwardButton = ({
  medium,
  dateFrom,
  dateTo,
  visible,
  disabled,
  saving,
}: {
  medium?: MediumEnum;
  dateFrom?: Date;
  dateTo?: Date;
  visible: boolean;
  disabled: boolean;
  saving: boolean;
}) => {
  const [show, setShow] = useState(false);
  const handleOpen = useCallback(() => {
    setShow(true);
  }, []);
  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const loadingState = useSelector(
    (store: StoreModel) => store.campaignForward.forwarded
  );

  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const { message } = AntApp.useApp();
  useEffect(() => {
    switch (loadingState.state) {
      case ReduxStoreState.Success: {
        setShow(false);
        const extra = loadingState.budgetChanged
          ? t`Een of meerdere budgetten wijken af van het originele concept.`
          : "";
        message.success({
          className: "succes",
          content: `${i18n._(t`Het concept is doorgestuurd.`)} ${i18n._(
            extra
          )}`.trim(),
          duration: 10,
        });

        dispatch(clearForwardCampaignAction());
        break;
      }
      case ReduxStoreState.Failure: {
        message.error(i18n._(t`Er ging iets mis bij het doorsturen.`));
        break;
      }
      default:
        break;
    }
  }, [dispatch, i18n, loadingState.budgetChanged, loadingState.state, message]);

  if (!visible) {
    return null;
  }

  return (
    <>
      <Button mode="tertiary" disabled={disabled} onClick={handleOpen}>
        <Icons.UploadIcon fill="#008ccc" />
        <Trans>Doorsturen</Trans>
      </Button>

      {show && medium && dateFrom && dateTo && (
        <CampaignForwardModal
          medium={medium}
          dateFrom={dateFrom}
          dateTo={dateTo}
          onClose={handleClose}
          loading={loadingState.loading}
          error={loadingState.error?.detail}
          saving={saving}
        />
      )}
    </>
  );
};

export default CampaignForwardButton;
