import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Channel,
  ChannelNames,
  Icons,
  Tooltip,
  VirtualTable,
  formatNumber,
  formatToEuro,
} from "@ster/ster-toolkit";
import { CheckboxProps } from "antd/lib/checkbox";
import { ColumnsType } from "antd/lib/table";
import { Key } from "antd/lib/table/interface";
import { parseJSON } from "date-fns";
import moment from "moment";
import { memo, useCallback, useMemo } from "react";

import { AvailableBreak, BreakSelection } from "../../api";
import { escapeRegex } from "../../utils";
import { isBetweenDays, setTimeFromString } from "../../utils/dateHelper";
import styles from "./BreakSelectTable.module.less";
import Footer from "./Footer";
import { BreakFilters } from "./models";
import PreferredPositionSelect from "./PreferredPositionSelect";

const BreakSelectTable = memo(
  ({
    breaks,
    value,
    onChange,
    filters,
    canSelectPreferredPosition,
    preferredPositionSurcharge,
    budget,
    alreadySelected,
  }: {
    breaks: AvailableBreak[];
    value: BreakSelection[];
    onChange: (v: BreakSelection[]) => void;
    filters: BreakFilters;
    canSelectPreferredPosition: boolean;
    preferredPositionSurcharge: number;
    budget?: number;
    alreadySelected?: string[] | null;
  }) => {
    const selectedRowKeys = value.map(({ key }) => key ?? "");
    const hasSecondaryTargetGroup = useMemo(
      () => !!filters.secondaryTargetGroup,
      [filters.secondaryTargetGroup]
    );

    /**
     * Calculate the nett spot price
     */
    const calculateNettSpotPrice = useCallback(
      (
        {
          nettSpotPrice,
          grossSpotPrice,
          selectivitySurcharge,
          booked,
        }: AvailableBreak,
        preferredPosition?: string | null
      ) =>
        nettSpotPrice +
        selectivitySurcharge +
        (booked || !preferredPosition || preferredPosition === "NNNN"
          ? 0
          : (grossSpotPrice ?? 0) * preferredPositionSurcharge),
      [preferredPositionSurcharge]
    );

    /**
     * Maak een rij-selectie (vinkje)
     */
    const handleRowSelectionChange = useCallback(
      (_selectedRowKeys: Key[], selectedRows: AvailableBreak[]): void => {
        const newValue = selectedRows.map(
          (selectedRow: AvailableBreak): BreakSelection => ({
            key: selectedRow.uniqueId,
            _break: selectedRow,
            preferredPosition: value.find(
              (selected) => selected.key === selectedRow.uniqueId
            )?.preferredPosition,
          })
        );
        onChange(newValue);
      },
      [onChange, value]
    );

    /**
     * Selecteer een VKP
     */
    const handleSelectPreferredPosition = useCallback(
      (uniqueId: string, preferredPosition: string) => {
        const index = value.map(({ key: id }) => id).indexOf(uniqueId);
        const newValue = [
          ...value.slice(0, index),
          { ...value[index], preferredPosition },
          ...value.slice(index + 1),
        ];

        onChange(newValue);
      },
      [onChange, value]
    );

    /**
     * Filteren op programma-naam
     */
    const filterPrograms = useCallback(
      (
        v: boolean | Key,
        { programmeBefore, programmeAfter }: AvailableBreak
      ): boolean => {
        const filterValue = v as string;
        if (!filterValue || filterValue.length === 0) {
          return true;
        }

        const regexp = new RegExp(escapeRegex(filterValue), "i");
        return regexp.test(programmeBefore) || regexp.test(programmeAfter);
      },
      []
    );

    const { i18n } = useLingui();
    const columns: ColumnsType<AvailableBreak> = useMemo(
      () => [
        {
          key: "alreadySelected",
          width: 48,
          align: "center",
          render: (_, { uniqueId }) =>
            alreadySelected?.includes(uniqueId) === true ? (
              <Tooltip
                title={i18n._(
                  t`Dit blok is al geselecteerd in een ander pakket van deze campagne`
                )}
                placement="left"
              >
                <span>
                  <Icons.InfoIcon fill="#008ccc" width={16} height={16} />
                </span>
              </Tooltip>
            ) : null,
        },
        {
          title: i18n._(t`Blok`),
          key: "breakId",
          width: 100,
          dataIndex: "breakId",
          sorter: (a, b): number => `${a.breakId}`.localeCompare(b.breakId),
        },
        {
          title: i18n._(t`Datum`),
          key: "schedDate",
          width: 160,
          render: (_, { schedDate }) => (
            <>{moment(schedDate).format("dd D MMM YYYY")}</>
          ),
          filteredValue: filters.schedDate,
          onFilter: (filterValue, record): boolean => {
            if (
              filters.schedDate &&
              filters.schedDate[0] &&
              filters.schedDate[1]
            ) {
              return (
                isBetweenDays(
                  parseJSON(filters.schedDate[0]),
                  parseJSON(filters.schedDate[1]),
                  record.schedDate
                ) &&
                (filters.days ?? [1, 2, 3, 4, 5, 6, 7]).includes(
                  moment(record.schedDate).isoWeekday()
                )
              );
            }

            return true;
          },
          sorter: (a, b): number =>
            a.schedDate.getTime() - b.schedDate.getTime(),
        },
        {
          title: i18n._(t`Tijd`),
          key: "schedStartTime",
          width: 100,
          render: (_, { schedStartTime }) => (
            <>{moment(schedStartTime).format("LT")}</>
          ),
          filteredValue: filters.schedStartTime,
          onFilter: (filterValue, { schedStartTime }): boolean => {
            if (
              filters.schedStartTime &&
              filters.schedStartTime[0] &&
              filters.schedStartTime[1]
            ) {
              const current = moment(schedStartTime);
              const from = setTimeFromString(
                current,
                filters.schedStartTime[0]
              );
              const to = setTimeFromString(current, filters.schedStartTime[1]);

              // Als de eindtijd voor de starttijd ligt vergelijken we met het eind & begin van de dag
              if (to < from) {
                return (
                  current.isBetween(
                    from,
                    moment(from).endOf("day"),
                    "milliseconds",
                    "[]"
                  ) ||
                  current.isBetween(
                    moment(to).startOf("day"),
                    to,
                    "milliseconds",
                    "[]"
                  )
                );
              }

              return current.isBetween(from, to, "milliseconds", "[]");
            }

            return true;
          },
          sorter: (a, b): number =>
            a.schedStartTime.getTime() - b.schedStartTime.getTime(),
        },
        {
          title: i18n._(t`Zender`),
          key: "channelDescr",
          width: 100,
          render: (_, { channelDescr }) => (
            <Channel type={channelDescr as ChannelNames} />
          ),
          filteredValue: filters.channelDescr,
          onFilter: (filterValue, record): boolean =>
            record.channelDescr === filterValue,
          sorter: (a, b): number =>
            (a.channelDescr ?? "").localeCompare(b.channelDescr ?? ""),
        },
        {
          title: i18n._(t`Voor`),
          key: "programmeBefore",
          dataIndex: "programmeBefore",
          ellipsis: true,
          sorter: (a, b): number =>
            a.programmeBefore.localeCompare(b.programmeBefore),
          filteredValue: filters.programmeBefore,
          filterMultiple: false,
          onFilter: filterPrograms,
        },
        {
          title: i18n._(t`Na`),
          key: "programmeAfter",
          dataIndex: "programmeAfter",
          ellipsis: true,
          sorter: (a, b): number =>
            a.programmeAfter.localeCompare(b.programmeAfter),
          filteredValue: filters.programmeAfter,
          filterMultiple: false,
          onFilter: filterPrograms,
        },
        {
          title: i18n._(t`Netto prijs`),
          key: "nettSpotPrice",
          align: "right",
          width: 150,
          render: (_, availableBreak) => {
            const preferredPosition = value.find(
              (s) => s.key === availableBreak.uniqueId
            )?.preferredPosition;

            // De prijs van een spot is de netto prijs + de toeslag voor selectiviteit + de toeslag voor de voorkeurspositie bij nog niet geboekte spots
            return (
              <>
                {formatToEuro(
                  calculateNettSpotPrice(availableBreak, preferredPosition),
                  false
                )}
              </>
            );
          },
          sorter: (a, b): number => a.nettSpotPrice - b.nettSpotPrice,
        },
        {
          title: i18n._(t`Prognose`),
          key: "predictedRating",
          align: "right",
          width: 125,
          render: (_, { predictedRating }) =>
            predictedRating ? formatNumber(predictedRating, 2) : "-",
          sorter: (a, b): number =>
            (a.predictedRating ?? 0) - (b.predictedRating ?? 0),
        },
        {
          title: (
            <Tooltip
              title={i18n._(t`Prognose voor de gekozen secundaire doelgroep`)}
            >
              {i18n._(t`Sec. dlgrp`)}
            </Tooltip>
          ),
          key: "predictedRatingSecondaryTargetGroup",
          align: "right",
          width: 125,
          render: (_, { predictedRatingSecondaryTargetGroup }) =>
            predictedRatingSecondaryTargetGroup
              ? formatNumber(predictedRatingSecondaryTargetGroup, 2)
              : "-",
          sorter: (a, b): number =>
            (a.predictedRatingSecondaryTargetGroup ?? 0) -
            (b.predictedRatingSecondaryTargetGroup ?? 0),
        },
        {
          title: i18n._(t`Selectiviteit`),
          key: "selectivity",
          align: "right",
          width: 125,
          render: (_, { selectivity }) => (
            <span>{selectivity ? formatNumber(selectivity) : ``}</span>
          ),
          sorter: (a, b): number => (a.selectivity ?? 0) - (b.selectivity ?? 0),
        },
        {
          title: i18n._(t`Positie`),
          key: "preferredPosition",
          width: 0,
          render: (_, { uniqueId, disabled, positionFreeString }) => (
            <PreferredPositionSelect
              uniqueId={uniqueId}
              enabled={!disabled && selectedRowKeys.includes(uniqueId)}
              onSelect={handleSelectPreferredPosition}
              value={
                value.find((selected) => selected.key === uniqueId)
                  ?.preferredPosition ?? undefined
              }
              positionFreeString={positionFreeString}
            />
          ),
        },
      ],
      [
        alreadySelected,
        calculateNettSpotPrice,
        filterPrograms,
        filters.channelDescr,
        filters.days,
        filters.programmeAfter,
        filters.programmeBefore,
        filters.schedDate,
        filters.schedStartTime,
        handleSelectPreferredPosition,
        i18n,
        selectedRowKeys,
        value,
      ]
    );

    const filteredColumns = useMemo(
      () =>
        columns.filter((s) => {
          switch (s.key) {
            case "preferredPosition":
              return canSelectPreferredPosition;
            case "predictedRatingSecondaryTargetGroup":
              return hasSecondaryTargetGroup;
            case "alreadySelected":
              return (alreadySelected?.length ?? 0) > 0;
            default:
              return true;
          }
        }),
      [
        alreadySelected?.length,
        canSelectPreferredPosition,
        columns,
        hasSecondaryTargetGroup,
      ]
    );

    const footer = useCallback(
      () => (
        <Footer
          breaks={breaks}
          filters={filters}
          value={value}
          preferredPositionSurcharge={preferredPositionSurcharge}
          budget={budget}
        />
      ),
      [breaks, budget, filters, preferredPositionSurcharge, value]
    );

    return (
      <VirtualTable
        id="breakSelectTable"
        compact
        rowKey={({ uniqueId }: AvailableBreak): string => uniqueId}
        rowSelection={{
          selectedRowKeys,
          onChange: handleRowSelectionChange,
          hideSelectAll: false,
          getCheckboxProps: ({
            disabled,
          }: AvailableBreak): Partial<
            Omit<CheckboxProps, "checked" | "defaultChecked">
          > => ({ disabled }),
        }}
        rowClassName={({ uniqueId }: AvailableBreak) =>
          alreadySelected?.includes(uniqueId) === true
            ? styles.alreadySelectedInOther
            : ""
        }
        columns={filteredColumns}
        dataSource={breaks}
        scroll={{ x: "max-content", y: window.innerHeight }}
        footer={footer}
      />
    );
  }
);

export default BreakSelectTable;
