import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Typography } from "antd";
import { memo } from "react";

import SimplePage from "../SimplePage";

const NoAccount = memo(() => {
  const { i18n } = useLingui();
  return (
    <SimplePage title={i18n._(t`Niet actief`)}>
      <Trans>
        <Typography.Paragraph>
          Je account voor de Klantportal is nog niet geactiveerd, neem zonodig
          even contact op met <a href="mailto:klantportal@ster.nl">Ster</a>.
        </Typography.Paragraph>
      </Trans>
    </SimplePage>
  );
});

export default NoAccount;
