import "../styles.less";
import "./suborder.less";

import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { formatNumber, formatToEuro } from "@ster/ster-toolkit";
import { memo } from "react";

import {
  MediumEnum,
  OrderStatus,
  PackageChoiceEnum,
  RunningState,
} from "../../../../api";
import CardStatistic from "../CardStatistic";
import ProgressIcon from "../ProgressIcon";
import {
  getProgress,
  getProgressDate,
  getRunningStateNumber,
  getRunningStateTitle,
} from "../utils";
import { SubOrderDetailsProps } from "./models";

const Requested = memo(
  ({
    medium,
    requestedBudget,
    requestedGrp,
    requestedImpressions,
    packageChoice,
    bonusBudgetRequested,
  }: SubOrderDetailsProps) => {
    const { i18n } = useLingui();

    const hideGrps = packageChoice === PackageChoiceEnum.FixedCosts;
    const formattedBudget = formatToEuro(requestedBudget, false);
    const formattedRequestedImpr = formatNumber(requestedImpressions ?? 0, 0);
    const formattedBonusBudgetRequested = formatToEuro(
      bonusBudgetRequested ?? 0,
      false
    );
    const formattedRequestedGRP = hideGrps
      ? ""
      : `GRP ${formatNumber(requestedGrp, 2)}`;

    return (
      <CardStatistic
        label={i18n._(t`Aanvraag`)}
        value={formattedBudget}
        subValue={
          bonusBudgetRequested
            ? i18n._(t`incl. ${formattedBonusBudgetRequested} bonus`)
            : undefined
        }
        detailValue={
          medium === MediumEnum.Inter
            ? `# ${formattedRequestedImpr}`
            : formattedRequestedGRP
        }
        className="card-stat--suborder"
      />
    );
  }
);

export const RunningValue = memo(
  ({
    status,
    medium,
    bookedBudget,
    achievedNettBudget,
    bookedGrp,
    achievedGrp,
    nacalcGrp,
    bookedImpressions,
    packageChoice,
    runningState,
    bonusBudgetBooked,
  }: SubOrderDetailsProps) => {
    const { i18n } = useLingui();
    const formattedBookedImpr = formatNumber(bookedImpressions ?? 0, 0);
    const formattedBonusBudgetBooked = formatToEuro(
      bonusBudgetBooked ?? 0,
      false
    );

    const currentlyBookedGrp =
      (status === OrderStatus.Ready &&
      nacalcGrp !== null &&
      nacalcGrp !== undefined
        ? nacalcGrp
        : bookedGrp) ?? 0;

    const hideGrps = packageChoice === PackageChoiceEnum.FixedCosts;
    const achievedOrBookedGrps =
      status === OrderStatus.Ready ? currentlyBookedGrp : achievedGrp;
    const formattedCurrentGrps = hideGrps
      ? ""
      : getRunningStateNumber(
          "GRP",
          currentlyBookedGrp,
          achievedOrBookedGrps,
          runningState,
          2
        );

    return (
      <CardStatistic
        label={i18n._(
          getRunningStateTitle(
            runningState,
            medium,
            nacalcGrp !== null && nacalcGrp !== undefined
          )
        )}
        value={getRunningStateNumber(
          "€",
          bookedBudget,
          status === OrderStatus.Ready ? bookedBudget : achievedNettBudget,
          runningState
        )}
        subValue={
          bonusBudgetBooked &&
          (runningState === RunningState.Forecast ||
            runningState === RunningState.Combination)
            ? i18n._(t`incl. ${formattedBonusBudgetBooked} bonus`)
            : undefined
        }
        detailValue={
          medium === MediumEnum.Inter
            ? `# ${formattedBookedImpr}`
            : formattedCurrentGrps
        }
        className="card-stat--suborder card-stat--wide card-stat--dark"
      />
    );
  }
);

export const SubOrderCardDetails = memo((details: SubOrderDetailsProps) => {
  const {
    status,
    medium,
    startDate,
    endDate,
    bookedBudget,
    requestedGrp,
    bookedGrp,
    achievedGrp,
    nacalcGrp,
    requestedImpressions,
    bookedImpressions,
    packageChoice,
    runningState,
  } = details;
  const { i18n } = useLingui();

  const currentlyBookedGrp =
    (status === OrderStatus.Ready &&
    nacalcGrp !== null &&
    nacalcGrp !== undefined
      ? nacalcGrp
      : bookedGrp) ?? 0;

  const progressGrp = getProgress(
    status === OrderStatus.Ready ? currentlyBookedGrp : achievedGrp,
    requestedGrp
  );

  const progressImpr =
    bookedImpressions && requestedImpressions
      ? getProgress(bookedImpressions, requestedImpressions)
      : 0;
  const progressDate = getProgressDate(startDate, endDate);
  const hideGrps = packageChoice === PackageChoiceEnum.FixedCosts;

  return (
    <section className="card-body">
      <div className="card__stats-wrapper">
        <Requested {...details} />
        {medium === MediumEnum.Radio &&
          status === OrderStatus.Ready &&
          nacalcGrp !== null &&
          nacalcGrp !== undefined && (
            <CardStatistic
              label={i18n._(getRunningStateTitle(RunningState.Forecast))}
              value={getRunningStateNumber(
                "€",
                bookedBudget,
                currentlyBookedGrp,
                RunningState.Forecast
              )}
              detailValue={
                hideGrps
                  ? ""
                  : getRunningStateNumber(
                      "GRP",
                      bookedGrp,
                      bookedGrp,
                      RunningState.Forecast,
                      2
                    )
              }
              className="card-stat--suborder card-stat--wide card-stat--dark"
            />
          )}
        {!(runningState === RunningState.Forecast && !bookedGrp) && (
          <RunningValue {...details} />
        )}
      </div>
      <div className="card__stats-wrapper card__stats-wrapper--column">
        <div className="card-stat__label">
          <Trans>Voortgang pakket</Trans>
        </div>
        <div className="icons-wrapper">
          {packageChoice !== PackageChoiceEnum.FixedCosts && (
            <ProgressIcon
              label={
                medium === MediumEnum.Inter ? i18n._(t`IMPR.`) : i18n._(t`GRP`)
              }
              value={medium === MediumEnum.Inter ? progressImpr : progressGrp}
              type="grp"
            />
          )}
          <ProgressIcon
            label={i18n._(t`Looptijd`)}
            value={progressDate}
            type="date"
          />
        </div>
      </div>
    </section>
  );
});

export default SubOrderCardDetails;
