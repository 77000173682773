/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  PackagePropertiesTv,
  PackagePropertiesTvFromJSON,
  PackagePropertiesTvFromJSONTyped,
  PackagePropertiesTvToJSON,
} from "./";

/**
 *
 * @export
 * @interface GroupedPackageTv
 */
export interface GroupedPackageTv {
  /**
   *
   * @type {string}
   * @memberof GroupedPackageTv
   */
  name: string;
  /**
   *
   * @type {Array<PackagePropertiesTv>}
   * @memberof GroupedPackageTv
   */
  properties: Array<PackagePropertiesTv>;
}

export function GroupedPackageTvFromJSON(json: any): GroupedPackageTv {
  return GroupedPackageTvFromJSONTyped(json, false);
}

export function GroupedPackageTvFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GroupedPackageTv {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json["name"],
    properties: (json["properties"] as Array<any>).map(
      PackagePropertiesTvFromJSON
    ),
  };
}

export function GroupedPackageTvToJSON(value?: GroupedPackageTv | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    properties: (value.properties as Array<any>).map(PackagePropertiesTvToJSON),
  };
}
