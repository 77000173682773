import { LeftOutlined } from "@ant-design/icons";
import { Trans } from "@lingui/macro";
import { memo, useCallback } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

interface PreviousSearch {
  from: string | undefined;
}

interface CampaignBackButtonProps {
  append?: URLSearchParams;
}

export const usePreviousSearch = (): string | undefined => {
  const location = useLocation();
  const locationState = location.state as PreviousSearch;
  const navigate = useNavigate();
  if (location && locationState && locationState.from && navigate.length > 1) {
    return locationState.from;
  }
  return undefined;
};

const CampaignBackButton = memo(({ append }: CampaignBackButtonProps) => {
  const previous = usePreviousSearch();
  const navigate = useNavigate();
  const pathname = "/campaigns";
  const handleBack = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
      if (previous) {
        event.preventDefault();
        let searchParams = new URLSearchParams(previous);

        if (append) {
          // Verwijder bestaande entries om dubbele te voorkomen
          Object.entries(append).forEach((element) => {
            searchParams.delete(element[0]);
          });

          // Voeg de bestaande en de nieuwe url parameters samen
          searchParams = new URLSearchParams({
            ...Object.fromEntries(searchParams),
            ...Object.fromEntries(append),
          });
        }
        navigate({ pathname, search: searchParams.toString() });
      }
    },
    [append, navigate, previous]
  );

  return (
    <Link onClick={handleBack} to={{ pathname, search: append?.toString() }}>
      <LeftOutlined />
      <Trans>campagnes</Trans>
    </Link>
  );
});

export default CampaignBackButton;
