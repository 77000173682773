/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  OnlineInstructionPerPeriod,
  OnlineInstructionPerPeriodFromJSON,
  OnlineInstructionPerPeriodFromJSONTyped,
  OnlineInstructionPerPeriodToJSON,
} from "./";

/**
 *
 * @export
 * @interface OnlineInstructionPerSubOrder
 */
export interface OnlineInstructionPerSubOrder {
  /**
   *
   * @type {number}
   * @memberof OnlineInstructionPerSubOrder
   */
  subOrderId: number;
  /**
   *
   * @type {Array<OnlineInstructionPerPeriod>}
   * @memberof OnlineInstructionPerSubOrder
   */
  instructionsPerPeriod?: Array<OnlineInstructionPerPeriod> | null;
}

export function OnlineInstructionPerSubOrderFromJSON(
  json: any
): OnlineInstructionPerSubOrder {
  return OnlineInstructionPerSubOrderFromJSONTyped(json, false);
}

export function OnlineInstructionPerSubOrderFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OnlineInstructionPerSubOrder {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    subOrderId: json["subOrderId"],
    instructionsPerPeriod: !exists(json, "instructionsPerPeriod")
      ? undefined
      : json["instructionsPerPeriod"] === null
        ? null
        : (json["instructionsPerPeriod"] as Array<any>).map(
            OnlineInstructionPerPeriodFromJSON
          ),
  };
}

export function OnlineInstructionPerSubOrderToJSON(
  value?: OnlineInstructionPerSubOrder | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    subOrderId: value.subOrderId,
    instructionsPerPeriod:
      value.instructionsPerPeriod === undefined
        ? undefined
        : value.instructionsPerPeriod === null
          ? null
          : (value.instructionsPerPeriod as Array<any>).map(
              OnlineInstructionPerPeriodToJSON
            ),
  };
}
