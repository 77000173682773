import { createAsyncAction } from "typesafe-actions";

import { AnnouncementDto } from "../../api/models";
import { ResponseError } from "../base";
import { actionNameAsync } from "../utils";

export const receiveAnnouncementsAction = createAsyncAction(
  actionNameAsync("announcements", "REQUEST"),
  actionNameAsync("announcements", "SUCCESS"),
  actionNameAsync("announcements", "FAILURE")
)<undefined, AnnouncementDto[], ResponseError>();

export const receivePublishedAnnouncementsAction = createAsyncAction(
  actionNameAsync("publishedAnnouncements", "REQUEST"),
  actionNameAsync("publishedAnnouncements", "SUCCESS"),
  actionNameAsync("publishedAnnouncements", "FAILURE")
)<undefined, AnnouncementDto[], ResponseError>();

export const upsertAnnouncementAction = createAsyncAction(
  actionNameAsync("upsertAnnouncement", "REQUEST"),
  actionNameAsync("upsertAnnouncement", "SUCCESS"),
  actionNameAsync("upsertAnnouncement", "FAILURE")
)<AnnouncementDto, AnnouncementDto, ResponseError>();

export const deleteAnnouncementAction = createAsyncAction(
  actionNameAsync("deleteAnnouncement", "REQUEST"),
  actionNameAsync("deleteAnnouncement", "SUCCESS"),
  actionNameAsync("deleteAnnouncement", "FAILURE")
)<number, undefined, ResponseError>();
