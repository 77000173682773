import { Spinner } from "@ster/ster-toolkit";
import { Store } from "antd/es/form/interface";
import { isDate } from "date-fns";
// eslint-disable-next-line import/no-extraneous-dependencies
import { FormFinishInfo } from "rc-field-form/lib/FormContext";
// eslint-disable-next-line import/no-extraneous-dependencies
import { FormInstance } from "rc-field-form/lib/interface";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";

import {
  MediumEnum,
  OrderRequest,
  PackageChoiceEnum,
  SubOrderRequest,
} from "../../api";
import { ReduxStoreState } from "../../store/base";
import {
  clearForecastAction,
  clearGroupedPackagesRadioAction,
  clearGroupedPackagesTvAction,
  clearPackagesAction,
  clearProductsAction,
  receiveCustomerContactClearAction,
  receiveCustomerOpportunitiesClearAction,
  receiveForecastAction,
  receiveInitialRequestAction,
  receiveSalesPeriodsAction,
} from "../../store/campaignCreate/actions";
import { clearCampaignInstructionsAction } from "../../store/campaignDetail/actions";
import { receiveEditableCampaignAction } from "../../store/campaignEdit/actions";
import { StoreModel } from "../../store/models";
import { subOrderGenericFieldsFilled } from "../../utils";
import { usePrevious } from "../../utils/hooks";
import CampaignCreate from "../campaignCreate/CampaignCreate";
import CampaignEdit from "../campaignEdit/CampaignEdit";
import { ValidationResults } from "./models";
import { campaignCreateEditSelector } from "./selectors";

interface CampaignCreateEditProps {
  isEdit?: boolean;
}

const CampaignCreateEdit = memo(({ isEdit }: CampaignCreateEditProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    orderId: orderIdFromParams,
    initialRequestId: initialRequestIdFromParams,
    medium: mediumFromParams,
    from: fromFromParams,
    to: toFromParams,
  } = useParams<{
    medium?: string;
    orderId?: string;
    initialRequestId?: string;
    from?: string;
    to?: string;
  }>();

  const medium = mediumFromParams as MediumEnum;
  const orderId = Number(orderIdFromParams ?? 0);
  const initialRequestId = Number(initialRequestIdFromParams ?? 0);
  const from = useMemo(
    () => (fromFromParams ? new Date(fromFromParams) : undefined),
    [fromFromParams]
  );
  const to = useMemo(
    () => (toFromParams ? new Date(toFromParams) : undefined),
    [toFromParams]
  );
  const [orderRequest, setOrderRequest] = useState<OrderRequest>();

  const {
    initialRequest,
    calculationTimestamp,
    forecastState,
    campaign,
    campaignState,
  } = useSelector((state: StoreModel) => {
    const date =
      from && isDate(from) ? from : orderRequest?.period?.from ?? new Date();
    return campaignCreateEditSelector(
      state,
      date,
      initialRequestId !== 0 ? initialRequestId : orderId
    );
  });

  const prevCalculationTimestamp = usePrevious(calculationTimestamp);

  useEffect(() => {
    setOrderRequest(initialRequest || campaign);
  }, [campaign, initialRequest]);

  useEffect(() => {
    // Ophalen van een concept
    if (!isEdit && !initialRequest && initialRequestId && from && to) {
      // Haal de bestaande aanvraag op
      dispatch(
        receiveInitialRequestAction.request({
          medium,
          initialRequestId,
          from: new Date(from),
          to: new Date(to),
        })
      );
    }
  }, [dispatch, from, initialRequest, initialRequestId, isEdit, medium, to]);

  useEffect((): void => {
    // Ophalen van een campagne
    if (!isEdit || campaignState !== ReduxStoreState.Initial) {
      return;
    }

    if (!campaign) {
      dispatch(
        receiveEditableCampaignAction.request({
          medium,
          orderId,
        })
      );
    }
  }, [campaign, campaignState, dispatch, isEdit, medium, orderId]);

  const reset = useCallback(() => {
    dispatch(receiveSalesPeriodsAction.request());
    dispatch(clearCampaignInstructionsAction());
    dispatch(clearForecastAction());
    dispatch(clearProductsAction());
    dispatch(receiveCustomerOpportunitiesClearAction());
    dispatch(receiveCustomerContactClearAction());
    dispatch(clearPackagesAction());
    dispatch(clearGroupedPackagesRadioAction());
    dispatch(clearGroupedPackagesTvAction());
  }, [dispatch]);

  useEffect(() => {
    reset();
  }, [reset]);

  const handleCancel = useCallback(() => {
    // reset
    setOrderRequest(undefined);
    reset();

    if (location.key === "default") {
      // geen vorige pagina's
      navigate("/campaigns");
    } else {
      navigate(-1);
    }
  }, [location.key, navigate, reset]);

  // ophalen van prognose
  const handleForecastCalculation = useCallback(
    (newSubOrders: SubOrderRequest[], conversionGroups?: string[] | null) => {
      if (orderRequest?.medium) {
        const filledSubOrders = newSubOrders
          .filter(
            (s) =>
              (subOrderGenericFieldsFilled(s) && s.grp) ||
              (s.packageChoice &&
                [
                  PackageChoiceEnum.FixedCosts,
                  PackageChoiceEnum.FixedCostsCult,
                ].includes(s.packageChoice) &&
                (s.breakSelection?.length ?? 0) > 0)
          )
          // Filter de lege spotlengtes
          .map((s) => ({
            ...s,
            spotLength: (s.spotLength ?? []).filter((sl) => sl),
          }));

        if (filledSubOrders.length === 0) {
          return;
        }

        dispatch(
          receiveForecastAction.request({
            input: {
              subOrders: filledSubOrders,
              conversionGroups,
              productId: orderRequest.productId,
            },
            initialRequestId: orderRequest.id ?? undefined,
            medium: orderRequest.medium,
          })
        );
      }
    },
    [dispatch, orderRequest]
  );

  const debouncedForecast = useDebouncedCallback(
    handleForecastCalculation,
    500
  );

  useEffect(() => {
    if (
      forecastState === ReduxStoreState.Initial &&
      (orderRequest?.subOrders?.filter((s) => s.budget).length ?? 0) ===
        orderRequest?.subOrders?.length
    ) {
      // ALs er een bestaand concept met deelorders geopend wordt vragen we de prognose op
      handleForecastCalculation(
        orderRequest?.subOrders ?? [],
        orderRequest?.forecastConversionGroups ?? []
      );
    }
  }, [
    forecastState,
    handleForecastCalculation,
    orderRequest?.forecastConversionGroups,
    orderRequest?.subOrders,
  ]);

  // Toevoegen van een nieuwe deelorder
  const handleAddNewSubOrder = useCallback(
    (subOrder: Partial<SubOrderRequest>) => {
      const newSubOrders = [
        ...(orderRequest?.subOrders ?? []),
        subOrder as SubOrderRequest,
      ];
      setOrderRequest({
        ...(orderRequest as OrderRequest),
        subOrders: newSubOrders,
      });

      // Als er een gevulde deelorder wordt toegevoegd (door dupliceren) vragen we een nieuwe prognose op
      if (subOrder.grp || (subOrder.breakSelection?.length ?? 0) > 0) {
        debouncedForecast.cancel();
        debouncedForecast(newSubOrders, orderRequest?.forecastConversionGroups);
      }
    },
    [debouncedForecast, orderRequest]
  );

  // Verwijderen van een deelorder
  const handleDeleteSubOrder = useCallback(
    (id: number) => {
      const newSubOrders =
        orderRequest?.subOrders?.filter((s) => s.id !== id) ?? [];
      setOrderRequest({
        ...(orderRequest as OrderRequest),
        subOrders: [...newSubOrders],
      });

      // Als er een deelorder wordt verwijderd vragen we een (nieuwe) prognose op
      if (newSubOrders.length > 0) {
        debouncedForecast.cancel();
        debouncedForecast(newSubOrders, orderRequest?.forecastConversionGroups);
      } else {
        dispatch(clearForecastAction());
      }
    },
    [orderRequest, debouncedForecast, dispatch]
  );

  // Bepaal of de prognose geupdated dient te worden
  const shouldUpdateForecast = useCallback(
    (
      subOrderUpdate: Partial<SubOrderRequest>,
      subOrder: SubOrderRequest,
      newSubOrders: SubOrderRequest[]
    ) => {
      // Als de deelorders wijzigen en er is een update in de berekening of blokselectie
      // dan updaten we ook de prognose
      if (
        calculationTimestamp !== prevCalculationTimestamp ||
        (subOrderUpdate.breakSelection !== undefined &&
          JSON.stringify(subOrderUpdate.breakSelection) !==
            JSON.stringify(subOrder.breakSelection))
      ) {
        debouncedForecast.cancel();
        debouncedForecast(newSubOrders, orderRequest?.forecastConversionGroups);
      }
    },
    [
      calculationTimestamp,
      debouncedForecast,
      orderRequest?.forecastConversionGroups,
      prevCalculationTimestamp,
    ]
  );

  // Wijzigen van een bestaande deelorder
  const handleSubOrderChange = useCallback(
    (subOrder: Partial<SubOrderRequest>) => {
      setOrderRequest((currentOrder) => {
        const currentOrders = currentOrder?.subOrders ?? [];
        const index = currentOrders.map(({ id }) => id).indexOf(subOrder.id);
        const newSubOrders = [
          ...currentOrders.slice(0, index),
          { ...currentOrders[index], ...subOrder },
          ...currentOrders.slice(index + 1),
        ];

        shouldUpdateForecast(subOrder, currentOrders[index], newSubOrders);

        return {
          ...(orderRequest as OrderRequest),
          subOrders: newSubOrders,
        };
      });
    },
    [orderRequest, shouldUpdateForecast]
  );

  const validateForm = useCallback(
    (form: FormInstance): Promise<boolean> =>
      form
        .validateFields()
        .then(() => true)
        .catch(() => false),
    []
  );

  const handleFormFinish = useCallback(
    (
      name: string,
      { forms, values }: FormFinishInfo,
      handleFormValid: (
        formName: string,
        validationResults: ValidationResults[],
        formValues: Store
      ) => void
    ) => {
      // Alleen afhandeling als er op een submit knop in een save-formulier gedrukt wordt
      const formsToHandle = [
        "save",
        "finalize",
        "saveSubOrdersNext",
        "saveSubOrdersPrevious",
        "proposal",
        "forward",
      ];
      if (!formsToHandle.includes(name)) {
        return;
      }

      // Controleer of er nog formulieren zijn met errors
      const validations = Object.entries<FormInstance>(forms).map(
        (
          form: [string, FormInstance]
        ): Promise<{ name: string; valid: boolean }> => {
          // Het details formulier niet saven als de suborders opgeslagen worden
          if (name.startsWith("saveSubOrders") && form[0] === "details") {
            return Promise.resolve({ name: form[0], valid: true });
          }

          // De formulier die alleen een knop bevatten dienen niet gesubmit te worden
          if (!formsToHandle.includes(form[0])) {
            // Als de submit failed zal deze de `onFinishFailed` binnen het formulier triggeren
            return Promise.resolve(validateForm(form[1])).then((result) => {
              form[1].submit();
              return { name: form[0], valid: result };
            });
          }

          return Promise.resolve({ name: form[0], valid: true });
        }
      );

      Promise.all(validations).then(
        (validationResults: ValidationResults[]) => {
          handleFormValid(name, validationResults, values);
        }
      );
    },
    [validateForm]
  );

  const handleUpdateForecastConversionGroups = useCallback(
    (conversionGroups: string[]) => {
      setOrderRequest({
        ...(orderRequest as OrderRequest),
        forecastConversionGroups: conversionGroups,
      });
      if (orderRequest?.subOrders) {
        if ((orderRequest?.subOrders?.filter((s) => s.grp).length ?? 0) > 0) {
          // Vraag alleen prognoses op van deelorders waarbij het aantal GRP gevuld is
          debouncedForecast.cancel();
          debouncedForecast(
            orderRequest.subOrders.filter((s) => s.grp),
            conversionGroups
          );
        }
        if (
          orderRequest?.subOrders?.find(
            (s) =>
              s.packageChoice &&
              [
                PackageChoiceEnum.FixedCosts,
                PackageChoiceEnum.FixedCostsCult,
              ].includes(s.packageChoice)
          )
        ) {
          debouncedForecast.cancel();
          debouncedForecast(
            orderRequest.subOrders.filter((s) => s.breakSelection),
            conversionGroups
          );
        }
      }
    },
    [debouncedForecast, orderRequest]
  );

  const handleAddForecastConversionGroup = useCallback(
    (value: string) => {
      handleUpdateForecastConversionGroups([
        ...(orderRequest?.forecastConversionGroups ?? []),
        value,
      ]);
    },
    [handleUpdateForecastConversionGroups, orderRequest]
  );

  const handleDeleteForecastConversionGroup = useCallback(
    (value: string) => {
      handleUpdateForecastConversionGroups(
        (orderRequest?.forecastConversionGroups ?? []).filter(
          (s) => s !== value
        )
      );
    },
    [handleUpdateForecastConversionGroups, orderRequest]
  );

  return isEdit ? (
    <Spinner spinning={campaignState === ReduxStoreState.Loading}>
      {orderRequest && (
        <CampaignEdit
          onAddForecastConversionGroup={handleAddForecastConversionGroup}
          onDeleteForecastConversionGroup={handleDeleteForecastConversionGroup}
          onDeleteSubOrder={handleDeleteSubOrder}
          onAddSubOrder={handleAddNewSubOrder}
          onChangeSubOrder={handleSubOrderChange}
          onFormFinish={handleFormFinish}
          orderRequest={orderRequest}
          updateOrderRequest={setOrderRequest}
          onCancel={handleCancel}
        />
      )}
    </Spinner>
  ) : (
    <CampaignCreate
      onAddForecastConversionGroup={handleAddForecastConversionGroup}
      onDeleteForecastConversionGroup={handleDeleteForecastConversionGroup}
      onDeleteSubOrder={handleDeleteSubOrder}
      onAddSubOrder={handleAddNewSubOrder}
      onChangeSubOrder={handleSubOrderChange}
      onFormFinish={handleFormFinish}
      orderRequest={orderRequest}
      updateOrderRequest={setOrderRequest}
      onCancel={handleCancel}
    />
  );
});

export default CampaignCreateEdit;
