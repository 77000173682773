/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  RatingRegio,
  RatingRegioFromJSON,
  RatingRegioFromJSONTyped,
  RatingRegioToJSON,
  RunningState,
  RunningStateFromJSON,
  RunningStateFromJSONTyped,
  RunningStateToJSON,
  Tagon,
  TagonFromJSON,
  TagonFromJSONTyped,
  TagonToJSON,
} from "./";

/**
 *
 * @export
 * @interface Spot
 */
export interface Spot {
  /**
   *
   * @type {number}
   * @memberof Spot
   */
  achievedRating: number;
  /**
   *
   * @type {number}
   * @memberof Spot
   */
  achievedRatingSecondaryTargetGroup?: number | null;
  /**
   *
   * @type {number}
   * @memberof Spot
   */
  bonusAmount: number;
  /**
   *
   * @type {string}
   * @memberof Spot
   */
  breakId: string;
  /**
   *
   * @type {string}
   * @memberof Spot
   */
  channelDescr: string;
  /**
   *
   * @type {string}
   * @memberof Spot
   */
  commercialCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof Spot
   */
  commercialDescr?: string | null;
  /**
   *
   * @type {number}
   * @memberof Spot
   */
  discountAmount: number;
  /**
   *
   * @type {number}
   * @memberof Spot
   */
  grossSpotPrice: number;
  /**
   *
   * @type {number}
   * @memberof Spot
   */
  nettSpotPrice: number;
  /**
   *
   * @type {number}
   * @memberof Spot
   */
  subOrderId: number;
  /**
   *
   * @type {string}
   * @memberof Spot
   */
  packageDescr: string;
  /**
   *
   * @type {number}
   * @memberof Spot
   */
  predictedRating: number;
  /**
   *
   * @type {number}
   * @memberof Spot
   */
  predictedRatingSecondaryTargetGroup?: number | null;
  /**
   *
   * @type {string}
   * @memberof Spot
   */
  preferredPosition?: string | null;
  /**
   *
   * @type {string}
   * @memberof Spot
   */
  programmeAfter: string;
  /**
   *
   * @type {string}
   * @memberof Spot
   */
  programmeBefore: string;
  /**
   *
   * @type {Date}
   * @memberof Spot
   */
  schedDate: Date;
  /**
   *
   * @type {Date}
   * @memberof Spot
   */
  schedStartTime: Date;
  /**
   *
   * @type {number}
   * @memberof Spot
   */
  spotId: number;
  /**
   *
   * @type {Array<number>}
   * @memberof Spot
   */
  spotLength: Array<number>;
  /**
   *
   * @type {string}
   * @memberof Spot
   */
  spotStatus?: string | null;
  /**
   *
   * @type {number}
   * @memberof Spot
   */
  totalSpotDiscount: number;
  /**
   *
   * @type {number}
   * @memberof Spot
   */
  compositionSeqNr: number;
  /**
   *
   * @type {boolean}
   * @memberof Spot
   */
  isTagon?: boolean;
  /**
   *
   * @type {Array<Tagon>}
   * @memberof Spot
   */
  tagons?: Array<Tagon> | null;
  /**
   *
   * @type {string}
   * @memberof Spot
   */
  readonly uniqueId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Spot
   */
  disabled?: boolean;
  /**
   *
   * @type {RunningState}
   * @memberof Spot
   */
  runningState: RunningState;
  /**
   *
   * @type {number}
   * @memberof Spot
   */
  nacalcRating?: number | null;
  /**
   *
   * @type {number}
   * @memberof Spot
   */
  selectivity?: number | null;
  /**
   *
   * @type {number}
   * @memberof Spot
   */
  selectivitySecondaryTargetGroup?: number | null;
  /**
   *
   * @type {Array<RatingRegio>}
   * @memberof Spot
   */
  ratingsRegio: Array<RatingRegio>;
}

export function SpotFromJSON(json: any): Spot {
  return SpotFromJSONTyped(json, false);
}

export function SpotFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Spot {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    achievedRating: json["achievedRating"],
    achievedRatingSecondaryTargetGroup: !exists(
      json,
      "achievedRatingSecondaryTargetGroup"
    )
      ? undefined
      : json["achievedRatingSecondaryTargetGroup"],
    bonusAmount: json["bonusAmount"],
    breakId: json["breakId"],
    channelDescr: json["channelDescr"],
    commercialCode: !exists(json, "commercialCode")
      ? undefined
      : json["commercialCode"],
    commercialDescr: !exists(json, "commercialDescr")
      ? undefined
      : json["commercialDescr"],
    discountAmount: json["discountAmount"],
    grossSpotPrice: json["grossSpotPrice"],
    nettSpotPrice: json["nettSpotPrice"],
    subOrderId: json["subOrderId"],
    packageDescr: json["packageDescr"],
    predictedRating: json["predictedRating"],
    predictedRatingSecondaryTargetGroup: !exists(
      json,
      "predictedRatingSecondaryTargetGroup"
    )
      ? undefined
      : json["predictedRatingSecondaryTargetGroup"],
    preferredPosition: !exists(json, "preferredPosition")
      ? undefined
      : json["preferredPosition"],
    programmeAfter: json["programmeAfter"],
    programmeBefore: json["programmeBefore"],
    schedDate: new Date(json["schedDate"]),
    schedStartTime: new Date(json["schedStartTime"]),
    spotId: json["spotId"],
    spotLength: json["spotLength"],
    spotStatus: !exists(json, "spotStatus") ? undefined : json["spotStatus"],
    totalSpotDiscount: json["totalSpotDiscount"],
    compositionSeqNr: json["compositionSeqNr"],
    isTagon: !exists(json, "isTagon") ? undefined : json["isTagon"],
    tagons: !exists(json, "tagons")
      ? undefined
      : json["tagons"] === null
        ? null
        : (json["tagons"] as Array<any>).map(TagonFromJSON),
    uniqueId: !exists(json, "uniqueId") ? undefined : json["uniqueId"],
    disabled: !exists(json, "disabled") ? undefined : json["disabled"],
    runningState: RunningStateFromJSON(json["runningState"]),
    nacalcRating: !exists(json, "nacalcRating")
      ? undefined
      : json["nacalcRating"],
    selectivity: !exists(json, "selectivity") ? undefined : json["selectivity"],
    selectivitySecondaryTargetGroup: !exists(
      json,
      "selectivitySecondaryTargetGroup"
    )
      ? undefined
      : json["selectivitySecondaryTargetGroup"],
    ratingsRegio: (json["ratingsRegio"] as Array<any>).map(RatingRegioFromJSON),
  };
}

export function SpotToJSON(value?: Spot | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    achievedRating: value.achievedRating,
    achievedRatingSecondaryTargetGroup:
      value.achievedRatingSecondaryTargetGroup,
    bonusAmount: value.bonusAmount,
    breakId: value.breakId,
    channelDescr: value.channelDescr,
    commercialCode: value.commercialCode,
    commercialDescr: value.commercialDescr,
    discountAmount: value.discountAmount,
    grossSpotPrice: value.grossSpotPrice,
    nettSpotPrice: value.nettSpotPrice,
    subOrderId: value.subOrderId,
    packageDescr: value.packageDescr,
    predictedRating: value.predictedRating,
    predictedRatingSecondaryTargetGroup:
      value.predictedRatingSecondaryTargetGroup,
    preferredPosition: value.preferredPosition,
    programmeAfter: value.programmeAfter,
    programmeBefore: value.programmeBefore,
    schedDate: value.schedDate.toISOString(),
    schedStartTime: value.schedStartTime.toISOString(),
    spotId: value.spotId,
    spotLength: value.spotLength,
    spotStatus: value.spotStatus,
    totalSpotDiscount: value.totalSpotDiscount,
    compositionSeqNr: value.compositionSeqNr,
    isTagon: value.isTagon,
    tagons:
      value.tagons === undefined
        ? undefined
        : value.tagons === null
          ? null
          : (value.tagons as Array<any>).map(TagonToJSON),
    disabled: value.disabled,
    runningState: RunningStateToJSON(value.runningState),
    nacalcRating: value.nacalcRating,
    selectivity: value.selectivity,
    selectivitySecondaryTargetGroup: value.selectivitySecondaryTargetGroup,
    ratingsRegio: (value.ratingsRegio as Array<any>).map(RatingRegioToJSON),
  };
}
