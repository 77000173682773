import { createAsyncAction } from "typesafe-actions";

import {
  ApiV1ProposalGetProposalDataPostRequest,
  ProposalData,
} from "../../api";
import { ResponseError } from "../base";
import { actionNameAsync } from "../utils";

// eslint-disable-next-line import/prefer-default-export
export const receiveProposalDataAction = createAsyncAction(
  actionNameAsync("proposalData", "REQUEST"),
  actionNameAsync("proposalData", "SUCCESS"),
  actionNameAsync("proposalData", "FAILURE")
)<ApiV1ProposalGetProposalDataPostRequest, ProposalData, ResponseError>();
