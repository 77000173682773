import "./styles.less";

import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  ChannelNames,
  Container,
  Icons,
  Tag,
  Typography,
} from "@ster/ster-toolkit";
import { isSameDay } from "date-fns";
import moment, { Moment } from "moment";
import { useCallback } from "react";

import {
  CommercialInstructionCommercialsPerPeriod,
  CommercialInstructionStatus,
  MediumEnum,
} from "../../../api";
import {
  getInstructionStatusTranslation,
  tagStatusMapper,
} from "../../../utils";
import { getDistinctChannelNames } from "../../../utils/channelNamesUtils";
import {
  formatTime,
  getDaysInsidePeriod,
  getExcludedDaysString,
} from "../../../utils/dateHelper";
import CardStatistic from "../Cards/CardStatistic";
import { formatDatePeriod } from "../Cards/utils";
import ChannelList from "../Channel/ChannelList";
import DeleteInstruction from "../Instructions/DeleteInstruction";
import EditInstruction from "../Instructions/EditInstruction";
import CommercialTable from "./CommercialTable";
import { InstructionSummaryProps } from "./types";

export type MomentRange = [Moment, Moment];

export interface PeriodToShow {
  range: MomentRange;
  diff: boolean;
}

export const getPeriodToShow = (
  instruction: MomentRange,
  campaign: MomentRange | undefined
): PeriodToShow => {
  if (!campaign) {
    // geen campagne periode
    return { range: instruction, diff: false };
  }

  if (
    campaign[0].isSameOrBefore(instruction[0], "day") &&
    campaign[1].isSameOrAfter(instruction[1], "day")
  ) {
    // instructie periode ligt tussen de campagne periode
    return { range: instruction, diff: false };
  }

  if (
    instruction[0].isBefore(campaign[0], "day") &&
    instruction[1].isAfter(campaign[1], "day")
  ) {
    // campagne periode ligt tussen de instructie periode
    return { range: campaign, diff: true };
  }

  if (
    instruction[0].isSame(campaign[0]) &&
    instruction[1].isSame(campaign[1])
  ) {
    // perioden gelijk
    return { range: instruction, diff: false };
  }

  if (
    instruction[0].isBefore(campaign[1], "day") &&
    instruction[1].isAfter(campaign[0], "day")
  ) {
    // campagne en instructie periode hebben overlap
    const start = campaign[0].isAfter(instruction[0])
      ? campaign[0]
      : instruction[0];
    const end = campaign[1].isBefore(instruction[1])
      ? campaign[1]
      : instruction[1];
    return { range: [start, end], diff: true };
  }

  return { range: instruction, diff: false };
};

const InstructionSummary = ({
  instruction,
  instructionNo,
  commercialsPerAdvertiser,
  onSave,
  onDelete,
  minDate,
  maxDate,
  spotLengths,
  campaignPeriod,
  subOrders,
}: InstructionSummaryProps) => {
  const { Title, Caption } = Typography;
  const { i18n } = useLingui();

  const channels =
    instruction.channels && instruction.channels.length > 0
      ? instruction.channels.map((channelName) => channelName as ChannelNames)
      : getDistinctChannelNames(subOrders);

  const showCampaignPeriod = useCallback(
    (commercialsPerPeriod: CommercialInstructionCommercialsPerPeriod) => {
      const periodsInsideCampaign = commercialsPerPeriod.dateRanges.map((s) =>
        getPeriodToShow([moment(s.from), moment(s.to)], campaignPeriod)
      );

      const days = periodsInsideCampaign
        ?.flatMap((s) =>
          getDaysInsidePeriod(s.range[0].toDate(), s.range[1].toDate())
        )
        .filter(
          (s) =>
            !commercialsPerPeriod.excluded.some((e) =>
              isSameDay(new Date(e), s)
            )
        )
        .sort((a, b) => Number(a) - Number(b));

      const excludedDaysString = getExcludedDaysString(days);

      return {
        days: excludedDaysString ? i18n._(excludedDaysString) : "",
        diff: periodsInsideCampaign.some((s) => s.diff),
      };
    },
    [campaignPeriod, i18n]
  );

  return (
    <section className="instruction-summary">
      <Container>
        <div className="instruction-summary__header">
          <div className="instruction-summary__titles">
            <Title level={2}>
              <Trans>Instructie {instructionNo}</Trans>
              {onSave && minDate && maxDate && spotLengths && (
                <EditInstruction
                  instruction={instruction}
                  commercialsPerAdvertiser={commercialsPerAdvertiser}
                  maxDate={maxDate}
                  medium={instruction.medium}
                  productId={instruction.productId}
                  onSave={onSave}
                  subOrders={subOrders}
                />
              )}
              {onDelete &&
                instruction.id &&
                instruction.instructionStatus ===
                  CommercialInstructionStatus.Concept && (
                  <DeleteInstruction
                    instructionId={instruction.id}
                    onDelete={onDelete}
                  />
                )}
            </Title>
            <Caption>
              <Trans>
                Aangemaakt op{" "}
                {moment(instruction.creationDate).format("LL [om] LT")}
              </Trans>
            </Caption>
          </div>
          <Tag
            status={tagStatusMapper(instruction.instructionStatus)}
            text={i18n._(
              getInstructionStatusTranslation(instruction.instructionStatus)
            )}
          />
        </div>
        <div>
          {instruction.commercialsPerPeriod.map((commercialsPerPeriod) => (
            <div className="rotation" key={commercialsPerPeriod.id}>
              <section className="stat-row">
                <div className="rotation-stat">
                  <Icons.CalendarIcon width={40} height={40} />
                  <CardStatistic
                    className="instruction-period"
                    label={<Trans>Periode</Trans>}
                    value={showCampaignPeriod(commercialsPerPeriod).days}
                    detailValue={
                      showCampaignPeriod(commercialsPerPeriod).diff
                        ? `Volledige looptijd: ${formatDatePeriod(
                            instruction.period?.from ??
                              campaignPeriod?.[0].toDate() ??
                              new Date(),
                            instruction.period?.to ??
                              campaignPeriod?.[1].toDate() ??
                              new Date()
                          )}`
                        : undefined
                    }
                  />
                </div>

                {(commercialsPerPeriod.commercialSelection[0].selectedDays
                  ?.length ?? 0) > 0 &&
                  (commercialsPerPeriod.commercialSelection[0].selectedDays
                    ?.length ?? 0) < 7 && (
                    <div className="rotation-stat">
                      <Icons.CalendarIcon width={40} height={40} />
                      <CardStatistic
                        label={<Trans>Dagen van de week</Trans>}
                        value={commercialsPerPeriod.commercialSelection[0].selectedDays
                          ?.map((s) => moment().weekday(s).format("dddd"))
                          .join(", ")}
                      />
                    </div>
                  )}

                {commercialsPerPeriod.commercialSelection[0].startTime &&
                  commercialsPerPeriod.commercialSelection[0].endTime && (
                    <div className="rotation-stat">
                      <Icons.TimeIcon width={40} height={40} />
                      <CardStatistic
                        label={<Trans>Tijd</Trans>}
                        value={`${formatTime(
                          commercialsPerPeriod.commercialSelection[0].startTime
                        )} - ${formatTime(
                          commercialsPerPeriod.commercialSelection[0].endTime
                        )}`}
                      />
                    </div>
                  )}

                {(channels?.length ?? 0) > 0 ? (
                  <div className="rotation-stat">
                    <CardStatistic
                      value={
                        <ChannelList
                          channelNames={channels}
                          channelWidth={50}
                          channelHeight={50}
                        />
                      }
                    />
                  </div>
                ) : (
                  <div className="rotation-stat">
                    <Icons.NPOIcon width={40} height={40} />
                    <CardStatistic
                      label={<Trans>Zenders</Trans>}
                      value={
                        instruction.medium === MediumEnum.Tv ? (
                          <Trans>NPO 1, 2 en 3</Trans>
                        ) : (
                          <Trans>NPO Radio 1, 2, 3, 4, 5 en FunX</Trans>
                        )
                      }
                    />
                  </div>
                )}
              </section>

              <CommercialTable
                productId={instruction.productId}
                rows={commercialsPerPeriod.commercialSelection}
                medium={instruction.medium}
                commercialsPerAdvertiser={commercialsPerAdvertiser}
              />
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default InstructionSummary;
