import { createSelector, createStructuredSelector } from "reselect";

import {
  CommercialInstructionRequest,
  CommercialsPerAdvertiser,
  OnlineInstructionPerSubOrder,
  OrderDetail,
} from "../../api/models";
import {
  accountSelector,
  bookingDateFromStoreSelector,
  bookingDateSelector,
  packagesFromStoreSelector,
  packagesSelector,
  settingsSelector,
} from "../../shared/selectors";
import { BookingDateMapped } from "../../store/campaignCreate/models";
import {
  LoadingBookingDate,
  LoadingCampaign,
  LoadingCampaignSpotsResult,
  LoadingCommercialsForProductId,
  LoadingInstructionsOnlineResult,
  LoadingInstructionsResult,
  LoadingPackages,
  LoadingSaveCampaignInstructionsOnline,
  StoreModel,
} from "../../store/models";

export const campaignDetailSelector = (
  { campaignDetail }: StoreModel,
  orderId: number
): LoadingCampaign | undefined => campaignDetail[orderId];

export const campaignSpotsSelector = (
  { campaignSpots }: StoreModel,
  orderId: number
): LoadingCampaignSpotsResult => campaignSpots[orderId];

const campaignInstructionsSelector = ({
  campaignInstructions,
}: StoreModel): LoadingInstructionsResult | undefined => campaignInstructions;

const commercialsForProductIdSelector = ({
  commercialsForProductId,
}: StoreModel): LoadingCommercialsForProductId | undefined =>
  commercialsForProductId;

const campaignInstructionsOnlineFromStoreSelector = ({
  campaignInstructionsOnline,
}: StoreModel): LoadingInstructionsOnlineResult | undefined =>
  campaignInstructionsOnline;

export const campaignSelector = createSelector(
  [campaignDetailSelector],
  (campaignDetail: LoadingCampaign | undefined): OrderDetail | undefined =>
    campaignDetail?.campaign
);

export const spotEditableDateSelector = createSelector(
  [bookingDateSelector],
  (bookingDate: BookingDateMapped): Date => bookingDate?.spotsEditableDate
);

const loadingSelector = createSelector(
  [
    campaignDetailSelector,
    campaignSpotsSelector,
    bookingDateFromStoreSelector,
    packagesFromStoreSelector,
    campaignInstructionsSelector,
    campaignInstructionsOnlineFromStoreSelector,
  ],
  (
    campaignDetail: LoadingCampaign | undefined,
    campaignSpots: LoadingCampaignSpotsResult | undefined,
    bookingDate: LoadingBookingDate | undefined,
    packages: LoadingPackages | undefined,
    campaignInstructions: LoadingInstructionsResult | undefined,
    campaignInstructionsOnline: LoadingInstructionsOnlineResult | undefined
  ): boolean =>
    (campaignDetail && campaignDetail.loading) ||
    (campaignSpots && campaignSpots.loading) ||
    (bookingDate && bookingDate.loading) ||
    (packages && packages.loading) ||
    (campaignInstructions && campaignInstructions.loading) ||
    (campaignInstructionsOnline && campaignInstructionsOnline.loading) ||
    false
);

export const campaignDetailRootSelector = createStructuredSelector({
  campaign: campaignSelector,
  packages: packagesSelector,
  loading: loadingSelector,
  spotsEditableDate: spotEditableDateSelector,
  account: accountSelector,
  settings: settingsSelector,
});

export const campaignSpotsRootSelector = createStructuredSelector({
  spots: campaignSpotsSelector,
  loading: loadingSelector,
});

const emptyInstructions: CommercialInstructionRequest[] = [];

export const instructionsSelector = createSelector(
  [campaignInstructionsSelector],
  (
    campaignInstructions: LoadingInstructionsResult | undefined
  ): CommercialInstructionRequest[] =>
    campaignInstructions?.instructions ?? emptyInstructions
);

const saveCampaignInstructionsOnlineFromStoreSelector = ({
  saveCampaignInstructionsOnline,
}: StoreModel): LoadingSaveCampaignInstructionsOnline | undefined =>
  saveCampaignInstructionsOnline;

export const campaignInstructionsOnlineSelector = createSelector(
  [campaignInstructionsOnlineFromStoreSelector],
  (
    campaignInstructionsOnline: LoadingInstructionsOnlineResult | undefined
  ): OnlineInstructionPerSubOrder[] =>
    campaignInstructionsOnline?.instructions ?? []
);

export const commercialsPerAdvertiserSelector = createSelector(
  [commercialsForProductIdSelector],
  (
    commercialsForProductId: LoadingCommercialsForProductId | undefined
  ): CommercialsPerAdvertiser | undefined =>
    commercialsForProductId?.commercialPerAdvertiser
);

export const instructionsLoadingSelector = createSelector(
  [campaignInstructionsSelector, commercialsForProductIdSelector],
  (
    campaignInstructions: LoadingInstructionsResult | undefined,
    commercialsForProductId: LoadingCommercialsForProductId | undefined
  ): boolean =>
    campaignInstructions?.loading || commercialsForProductId?.loading || false
);

export const instructionsOnlineLoadingSelector = createSelector(
  [
    campaignInstructionsOnlineFromStoreSelector,
    commercialsForProductIdSelector,
    saveCampaignInstructionsOnlineFromStoreSelector,
  ],
  (
    campaignInstructionsOnline: LoadingInstructionsOnlineResult | undefined,
    commercialsForProductId: LoadingCommercialsForProductId | undefined,
    saveCampaignInstructionsOnline:
      | LoadingSaveCampaignInstructionsOnline
      | undefined
  ): boolean =>
    campaignInstructionsOnline?.loading ||
    commercialsForProductId?.loading ||
    saveCampaignInstructionsOnline?.loading ||
    false
);

export const campaignInstructionsRootSelector = createStructuredSelector({
  instructions: instructionsSelector,
  commercialsPerAdvertiser: commercialsPerAdvertiserSelector,
  loading: instructionsLoadingSelector,
});

export const campaignOnlineInstructionsRootSelector = createStructuredSelector({
  instructions: campaignInstructionsOnlineSelector,
  commercialsPerAdvertiser: commercialsPerAdvertiserSelector,
  loading: instructionsOnlineLoadingSelector,
  saveInstructions: saveCampaignInstructionsOnlineFromStoreSelector,
});
