import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form, Option, Select, Tag } from "@ster/ster-toolkit";
import { memo, useCallback } from "react";

import { DateSpan, Package } from "../../../../api";
import { TagStatus, getPopupContainerSubOrders } from "../../../../utils";
import { PackageSelectProps } from "./models";
import {
  getExtraPackageText,
  isPackageOptionDisabled,
} from "./PackageFunctions";
import styles from "./PackageSelectGrouped.module.less";

/**
 * Dropdown met pakketopties
 */
const PackageSelectOnline = memo(
  ({
    packages,
    period,
    hideIndices = false,
    numberOfExcludedDays = 0,
    enabled,
    spreadEnabled,
    value,
  }: PackageSelectProps) => {
    const { i18n } = useLingui();
    const isDisabled = useCallback(
      (selectedPackage: Package, { from, to }: DateSpan) =>
        isPackageOptionDisabled(
          selectedPackage,
          { from, to },
          numberOfExcludedDays
        ),
      [numberOfExcludedDays]
    );

    const isSelectedPackageValid = useCallback(() => {
      const selectedPackage = packages.find(({ code }) => code === value);

      if (selectedPackage && period) {
        const { periodEnabled, salesLock } = isDisabled(
          selectedPackage,
          period
        );
        return (
          periodEnabled &&
          !salesLock &&
          !(spreadEnabled && !selectedPackage.isSpreadEnabled)
        );
      }
      return true;
    }, [isDisabled, packages, period, spreadEnabled, value]);

    return (
      <Form.Item
        label={i18n._(t`Pakket`)}
        wrapperCol={{ span: 12 }}
        name="packageCode"
        // ipv via de validator zetten we de validateStatus en het help attribuut zodat je meteen een melding krijgt als je pakket niet voldoet
        validateStatus={isSelectedPackageValid() ? "success" : "error"}
        help={
          isSelectedPackageValid()
            ? null
            : i18n._(t`Het pakket is niet beschikbaar in de gekozen periode.`)
        }
        rules={[
          {
            required: true,
            message: i18n._(t`Selecteer een pakket`),
          },
          {
            validator: (): Promise<void> =>
              isSelectedPackageValid()
                ? Promise.resolve()
                : Promise.reject(new Error()),
          },
        ]}
      >
        <Select.Search
          placeholder={i18n._(t`Selecteer een pakket`)}
          disabled={!enabled}
          optionFilterProp="search"
          getPopupContainer={getPopupContainerSubOrders}
        >
          {period &&
            packages.map((p) => {
              const packageProperties = isDisabled(p, period);
              const { periodEnabled, salesLock } = packageProperties;

              const optionEnabled =
                periodEnabled &&
                !salesLock &&
                !(spreadEnabled && !p.isSpreadEnabled);
              const idx = !hideIndices ? p.productIndex : "";

              return (
                <Option
                  key={p.code}
                  value={p.code}
                  disabled={!optionEnabled}
                  title={
                    !optionEnabled
                      ? i18n._(
                          t`Dit pakket is niet te kiezen, pas zonodig de periode van de deelorder aan`
                        )
                      : undefined
                  }
                  search={`${p.code} : ${p.name}`}
                >
                  {p.name}
                  {getExtraPackageText(
                    packageProperties,
                    spreadEnabled,
                    i18n,
                    p
                  )}
                  {idx && (
                    <Tag
                      status={TagStatus.Info}
                      text={`${i18n._(t`Pakketindex`)}: ${idx}`}
                      className={styles.dropdownTag}
                    />
                  )}
                </Option>
              );
            })}
        </Select.Search>
      </Form.Item>
    );
  }
);

export default PackageSelectOnline;
