/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum SettingDataType {
  String = "String",
  Int = "Int",
  Bool = "Bool",
  Datetime = "Datetime",
}

export function SettingDataTypeFromJSON(json: any): SettingDataType {
  return SettingDataTypeFromJSONTyped(json, false);
}

export function SettingDataTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SettingDataType {
  return json as SettingDataType;
}

export function SettingDataTypeToJSON(value?: SettingDataType | null): any {
  return value as any;
}
