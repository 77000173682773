import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Icons } from "@ster/ster-toolkit";
import { App as AntApp } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { MediumEnum } from "../../../api";
import { ReduxStoreState } from "../../../store/base";
import { clearProposalGenerateAction } from "../../../store/campaignCreate/actions";
import { LoadingPortalUser, StoreModel } from "../../../store/models";
import { usePrevious } from "../../../utils/hooks";
import CampaignProposalModal from "./CampaignProposalModal";

const CampaignProposalButton = ({
  medium,
  dateFrom,
  dateTo,
  visible,
  disabled,
  account,
  saving,
}: {
  medium?: MediumEnum;
  dateFrom?: Date;
  dateTo?: Date;
  visible: boolean;
  disabled: boolean;
  account: LoadingPortalUser;
  saving: boolean;
}) => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const { message } = AntApp.useApp();
  const [show, setShow] = useState(false);
  const handleOpen = useCallback(() => {
    setShow(true);
  }, []);
  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const loadingState = useSelector(
    (store: StoreModel) => store.proposalGenerate
  );

  const proposalState = loadingState.state;

  const prevState = usePrevious(proposalState);
  useEffect(() => {
    if (
      (prevState === ReduxStoreState.Loading ||
        prevState === ReduxStoreState.Initial) &&
      proposalState === ReduxStoreState.Success
    ) {
      message.success(
        i18n._(
          t`Het voorstel wordt naar je verstuurd, dit kan enkele minuten duren.`
        )
      );
      dispatch(clearProposalGenerateAction());
      handleClose();
    } else if (
      prevState === ReduxStoreState.Loading &&
      proposalState === ReduxStoreState.Failure
    ) {
      dispatch(clearProposalGenerateAction());
    }
  }, [dispatch, handleClose, i18n, message, prevState, proposalState]);

  if (!visible) {
    return null;
  }

  return (
    <>
      <Button mode="tertiary" disabled={disabled} onClick={handleOpen}>
        <Icons.PdfIcon fill="#008ccc" />
        <Trans>Voorstel</Trans>
      </Button>

      {show && medium && dateFrom && dateTo && (
        <CampaignProposalModal
          onClose={handleClose}
          account={account}
          loading={loadingState.loading}
          saving={saving}
        />
      )}
    </>
  );
};

export default CampaignProposalButton;
