/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Banner,
  BannerFromJSON,
  BannerFromJSONTyped,
  BannerToJSON,
  Commercial,
  CommercialFromJSON,
  CommercialFromJSONTyped,
  CommercialToJSON,
  Product,
  ProductFromJSON,
  ProductFromJSONTyped,
  ProductToJSON,
} from "./";

/**
 *
 * @export
 * @interface CommercialsPerAdvertiser
 */
export interface CommercialsPerAdvertiser {
  /**
   *
   * @type {number}
   * @memberof CommercialsPerAdvertiser
   */
  advertiserId: number;
  /**
   *
   * @type {string}
   * @memberof CommercialsPerAdvertiser
   */
  advertiserName: string;
  /**
   *
   * @type {Array<Banner>}
   * @memberof CommercialsPerAdvertiser
   */
  banners: Array<Banner>;
  /**
   *
   * @type {Array<Commercial>}
   * @memberof CommercialsPerAdvertiser
   */
  commercials: Array<Commercial>;
  /**
   *
   * @type {Array<Product>}
   * @memberof CommercialsPerAdvertiser
   */
  products: Array<Product>;
}

export function CommercialsPerAdvertiserFromJSON(
  json: any
): CommercialsPerAdvertiser {
  return CommercialsPerAdvertiserFromJSONTyped(json, false);
}

export function CommercialsPerAdvertiserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CommercialsPerAdvertiser {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    advertiserId: json["advertiserId"],
    advertiserName: json["advertiserName"],
    banners: (json["banners"] as Array<any>).map(BannerFromJSON),
    commercials: (json["commercials"] as Array<any>).map(CommercialFromJSON),
    products: (json["products"] as Array<any>).map(ProductFromJSON),
  };
}

export function CommercialsPerAdvertiserToJSON(
  value?: CommercialsPerAdvertiser | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    advertiserId: value.advertiserId,
    advertiserName: value.advertiserName,
    banners: (value.banners as Array<any>).map(BannerToJSON),
    commercials: (value.commercials as Array<any>).map(CommercialToJSON),
    products: (value.products as Array<any>).map(ProductToJSON),
  };
}
