import { Trans } from "@lingui/macro";
import { Container, Row, Spinner, Typography } from "@ster/ster-toolkit";
import { Col } from "antd";
import { memo } from "react";

import { SpotsByMedium } from "../../api/models";
import Carousel from "../partials/Carousel/Carousel";

const CurrentSpots = memo(
  ({
    spotsByMedium,
    loading,
  }: {
    spotsByMedium: SpotsByMedium[];
    loading: boolean;
  }) => (
    <Spinner spinning={loading}>
      <Container>
        <Row>
          <Col>
            <Typography.Title level={2}>
              <Trans>Nu en straks</Trans>
            </Typography.Title>
            <Typography.Paragraph>
              <Trans>
                Hieronder vind je de spots uit jouw campagne die de komende 2
                uur uitgezonden zullen worden.
              </Trans>
            </Typography.Paragraph>
          </Col>
        </Row>
        {spotsByMedium.length > 0 ? (
          spotsByMedium.map((spots) => (
            <Row key={spots.medium}>
              <Col span={24}>
                <Carousel data={spots} />
              </Col>
            </Row>
          ))
        ) : (
          <Row>
            <Col span={24}>
              <Typography.Paragraph>
                <Trans>Momenteel worden er geen spots uitgezonden.</Trans>
              </Typography.Paragraph>
            </Col>
          </Row>
        )}
      </Container>
    </Spinner>
  )
);

export default CurrentSpots;
