// eslint-disable-next-line import/no-extraneous-dependencies
import { ITelemetryItem } from "@microsoft/applicationinsights-core-js";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import {
  ApplicationInsights,
  SeverityLevel,
} from "@microsoft/applicationinsights-web";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Router } from "@remix-run/router";
import { MessageInstance } from "antd/lib/message/interface";
import { Component, ErrorInfo, ReactNode } from "react";

// Application Insights
const reactPlugin = new ReactPlugin();

interface ErrorBoundaryProps {
  cloudRoleNameUi?: string;
  instrumentationKey?: string;
  router: Router;
  children?: React.ReactNode;
  message: MessageInstance;
}

interface ErrorBoundaryState {
  hasError: boolean;
  initialized: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  private appInsights?: ApplicationInsights;

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, initialized: false };
  }

  componentDidUpdate(prevProps: Readonly<ErrorBoundaryProps>): void {
    const { instrumentationKey, cloudRoleNameUi, router } = this.props;
    const { initialized } = this.state;
    const { instrumentationKey: prevInstrumentationKey } = prevProps;

    if (prevInstrumentationKey !== instrumentationKey && !initialized) {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey,
          maxBatchInterval: 0,
          disableFetchTracking: false,
          enableCorsCorrelation: true,
          correlationHeaderExcludedDomains: [
            "google-analytics.com",
            "googletagmanager.com",
            "doubleclick.net",
          ],
          disableCookiesUsage: true,
          extensions: [reactPlugin],
          extensionConfig: {
            [reactPlugin.identifier]: { router },
          },
          isBrowserLinkTrackingEnabled: true,
          enableAutoRouteTracking: true,
        },
      });
      this.appInsights.loadAppInsights();
      this.appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
        if (envelope.name.toLowerCase().includes("exception")) {
          // Ignore resizeobserver errors, ref: https://stackoverflow.com/a/50387233
          if (
            envelope.data?.message.includes(
              "ResizeObserver loop completed with undelivered notifications"
            )
          )
            return false;
        }

        if (envelope && envelope.tags) {
          /* eslint-disable no-param-reassign */
          envelope.tags["ai.operation.name"] = window.document.title;
          envelope.tags["ai.cloud.role"] = cloudRoleNameUi;
          /* eslint-enable no-param-reassign */
        }

        return true;
      });

      this.setState({ initialized: true });
    }
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    this.setState({ hasError: true });
    if (this.appInsights) {
      this.appInsights.trackException({
        error,
        exception: error,
        severityLevel: SeverityLevel.Error,
        properties: { ...info },
      });
    }
  }

  render(): ReactNode {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      this.props.message.error({
        content: "Er is iets misgegaan.",
        duration: 20,
        onClose: () => {
          this.setState({ hasError: false });
        },
      });
    }

    return children ?? null;
  }
}

export default withAITracking(reactPlugin, ErrorBoundary);
