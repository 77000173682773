import "../../Pickers/styles.less";
import "./OnlineCommercialPicker.module.less";

import { Trans } from "@lingui/macro";
import { Icons, Input, Tooltip } from "@ster/ster-toolkit";
import { ChangeEvent, useCallback, useMemo } from "react";

import {
  Banner,
  Commercial,
  CommercialInstructionSpots,
  CommercialsPerAdvertiser,
} from "../../../../api";
import { onlineSpotLengthMap } from "../../../../utils/constants";
import CommercialButton from "../../Pickers/CommercialPicker/CommercialButton";

interface OnlineCommercialRowProps {
  selection: CommercialInstructionSpots;
  onRemove?: (commercialId: number) => void;
  onReady: (item: CommercialInstructionSpots) => void;
  commercialsPerAdvertiser?: CommercialsPerAdvertiser;
  canRemove: boolean;
  spotLength: number[];
  useBanners: boolean;
}

const OnlineCommercialRow = ({
  onRemove,
  onReady,
  commercialsPerAdvertiser,
  canRemove,
  spotLength,
  selection,
  useBanners,
}: OnlineCommercialRowProps) => {
  const { EditIcon, DeleteIcon, PlayIcon, FileIcon } = Icons;
  const { id, commercial, banner, tagOns, homepage } = selection;

  const handleSelectCommercial = useCallback(
    (_index: number, selectedCommercial: Commercial) => {
      onReady({
        id,
        commercial: selectedCommercial,
        tagOns,
        homepage,
      });
    },
    [homepage, id, onReady, tagOns]
  );

  const handleSelectBanner = useCallback(
    (_index: number, selectedBanner: Banner) => {
      onReady({ id, banner: selectedBanner, tagOns, homepage });
    },
    [homepage, id, onReady, tagOns]
  );

  const handleOnRemove = useCallback(() => {
    if (onRemove) {
      onRemove(id);
    }
  }, [id, onRemove]);

  const handleChangeHomepage = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      onReady({
        ...selection,
        homepage: target.value,
      });
    },
    [onReady, selection]
  );

  const filteredCommercials = useMemo(() => {
    if (useBanners) {
      return {
        ...commercialsPerAdvertiser,
        commercials: [],
      } as CommercialsPerAdvertiser;
    }

    return {
      ...commercialsPerAdvertiser,
      commercials: spotLength[0]
        ? commercialsPerAdvertiser?.commercials.filter((s) =>
            onlineSpotLengthMap[spotLength[0]].includes(
              (s as Commercial).length
            )
          )
        : [],
    } as CommercialsPerAdvertiser;
  }, [commercialsPerAdvertiser, spotLength, useBanners]);

  const buttonContent = useMemo(() => {
    if (commercial) {
      return (
        <>
          <span className="icon-wrapper play">
            <PlayIcon width={20} height={20} />
          </span>
          <Tooltip title={commercial.description}>{commercial.id}</Tooltip>
          <EditIcon width={16} height={16} />
        </>
      );
    }

    if (banner) {
      return (
        <>
          <span className="icon-wrapper">
            <FileIcon width={20} height={20} />
          </span>
          <Tooltip title={banner.description}>{banner.id}</Tooltip>
          <EditIcon width={16} height={16} />
        </>
      );
    }

    return useBanners ? (
      <Trans>Selecteer banner</Trans>
    ) : (
      <Trans>Selecteer hoofdspot</Trans>
    );
  }, [EditIcon, FileIcon, PlayIcon, banner, commercial, useBanners]);

  return (
    <div className="picker-row picker-row--commercial online">
      <CommercialButton
        selected={selection}
        onSelectCommercial={handleSelectCommercial}
        onSelectBanner={handleSelectBanner}
        commercialsPerAdvertiser={filteredCommercials}
        index={0}
        useBanners={useBanners}
      >
        {buttonContent}
      </CommercialButton>
      <div className="picker-row__input text">
        <Input
          type="text"
          onChange={handleChangeHomepage}
          value={homepage ?? "https://"}
        />
      </div>
      <div className="picker-row__buttons">
        {onRemove && (
          <Tooltip
            className="picker-row__tooltip"
            title={<Trans>Regel verwijderen</Trans>}
          >
            <button
              className="picker-row__button"
              type="button"
              disabled={!canRemove}
              onClick={handleOnRemove}
            >
              <DeleteIcon />
            </button>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default OnlineCommercialRow;
