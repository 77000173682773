import {
  Container,
  ContentContainer,
  Icons,
  Typography,
} from "@ster/ster-toolkit";
import { PropsWithChildren, memo } from "react";
import { Helmet } from "react-helmet-async";

import styles from "./SimplePage.module.less";

export interface SimplePageProps {
  title: string;
}

const SimplePage = memo(
  ({ title, children }: PropsWithChildren<SimplePageProps>) => (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <ContentContainer className="content">
        <Container>
          <span className={styles.icon}>
            <Icons.LoginIcon width="2.5rem" height="2.5rem" fill="#008ccc" />
          </span>
          <Typography.Title>{title}</Typography.Title>
          {children}
        </Container>
      </ContentContainer>
    </>
  )
);

export default SimplePage;
