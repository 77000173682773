/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  MediumEnum,
  MediumEnumFromJSON,
  MediumEnumFromJSONTyped,
  MediumEnumToJSON,
  Spot,
  SpotFromJSON,
  SpotFromJSONTyped,
  SpotToJSON,
} from "./";

/**
 *
 * @export
 * @interface SpotsResult
 */
export interface SpotsResult {
  /**
   *
   * @type {MediumEnum}
   * @memberof SpotsResult
   */
  medium: MediumEnum;
  /**
   *
   * @type {number}
   * @memberof SpotsResult
   */
  orderId: number;
  /**
   *
   * @type {Array<Spot>}
   * @memberof SpotsResult
   */
  spots: Array<Spot>;
}

export function SpotsResultFromJSON(json: any): SpotsResult {
  return SpotsResultFromJSONTyped(json, false);
}

export function SpotsResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SpotsResult {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    medium: MediumEnumFromJSON(json["medium"]),
    orderId: json["orderId"],
    spots: (json["spots"] as Array<any>).map(SpotFromJSON),
  };
}

export function SpotsResultToJSON(value?: SpotsResult | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    medium: MediumEnumToJSON(value.medium),
    orderId: value.orderId,
    spots: (value.spots as Array<any>).map(SpotToJSON),
  };
}
