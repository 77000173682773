/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Organisation,
  OrganisationFromJSON,
  OrganisationFromJSONTyped,
  OrganisationToJSON,
} from "./";

/**
 *
 * @export
 * @interface GetOrganisationCodesResponseData
 */
export interface GetOrganisationCodesResponseData {
  /**
   *
   * @type {Array<Organisation>}
   * @memberof GetOrganisationCodesResponseData
   */
  National?: Array<Organisation>;
  /**
   *
   * @type {Array<Organisation>}
   * @memberof GetOrganisationCodesResponseData
   */
  Regional?: Array<Organisation>;
}

export function GetOrganisationCodesResponseDataFromJSON(
  json: any
): GetOrganisationCodesResponseData {
  return GetOrganisationCodesResponseDataFromJSONTyped(json, false);
}

export function GetOrganisationCodesResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetOrganisationCodesResponseData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    National: !exists(json, "National")
      ? undefined
      : (json["National"] as Array<any>).map(OrganisationFromJSON),
    Regional: !exists(json, "Regional")
      ? undefined
      : (json["Regional"] as Array<any>).map(OrganisationFromJSON),
  };
}

export function GetOrganisationCodesResponseDataToJSON(
  value?: GetOrganisationCodesResponseData | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    National:
      value.National === undefined
        ? undefined
        : (value.National as Array<any>).map(OrganisationToJSON),
    Regional:
      value.Regional === undefined
        ? undefined
        : (value.Regional as Array<any>).map(OrganisationToJSON),
  };
}
