/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  DateSpan,
  DateSpanFromJSON,
  DateSpanFromJSONTyped,
  DateSpanToJSON,
  SubOrderConfiguration,
  SubOrderConfigurationFromJSON,
  SubOrderConfigurationFromJSONTyped,
  SubOrderConfigurationToJSON,
} from "./";

/**
 *
 * @export
 * @interface CampaignConfiguration
 */
export interface CampaignConfiguration {
  /**
   *
   * @type {string}
   * @memberof CampaignConfiguration
   */
  code?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof CampaignConfiguration
   */
  forecastConversionGroups?: Array<string> | null;
  /**
   *
   * @type {Date}
   * @memberof CampaignConfiguration
   */
  createdOn?: Date;
  /**
   *
   * @type {number}
   * @memberof CampaignConfiguration
   */
  id?: number;
  /**
   *
   * @type {Array<SubOrderConfiguration>}
   * @memberof CampaignConfiguration
   */
  _configuration?: Array<SubOrderConfiguration> | null;
  /**
   *
   * @type {DateSpan}
   * @memberof CampaignConfiguration
   */
  orderPeriod?: DateSpan;
}

export function CampaignConfigurationFromJSON(
  json: any
): CampaignConfiguration {
  return CampaignConfigurationFromJSONTyped(json, false);
}

export function CampaignConfigurationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CampaignConfiguration {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    code: !exists(json, "code") ? undefined : json["code"],
    forecastConversionGroups: !exists(json, "forecastConversionGroups")
      ? undefined
      : json["forecastConversionGroups"],
    createdOn: !exists(json, "createdOn")
      ? undefined
      : new Date(json["createdOn"]),
    id: !exists(json, "id") ? undefined : json["id"],
    _configuration: !exists(json, "configuration")
      ? undefined
      : json["configuration"] === null
        ? null
        : (json["configuration"] as Array<any>).map(
            SubOrderConfigurationFromJSON
          ),
    orderPeriod: !exists(json, "orderPeriod")
      ? undefined
      : DateSpanFromJSON(json["orderPeriod"]),
  };
}

export function CampaignConfigurationToJSON(
  value?: CampaignConfiguration | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    code: value.code,
    forecastConversionGroups: value.forecastConversionGroups,
    createdOn:
      value.createdOn === undefined ? undefined : value.createdOn.toISOString(),
    id: value.id,
    configuration:
      value._configuration === undefined
        ? undefined
        : value._configuration === null
          ? null
          : (value._configuration as Array<any>).map(
              SubOrderConfigurationToJSON
            ),
    orderPeriod: DateSpanToJSON(value.orderPeriod),
  };
}
