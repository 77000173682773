import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Icons, Tooltip } from "@ster/ster-toolkit";
import { memo, useCallback, useState } from "react";

import {
  CommercialInstructionRequest,
  CommercialInstructionStatus,
  CommercialsPerAdvertiser,
  MediumEnum,
} from "../../../api";
import styles from "./EditInstruction.module.less";
import InstructionModal from "./InstructionModal";
import { SimpleSubOrder } from "./utils";

const EditInstruction = ({
  instruction,
  commercialsPerAdvertiser,
  maxDate,
  medium,
  productId,
  onSave,
  subOrders,
}: {
  instruction: CommercialInstructionRequest;
  commercialsPerAdvertiser?: CommercialsPerAdvertiser;
  maxDate: Date;
  medium: MediumEnum;
  productId: number;
  onSave: (i: CommercialInstructionRequest, onOkCallback: () => void) => void;
  subOrders: SimpleSubOrder[];
}) => {
  const { i18n } = useLingui();
  const [show, setShow] = useState(false);

  const handleOpenDialog = useCallback(() => {
    setShow(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setShow(false);
  }, []);

  if (instruction.instructionStatus !== CommercialInstructionStatus.Concept) {
    return null;
  }

  return (
    <>
      <Tooltip
        title={i18n._(t`Bewerk deze uitzendinstructie`)}
        placement="bottom"
      >
        <button
          type="button"
          onClick={handleOpenDialog}
          className={styles["instructions-edit-btn"]}
        >
          <Icons.EditIcon />
        </button>
      </Tooltip>
      {show && (
        <InstructionModal
          onCancel={handleCloseDialog}
          onOk={handleCloseDialog}
          commercialsPerAdvertiser={commercialsPerAdvertiser}
          maxDate={maxDate}
          medium={medium}
          productId={productId}
          instruction={instruction}
          okText={<Trans>OK</Trans>}
          onSave={onSave}
          instructionStatus={instruction.instructionStatus}
          subOrders={subOrders}
          dateRangesWithoutInstructions={[]}
        />
      )}
    </>
  );
};

export default memo(EditInstruction);
