import { i18n } from "@lingui/core";
import { Trans, t } from "@lingui/macro";
import {
  Button,
  ContentContainer,
  Icons,
  Pageheader,
  Spinner,
} from "@ster/ster-toolkit";
import { App as AntApp } from "antd";
import { SelectValue } from "antd/lib/select";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useMatch,
  useNavigate,
} from "react-router-dom";

import {
  Advertiser,
  ApiV1DocumentDeleteGuidPostRequest,
  DocumentApi,
} from "../../api";
import apiConfig from "../../apiConfig";
import { receiveDocumentsAction } from "../../store/documents/actions";
import { receiveOrganisationsAction } from "../../store/organisations/actions";
import { getOrganisationCode } from "../../utils";
import { privateRoutePaths } from "../authentication/PrivateRoute";
import DocumentList from "./DocumentList";
import DocumentUpload from "./DocumentUpload";
import { documentsRootSelector } from "./selectors";

const DocumentContainer = memo(() => {
  const dispatch = useDispatch();
  const { message } = AntApp.useApp();

  // map state to props
  const { account, documents, organisations, loading } = useSelector(
    documentsRootSelector
  );

  // haal documenten op/vernieuwen
  useEffect((): void => {
    dispatch(receiveDocumentsAction.request());
  }, [dispatch]);

  // haal organisaties op/vernieuwen
  useEffect((): void => {
    if (
      account &&
      account.roles &&
      account.roles.includes("Medewerker") &&
      organisations.length === 0 &&
      !loading
    ) {
      dispatch(receiveOrganisationsAction.request());
    }
  }, [account, dispatch, loading, organisations.length]);

  const navigate = useNavigate();
  const openUpload = useCallback(() => {
    navigate("/documents/upload");
  }, [navigate]);

  const handleDeleteDocument = useCallback(
    (guid: string) => {
      const deleteDocByGuid: ApiV1DocumentDeleteGuidPostRequest = {
        guid: guid as string,
      };
      const api = new DocumentApi(apiConfig());
      api
        .apiV1DocumentDeleteGuidPost(deleteDocByGuid)
        .then(() => {
          message.success(i18n._(t`Document is succesvol verwijderd.`));
          dispatch(receiveDocumentsAction.request());
        })
        .catch(() => {
          message.error(i18n._(t`Er ging iets fout tijdens het verwijderen.`));
        });
    },
    [dispatch, message]
  );

  const matchUrl = useMatch("/documents/upload");

  const hasEditRights = useMemo(
    () =>
      account &&
      account.claims &&
      account.claims.includes("documenten_upload_alles") &&
      (account.sterInlog?.internalUser || account.roles.includes("Admin")),
    [account]
  );

  const organisationsWithDocs = useMemo(
    () => documents.map((d) => d.organisation),
    [documents]
  );
  const organisationsToDisplay = useMemo(
    () =>
      organisations
        .filter(
          (org) =>
            org.code &&
            (org.code === getOrganisationCode(account) ||
              organisationsWithDocs.includes(org.code))
        )
        .sort((a, b) => (a.name ?? "").localeCompare(b.name ?? "")),
    [account, organisationsWithDocs, organisations]
  );
  const [selectedOrganisation, setOrganisation] = useState<string>(
    getOrganisationCode(account)
  );
  const handleSelectOrganisation = useCallback((selectedCode: SelectValue) => {
    setOrganisation(selectedCode as string);
    setAdvertiser(0);
  }, []);

  const advertisers = useMemo(
    () =>
      documents
        .filter(
          (d) =>
            d.advertiser &&
            (d.organisation === selectedOrganisation ||
              selectedOrganisation === "")
        )
        .map(
          (d) =>
            ({ name: d.advertiserName ?? "", id: d.advertiser }) as Advertiser
        )
        .filter((a, idx, all) => all.findIndex((b) => b.id === a.id) === idx)
        .sort((a, b) => a.name.localeCompare(b.name)),
    [documents, selectedOrganisation]
  );

  const [selectedAdvertiser, setAdvertiser] = useState<number>(0);
  const filtered = useMemo(
    () =>
      documents.filter(
        ({ advertiser, organisation }) =>
          (selectedAdvertiser === 0 || advertiser === selectedAdvertiser) &&
          (selectedOrganisation === "" || organisation === selectedOrganisation)
      ),
    [documents, selectedAdvertiser, selectedOrganisation]
  );
  const handleSelectAdvertiser = useCallback((selectedId: SelectValue) => {
    setAdvertiser(
      selectedId && Number(selectedId) > 0 ? Number(selectedId) : 0
    );
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {matchUrl ? i18n._(t`Document upload`) : i18n._(t`Documenten`)}
        </title>
      </Helmet>

      <Pageheader
        title={
          matchUrl ? <Trans>Document upload</Trans> : <Trans>Documenten</Trans>
        }
        icon={
          <Icons.DocumentsIcon
            width="70%"
            height="70%"
            fill="rgba(129, 176, 210, 0.2)"
          />
        }
      >
        {hasEditRights && !matchUrl && (
          <Button
            mode="primary"
            onClick={openUpload}
            className="hide-for-mobile"
          >
            <Trans>Document uploaden</Trans>
          </Button>
        )}
      </Pageheader>
      <ContentContainer>
        <Spinner spinning={loading}>
          <Routes>
            <Route
              path="upload"
              element={
                hasEditRights ? (
                  <DocumentUpload />
                ) : (
                  <Navigate to={privateRoutePaths.forbidden} />
                )
              }
            />
            <Route
              path=""
              element={
                <DocumentList
                  organisations={organisationsToDisplay}
                  onSelectOrganisation={handleSelectOrganisation}
                  selectedOrganisation={selectedOrganisation}
                  advertisers={advertisers}
                  onSelectAdvertiser={handleSelectAdvertiser}
                  selectedAdvertiser={selectedAdvertiser}
                  documents={filtered}
                  handleDeleteDocument={handleDeleteDocument}
                  editable={hasEditRights}
                  showAllDetails={
                    account.sterInlog?.internalUser ||
                    account.roles.includes("Admin")
                  }
                />
              }
            />
          </Routes>
        </Spinner>
      </ContentContainer>
    </>
  );
});

export default DocumentContainer;
