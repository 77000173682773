import "./instructioncontainer.less";

import { Icons, Typography } from "@ster/ster-toolkit";
import { PropsWithChildren } from "react";

interface InstructionsContainerProps {
  title: string;
  onDeleteClick?: () => void;
}

const InstructionsContainer = ({
  children,
  onDeleteClick,
  title,
}: PropsWithChildren<InstructionsContainerProps>) => (
  <section className="instructions-container">
    <Typography.Title level={4}>{title}</Typography.Title>
    {onDeleteClick && (
      <button className="delete-btn" type="button" onClick={onDeleteClick}>
        <Icons.DeleteIcon />
      </button>
    )}
    {children}
  </section>
);

export default InstructionsContainer;
