import { useLingui } from "@lingui/react";
import { Checkbox } from "@ster/ster-toolkit";
import { addDays, format, startOfWeek } from "date-fns";
import { memo } from "react";

import { DayOfWeekProps } from "./models";

const DayOfWeekPicker = memo(
  ({
    enabledDays,
    value,
    onChange,
    disabled,
    shortDayNames,
  }: DayOfWeekProps) => {
    const { i18n } = useLingui();

    const firstDayOfWeek = startOfWeek(new Date());
    const shortWeekDaysArray = Array.from(Array(7)).map((e, i) => ({
      label: format(
        addDays(firstDayOfWeek, i),
        shortDayNames ? "EEEEEE" : "EEEE"
      ),
      value: i,
    }));

    return (
      <Checkbox.Group
        options={shortWeekDaysArray.map((weekDay, index) => ({
          label: i18n._(weekDay.label),
          value: weekDay.value,
          disabled: enabledDays[index] === 0,
        }))}
        disabled={disabled}
        onChange={onChange}
        value={value}
      />
    );
  }
);

export default DayOfWeekPicker;
