import { Row } from "@ster/ster-toolkit";
import { Col } from "antd";
import { CSSProperties, memo } from "react";
import { areEqual } from "react-window";

import { Organisation, PortalUser } from "../../api/models";
import UserCard from "../partials/Cards/User/UserCard";

const VirtualUserRow = memo(
  ({
    data,
    index,
    style,
  }: {
    data: {
      users: PortalUser[];
      organisations: Organisation[];
      onDelete: (userId: string) => void;
    };
    index: number;
    style?: CSSProperties;
  }) => {
    const user = data.users[index];
    const organisation = data.organisations.find(
      ({ code }) => code === user.userInfo.organisationCode
    );
    return (
      <Row key={user.id} style={{ ...style, marginBottom: 40 }}>
        <Col span={24}>
          <UserCard
            cardData={user}
            organisation={organisation}
            onDelete={data.onDelete}
          />
        </Col>
      </Row>
    );
  },
  areEqual
);

export default VirtualUserRow;
