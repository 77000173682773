import { ActionType, createReducer } from "typesafe-actions";

import { ReduxStoreState } from "../base";
import { LoadingAdvertisers } from "../models";
import { receiveAdvertisersAction } from "./actions";

const initialState: LoadingAdvertisers = {
  state: ReduxStoreState.Initial,
  advertisers: {},
  loading: false,
};

// eslint-disable-next-line import/prefer-default-export
export const advertisersReducer = createReducer<
  LoadingAdvertisers,
  ActionType<typeof receiveAdvertisersAction>
>(initialState)
  .handleAction(receiveAdvertisersAction.request, (state) => ({
    ...state,
    loading: true,
    state: ReduxStoreState.Loading,
  }))
  .handleAction(receiveAdvertisersAction.failure, (state) => ({
    ...state,
    loading: false,
    state: ReduxStoreState.Failure,
  }))
  .handleAction(receiveAdvertisersAction.success, (state, action) => ({
    advertisers: action.payload,
    loading: false,
    state: ReduxStoreState.Success,
  }));
