/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum UpdateFields {
  SpotLength = "SpotLength",
  Impressions = "Impressions",
  Budget = "Budget",
  EndDate = "EndDate",
  StartDate = "StartDate",
  Package = "Package",
}

export function UpdateFieldsFromJSON(json: any): UpdateFields {
  return UpdateFieldsFromJSONTyped(json, false);
}

export function UpdateFieldsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateFields {
  return json as UpdateFields;
}

export function UpdateFieldsToJSON(value?: UpdateFields | null): any {
  return value as any;
}
