import {
  AnyAction,
  applyMiddleware,
  compose,
  legacy_createStore as createStore,
} from "redux";
import createSagaMiddleware from "redux-saga";

import { logoutMeAction } from "./account/actions";
import { Loading, ReduxStoreState } from "./base";
import { StoreModel } from "./models";
import appReducer from "./reducers";
import rootSagas from "./sagas";

const composeEnhancers =
  (process.env.NODE_ENV === "development" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const rootReducer = (
  state: StoreModel | undefined,
  action: AnyAction
): ReturnType<typeof appReducer> => {
  if (action.type === logoutMeAction.request().type) {
    // reset the store to its initial state
    return appReducer(undefined, action);
  }

  const store = appReducer(state, action);
  if (action.type === "INVALIDATE" && action.payload.propertyName) {
    /* Als er een `INVALIDATE` actie voorkomt met een `propertyName`,
     * zal de betreffende property op `Initial` gezet worden als deze niet op `Succes` staat. */
    const key = action.payload.propertyName as keyof StoreModel;
    if ((store[key] as Loading).state !== ReduxStoreState.Success) {
      return {
        ...store,
        [key]: {
          loading: false,
          state: ReduxStoreState.Initial,
        } as Loading,
      };
    }
  }

  return store;
};

const sagaMiddleware = createSagaMiddleware();

const store = createStore<
  StoreModel,
  AnyAction,
  Record<string, unknown>,
  Record<string, unknown>
>(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(rootSagas);

export default store;
