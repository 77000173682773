import { createAction, createAsyncAction } from "typesafe-actions";

import { ApiV1BookSpotPostRequest } from "../../api";
import { ResponseError } from "../base";
import { actionNameAsync } from "../utils";
import {
  AvailableBreakFailurePayload,
  AvailableBreakRequestPayload,
  AvailableBreakResponsePayload,
} from "./models";

export const clearBreaksAction = createAction("RECEIVE_BREAKS_CLEAR")();

export const receiveBreaksAction = createAsyncAction(
  actionNameAsync("breaks", "REQUEST"),
  actionNameAsync("breaks", "SUCCESS"),
  actionNameAsync("breaks", "FAILURE")
)<
  AvailableBreakRequestPayload,
  AvailableBreakResponsePayload,
  AvailableBreakFailurePayload
>();

export const clearBookSpotAction = createAction("RECEIVE_BOOKSPOT_CLEAR")();

export const bookSpotAction = createAsyncAction(
  actionNameAsync("bookSpot", "REQUEST"),
  actionNameAsync("bookSpot", "SUCCESS"),
  actionNameAsync("bookSpot", "FAILURE")
)<ApiV1BookSpotPostRequest, boolean, ResponseError>();
