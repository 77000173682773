import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  ContentContainer,
  Form,
  Switch,
  Tag,
  Typography,
} from "@ster/ster-toolkit";
import { Alert, Form as AntForm } from "antd";
import { Store } from "antd/lib/form/interface";
import { memo, useCallback, useEffect, useState } from "react";

import { PackagePropertiesTv } from "../../../../api";
import { TagStatus, hasKey } from "../../../../utils";
import { formItemLayout } from "../../../partials/Form";
import { PackageOptionsTvProps } from "./models";
import styles from "./PackageOptions.module.less";

type PackageSwitchProperties = {
  name: string;
  label?: React.ReactNode;
  helpText?: string;
  selectedPackageProperties?: PackagePropertiesTv;
  packagesProperties: PackagePropertiesTv[];
};

const PackageSwitch = memo(
  ({
    name,
    label,
    helpText,
    selectedPackageProperties,
    packagesProperties,
  }: PackageSwitchProperties) => {
    const { i18n } = useLingui();

    const enabledForOption = useCallback(
      (option: string, toggled: boolean) => {
        if (!selectedPackageProperties) {
          return false;
        }

        // Bepaal of er een pakket beschikbaar met de betreffende optie
        const newSelection = {
          ...selectedPackageProperties,
          [option]: toggled,
        };

        return (
          packagesProperties.filter(
            ({ hasSpread, hasPreferredPosition, hasHotspot, hasPremium }) =>
              hasSpread === newSelection.hasSpread &&
              hasPreferredPosition === newSelection.hasPreferredPosition &&
              hasHotspot === newSelection.hasHotspot &&
              hasPremium === newSelection.hasPremium
          ).length > 0
        );
      },
      [packagesProperties, selectedPackageProperties]
    );

    return (
      <Form.Item
        label={label}
        name={name}
        valuePropName="checked"
        helpText={helpText}
      >
        <Switch
          disabled={
            !enabledForOption(
              name,
              selectedPackageProperties !== undefined &&
                hasKey(selectedPackageProperties, name) &&
                !selectedPackageProperties[name]
            )
          }
          checkedChildren={i18n._(t`aan`)}
          unCheckedChildren={i18n._(t`uit`)}
        />
      </Form.Item>
    );
  }
);

/**
 * Pakketopties voor tv
 */
const PackageOptionsTv = memo(
  ({
    packages,
    groupedPackage: { properties: packagesProperties },
    subOrder,
    onChange,
    onFinishFailed,
    hasSalesLock,
    groupedPackage,
  }: PackageOptionsTvProps) => {
    const { i18n } = useLingui();
    const [form] = AntForm.useForm();
    const [selectedPackageProperties, setSelectedPackageProperties] =
      useState<PackagePropertiesTv>();

    const handleValuesChange = useCallback(
      (changedValues: Store, values: Store) => {
        if (
          [
            "hasSpread",
            "hasPreferredPosition",
            "hasHotspot",
            "hasPremium",
          ].find((key) => hasKey(changedValues, key)) !== undefined
        ) {
          const packageProperties = packagesProperties.find(
            ({ hasSpread, hasPreferredPosition, hasHotspot, hasPremium }) =>
              hasSpread === (values.hasSpread ?? false) &&
              hasPreferredPosition === (values.hasPreferredPosition ?? false) &&
              hasHotspot === (values.hasHotspot ?? false) &&
              hasPremium === (values.hasPremium ?? false)
          );

          onChange({ packageCode: packageProperties?.code }, values);
        } else {
          onChange(changedValues, values);
        }
      },
      [packagesProperties, onChange]
    );

    useEffect(() => {
      const packageProperties = packagesProperties.find(
        ({ code }) => code === subOrder._package?.code
      );
      form.setFieldsValue({ ...subOrder, ...packageProperties });
      setSelectedPackageProperties(packageProperties);
    }, [form, packagesProperties, subOrder]);

    return (
      <div className={styles.container}>
        <Typography.Title level={3} className={styles.title}>
          <Trans>Extra opties</Trans>
        </Typography.Title>
        <ContentContainer className={styles.options}>
          <Form
            {...formItemLayout}
            form={form}
            onValuesChange={handleValuesChange}
            name={`packageOptionsTv-${subOrder.id}`}
            onFinishFailed={onFinishFailed}
          >
            {packagesProperties.find(({ hasPremium }) => hasPremium) !==
              undefined && (
              <PackageSwitch
                label={i18n._(t`Premium`)}
                name="hasPremium"
                helpText={i18n._(
                  t`Met de optie Premium kun je spreiding op basis van best effort verwachten.`
                )}
                packagesProperties={packagesProperties}
                selectedPackageProperties={selectedPackageProperties}
              />
            )}
            {packagesProperties.find(({ hasSpread }) => hasSpread) !==
              undefined && (
              <PackageSwitch
                label={i18n._(t`Spreiding`)}
                name="hasSpread"
                helpText={i18n._(t`Met de optie Spreiding kun je een gelijk aantal spots per
                zender per dag verwachten.`)}
                packagesProperties={packagesProperties}
                selectedPackageProperties={selectedPackageProperties}
              />
            )}
            {packagesProperties.find(
              ({ hasPreferredPosition }) => hasPreferredPosition
            ) !== undefined && (
              <PackageSwitch
                label={i18n._(t`Voorkeurspositie`)}
                name="hasPreferredPosition"
                helpText={i18n._(
                  t`Met de optie Voorkeurspositie kun je een specifieke positie binnen een reclameblok aanvragen.`
                )}
                packagesProperties={packagesProperties}
                selectedPackageProperties={selectedPackageProperties}
              />
            )}
            {packagesProperties.find(({ hasHotspot }) => hasHotspot) !==
              undefined && (
              <PackageSwitch
                label={i18n._(t`Hotspot`)}
                name="hasHotspot"
                helpText={i18n._(
                  t`Met de optie Hotspot krijg je voorrang boven andere inkoopopties.`
                )}
                packagesProperties={packagesProperties}
                selectedPackageProperties={selectedPackageProperties}
              />
            )}
            {packagesProperties.length > 1 && (
              <Form.Item label={i18n._(t`Gekozen pakket`)}>
                <span>
                  {subOrder._package?.name}
                  <Tag
                    className={styles.tag}
                    status={TagStatus.Info}
                    text={`${i18n._(t`Pakketindex`)}: ${
                      packages.find(
                        ({ code }) => code === subOrder._package?.code
                      )?.productIndex ?? 0
                    }`}
                  />
                </span>
              </Form.Item>
            )}
          </Form>
          {hasSalesLock && (
            <Alert
              showIcon
              type="info"
              message=""
              className="submitAlert"
              description={
                groupedPackage.name.toLocaleLowerCase().includes("kansspel") ? (
                  <Trans>
                    In verband met de huidige regelgeving rondom
                    kansspeladverteerders zijn niet alle pakketten beschikbaar.
                  </Trans>
                ) : (
                  <Trans>
                    In verband met een verkoopstop zijn niet alle pakketten
                    beschikbaar.
                  </Trans>
                )
              }
            />
          )}
        </ContentContainer>
      </div>
    );
  }
);

export default PackageOptionsTv;
