import { MessageDescriptor } from "@lingui/core";
import { msg } from "@lingui/macro";
import {
  addDays,
  eachDayOfInterval,
  format,
  getMonth,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  parse,
  startOfDay,
} from "date-fns";
import moment, { Moment, unitOfTime } from "moment";

import { allDaysOfTheWeek } from "../components/breakselect/constants";
import { Period } from "../shared/models";
import { DateRange } from "./types";

export const defaultDateFormat = "yyyy-MM-dd";

export const datesEquals = (
  a: Date,
  b: Date,
  granularity: unitOfTime.StartOf = "day"
): boolean => moment(a).isSame(moment(b), granularity);

export const periodEquals = (
  a: Period,
  b: Period,
  granularity: unitOfTime.StartOf = "day"
): boolean =>
  !!(a?.from && a?.to && b?.from && b?.to) &&
  datesEquals(a.from, b.from, granularity) &&
  datesEquals(a.to, b.to, granularity);

export const getFormattedDate = (
  date: Date | undefined,
  dateFormat = defaultDateFormat
): string => (date ? format(date, dateFormat) : "");

export const getDateFromString = (
  date: string,
  dateFormat = defaultDateFormat
): Date => parse(date, dateFormat, new Date());

export const setTimeFromString = (
  momentDate: Moment,
  timeStr: string
): Moment => {
  const splittedTime = timeStr.split(":");
  if (!splittedTime || splittedTime.length !== 2) {
    throw new Error("Incorrect time format, expected '03:45'.");
  }

  return momentDate.clone().set({
    hours: parseInt(splittedTime[0], 10),
    minute: parseInt(splittedTime[1], 10),
    seconds: 0,
    millisecond: 0,
  });
};

export const getFormattedDateShort = (
  date: Date | undefined,
  dateFormat = "d MMMM"
): string => (date ? format(date, dateFormat) : "");

export const getFormattedDateWithTimeShort = (
  date: Date | undefined,
  dateFormat = "d MMMM HH:mm"
): string => (date ? format(date, dateFormat) : "");

export const getDaysDiff = (from: moment.Moment, to: moment.Moment): number =>
  Math.round(
    to.diff(from, "days", true) + 1 // include current day
  );

/**
 * Geef de weekdagen terug die binnen de periode vallen op basis van het nummer van de dag
 * Maandag = 1, Zondag = 7.
 */
export const getSpecificDaysInsidePeriod = (
  dateFrom: Date,
  dateTo: Date,
  daysOfWeek: number[] = allDaysOfTheWeek
): Date[] =>
  daysOfWeek.flatMap((dayOfWeek) => {
    if (dayOfWeek < 1 || dayOfWeek > 7) {
      throw new Error("Incorrect dayOfWeek");
    }

    const start = moment(dateFrom);
    const end = moment(dateTo);
    const result = [];

    // pak eerste day van de week met kenmerk `dayOfWeek` (zondag = 7)
    const current = start.clone().isoWeekday(dayOfWeek);
    if (current.isSameOrAfter(start) && current.isSameOrBefore(end)) {
      result.push(current.clone().toDate());
    }

    while (current.isoWeekday(dayOfWeek + 7).isSameOrBefore(end)) {
      result.push(current.clone().toDate());
    }

    return result;
  });

/**
 * Geef alle dagen terug binnen een periode
 */
export const getDaysInsidePeriod = (dateFrom: Date, dateTo: Date): Date[] => {
  let i = 0;
  const dates: Date[] = [];
  while (i <= moment(dateTo).diff(moment(dateFrom), "days")) {
    const date = moment(dateFrom).add(i, "days").toDate();
    dates.push(date);
    i += 1;
  }
  return dates.sort((a, b) => a.getTime() - b.getTime());
};

// Filter de datums waarvan alle andere dagen van de week ook in de selectie zitten
export const filterFullWeeks = (dates: Date[]): Date[] =>
  dates.filter(
    (d) =>
      [0, 1, 2, 3, 4, 5, 6]
        .map((s) =>
          dates.findIndex((v) =>
            moment(d)
              .add(-(moment(d).isoWeekday() - 1), "days") // Begin de week vanaf maandag
              .add(s, "days")
              .isSame(moment(v), "days")
          )
        )
        .filter((s) => s !== -1).length === 7
  );

/**
 * Maak een mooie weergave voor de lijst van uitgesloten dagen. Wanneer er meer
 * dan 5 dagen uitgesloten zijn, worden er periodes gemaakt.
 * Bijvoorbeeld:
 * - Alleen dagen (max. 5): "20 juli, 9 augustus, 27 september"
 * - Meerdere periodes (max. 5): "20 juli - 9 augustus, 21 - 27 september"
 * - Totaal overzicht: "10 dagen tussen 20 juli - 27 september"
 */
export const getExcludedDaysString = (
  excluded: Date[] | undefined
): MessageDescriptor | undefined => {
  if (!excluded) {
    return undefined;
  }

  if (excluded.length <= 5) {
    return msg`${excluded
      .map((d) => getFormattedDate(d, "d MMMM"))
      .join(", ")}`;
  }

  const range = eachDayOfInterval({
    start: excluded[0],
    end: excluded[excluded.length - 1],
  });

  let periods: string[] = [];
  let tmpFirstInPeriod: Date | undefined = excluded[0];
  let tmpLastInPeriod: Date | undefined;
  range.forEach((day, index) => {
    const isExcluded = excluded.find((d) => isSameDay(d, day)) !== undefined;
    const hasNext = index !== range.length - 1;
    if (isExcluded) {
      if (!tmpFirstInPeriod) {
        // start nieuwe periode
        tmpFirstInPeriod = day;
        // zet het einde van de periode
        tmpLastInPeriod = day;
      } else if (tmpFirstInPeriod) {
        // zet het einde van de periode
        tmpLastInPeriod = day;
      }
    }

    if ((!isExcluded && tmpFirstInPeriod && tmpLastInPeriod) || !hasNext) {
      // einde van een periode
      if (!hasNext) {
        tmpLastInPeriod = day;
      }

      let formattedString;
      if (
        tmpLastInPeriod &&
        tmpFirstInPeriod &&
        isSameDay(tmpLastInPeriod, tmpFirstInPeriod)
      ) {
        // periode is slechts 1 dag
        formattedString = getFormattedDate(tmpFirstInPeriod, "d MMMM");
      } else {
        // maak de periode-string
        formattedString = `${getFormattedDate(
          tmpFirstInPeriod,
          "d"
        )} - ${getFormattedDate(tmpLastInPeriod, "d MMMM")}`;
      }

      periods = [...periods, formattedString];

      // reset
      tmpFirstInPeriod = undefined;
      tmpLastInPeriod = undefined;
    }
  });

  if (periods.length > 10) {
    // bij meer dan 10 periodes, aantal dagen + totale periode tonen
    return msg`${excluded.length} dagen tussen ${getFormattedDate(
      range[0],
      getMonth(range[0]) === getMonth(range[range.length - 1]) ? "d" : "d MMMM"
    )} - ${getFormattedDate(range[range.length - 1], "d MMMM")}`;
  }

  return msg`${periods.join(", ")}`;
};

export const formatTime = (time: string): string =>
  moment.utc(moment.duration(time).as("milliseconds")).format("HH:mm");

// Voeg (deels) overlappende dateranges samen
export const mergeDateRange = (dateRanges: DateRange[]) => {
  let mergedDateRanges: DateRange[] = [];
  dateRanges
    .sort((a, b) => Number(a.startDate) - Number(b.startDate))
    .forEach((current, index) => {
      // Alleen toevoegen als een andere periode niet volledig overlapt
      if (
        !mergedDateRanges.some(
          (other, i) =>
            i !== index &&
            other.startDate <= current.startDate &&
            other.endDate >= current.endDate
        )
      ) {
        const overlapBeforeIndex = mergedDateRanges.findIndex(
          (other) =>
            current.startDate <= moment(other.endDate).add(1, "day").toDate() &&
            current.endDate >= other.endDate
        );
        if (overlapBeforeIndex >= 0) {
          mergedDateRanges = [
            ...mergedDateRanges.slice(0, overlapBeforeIndex),
            {
              startDate: mergedDateRanges[overlapBeforeIndex].startDate,
              endDate: current.endDate,
            },
            ...mergedDateRanges.slice(index + 1),
          ];
        } else {
          mergedDateRanges = [...mergedDateRanges, current];
        }
      }
    });
  return mergedDateRanges;
};

export const datesToDateRanges = (dates: Date[]) =>
  mergeDateRange(dates.map((d) => ({ startDate: d, endDate: d })));

export const isSameOrBeforeDay = (date: Date, dateToCompare: Date) =>
  isBefore(startOfDay(date), startOfDay(dateToCompare)) ||
  isEqual(startOfDay(date), startOfDay(dateToCompare));

export const isSameOrAfterDay = (date: Date, dateToCompare: Date) =>
  isAfter(startOfDay(date), startOfDay(dateToCompare)) ||
  isEqual(startOfDay(date), startOfDay(dateToCompare));

export const isBetweenDays = (
  dateStart: Date,
  dateEnd: Date,
  dateToCompare: Date
) =>
  isSameOrAfterDay(dateToCompare, dateStart) &&
  isSameOrBeforeDay(dateToCompare, dateEnd);

/**
 * Returns a Date object with the date set to the first working day
 */
export const getFirstWorkingDay = (date: Date): Date => {
  const hours = date.getHours();
  const day = (hours >= 12 ? addDays(date, 1) : date).getDay();

  switch (day) {
    case 4:
      // Thursday => Monday
      return addDays(date, 4);
    case 5:
      // Friday => Tuesday
      return addDays(date, 4);
    case 6:
      // Saturday => Tuesday
      return addDays(date, 3);
    default:
      // Sunday => Tuesday
      // Monday => Wednesday
      // Tuesday => Thursday
      // Wednesday => Friday
      return addDays(date, 2);
  }
};
