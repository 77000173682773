import { i18n } from "@lingui/core";
import { Trans, t } from "@lingui/macro";
import {
  Button,
  Input,
  Option,
  Row,
  Select,
  Typography,
} from "@ster/ster-toolkit";
import { Col, Space } from "antd";
import { SelectValue } from "antd/lib/select";
import { ChangeEvent, createRef, memo, useCallback } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList as List } from "react-window";

import { Organisation, PortalUser } from "../../api";
import shared from "../Shared.module.less";
import { UsersFilter } from "./models";
import VirtualUserRow from "./VirtualUserRow";

interface UserListProps {
  users: PortalUser[];
  organisations: Organisation[];
  onSearch: (search: string) => void;
  onOrganisationSelect: (organisationCode: string) => void;
  onDelete: (userId: string) => void;
  onCreate: () => void;
  onExport: () => void;
  filter: UsersFilter;
}

const UsersList = memo(
  ({
    users,
    organisations,
    onSearch,
    onOrganisationSelect,
    onDelete,
    filter,
    onExport,
    onCreate,
  }: UserListProps) => {
    const listRef = createRef<List>();

    const handleSearch = useCallback(
      ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        onSearch(value ?? "");
        if (listRef && listRef.current) {
          listRef.current.scrollToItem(0);
        }
      },
      [listRef, onSearch]
    );

    const handleSelect = useCallback(
      (value: SelectValue) => {
        onOrganisationSelect((value as string) ?? "");
        if (listRef && listRef.current) {
          listRef.current.scrollToItem(0);
        }
      },
      [listRef, onOrganisationSelect]
    );

    return (
      <>
        <Row
          className={shared.filterrow}
          justify="end"
          align="middle"
          gutter={[24, 24]}
        >
          <Col span={24}>
            <Space className={shared.filterspacer} direction="horizontal">
              <Button mode="secondary" onClick={onExport}>
                <Trans>Exporteren</Trans>
              </Button>
              <Button mode="primary" onClick={onCreate}>
                <Trans>Nieuwe gebruiker</Trans>
              </Button>

              <Input.Search
                placeholder={i18n._(t`Zoeken`)}
                onChange={handleSearch}
                allowClear
                value={filter.search}
              />

              <Select.Search
                optionFilterProp="search"
                disabled={organisations.length === 0}
                style={{ minWidth: 320 }}
                placeholder={i18n._(t`Filter op organisatie`)}
                onChange={handleSelect}
                allowClear
                value={filter.organisationCode}
              >
                <Option key="all" value="">
                  <Trans>Alle organisaties</Trans>
                </Option>
                {organisations.map(({ name, code }) => (
                  <Option
                    key={code ?? ""}
                    value={code ?? ""}
                    search={`${name} ${code}`}
                  >
                    {name} ({code})
                  </Option>
                ))}
              </Select.Search>
            </Space>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {users.length === 0 ? (
              <Typography.Paragraph>
                <Trans>Er zijn geen gebruikers gevonden.</Trans>
              </Typography.Paragraph>
            ) : (
              <AutoSizer
                disableHeight
                // eslint-disable-next-line react/no-children-prop
                children={({ width }) => (
                  <List
                    ref={listRef}
                    itemCount={users.length}
                    height={640}
                    width={width}
                    itemData={{ users, organisations, onDelete }}
                    itemSize={(_): number => 200}
                    initialScrollOffset={0}
                  >
                    {VirtualUserRow}
                  </List>
                )}
              />
            )}
          </Col>
        </Row>
      </>
    );
  }
);

export default UsersList;
