/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface DynamicMarketIndexRadio
 */
export interface DynamicMarketIndexRadio {
  /**
   *
   * @type {number}
   * @memberof DynamicMarketIndexRadio
   */
  index: number;
  /**
   *
   * @type {Array<string>}
   * @memberof DynamicMarketIndexRadio
   */
  channelIds: Array<string>;
  /**
   *
   * @type {Date}
   * @memberof DynamicMarketIndexRadio
   */
  start: Date;
  /**
   *
   * @type {Date}
   * @memberof DynamicMarketIndexRadio
   */
  end: Date;
}

export function DynamicMarketIndexRadioFromJSON(
  json: any
): DynamicMarketIndexRadio {
  return DynamicMarketIndexRadioFromJSONTyped(json, false);
}

export function DynamicMarketIndexRadioFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DynamicMarketIndexRadio {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    index: json["index"],
    channelIds: json["channelIds"],
    start: new Date(json["start"]),
    end: new Date(json["end"]),
  };
}

export function DynamicMarketIndexRadioToJSON(
  value?: DynamicMarketIndexRadio | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    index: value.index,
    channelIds: value.channelIds,
    start: value.start.toISOString(),
    end: value.end.toISOString(),
  };
}
