import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Tooltip } from "@ster/ster-toolkit";
import { memo } from "react";

import { PackageChoiceEnum } from "../../../../api";
import ButtonOnlineEndReport from "../../Buttons/ButtonOnlineEndReport";

export interface SubOrderFooterOnlineProps {
  orderId: number;
  subOrder: {
    id: number;
    packageChoice: PackageChoiceEnum;
  };
  subOrderId: number;
  isReady: boolean;
}

const SubOrderFooterOnline = memo(
  ({ orderId, subOrder, subOrderId, isReady }: SubOrderFooterOnlineProps) => {
    const { i18n } = useLingui();

    return (
      <>
        <Button
          rel="noopener noreferrer"
          target="_blank"
          mode="tertiary"
          href={`/delivery/${orderId}/${subOrderId}.xls`}
        >
          <Tooltip title={i18n._(t`Download een Excel-sheet van 'deliveries'`)}>
            <span>
              <Trans>Delivery</Trans>
            </span>
          </Tooltip>
        </Button>

        <ButtonOnlineEndReport
          mode={isReady ? "secondary" : "tertiary"}
          orderId={orderId}
          subOrder={subOrder}
          isReady={isReady}
        />
      </>
    );
  }
);

export default SubOrderFooterOnline;
