import { Trans } from "@lingui/macro";
import { Container, Icons, Typography } from "@ster/ster-toolkit";
import { useMemo } from "react";

import { MediumEnum, OrderStatus, PackageChoiceEnum } from "../../../api";
import {
  getFormattedDateShort,
  getFormattedDateWithTimeShort,
} from "../../../utils/dateHelper";
import CardStatistic from "../Cards/CardStatistic";
import DeleteInstruction from "../Instructions/DeleteInstruction";
import CommercialTable from "../InstructionSummary/CommercialTable";
import EditOnlineInstruction from "../OnlineInstructions/EditOnlineInstruction";
import { OnlineInstructionSummaryProps } from "./types";

const OnlineInstructionSummary = ({
  subOrder,
  commercialsPerAdvertiser,
  onSave,
  onDelete,
  maxDate,
  productId,
  isEditCampaign,
}: OnlineInstructionSummaryProps) => {
  const { Title, Caption } = Typography;

  const editable = useMemo(
    () =>
      onSave &&
      maxDate &&
      ((subOrder.status ?? OrderStatus.Concept) === OrderStatus.Concept ||
        (isEditCampaign && subOrder.status !== OrderStatus.Ready)),
    [isEditCampaign, maxDate, onSave, subOrder.status]
  );

  return (
    <section className="instruction-summary">
      <Container>
        <div className="instruction-summary__header">
          <div className="instruction-summary__titles">
            <Title level={2}>
              {subOrder.packageName}
              {subOrder.spotLength?.[0]
                ? ` ${subOrder.spotLength?.[0]}":`
                : ":"}{" "}
              {subOrder.campaignName}
              {onSave && maxDate && editable && (
                <EditOnlineInstruction
                  commercialsPerAdvertiser={commercialsPerAdvertiser}
                  maxDate={maxDate}
                  onSave={onSave}
                  subOrders={[subOrder]}
                />
              )}
              {onDelete && !!subOrder.id && (
                <DeleteInstruction
                  instructionId={subOrder.id}
                  onDelete={onDelete}
                />
              )}
            </Title>
            <Caption>
              {getFormattedDateShort(subOrder.dateRange.startDate)} -{" "}
              {getFormattedDateShort(subOrder.dateRange.endDate)}
            </Caption>
          </div>
        </div>
        <div>
          {subOrder.onlineInstructionsPerPeriod?.map((instruction) => (
            <div className="rotation" key={instruction.id}>
              <section className="stat-row">
                {(instruction.startDate || instruction.endDate) && (
                  <div className="rotation-stat">
                    <Icons.CalendarIcon width={40} height={40} />
                    <CardStatistic
                      className="instruction-period"
                      label={<Trans>Periode</Trans>}
                      value={`${getFormattedDateWithTimeShort(
                        instruction.startDate ?? subOrder.dateRange.startDate
                      )} - ${getFormattedDateWithTimeShort(
                        instruction.endDate ?? subOrder.dateRange.endDate
                      )}`}
                    />
                  </div>
                )}
              </section>

              <CommercialTable
                productId={productId}
                rows={instruction.instructions ?? []}
                medium={MediumEnum.Inter}
                commercialsPerAdvertiser={commercialsPerAdvertiser}
                hasBanners={
                  subOrder.packageChoice === PackageChoiceEnum.Display
                }
              />
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default OnlineInstructionSummary;
