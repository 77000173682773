/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  SimplePortalUser,
  SimplePortalUserFromJSON,
  SimplePortalUserFromJSONTyped,
  SimplePortalUserToJSON,
  SterInlogUser,
  SterInlogUserFromJSON,
  SterInlogUserFromJSONTyped,
  SterInlogUserToJSON,
} from "./";

/**
 *
 * @export
 * @interface PortalUser
 */
export interface PortalUser {
  /**
   *
   * @type {string}
   * @memberof PortalUser
   */
  readonly id: string;
  /**
   *
   * @type {string}
   * @memberof PortalUser
   */
  readonly email: string;
  /**
   *
   * @type {boolean}
   * @memberof PortalUser
   */
  active: boolean;
  /**
   *
   * @type {string}
   * @memberof PortalUser
   */
  phoneNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof PortalUser
   */
  userName: string;
  /**
   *
   * @type {SimplePortalUser}
   * @memberof PortalUser
   */
  userInfo: SimplePortalUser;
  /**
   *
   * @type {Array<string>}
   * @memberof PortalUser
   */
  roles: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof PortalUser
   */
  claims: Array<string>;
  /**
   *
   * @type {Date}
   * @memberof PortalUser
   */
  readonly lastLogin?: Date | null;
  /**
   *
   * @type {SterInlogUser}
   * @memberof PortalUser
   */
  sterInlog: SterInlogUser;
  /**
   *
   * @type {string}
   * @memberof PortalUser
   */
  userType: string;
  /**
   *
   * @type {string}
   * @memberof PortalUser
   */
  ipAddress?: string | null;
}

export function PortalUserFromJSON(json: any): PortalUser {
  return PortalUserFromJSONTyped(json, false);
}

export function PortalUserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PortalUser {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    email: json["email"],
    active: json["active"],
    phoneNumber: !exists(json, "phoneNumber") ? undefined : json["phoneNumber"],
    userName: json["userName"],
    userInfo: SimplePortalUserFromJSON(json["userInfo"]),
    roles: json["roles"],
    claims: json["claims"],
    lastLogin: !exists(json, "lastLogin")
      ? undefined
      : json["lastLogin"] === null
        ? null
        : new Date(json["lastLogin"]),
    sterInlog: SterInlogUserFromJSON(json["sterInlog"]),
    userType: json["userType"],
    ipAddress: !exists(json, "ipAddress") ? undefined : json["ipAddress"],
  };
}

export function PortalUserToJSON(value?: PortalUser | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    active: value.active,
    phoneNumber: value.phoneNumber,
    userName: value.userName,
    userInfo: SimplePortalUserToJSON(value.userInfo),
    roles: value.roles,
    claims: value.claims,
    sterInlog: SterInlogUserToJSON(value.sterInlog),
    userType: value.userType,
    ipAddress: value.ipAddress,
  };
}
