import { Trans } from "@lingui/macro";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ReduxStoreState } from "../../../store/base";
import { getAnalysisNmoNotificationAction } from "../../../store/campaignAnalysis/actions";
import { StoreModel } from "../../../store/models";
import { getFormattedDate } from "../../../utils/dateHelper";
import { ClosableAlert, ClosableAlertBaseProps } from "../../Notification";

interface CampaignAnalysisNmoNotificationProps {
  nmoSwitchDate: Date;
}

const CampaignAnalysisNmoNotification = ({
  nmoSwitchDate,
  currentlyClosed,
  onClose,
}: CampaignAnalysisNmoNotificationProps & ClosableAlertBaseProps) => {
  const dispatch = useDispatch();

  const hasNmoNotification = useSelector(
    (state: StoreModel) => state.campaignAnalysisNmoNotification
  );

  useEffect(() => {
    if (hasNmoNotification.state === ReduxStoreState.Initial) {
      dispatch(getAnalysisNmoNotificationAction.request());
    }
  }, [dispatch, hasNmoNotification.state]);

  if (!hasNmoNotification.result) {
    return null;
  }

  return (
    <ClosableAlert
      id="campaign-analysis-nmo-notification"
      message={
        <Trans>
          Vanaf {getFormattedDate(nmoSwitchDate, "PPP")} begint het nieuwe NMO
          Kijkonderzoek. Dit kan gevolgen hebben voor de analyse(s) van jouw
          televisiecampagnes - mogelijk ontvang je er twee in plaats van één.
          Meer weten? Neem dan contact op met je accountmanager of één van onze
          planners via de chat.
        </Trans>
      }
      currentlyClosed={currentlyClosed}
      onClose={onClose}
    />
  );
};

export default CampaignAnalysisNmoNotification;
