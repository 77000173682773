import { createAsyncAction } from "typesafe-actions";

import { TermsAndConditionsResult } from "../../api/models";
import { ResponseError } from "../base";
import { actionNameAsync } from "../utils";
import { TermsPayload } from "./models";

export const receiveTermsAction = createAsyncAction(
  actionNameAsync("terms", "REQUEST"),
  actionNameAsync("terms", "SUCCESS"),
  actionNameAsync("terms", "FAILURE")
)<undefined, TermsAndConditionsResult, ResponseError>();

export const postTermsAction = createAsyncAction(
  actionNameAsync("termsPost", "REQUEST"),
  actionNameAsync("termsPost", "SUCCESS"),
  actionNameAsync("termsPost", "FAILURE")
)<TermsPayload, TermsAndConditionsResult, ResponseError>();
