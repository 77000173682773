import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

import { SettingsApi } from "../../api";
import apiConfig from "../../apiConfig";
import { toError } from "../utils";
import {
  getEditableSettingsAction,
  updateEditableSettingAction,
} from "./actions";

export function* getEditableSettings(): SagaIterator {
  try {
    const settingsApi = new SettingsApi(apiConfig());
    const response = yield call(() => settingsApi.apiV1SettingsEditableGet());
    yield put(getEditableSettingsAction.success(response));
  } catch (err) {
    yield put(getEditableSettingsAction.failure(toError(err)));
  }
}

export function* updateEditableSetting(
  action: ReturnType<typeof updateEditableSettingAction.request>
): SagaIterator {
  try {
    const settingsApi = new SettingsApi(apiConfig());
    const response = yield call(() =>
      settingsApi.apiV1SettingsPut({
        editableSetting: action.payload.setting,
      })
    );

    yield put(updateEditableSettingAction.success(response));

    if (action.payload.onSuccess) {
      yield call(action.payload.onSuccess, response);
    }
  } catch (err) {
    yield put(updateEditableSettingAction.failure(toError(err)));
  }
}
