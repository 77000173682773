import "./priceindextooltip.less";

import { Trans } from "@lingui/macro";
import {
  Channel,
  ChannelNames,
  Icons,
  formatNumber,
  formatToEuro,
} from "@ster/ster-toolkit";
import { Popover } from "antd";
import { format } from "date-fns";
import moment from "moment";
import { Fragment, useCallback, useMemo } from "react";

import {
  DateSpan,
  MediumEnum,
  Package,
  RuleType,
  TargetGroup,
} from "../../../../api";
import { UniqueNumbers, UniqueStrings } from "../../../../utils";
import { RadioShortChannelNames } from "../../Channel/Channel";

export interface PriceIndexTooltipProps {
  size: "small" | "default";
  pckg: Package;
  targetGroup: TargetGroup;
  spotLengthIndex: number;
  period: DateSpan;
  medium: MediumEnum;
}

const PriceIndexTooltip = ({
  size,
  pckg,
  targetGroup,
  spotLengthIndex,
  period,
  medium,
}: PriceIndexTooltipProps) => {
  const getMonthNumbers = useCallback(() => {
    const dateStart = moment(period.from);
    const dateEnd = moment(period.to);
    const interim = dateStart.clone();
    const monthNumbers = [];

    while (dateEnd > interim || interim.month() === dateEnd.month()) {
      monthNumbers.push(interim.month() + 1);
      interim.add(1, "month");
    }

    return monthNumbers;
  }, [period]);

  const totalPrice = useMemo(
    () =>
      UniqueStrings(
        getMonthNumbers().map((m) =>
          formatToEuro(
            ((targetGroup.outputPriceWithoutSpotLengthIndex.find(
              (o) => o.month === m
            )?.outputPrice ?? 0) *
              spotLengthIndex) /
              100,
            false
          )
        )
      ).join(" / "),
    [getMonthNumbers, spotLengthIndex, targetGroup]
  );

  const monthIndexes = useMemo(
    () =>
      UniqueNumbers(
        getMonthNumbers().map(
          (m) =>
            targetGroup.outputPriceWithoutSpotLengthIndex.find(
              (o) => o.month === m
            )?.monthIndex ?? 100
        )
      ),
    [getMonthNumbers, targetGroup]
  );

  const marketIndexes = useMemo(
    () =>
      UniqueNumbers(
        getMonthNumbers().map(
          (m) =>
            targetGroup.outputPriceWithoutSpotLengthIndex.find(
              (o) => o.month === m
            )?.marketIndex ?? 100
        )
      ),
    [getMonthNumbers, targetGroup]
  );

  const marketIndexesRadio = useMemo(
    () =>
      getMonthNumbers().map((m) =>
        targetGroup.outputPriceWithoutSpotLengthIndex
          .find((o) => o.month === m)
          ?.marketIndexRadio?.filter((s) =>
            s.channelIds.some((c) => targetGroup.channels?.some((t) => t === c))
          )
      ),
    [getMonthNumbers, targetGroup]
  );

  const discountPercs = useMemo(
    () =>
      UniqueNumbers(
        getMonthNumbers().map(
          (m) =>
            targetGroup.outputPriceWithoutSpotLengthIndex.find(
              (o) => o.month === m
            )?.discountPercWithMarketIndex ?? 0
        )
      ),
    [getMonthNumbers, targetGroup]
  );

  const showDiscounts = pckg.discountSurtaxes?.length !== 0;
  const showDynMarketIdx =
    medium !== MediumEnum.Radio && marketIndexes.some((m) => m !== 100);
  const showDynMarketIdxRadio =
    medium === MediumEnum.Radio &&
    marketIndexesRadio.filter((m) => m?.some((x) => x.index !== 100)).length !==
      0;

  const TargetGroupYearPriceComponent = useCallback(
    () => (
      <li className="price-index__list-item">
        <span>
          <Trans>Jaarprijs doelgroep</Trans>
        </span>
        <span className="price-index__price">
          {formatToEuro(targetGroup.targetGroupIndex)}
        </span>
      </li>
    ),
    [targetGroup.targetGroupIndex]
  );

  const YearBaseRateComponent = useCallback(
    () => (
      <li className="price-index__list-item">
        <span>
          <Trans>Netto basis jaarprijs</Trans>
        </span>
        <span className="price-index__price">
          {formatToEuro(pckg.yearBaseRate)}
        </span>
      </li>
    ),
    [pckg.yearBaseRate]
  );

  const PriceIndexContent = useCallback(
    () => (
      <ul className="price-index__list">
        {medium === "radio" ? (
          <TargetGroupYearPriceComponent />
        ) : (
          <YearBaseRateComponent />
        )}

        {showDiscounts &&
          pckg.discountSurtaxes?.map((d) => (
            <li className="price-index__list-item discounts" key={d.code}>
              <span>{d.description}</span>
              <span>
                <span>
                  {`${d.type === RuleType.Discount ? "-" : "+"} ${formatNumber(
                    d.percentage ?? 0,
                    1
                  )}%`}
                </span>
              </span>
            </li>
          ))}

        {showDynMarketIdx && (
          <li className="price-index__list-item">
            <span>
              <Trans>Marktindex</Trans>
            </span>
            <span>
              {marketIndexes.map((m, index) => (
                <Fragment key={JSON.stringify(m)}>
                  {`${m >= 100 ? "+ " : "- "} ${formatNumber(m - 100)}% `}
                  {index + 1 < marketIndexes.length ? " / " : ""}
                </Fragment>
              ))}
            </span>
          </li>
        )}

        {showDynMarketIdxRadio && (
          <>
            <li className="price-index__list-item">
              <span>
                <Trans>Marktindex</Trans>
              </span>
            </li>
            {marketIndexesRadio.map((m) =>
              m?.map((x) => (
                <li className="price-index__list-item" key={JSON.stringify(x)}>
                  <span>
                    - {format(x.start, "LLLL")}{" "}
                    {x.channelIds.map((channel) => (
                      <Channel
                        type={
                          RadioShortChannelNames.find(
                            ({ key }) => key === channel
                          )?.value as ChannelNames
                        }
                        key={channel}
                      />
                    ))}
                  </span>
                  <span>{`${x.index >= 100 ? "+ " : "- "} ${formatNumber(
                    x.index - 100
                  )}% `}</span>
                </li>
              ))
            )}
          </>
        )}

        {(showDiscounts || showDynMarketIdx || showDynMarketIdxRadio) &&
          discountPercs.length !== 0 && (
            <li className="price-index__list-item discounts sum">
              <span>
                <Trans>Totaal kortingen/toeslagen</Trans>
              </span>
              <span>
                {discountPercs.map((m, index) => (
                  <Fragment key={JSON.stringify(m)}>
                    {m !== 0 && m < 0 && "+"}
                    <span>{` ${formatNumber(m * -1, 1)}%`}</span>
                    {/* Toon het totaal van de kortingen als een toeslag. - 10% korting -> + 10% toeslag */}
                    {index + 1 < discountPercs.length ? " / " : ""}
                  </Fragment>
                ))}
              </span>
            </li>
          )}

        {medium !== MediumEnum.Radio && (
          <li className="price-index__list-item">
            <span>
              <Trans>Doelgroepindex</Trans>
            </span>
            <span>
              x
              <span className="price-index__index">
                {formatNumber(targetGroup.targetGroupIndex)}
              </span>
            </span>
          </li>
        )}
        <li className="price-index__list-item">
          <span>
            <Trans>Maandindex</Trans>
          </span>
          <span>
            x
            {monthIndexes.map((m, index) => (
              <Fragment key={m}>
                <span className="price-index__index">{m}</span>{" "}
                {index + 1 < monthIndexes.length ? " /" : ""}
              </Fragment>
            ))}
          </span>
        </li>
        <li className="price-index__list-item">
          <span>
            <Trans>Pakketindex</Trans>
          </span>
          <span>
            x<span className="price-index__index">{pckg.productIndex}</span>
          </span>
        </li>
        <li className="price-index__list-item">
          <span>
            <Trans>Spotlengte-index</Trans>
          </span>
          <span>
            x<span className="price-index__index">{spotLengthIndex}</span>
          </span>
        </li>
        <li className="price-index__list-item sum">
          <span>
            <Trans>Outputprijs</Trans>
          </span>
          <span>
            <span className="price-index__price">{totalPrice}</span>
          </span>
        </li>
      </ul>
    ),
    [
      TargetGroupYearPriceComponent,
      YearBaseRateComponent,
      discountPercs,
      marketIndexes,
      marketIndexesRadio,
      medium,
      monthIndexes,
      pckg.discountSurtaxes,
      pckg.productIndex,
      showDiscounts,
      showDynMarketIdx,
      showDynMarketIdxRadio,
      spotLengthIndex,
      targetGroup.targetGroupIndex,
      totalPrice,
    ]
  );

  return (
    <div className="price-index-tooltip">
      {size === "small" ? (
        <span>{totalPrice}</span>
      ) : (
        <span className="price-index-tooltip__price--big">{totalPrice}</span>
      )}
      <Popover
        placement="bottom"
        arrow={{ pointAtCenter: true }}
        content={<PriceIndexContent />}
      >
        <span className="price-index-tooltip__icon-wrapper">
          {size === "small" ? (
            <Icons.QuestionIcon width={16} height={16} />
          ) : (
            <Icons.QuestionIcon width={24} height={24} />
          )}
        </span>
      </Popover>
    </div>
  );
};

export default PriceIndexTooltip;
