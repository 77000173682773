import "./app.less";

import { Trans } from "@lingui/macro";
import { Row } from "@ster/ster-toolkit";
import { Alert, Space } from "antd";
import Cookies from "js-cookie";
import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";

import { notificationSelector } from "../shared/selectors";
import { ReduxStoreState } from "../store/base";
import { defaultCookieOptions, getLanguage } from "../utils";
import { announcementsClosedCookieName } from "../utils/constants";
import sharedStyles from "./Shared.module.less";

export interface ClosableAlertBaseProps {
  currentlyClosed: (number | string)[];
  onClose: (id: number | string) => void;
}

export interface ClosableAlertProps extends ClosableAlertBaseProps {
  id: number | string;
  markdown?: string;
  message?: ReactNode;
}

export const ClosableAlert = ({
  id,
  markdown,
  message,
  currentlyClosed,
  onClose,
}: ClosableAlertProps) => {
  const handleClose = useCallback(() => {
    onClose(id);
  }, [id, onClose]);

  if (currentlyClosed.includes(id)) {
    return null;
  }

  return (
    <Row gutter={[0, 48]} key={id}>
      <Alert
        showIcon
        type="warning"
        style={{ width: "100%" }}
        message={<Trans>Mededeling</Trans>}
        description={
          markdown ? <ReactMarkdown>{markdown}</ReactMarkdown> : message
        }
        closable={onclose !== undefined}
        onClose={handleClose}
      />
    </Row>
  );
};

const Notification = ({
  renderBefore,
}: {
  renderBefore?: (props: ClosableAlertBaseProps) => ReactElement | null;
}) => {
  const [currentlyClosed, setCurrentlyClosed] = useState<(number | string)[]>(
    []
  );

  useEffect(() => {
    const cookie = Cookies.get(announcementsClosedCookieName);
    if (cookie) {
      setCurrentlyClosed(JSON.parse(cookie));
    }
  }, []);

  const handleClose = useCallback(
    (id: string | number) => {
      const newClosed = [...currentlyClosed, id];
      setCurrentlyClosed(newClosed);

      // Save value to cookie
      Cookies.set(
        announcementsClosedCookieName,
        JSON.stringify(newClosed),
        defaultCookieOptions
      );
    },
    [currentlyClosed]
  );

  const { account, announcements } = useSelector(notificationSelector);

  const accountReady = useMemo(
    () => account.state !== ReduxStoreState.Loading,
    [account.state]
  );
  const loggedIn = useMemo(
    () => Boolean(account?.email && account.active) && accountReady,
    [account, accountReady]
  );

  if (!loggedIn || !announcements) {
    return null;
  }

  return (
    <Space direction="vertical" className={sharedStyles.fullWidth}>
      {renderBefore?.({ currentlyClosed, onClose: handleClose })}
      {announcements
        .filter((a) => !currentlyClosed.includes(a.id!))
        .map((a) => (
          <ClosableAlert
            id={a.id!}
            key={a.id!}
            markdown={
              getLanguage() === "nl" ? a.message : a.messageInt ?? a.message
            }
            currentlyClosed={currentlyClosed}
            onClose={handleClose}
          />
        ))}
    </Space>
  );
};

export default Notification;
