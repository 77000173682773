/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  AnalysisAvailability,
  AnalysisAvailabilityFromJSON,
  AnalysisAvailabilityFromJSONTyped,
  AnalysisAvailabilityToJSON,
  AuditlogModel,
  AuditlogModelFromJSON,
  AuditlogModelFromJSONTyped,
  AuditlogModelToJSON,
  MediumEnum,
  MediumEnumFromJSON,
  MediumEnumFromJSONTyped,
  MediumEnumToJSON,
  OrderStatus,
  OrderStatusFromJSON,
  OrderStatusFromJSONTyped,
  OrderStatusToJSON,
  RunningState,
  RunningStateFromJSON,
  RunningStateFromJSONTyped,
  RunningStateToJSON,
  SubOrderListItem,
  SubOrderListItemFromJSON,
  SubOrderListItemFromJSONTyped,
  SubOrderListItemToJSON,
} from "./";

/**
 *
 * @export
 * @interface OrderListItem
 */
export interface OrderListItem {
  /**
   *
   * @type {string}
   * @memberof OrderListItem
   */
  name?: string | null;
  /**
   *
   * @type {number}
   * @memberof OrderListItem
   */
  id: number;
  /**
   *
   * @type {Date}
   * @memberof OrderListItem
   */
  orderStartDate: Date;
  /**
   *
   * @type {Date}
   * @memberof OrderListItem
   */
  orderEndDate: Date;
  /**
   *
   * @type {Date}
   * @memberof OrderListItem
   */
  startDate: Date;
  /**
   *
   * @type {Date}
   * @memberof OrderListItem
   */
  endDate: Date;
  /**
   *
   * @type {number}
   * @memberof OrderListItem
   */
  budget: number;
  /**
   *
   * @type {string}
   * @memberof OrderListItem
   */
  productDescr: string;
  /**
   *
   * @type {number}
   * @memberof OrderListItem
   */
  productId: number;
  /**
   *
   * @type {string}
   * @memberof OrderListItem
   */
  advertiserName: string;
  /**
   *
   * @type {number}
   * @memberof OrderListItem
   */
  advertiserId: number;
  /**
   *
   * @type {MediumEnum}
   * @memberof OrderListItem
   */
  medium: MediumEnum;
  /**
   *
   * @type {number}
   * @memberof OrderListItem
   */
  predictedBudget?: number;
  /**
   *
   * @type {number}
   * @memberof OrderListItem
   */
  bookedBudget?: number;
  /**
   *
   * @type {boolean}
   * @memberof OrderListItem
   */
  spreadedSecondPart: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OrderListItem
   */
  spreadedFirstPart: boolean;
  /**
   *
   * @type {number}
   * @memberof OrderListItem
   */
  spreadedOrderId?: number | null;
  /**
   *
   * @type {OrderStatus}
   * @memberof OrderListItem
   */
  status: OrderStatus;
  /**
   *
   * @type {RunningState}
   * @memberof OrderListItem
   */
  runningState: RunningState;
  /**
   *
   * @type {boolean}
   * @memberof OrderListItem
   */
  needsInstructions?: boolean;
  /**
   *
   * @type {Array<AuditlogModel>}
   * @memberof OrderListItem
   */
  auditLogs: Array<AuditlogModel>;
  /**
   *
   * @type {Array<string>}
   * @memberof OrderListItem
   */
  packageCodes?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof OrderListItem
   */
  contactPersonAgencyEmail?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof OrderListItem
   */
  isProspect?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OrderListItem
   */
  hasRequestedGrps?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OrderListItem
   */
  hasBookedGrps?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OrderListItem
   */
  hasRequestedImpressions?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OrderListItem
   */
  hasBookedImpressions?: boolean;
  /**
   *
   * @type {string}
   * @memberof OrderListItem
   */
  prospectId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof OrderListItem
   */
  disableDetails?: boolean;
  /**
   *
   * @type {Array<SubOrderListItem>}
   * @memberof OrderListItem
   */
  subOrders: Array<SubOrderListItem>;
  /**
   *
   * @type {boolean}
   * @memberof OrderListItem
   */
  isEditable?: boolean;
  /**
   *
   * @type {AnalysisAvailability}
   * @memberof OrderListItem
   */
  analysis: AnalysisAvailability;
}

export function OrderListItemFromJSON(json: any): OrderListItem {
  return OrderListItemFromJSONTyped(json, false);
}

export function OrderListItemFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OrderListItem {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, "name") ? undefined : json["name"],
    id: json["id"],
    orderStartDate: new Date(json["orderStartDate"]),
    orderEndDate: new Date(json["orderEndDate"]),
    startDate: new Date(json["startDate"]),
    endDate: new Date(json["endDate"]),
    budget: json["budget"],
    productDescr: json["productDescr"],
    productId: json["productId"],
    advertiserName: json["advertiserName"],
    advertiserId: json["advertiserId"],
    medium: MediumEnumFromJSON(json["medium"]),
    predictedBudget: !exists(json, "predictedBudget")
      ? undefined
      : json["predictedBudget"],
    bookedBudget: !exists(json, "bookedBudget")
      ? undefined
      : json["bookedBudget"],
    spreadedSecondPart: json["spreadedSecondPart"],
    spreadedFirstPart: json["spreadedFirstPart"],
    spreadedOrderId: !exists(json, "spreadedOrderId")
      ? undefined
      : json["spreadedOrderId"],
    status: OrderStatusFromJSON(json["status"]),
    runningState: RunningStateFromJSON(json["runningState"]),
    needsInstructions: !exists(json, "needsInstructions")
      ? undefined
      : json["needsInstructions"],
    auditLogs: (json["auditLogs"] as Array<any>).map(AuditlogModelFromJSON),
    packageCodes: !exists(json, "packageCodes")
      ? undefined
      : json["packageCodes"],
    contactPersonAgencyEmail: !exists(json, "contactPersonAgencyEmail")
      ? undefined
      : json["contactPersonAgencyEmail"],
    isProspect: !exists(json, "isProspect") ? undefined : json["isProspect"],
    hasRequestedGrps: !exists(json, "hasRequestedGrps")
      ? undefined
      : json["hasRequestedGrps"],
    hasBookedGrps: !exists(json, "hasBookedGrps")
      ? undefined
      : json["hasBookedGrps"],
    hasRequestedImpressions: !exists(json, "hasRequestedImpressions")
      ? undefined
      : json["hasRequestedImpressions"],
    hasBookedImpressions: !exists(json, "hasBookedImpressions")
      ? undefined
      : json["hasBookedImpressions"],
    prospectId: !exists(json, "prospectId") ? undefined : json["prospectId"],
    disableDetails: !exists(json, "disableDetails")
      ? undefined
      : json["disableDetails"],
    subOrders: (json["subOrders"] as Array<any>).map(SubOrderListItemFromJSON),
    isEditable: !exists(json, "isEditable") ? undefined : json["isEditable"],
    analysis: AnalysisAvailabilityFromJSON(json["analysis"]),
  };
}

export function OrderListItemToJSON(value?: OrderListItem | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    id: value.id,
    orderStartDate: value.orderStartDate.toISOString(),
    orderEndDate: value.orderEndDate.toISOString(),
    startDate: value.startDate.toISOString(),
    endDate: value.endDate.toISOString(),
    budget: value.budget,
    productDescr: value.productDescr,
    productId: value.productId,
    advertiserName: value.advertiserName,
    advertiserId: value.advertiserId,
    medium: MediumEnumToJSON(value.medium),
    predictedBudget: value.predictedBudget,
    bookedBudget: value.bookedBudget,
    spreadedSecondPart: value.spreadedSecondPart,
    spreadedFirstPart: value.spreadedFirstPart,
    spreadedOrderId: value.spreadedOrderId,
    status: OrderStatusToJSON(value.status),
    runningState: RunningStateToJSON(value.runningState),
    needsInstructions: value.needsInstructions,
    auditLogs: (value.auditLogs as Array<any>).map(AuditlogModelToJSON),
    packageCodes: value.packageCodes,
    contactPersonAgencyEmail: value.contactPersonAgencyEmail,
    isProspect: value.isProspect,
    hasRequestedGrps: value.hasRequestedGrps,
    hasBookedGrps: value.hasBookedGrps,
    hasRequestedImpressions: value.hasRequestedImpressions,
    hasBookedImpressions: value.hasBookedImpressions,
    prospectId: value.prospectId,
    disableDetails: value.disableDetails,
    subOrders: (value.subOrders as Array<any>).map(SubOrderListItemToJSON),
    isEditable: value.isEditable,
    analysis: AnalysisAvailabilityToJSON(value.analysis),
  };
}
