/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  MediumEnum,
  MediumEnumFromJSON,
  MediumEnumToJSON,
  ProblemDetails,
  ProblemDetailsFromJSON,
  ProblemDetailsToJSON,
  SpotResultOutput,
  SpotResultOutputFromJSON,
  SpotResultOutputToJSON,
  SpotTrackerRequestInput,
  SpotTrackerRequestInputFromJSON,
  SpotTrackerRequestInputToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface ApiV1SpotTrackerExportPostRequest {
  spotTrackerRequestInput: SpotTrackerRequestInput;
  medium: MediumEnum;
  selectedColumns: Array<string>;
}

export interface ApiV1SpotTrackerPostRequest {
  spotTrackerRequestInput: SpotTrackerRequestInput;
  medium: MediumEnum;
}

/**
 *
 */
export class SpotTrackerApi extends runtime.BaseAPI {
  /**
   */
  async apiV1SpotTrackerExportPostRaw(
    requestParameters: ApiV1SpotTrackerExportPostRequest
  ): Promise<runtime.ApiResponse<Blob>> {
    if (
      requestParameters.spotTrackerRequestInput === null ||
      requestParameters.spotTrackerRequestInput === undefined
    ) {
      throw new runtime.RequiredError(
        "spotTrackerRequestInput",
        "Required parameter requestParameters.spotTrackerRequestInput was null or undefined when calling apiV1SpotTrackerExportPost."
      );
    }

    if (
      requestParameters.medium === null ||
      requestParameters.medium === undefined
    ) {
      throw new runtime.RequiredError(
        "medium",
        "Required parameter requestParameters.medium was null or undefined when calling apiV1SpotTrackerExportPost."
      );
    }

    if (
      requestParameters.selectedColumns === null ||
      requestParameters.selectedColumns === undefined
    ) {
      throw new runtime.RequiredError(
        "selectedColumns",
        "Required parameter requestParameters.selectedColumns was null or undefined when calling apiV1SpotTrackerExportPost."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.medium !== undefined) {
      queryParameters["medium"] = requestParameters.medium;
    }

    if (requestParameters.selectedColumns) {
      queryParameters["selectedColumns"] = requestParameters.selectedColumns;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/SpotTracker/export`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: SpotTrackerRequestInputToJSON(
        requestParameters.spotTrackerRequestInput
      ),
    });

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async apiV1SpotTrackerExportPost(
    requestParameters: ApiV1SpotTrackerExportPostRequest
  ): Promise<Blob> {
    const response =
      await this.apiV1SpotTrackerExportPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1SpotTrackerPostRaw(
    requestParameters: ApiV1SpotTrackerPostRequest
  ): Promise<runtime.ApiResponse<Array<SpotResultOutput>>> {
    if (
      requestParameters.spotTrackerRequestInput === null ||
      requestParameters.spotTrackerRequestInput === undefined
    ) {
      throw new runtime.RequiredError(
        "spotTrackerRequestInput",
        "Required parameter requestParameters.spotTrackerRequestInput was null or undefined when calling apiV1SpotTrackerPost."
      );
    }

    if (
      requestParameters.medium === null ||
      requestParameters.medium === undefined
    ) {
      throw new runtime.RequiredError(
        "medium",
        "Required parameter requestParameters.medium was null or undefined when calling apiV1SpotTrackerPost."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.medium !== undefined) {
      queryParameters["medium"] = requestParameters.medium;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/SpotTracker`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: SpotTrackerRequestInputToJSON(
        requestParameters.spotTrackerRequestInput
      ),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(SpotResultOutputFromJSON)
    );
  }

  /**
   */
  async apiV1SpotTrackerPost(
    requestParameters: ApiV1SpotTrackerPostRequest
  ): Promise<Array<SpotResultOutput>> {
    const response = await this.apiV1SpotTrackerPostRaw(requestParameters);
    return await response.value();
  }
}
