import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

import { CampaignApi, TipsApi } from "../../api";
import apiConfig from "../../apiConfig";
import { toError } from "../utils";
import { receiveCurrentSpotsAction, receiveTipsAction } from "./actions";

export function* receiveCurrentSpots(): SagaIterator {
  try {
    const campaignApi = new CampaignApi(apiConfig());
    const response = yield call(() => campaignApi.apiV1CampaignGetspotsGet());
    yield put(receiveCurrentSpotsAction.success(response));
  } catch (err) {
    yield put(receiveCurrentSpotsAction.failure(toError(err)));
  }
}

export function* receiveTips(): SagaIterator {
  try {
    const tipsApi = new TipsApi(apiConfig());
    const response = yield call(() => tipsApi.apiV1TipsGet());
    yield put(receiveTipsAction.success(response));
  } catch (err) {
    yield put(receiveTipsAction.failure(toError(err)));
  }
}
