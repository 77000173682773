import "./slide.less";

export interface CarouselItem {
  id?: number | string;
  time?: string | null | undefined;
  name?: string | null | undefined;
  title?: string | null | undefined;
  subTitle?: string;
  type: "before" | "blockspot" | "after";
}

export const Slide = (b: CarouselItem) => (
  <div>
    <div className={`slide slide--${b.type}`} key={b.id}>
      <span className="slide__time">{b.time}</span>
      {b.type === "blockspot" ? (
        <div className="slide__content">
          <span className="slide__title">{b.title}</span>
          <div className="slide__name">{b.name}</div>
          <div className="slide__subtitle">{b.subTitle}</div>
        </div>
      ) : (
        <span className="slide__title">{b.title}</span>
      )}
    </div>
  </div>
);
