/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface RatingRegio
 */
export interface RatingRegio {
  /**
   *
   * @type {string}
   * @memberof RatingRegio
   */
  intomartTargetGroupId: string;
  /**
   *
   * @type {string}
   * @memberof RatingRegio
   */
  targetGroupId: string;
  /**
   *
   * @type {string}
   * @memberof RatingRegio
   */
  channelNr: string;
  /**
   *
   * @type {string}
   * @memberof RatingRegio
   */
  channelDescr: string;
  /**
   *
   * @type {Date}
   * @memberof RatingRegio
   */
  actualStartTime: Date;
  /**
   *
   * @type {number}
   * @memberof RatingRegio
   */
  achievedRating: number;
}

export function RatingRegioFromJSON(json: any): RatingRegio {
  return RatingRegioFromJSONTyped(json, false);
}

export function RatingRegioFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RatingRegio {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    intomartTargetGroupId: json["intomartTargetGroupId"],
    targetGroupId: json["targetGroupId"],
    channelNr: json["channelNr"],
    channelDescr: json["channelDescr"],
    actualStartTime: new Date(json["actualStartTime"]),
    achievedRating: json["achievedRating"],
  };
}

export function RatingRegioToJSON(value?: RatingRegio | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    intomartTargetGroupId: value.intomartTargetGroupId,
    targetGroupId: value.targetGroupId,
    channelNr: value.channelNr,
    channelDescr: value.channelDescr,
    actualStartTime: value.actualStartTime.toISOString(),
    achievedRating: value.achievedRating,
  };
}
