import { Configuration, ConfigurationParameters } from "./api";
import { getToken, isAccessTokenValid, logout } from "./utils";

const configParams: ConfigurationParameters = {
  basePath: "",
};

const config = (apiKey: string | undefined = undefined): Configuration => {
  const token = apiKey ?? getToken();
  if (!token || !isAccessTokenValid(token)) {
    logout();
  }

  const bearer = `Bearer ${token}`;
  return new Configuration({ ...configParams, apiKey: bearer });
};

export default config;
