import "./priceindextooltip.less";

import { Trans } from "@lingui/macro";
import { Icons, formatNumber, formatToEuro } from "@ster/ster-toolkit";
import { Popover } from "antd";
import moment from "moment";
import { Fragment, useCallback, useMemo } from "react";

import { DateSpan, Package, RuleType } from "../../../../api";
import { UniqueNumbers, UniqueStrings } from "../../../../utils";

export interface PriceIndexTooltipOnlineProps {
  size: "small" | "default";
  pckg: Package;
  format?: string | null;
  period: DateSpan;
}

const PriceIndexTooltipOnline = ({
  size,
  pckg,
  format,
  period,
}: PriceIndexTooltipOnlineProps) => {
  const getMonthNumbers = useCallback(() => {
    const dateStart = moment(period.from);
    const dateEnd = moment(period.to);
    const interim = dateStart.clone();
    const monthNumbers = [];

    while (dateEnd > interim || interim.month() === dateEnd.month()) {
      monthNumbers.push(interim.month() + 1);
      interim.add(1, "month");
    }

    return monthNumbers;
  }, [period]);

  const internetFormat = useMemo(
    () => pckg.internetFormat?.find((s) => s.id === format),
    [format, pckg.internetFormat]
  );

  const totalPrice = useMemo(
    () =>
      UniqueStrings(
        getMonthNumbers().map((m) =>
          formatToEuro(
            internetFormat?.outputPrice?.find((o) => o.month === m)
              ?.outputPrice ?? 0
          )
        )
      ).join(" / "),
    [getMonthNumbers, internetFormat]
  );

  const monthIndexes = useMemo(
    () =>
      UniqueNumbers(
        getMonthNumbers().map(
          (m) =>
            internetFormat?.outputPrice?.find((o) => o.month === m)
              ?.monthIndex ?? 100
        )
      ),
    [getMonthNumbers, internetFormat]
  );

  const PriceIndexContent = useCallback(
    () => (
      <ul className="price-index__list">
        <li className="price-index__list-item">
          <span>
            <Trans>CPM-prijs</Trans>
          </span>
          <span className="price-index__price">
            {formatToEuro(internetFormat?.cpmPrice ?? 0)}
          </span>
        </li>
        {pckg.discountPerc !== 0 && (
          <>
            {pckg.discountSurtaxes?.map((d) => (
              <li className="price-index__list-item discounts" key={d.code}>
                <span>{d.description}</span>
                <span>
                  <span>
                    {`${
                      d.type === RuleType.Discount ? "-" : "+"
                    } ${formatNumber(d.percentage ?? 0)}%`}
                  </span>
                </span>
              </li>
            ))}
            <li className="price-index__list-item discounts sum">
              <span>
                <Trans>Totaal kortingen/toeslagen</Trans>
              </span>
              <span>
                {pckg.discountPerc > 0 ? "-" : "+"}
                <span>{` ${formatNumber(pckg.discountPerc, 0)}%`}</span>
              </span>
            </li>
          </>
        )}
        <li className="price-index__list-item">
          <span>
            <Trans>Maandindex</Trans>
          </span>
          <span>
            x
            {monthIndexes.map((m, index) => (
              <Fragment key={m}>
                <span className="price-index__index">{m}</span>{" "}
                {index + 1 < monthIndexes.length ? " /" : ""}
              </Fragment>
            ))}
          </span>
        </li>
        <li className="price-index__list-item sum">
          <span>
            <Trans>Outputprijs</Trans>
          </span>
          <span>
            <span className="price-index__price">{totalPrice}</span>
          </span>
        </li>
      </ul>
    ),
    [
      internetFormat?.cpmPrice,
      monthIndexes,
      pckg.discountPerc,
      pckg.discountSurtaxes,
      totalPrice,
    ]
  );

  return (
    <div className="price-index-tooltip">
      {size === "small" ? (
        <span>{totalPrice}</span>
      ) : (
        <span className="price-index-tooltip__price--big">{totalPrice}</span>
      )}
      <Popover
        placement="bottom"
        arrow={{ pointAtCenter: true }}
        content={<PriceIndexContent />}
      >
        <span className="price-index-tooltip__icon-wrapper">
          {size === "small" ? (
            <Icons.QuestionIcon width={16} height={16} />
          ) : (
            <Icons.QuestionIcon width={24} height={24} />
          )}
        </span>
      </Popover>
    </div>
  );
};

export default PriceIndexTooltipOnline;
