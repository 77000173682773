import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Modal } from "@ster/ster-toolkit";
import { Alert, App as AntApp, Typography } from "antd";
import { isSameDay } from "date-fns";
import moment from "moment";
import {
  ReactElement,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  CommercialInstructionAlternationType,
  CommercialInstructionRequest,
  CommercialInstructionStatus,
  CommercialsPerAdvertiser,
  MediumEnum,
} from "../../../api";
import { ReduxStoreState } from "../../../store/base";
import {
  clearSaveCampaignInstructionsAction,
  saveCampaignInstructionsAction,
} from "../../../store/campaignDetail/actions";
import { StoreModel } from "../../../store/models";
import { InstructionConstants } from "../../../utils";
import {
  datesToDateRanges,
  getDaysInsidePeriod,
  mergeDateRange,
} from "../../../utils/dateHelper";
import { DateRange } from "../../../utils/types";
import { InstructionsSelection } from "../OnlineInstructions/InstructionPeriodPicker/types";
import styles from "./InstructionModal.module.less";
import InstructionsForSpotlength from "./InstructionsForSpotlength";
import { SpotLengthWithDates } from "./types";
import {
  SimpleSubOrder,
  emptyCommercialSelection,
  getRotationFrequencyPercentage,
} from "./utils";

interface InstructionModalProps {
  onCancel: () => void;
  onOk: () => void;
  instruction?: CommercialInstructionRequest;
  commercialsPerAdvertiser?: CommercialsPerAdvertiser;
  maxDate: Date;
  medium: MediumEnum;
  productId: number;
  instructionStatus?: CommercialInstructionStatus;
  okText?: ReactNode;
  onSave?: (
    instruction: CommercialInstructionRequest,
    onOkCallback: () => void
  ) => void;
  nextId?: number;
  subOrders: SimpleSubOrder[];
  dateRangesWithoutInstructions: DateRange[];
  header?: false | ReactElement;
}

const InstructionModal = memo(
  ({
    onCancel,
    onOk,
    instruction,
    commercialsPerAdvertiser,
    maxDate,
    medium,
    productId,
    instructionStatus = CommercialInstructionStatus.InBehandeling,
    okText,
    onSave,
    nextId,
    subOrders,
    dateRangesWithoutInstructions,
    header,
  }: InstructionModalProps) => {
    const { i18n } = useLingui();
    const dispatch = useDispatch();
    const { message } = AntApp.useApp();

    const { loadingState } = useSelector(
      ({
        account: { email },
        saveCampaignInstructions: { state },
      }: StoreModel) => ({
        contactPersonAgencyEmail: email,
        loadingState: state,
      })
    );

    const origInstruction: InstructionsSelection = useMemo(
      () =>
        instruction
          ? {
              id: instruction.id ?? -1,
              dateRange: instruction.period
                ? {
                    startDate: instruction.period.from,
                    endDate: instruction.period.to,
                  }
                : undefined,
              channels: instruction.channels ?? [],
              timeRange:
                instruction.startTime && instruction.endTime
                  ? {
                      startTime: instruction.startTime,
                      endTime: instruction.endTime,
                    }
                  : undefined,
            }
          : {
              id: nextId ?? -1,
              dateRange: undefined,
              timeRange: undefined,
            },
      [instruction, nextId]
    );

    const spotLengthsWithDatesFromSubOrders = useMemo(() => {
      // Start met de bestaande instructies
      let collection: SpotLengthWithDates[] =
        instruction?.commercialsPerPeriod.map((s) => ({
          ...s,
          excluded: s.excluded.map((d) => new Date(d)),
          dateRanges: s.dateRanges.map((d) => ({
            startDate: d.from,
            endDate: d.to,
          })),
        })) ?? [];

      const daysWithoutInstruction = dateRangesWithoutInstructions?.flatMap(
        (s) => getDaysInsidePeriod(s.startDate, s.endDate)
      );

      // Groepeer de deelorders per spotlengte
      subOrders.forEach((subOrder) => {
        const existing = collection.findIndex(
          (s) => s.spotLength.join("+") === subOrder.spotLength.join("+")
        );
        const sameSpotLength = collection.filter(
          (s) => s.spotLength.join("+") === subOrder.spotLength.join("+")
        );
        const dateRanges = sameSpotLength.flatMap((s) => s.dateRanges);
        const sortedDates = dateRanges
          ?.flatMap((s) => getDaysInsidePeriod(s.startDate, s.endDate))
          .sort((a, b) => Number(a) - Number(b));
        const datesSubOrder = getDaysInsidePeriod(
          subOrder.dateRange.startDate,
          subOrder.dateRange.endDate
        ).filter((s) => daysWithoutInstruction.some((d) => isSameDay(s, d)));
        const dateRangesSubOrder = datesToDateRanges(datesSubOrder);

        // Bepaal of de deelorder periode afwijkt van de al bestaande periodes
        if (
          datesSubOrder.some((s) => !sortedDates.some((d) => isSameDay(d, s)))
        ) {
          if (existing >= 0) {
            collection[existing].dateRanges = [
              ...collection[existing].dateRanges,
              ...dateRangesSubOrder,
            ];
          } else {
            collection = [
              ...collection,
              {
                id: collection.length
                  ? Math.max(...collection.map((s) => s.id)) + 1
                  : 0,
                spotLength: subOrder.spotLength,
                dateRanges: dateRangesSubOrder,
                excluded: [],
                commercialSelection: emptyCommercialSelection(
                  subOrder.spotLength
                ),
              },
            ];
          }
        }
      });

      return collection.map((item) => ({
        ...item,
        dateRanges: mergeDateRange(item.dateRanges),
      }));
    }, [
      dateRangesWithoutInstructions,
      instruction?.commercialsPerPeriod,
      subOrders,
    ]);

    const [spotLengthsWithDates, setSpotLengthsWithDatesFromSubOrders] =
      useState<SpotLengthWithDates[]>(spotLengthsWithDatesFromSubOrders);

    // Uitgesloten dagen worden weer toegevoegd
    const handleAddDays = useCallback(
      (value: SpotLengthWithDates, index: number) => {
        // zoek alle items met dezelfde spotlengte
        const allSpotLengthIndexes = spotLengthsWithDates.reduce(
          (c: number[], v, i) =>
            v.spotLength.join("+") === value.spotLength.join("+")
              ? c.concat(i)
              : c,
          []
        );

        const newValue = [...spotLengthsWithDates.slice(0, index), value];
        let previousRow = value;
        let lastIndex = index;

        // Haal de uitgesloten dagen weg bij de volgende regel met dezelfde spotlengte
        allSpotLengthIndexes
          .filter((idx) => idx > index)
          .forEach((idx) => {
            if (previousRow.excluded.length > 0) {
              newValue.push({
                ...spotLengthsWithDates[idx],
                excluded: spotLengthsWithDates[idx].excluded.filter((s) =>
                  previousRow.excluded.some((e) => isSameDay(e, s))
                ),
                dateRanges: datesToDateRanges(previousRow.excluded),
              });
            }
            lastIndex = idx;
            previousRow = newValue[newValue.length - 1];
          });
        newValue.push(...spotLengthsWithDates.slice(lastIndex + 1));
        setSpotLengthsWithDatesFromSubOrders(newValue);
      },
      [spotLengthsWithDates]
    );

    const handleExcludeDays = useCallback(
      (value: SpotLengthWithDates, index: number) => {
        // zoek alle items met dezelfde spotlengte
        const allSpotLengthIndexes = spotLengthsWithDates.reduce(
          (c: number[], v, i) =>
            v.spotLength.join("+") === value.spotLength.join("+")
              ? c.concat(i)
              : c,
          []
        );

        // Als het item de laatste van de spotlengtes is maken we een nieuwe regel aan
        if (
          allSpotLengthIndexes.findIndex((s) => s === index) ===
          allSpotLengthIndexes.length - 1
        ) {
          setSpotLengthsWithDatesFromSubOrders([
            ...spotLengthsWithDates.slice(0, index),
            value,
            {
              id: Math.max(...spotLengthsWithDates.map((s) => s.id)) + 1,
              spotLength: value.spotLength,
              excluded: [],
              dateRanges: datesToDateRanges(value.excluded),
              commercialSelection: emptyCommercialSelection(value.spotLength),
            },
            ...spotLengthsWithDates.slice(index + 1),
          ]);
        } else {
          // Voeg de uitgesloten dagen toe aan de volgende regel met dezelfde spotlengte
          const indexToUpdate = spotLengthsWithDates.findIndex(
            (s, idx) =>
              idx > index &&
              s.spotLength.join("+") === value.spotLength.join("+")
          );

          setSpotLengthsWithDatesFromSubOrders([
            ...spotLengthsWithDates.slice(0, index),
            value,
            {
              ...spotLengthsWithDates[indexToUpdate],
              dateRanges: datesToDateRanges(value.excluded),
            },
            ...spotLengthsWithDates.slice(index + 2),
          ]);
        }
      },
      [spotLengthsWithDates]
    );

    const handleUpdateSpotlengthWithDates = useCallback(
      (value: SpotLengthWithDates) => {
        const index = spotLengthsWithDates.findIndex((s) => s.id === value.id);
        if (
          value.excluded.length > spotLengthsWithDates[index].excluded.length
        ) {
          handleExcludeDays(value, index);
        } else if (
          value.excluded.length < spotLengthsWithDates[index].excluded.length
        ) {
          handleAddDays(value, index);
        } else {
          // Andere update
          setSpotLengthsWithDatesFromSubOrders([
            ...spotLengthsWithDates.slice(0, index),
            value,
            ...spotLengthsWithDates.slice(index + 1),
          ]);
        }
      },
      [handleAddDays, handleExcludeDays, spotLengthsWithDates]
    );

    const handleDeleteSpotlengthWithDates = useCallback(
      (value: SpotLengthWithDates) => {
        const index = spotLengthsWithDates.findIndex((s) => s.id === value.id);

        // Voeg de uitgesloten dagen van de verwijdere regel toe aan de regel ervoor
        setSpotLengthsWithDatesFromSubOrders([
          ...spotLengthsWithDates.slice(0, index - 1),
          {
            ...spotLengthsWithDates[index - 1],
            excluded: value.excluded,
          },
          ...spotLengthsWithDates.slice(index + 1),
        ]);
      },
      [spotLengthsWithDates]
    );

    const validFrequency = useMemo(
      () =>
        spotLengthsWithDates.every(
          (s) =>
            s.commercialSelection.every((c) => !c.rotationFrequency) ||
            s.commercialSelection.every((c) => c.rotationFrequency)
        ),
      [spotLengthsWithDates]
    );

    const saveEnabled = useMemo(
      () =>
        validFrequency &&
        spotLengthsWithDates.every(
          (s) =>
            s.commercialSelection.length >= 1 &&
            s.commercialSelection.every(
              (c) =>
                // Controleer of er een hoofdspot is en of er gevulde tagons zijn
                c.commercial &&
                s.spotLength.length ===
                  (c.tagOns?.filter((to) => to?.id).length ?? 0) + 1
            )
        ),
      [spotLengthsWithDates, validFrequency]
    );

    const saveBusy = useMemo(
      () => loadingState === ReduxStoreState.Loading,
      [loadingState]
    );

    /* Gebaseerd op de status, dialoog sluiten of niet? */
    useEffect(() => {
      switch (loadingState) {
        case ReduxStoreState.Success:
          message.success({
            className: "succes",
            content: i18n._(
              t`Je instructie is ingediend en wordt in behandeling genomen.`
            ),
            duration: 10,
          });
          onOk(); // sluiten
          dispatch(clearSaveCampaignInstructionsAction()); // reset
          break;
        case ReduxStoreState.Failure:
          message.error(
            i18n._(t`Er ging iets mis bij het opslaan van je instructie.`)
          );
          dispatch(clearSaveCampaignInstructionsAction()); // reset
          break;
        default:
          break;
      }
    }, [dispatch, i18n, loadingState, message, onOk]);

    const handleOk = useCallback(() => {
      if (!saveEnabled) {
        return;
      }

      const ci: CommercialInstructionRequest = {
        id: origInstruction.id,
        startTime: origInstruction.timeRange?.startTime,
        endTime: origInstruction.timeRange?.endTime,
        medium,
        productId,
        instructionStatus,
        alternationType: CommercialInstructionAlternationType.Percentage,
        commercialsPerPeriod: spotLengthsWithDates.map((s) => ({
          ...s,
          dateRanges: s.dateRanges.map((d) => ({
            from: d.startDate,
            to: d.endDate,
          })),
          commercialSelection: s.commercialSelection.map((c) => ({
            ...c,
            rotationFrequency: getRotationFrequencyPercentage(
              c.rotationFrequency,
              s.commercialSelection
            ),
          })),
        })),
      };

      if (origInstruction.dateRange) {
        ci.period = {
          from: origInstruction.dateRange.startDate,
          to: origInstruction.dateRange.endDate,
        };
      }

      if (ci.commercialsPerPeriod.length === 0) {
        onCancel();
        return;
      }

      if (onSave) {
        onSave(ci, onOk);
      } else {
        // Really save this object
        dispatch(saveCampaignInstructionsAction.request(ci));
      }
    }, [
      dispatch,
      instructionStatus,
      medium,
      onCancel,
      onOk,
      onSave,
      origInstruction.dateRange,
      origInstruction.id,
      origInstruction.timeRange?.endTime,
      origInstruction.timeRange?.startTime,
      productId,
      saveEnabled,
      spotLengthsWithDates,
    ]);

    return (
      <Modal
        open
        onCancel={onCancel}
        cancelText={<Trans>Annuleren</Trans>}
        onOk={handleOk}
        okText={okText ?? <Trans>Indienen</Trans>}
        okButtonProps={{ disabled: !saveEnabled, loading: saveBusy }}
        title={i18n._(
          instruction ? t`Instructie bewerken` : t`Instructie toevoegen`
        )}
        width="100%"
        className={styles.instructionModal}
      >
        {header}
        {moment(maxDate).isAfter(InstructionConstants.now) ? (
          <>
            <InstructionsForSpotlength
              spotLengthsWithDates={spotLengthsWithDates}
              commercialsPerAdvertiser={commercialsPerAdvertiser}
              onChange={handleUpdateSpotlengthWithDates}
              productId={productId}
              medium={medium}
              onDelete={handleDeleteSpotlengthWithDates}
              subOrders={subOrders}
            />

            {!validFrequency && (
              <Alert
                className={styles.warning}
                showIcon
                type="warning"
                message=""
                description={i18n._(
                  t`De verdeling dient voor alle commercialregels ingevuld te worden.`
                )}
              />
            )}
          </>
        ) : (
          <Typography.Paragraph>
            <Trans>
              Het is niet meer mogelijk om uitzendinstructies door te geven.
            </Trans>
          </Typography.Paragraph>
        )}
      </Modal>
    );
  }
);

export default InstructionModal;
