import { createAction, createAsyncAction } from "typesafe-actions";

import {
  ApiV1PlanningDeleteInitialRequestIdPostRequest,
  OrderRequest,
} from "../../api";
import { OrderListItem } from "../../api/models";
import { ResponseError } from "../base";
import { actionNameAsync } from "../utils";
import { CampaignRequestPayload, CopyCampaignPayload } from "./models";

export const receiveCampaignsAction = createAsyncAction(
  actionNameAsync("campaigns", "REQUEST"),
  actionNameAsync("campaigns", "SUCCESS"),
  actionNameAsync("campaigns", "FAILURE")
)<CampaignRequestPayload, OrderListItem[], ResponseError>();

export const clearDeleteCampaignAction = createAction(
  "RECEIVE_DELETECAMPAIGN_CLEAR"
)();

export const deleteCampaignAction = createAsyncAction(
  actionNameAsync("deleteCampaign", "REQUEST"),
  actionNameAsync("deleteCampaign", "SUCCESS"),
  actionNameAsync("deleteCampaign", "FAILURE")
)<ApiV1PlanningDeleteInitialRequestIdPostRequest, undefined, ResponseError>();

export const copyCampaignAction = createAsyncAction(
  actionNameAsync("copyCampaign", "REQUEST"),
  actionNameAsync("copyCampaign", "SUCCESS"),
  actionNameAsync("copyCampaign", "FAILURE")
)<CopyCampaignPayload, OrderRequest, ResponseError>();

export const clearCopyCampaignAction = createAction("COPYCAMPAIGN_CLEAR")();
