import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Checkbox,
  ComponentLocalization,
  Form,
  RangePicker,
} from "@ster/ster-toolkit";
import { StoreValue } from "antd/lib/form/interface";
import { addMonths, endOfMonth, isAfter, startOfDay } from "date-fns";
import { memo, useCallback, useEffect, useMemo, useState } from "react";

import { PackageChoiceEnum } from "../../../../api";
import {
  RangeValueType,
  getLanguage,
  getPopupContainerSubOrders,
} from "../../../../utils";
import { isBetweenDays } from "../../../../utils/dateHelper";
import { PeriodProps } from "./models";
import styles from "./Period.module.less";

const Period = memo(
  ({
    subOrder: { packageChoice, spread, period: subOrderPeriod },
    period,
    selectedSalesPeriod,
  }: PeriodProps) => {
    const { i18n } = useLingui();
    const [dates, setDates] = useState<RangeValueType<Date | undefined>>([
      subOrderPeriod?.from,
      subOrderPeriod?.to,
    ]);
    const [spreadChecked, setSpreadChecked] = useState(spread);

    useEffect(() => {
      setSpreadChecked(spread);
    }, [spread]);

    // Disable spread for fixed costs packages and in the last month of the year (month() is zero indexed)
    const canEnableSpread = useMemo(
      () =>
        packageChoice !== PackageChoiceEnum.FixedCosts &&
        packageChoice !== PackageChoiceEnum.NpoPromo &&
        period.to.getMonth() < 11,
      [packageChoice, period]
    );

    // Bepaling van de niet beschikbare data
    const isDateDisabled = useCallback(
      (currentDate: Date): boolean => {
        let endDate = selectedSalesPeriod.salesPeriodEndDate;
        if (spreadChecked && dates?.[0]) {
          endDate = endOfMonth(addMonths(endDate, 1));
        }

        return !isBetweenDays(
          selectedSalesPeriod.salesPeriodStartDate,
          endDate,
          currentDate
        );
      },
      [
        dates,
        selectedSalesPeriod.salesPeriodEndDate,
        selectedSalesPeriod.salesPeriodStartDate,
        spreadChecked,
      ]
    );

    const getValue = useCallback(
      (value: Date[]): StoreValue => {
        if (isAfter(startOfDay(value?.[0]), startOfDay(period.to))) {
          // De startdatum mag niet na de einddatum van de order liggen. Dit is niet af te dwingen in de RangePicker.
          // Om issues met de order (wel/niet wijzigbaar) te voorkomen zetten we de startdatum terug
          return [period.from, value?.[1]];
        }
        return value;
      },
      [period]
    );

    return (
      <Form.Item
        label={i18n._(t`Periode`)}
        name="period"
        getValueFromEvent={getValue}
        rules={[
          {
            required: true,
            message: i18n._(t`Kies een periode`),
          },
          {
            validator: (_, value): Promise<void> =>
              !spreadChecked &&
              value?.[1] &&
              isAfter(
                startOfDay(value[1]),
                startOfDay(selectedSalesPeriod.salesPeriodEndDate)
              )
                ? // Controleer of de einddatum niet na die van de order ligt terwijl maandoverschrijdend uit staat
                  Promise.reject(
                    new Error(i18n._(t`De gekozen einddatum is ongeldig`))
                  )
                : Promise.resolve(),
          },
        ]}
      >
        <RangePicker
          showWeek
          popupClassName={styles.period}
          disabledDate={isDateDisabled}
          onCalendarChange={setDates}
          defaultPickerValue={[selectedSalesPeriod.salesPeriodStartDate, null!]}
          getPopupContainer={getPopupContainerSubOrders}
          componentLocale={getLanguage() as ComponentLocalization}
          renderExtraFooter={(): React.ReactNode => (
            <>
              {dates?.[0] &&
              isAfter(
                startOfDay(dates[0]),
                startOfDay(selectedSalesPeriod.salesPeriodEndDate)
              ) ? (
                <div className={styles.error}>
                  {i18n._(t`De startdatum mag niet in de overschrijdende maand
              liggen, deze is aangepast.`)}
                </div>
              ) : null}
              {canEnableSpread && (
                <Form.Item
                  valuePropName="checked"
                  name="spread"
                  initialValue={spreadChecked}
                >
                  <Checkbox
                    onChange={(e) => setSpreadChecked(e.target.checked)}
                  >
                    <Trans>Maandoverschrijdend</Trans>
                  </Checkbox>
                </Form.Item>
              )}
            </>
          )}
        />
      </Form.Item>
    );
  }
);

export default Period;
