// Make sure moment.js has the required locale data
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  ComponentLocalization,
  DatePickerExtended,
  Icons,
  Modal,
} from "@ster/ster-toolkit";
import { Alert, Space } from "antd";
import classNames from "classnames";
import moment from "moment";
import { memo, useCallback, useMemo, useState } from "react";

import { getLanguage, removeAndSortDuplicateDates } from "../../../../utils";
import { getExcludedDaysString } from "../../../../utils/dateHelper";
import styles from "./ExcludeDays.module.less";
import { ExcludeDaysProps } from "./models";

const ExcludeDays = memo(
  ({
    onChange,
    value,
    period,
    weekDays,
    daysOfWeek,
    canDisableSeperateDays,
    disabled,
    disabledText,
  }: ExcludeDaysProps) => {
    const { i18n } = useLingui();
    const [showCalendar, setShowCalendar] = useState(false);
    const handleShowCalendar = useCallback(() => {
      setShowCalendar(!showCalendar);
    }, [showCalendar]);

    const handleHideCalendar = useCallback(() => {
      setShowCalendar(false);
    }, []);

    const valueMapped = useMemo(() => value?.map((s) => new Date(s)), [value]);

    const handleChange = useCallback(
      (date: Date) => {
        const mappedDate = new Date(date.toDateString());
        if (onChange) {
          if (valueMapped) {
            const index = valueMapped.findIndex(
              (a) => new Date(a).valueOf() === mappedDate.valueOf()
            );
            if (index !== -1) {
              onChange([
                ...valueMapped.slice(0, index),
                ...valueMapped.slice(index + 1),
              ]);
            } else {
              onChange(
                [...valueMapped, mappedDate].sort(
                  (a, b) => Number(new Date(a)) - Number(new Date(b))
                )
              );
            }
          } else {
            onChange([mappedDate]);
          }
        }
      },
      [onChange, valueMapped]
    );

    const onClickWeek = useCallback(
      (_weekNumber: number, days: Date[]) => {
        if (!onChange) {
          return;
        }

        // Filter de dagen die voor of na de periode liggen
        const daysInRange = days
          .filter(
            (d) =>
              moment(d).startOf("day").isSameOrAfter(period?.from) &&
              moment(d).startOf("day").isSameOrBefore(period?.to) &&
              weekDays?.[moment(d).isoWeekday() - 1] === 1 &&
              ((daysOfWeek?.length ?? 0) === 0 ||
                daysOfWeek?.some((s) => s === moment(d).isoWeekday() - 1))
          )
          .map((v) => new Date(v.toDateString()));

        if (valueMapped) {
          // Als de huidige waarde leeg is, of als 1 van de geselecteerde dagen niet in de selectie zit voegen we de hele week toe
          const toAdd =
            valueMapped.length === 0 ||
            daysInRange
              .map((v) =>
                valueMapped.findIndex(
                  (a) => new Date(a).valueOf() === v.valueOf()
                )
              )
              .some((s) => s === -1);

          if (toAdd) {
            // Soteer en filter dubbele waardes
            onChange(
              removeAndSortDuplicateDates(
                [...valueMapped, ...daysInRange].sort(
                  (a, b) => Number(new Date(a)) - Number(new Date(b))
                )
              )
            );
          } else {
            // Verwijder alle dagen uit de geselecteerde week
            onChange(
              valueMapped.filter(
                (v) =>
                  !daysInRange.some(
                    (a) => new Date(a).valueOf() === v.valueOf()
                  )
              )
            );
          }
        } else {
          onChange(daysInRange);
        }
      },
      [daysOfWeek, onChange, period?.from, period?.to, valueMapped, weekDays]
    );

    const toggleExcludedDays = useCallback(
      (exclude: boolean) => {
        if (!onChange) {
          return;
        }

        const dates: Date[] = [];
        if (!exclude) {
          // don't exclude a day
          onChange(dates);
        } else if (period?.from && period?.to) {
          const current = moment(period.from).startOf("day");
          const end = moment(period.to).endOf("day");
          while (current.isSameOrBefore(end)) {
            dates.push(current.toDate());
            current.add(1, "days");
          }

          // exclude all days
          onChange(dates);
        }
      },
      [onChange, period?.from, period?.to]
    );

    const excludedDaysString = useMemo(() => {
      const excludedDaysTranslation = getExcludedDaysString(valueMapped);
      return excludedDaysTranslation ? i18n._(excludedDaysTranslation) : "";
    }, [i18n, valueMapped]);

    return (
      <>
        {disabled ? (
          <span className={styles.disabledLink}>
            <Icons.CalendarIcon /> {disabledText}
          </span>
        ) : (
          <>
            <button className="link" type="button" onClick={handleShowCalendar}>
              <Icons.CalendarIcon />{" "}
              {(valueMapped?.length ?? 0) > 0 ? (
                <Trans>Uitgesloten dagen: </Trans>
              ) : (
                <Trans>Geen dagen uitgesloten</Trans>
              )}
            </button>{" "}
            {excludedDaysString}
          </>
        )}

        <Modal
          open={showCalendar}
          onOk={handleHideCalendar}
          onCancel={handleHideCalendar}
          cancelText={<Trans>Annuleren</Trans>}
          title={<Trans>Dagen uitsluiten</Trans>}
          width="450px"
          footer={[
            <Button key="submit" mode="primary" onClick={handleHideCalendar}>
              Ok
            </Button>,
          ]}
          className={styles.exclude}
        >
          {!canDisableSeperateDays && (
            <Alert
              showIcon
              type="info"
              message=""
              description={i18n._(
                t`Bij dit pakket is het alleen mogelijk om complete weken uit te sluiten (klik op het weeknummer).`
              )}
            />
          )}

          <DatePickerExtended
            mode="multiple"
            componentLocale={getLanguage() as ComponentLocalization}
            defaultMonth={period?.from}
            disabled={[
              {
                before: period?.from ?? new Date(),
              },
              {
                after: period?.to ?? new Date(),
              },
              {
                dayOfWeek: [0, 1, 2, 3, 4, 5, 6].filter(
                  (key) => weekDays?.[(key + 6) % 7] === 0
                ),
              },
            ]}
            selected={value?.map((v) => new Date(v))}
            onWeekNumberClick={onClickWeek}
            onDayClick={canDisableSeperateDays ? handleChange : undefined}
            className={classNames(styles.calendar, "excluded")}
          />

          <div className={styles.center}>
            <Space direction="horizontal">
              <Button
                size="small"
                mode="tertiary"
                onClick={() => toggleExcludedDays(true)}
              >
                <Trans>Alles uitsluiten</Trans>
              </Button>
              <Button
                size="small"
                mode="tertiary"
                onClick={() => toggleExcludedDays(false)}
              >
                <Trans>Niets uitsluiten</Trans>
              </Button>
            </Space>
          </div>
        </Modal>
      </>
    );
  }
);

export default ExcludeDays;
