/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  SpotResultGrpOutput,
  SpotResultGrpOutputFromJSON,
  SpotResultGrpOutputFromJSONTyped,
  SpotResultGrpOutputToJSON,
} from "./";

/**
 *
 * @export
 * @interface SpotResultOutput
 */
export interface SpotResultOutput {
  /**
   *
   * @type {string}
   * @memberof SpotResultOutput
   */
  advertiserName: string;
  /**
   *
   * @type {string}
   * @memberof SpotResultOutput
   */
  channel: string;
  /**
   *
   * @type {Date}
   * @memberof SpotResultOutput
   */
  dateOfBroadcast: Date;
  /**
   *
   * @type {number}
   * @memberof SpotResultOutput
   */
  duration: number;
  /**
   *
   * @type {string}
   * @memberof SpotResultOutput
   */
  productName: string;
  /**
   *
   * @type {string}
   * @memberof SpotResultOutput
   */
  breakId: string;
  /**
   *
   * @type {string}
   * @memberof SpotResultOutput
   */
  commercialCode: string;
  /**
   *
   * @type {string}
   * @memberof SpotResultOutput
   */
  commercialTitle: string;
  /**
   *
   * @type {Array<SpotResultGrpOutput>}
   * @memberof SpotResultOutput
   */
  targetGroups: Array<SpotResultGrpOutput>;
  /**
   *
   * @type {string}
   * @memberof SpotResultOutput
   */
  programBefore: string;
  /**
   *
   * @type {string}
   * @memberof SpotResultOutput
   */
  programAfter: string;
}

export function SpotResultOutputFromJSON(json: any): SpotResultOutput {
  return SpotResultOutputFromJSONTyped(json, false);
}

export function SpotResultOutputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SpotResultOutput {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    advertiserName: json["advertiserName"],
    channel: json["channel"],
    dateOfBroadcast: new Date(json["dateOfBroadcast"]),
    duration: json["duration"],
    productName: json["productName"],
    breakId: json["breakId"],
    commercialCode: json["commercialCode"],
    commercialTitle: json["commercialTitle"],
    targetGroups: (json["targetGroups"] as Array<any>).map(
      SpotResultGrpOutputFromJSON
    ),
    programBefore: json["programBefore"],
    programAfter: json["programAfter"],
  };
}

export function SpotResultOutputToJSON(value?: SpotResultOutput | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    advertiserName: value.advertiserName,
    channel: value.channel,
    dateOfBroadcast: value.dateOfBroadcast.toISOString(),
    duration: value.duration,
    productName: value.productName,
    breakId: value.breakId,
    commercialCode: value.commercialCode,
    commercialTitle: value.commercialTitle,
    targetGroups: (value.targetGroups as Array<any>).map(
      SpotResultGrpOutputToJSON
    ),
    programBefore: value.programBefore,
    programAfter: value.programAfter,
  };
}
