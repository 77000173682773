/* eslint-disable react/no-array-index-key */
import { CheckOutlined } from "@ant-design/icons";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  ComponentLocalization,
  Form,
  Icons,
  Input,
  MultiOption,
  Option,
  Radio,
  RangePicker,
  Row,
  Select,
} from "@ster/ster-toolkit";
import { Form as AntForm, Col } from "antd";
import { isAfter, isBefore, startOfDay, subDays } from "date-fns";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";

import {
  Advertiser,
  AnalysisTargetGroupTV,
  MediumEnum,
  Product,
} from "../../api";
import { StoreModel } from "../../store/models";
import { getLanguage } from "../../utils";
import { targetGroupDisplayName } from "../../utils/analysis";
import { SpotAnalysisFilter } from "./models";

const SpotAnalysisFilters = ({
  advertisers,
  filter,
  onChange,
  mediumTypes,
  targetGroups,
}: {
  filter: SpotAnalysisFilter;
  onChange: (value: SpotAnalysisFilter) => void;
  advertisers: Advertiser[];
  mediumTypes: MediumEnum[];
  targetGroups: AnalysisTargetGroupTV[];
}) => {
  const { i18n } = useLingui();

  const { loading: loadingSpotTracker = false } = useSelector(
    (store: StoreModel) => store.spotTracker.data
  );

  const [form] = AntForm.useForm<SpotAnalysisFilter>();

  const handleFinish = useCallback(
    (values: SpotAnalysisFilter) => {
      onChange(values);
    },
    [onChange]
  );

  const advertiserId = AntForm.useWatch("advertiserId", form);
  const productId = AntForm.useWatch("productId", form);
  const medium = AntForm.useWatch("medium", form);
  const period = AntForm.useWatch("period", form);

  useEffect(() => {
    if (!productId) {
      if (advertiserId) {
        const products = advertisers.find(
          (a) => a.id === advertiserId
        )?.products;
        if (products?.length === 1) {
          // if only one product, select it
          form.setFieldsValue({ productId: products?.[0].id });
        } else if (Number(products?.length) > 0) {
          // reset `productId` value
          form.setFieldsValue({ productId: undefined });
        }
      } else if (advertisers.length === 1) {
        form.setFieldsValue({ advertiserId: advertisers?.[0].id });
      } else {
        form.setFieldsValue({ advertiserId: undefined });
      }
    }
  }, [advertiserId, advertisers, form, productId]);

  const isDateDisabled = useCallback(
    (currentDate: Date): boolean =>
      // After 1 January 2020
      isBefore(currentDate, new Date(2020, 0, 1)) ||
      // The most recent day, should be 7 days ago
      isAfter(currentDate, startOfDay(subDays(new Date(), 7))),
    []
  );

  return (
    <Form form={form} onFinish={handleFinish} initialValues={filter}>
      <Row gutter={[16, 0]} align="bottom">
        <Col xs={12} md={4}>
          <Form.Item
            name="advertiserId"
            rules={[{ required: true, message: i18n._(t`Veld is verplicht`) }]}
          >
            <Select.Search
              optionFilterProp="children"
              placeholder={i18n._(t`Adverteerder`)}
              onSelect={() => {
                form.setFieldsValue({ productId: undefined });
              }}
            >
              {advertisers.map((advertiser: Advertiser) => (
                <Option value={advertiser.id} key={advertiser.id}>
                  {advertiser.name}
                </Option>
              ))}
            </Select.Search>
          </Form.Item>
        </Col>
        <Col xs={12} md={4}>
          <Form.Item
            noStyle
            dependencies={["advertiserId"]}
            rules={[{ required: true, message: i18n._(t`Veld is verplicht`) }]}
          >
            {({ getFieldValue }) => {
              const selectedAdvertiser = getFieldValue("advertiserId");
              const products = advertisers
                .filter((a) => a.id === selectedAdvertiser)
                .flatMap((a) => a.products ?? []);

              return (
                <Form.Item
                  name="productId"
                  disabled={!selectedAdvertiser || products.length === 0}
                  rules={[
                    { required: true, message: i18n._(t`Veld is verplicht`) },
                  ]}
                >
                  <Select.Search
                    optionFilterProp="children"
                    disabled={!selectedAdvertiser || products.length === 0}
                    placeholder={i18n._(t`Sternummer`)}
                  >
                    {products.map((product: Product) => (
                      <Option value={product.id} key={product.id}>
                        {product.id} - {product.description}
                      </Option>
                    ))}
                  </Select.Search>
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col xs={12} md={7} lg={6} xl={5}>
          <Form.Item
            name="medium"
            rules={[{ required: true, message: i18n._(t`Veld is verplicht`) }]}
          >
            <Radio.Group
              mode="horizontal"
              name="radioMediaGroup"
              style={{
                height: 36,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {mediumTypes.includes(MediumEnum.Tv) && (
                <Radio value="tv" style={{ margin: 0 }}>
                  <Trans>Televisie</Trans>
                  <Icons.TVIcon width={20} height={20} />
                </Radio>
              )}
              {mediumTypes.includes(MediumEnum.Radio) && (
                <Radio value="radio" style={{ margin: 0 }} disabled>
                  <Trans>Radio</Trans>
                  <Icons.RadioIcon width={20} height={20} />
                </Radio>
              )}
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col xs={12} md={5}>
          <Form.Item
            name="period"
            rules={[{ required: true, message: i18n._(t`Veld is verplicht`) }]}
          >
            <RangePicker
              allowClear={false}
              componentLocale={getLanguage() as ComponentLocalization}
              disabledDate={isDateDisabled}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col xs={12} md={4}>
          <Form.Item name="targetGroup" rules={[{ required: false }]} hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="secondaryTargetGroups"
            rules={[{ required: false }]}
            hidden
          >
            <Input />
          </Form.Item>
          <Form.Item noStyle dependencies={["advertiserId"]}>
            {({ setFieldsValue }) => (
              <Form.Item>
                <Select.Multi
                  placeholder={i18n._(t`Secundaire doelgroep`)}
                  onChange={(value) => {
                    const v = value as number[] | undefined;
                    if (!v || v.length === 0) {
                      setFieldsValue({
                        secondaryTargetGroups: [],
                      });
                    } else {
                      setFieldsValue({
                        secondaryTargetGroups: v.map(
                          (idx) => targetGroups[idx]
                        ),
                      });
                    }
                  }}
                >
                  {targetGroups.map((tg, i) => (
                    <MultiOption
                      key={i}
                      value={i}
                      title={targetGroupDisplayName(tg)}
                      disabled={targetGroupDisplayName(tg) === "13+"}
                    />
                  ))}
                </Select.Multi>
              </Form.Item>
            )}
          </Form.Item>
        </Col>
        <Col xs={12} md={2}>
          <Form.Item>
            <Button
              mode="primary"
              htmlType="submit"
              disabled={
                !(
                  advertiserId &&
                  advertiserId > 0 &&
                  productId &&
                  productId > 0 &&
                  medium &&
                  period &&
                  period.length === 2 &&
                  !loadingSpotTracker
                )
              }
            >
              <CheckOutlined />
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default SpotAnalysisFilters;
