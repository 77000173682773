/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface AnalysisPeriod
 */
export interface AnalysisPeriod {
  /**
   *
   * @type {Date}
   * @memberof AnalysisPeriod
   */
  start?: Date;
  /**
   *
   * @type {Date}
   * @memberof AnalysisPeriod
   */
  end?: Date;
}

export function AnalysisPeriodFromJSON(json: any): AnalysisPeriod {
  return AnalysisPeriodFromJSONTyped(json, false);
}

export function AnalysisPeriodFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AnalysisPeriod {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    start: !exists(json, "start") ? undefined : new Date(json["start"]),
    end: !exists(json, "end") ? undefined : new Date(json["end"]),
  };
}

export function AnalysisPeriodToJSON(value?: AnalysisPeriod | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    start: value.start === undefined ? undefined : value.start.toISOString(),
    end: value.end === undefined ? undefined : value.end.toISOString(),
  };
}
