import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Channel,
  ChannelNames,
  Form,
  Icons,
  Option,
  Select,
  Tag,
} from "@ster/ster-toolkit";
import { Form as AntForm } from "antd";
import { memo, useMemo } from "react";

import { MediumEnum, TargetGroup } from "../../../../api";
import { TagStatus, getPopupContainerSubOrders } from "../../../../utils";
import { RadioShortChannelNames } from "../../../partials/Channel/Channel";
import { TargetGroupSelectProps } from "./models";
import styles from "./TargetGroupSelect.module.less";

const TargetGroupSingleItemBody = memo(
  ({
    targetGroup,
    medium,
  }: {
    targetGroup: TargetGroup;
    medium: MediumEnum;
  }) => {
    const { i18n } = useLingui();
    return (
      <span className={styles.targetGroupOptionWrapper}>
        {targetGroup.channels?.map((channel) => (
          <span key={channel} className={styles.channelIcon}>
            <Channel
              type={
                RadioShortChannelNames.find(({ key }) => key === channel)
                  ?.value as ChannelNames
              }
            />
          </span>
        ))}
        <span>{targetGroup.targetGroupName}</span>
        {medium !== MediumEnum.Radio && (
          <Tag
            status={TagStatus.Info}
            text={`${i18n._(t`Doelgroepindex`)}: ${
              targetGroup.targetGroupIndex
            }`}
            className={styles.dropdownTag}
          />
        )}
      </span>
    );
  }
);

const TargetGroupSelect = memo(
  ({ disabled, targetGroups, medium, form }: TargetGroupSelectProps) => {
    const { i18n } = useLingui();

    const targetGroupId = AntForm.useWatch("targetGroupId", { form });

    const selectedTargetGroup = useMemo(
      () => targetGroups.find((v) => v.id === targetGroupId),
      [targetGroups, targetGroupId]
    );

    const displayOnly = targetGroups.length === 1;

    return (
      <Form.Item
        label={i18n._(t`Doelgroep`)}
        wrapperCol={{ span: 12 }}
        name="targetGroupId"
        rules={[
          {
            required: true,
            message: i18n._(t`Selecteer een doelgroep`),
          },
        ]}
      >
        {displayOnly === true ? (
          selectedTargetGroup && (
            <TargetGroupSingleItemBody
              targetGroup={selectedTargetGroup}
              medium={medium}
            />
          )
        ) : (
          <Select.Search
            disabled={disabled}
            placeholder={
              <>
                <Icons.AdultsIcon /> <Trans>Selecteer een doelgroep</Trans>
              </>
            }
            className={styles.targetGroup}
            optionFilterProp="search"
            getPopupContainer={getPopupContainerSubOrders}
          >
            {targetGroups.map((tg) => (
              <Option key={tg.id} value={tg.id} search={tg.targetGroupName}>
                <TargetGroupSingleItemBody targetGroup={tg} medium={medium} />
              </Option>
            ))}
          </Select.Search>
        )}
      </Form.Item>
    );
  }
);

export default TargetGroupSelect;
