/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface TimeBand
 */
export interface TimeBand {
  /**
   *
   * @type {string}
   * @memberof TimeBand
   */
  startTime: string;
  /**
   *
   * @type {string}
   * @memberof TimeBand
   */
  endTime: string;
}

export function TimeBandFromJSON(json: any): TimeBand {
  return TimeBandFromJSONTyped(json, false);
}

export function TimeBandFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TimeBand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    startTime: json["startTime"],
    endTime: json["endTime"],
  };
}

export function TimeBandToJSON(value?: TimeBand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    startTime: value.startTime,
    endTime: value.endTime,
  };
}
