import { createSelector, createStructuredSelector } from "reselect";

import {
  DigitalProgram,
  GroupedPackageRadio,
  GroupedPackageTv,
  Package,
  PackageChoiceEnum,
  PackageResult,
} from "../../../api";
import { ValueList } from "../../../api/models/ValueList";
import {
  packagesFromStoreSelector,
  packagesSelector,
  salesPeriodsFromStoreSelector,
} from "../../../shared/selectors";
import { ReduxStoreState } from "../../../store/base";
import {
  LoadingCalculation,
  LoadingContextTargets,
  LoadingGroupedPackagesRadio,
  LoadingGroupedPackagesTv,
  LoadingPackages,
  LoadingPrograms,
  LoadingSalesPeriods,
  LoadingSites,
  LoadingSpotIndexes,
  LoadingTargetGroups,
  StoreModel,
} from "../../../store/models";
import { MediumPeriodPackageChoice, StateResult } from "./models";

export const mediumPeriodSelector = (
  _: StoreModel,
  mediumPeriod: MediumPeriodPackageChoice
): MediumPeriodPackageChoice => mediumPeriod;

export const spotIndexesFromStoreSelector = (
  { spotIndexes }: StoreModel,
  { medium }: MediumPeriodPackageChoice
): LoadingSpotIndexes => spotIndexes[medium];

const groupedPackagesRadioFromStoreSelector = ({
  groupedPackagesRadio,
}: StoreModel): LoadingGroupedPackagesRadio => groupedPackagesRadio;

const groupedPackagesTvFromStoreSelector = ({
  groupedPackagesTv,
}: StoreModel): LoadingGroupedPackagesTv => groupedPackagesTv;

const groupedPackagesTvFromYearSelector = createSelector(
  [groupedPackagesTvFromStoreSelector, mediumPeriodSelector],
  (
    groupedPackages: LoadingGroupedPackagesTv,
    mediumPeriod: MediumPeriodPackageChoice
  ): GroupedPackageTv[] =>
    groupedPackages?.groupedPackagesTv?.[mediumPeriod.period.from.getFullYear()]
);

const groupedPackagesRadioFromYearSelector = createSelector(
  [groupedPackagesRadioFromStoreSelector, mediumPeriodSelector],
  (
    groupedPackages: LoadingGroupedPackagesRadio,
    mediumPeriod: MediumPeriodPackageChoice
  ): GroupedPackageRadio[] =>
    groupedPackages?.groupedPackagesRadio?.[
      mediumPeriod.period.from.getFullYear()
    ]
);

const sitesFromStoreSelector = ({ sites }: StoreModel): LoadingSites => sites;

const programsFromStoreSelector = ({ programs }: StoreModel): LoadingPrograms =>
  programs;

const contextTargetsFromStoreSelector = ({
  contextTargets,
}: StoreModel): LoadingContextTargets => contextTargets;

const targetGroupsFromStoreSelector = ({
  targetGroups,
}: StoreModel): LoadingTargetGroups => targetGroups;

export const spotIndexesSelector = createSelector(
  [spotIndexesFromStoreSelector],
  (spotIndexes: LoadingSpotIndexes) =>
    spotIndexes?.spotIndexes?.filter((t) => (t.length ?? 0) % 5 === 0) || [] // Geef alleen spotindexen terug die een veelvoud van 5 zijn
);

export const spotIndexesOnlineSelector = createSelector(
  [spotIndexesFromStoreSelector],
  (spotIndexes: LoadingSpotIndexes) => spotIndexes?.spotIndexes || []
);

export const grpPackagesSelector = createSelector(
  [packagesSelector],
  (packages: PackageResult[]) =>
    packages.find((s) => s.type === PackageChoiceEnum.Grps)?.packages || []
);

export const fixedCostsPackagesSelector = createSelector(
  [packagesSelector, mediumPeriodSelector],
  (packages: PackageResult[], { packageChoice }: MediumPeriodPackageChoice) =>
    packages.find((s) => s.type === packageChoice)?.packages ?? []
);

export const npoPromoPackagesSelector = createSelector(
  [packagesSelector],
  (packages: PackageResult[]) =>
    packages.find((s) => s.type === PackageChoiceEnum.NpoPromo)?.packages || []
);

export const displayPackagesSelector = createSelector(
  [packagesSelector],
  (packages: PackageResult[]) =>
    packages.find((s) => s.type === PackageChoiceEnum.Display)?.packages || []
);

export const videoPackagesSelector = createSelector(
  [packagesSelector],
  (packages: PackageResult[]) =>
    packages.find((s) => s.type === PackageChoiceEnum.Video)?.packages || []
);

export const sitesSelector = createSelector(
  [sitesFromStoreSelector],
  (sites: LoadingSites): ValueList[] => sites.data ?? []
);

export const programsSelector = createSelector(
  [programsFromStoreSelector],
  (programs: LoadingPrograms): DigitalProgram[] => programs.data ?? []
);

export const contextTargetsSelector = createSelector(
  [contextTargetsFromStoreSelector],
  (contextTargets: LoadingContextTargets): ValueList[] =>
    contextTargets.contextTargets ?? []
);

export const targetGroupsSelector = createSelector(
  [targetGroupsFromStoreSelector],
  (targetGroups: LoadingTargetGroups): ValueList[] => targetGroups.data ?? []
);

export const groupedPackagesRadioSelector = createSelector(
  [groupedPackagesRadioFromYearSelector, grpPackagesSelector],
  (groupedPackages: GroupedPackageRadio[], packages: Package[]) => {
    if (!groupedPackages) {
      return [];
    }

    // Filter de gegroepeerde pakketten die niet in de gewone pakketten aanroep beschikbaar zijn
    const availableGroupedPackages =
      groupedPackages
        ?.map((g) => ({
          name: g.name,
          properties: g.properties.filter(({ code: propertyCode }) =>
            packages.find(({ code }) => code === propertyCode)
          ),
        }))
        .filter((g) => g.properties.length > 0) || [];

    // Bepaal welke pakketten er niet in een pakketgroep zitten
    const packagesNotInGroup = packages.filter(
      ({ code }) =>
        groupedPackages
          ?.flatMap(({ properties }) => properties)
          .map((g) => g.code)
          .find((prop) => prop === code) === undefined
    );

    // Voeg de pakketgroepen en de losse pakketten samen
    return availableGroupedPackages
      .concat(
        ...packagesNotInGroup.map((p) => ({
          name: p.name,
          properties: [
            {
              code: p.code,
              hasCombi: false,
              hasHours: false,
              hasSpread: false,
              hasChannelChoice: false,
              hasDays: false,
              hasPreferredPosition: false,
            },
          ],
        }))
      )
      .sort((a, b) => a.name.localeCompare(b.name));
  }
);

export const groupedPackagesTvSelector = createSelector(
  [groupedPackagesTvFromYearSelector, grpPackagesSelector],
  (groupedPackages: GroupedPackageTv[], packages: Package[]) => {
    if (!groupedPackages) {
      return [];
    }

    // Filter de gegroepeerde pakketten die niet in de gewone pakketten aanroep beschikbaar zijn
    const availableGroupedPackages =
      groupedPackages
        ?.map((g) => ({
          name: g.name,
          properties: g.properties.filter(({ code: propertyCode }) =>
            packages.find(({ code }) => code === propertyCode)
          ),
        }))
        .filter((g) => g.properties.length > 0) || [];

    // Bepaal welke pakketten er niet in een pakketgroep zitten
    const packagesNotInGroup = packages.filter(
      ({ code }) =>
        groupedPackages
          ?.flatMap(({ properties }) => properties)
          .map((g) => g.code)
          .find((prop) => prop === code) === undefined
    );

    // Voeg de pakketgroepen en de losse pakketten samen
    return availableGroupedPackages
      .concat(
        ...packagesNotInGroup.map((p) => ({
          name: p.name,
          properties: [
            {
              code: p.code,
              hasSpread: false,
              hasPreferredPosition: false,
              hasHotspot: false,
              hasPremium: false,
            },
          ],
        }))
      )
      .sort((a, b) => a.name.localeCompare(b.name));
  }
);

const loadingSubOrderSelector = createSelector(
  [
    spotIndexesFromStoreSelector,
    packagesFromStoreSelector,
    groupedPackagesRadioFromStoreSelector,
    groupedPackagesTvFromStoreSelector,
    salesPeriodsFromStoreSelector,
    sitesFromStoreSelector,
    contextTargetsFromStoreSelector,
    programsFromStoreSelector,
    targetGroupsFromStoreSelector,
  ],
  (
    spotIndexes: LoadingSpotIndexes,
    packages: LoadingPackages,
    groupedPackagesRadio: LoadingGroupedPackagesRadio,
    groupedPackagesTv: LoadingGroupedPackagesTv,
    salesPeriods: LoadingSalesPeriods,
    sites: LoadingSites,
    contextTargets: LoadingContextTargets,
    programs: LoadingPrograms,
    targetGroups: LoadingTargetGroups
  ): boolean =>
    (spotIndexes?.state ?? ReduxStoreState.Initial) ===
      ReduxStoreState.Loading ||
    packages.state === ReduxStoreState.Loading ||
    groupedPackagesRadio.state === ReduxStoreState.Loading ||
    groupedPackagesTv.state === ReduxStoreState.Loading ||
    salesPeriods.state === ReduxStoreState.Loading ||
    sites.state === ReduxStoreState.Loading ||
    contextTargets.state === ReduxStoreState.Loading ||
    programs.state === ReduxStoreState.Loading ||
    targetGroups.state === ReduxStoreState.Loading
);

const stateSelector = createSelector(
  [
    spotIndexesFromStoreSelector,
    packagesFromStoreSelector,
    groupedPackagesRadioFromStoreSelector,
    groupedPackagesTvFromStoreSelector,
    salesPeriodsFromStoreSelector,
    sitesFromStoreSelector,
    contextTargetsFromStoreSelector,
    programsFromStoreSelector,
    targetGroupsFromStoreSelector,
  ],
  (
    spotIndexes: LoadingSpotIndexes,
    packages: LoadingPackages,
    groupedPackagesRadio: LoadingGroupedPackagesRadio,
    groupedPackagesTv: LoadingGroupedPackagesTv,
    salesPeriods: LoadingSalesPeriods,
    sites: LoadingSites,
    contextTargets: LoadingContextTargets,
    programs: LoadingPrograms,
    targetGroups: LoadingTargetGroups
  ): StateResult => ({
    spotIndexes: spotIndexes?.state ?? ReduxStoreState.Initial,
    packages: packages.state,
    groupedPackagesRadio: groupedPackagesRadio.state,
    groupedPackagesTv: groupedPackagesTv.state,
    salesPeriods: salesPeriods.state,
    sites: sites.state,
    contextTargets: contextTargets.state,
    programs: programs.state,
    targetGroups: targetGroups.state,
  })
);

const canRequestSelector = createSelector(
  [salesPeriodsFromStoreSelector, mediumPeriodSelector],
  (
    salesPeriods: LoadingSalesPeriods,
    mediumPeriod: MediumPeriodPackageChoice
  ): boolean =>
    salesPeriods.salesPeriods
      .find((s) => s.medium === mediumPeriod.medium)
      ?.result.find(
        (s) =>
          s.salesPeriodStartDate <= mediumPeriod.period.to &&
          s.salesPeriodEndDate >= mediumPeriod.period.from
      )?.canRequest ?? false
);

export const calculationFromStoreSelector = (
  { calculation }: StoreModel,
  subOrderId: number
): LoadingCalculation =>
  calculation[subOrderId] ?? {
    loading: false,
    state: ReduxStoreState.Initial,
    calculation: {},
  };

export const grpSubOrderSelector = createStructuredSelector({
  spotIndexes: spotIndexesSelector,
  packages: grpPackagesSelector,
  groupedPackagesRadio: groupedPackagesRadioSelector,
  groupedPackagesTv: groupedPackagesTvSelector,
  canRequest: canRequestSelector,
  loading: loadingSubOrderSelector,
  states: stateSelector,
});

export const fixedCostsSubOrderSelector = createStructuredSelector({
  packages: fixedCostsPackagesSelector,
  spotIndexes: spotIndexesSelector,
  canRequest: canRequestSelector,
  loading: loadingSubOrderSelector,
  states: stateSelector,
});

export const npoPromoSubOrderSelector = createStructuredSelector({
  spotIndexes: spotIndexesSelector,
  loading: loadingSubOrderSelector,
  states: stateSelector,
  packages: npoPromoPackagesSelector,
});

export const onlineSubOrderSelector = createStructuredSelector({
  loading: loadingSubOrderSelector,
  displayPackages: displayPackagesSelector,
  videoPackages: videoPackagesSelector,
  sites: sitesSelector,
  programs: programsSelector,
  contextTargets: contextTargetsSelector,
  states: stateSelector,
  targetGroups: targetGroupsSelector,
  spotIndexes: spotIndexesOnlineSelector,
});

export const calculationSelector = createStructuredSelector({
  calculation: calculationFromStoreSelector,
});
