import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Modal, Row, Spinner } from "@ster/ster-toolkit";
import { Alert, Col } from "antd";
import { memo, useCallback, useEffect } from "react";

import { AvailableBreak, BreakSelection } from "../../api";
import BreakFilterForm from "./BreakFilterForm";
import styles from "./BreakSelectModal.module.less";
import BreakSelectTable from "./BreakSelectTable";
import { BreakSelectInnerProps } from "./models";

const BreakSelectModal = memo(
  ({
    breaks,
    value,
    loading,
    onCancel,
    onOk,
    onFilter,
    onChange,
    filters,
    defaultFilters,
    showSelection,
    setShowSelection,
    selectionCount,
    channels,
    secondaryTargetGroups,
    showTop2000Warning,
    canSelectPreferredPosition,
    preferredPositionSurcharge,
    budget,
    footer,
    alreadySelected,
  }: BreakSelectInnerProps) => {
    const { i18n } = useLingui();

    const handleSelectAll = useCallback(() => {
      const newValue = breaks.map(
        (selectedRow: AvailableBreak): BreakSelection => ({
          key: selectedRow.uniqueId,
          _break: selectedRow,
        })
      );
      onChange(newValue);
    }, [breaks, onChange]);

    useEffect(() => {
      try {
        window.$chatwoot?.toggleBubbleVisibility("hide");
      } catch (e) {
        // discard
      }
      return () => {
        try {
          window.$chatwoot?.toggleBubbleVisibility("show");
        } catch (e) {
          // discard
        }
      };
    }, []);

    return (
      <Modal
        open
        onCancel={onCancel}
        cancelText={i18n._(t`Annuleren`)}
        onOk={onOk}
        width="100%"
        className={styles.modal}
        footer={
          <div className={styles.footer}>
            {footer ?? (
              <>
                {showTop2000Warning && (
                  <Alert
                    showIcon
                    type="warning"
                    message=""
                    description={i18n._(
                      t`Let op! indien je Top2000 blokken in wilt boeken dien je een
              Top2000 GRP pakket te selecteren!`
                    )}
                  />
                )}
                <Button
                  mode="tertiary"
                  key="back"
                  className={styles.cancel}
                  onClick={onCancel}
                >
                  <Trans>Annuleren</Trans>
                </Button>

                <Button
                  key="submit"
                  mode="primary"
                  loading={loading}
                  onClick={onOk}
                >
                  <Trans>OK</Trans>
                </Button>
              </>
            )}
          </div>
        }
      >
        <BreakFilterForm
          onFilter={onFilter}
          filters={filters}
          defaultFilters={defaultFilters}
          showSelection={showSelection}
          onShowSelectionChange={setShowSelection}
          selectionCount={selectionCount}
          channels={channels}
          secondaryTargetGroups={secondaryTargetGroups}
          onSelectAll={handleSelectAll}
        />
        <Row>
          <Col span={24}>
            <Spinner spinning={loading}>
              <BreakSelectTable
                breaks={breaks}
                value={value}
                onChange={onChange}
                filters={filters}
                canSelectPreferredPosition={canSelectPreferredPosition}
                preferredPositionSurcharge={preferredPositionSurcharge}
                budget={budget}
                alreadySelected={alreadySelected}
              />
            </Spinner>
          </Col>
        </Row>
      </Modal>
    );
  }
);

export default BreakSelectModal;
