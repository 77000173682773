/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  RuleType,
  RuleTypeFromJSON,
  RuleTypeFromJSONTyped,
  RuleTypeToJSON,
} from "./";

/**
 *
 * @export
 * @interface DiscountSurtaxRule
 */
export interface DiscountSurtaxRule {
  /**
   *
   * @type {string}
   * @memberof DiscountSurtaxRule
   */
  code?: string | null;
  /**
   *
   * @type {string}
   * @memberof DiscountSurtaxRule
   */
  description?: string | null;
  /**
   *
   * @type {number}
   * @memberof DiscountSurtaxRule
   */
  percentage?: number;
  /**
   *
   * @type {RuleType}
   * @memberof DiscountSurtaxRule
   */
  type?: RuleType;
}

export function DiscountSurtaxRuleFromJSON(json: any): DiscountSurtaxRule {
  return DiscountSurtaxRuleFromJSONTyped(json, false);
}

export function DiscountSurtaxRuleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DiscountSurtaxRule {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    code: !exists(json, "code") ? undefined : json["code"],
    description: !exists(json, "description") ? undefined : json["description"],
    percentage: !exists(json, "percentage") ? undefined : json["percentage"],
    type: !exists(json, "type") ? undefined : RuleTypeFromJSON(json["type"]),
  };
}

export function DiscountSurtaxRuleToJSON(
  value?: DiscountSurtaxRule | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    code: value.code,
    description: value.description,
    percentage: value.percentage,
    type: RuleTypeToJSON(value.type),
  };
}
