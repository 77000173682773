import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  ComponentLocalization,
  ContentContainer,
  DatePickerMonth,
  Icons,
  MediaRadioGroup,
  Row,
  Typography,
} from "@ster/ster-toolkit";
import { Col, RadioChangeEvent, Space } from "antd";
import classNames from "classnames";
import { addMonths, endOfMonth, startOfMonth } from "date-fns";
import { memo, useCallback } from "react";

import { getLanguage } from "../../utils";
import InvoiceCard from "../partials/Cards/Invoice/InvoiceCard";
import shared from "../Shared.module.less";
import { InvoiceListProps } from "./models";

const InvoiceList = memo(
  ({
    invoices,
    period,
    onPeriodChange,
    medium,
    onMediumChange,
    availableMediumTypes,
  }: InvoiceListProps): React.ReactElement => {
    const { i18n } = useLingui();
    // Verander van medium type
    const handleMediumChange = useCallback(
      ({ target }: RadioChangeEvent): void => onMediumChange(target.value),
      [onMediumChange]
    );

    // zet datum-venster 1 maand terug
    const handlePreviousMonth = useCallback(() => {
      onPeriodChange(
        period.from && startOfMonth(addMonths(period.from, -1)),
        period.to && startOfMonth(addMonths(period.to, -1))
      );
    }, [onPeriodChange, period]);

    // zet datum-venster 1 maand verder
    const handleNextMonth = useCallback(() => {
      onPeriodChange(
        period.from && startOfMonth(addMonths(period.from, 1)),
        period.to && startOfMonth(addMonths(period.to, 1))
      );
    }, [onPeriodChange, period]);

    // kies andere maand
    const handleMonthChange = useCallback(
      (value: Date | Date[]): void => {
        const selectedValue = Array.isArray(value) ? value[0] : value;

        if (value) {
          onPeriodChange(
            startOfMonth(selectedValue),
            endOfMonth(selectedValue)
          );
        } else {
          onPeriodChange(null, null);
        }
      },
      [onPeriodChange]
    );

    return (
      <ContentContainer>
        <Row
          className={classNames(shared.filterrow, "form-filtering-paging")}
          justify="space-between"
          align="middle"
          gutter={[12, 24]}
        >
          <Col flex="470px">
            <MediaRadioGroup
              value={medium}
              mediaTypes={availableMediumTypes}
              onChange={handleMediumChange}
              className={shared.mediaRadioButtons}
              labels={{
                online: i18n._(t`Online`),
                radio: i18n._(t`Radio`),
                tv: i18n._(t`Televisie`),
                all: i18n._(t`Alles`),
              }}
              includeAll
            />
          </Col>
          <Col flex="auto">
            <Space className={shared.filterspacer} direction="horizontal">
              <Button
                mode="tertiary"
                icon={<Icons.ChevronLeftIcon />}
                onClick={handlePreviousMonth}
              >
                <span className="btn-label">
                  <Trans>Vorige maand</Trans>
                </span>
              </Button>

              <DatePickerMonth
                onChange={handleMonthChange}
                value={period.from}
                format="MMM yyyy"
                componentLocale={getLanguage() as ComponentLocalization}
              />

              <Button
                mode="tertiary"
                icon={<Icons.ChevronRightIcon />}
                onClick={handleNextMonth}
              >
                <span className="btn-label">
                  <Trans>Volgende maand</Trans>
                </span>
              </Button>
            </Space>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {invoices.length === 0 ? (
              <Trans>
                <Typography.Paragraph>
                  Er zijn geen facturen voor deze selectie beschikbaar.
                </Typography.Paragraph>
              </Trans>
            ) : (
              invoices.map((invoice) => (
                <Row gutter={[40, 40]} key={invoice.internalId}>
                  <Col span={24}>
                    <InvoiceCard cardData={invoice} />
                  </Col>
                </Row>
              ))
            )}
          </Col>
        </Row>
      </ContentContainer>
    );
  }
);

export default InvoiceList;
