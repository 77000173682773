import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

import { AccountApi } from "../../api/apis";
import apiConfig from "../../apiConfig";
import { toError } from "../utils";
import {
  createUserAction,
  deleteUserAction,
  receiveUserAction,
  receiveUsersAction,
  receiveUsersExportAction,
  updateUserAction,
} from "./actions";

export function* receiveUsers(): SagaIterator {
  try {
    const accountApi = new AccountApi(apiConfig());
    const response = yield call(() => accountApi.apiV1AccountAllGet());
    yield put(receiveUsersAction.success(response));
  } catch (err) {
    yield put(receiveUsersAction.failure(toError(err)));
  }
}

export function* createUser(
  action: ReturnType<typeof createUserAction.request>
): SagaIterator {
  try {
    const accountApi = new AccountApi(apiConfig());
    const response = yield call(() =>
      accountApi.apiV1AccountRegisterPost({ userModel: action.payload })
    );
    yield put(createUserAction.success(response));
    yield put(receiveUsersAction.request());
  } catch (err) {
    yield put(createUserAction.failure(toError(err)));
  }
}

export function* updateUser(
  action: ReturnType<typeof updateUserAction.request>
): SagaIterator {
  try {
    const accountApi = new AccountApi(apiConfig());
    const response = yield call(() =>
      accountApi.apiV1AccountUpdateUserIdPost(action.payload)
    );
    yield put(updateUserAction.success(response));
    yield put(receiveUsersAction.request());
  } catch (err) {
    yield put(updateUserAction.failure(toError(err)));
  }
}

export function* receiveUser(
  action: ReturnType<typeof receiveUserAction.request>
): SagaIterator {
  try {
    const accountApi = new AccountApi(apiConfig());
    const response = yield call(() =>
      accountApi.apiV1AccountUserIdGet({ userId: action.payload })
    );
    yield put(receiveUserAction.success(response));
  } catch (err) {
    yield put(receiveUserAction.failure(toError(err)));
  }
}

export function* deleteUser(
  action: ReturnType<typeof deleteUserAction.request>
): SagaIterator {
  try {
    const accountApi = new AccountApi(apiConfig());
    const response = yield call(() =>
      action.payload.active
        ? accountApi.apiV1AccountDeleteUserIdPost(action.payload)
        : accountApi.apiV1AccountDeleteUserIdDelete(action.payload)
    );
    yield put(deleteUserAction.success(response));
    yield put(receiveUsersAction.request());
  } catch (err) {
    yield put(deleteUserAction.failure(toError(err)));
  }
}

export function* receiveUsersExport(): SagaIterator {
  try {
    const accountApi = new AccountApi(apiConfig());
    const response = yield call(() => accountApi.apiV1AccountExportGet());

    yield put(receiveUsersExportAction.success(response));
  } catch (err) {
    yield put(receiveUsersExportAction.failure(toError(err)));
  }
}
