/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  CustomerAccount,
  CustomerAccountFromJSON,
  CustomerAccountToJSON,
  CustomerContact,
  CustomerContactFromJSON,
  CustomerContactToJSON,
  CustomerOpportunity,
  CustomerOpportunityFromJSON,
  CustomerOpportunityToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface ApiV1CustomerContactsAccountIdGetRequest {
  accountId: string;
}

export interface ApiV1CustomerContactsAdvertiserIdGetRequest {
  advertiserId: number;
}

export interface ApiV1CustomerOpportunitiesAccountIdGetRequest {
  accountId: string;
}

export interface ApiV1CustomerOpportunitiesAdvertiserIdGetRequest {
  advertiserId: number;
}

/**
 *
 */
export class CustomerApi extends runtime.BaseAPI {
  /**
   */
  async apiV1CustomerAccountsGetRaw(): Promise<
    runtime.ApiResponse<Array<CustomerAccount>>
  > {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Customer/accounts`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(CustomerAccountFromJSON)
    );
  }

  /**
   */
  async apiV1CustomerAccountsGet(): Promise<Array<CustomerAccount>> {
    const response = await this.apiV1CustomerAccountsGetRaw();
    return await response.value();
  }

  /**
   */
  async apiV1CustomerContactsAccountIdGetRaw(
    requestParameters: ApiV1CustomerContactsAccountIdGetRequest
  ): Promise<runtime.ApiResponse<Array<CustomerContact>>> {
    if (
      requestParameters.accountId === null ||
      requestParameters.accountId === undefined
    ) {
      throw new runtime.RequiredError(
        "accountId",
        "Required parameter requestParameters.accountId was null or undefined when calling apiV1CustomerContactsAccountIdGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Customer/contacts/{accountId}`.replace(
        `{${"accountId"}}`,
        encodeURIComponent(String(requestParameters.accountId))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(CustomerContactFromJSON)
    );
  }

  /**
   */
  async apiV1CustomerContactsAccountIdGet(
    requestParameters: ApiV1CustomerContactsAccountIdGetRequest
  ): Promise<Array<CustomerContact>> {
    const response =
      await this.apiV1CustomerContactsAccountIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1CustomerContactsAdvertiserIdGetRaw(
    requestParameters: ApiV1CustomerContactsAdvertiserIdGetRequest
  ): Promise<runtime.ApiResponse<Array<CustomerContact>>> {
    if (
      requestParameters.advertiserId === null ||
      requestParameters.advertiserId === undefined
    ) {
      throw new runtime.RequiredError(
        "advertiserId",
        "Required parameter requestParameters.advertiserId was null or undefined when calling apiV1CustomerContactsAdvertiserIdGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Customer/contacts/{advertiserId}`.replace(
        `{${"advertiserId"}}`,
        encodeURIComponent(String(requestParameters.advertiserId))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(CustomerContactFromJSON)
    );
  }

  /**
   */
  async apiV1CustomerContactsAdvertiserIdGet(
    requestParameters: ApiV1CustomerContactsAdvertiserIdGetRequest
  ): Promise<Array<CustomerContact>> {
    const response =
      await this.apiV1CustomerContactsAdvertiserIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1CustomerOpportunitiesAccountIdGetRaw(
    requestParameters: ApiV1CustomerOpportunitiesAccountIdGetRequest
  ): Promise<runtime.ApiResponse<Array<CustomerOpportunity>>> {
    if (
      requestParameters.accountId === null ||
      requestParameters.accountId === undefined
    ) {
      throw new runtime.RequiredError(
        "accountId",
        "Required parameter requestParameters.accountId was null or undefined when calling apiV1CustomerOpportunitiesAccountIdGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Customer/opportunities/{accountId}`.replace(
        `{${"accountId"}}`,
        encodeURIComponent(String(requestParameters.accountId))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(CustomerOpportunityFromJSON)
    );
  }

  /**
   */
  async apiV1CustomerOpportunitiesAccountIdGet(
    requestParameters: ApiV1CustomerOpportunitiesAccountIdGetRequest
  ): Promise<Array<CustomerOpportunity>> {
    const response =
      await this.apiV1CustomerOpportunitiesAccountIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV1CustomerOpportunitiesAdvertiserIdGetRaw(
    requestParameters: ApiV1CustomerOpportunitiesAdvertiserIdGetRequest
  ): Promise<runtime.ApiResponse<Array<CustomerOpportunity>>> {
    if (
      requestParameters.advertiserId === null ||
      requestParameters.advertiserId === undefined
    ) {
      throw new runtime.RequiredError(
        "advertiserId",
        "Required parameter requestParameters.advertiserId was null or undefined when calling apiV1CustomerOpportunitiesAdvertiserIdGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/Customer/opportunities/{advertiserId}`.replace(
        `{${"advertiserId"}}`,
        encodeURIComponent(String(requestParameters.advertiserId))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(CustomerOpportunityFromJSON)
    );
  }

  /**
   */
  async apiV1CustomerOpportunitiesAdvertiserIdGet(
    requestParameters: ApiV1CustomerOpportunitiesAdvertiserIdGetRequest
  ): Promise<Array<CustomerOpportunity>> {
    const response =
      await this.apiV1CustomerOpportunitiesAdvertiserIdGetRaw(
        requestParameters
      );
    return await response.value();
  }
}
