import { Icons } from "@ster/ster-toolkit";
import { CSSProperties } from "react";

interface ArrowProps {
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
}

export const NextArrow = ({ className, style, onClick }: ArrowProps) => (
  <button
    type="button"
    className={className}
    style={{ ...style }}
    onClick={onClick}
  >
    <Icons.ChevronRightIcon width={32} height={32} />
  </button>
);
export const PrevArrow = ({ className, style, onClick }: ArrowProps) => (
  <button
    type="button"
    className={className}
    style={{ ...style }}
    onClick={onClick}
  >
    <Icons.ChevronLeftIcon width={32} height={32} />
  </button>
);
