/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  TermsAndConditionsModel,
  TermsAndConditionsModelFromJSON,
  TermsAndConditionsModelToJSON,
  TermsAndConditionsResult,
  TermsAndConditionsResultFromJSON,
  TermsAndConditionsResultToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface ApiV1TermsPostRequest {
  termsAndConditionsModel?: TermsAndConditionsModel;
}

/**
 *
 */
export class TermsAndConditionsApi extends runtime.BaseAPI {
  /**
   */
  async apiV1TermsGetRaw(): Promise<
    runtime.ApiResponse<TermsAndConditionsResult>
  > {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/terms`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TermsAndConditionsResultFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1TermsGet(): Promise<TermsAndConditionsResult> {
    const response = await this.apiV1TermsGetRaw();
    return await response.value();
  }

  /**
   */
  async apiV1TermsPostRaw(
    requestParameters: ApiV1TermsPostRequest
  ): Promise<runtime.ApiResponse<TermsAndConditionsResult>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/v1/terms`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TermsAndConditionsModelToJSON(
        requestParameters.termsAndConditionsModel
      ),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TermsAndConditionsResultFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1TermsPost(
    requestParameters: ApiV1TermsPostRequest
  ): Promise<TermsAndConditionsResult> {
    const response = await this.apiV1TermsPostRaw(requestParameters);
    return await response.value();
  }
}
